import { useState } from 'react'
import AccordionCard from '@/Shared/AccordionCard'
import AccordionItem from '@/Shared/AccordionItem'
import Button from '@/Shared/Button'
import classNames from 'classnames'
import Edit from './Edit'
import { usePage } from '@inertiajs/react'

export default () => {
  const { associate } = usePage().props.contact
  const [emergencyContact, setEmergencyContact] = useState(null)

  return (
    <AccordionCard
      title={`Emergency Contacts (${associate.emergency_contacts?.length || 0})`}
      actions={
        <Button theme="link" onClick={() => setEmergencyContact({})}>
          <i className="far fa-plus pr-1 text-sm"></i>
          <span>Add</span>
        </Button>
      }
      collapsed
    >
      {associate.emergency_contacts && associate.emergency_contacts.length > 0 ? (
        <div className="divide-y divide-dashed divide-gray-200 overflow-hidden rounded-md border border-gray-200">
          {associate.emergency_contacts.map((contact) => (
            <AccordionItem key={contact.id} onClick={() => setEmergencyContact(contact)}>
              <div className={classNames('font-semibold')}>
                {contact.name} {contact.relationship && `(${contact.relationship})`}
              </div>
              {contact.phone && (
                <div>
                  <a
                    className="p-0.5 font-medium text-blue-500 hover:underline focus:rounded-md focus:ring-2 focus:ring-blue-500 focus:ring-offset-1"
                    href={`tel:${contact.phone}`}
                    target="_blank"
                  >
                    {contact.phone_formatted}
                  </a>
                </div>
              )}
              {contact.email && (
                <div>
                  <a
                    className="p-0.5 font-medium text-blue-500 hover:underline focus:rounded-md focus:ring-2 focus:ring-blue-500 focus:ring-offset-1"
                    href={`mail${contact.email}`}
                    target="_blank"
                  >
                    {contact.email}
                  </a>
                </div>
              )}
            </AccordionItem>
          ))}
        </div>
      ) : (
        <div className="text-gray-700">No emergency contacts have been added.</div>
      )}

      <Edit
        associate={associate}
        record={emergencyContact}
        open={emergencyContact != undefined}
        onClosed={() => setEmergencyContact(null)}
      />
    </AccordionCard>
  )
}
