import { Fragment, useEffect, useRef, useState } from 'react'
import Button from '@/Shared/Button'
// import Dropdown from '@/Shared/Dropdown'
// import DropdownItem from '@/Shared/DropdownItem'
import DeleteConfirmationDialog from '@/Shared/Dialog/DeleteConfirmationDialog'
import FileEdit from './Edit'
import FileReviewingDialog from './FileReviewingDialog'
import classNames from 'classnames'
import { usePage } from '@inertiajs/react'
import { router } from '@inertiajs/react'
import Helpers from '@/utils/helpers'

export default () => {
  const fileUpload = useRef()
  const timer = useRef()
  const { transaction, auth, media_url } = usePage().props
  const administrating = Helpers.isAdminOrHR(auth.user)

  const [file, setFile] = useState(null)
  const [editing, setEditing] = useState(false)
  const [deleting, setDeleting] = useState(null)
  const [reviewing, setReviewing] = useState(false)
  const [breadcrumbs, setBreadcrumbs] = useState([{ id: null, name: 'Files' }])
  const [activeDirectory, setActiveDirectory] = useState(null)
  const [directories, setDirectories] = useState(transaction.directories)
  const [files, setFiles] = useState(transaction.files)

  useEffect(() => {
    onDirectoryOpened(activeDirectory?.id)
  }, [transaction])

  const fileChanged = (event, file) => {
    if (event.target.files.length > 0) {
      router.post(
        route('transactions.files.update', file.id),
        { ...file, newFile: Array.from(event.target.files)[0], _method: 'put' },
        {
          only: ['flash', 'history', 'transaction'],
        },
      )
    }
  }

  const onFileDelete = () => {
    router.delete(route('transactions.files.destroy', deleting), {
      only: ['flash', 'history', 'transaction', 'fileTypes'],
      onFinish: () => {
        setDeleting(null)
      },
    })
  }

  function isTouchDevice() {
    return 'ontouchstart' in window || navigator.maxTouchPoints
  }

  const onDirectoryClicked = (event, folderId) => {
    clearTimeout(timer.current)

    if (isTouchDevice() || event.detail === 2) {
      onDirectoryOpened(folderId)
    } else if (event.detail === 1) {
      timer.current = setTimeout(() => {}, 200)
    }
  }

  const onDirectoryOpened = (id) => {
    setBreadcrumbs([{ id: null, name: 'Files' }])

    if (id == null) {
      setDirectories(transaction.directories.filter((directory) => directory.directories?.length > 0 || directory.files?.length > 0))
      setFiles(transaction.files)
    } else {
      let { match: directory, nav } = findDirectory(transaction.directories, id)

      setBreadcrumbs((prevData) => [...prevData, ...nav])

      if (directory) {
        setActiveDirectory(directory)
        setDirectories(directory.directories)
        setFiles(directory.files)
      } else {
        setDirectories([])
        setFiles([])
      }
    }
  }

  const findDirectory = (directories, id) => {
    for (const directory of directories) {
      if (directory.id == id) {
        return { match: directory, nav: [directory] }
      } else if (directory.directories?.length > 0) {
        let match = findDirectory(directory.directories, id)

        if (match) {
          match.nav.unshift(directory)
          return match
        }
      }
    }
  }

  const isSystemGenerated = (file) => file.controlled && file.type.includes('Agent Commission Statement')

  const viewFile = (file) => {
    if (file.controlled && file.type.includes('Agent Commission Statement')) {
      window.open(route('transactions.files.show', file.id), '_blank')
    } else {
      window.open(`https://${media_url}/storage/${file.url}`, '_blank')
    }
  }

  return (
    <Fragment>
      <div className="mb-2 flex items-center justify-between gap-4 sm:sticky sm:top-0 sm:z-50 sm:bg-gray-100">
        <div className="flex flex-1 items-center gap-2">
          {breadcrumbs.map((directory, index) => (
            <Fragment key={directory.id}>
              <button
                role="button"
                className="rounded-full px-3 py-1 text-2xl font-medium tracking-wider text-gray-800 hover:bg-gray-200"
                onClick={() => onDirectoryOpened(directory.id)}
              >
                {directory.name}
              </button>

              {index + 1 < breadcrumbs.length && <i className="fas fa-angle-right text-lg text-gray-500/80"></i>}
            </Fragment>
          ))}
        </div>

        <div className="flex h-14 items-center justify-end">
          {(administrating || (!transaction.restricted && transaction.editable)) && (
            <Button theme="solid" onClick={() => setEditing(true)}>
              <i className="fas fa-file-plus mr-2 text-lg"></i>
              <span className="pr-1">New File</span>
            </Button>
          )}

          {/* <Dropdown
          id="files-add-options"
          label={
            <a role="button" className="flex items-center">
              <i className="fal fa-plus pr-2 text-2xl"></i> New
            </a>
          }
          orientation="right"
          noIcon
        >
          <DropdownItem>Folder</DropdownItem>
          <DropdownItem>File Upload</DropdownItem>
        </Dropdown> */}
        </div>
      </div>

      <div className="mb-4 flex flex-grow flex-col divide-y divide-gray-200 rounded bg-white shadow">
        <div className="flex h-14 items-center px-4 sm:px-6">
          <div className="text-sm font-medium uppercase tracking-wider text-gray-800">Name</div>
        </div>

        {/* 
      <div className="relative mx-4 flex border-b border-gray-200 py-2">
        <input
          type="text"
          name="search"
          placeholder="Search in files"
          autoComplete="off"
          autoCorrect="false"
          spellCheck="false"
          className="outline-none font-md transition-border block w-full rounded border border-transparent bg-gray-100 px-4 py-3 pl-14 placeholder-gray-400 duration-150 ease-in-out focus:border-0 focus:ring-1 focus:ring-blue-500"
        />

        <span className="absolute inset-y-0 left-0 flex w-10 justify-center pl-4">
          <span className="flex h-full items-center justify-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
              <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <rect id="bound" x="0" y="0" width="24" height="24"></rect>
                <path
                  className="fill-current text-blue-500"
                  d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                  id="Path-2"
                  fillRule="nonzero"
                  opacity="0.3"
                ></path>
                <path
                  className="fill-current text-blue-500"
                  d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                  id="Path"
                  fillRule="nonzero"
                ></path>
              </g>
            </svg>
          </span>
        </span>
      </div> 
      */}

        {directories?.length > 0 || files?.length > 0 ? (
          <div className="flex">
            <ul className="flex-1 divide-y divide-gray-300/80">
              {directories?.map((folder) => (
                <li
                  key={folder.id}
                  className="flex cursor-pointer select-none items-center justify-between px-5 py-4 hover:bg-gray-100"
                  onClick={(event) => onDirectoryClicked(event, folder.id)}
                >
                  <div className="flex flex-grow items-center">
                    <div className="flex h-10 w-10 items-center justify-center">
                      <i
                        className={classNames('fas fa-folder text-3xl', folder.name === 'Closing' ? 'text-blue-500' : 'text-gray-500')}
                      ></i>
                    </div>

                    <div className="ml-4">
                      <p className="font-medium text-gray-600">{folder.name}</p>
                    </div>
                  </div>

                  {/* <div className="relative z-0 flex items-center -space-x-2 overflow-hidden">
                  <img
                    className="relative z-10 inline-block h-10 w-10 rounded-full ring-2 ring-white"
                    src="https://www.lsnpros.com/images/associates/10/headshot.jpg"
                    alt=""
                  />
                  {folder.name === 'Closing' && (
                    <img
                      className="relative z-0 inline-block h-10 w-10 rounded-full ring-2 ring-white"
                      src="https://www.lsnpros.com/images/associates/29/headshot.jpg"
                      alt=""
                    />
                  )}
                </div> */}
                </li>
              ))}

              {files?.map((file) => (
                <li
                  className={classNames(
                    'space-y-4 px-5 py-4 sm:flex sm:items-center sm:gap-2 sm:space-y-0',
                    {
                      'text-gray-500': transaction.completed_at || !file.status,
                      'bg-green-50': !transaction.completed_at && (file.approved || isSystemGenerated(file)),
                      'bg-red-100/80': !transaction.completed_at && (file.not_applicable || file.declined),
                    },
                    // file.id == activeFile?.id ? 'bg-gray-200' : 'hover:bg-gray-100',
                  )}
                  key={file.id}
                >
                  {/* <div className="relative z-0 flex items-center -space-x-2 overflow-hidden">
                  <div className="z-10 rounded-full border border-white">
                    <img
                      className="h-10 w-10 rounded-full ring-1 ring-white"
                      src="https://www.lsnpros.com/images/associates/10/headshot.jpg"
                      alt=""
                    />
                  </div>
                  <div className="z-0 rounded-full border border-white">
                    <img
                      className="h-10 w-10 rounded-full ring-1 ring-white"
                      src="https://www.lsnpros.com/images/associates/29/headshot.jpg"
                      alt=""
                    />
                  </div>
                </div> */}

                  <div className="flex-1 space-y-4">
                    <div className="flex flex-1 items-center gap-2.5">
                      <div className="flex h-6 w-6 shrink-0 items-center justify-center sm:h-10 sm:w-10">
                        <i
                          className={classNames('fas fa-file-pdf text-3xl', {
                            'text-gray-600': transaction.completed_at || !file.status,
                            'text-green-700': !transaction.completed_at && (file.approved || isSystemGenerated(file)),
                            'text-red-600': !transaction.completed_at && (file.not_applicable || file.declined),
                          })}
                        ></i>
                      </div>

                      <div
                        className={classNames({
                          'font-medium text-gray-800': transaction.completed_at,
                          'font-medium text-blue-500': !file.status,
                          'font-medium text-green-900': !transaction.completed_at && (file.approved || isSystemGenerated(file)),
                          'font-semibold text-red-600': !transaction.completed_at && (file.not_applicable || file.declined),
                        })}
                      >
                        {file.filename}
                      </div>
                    </div>

                    {!transaction.completed_at && file.comments && (
                      <div className="flex-1">
                        {file.comments && (
                          <div
                            className={classNames(
                              'mt-2 rounded-md border border-gray-200/75 px-5 py-3 shadow',
                              !file.status || file.replaced ? 'bg-gray-100' : 'bg-white',
                            )}
                          >
                            <div className="mb-0.5 font-bold">Comments:</div>
                            <div className="whitespace-pre-line border-l-4 border-blue-500 pl-3 italic">{file.comments}</div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>

                  {isSystemGenerated(file) ? (
                    <div className="inline-block whitespace-nowrap rounded bg-gray-200 px-2.5 py-1.5 text-sm font-medium uppercase leading-none text-gray-800">
                      System-Generated
                    </div>
                  ) : (
                    activeDirectory.name === 'Closing' &&
                    !transaction.completed_at && (
                      <div>
                        {(['Compliance', 'Under Review'].indexOf(transaction.status) >= 0 || transaction.paused_at) &&
                          (!file.status || file.replaced) && (
                            <div className="inline-block whitespace-nowrap rounded bg-orange-200/60 px-2.5 py-1.5 text-sm font-medium uppercase leading-none text-orange-600">
                              {file.replaced ? 'Replaced' : 'Pending'}
                            </div>
                          )}

                        {file.approved && (
                          <div className="inline-block whitespace-nowrap rounded bg-green-700 px-2.5 py-1.5 text-sm font-medium uppercase leading-none text-white">
                            {file.status}
                          </div>
                        )}

                        {(file.declined || file.not_applicable) && (
                          <div className="inline-block whitespace-nowrap rounded border border-yellow-300 bg-yellow-100 px-2.5 py-1.5 text-sm font-bold uppercase leading-none text-yellow-700">
                            {file.declined ? 'Requires Attention' : 'N/A'}
                          </div>
                        )}
                      </div>
                    )
                  )}

                  <div className="flex items-center justify-end gap-3">
                    {(isSystemGenerated(file) ||
                      activeDirectory.name !== 'Closing' ||
                      !administrating ||
                      transaction.status !== 'Under Review') && (
                      <Button theme="outline-sm" onClick={() => viewFile(file)}>
                        <div className="flex items-center gap-2">
                          <i className="far fa-magnifying-glass-plus text-xl leading-none"></i>
                          View
                        </div>
                      </Button>
                    )}

                    {!isSystemGenerated(file) && (
                      <Fragment>
                        {activeDirectory.name === 'Closing' && administrating && transaction.status === 'Under Review' ? (
                          <Button
                            theme="outline-sm"
                            onClick={() => {
                              setFile(file)
                              setReviewing(true)
                            }}
                          >
                            <div className="flex items-center gap-2">
                              <i className="far fa-search text-xl leading-none"></i>
                              Review
                            </div>
                          </Button>
                        ) : (
                          <div className="flex items-center justify-between gap-4 whitespace-nowrap font-medium sm:justify-end">
                            {(administrating || transaction.editable) &&
                              !transaction.restricted &&
                              file.replaceable &&
                              (!activeDirectory || !activeDirectory.archived) && (
                                <label htmlFor={`${file.id}-url`} className="block">
                                  <span
                                    role="button"
                                    tabIndex={0}
                                    className="group relative ml-2 inline-flex cursor-pointer items-center rounded-md border border-gray-300 bg-white px-4 py-2.5 text-base font-medium leading-none text-gray-700 shadow-sm outline-none transition-all duration-150 ease-in-out hover:bg-gray-200 hover:text-gray-800 focus:border-transparent focus:ring-2 focus:ring-primary-500"
                                    onKeyUp={(e) => (e.key === ' ' || e.key === 'Enter') && fileUpload.current.click()}
                                  >
                                    <input
                                      ref={fileUpload}
                                      type="file"
                                      id={`${file.id}-url`}
                                      className="sr-only"
                                      accept="application/pdf"
                                      onChange={(event) => fileChanged(event, file)}
                                      tabIndex="-1"
                                    />

                                    <span className="flex items-center gap-2">
                                      <i className="far fa-file-arrow-up text-xl leading-none text-gray-700"></i> <span>Replace</span>
                                    </span>
                                  </span>
                                </label>
                              )}

                            {!transaction.restricted && (file.addendum || file.optional) && (
                              <Button theme="icon" onClick={() => setDeleting(file.id)}>
                                <i className="fas fa-trash text-lg leading-none text-red-700"></i>
                              </Button>
                            )}
                          </div>
                        )}
                      </Fragment>
                    )}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        ) : (
          <div className="bg-gray-100 p-4 text-center font-medium text-gray-600 sm:px-6">Use the "New File" button to upload a file.</div>
        )}

        <FileEdit
          transaction={transaction}
          open={editing}
          onClosed={() => {
            setEditing(false)
          }}
        />

        <FileReviewingDialog
          file={file}
          open={reviewing}
          onCanceled={() => {
            setReviewing(false)
            setTimeout(() => {
              setFile(null)
            }, 500)
          }}
        >
          Reviewing
        </FileReviewingDialog>

        <DeleteConfirmationDialog open={deleting != null} onCanceled={() => setDeleting(null)} onApproved={() => onFileDelete()}>
          <p>Are you sure you want to delete this file?</p>
        </DeleteConfirmationDialog>
      </div>
    </Fragment>
  )
}
