import { useEffect, useState } from 'react'
import { usePage } from '@inertiajs/react'
import Entry from './Entry'
import NoteEntry from './NoteEntry'
import './styles.scss'

export default ({ emptyText, scrollEvent, scrollOffset, type }) => {
  const props = usePage().props
  const history = props[(type != undefined ? `${type}_` : '') + 'history']
  const [timeline, setTimeline] = useState({
    endpoint: '',
    entries: [],
    page: 2,
    hasMore: true,
  })
  const [fetching, setFetching] = useState(false)

  useEffect(() => {
    setTimeline({ ...timeline, endpoint: history.endpoint, entries: history.entries })
  }, [history])

  useEffect(() => {
    if (scrollOffset != undefined && scrollEvent != undefined) {
      handleHistoryInfiniteScroll(scrollEvent, type || 'history')
    }
  }, [scrollOffset])

  const handleHistoryInfiniteScroll = (event, type) => {
    if (fetching || !timeline.hasMore) return
    let variance = 50
    if (event.target.offsetHeight + event.target.scrollTop + variance < event.target.scrollHeight) return
    loadMore(type)
  }

  const fetchMoreHistory = async (page, type) => {
    return axios.get(timeline.endpoint, {
      params: {
        page: page,
        type: type,
      },
    })
  }

  const loadMore = (type, reset) => {
    let page = timeline.page

    if (reset) {
      page = 1
      timeline.entries = null
    }
    setFetching(true)

    fetchMoreHistory(page, type).then((response) => {
      if (response.data.entries.length > 0) {
        let updated = { ...timeline, page: page + 1 }

        if (!reset && updated.entries) response.data.entries.map((entry) => updated.entries.push(entry))
        else updated.entries = response.data.entries

        setTimeline(updated)
      } else {
        setTimeline({ ...timeline, hasMore: false })
      }

      setFetching(false)
    })
  }

  return timeline.entries?.length > 0 ? (
    <div
      className="flex flex-col overflow-y-auto px-3 md:pl-8"
      onScroll={(event) => !scrollOffset && handleHistoryInfiniteScroll(event, type || 'history')}
    >
      <div className="relative mb-8">
        <div className="static ml-[4px] py-6">
          <div className="relative z-10">
            {timeline.entries.map((entry) =>
              (() => {
                switch (entry.type) {
                  case 'Note':
                    return <NoteEntry entry={entry} key={entry.id} />
                  default:
                    return (
                      <Entry action={entry.action} icon={entry.icon} timestamp={entry.elapsed} key={entry.id}>
                        <div className="text-gray-900">
                          <span>{entry.author || 'BetterLivingPRO'}</span>{' '}
                          <span dangerouslySetInnerHTML={{ __html: entry.description }}></span>
                          {!entry.comments && ['edit', 'email'].indexOf(entry.action) == -1 ? '.' : ''}
                        </div>
                      </Entry>
                    )
                }
              })(),
            )}
          </div>
          <div className="absolute top-0 z-0 h-1.5 w-6 rounded" style={{ backgroundColor: '#dcdcdc' }}></div>
          <div className="absolute bottom-0 z-0 h-1.5 w-6 rounded" style={{ backgroundColor: '#dcdcdc' }}></div>
          <div className="absolute inset-y-0 left-0 z-0 ml-3 h-full w-1.5 rounded" style={{ backgroundColor: '#dcdcdc' }}></div>
        </div>
      </div>
    </div>
  ) : (
    <div className="mb-4 flex flex-grow flex-col divide-y divide-gray-200 rounded bg-white px-6 shadow sm:mx-8">
      <div className="rounded-lg bg-white p-4 text-center font-medium text-gray-600">{emptyText || 'No activity history exists.'}</div>
    </div>
  )
}
