import React, { Fragment, useEffect, useRef, useState } from 'react'
import Button from '@/Shared/Button'
import SlideOver from '@/Shared/SlideOver'
import useDebounce from '@/hooks/useDebounce'
import { usePage } from '@inertiajs/react'
import TagGroup from './Group'
import Tag from './Tag'
import TextInput from '@/Shared/Forms/TextInput'

export default ({ contact, open, onClosed }) => {
  const [changes, setChanges] = useState([])
  const [filteredTags, setFilteredTags] = useState([])
  const [mode, setMode] = useState('select')
  const [search, setSearch] = useState('')
  const [submitting, setSubmitting] = useState(false)
  const [tags, setTags] = useState(usePage().props.tags)
  const searchRef = useRef(null)

  if (tags == undefined) return <></>

  useEffect(() => filter(), [tags])
  useDebounce(() => tags && filter(), 200, [search])

  const changed = (tag) => {
    const isChanged = tags.personal.find((t) => t.id == tag.id && t.label !== tag.label) != undefined

    let updatedTags = [...changes]
    let existing = changes.findIndex((t) => t.id == tag.id)
    if (existing >= 0) {
      if (isChanged) {
        updatedTags[existing] = tag
      } else {
        updatedTags.splice(existing, 1)
      }
    } else {
      updatedTags = updatedTags.concat(tag)
    }

    setChanges(updatedTags)

    setFilteredTags([...filteredTags.map((g) => (g.name === 'My' ? { ...g, tags: g.tags.map((t) => (t.id == tag.id ? tag : t)) } : g))])
  }

  const changeMode = (mode) => {
    if (mode === 'select') {
      filter()
    }

    setMode(mode)
    searchRef.current.focus()
  }

  const filter = () => {
    let groups = []

    if (tags.corporate) {
      groups.push({
        name: 'Corporate',
        tags: tags.corporate.filter((t) => t.label.toLowerCase().includes(search.toLowerCase())),
        editable: false,
      })
    }

    if (tags.personal) {
      groups.push({
        name: 'My',
        tags: tags.personal.filter((t) => t.label.toLowerCase().includes(search.toLowerCase())),
        editable: true,
      })
    }

    if (tags.smart) {
      groups.push({
        name: 'Smart',
        tags: tags.smart.filter((t) => t.label.toLowerCase().includes(search.toLowerCase())),
        editable: false,
      })
    }

    setFilteredTags(groups)
  }

  const reset = () => {
    setSearch('')
    searchRef.current.focus()
  }

  const save = () => {
    setSubmitting(true)

    axios
      .post(`/api/tags`, {
        tags: changes,
        _method: 'patch',
      })
      .then((response) => {
        setChanges([])
        setTags(response.data.tags)
        changeMode('select')
      })
      .catch((_) => {})
      .then((_) => setSubmitting(false))
  }

  const updateTags = (response) => {
    if (response.data && response.data.tag) {
      setTags({ ...tags, personal: tags.personal.concat(response.data.tag) })
    }
  }

  const EditHeaderActions = () => (
    <Button theme="toolbar-icon" onClick={() => changeMode('select')}>
      <span className="sr-only">Cancel edit tags</span>
      <i className="fas fa-undo text-lg leading-none text-white text-opacity-80"></i>
    </Button>
  )

  const SelectHeaderActions = () => (
    <Button theme="toolbar-icon" onClick={() => changeMode('edit')}>
      <span className="sr-only">Edit tags</span>
      <i className="fas fa-pen text-lg leading-none text-white text-opacity-80"></i>
    </Button>
  )

  return (
    <SlideOver
      actions={mode === 'select' ? <SelectHeaderActions /> : <EditHeaderActions />}
      footerActions={
        <Button theme="solid" type="submit" disabled={submitting || changes.length == 0} onClick={() => save()}>
          Save Changes
        </Button>
      }
      focusRef={searchRef}
      show={open}
      title="Tags"
      onCancel={() => changeMode('select')}
      onClosed={onClosed}
      hideFooter={mode === 'select'}
      aboveMessages
    >
      <div className="relative flex w-full">
        <TextInput
          ref={searchRef}
          classes="flex-grow"
          inputClasses="px-4 pr-14 py-2"
          placeholder="Search or Create Tags"
          value={search}
          onChange={(value) => setSearch(value)}
        />

        <div className="absolute inset-y-0 right-2 flex justify-center">
          {search && (
            <button
              className="m-2 flex w-6 items-center justify-center rounded text-gray-400 outline-none hover:text-gray-900 focus:ring-2 focus:ring-primary-500"
              title="Clear filter"
              onClick={() => reset()}
            >
              <i className="far fa-times fa-lg"></i>
            </button>
          )}
          <span className="flex h-full w-7 items-center justify-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
              <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <rect id="bound" x="0" y="0" width="24" height="24"></rect>
                <path
                  className="fill-current text-primary-500"
                  d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                  id="Path-2"
                  fillRule="nonzero"
                  opacity="0.3"
                ></path>
                <path
                  className="fill-current text-primary-500"
                  d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                  id="Path"
                  fillRule="nonzero"
                ></path>
              </g>
            </svg>
          </span>
        </div>
      </div>

      <div className="mt-6">
        {filteredTags
          .filter((group) => !contact || !group.name.includes('Smart'))
          .map(
            (group) =>
              (mode === 'select' || ['My'].indexOf(group.name) >= 0) && (
                <Fragment key={group.name}>
                  <TagGroup
                    group={{ ...group, tags: group.tags.filter((tag) => (tag.deleting == undefined ? !tag.deleted : !tag.deleting)) }}
                  >
                    {mode === 'select' &&
                      search !== '' &&
                      group.name === 'My' &&
                      tags.corporate.filter((t) => !t.deleted && t.label.toLowerCase() === search.toLowerCase()).length === 0 &&
                      tags.personal.filter((t) => !t.deleted && t.label.toLowerCase() === search.toLowerCase()).length === 0 &&
                      tags.smart.filter((t) => !t.deleted && t.label.toLowerCase() === search.toLowerCase()).length === 0 && (
                        <Tag tag={{ label: search, creatable: true }} onUpdated={(response) => updateTags(response)} />
                      )}

                    {group.tags
                      .filter((tag) => (tag.deleting == undefined ? !tag.deleted : !tag.deleting))
                      .map((tag) => (
                        <Tag
                          contact={contact}
                          tag={tag}
                          editing={mode === 'edit'}
                          onChanged={(tag) => changed(tag)}
                          onDeleted={(tag) => changed({ ...tag, deleting: true })}
                          onRestored={(tag) => changed({ ...tag, deleting: false })}
                          key={tag.id}
                        />
                      ))}
                  </TagGroup>

                  {mode === 'edit' && (
                    <TagGroup
                      group={{
                        ...group,
                        name: 'Deleted',
                        tags: group.tags.filter((tag) => (tag.deleting == undefined ? tag.deleted : tag.deleting)),
                      }}
                    >
                      {group.tags
                        .filter((tag) => (tag.deleting == undefined ? tag.deleted : tag.deleting))
                        .map((tag) => (
                          <Tag
                            contact={contact}
                            tag={tag}
                            editing={mode === 'edit'}
                            onChanged={(tag) => changed(tag)}
                            onDeleted={(tag) => changed({ ...tag, deleting: true })}
                            onRestored={(tag) => changed({ ...tag, deleting: false })}
                            key={tag.id}
                          />
                        ))}
                    </TagGroup>
                  )}
                </Fragment>
              ),
          )}
      </div>
    </SlideOver>
  )
}
