import { Fragment } from 'react'
import { router } from '@inertiajs/react'
import { usePage } from '@inertiajs/react'

const FilterGroup = ({ name, value, onClick }) => {
  return (
    <div className="relative flex items-center rounded border border-green-200 border-opacity-50 bg-green-100 py-2 pl-3 pr-8 font-medium text-green-700">
      <span className="text-lg leading-none md:text-base">
        {name === 'search' && '"'}
        {name === 'search' ? value : <span className="capitalize">{value}</span>}
        {name === 'search' && '"'}
      </span>
      <button
        className="absolute right-1 flex h-6 items-center justify-center rounded px-1.5 outline-none hover:bg-green-500 hover:text-white focus:bg-green-100 focus:text-primary-500 focus:ring-2 focus:ring-inset focus:ring-primary-500"
        onClick={onClick}
      >
        <i className="far fa-times text-xl md:text-lg"></i>
      </button>
    </div>
  )
}

export default () => {
  const { filters, services } = usePage().props
  const activeFilters = Object.keys(filters).filter((k) => filters[k] && ['direction', 'limit', 'radius', 'sortBy'].indexOf(k) == -1)

  let options = {
    mlsAssociations: [
      { value: 'MLS PIN', label: 'MLS PIN' },
      { value: 'Cape Cod Multiple Listing Services', label: 'Cape Cod Multiple Listing Services' },
      { value: 'Statewide MLS', label: 'Statewide MLS' },
      { value: 'PrimeMLS', label: 'PrimeMLS' },
    ],
    realtorAssociations: [
      { value: 'Cape Cod & Islands Assoc. of Realtors (CCIAOR)', label: 'Cape Cod & Islands Assoc. of Realtors (CCIAOR)' },
      { value: 'Greater Boston Real Estate Board', label: 'Greater Boston Real Estate Board' },
      { value: 'Greater Providence Board of Realtors', label: 'Greater Providence Board of Realtors' },
      { value: 'Massachusetts Association of Realtors', label: 'Massachusetts Association of Realtors' },
      { value: 'New Hampshire Association of Realtors', label: 'New Hampshire Association of Realtors' },
      { value: 'Northern RI Board of Realtors', label: 'Northern RI Board of Realtors' },
      { value: 'Realtor Association of Central Massachusetts', label: 'Realtor Association of Central Massachusetts' },
      { value: 'Realtor Association of Southeastern MA (RASEM)', label: 'Realtor Association of Southeastern MA (RASEM)' },
      { value: 'Rhode Island Association of Realtors', label: 'Rhode Island Association of Realtors' },
      { value: 'Seacoast Board of Realtors', label: 'Seacoast Board of Realtors' },
    ],
    licenseStates: [
      { value: 'MA', label: 'Massachusetts' },
      { value: 'NH', label: 'New Hampshire' },
      { value: 'RI', label: 'Rhode Island' },
    ],
    services: services,
    roles: [
      { value: 'svp', label: 'Support Vice President' },
      { value: 'pal', label: 'PAL' },
    ],
  }

  const getItems = (key) => {
    if (!filters[key]) return []
    return filters[key]
  }

  const getFilterGroup = (filter, data) => {
    return filters[filter].map((item) => (
      <FilterGroup
        name={filter}
        value={data?.find((d) => d.value == item).label || item}
        key={item + filter}
        onClick={() => removeFilter(filter, item)}
      />
    ))
  }

  const renderFilter = (filter) => {
    switch (filter) {
      case 'status':
      case 'website_status':
        return getItems(filter).map((status) => (
          <FilterGroup name={filter} value={status} key={status + filter} onClick={() => removeFilter(filter, status)} />
        ))
      case 'tags':
        return getItems(filter).map((tag) => (
          <FilterGroup name={filter} value={tag} key={tag + filter} onClick={() => removeFilter(filter, tag)} />
        ))
      case 'managers':
        return getItems(filter).map((manager) => (
          <FilterGroup name={filter} value={manager.name} key={manager.id} onClick={() => removeFilter(filter, manager)} />
        ))
      case 'roles':
      case 'services':
      case 'licenseStates':
      case 'realtorAssociations':
      case 'mlsAssociations':
        return getFilterGroup(filter, options[filter])
      default:
        let value = typeof filters[filter] == 'boolean' ? filter : filters[filter]
        switch (value) {
          case 'associatesOnly':
            value = 'Associates Only'
            break
        }
        return <FilterGroup name={filter} value={value} onClick={() => removeFilter(filter)} />
    }
  }

  const removeFilter = (key, value) => {
    let newFilters = { ...filters }

    if (value) {
      let items = getItems(key)
      items = items.filter((t) => t !== value)
      if (items.length > 0) {
        newFilters[key] = key === 'tags' ? items.join(',') : items
      } else {
        delete newFilters[key]
      }
    } else {
      delete newFilters[key]
    }

    if (key === 'managers') {
      newFilters[key] = newFilters.managers?.map((a) => a.user_id)
    }

    const query = encodeURIComponent(JSON.stringify(newFilters))

    router.get(route(route().current(), { filters: query }))
  }

  return (
    activeFilters.length > 0 && (
      <div className="flex flex-none flex-wrap gap-1.5">
        {activeFilters.map((key, index) => (
          <Fragment key={index}>{renderFilter(key)}</Fragment>
        ))}
      </div>
    )
  )
}
