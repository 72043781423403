import { useRef, useEffect, useState } from 'react'
import classNames from 'classnames'
import Button from '@/Shared/Button'
import ContactForm from './ContactForm'
import './styles.scss'
import { router } from '@inertiajs/react'

function debounce(fn, ms) {
  let timer
  return (_) => {
    clearTimeout(timer)
    timer = setTimeout((_) => {
      timer = null
      fn.apply(this, arguments)
    }, ms)
  }
}

export default ({ contact }) => {
  const scrollRef = useRef()
  const titleBarRef = useRef()
  const [compact, setCompact] = useState(false)

  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  })

  useEffect(() => {
    window.addEventListener('resize', debouncedHandleResize)

    return (_) => {
      window.removeEventListener('resize', debouncedHandleResize)
    }
  })

  useEffect(() => {
    const checkForCompactMode = () => {
      if (dimensions.width < 1024) {
        return
      }

      let scrollRect = scrollRef.current.getBoundingClientRect()
      let titleBarRect = titleBarRef.current.getBoundingClientRect()
      let variance = 50

      if (titleBarRect.top - variance <= scrollRect.top && !compact) {
        setCompact(true)
      } else if (titleBarRect.top - variance > scrollRect.top && compact) {
        setCompact(false)
      }
    }

    if (scrollRef && scrollRef.current) {
      scrollRef.current.addEventListener('scroll', checkForCompactMode, { passive: true })

      return (_) => {
        scrollRef.current?.removeEventListener('scroll', checkForCompactMode)
      }
    }
  }, [compact, dimensions])

  const debouncedHandleResize = debounce(function handleResize() {
    if (window.innerWidth < 1024) {
      setCompact(false)
    }

    setDimensions({
      height: window.innerHeight,
      width: window.innerWidth,
    })
  }, 200)

  const goBack = () => {
    if (history.length > 2) {
      history.back()
    } else {
      router.visit(route('pros.index'))
    }
  }

  return (
    <div ref={scrollRef} className="flex h-full justify-center overflow-y-auto bg-gray-300">
      <div className="mx-auto w-full xl:max-w-7xl">
        <div className="my-6 ml-4 xl:-ml-2">
          <Button type="submit" theme="outline" onClick={() => goBack()}>
            <i className="fas fa-angle-left mr-2"></i> Back
          </Button>
        </div>

        <div className="bg-white lg:min-h-screen xl:rounded-lg xl:shadow-xl">
          <div
            className="h-[225px] bg-cover bg-center pb-0 sm:pb-[24.9%] xl:rounded-t-lg"
            style={{ backgroundImage: `url(${contact.profile.banner})` }}
          ></div>

          <div ref={titleBarRef} className="relative">
            <div
              className={classNames('bg-white px-8 lg:sticky lg:top-0 lg:z-10', {
                'py-4 shadow-md': compact,
              })}
            >
              <div className="relative flex flex-col items-center gap-5 text-center lg:flex-row lg:text-left">
                <div className="lg:flex-1">
                  <div className="lg:flex lg:items-center lg:gap-6">
                    <div>
                      <div
                        className={classNames(
                          'flex overflow-hidden rounded-full border-2 border-primary-500 bg-primary-500 shadow-md ring-4 ring-white ring-opacity-50',
                          {
                            'mx-auto -mt-36 mb-4 h-48 w-48 lg:mb-0 lg:mt-[-126px] lg:h-auto lg:w-auto': !compact,
                            'h-20 w-20': compact,
                          },
                        )}
                      >
                        <img src={contact.avatar} alt="" />
                      </div>
                    </div>
                    <div className="lg:w-2/3">
                      <h1 className="text-4xl font-bold text-gray-900">{contact.full_name}</h1>
                      <div className="text-xl text-gray-800">{contact.company}</div>
                    </div>
                  </div>
                </div>
                <div className="lg:text-right">
                  <div className={classNames({ 'lg:absolute lg:right-0 lg:top-0 lg:-translate-y-1/2': !compact })}>
                    <div
                      className={classNames(
                        'inline-flex items-center justify-center bg-white',
                        compact ? '' : 'rounded ring-4 ring-white ring-opacity-50 lg:shadow-md',
                      )}
                    >
                      <img
                        className={classNames(compact ? 'h-20' : 'max-h-[85px] lg:max-h-[115px] lg:max-w-[300px] lg:rounded')}
                        src={contact.profile.logo}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-4 text-center text-lg font-bold lg:hidden">
              <a
                href="#bio"
                className="group inline-flex items-center gap-2 rounded-md px-3 py-1 text-primary-500 focus:ring-2 focus:ring-primary-500 focus:ring-offset-1"
              >
                <i className="fas fa-arrow-down"></i>
                <span className="group-hover:underline">Read more about me</span>
                <i className="fas fa-arrow-down"></i>
              </a>
            </div>

            <div className="mt-6 px-4 sm:px-8 lg:flex lg:gap-10">
              <div className="mx-auto max-w-sm lg:sticky lg:top-[125px] lg:w-1/3 lg:max-w-full lg:self-start">
                <div className="space-y-4 rounded-md bg-gray-200 bg-opacity-50 p-6">
                  <div className="mb-6 text-center text-lg">
                    <h3 className="mb-2 text-xl font-bold text-gray-600">Refer this person:</h3>

                    <div className="group -mx-2 flex justify-center">
                      <a
                        href={route('pros.referral', { contact: contact.id, type: 'email' })}
                        tooltip="Refer this person by email"
                        className="relative ml-2 inline-flex h-10 items-center rounded-md bg-primary-500 px-6 font-medium leading-none text-white shadow-sm outline-none ring-primary-500 transition-all duration-150 ease-in-out hover:bg-primary-900 focus:ring-2 focus:ring-offset-1"
                        target="_blank"
                      >
                        <i className="fas fa-envelope text-large pr-2"></i>
                        <span>By Email</span>
                      </a>

                      <a
                        href={route('pros.referral', { contact: contact.id, type: 'text' })}
                        tooltip="Refer this person by text"
                        className="relative ml-2 inline-flex h-10 items-center rounded-md bg-primary-500 px-6 font-medium leading-none text-white shadow-sm outline-none ring-primary-500 transition-all duration-150 ease-in-out hover:bg-primary-900 focus:ring-2 focus:ring-offset-1"
                        target="_blank"
                      >
                        <i className="fas fa-sms text-large pr-2"></i>
                        <span>By Text</span>
                      </a>
                    </div>
                  </div>

                  <div className="text-center">
                    <span className="font-bold">{contact.title}</span>
                    {contact.license && <div>{contact.license}</div>}
                    {contact.associate?.licenses?.length > 0 &&
                      contact.associate.licenses.map((license) => <div key={license.license}>{license.license}</div>)}

                    {contact.associate ? (
                      <div className="mt-2" dangerouslySetInnerHTML={{ __html: contact.primary_market_location }}></div>
                    ) : (
                      contact.addresses?.length > 0 && <div className="mt-2">{contact.addresses[0].address_inline}</div>
                    )}
                  </div>

                  <div className="flex justify-center">
                    <div className="space-y-4">
                      {contact.phone_numbers?.length > 0 && (
                        <div className="space-y-4">
                          {contact.phone_numbers?.map((phone) => (
                            <div className="flex items-center justify-center space-x-3" key={phone.phone}>
                              <div className="flex w-4 items-center justify-center">
                                {phone.type === 'Mobile' ? (
                                  <i className="fas fa-mobile-alt text-lg leading-none"></i>
                                ) : (
                                  <i className="fas fa-phone text-lg leading-none"></i>
                                )}
                              </div>
                              <span className="font-medium text-primary-500">{phone.phone_formatted}</span>
                              {phone.type && <span className="text-gray-600">({phone.type})</span>}
                            </div>
                          ))}
                        </div>
                      )}

                      <a href="#contact" className="flex items-center space-x-3 lg:hidden">
                        <div className="flex w-4 items-center justify-center">
                          <i className="far fa-envelope text-lg leading-none"></i>
                        </div>
                        <span className="font-medium text-primary-500">Contact me by email</span>
                      </a>

                      {contact.websites?.length > 0 && (
                        <div className="space-y-4">
                          {contact.websites
                            ?.filter((website) => website.primary)
                            .map((website, index) => (
                              <a
                                href={website.url}
                                className="group inline-flex items-center gap-3 font-medium text-primary-500 hover:text-primary-900"
                                target="_blank"
                                key={index}
                              >
                                <span className="group-hover:underline">Visit my company website</span>{' '}
                                <i className="fas fa-external-link text-lg leading-none"></i>
                              </a>
                            ))}
                        </div>
                      )}
                    </div>
                  </div>

                  {contact.social_accounts?.length > 0 && (
                    <div className="pt-3 text-center">
                      <h3 className="mb-3 text-xl font-bold text-gray-600">Follow me on social media:</h3>
                      <div className="space-x-3 space-y-4">
                        {contact.social_accounts.map((social, index) => (
                          <a
                            href={social.url}
                            className="rounded-md px-1.5 text-4xl text-primary-500 hover:text-gray-500 focus:ring-2 focus:ring-primary-500 focus:ring-offset-1"
                            tooltip={`Follow me on ${social.type}`}
                            target="_blank"
                            key={index}
                          >
                            <i className={`fa-brands fa-${social.type.toLowerCase()}`}></i>
                          </a>
                        ))}
                      </div>
                    </div>
                  )}

                  {contact.lsns?.length > 0 && (
                    <div className="pt-3 text-center">
                      <h3 className="mb-3 text-xl font-bold text-gray-600">I'm also a member of these Local Service Networks:</h3>
                      <div className="flex flex-col items-center gap-2">
                        {contact.lsns.map((lsn) => (
                          <a
                            href={lsn.url}
                            className="inline-flex rounded-md px-2.5 py-0.5 font-semibold text-primary-500 hover:text-primary-900 hover:underline focus:ring-2 focus:ring-primary-500 focus:ring-offset-1"
                            target="_blank"
                            key={lsn.url}
                          >
                            {lsn.name}
                          </a>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="text-lg lg:w-2/3">
                <div id="bio" className="pb-8 lg:hidden"></div>

                <div
                  className="profile-body mb-8 border-b border-gray-300 pb-8"
                  dangerouslySetInnerHTML={{ __html: contact.profile.content }}
                ></div>

                <ContactForm contact={contact} />
              </div>
            </div>
          </div>
        </div>

        <div className="hidden xl:block xl:h-10"></div>
      </div>
    </div>
  )
}
