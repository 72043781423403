import { useContext, useEffect, useRef } from 'react'
import { useForm, usePage, router } from '@inertiajs/react'
import Button from '@/Shared/Button'
import SlideOver from '@/Shared/SlideOver'
import TransactionDates from './Dates'
import { DatesContext } from './DatesContext'

export default function Edit({ open, onClosed }) {
  let focusRef = useRef()
  const { transaction } = usePage().props
  const { data, setData, clearErrors, errors } = useForm()
  const { dates, fillDates, transformDates } = useContext(DatesContext)

  useEffect(() => {
    if (dates) {
      setData({
        ...{ id: transaction.id },
        ...fillDates(),
      })
    }
  }, [open])

  const submit = (event) => {
    event.preventDefault()

    router.post(
      route('transactions.update', data.id),
      {
        ...data,
        ...transformDates(data),
        _method: 'put',
      },
      {
        onSuccess: (_) => {
          clearErrors()
          onClosed()
        },
      },
    )
  }

  return (
    <SlideOver
      focusRef={focusRef}
      footerActions={
        <Button type="submit" theme="solid" form="important-dates-form">
          Save
        </Button>
      }
      show={open}
      size="max-w-xl"
      title="Important Dates"
      onClosed={onClosed}
      aboveMessages
    >
      <form id="important-dates-form" className="pb-4" onSubmit={submit}>
        <TransactionDates data={data} setData={setData} errors={errors} />
      </form>
    </SlideOver>
  )
}
