import Alert from '@/Shared/Alert'
import Button from '@/Shared/Button'
import Dialog from '@/Shared/Dialog/Index'
import { router } from '@inertiajs/react'
import { usePage } from '@inertiajs/react'

export default function ApproveReviewDialog({ open, onCanceled }) {
  const { transaction } = usePage().props

  const onSubmit = () => {
    router.post(
      route('transactions.approve', transaction.id),
      {},
      {
        only: ['flash', 'history', 'transaction'],
        onSuccess: () => {
          onCanceled()
        },
      },
    )
  }

  return (
    <Dialog
      footerActions={
        <Button type="submit" theme="solid" onClick={() => onSubmit()}>
          Approve & Complete Transaction
        </Button>
      }
      size="sm:max-w-xl"
      show={open}
      title="Approve & Complete Transaction"
      onClosed={onCanceled}
      focusCancelButton
    >
      <Alert
        heading={
          <p>
            This action is <span className="font-bold uppercase text-red-600">final</span> and can't be reversed.
          </p>
        }
        subtext="Are you sure you would you like to continue?"
        type="danger"
      />
    </Dialog>
  )
}
