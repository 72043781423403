import { Fragment, useState } from 'react'
import Button from '@/Shared/Button'
import Filters from './Filters'
import SearchBar from '@/Shared/SearchBar'

export default ({ onCreate }) => {
  const [open, setOpen] = useState(false)

  return (
    <Fragment>
      <SearchBar
        actions={
          <Fragment>
            <Button theme="solid" onClick={() => onCreate()}>
              <i className="far fa-plus pr-2 text-lg"></i>
              <span className="inline pr-1 lg:hidden xl:inline">New</span>
            </Button>
          </Fragment>
        }
        label="Associates"
        onShowFilters={() => setOpen(true)}
        placeholder="Search associates"
      />

      <Filters open={open} onClosed={() => setOpen(false)} />
    </Fragment>
  )
}
