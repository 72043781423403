import { useEffect, useRef, useState } from 'react'
import Alert from '@/Shared/Alert'
import Button from '@/Shared/Button'
import Dialog from '@/Shared/Dialog/Index'
import RichTextEditor from '@/Shared/RichTextEditor/Index'
import { useForm } from '@inertiajs/react'
import { Tooltip } from '@/Shared/Tooltip'
import { router } from '@inertiajs/react'
import classNames from 'classnames'

export default ({ record, open, onClosed }) => {
  let focusRef = useRef()
  const { clearErrors, data, setData } = useForm()
  const [note, setNote] = useState('')
  const [showStandardResponses, setShowStandardResponses] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  useEffect(() => {
    if (record) {
      setData({
        id: record.id || null,
        approved: null,
        note: '',
      })
    }
  }, [record])

  useEffect(() => {
    if (note != data.note) {
      setData({ ...data, note: note })
    }
  }, [note])

  const onSubmit = (event, approved) => {
    event.preventDefault()

    setSubmitting(true)

    router.post(
      route('admin.service-relations.update', data.id),
      {
        ...data,
        approved: approved,
        _method: 'put',
      },
      {
        onSuccess: () => {
          clearErrors()
          onClosed()
        },
        onFinish: () => setSubmitting(false),
      },
    )
  }

  return (
    <Dialog
      focusRef={focusRef}
      footerActions={
        <div className="flex items-center">
          {!record?.approved_at && (
            <Button theme="solid" onClick={(event) => onSubmit(event, true)} disabled={!open || submitting}>
              <div className="flex items-center gap-2">
                <i className="far fa-check-circle text-xl"></i>
                Approve
              </div>
            </Button>
          )}

          {!record?.denied_at && (
            <Button
              theme="solid"
              className="bg-red-600 text-white"
              onClick={(event) => onSubmit(event, false)}
              disabled={!open || submitting}
            >
              <div className="flex items-center gap-2">
                <i className="far fa-times-circle text-xl"></i>
                Deny
              </div>
            </Button>
          )}
        </div>
      }
      background="bg-gray-100"
      cancelText="Close"
      position="top"
      show={open}
      size="sm:max-w-3xl"
      title={`Service Relation Approval Request`}
      onClosed={() => onClosed()}
    >
      <div className="space-y-4 text-xl">
        <div>
          <div className="mb-0.5 block text-sm font-semibold uppercase text-blue-400/80">Related Service</div>
          <div className="font-semibold text-blue-500">{record.related_service}</div>
        </div>

        <div>
          <div className="mb-0.5 block text-sm font-medium uppercase text-gray-500">Primary Service</div>
          <div className="font-medium text-gray-700">{record.primary_service}</div>
        </div>

        {record?.pros && (
          <div className="space-y-2">
            <div className="mb-0.5 block text-sm font-medium uppercase text-gray-500">
              <div>PROs{!record?.approved_at && !record?.denied_at && ' Requesting Approval'}</div>
            </div>

            <div className="rounded bg-white shadow">
              {record.pros.length > 0 ? (
                <div className="flex flex-col">
                  <div className="overflow-x-auto">
                    <div className="inline-block min-w-full align-middle">
                      <div className="overflow-hidden rounded-lg border-b border-gray-200 shadow">
                        <table className="min-w-full divide-y divide-gray-300">
                          <tbody className="divide-y divide-gray-200 bg-white">
                            {record.pros.map((pro) => (
                              <tr key={pro.id}>
                                <td className="px-4 py-3 text-gray-700 sm:px-6">{pro.name}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="px-4 py-3 text-center text-base font-medium text-red-600">
                  {record?.denied_at
                    ? 'There are no PROs for this service relation due to it being denied'
                    : 'There are currently no PROs using this service relation.'}
                  .
                </div>
              )}
            </div>
          </div>
        )}

        <div>
          <div className="mb-1.5 flex items-center gap-2 text-sm font-medium uppercase text-gray-500">
            <span>Custom Message</span>
            <Tooltip className="leading-none" label="Supplying a custom message below will replace the default message.">
              <i className="far fa-question-circle cursor-help text-lg leading-none text-gray-400"></i>
            </Tooltip>
          </div>

          <RichTextEditor value={note} height="h-[150px] overflow-y-scroll" theme="simple" onChange={(value) => setNote(value)} />
        </div>

        {(record?.approved_at || record?.denied_at) && record?.note && (
          <div className={classNames('rounded-md border border-gray-300 px-4 py-2', record.approved_at ? 'bg-yellow-100' : 'bg-red-100')}>
            <div className={classNames('text-base font-bold', record.approved_at ? 'text-yellow-700' : 'text-red-600')}>
              Custom message sent when {record.approved_at ? 'Approved' : 'Denied'}:
            </div>
            <div className="ProseMirror text-base" dangerouslySetInnerHTML={{ __html: record.note }}></div>
          </div>
        )}

        <div className="space-y-4 text-base">
          <Button theme="border" onClick={() => setShowStandardResponses(!showStandardResponses)}>
            {showStandardResponses ? 'Hide' : 'View'} Standard Responses
          </Button>

          {showStandardResponses && (
            <div className="rounded-md border border-gray-300 bg-white p-4">
              <p className="mb-4">
                <b>Note:</b> Each of the PROs shown above will receive a notification containing the following default text unless a Custom
                Message field is supplied.
              </p>

              <div>
                <div className="mb-1.5 flex items-center gap-2 text-sm font-medium uppercase text-blue-500">
                  <span>Approvals</span>
                </div>

                <Alert
                  heading={
                    <div className="space-y-3">
                      <p>
                        The <i>{record.related_service}</i> service has been approved as a related service for the{' '}
                        <i>{record.primary_service}</i> service.
                      </p>
                      <p>
                        You will now appear under searches for <i>{record.related_service}</i>.
                      </p>
                    </div>
                  }
                />
              </div>

              <div>
                <div className="mb-1.5 flex items-center gap-2 text-sm font-medium uppercase text-red-600">
                  <span>Denials</span>
                </div>

                <Alert
                  type="danger"
                  heading={
                    <div className="space-y-3">
                      <p>
                        After reviewing the <i>{record.related_service}</i> service as a related service for the{' '}
                        <i>{record.primary_service}</i> service, we have determined that it doesn't closely relate to your primary service.
                      </p>
                      <p>
                        Therefore, the <i>{record.related_service}</i> service is not allowed and has been removed from your services
                        provided.
                      </p>
                    </div>
                  }
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </Dialog>
  )
}
