import { useContext, useEffect, useRef, useState } from 'react'
import { AccordionContext } from '@/Shared/Accordion'
import Button from '@/Shared/Button'
import TextInput from '@/Shared/Forms/TextInput'

export default ({ user }) => {
  const focusRef = useRef()
  const { onSaved } = useContext(AccordionContext)
  const [data, setData] = useState({
    setting: 'password',
  })
  const [errors, setErrors] = useState({})

  useEffect(() => {
    focusRef.current.focus()
  }, [])

  const submit = (event) => {
    event.preventDefault()

    axios
      .post(route('api.users.update', user.id), { ...data, _method: 'PATCH' })
      .then((_) => onSaved())
      .catch((error) => {
        if (error.response != undefined) {
          const { status } = error.response
          const VALIDATION_ERROR = 422
          if (status == VALIDATION_ERROR) {
            setErrors(error.response.data.errors)
          }
        }
      })
  }

  return (
    <div>
      <form className="mx-auto mt-4 max-w-lg" onSubmit={submit}>
        <TextInput
          ref={focusRef}
          label="Current Password"
          name="current_password"
          type="password"
          error={errors.current_password}
          value={data.current_password}
          onChange={(value) => setData({ ...data, current_password: value })}
          required
        />

        <TextInput
          label="New Password"
          name="password"
          type="password"
          error={errors.password}
          value={data.password}
          onChange={(value) => setData({ ...data, password: value })}
          required
        />

        <TextInput
          label="Re-type Password"
          name="password_confirmation"
          type="password"
          error={errors.password_confirmation}
          value={data.password_confirmation}
          onChange={(value) => setData({ ...data, password_confirmation: value })}
          required
        />

        <div className="flex justify-end">
          <Button type="submit" theme="solid">
            Save
          </Button>
        </div>
      </form>
    </div>
  )
}
