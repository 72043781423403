import React, { useEffect, useRef, useState } from 'react'
import Button from '@/Shared/Button'
import Checkbox from '@/Shared/Forms/Checkbox'
import Heading from '@/Shared/Forms/Heading'
import Select from '@/Shared/Forms/Select'
import SlideOver from '@/Shared/SlideOver'
import TextInput from '@/Shared/Forms/TextInput'
import { useForm, usePage } from '@inertiajs/react'
import { router } from '@inertiajs/react'
import Helpers from '@/utils/helpers'

export default ({ open, record, type, onClosed }) => {
  const { contact } = usePage().props
  let focusRef = useRef()

  const form = useForm({
    create: false,
  })
  const { clearErrors, data, errors, setError, setData, post } = form
  const [relationships] = useState([
    { label: 'Wife', value: 'Wife' },
    { label: 'Husband', value: 'Husband' },
    { label: 'Fiancé', value: 'Fiancé' },
    { label: 'Spouse', value: 'Spouse' },
    { label: 'Partner', value: 'Partner' },
    { label: 'Mother', value: 'Mother' },
    { label: 'Father', value: 'Father' },
    { label: 'Sister', value: 'Sister' },
    { label: 'Brother', value: 'Brother' },
    { label: 'Daughter', value: 'Daughter' },
    { label: 'Son', value: 'Son' },
    { label: 'Aunt', value: 'Aunt' },
    { label: 'Uncle', value: 'Uncle' },
    { label: 'Niece', value: 'Niece' },
    { label: 'Nephew', value: 'Nephew' },
    { label: 'Grandmother', value: 'Grandmother' },
    { label: 'Grandfather', value: 'Grandfather' },
    { label: 'Granddaughter', value: 'Granddaughter' },
    { label: 'Grandson', value: 'Grandson' },
    { label: 'Girlfriend', value: 'Girlfriend' },
    { label: 'Boyfriend', value: 'Boyfriend' },
    { label: 'Significant Other', value: 'Significant Other' },
  ])

  useEffect(() => {
    if (open) {
      setData(loadData(record))
    }
  }, [open])

  function loadData(contact) {
    const defaultData = {
      type: 'Contact',
    }

    return {
      ...defaultData,
      ...(contact && {
        id: contact.id || null,
        type: contact.type || defaultData.type,
        name: contact.name ? Helpers.capitalize(contact.name) : '',
        label: contact.label ? Helpers.capitalize(contact.label) : '',
        related_label: contact.related_label ? Helpers.capitalize(contact.related_label) : '',
        create: contact.create || false,
        professional: type === 'Professional',
      }),
    }
  }

  const submit = (event) => {
    event.preventDefault()

    if (data.id) {
      axios
        .patch(route('api.contacts.relationships.update', { contact: contact.id, relationship: data.id }), {
          ...data,
        })
        .then((_) => {
          clearErrors()
          onClosed()
          router.reload()
        })
        .catch((error) => {
          if (error.response != undefined) {
            const { status } = error.response
            const VALIDATION_ERROR = 422
            if (status == VALIDATION_ERROR) {
              clearErrors()
              setError(error.response.data.errors)
            }
          }
        })
    } else {
      post(route('api.contacts.relationships.store', { contact: contact.id }), {
        onSuccess: () => {
          clearErrors()
          onClosed()
        },
      })
    }
  }

  return (
    <SlideOver
      focusRef={focusRef}
      footerActions={
        <Button type="submit" theme="solid" form="newContact">
          {data.id ? 'Save Changes' : 'Add'}
        </Button>
      }
      show={open}
      size="max-w-xl"
      title={data.id ? `Edit ${data.name}` : 'New'}
      subTitle={`${type} Contact`}
      onClosed={onClosed}
      aboveMessages
    >
      <form id="newContact" onSubmit={submit}>
        <div className="mb-5">
          <TextInput
            ref={focusRef}
            label="Name"
            name="name"
            error={errors.name}
            value={data.name}
            onChange={(value) => setData({ ...data, name: value })}
            onBlur={() => setData({ ...data, name: Helpers.capitalize(data.name) })}
          />

          <Heading>Relationship</Heading>

          <div className="mb-4">
            {data.professional || data.label ? (
              <TextInput
                classes="mb-0"
                label={
                  <div className="mb-2 flex items-end gap-2.5 py-1 text-base normal-case leading-none text-gray-500">
                    <span>
                      How is <span className="font-semibold">{data.name || '[Contact]'}</span> related to{' '}
                      <span className="font-semibold">{contact.full_name}</span>?
                    </span>
                  </div>
                }
                name="label"
                error={errors.label}
                placeholder={type === 'Professional' ? 'i.e. Client' : 'i.e. Wife, Brother, Friend, etc.'}
                value={data.label}
                onChange={(value) => setData({ ...data, label: value })}
                onBlur={() => setData({ ...data, label: Helpers.capitalize(data.label) })}
              />
            ) : (
              <Select
                classes="my-2 sm:flex-grow sm:mt-0"
                label={
                  <div className="mb-2 flex items-end gap-2.5 py-1 text-base normal-case leading-none text-gray-500">
                    <span>
                      How is <span className="font-semibold">{data.name || '[Contact]'}</span> related to{' '}
                      <span className="font-semibold">{contact.full_name}</span>?
                    </span>
                  </div>
                }
                name="label"
                options={relationships}
                placeholder={type === 'Professional' ? 'i.e. Client' : 'i.e. Wife, Brother, Friend, etc.'}
                value={data.label}
                isClearable={false}
                onChange={(selected) => setData({ ...data, label: selected && Helpers.capitalize(selected.label) })}
                creatable
              />
            )}
          </div>

          <div className="mb-4 flex justify-center">
            <Checkbox
              name={`create`}
              label="Save to My Contacts"
              value={data.create || false}
              onChange={(checked) => setData({ ...data, create: checked })}
            />
          </div>

          {data.create && (
            <div className="mb-4">
              {data.professional || data.related_label ? (
                <TextInput
                  classes="mb-0"
                  label={
                    <div className="mb-2 flex items-end gap-2.5 py-1 text-base normal-case leading-none">
                      <span>
                        How is <span className="font-semibold">{contact.full_name}</span> related to{' '}
                        <span className="font-semibold">{data.name || '[Contact]'}</span>?
                      </span>
                    </div>
                  }
                  name="related_label"
                  error={errors.related_label}
                  placeholder={type === 'Professional' ? 'i.e. Client' : 'i.e. Husband, Sister, Friend, etc.'}
                  value={data.related_label}
                  onChange={(value) => setData({ ...data, related_label: value })}
                  onBlur={() => setData({ ...data, related_label: Helpers.capitalize(data.related_label) })}
                />
              ) : (
                <Select
                  classes="my-2 sm:flex-grow sm:mt-0"
                  label={
                    <div className="mb-2 flex items-end gap-2.5 py-1 text-base normal-case leading-none">
                      <span>
                        How is <span className="font-semibold">{contact.full_name}</span> related to{' '}
                        <span className="font-semibold">{data.name || '[Contact]'}</span>?
                      </span>
                    </div>
                  }
                  name="related_label"
                  options={relationships}
                  placeholder={type === 'Professional' ? 'i.e. Client' : 'i.e. Husband, Sister, Friend, etc.'}
                  value={data.related_label}
                  isClearable={false}
                  onChange={(selected) => setData({ ...data, related_label: selected && Helpers.capitalize(selected.label) })}
                  creatable
                />
              )}
            </div>
          )}
        </div>
      </form>
    </SlideOver>
  )
}
