import Alert from '@/Shared/Alert'
import Checkbox from '@/Shared/Forms/Checkbox'
import Heading from '@/Shared/Forms/Heading'
import Helpers from '@/utils/helpers'
import TextInput from '@/Shared/Forms/TextInput'
import { Fragment } from 'react'

export default ({ associates, data, errors, onChanged, updateSplit }) => {
  const onBlur = (split) => {
    let formatted = Helpers.formatDecimal(Helpers.parseCurrency(split.client_rebate_deduction || 0), 2)

    if (split.client_rebate_deduction !== formatted) {
      updateSplit({ ...split, client_rebate_deduction: formatted })
    }
  }

  return (
    <div className="flex-1">
      <TextInput
        label="Rebate to Client Amount"
        name="client_rebate"
        icon={<i className="fas fa-dollar-sign"></i>}
        value={data.client_rebate}
        error={errors.client_rebate}
        onBlur={() => onChanged(Helpers.formatDecimal(Helpers.parseCurrency(data.client_rebate || 0), 2), 'client_rebate')}
        onChange={(value) => onChanged(Helpers.sanitizeCurrencyInput(value || 0), 'client_rebate')}
        onFocus={(e) => e.target.select()}
        clearable
      />

      {associates.length > 1 && (
        <Fragment>
          {data.splits && (
            <Heading>
              <div className="flex items-center gap-3">
                <span>Deducted from</span>
              </div>
            </Heading>
          )}

          <div className="mb-5 pt-2">
            <Checkbox
              name="client_rebate_auto_distribute"
              label="Distribute Automatically"
              description="Deducts the client rebate amount from all associates according to their transaction share %."
              value={data.client_rebate_auto_distribute}
              onChange={(checked) => onChanged(checked, 'client_rebate_auto_distribute')}
            />
          </div>

          {data.splits?.map((split, index) => (
            <div key={index}>
              <TextInput
                label={
                  <span className="font-bold text-black">
                    {split.name} {data.client_rebate_auto_distribute && <span>({split.percent}%)</span>}
                  </span>
                }
                name={`client_rebate_deduction_${index}`}
                icon={<i className="fas fa-dollar-sign"></i>}
                value={split.client_rebate_deduction}
                onBlur={() => onBlur(split)}
                onChange={(value) => updateSplit({ ...split, client_rebate_deduction: Helpers.sanitizeCurrencyInput(value || 0) })}
                onFocus={(e) => e.target.select()}
                clearable
                disabled={data.client_rebate_auto_distribute}
              />
            </div>
          ))}

          {errors.client_rebate_not_deducted && <Alert heading={errors.client_rebate_not_deducted} type="danger" />}
        </Fragment>
      )}
    </div>
  )
}
