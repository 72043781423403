import { useState } from 'react'
import Button from '@/Shared/Button'
import classNames from 'classnames'
import TextInput from '@/Shared/Forms/TextInput'
import { Tooltip } from '@/Shared/Tooltip'

const ListBox = ({ label, options, search, selected, onSearch, onSelected }) => {
  const [lastSelectedIndex, setLastSelectedIndex] = useState(null)

  const onOptionSelected = (event, index) => {
    if (event.shiftKey) {
      let selectedItems = []
      let start = lastSelectedIndex > index ? index : lastSelectedIndex
      let end = lastSelectedIndex > index ? lastSelectedIndex : index

      for (let i = start; i <= end; i++) {
        selectedItems = selectedItems.concat(i)
      }
      onSelected(selectedItems)
    } else if (event.metaKey) {
      let exists = selected.find((s) => s == index)
      if (exists) {
        onSelected(selected.filter((s) => s !== index))
      } else {
        onSelected(selected.concat(index))
      }
      setLastSelectedIndex(index)
    } else {
      onSelected([].concat(index))
      setLastSelectedIndex(index)
    }
  }

  return (
    <div className="flex-1">
      <div className="text-md text-center font-semibold text-gray-500">{label}</div>
      <div className="relative mb-1 flex w-full flex-grow">
        <TextInput
          classes="flex-grow"
          inputClasses="px-4 pr-14 py-2"
          placeholder="i.e. Boston, MA"
          value={search}
          onChange={(value) => onSearch(value)}
        />

        <span className={classNames('absolute inset-y-0 flex w-10 justify-center', search ? 'right-2' : 'right-0')}>
          <span className="flex h-full items-center justify-center">
            {search ? (
              <Button theme="icon-sm" onClick={() => onSearch(null)}>
                <i className="fal fa-times text-lg"></i>
              </Button>
            ) : (
              <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <rect id="bound" x="0" y="0" width="24" height="24"></rect>
                  <path
                    className="fill-current text-primary-500"
                    d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                    id="Path-2"
                    fillRule="nonzero"
                    opacity="0.3"
                  ></path>
                  <path
                    className="fill-current text-primary-500"
                    d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                    id="Path"
                    fillRule="nonzero"
                  ></path>
                </g>
              </svg>
            )}
          </span>
        </span>
      </div>

      <ul
        className="font-md transition-border px-4py-2 block max-h-250px min-h-250px w-full overflow-y-scroll rounded border border-gray-300 bg-white placeholder-gray-400 outline-none duration-150 ease-in-out focus:outline-none sm:max-h-350px sm:min-h-350px"
        role="listbox"
        aria-orientation="vertical"
        tabIndex={0}
      >
        {options.map((option, index) => (
          <li
            className={classNames(
              'relative cursor-default select-none px-3 py-2',
              { 'font-bold': option.label.includes('All of') },
              selected.indexOf(index) >= 0 ? 'bg-primary-500 text-white' : ' hover:bg-gray-100',
            )}
            role="option"
            tabIndex={-1}
            onClick={(event) => onOptionSelected(event, index)}
            key={option.value}
          >
            {option.label}
          </li>
        ))}
      </ul>
    </div>
  )
}

export default ({
  availableLabel,
  available,
  searchAvailable,
  searchSelected,
  selected,
  selectedLabel,
  onAdd,
  onAddAll,
  onRemove,
  onRemoveAll,
  onSearchAvailable,
  onSearchSelected,
}) => {
  const [availableHighlighted, setAvailableHighlighted] = useState([])
  const [selectedHighlighted, setSelectedHighlighted] = useState([])

  const add = () => {
    if (availableHighlighted.length > 0) {
      onAdd(availableHighlighted)
    }

    setAvailableHighlighted([])
  }

  const addAll = () => {
    onAddAll()

    setAvailableHighlighted([])
  }

  const remove = () => {
    if (selectedHighlighted.length > 0) {
      onRemove(selectedHighlighted)
    }

    setSelectedHighlighted([])
  }

  const removeAll = () => {
    onRemoveAll()

    setSelectedHighlighted([])
  }

  return (
    <div className="pt-2 sm:flex sm:justify-between sm:gap-4">
      <ListBox
        label={availableLabel || 'Available'}
        options={available}
        search={searchAvailable}
        selected={availableHighlighted}
        onSearch={onSearchAvailable}
        onSelected={(selected) => {
          setSelectedHighlighted([])
          setAvailableHighlighted(selected)
        }}
      />

      <div className="my-2 flex justify-center sm:my-0 sm:mt-63px">
        <div className="-ml-2 flex items-center justify-center sm:flex-col sm:space-y-4">
          <Tooltip label="Add all">
            <Button theme="icon" onClick={() => addAll()}>
              <i className="fas fa-chevron-double-right rotate-90 text-xl sm:rotate-0"></i>
            </Button>
          </Tooltip>

          <Tooltip label="Add selected">
            <Button theme="icon" onClick={() => add()}>
              <i className="fas fa-arrow-right rotate-90 text-xl sm:rotate-0"></i>
            </Button>
          </Tooltip>

          <Tooltip label="Remove selected">
            <Button theme="icon" onClick={() => remove()}>
              <i className="fas fa-arrow-left rotate-90 text-xl sm:rotate-0"></i>
            </Button>
          </Tooltip>

          <Tooltip label="Remove all">
            <Button theme="icon" onClick={() => removeAll()}>
              <i className="fas fa-chevron-double-left rotate-90 text-xl sm:rotate-0"></i>
            </Button>
          </Tooltip>
        </div>
      </div>

      <ListBox
        label={selectedLabel || 'Selected'}
        options={selected}
        search={searchSelected}
        selected={selectedHighlighted}
        onSearch={onSearchSelected}
        onSelected={(selected) => {
          setAvailableHighlighted([])
          setSelectedHighlighted(selected)
        }}
      />
    </div>
  )
}
