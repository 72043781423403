import { Fragment, createRef, useEffect, useRef, useState } from 'react'
import { useForm, usePage } from '@inertiajs/react'
import { router } from '@inertiajs/react'
import Button from '@/Shared/Button'
import Checkbox from '@/Shared/Forms/Checkbox'
import Dialog from '@/Shared/Dialog/Index'
import RichTextEditor from '@/Shared/RichTextEditor/Index'
import Select from '@/Shared/Forms/Select'
import TextInput from '@/Shared/Forms/TextInput'

export default ({ children, open, record, onClosed, onDeleting, onRestoring }) => {
  let focusRef = useRef(),
    scrollRef = useRef(),
    ccRef = useRef(),
    bccRef = useRef()
  const attachmentRef = createRef()
  const { categories, fileTypes, recipients, shortcodes, states } = usePage().props
  const form = useForm({
    id: null,
    category_id: null,
    name: '',
    subject: '',
    to: [],
    cc: [],
    bcc: [],
    content: '',
    active: true,
    transaction_files: [],
    attachments: [],
    states: [],
  })

  const { clearErrors, data, errors, setData, setError } = form
  const [content, setContent] = useState('')
  const [subject, setSubject] = useState('')
  const [ccOpen, setCcOpen] = useState(false)
  const [bccOpen, setBccOpen] = useState(false)

  useEffect(() => {
    if (record) {
      setData({
        id: record.id,
        category_id: record.category_id,
        to: record.to,
        cc: record.cc,
        bcc: record.bcc,
        name: record.name,
        subject: record.subject,
        content: record.content,
        active: record.id ? record.active : true,
        transaction_files: record.files?.length > 0 ? fileTypes.filter((type) => record.files.find((f) => type.id == f.id)) : [],
        attachments: record.attachments || [],
        states: record.states?.length > 0 ? states.filter((state) => record.states.find((s) => state.value == s.state)) : null,
      })

      setCcOpen(record.cc?.length > 0)
      setBccOpen(record.bcc?.length > 0)

      setContent(record.content)
      setSubject(record.subject)
    }
  }, [record])

  useEffect(() => {
    if (content != data.content) {
      setData({ ...data, content: content })
    }
  }, [content])

  useEffect(() => {
    if (subject != data.subject) {
      setData({ ...data, subject: subject })
    }
  }, [subject])

  const getReadableFileSizeString = (Num = 0, dec = 1) => {
    if (Num < 1000) return Num + ' Bytes'
    Num = ('0'.repeat(((Num += '').length * 2) % 3) + Num).match(/.{3}/g)
    return Number(Num[0]) + '.' + Num[1].substring(0, dec) + '  KMGTPEZY'[Num.length]
  }

  const onAttachmentsChanged = (event) => {
    setData({ ...data, attachments: data.attachments.concat(Array.from(event.target.files)) })

    setTimeout(() => {
      scrollRef.current.scrollTo({ top: scrollRef.current.scrollHeight })
    }, 100)
  }

  const onClosing = () => {
    clearErrors()
    onClosed()
  }

  const removeAttachment = (index) => {
    let attachments = data.attachments
    attachments.splice(index, 1)
    setData({ ...data, attachments: attachments })
  }

  const submit = (event) => {
    event.preventDefault()

    router.post(
      data.id ? route('admin.email-templates.update', data.id) : route('admin.email-templates.store'),
      data.id ? { ...data, _method: 'put' } : data,
      {
        forceFormData: true,
        onSuccess: () => {
          onClosing()
        },
        onError: (errors) => {
          setError(errors)
        },
      },
    )
  }

  return (
    <Dialog
      ref={scrollRef}
      focusRef={focusRef}
      footerActionsLeft={
        record && !Boolean(record.deleted_at) ? (
          <Fragment>
            {record.id && (
              <button
                role="button"
                className="group relative inline-flex h-10 w-10 items-center justify-center rounded leading-none transition-all duration-150 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-red-600"
                onClick={() => onDeleting()}
              >
                <span className="sr-only">Delete Email Template</span>
                <i className="fas fa-trash text-xl text-gray-800 group-hover:text-red-600"></i>
              </button>
            )}
          </Fragment>
        ) : (
          <Button theme="outline" onClick={() => onRestoring()}>
            <i className="fas fa-trash-restore mr-2 text-lg text-gray-600 group-hover:text-gray-900"></i>
            Restore
          </Button>
        )
      }
      footerActions={
        <Button form="emailTemplateForm" type="submit" theme="solid">
          Save
        </Button>
      }
      position="top"
      show={open}
      size="sm:max-w-5xl"
      title={record.name == undefined ? 'New Email Template' : `Edit ${record.name}`}
      onClosed={onClosing}
    >
      <form id="emailTemplateForm" className="flex h-full flex-col" onSubmit={submit}>
        <div className="sm:flex sm:gap-4">
          <div className="sm:flex-1">
            <Select
              ref={focusRef}
              name="label"
              label="Category"
              error={errors.category_id}
              options={categories}
              placeholder="Category"
              value={data.category_id}
              isClearable={false}
              onChange={(selected) => setData({ ...data, category_id: selected?.value })}
              required
            />
          </div>

          <div className="sm:flex-1">
            <Select
              name="label"
              label="States Available In"
              error={errors.states}
              options={states}
              placeholder="Any State"
              value={data.states}
              onChange={(selected) => setData({ ...data, states: selected })}
              multiple
            />
          </div>

          <div className="mb-4 sm:mb-0 sm:flex sm:flex-1 sm:justify-end">
            <Checkbox name="active" label="Active" value={data.active} onChange={(selected) => setData({ ...data, active: selected })} />
          </div>
        </div>

        <Select
          name="to"
          label={
            <div className="flex items-center justify-between gap-6">
              <label htmlFor="to">To</label>
              <div className="-my-1 ml-4 shrink-0">
                {!ccOpen && (
                  <button
                    type="button"
                    className="rounded-md px-1.5 py-0.5 text-base font-medium uppercase text-blue-500 hover:bg-blue-100 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    onClick={() => {
                      setCcOpen(true)
                      setTimeout(() => {
                        ccRef.current.focus()
                      }, 300)
                    }}
                  >
                    Cc
                  </button>
                )}
                {!bccOpen && (
                  <button
                    type="button"
                    className="ml-3 rounded-md px-1.5 py-0.5 text-base font-medium uppercase text-blue-500 hover:bg-blue-100 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    onClick={() => {
                      setBccOpen(true)
                      setTimeout(() => {
                        bccRef.current.focus()
                      }, 300)
                    }}
                  >
                    Bcc
                  </button>
                )}
              </div>
            </div>
          }
          error={errors.to}
          options={recipients}
          placeholder="Recipients"
          value={data.to}
          isClearable={false}
          onChange={(selected) => setData({ ...data, to: selected })}
          multiple
        />

        {ccOpen && (
          <Select
            ref={ccRef}
            name="cc"
            label="CC"
            error={errors.cc}
            options={recipients}
            placeholder="Recipients"
            value={data.cc}
            isClearable={false}
            onChange={(selected) => setData({ ...data, cc: selected })}
            multiple
          />
        )}

        {bccOpen && (
          <Select
            ref={bccRef}
            name="bcc"
            label="BCC"
            error={errors.bcc}
            options={recipients}
            placeholder="Recipients"
            value={data.bcc}
            isClearable={false}
            onChange={(selected) => setData({ ...data, bcc: selected })}
            multiple
          />
        )}

        <TextInput
          label="Name"
          name="name"
          error={errors.name}
          value={data.name}
          onChange={(value) => setData({ ...data, name: value })}
          required
        />

        <div className="pb-3">
          <label className="text-sm font-medium uppercase text-gray-700">Subject</label>
          <RichTextEditor
            value={subject}
            height="min-h-full"
            shortcodes={shortcodes}
            onChange={(value) => setSubject(value)}
            hideFormatting
          />

          {errors.subject && <div className="mt-1 text-red-600">{errors.subject}</div>}
        </div>

        <div className="pb-3">
          <label className="text-sm font-medium uppercase text-gray-700">Body</label>
          <RichTextEditor
            value={content}
            height="h-300px overflow-y-scroll"
            theme="checklist"
            shortcodes={shortcodes}
            onChange={(value) => setContent(value)}
          />

          {errors.content && <div className="mt-1 text-red-600">{errors.content}</div>}
        </div>

        <div className="mt-3" ref={attachmentRef}>
          <label className="mb-1 block text-sm font-medium uppercase text-gray-700">Attachments</label>

          <div className="mb-4 flex items-center gap-4">
            <div className="sm:w-1/2">
              <Select
                name="label"
                className="mb-0"
                error={errors.transaction_files}
                options={fileTypes}
                value={data.transaction_files}
                placeholder="Select files"
                placement="top"
                multiple
                onChange={(selected) => setData({ ...data, transaction_files: selected })}
              />
            </div>

            <div className="font-bold">or</div>

            <div className="flex-1">
              <label
                htmlFor="attachments"
                className="group relative inline-flex h-10 cursor-pointer items-center gap-2 rounded-md border border-gray-300 bg-white px-2 font-medium leading-none text-gray-700 shadow-sm outline-none transition-all duration-150 ease-in-out hover:bg-gray-200 hover:text-gray-800 focus:border-transparent focus:ring-2 focus:ring-primary-500 xl:px-6"
              >
                <i className="fal fa-arrow-up-from-bracket text-lg"></i>
                <span>Upload</span>
                <input id="attachments" name="attachments" type="file" className="sr-only" multiple onChange={onAttachmentsChanged}></input>
              </label>
            </div>
          </div>

          {data.attachments.length > 0 ? (
            <div className="max-w-xl space-y-3">
              {data.attachments.map((file, index) => (
                <div className="flex items-center justify-between bg-gray-100 px-3 py-2" key={index}>
                  <div className="font-semibold">
                    {file.name}
                    <span className="ml-1 text-gray-600">
                      ({file.size ? getReadableFileSizeString(file.size) : <span className="italic text-red-500">File unavailable</span>})
                    </span>
                  </div>
                  <div>
                    <button
                      type="button"
                      className="flex h-5 items-center justify-center rounded px-1 outline-none hover:bg-green-500 hover:text-white focus:bg-green-100 focus:text-blue-500 focus:ring-2 focus:ring-inset focus:ring-blue-500"
                      onClick={() => removeAttachment(index)}
                    >
                      <i className="far fa-times text-lg"></i>
                    </button>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="rounded-md border border-gray-200 bg-gray-100 p-2 text-center">No files have been uploaded.</div>
          )}
        </div>
      </form>

      {children}
    </Dialog>
  )
}
