import React, { useRef, useState } from 'react'
import { useForm } from '@inertiajs/react'
import Button from '@/Shared/Button'
import SlideOver from '@/Shared/SlideOver'
import TextInput from '@/Shared/Forms/TextInput'

export default ({ open, onClosed }) => {
  let focusRef = useRef()
  const form = useForm({
    first_name: '',
    last_name: '',
    email: '',
  })
  const { clearErrors, data, errors, setData, post, processing, reset } = form

  const submit = (event) => {
    event.preventDefault()

    post(route('pros.store'), {
      onSuccess: () => {
        clearErrors()
        reset()
        onClosed()
      },
    })
  }

  return (
    <SlideOver
      focusRef={focusRef}
      footerActions={
        <Button type="submit" theme="solid" form="newChecklist" disabled={processing}>
          <i className="fas fa-envelope mr-2 text-lg"></i>
          Send Invitation Email
        </Button>
      }
      show={open}
      size="max-w-lg"
      title="Invite a PRO"
      onClosed={onClosed}
    >
      <form id="newChecklist" className="mb-5 flex flex-col" onSubmit={submit}>
        <TextInput
          ref={focusRef}
          label="First name"
          name="first_name"
          value={data.first_name}
          error={errors.first_name}
          onChange={(value) => setData({ ...data, first_name: value })}
          required
        />

        <TextInput
          label="Last name"
          name="last_name"
          value={data.last_name}
          error={errors.last_name}
          onChange={(value) => setData({ ...data, last_name: value })}
          required
        />

        <TextInput
          label="Email"
          name="email"
          value={data.email}
          error={errors.email}
          onChange={(value) => setData({ ...data, email: value })}
          required
        />
      </form>
    </SlideOver>
  )
}
