import { createContext, useEffect, useState } from 'react'
import { usePage } from '@inertiajs/react'

export const DatesContext = createContext({
  dates: [],
  fillDates: () => {},
  transformDates: (data) => {},
  updateDate: (newDate, currentDate) => {},
})

export const DatesProvider = ({ children }) => {
  const { transaction } = usePage().props
  const [dates, setDates] = useState([])

  const fillDates = () => {
    return dates.reduce((acc, date) => {
      acc[date.name] = transaction[date.name] ? new Date(transaction[date.name]) : null
      return acc
    }, {})
  }

  const transformDates = (data) =>
    dates.reduce((acc, date) => {
      if (data[date.name]) {
        acc[date.name] = data[date.name]?.toLocaleString('en-US', { timeZone: 'America/New_York' }) || null
      }
      return acc
    }, {})

  const updateDate = (newDate, currentDate) => {
    if (!newDate) {
      return currentDate
    } else if (!currentDate) {
      newDate.setHours(9)
      newDate.setMinutes(0)
      newDate.setSeconds(0)
      return newDate
    }

    let datetime = new Date(newDate)
    datetime.setHours(currentDate.getHours())
    datetime.setMinutes(currentDate.getMinutes())
    datetime.setSeconds(0)

    return datetime
  }

  const context = {
    dates,
    fillDates,
    transformDates,
    updateDate,
  }

  useEffect(() => {
    if (transaction) {
      setDates([
        ...[{ name: 'hiring_date', label: 'Hiring / Engagement Date', type: 'date' }],
        ...(transaction?.states?.find((state) => state.value === 'MA')
          ? [{ name: 'offer_to_purchase_date', label: 'Offer to Purchase Date', type: 'date' }]
          : []),
        ...[
          { name: 'purchase_and_sale_date', label: 'Purchase and Sale Date', type: 'date' },
          { name: 'closing_at', label: 'Closing Date', type: 'date' },
          { name: 'home_inspected_at', label: 'Home Inspection Date', type: 'datetime' },
          { name: 'home_inspection_contingency_date', label: 'Home Inspection Contingency Date', type: 'date' },
          { name: 'reinspection_date', label: 'Re-inspection Date', type: 'datetime' },
          { name: 'smoke_detector_certification_date', label: 'Smoke Detector Certification Date', type: 'date' },
          { name: 'final_water_reading_date', label: 'Final Water Reading Date', type: 'date' },
          {
            name: 'deadline_for_sale_of_buyers_property_contingency_date',
            label: 'Deadline for Sale of Buyer’s Property Contingency',
            type: 'date',
          },
          {
            name: 'deadline_for_sellers_suitable_housing_contingency_date',
            label: 'Deadline for Seller’s Suitable Housing Contingency',
            type: 'date',
          },
          {
            name: 'deadline_for_receipt_review_of_condo_documents_date',
            label: 'Deadline for Receipt and Review of Condo Documents',
            type: 'date',
          },
          { name: 'appraisal_date', label: 'Appraisal Date', type: 'date' },
          { name: 'mortgage_commitment_date', label: 'Mortage Commitment Date', type: 'date' },
          { name: 'walk_through_date', label: 'Walk Through Date', type: 'datetime' },
        ],
      ])
    }
  }, [transaction])

  return <DatesContext.Provider value={context}>{children}</DatesContext.Provider>
}

export const DatesConsumer = DatesContext.Consumer
