import Avatar from '@/Shared/Avatar'
import Dropdown from '@/Shared/Dropdown'
import DropdownItem from '@/Shared/DropdownItem'
import { router } from '@inertiajs/react'

export default ({ contact, followed }) => {
  const onStopFollowing = () => {
    router.post(route('contacts.unfollow'), {
      id: contact.id,
      follower_id: followed.id,
    })
  }

  return (
    <div className="block rounded-b-lg rounded-t-lg border border-gray-500 bg-white shadow">
      <div className="relative z-10 flex items-start justify-start px-4 py-3">
        <div className="flex flex-grow flex-wrap items-center justify-center text-left">
          <div className="space-y-1">
            <Avatar contact={followed} height="h-10" width="w-10" />

            <div className="flex items-center justify-center leading-none">
              <div className="mt-0.5 inline-flex items-center whitespace-nowrap rounded-full bg-purple-100 px-1.5 py-1.5 text-xs font-medium uppercase leading-none text-purple-800">
                <span>PRO</span>
              </div>
            </div>
          </div>

          <div className="mx-3 flex-1 leading-snug text-gray-600 sm:mb-0">
            <div className="space-x-2 font-semibold">{followed.name}</div>

            {followed.industry && <div>{followed.industry}</div>}
            {followed.company && <div>{followed.company}</div>}
          </div>

          <div className="-ml-1.5">
            <Dropdown
              id="note-options"
              label={<i className="fas fa-ellipsis-v text-xl text-gray-400 group-hover:text-gray-800"></i>}
              orientation="right"
              icon
            >
              <DropdownItem onClick={() => window.open(followed.permalink)}>
                <i className="far fa-external-link mr-4 text-gray-600 hover:text-gray-700 group-hover:font-bold group-hover:text-primary-500"></i>
                View Website
              </DropdownItem>

              <DropdownItem colorClasses="text-red-500 hover:text-red-600" onClick={() => onStopFollowing()}>
                <i className="fas fa-link-slash mr-4"></i>
                Stop Following
              </DropdownItem>
            </Dropdown>
          </div>
        </div>
      </div>
    </div>
  )
}
