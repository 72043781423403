import { Fragment, useEffect, useState } from 'react'
import Button from '@/Shared/Button'
import classNames from 'classnames'
import NoteDialog from '@/Shared/NoteDialog'
import Timeline from '@/Shared/Timeline/Index'

export default ({ open, transaction, onBack, onClose }) => {
  const [noteDialogOpen, setNoteDialogOpen] = useState(false)
  const [note, setNote] = useState(null)

  useEffect(() => {
    if (note != null) {
      setNoteDialogOpen(true)
    }
  }, [note])

  return (
    <div
      className={classNames(
        'fixed inset-y-0 right-0 z-30 h-full w-full overflow-y-auto bg-gray-100 transition duration-300 ease-in-out',
        open ? 'translate-x-0' : 'translate-x-full',
      )}
    >
      <div className="sticky top-0 z-20 shadow-md">
        <div className="bg-blue-700 py-2.5 pl-4 pr-3 text-white sm:pl-6">
          <div className="flex items-center justify-between">
            <button className="inline-flex h-10 items-center leading-none text-white outline-none" type="button" onClick={onBack}>
              <span className="sr-only">Back</span>
              <i className="far fa-chevron-left mr-3 text-xl leading-none"></i>
              <span className="font-medium">Menu</span>
            </button>

            <div className="ml-3 flex h-10 items-center">
              <Button theme="toolbar-icon" onClick={onClose}>
                <span className="sr-only">Close</span>
                <i className="fal fa-times text-2xl leading-none text-opacity-80"></i>
              </Button>
            </div>
          </div>
        </div>

        <div className="bg-white px-3 py-3 sm:px-6">
          <div className="text-center text-xl">
            <span>{transaction.name}</span>
          </div>
        </div>
      </div>

      <div className="p-4 md:p-6">
        <div className="mb-2 flex items-center justify-between gap-4">
          <div className="flex flex-1 items-center gap-2">
            <div className="py-1 pl-3 text-2xl font-medium tracking-wider text-gray-800">Notes</div>
          </div>

          <div className="flex h-14 items-center justify-end">
            <Button theme="solid" onClick={() => setNote({ type: 'Note' })}>
              <i className="fas fa-note-medical mr-2 text-lg"></i>
              <span className="pr-1">New Note</span>
            </Button>
          </div>
        </div>

        <Timeline
          type="note"
          emptyText={
            <div className="space-y-1">
              <i className="far fa-note-sticky text-3xl text-gray-400"></i>
              <p>No notes have been added to this transaction.</p>
            </div>
          }
        />
      </div>

      {note && (
        <NoteDialog
          open={noteDialogOpen}
          id={transaction.id}
          type="Transaction"
          noteOnly={false}
          record={note}
          onClosed={() => {
            setNoteDialogOpen(false)
            setTimeout(() => {
              setNote(null)
            }, 150)
          }}
        />
      )}
    </div>
  )
}
