import { Fragment, useEffect, useState } from 'react'
import classNames from 'classnames'
import { useEditor, EditorContent } from '@tiptap/react'
import { Color } from '@tiptap/extension-color'
import Document from '@tiptap/extension-document'
import Highlight from '@tiptap/extension-highlight'
import Link from '@tiptap/extension-link'
import Paragraph from '@tiptap/extension-paragraph'
import Select from '@/Shared/Forms/Select'
import StarterKit from '@tiptap/starter-kit'
import Text from '@tiptap/extension-text'
import TextAlign from '@tiptap/extension-text-align'
import TextStyle from '@tiptap/extension-text-style'
import Underline from '@tiptap/extension-underline'
import { Variable } from './Variable'
import './styles.scss'
import { DOMParser as ProseMirrorDOMParser, Fragment as Fragment2 } from 'prosemirror-model'

const MenuBar = ({ editor, hideFormatting, shortcodes, theme }) => {
  if (!editor || (hideFormatting && !shortcodes)) {
    return null
  }

  const classes =
    'inline-flex items-center justify-center relative px-2 mx-1 w-10 h-10 font-medium leading-none rounded outline-none transition-all duration-150 ease-in-out hover:bg-gray-200 hover:text-gray-700 focus:ring-2 focus:ring-primary-500'
  const highlightedStyle = 'bg-gray-300 text-gray-700'
  const defaultStyle = 'text-gray-700'
  const sectionStyle = 'py-1 px-0.5 border-r border-gray-200 border-opacity-75'

  const withHttp = (url) => (!/^https?:\/\//i.test(url) ? `http://${url}` : url)
  const setLink = () => {
    const url = window.prompt('URL')
    editor
      .chain()
      .focus()
      .extendMarkRange('link')
      .setLink({ href: withHttp(url) })
      .run()
  }

  return (
    <div className="relative rounded-t border border-gray-300 bg-gray-50">
      {!hideFormatting && (
        <Fragment>
          <div className="md:inline-flex md:flex-wrap md:items-center md:border-b md:border-gray-200">
            <div className="inline-flex items-center border-b border-gray-200 bg-white md:border-0">
              {['checklist', 'full'].indexOf(theme) >= 0 && (
                <div className={sectionStyle}>
                  <button type="button" className={classes} onClick={() => editor.chain().focus().undo().run()} title="Undo">
                    <i className="fas fa-undo"></i>
                  </button>

                  <button type="button" className={classes} title="Redo" onClick={() => editor.chain().focus().redo().run()}>
                    <i className="fas fa-redo"></i>
                  </button>
                </div>
              )}

              {['checklist', 'full', 'simple'].indexOf(theme) >= 0 && (
                <div className={sectionStyle}>
                  <button
                    type="button"
                    className={classNames(classes, editor.isActive('bold') ? highlightedStyle : defaultStyle)}
                    title="Bold"
                    onClick={() => editor.chain().focus().toggleBold().run()}
                  >
                    <i className="fas fa-bold"></i>
                  </button>
                  <button
                    type="button"
                    className={classNames(classes, editor.isActive('italic') ? highlightedStyle : defaultStyle)}
                    title="Italicize"
                    onClick={() => editor.chain().focus().toggleItalic().run()}
                  >
                    <i className="fas fa-italic"></i>
                  </button>
                  <button
                    type="button"
                    className={classNames(classes, editor.isActive('underline') ? highlightedStyle : defaultStyle)}
                    title="Underline"
                    onClick={() => editor.chain().focus().toggleUnderline().run()}
                  >
                    <i className="fas fa-underline"></i>
                  </button>
                  <button
                    type="button"
                    className={classNames(classes, editor.isActive('strike') ? highlightedStyle : defaultStyle)}
                    title="Strike"
                    onClick={() => editor.chain().focus().toggleStrike().run()}
                  >
                    <i className="fas fa-strikethrough"></i>
                  </button>
                </div>
              )}
            </div>

            {['checklist', 'full', 'link', 'simple'].indexOf(theme) >= 0 && (
              <div className="inline-flex items-center border-b border-gray-200 bg-white md:border-0">
                <div className={sectionStyle}>
                  {['checklist'].indexOf(theme) >= 0 && (
                    <>
                      <button
                        type="button"
                        className={classNames(
                          classes,
                          editor.isActive({ textStyle: { color: '#ff0000' } }) ? highlightedStyle : defaultStyle,
                        )}
                        title="Highlight selected"
                        onClick={() => editor.chain().focus().setColor('#ff0000').run()}
                      >
                        <i className="fas fa-text" style={{ color: '#ff0000' }}></i>
                      </button>
                      <button
                        type="button"
                        className={classNames(classes, editor.isActive('highlight') ? highlightedStyle : defaultStyle)}
                        title="Highlight selected"
                        onClick={() => editor.chain().focus().toggleHighlight().run()}
                      >
                        <i className="fas fa-highlighter"></i>
                      </button>
                    </>
                  )}
                  <button
                    type="button"
                    className={classes}
                    title="Clear Formatting"
                    onClick={() => editor.chain().focus().unsetAllMarks().run()}
                  >
                    <i className="fas fa-remove-format"></i>
                  </button>
                </div>

                <div className={sectionStyle}>
                  <button
                    type="button"
                    className={classNames(classes, editor.isActive('link') ? highlightedStyle : defaultStyle)}
                    title="Link"
                    onClick={setLink}
                  >
                    <i className="fas fa-link"></i>
                  </button>

                  <button type="button" className={classes} title="Remove link" onClick={() => editor.chain().focus().unsetLink().run()}>
                    <i className="fas fa-unlink"></i>
                  </button>
                </div>
              </div>
            )}

            <div className="inline-flex items-center border-b border-gray-200 bg-white md:border-0">
              {['checklist', 'full'].indexOf(theme) >= 0 && (
                <div className={sectionStyle}>
                  <button
                    type="button"
                    className={classNames(classes, editor.isActive({ textAlign: 'left' }) ? highlightedStyle : defaultStyle)}
                    title="Align left"
                    onClick={() => editor.chain().focus().setTextAlign('left').run()}
                  >
                    <i className="fas fa-align-left"></i>
                  </button>
                  <button
                    type="button"
                    className={classNames(classes, editor.isActive({ textAlign: 'center' }) ? highlightedStyle : defaultStyle)}
                    title="Align center"
                    onClick={() => editor.chain().focus().setTextAlign('center').run()}
                  >
                    <i className="fas fa-align-center"></i>
                  </button>
                  <button
                    type="button"
                    className={classNames(classes, editor.isActive({ textAlign: 'right' }) ? highlightedStyle : defaultStyle)}
                    title="Align right"
                    onClick={() => editor.chain().focus().setTextAlign('right').run()}
                  >
                    <i className="fas fa-align-right"></i>
                  </button>
                </div>
              )}

              {['checklist', 'full', 'simple'].indexOf(theme) >= 0 && (
                <div className={sectionStyle}>
                  <button
                    type="button"
                    className={classNames(classes, editor.isActive('bulletList') ? highlightedStyle : defaultStyle)}
                    title="Bullets"
                    onClick={() => editor.chain().focus().toggleBulletList().run()}
                  >
                    <i className="fas fa-list-ul"></i>
                  </button>
                  <button
                    type="button"
                    className={classNames(classes, editor.isActive('orderedList') ? highlightedStyle : defaultStyle)}
                    title="Numbered List"
                    onClick={() => editor.chain().focus().toggleOrderedList().run()}
                  >
                    <i className="fas fa-list-ol"></i>
                  </button>
                </div>
              )}
            </div>
          </div>
        </Fragment>
      )}

      {shortcodes && (
        <div className="my-1.5 w-full px-0.5">
          <Select
            classes="inline-flex mb-0 px-1 w-full max-w-xl"
            height="2.575rem"
            minHeight="auto"
            options={shortcodes}
            placeholder="Shortcodes"
            onChange={(selected) => editor.chain().focus().setVariable({ label: selected.value }).run()}
          />
        </div>
      )}
    </div>
  )
}

const RichTextEditor = ({ autoFocus, value, height, hideFormatting, shortcodes, theme, onChange, ...props }) => {
  const editor = useEditor({
    autofocus: autoFocus || false,
    extensions: hideFormatting
      ? [Color, Document, Link, Paragraph, Text, Variable]
      : theme === 'simple'
        ? [Document, Link, Paragraph, StarterKit, Text, Underline]
        : [
            Color,
            Highlight,
            Link,
            StarterKit,
            TextAlign.configure({
              types: ['paragraph'],
            }),
            TextStyle,
            Underline,
            Variable,
          ],
    editorProps: {
      attributes: {
        class:
          'ProseMirror prose text-lg max-w-full px-4 py-2 ' +
          `${height || 'min-h-75px'} ` +
          'bg-white border-r border-b border-l border-gray-300 rounded-b outline-none font-md transition-border duration-150 ease-in-out placeholder-gray-400 ',
      },
      handlePaste(view, event) {
        event.preventDefault()

        // Get the pasted content as HTML
        const pasteHtml = (event.clipboardData || window.clipboardData).getData('text/html')

        if (!pasteHtml) {
          // If HTML is not available, fall back to plain text
          const pasteText = (event.clipboardData || window.clipboardData).getData('text/plain')
          editor.commands.insertContent(pasteText)
          return true
        }

        // Clean up the HTML to remove unwanted styles
        const cleanHTML = removeStylesFromHTML(pasteHtml)

        // Parse the cleaned HTML into a ProseMirror DocumentFragment
        const doc = new DOMParser().parseFromString(cleanHTML, 'text/html')
        let fragment = ProseMirrorDOMParser.fromSchema(editor.schema).parse(doc.body)

        // Remove empty paragraphs (trailing break elements)
        fragment = removeEmptyParagraphs(fragment)

        // If the filtered fragment has content, insert it into the editor
        if (fragment && fragment.childCount > 0) {
          const { tr } = view.state

          // Create a new transaction to insert the filtered content
          const transaction = tr.replaceSelectionWith(fragment, false).scrollIntoView()

          // Dispatch the transaction to update the editor's state
          view.dispatch(transaction)
        }

        return true
      },
    },
    content: value,
    onUpdate({ editor }) {
      setContent(editor.getHTML())
    },
  })
  const [content, setContent] = useState(value)

  useEffect(() => {
    if (editor != undefined) {
      if (value !== editor.getHTML()) {
        editor.commands.setContent(value)
      }
    }
  }, [value])

  useEffect(() => {
    if (content != value) {
      onChange(content)
    }
  }, [content])

  // Helper function to remove styles but keep structural elements like <a> tags
  function removeStylesFromHTML(html) {
    const tempDiv = document.createElement('div')
    tempDiv.innerHTML = html

    // Traverse the elements and remove style-related attributes
    const cleanElement = (element) => {
      element.removeAttribute('style')
      element.removeAttribute('class')
      element.removeAttribute('font')
      element.removeAttribute('size')
      element.removeAttribute('color')

      // Recursively clean children elements
      Array.from(element.children).forEach((child) => cleanElement(child))
    }

    Array.from(tempDiv.children).forEach((child) => cleanElement(child))

    return tempDiv.innerHTML
  }

  // Function to remove <p><br class="ProseMirror-trailingBreak"></p> elements after parsing
  function removeEmptyParagraphs(fragment) {
    const content = []

    fragment.forEach((node) => {
      // Check if the node is an empty paragraph (which represents the trailing break)
      if (node.type.name === 'paragraph' && node.childCount === 0) {
        // Skip this node as it is an empty paragraph
        return
      }
      content.push(node)
    })

    // Return a new fragment with the filtered content
    return Fragment2.fromArray(content)
  }

  return (
    <div className={props.className}>
      <MenuBar editor={editor} theme={theme} hideFormatting={hideFormatting} shortcodes={shortcodes} />
      <EditorContent editor={editor} />
    </div>
  )
}

RichTextEditor.defaultProps = {
  theme: 'full',
}

export default RichTextEditor
