import React from 'react'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

const FallbackComponent = () => {
  if (import.meta.env.VITE_BUGSNAG_RELEASE_STAGE !== 'development') {
    setTimeout(() => {
      window.location.href = '/account/error'
    }, 250)
  }

  return false
}

export default ({ children }) => {
  let app = document.getElementById('app')
  let user
  if (app) {
    const data = app.getAttribute('data-page')
    const { props } = data != undefined ? JSON.parse(data) : null
    user = props?.auth?.user
    app.removeAttribute('data-page')
  }

  Bugsnag.start({
    apiKey: import.meta.env.VITE_BUGSNAG_API_KEY,
    appVersion: import.meta.env.VITE_BUGSNAG_APP_VERSION,
    enabledReleaseStages: import.meta.env.VITE_BUGSNAG_NOTIFY_RELEASE_STAGES.split(','),
    releaseStage: import.meta.env.VITE_BUGSNAG_RELEASE_STAGE,
    plugins: [new BugsnagPluginReact()],
    onError: function (event) {
      if (user) {
        event.setUser(user.id, user.email, `${user.first_name} ${user.last_name}`)
      }
    },
  })

  const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)

  return <ErrorBoundary FallbackComponent={FallbackComponent}>{children}</ErrorBoundary>
}
