import classNames from 'classnames'

const Label = ({ children, type }) => (
  <div
    className={classNames(
      'inline-block rounded px-2.5 py-1.5 text-sm font-medium uppercase leading-none',
      {
        default: 'border border-gray-300/50 bg-gray-200 text-gray-800',
        error: 'border border-red-200/50 bg-red-100 text-red-600',
        success: 'border border-green-200/50 bg-green-50 text-green-900',
        warning: 'border border-yellow-200/50 bg-yellow-100 text-yellow-700',
      }[type],
    )}
  >
    {children}
  </div>
)

Label.defaultProps = {
  type: 'default',
}

export default Label
