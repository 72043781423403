import React, { useState } from 'react'
import { router } from '@inertiajs/react'
import { usePage } from '@inertiajs/react'
import HeaderColumn from '@/Shared/Table/HeaderColumn'

export default () => {
  const { filters } = usePage().props
  const { sortBy, direction } = filters

  const sort = (field) => {
    let values = filters ? { ...filters, sortBy: field } : { sortBy: field }
    values = { ...values, direction: field === sortBy ? (direction === 'asc' ? 'desc' : 'asc') : 'asc' }

    router.get(
      route(route().current(), { filters: encodeURIComponent(JSON.stringify(values)) }),
      {},
      {
        preserveState: true,
      },
    )
  }

  return (
    <tr>
      <HeaderColumn className="hidden w-[115px] pr-0 sm:table-cell sm:pr-0" noBorder noHover></HeaderColumn>
      <HeaderColumn className="sm:pl-0" sorting={sortBy} direction={direction} field="address" onSort={(field) => sort(field)} noBorder>
        Location
      </HeaderColumn>
      <HeaderColumn className="hidden xl:table-cell xl:w-96">Host & Co-Hosts</HeaderColumn>
      <HeaderColumn className="hidden md:table-cell" sorting={sortBy} direction={direction} field="when" onSort={(field) => sort(field)}>
        Date & Time
      </HeaderColumn>
      <HeaderColumn
        className="hidden text-blue-500 sm:table-cell"
        sorting={sortBy}
        direction={direction}
        field="visitors"
        onSort={(field) => sort(field)}
      >
        <i className="fas fa-users flex-1 text-center"></i>
      </HeaderColumn>
      <HeaderColumn className="hidden lg:table-cell">Status</HeaderColumn>
      <HeaderColumn noHover>
        <span className="sr-only">Edit</span>
      </HeaderColumn>
    </tr>
  )
}
