import { Fragment, useEffect, useRef, useState } from 'react'
import { useForm } from '@inertiajs/react'
import Button from '@/Shared/Button'
import DeleteConfirmationDialog from '@/Shared/Dialog/DeleteConfirmationDialog'
import SlideOver from '@/Shared/SlideOver'
import TextInput from '@/Shared/Forms/TextInput'

export default ({ associate, record, open, onClosed }) => {
  let focusRef = useRef()
  const form = useForm({
    associate_id: associate.id,
    name: '',
    phone: '',
    email: '',
    relationship: '',
  })
  const { clearErrors, data, delete: destroy, errors, setData, patch, post, reset } = form
  const [deleting, setDeleting] = useState(false)

  useEffect(() => {
    if (record) {
      setData({
        ...data,
        id: record.id,
        name: record.name,
        phone: record.phone_formatted,
        email: record.email,
        relationship: record.relationship,
      })
    }
  }, [record])

  const closeDialog = () => {
    setDeleting(false)
    clearErrors()
    reset()
    onClosed()
  }

  const onDelete = () => {
    destroy(route('associates.emergency_contacts.destroy', { associate: associate.id, emergency_contact: data.id }), {
      onSuccess: () => {
        closeDialog()
      },
    })
  }

  const submit = (event) => {
    event.preventDefault()

    if (data.id) {
      patch(route('associates.emergency_contacts.update', { associate: associate.id, emergency_contact: data.id }), {
        onSuccess: () => {
          closeDialog()
        },
      })
    } else {
      post(route('associates.emergency_contacts.store', associate.id), {
        onSuccess: () => {
          closeDialog()
        },
      })
    }
  }

  return (
    <SlideOver
      focusRef={focusRef}
      footerActions={
        <Button type="submit" theme="solid" form="newChecklist">
          Save
        </Button>
      }
      footerLeftActions={
        data.id && (
          <button
            role="button"
            className="group relative inline-flex h-10 w-10 items-center justify-center rounded leading-none transition-all duration-150 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-red-600"
            onClick={() => setDeleting()}
          >
            <span className="sr-only">Delete Contact</span>
            <i className="fas fa-trash text-xl text-gray-800 group-hover:text-red-600"></i>
          </button>
        )
      }
      show={open}
      size="max-w-lg"
      title={data.id ? `Edit ${data.name}` : 'New'}
      subTitle="Emergency Contact"
      onClosed={closeDialog}
    >
      <form id="newChecklist" className="mb-5 flex flex-col" onSubmit={submit}>
        <TextInput
          label="Name"
          name="name"
          value={data.name}
          error={errors.name}
          onChange={(value) => setData({ ...data, name: value })}
          required
        />

        <TextInput
          label="Phone"
          name="phone"
          value={data.phone}
          error={errors.phone}
          onChange={(value) => setData({ ...data, phone: value })}
          required
        />

        <TextInput
          label="Email"
          name="email"
          type="email"
          value={data.email}
          error={errors.email}
          onChange={(value) => setData({ ...data, email: value })}
          required
        />

        <TextInput
          label="Relationship"
          name="relationship"
          value={data.relationship}
          error={errors.relationship}
          onChange={(value) => setData({ ...data, relationship: value })}
        />
      </form>

      <DeleteConfirmationDialog open={deleting} onCanceled={() => setDeleting(false)} onApproved={() => onDelete()}>
        <Fragment>
          <p>Are you sure you want to delete this contact?</p>
        </Fragment>
      </DeleteConfirmationDialog>
    </SlideOver>
  )
}
