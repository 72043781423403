import { Fragment, useEffect, useRef, useState } from 'react'
import { useForm, usePage } from '@inertiajs/react'
import Button from '@/Shared/Button'
import classNames from 'classnames'
import Helpers from '@/utils/helpers'
import { DatePicker } from '@/Shared/DatePicker/DatePicker'
import DeleteConfirmationDialog from '@/Shared/Dialog/DeleteConfirmationDialog'
import Select from '@/Shared/Forms/Select'
import SlideOver from '@/Shared/SlideOver'
import TextInput from '@/Shared/Forms/TextInput'

export default ({ associate, license, open, onClosed }) => {
  let focusRef = useRef()
  const { user } = usePage().props.auth
  const form = useForm({
    associate_id: associate.id,
    type: '',
    state: '',
    brokerage_name: '',
    license: '',
    expires_at: null,
  })
  const { clearErrors, data, delete: destroy, errors, setData, patch, post, reset } = form
  const [deleting, setDeleting] = useState(false)
  const types = [
    { value: 'Sales', label: 'Sales' },
    { value: 'Broker', label: 'Broker' },
    { value: 'Brokerage', label: 'Brokerage' },
  ]
  const states = [
    { value: 'MA', label: 'Massachusetts' },
    { value: 'NH', label: 'New Hampshire' },
    { value: 'RI', label: 'Rhode Island' },
  ]

  useEffect(() => {
    setData({
      ...data,
      id: license?.id,
      type: license?.type,
      state: license?.state,
      brokerage_name: license?.brokerage_name,
      license: license?.license,
      expires_at: license?.expires_at,
    })
  }, [license])

  const onClosing = () => {
    setDeleting(false)
    clearErrors()
    reset()
    onClosed()
  }

  const onDelete = () => {
    destroy(route('associates.licenses.destroy', { associate: associate.id, license: data.id }), {
      onSuccess: () => onClosing(),
    })
  }

  const submit = (event) => {
    event.preventDefault()

    if (data.id) {
      patch(route('associates.licenses.update', { associate: associate.id, license: data.id }), {
        onSuccess: () => onClosing(),
      })
    } else {
      post(route('associates.licenses.store', associate.id), {
        onSuccess: () => onClosing(),
      })
    }
  }

  return (
    <SlideOver
      focusRef={focusRef}
      footerActions={
        <Button type="submit" theme="solid" form="newChecklist">
          Save
        </Button>
      }
      footerLeftActions={
        Helpers.isAdminOrHR(user) &&
        data.id && (
          <button
            role="button"
            className="group relative inline-flex h-10 w-10 items-center justify-center rounded leading-none transition-all duration-150 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-red-600"
            onClick={() => setDeleting(true)}
          >
            <span className="sr-only">Delete License</span>
            <i className="fas fa-trash text-xl text-gray-800 group-hover:text-red-600"></i>
          </button>
        )
      }
      show={open}
      size="max-w-lg"
      title={data.id ? `${data.state} ${data.type}` : 'New'}
      subTitle="State License"
      onClosed={onClosing}
    >
      <form id="newChecklist" className="mb-5 flex flex-col" onSubmit={submit}>
        <Select
          ref={focusRef}
          name="type"
          options={types}
          label="Type"
          placeholder="Select a Type"
          error={errors.type}
          value={data.type}
          isClearable={false}
          onChange={(selected) => setData({ ...data, type: selected?.value, brokerage_name: '' })}
        />

        {data.type === 'Brokerage' && (
          <TextInput
            label="Brokerage Name"
            name="brokerage_name"
            value={data.brokerage_name}
            error={errors.brokerage_name}
            onChange={(value) => setData({ ...data, brokerage_name: value })}
            required
          />
        )}

        <TextInput
          label="License #"
          name="license"
          value={data.license}
          error={errors.license}
          onChange={(value) => setData({ ...data, license: value })}
          required
        />

        <Select
          name="state"
          options={states}
          label="State"
          placeholder="Select a State"
          error={errors.state}
          value={data.state}
          isClearable={false}
          onChange={(selected) => setData({ ...data, state: selected?.value })}
        />

        <div className="mb-4">
          <label
            htmlFor="expires_at"
            className={classNames('mb-0.5 block text-sm font-medium uppercase', errors.expires_at ? 'text-red-600' : 'text-gray-500')}
          >
            Expiration Date
          </label>
          <DatePicker date={data.expires_at && new Date(data.expires_at)} onChange={(date) => setData({ ...data, expires_at: date })} />
          {errors.expires_at && <div className="mt-1 text-red-600" dangerouslySetInnerHTML={{ __html: errors.expires_at }}></div>}
        </div>
      </form>

      <DeleteConfirmationDialog open={deleting} onCanceled={() => setDeleting(false)} onApproved={() => onDelete()}>
        <Fragment>
          <p className="text-center">Are you sure you want to delete this license?</p>

          <div className="mt-2 bg-gray-100 p-4 text-center">
            <div className="font-semibold text-gray-900">
              {data.state + ' ' + data.type} - #{data.license}
            </div>
            <div className="text-gray-500">
              Expires{' '}
              {new Date(data.expires_at).toLocaleDateString('en-us', {
                month: 'long',
                day: 'numeric',
                year: 'numeric',
              })}
            </div>
          </div>
        </Fragment>
      </DeleteConfirmationDialog>
    </SlideOver>
  )
}
