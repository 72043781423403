import React, { useEffect, useRef, useState } from 'react'
import { router } from '@inertiajs/react'
import { useForm, usePage } from '@inertiajs/react'
import Button from '@/Shared/Button'
import Checkbox from '@/Shared/Forms/Checkbox'
import Heading from '@/Shared/Forms/Heading'
import MultipleInputBlock from '@/Shared/Forms/MultipleInputBlock'
import Select from '@/Shared/Forms/Select'
import SlideOver from '@/Shared/SlideOver'
import TextInput from '@/Shared/Forms/TextInput'
import { nanoid } from 'nanoid'

export default ({ open, onClosed, record }) => {
  let focusRef = useRef()
  const { allSections } = usePage().props
  const checklist = record || {}
  const form = useForm({
    id: checklist.id || null,
    name: checklist.name || '',
    active: checklist.active || true,
    sections:
      checklist.sections?.length > 0
        ? checklist.sections.map((s) =>
            Object.assign(
              {},
              {
                id: s.id || nanoid(),
                label: s.name,
                active: s.active,
                sort_order: s.sort_order,
              },
            ),
          )
        : [
            {
              active: true,
              sort_order: 1,
            },
          ],
  })
  const { data, setData, post } = form
  const [errors, setErrors] = useState([])
  const [submitting, setSubmitting] = useState(false)

  useEffect(() => {
    if (Object.keys(form.errors).length != 0) {
      setErrors(form.errors)
    }
  }, [form])

  const submit = (event) => {
    event.preventDefault()

    setSubmitting(true)

    if (data.id) {
      axios
        .patch(route('admin.checklists.update', data.id), { ...data, _method: 'patch' })
        .then((_) => {
          onClosed()
          setTimeout(() => {
            router.visit(window.location.href, {
              preserveScroll: true,
              replace: true,
            })
          }, 300)
          setSubmitting(false)
        })
        .catch((error) => {
          if (error.response != undefined) {
            const { status } = error.response
            const VALIDATION_ERROR = 422
            if (status == VALIDATION_ERROR) {
              setErrors(error.response.data.errors)
            }
          }
        })
    } else {
      post(route('admin.checklists.store'), {
        onSuccess: () => onClosed(),
        onFinish: () => setSubmitting(false),
      })
    }
  }

  return (
    <SlideOver
      focusRef={focusRef}
      footerActions={
        <Button type="submit" theme="solid" form="newChecklist" disabled={submitting}>
          {data.id ? 'Save Changes' : 'Create'}
        </Button>
      }
      show={open}
      size="max-w-lg"
      title={data.id ? checklist.name : 'New'}
      subTitle="Transaction Checklist"
      onClosed={onClosed}
    >
      <form id="newChecklist" className="mb-5 flex flex-col" onSubmit={submit}>
        <Heading>General Information</Heading>

        <div className="mb-3">
          <Checkbox
            name="active"
            label="Active"
            description="Makes this checklist available for transactions."
            value={data.active}
            onChange={(selected) => setData({ ...data, active: selected })}
          />
        </div>

        <TextInput
          ref={focusRef}
          label="Name"
          name="name"
          value={data.name}
          error={errors.name}
          onChange={(value) => setData({ ...data, name: value })}
          required
        />

        <div className="mb-5">
          <Heading>Sections</Heading>
          <MultipleInputBlock
            containerClassName="space-y-3 px-4 sm:px-6"
            data={data.sections}
            isSortable={true}
            onChange={(value) => setData({ ...data, sections: value })}
            render={({ attributes, data, listeners, onChange }) => (
              <div className="flex-1 pb-1">
                <div className="flex items-start">
                  <span className="-ml-2.5 mr-1.5 inline-block">
                    <Button theme="icon" {...attributes} {...listeners}>
                      <i className="fas fa-grip-vertical text-gray-400"></i>
                    </Button>
                  </span>

                  <div className="flex-1">
                    {data.label ? (
                      <TextInput classes="mb-2" name="label" value={data.label} onChange={(value) => onChange(value, 'label')} />
                    ) : (
                      <Select
                        classes="my-2 sm:flex-grow sm:mt-0"
                        name="label"
                        options={allSections.filter(
                          (s) => s.label === data.label || form.data.sections.find((ds) => ds.label === s.label) == undefined,
                        )}
                        placeholder="Section"
                        value={data.label}
                        isClearable={false}
                        onChange={(selected) => onChange(selected && selected.label, 'label')}
                        creatable
                      />
                    )}

                    <Checkbox
                      name={`active_${data.id || 'new'}`}
                      label="Active"
                      value={data.active}
                      onChange={(selected) => onChange(selected, 'active')}
                    />
                  </div>
                </div>
              </div>
            )}
          />
        </div>
      </form>
    </SlideOver>
  )
}
