import React from 'react'
import classNames from 'classnames'

export default React.forwardRef(
  (
    {
      autoComplete,
      autoCorrect,
      autoFocus,
      classes,
      disabled,
      error,
      hideErrorMessage,
      inputClasses,
      label,
      name,
      placeholder,
      required,
      rows,
      spellCheck,
      type,
      value,
      onChange,
    },
    ref,
  ) => {
    return (
      <div className={classNames(classes, !classes && 'mb-4')}>
        {label && (
          <label
            htmlFor={name}
            className={classNames('mb-0.5 block text-sm font-medium uppercase', error ? 'text-red-600' : 'text-gray-500')}
          >
            {label}
            {required && <span className="pl-1 text-red-600">*</span>}
          </label>
        )}

        <div className="flex items-center">
          <textarea
            autoComplete={autoComplete || 'off'}
            autoCorrect={autoCorrect || 'false'}
            autoFocus={autoFocus || false}
            id={name}
            className={classNames(
              'font-md transition-border block w-full rounded placeholder-gray-400 outline-none duration-150 ease-in-out',
              { 'px-4 py-2': !inputClasses },
              error
                ? 'border-transparent ring-2 ring-red-500 hover:ring-red-400 focus:ring-red-700'
                : 'border border-gray-300 hover:border-gray-400 focus:border-transparent focus:ring-2 focus:ring-primary-500',
              inputClasses,
              disabled ? 'cursor-not-allowed bg-gray-200' : '',
            )}
            rows={rows}
            placeholder={placeholder || ''}
            ref={ref}
            spellCheck={spellCheck || 'false'}
            type={type || 'text'}
            value={value || ''}
            onChange={(e) => onChange(e.target.value)}
            disabled={disabled}
          />
        </div>

        {error && !hideErrorMessage && <div className="mt-1 text-red-600" dangerouslySetInnerHTML={{ __html: error }}></div>}
      </div>
    )
  },
)
