import { useState } from 'react'
import { usePage } from '@inertiajs/react'
import ActiveFilters from '@/Shared/SlideOvers/ActiveFilters'
import Avatar from '@/Shared/Avatar'
import axios from '@/apis/axios'
import Button from '@/Shared/Button'
import ContactPreview from '@/Pages/Contacts/Preview'
import DataTableHeader from './DataTableHeader'
import MainLayout from '@/Shared/Layouts/Main'
import Pagination from '@/Shared/Pagination'
import SearchBar from '@/Pages/Pros/SearchBar'
import { Tooltip } from '@/Shared/Tooltip'
import { router } from '@inertiajs/react'
import classNames from 'classnames'

const ProIndex = () => {
  const { pros, filters } = usePage().props
  const activeFilters = Object.keys(filters).filter((k) => filters[k] && ['direction', 'limit', 'radius', 'sortBy'].indexOf(k) == -1)
  const [contactPreviewing, setContactPreviewing] = useState(null)
  const { data, paginator } = pros
  const administrating = route().current().startsWith('admin')

  const changeContact = (id) => {
    setContactPreviewing(null)

    setTimeout(() => {
      rowClicked(id)
    }, 300)
  }

  const onImpersonateUser = (user_id) => {
    router.post(route('admin.users.impersonate', user_id))
  }

  const rowClicked = (id) => {
    if (administrating) {
      router.visit(route('admin.pros.show', { contact: id, subpage: 'account' }))
    } else {
      axios.get(route('pros.show', id)).then((response) => {
        setContactPreviewing(response.data)
      })
    }
  }

  return (
    <div className="space-y-2">
      <div className="flex flex-wrap items-stretch justify-between bg-white px-6 py-4">
        <SearchBar />
      </div>

      <div className="space-y-2">
        {activeFilters.length > 0 && (
          <div className="px-4 xl:px-6">
            <ActiveFilters />
          </div>
        )}

        <div className="mx-4 mb-6 bg-white shadow sm:mx-6 sm:rounded">
          <div className="flex flex-col">
            <div className="overflow-x-auto">
              <div className="inline-block min-w-full align-middle">
                <div className="overflow-hidden border-b border-gray-200 shadow">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead>
                      <DataTableHeader sortBy={filters.sortBy} direction={filters.direction} />
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {data.map(
                        ({ id, user_id, avatar, name, company, phone, email, location, industry, distance, status, deleted_at }) => (
                          <tr className="group hover:bg-gray-100" key={id}>
                            <td className="w-10 whitespace-nowrap py-3 pl-4 pr-4 sm:pl-6">
                              <div className="flex h-10 w-10 flex-shrink-0 items-center justify-center">
                                <div>
                                  <Avatar contact={{ avatar: avatar, name: name }} height="h-10" width="w-10" textSize="text-base" />
                                </div>
                              </div>
                            </td>
                            <td className="cursor-pointer py-3 pr-4 sm:pr-6" onClick={() => rowClicked(id)}>
                              <div className="mb-2 sm:mb-0 sm:flex-1">
                                <div className="font-medium text-primary-500">{name}</div>
                                {company && <div className="leading-tight text-gray-700">{company}</div>}
                                {industry && <div className="leading-tight text-gray-700 sm:hidden">{industry}</div>}
                                {phone && <div className="leading-tight text-gray-700 xl:hidden">{phone}</div>}
                                {email && <div className="leading-tight text-gray-700 2xl:hidden">{email}</div>}
                              </div>
                            </td>
                            <td
                              className="hidden cursor-pointer whitespace-nowrap px-4 py-3 text-gray-700 sm:table-cell sm:px-6 xl:table-cell"
                              onClick={() => rowClicked(id)}
                            >
                              {industry}
                            </td>
                            <td
                              className="hidden cursor-pointer whitespace-nowrap px-4 py-3 text-gray-700 sm:px-6 xl:table-cell"
                              onClick={() => rowClicked(id)}
                            >
                              {phone}
                            </td>
                            <td
                              className="hidden cursor-pointer whitespace-nowrap px-4 py-3 text-gray-700 sm:px-6 2xl:table-cell"
                              onClick={() => rowClicked(id)}
                            >
                              {email}
                            </td>
                            <td
                              className="hidden cursor-pointer whitespace-nowrap px-4 py-3 text-gray-700 md:px-6 lg:table-cell"
                              onClick={() => rowClicked(id)}
                            >
                              <div>{location}</div>
                              {distance != null && <span className="text-primary-500">{distance} miles away</span>}
                            </td>

                            {administrating && (
                              <td
                                className="hidden cursor-pointer whitespace-nowrap px-4 py-3 text-gray-700 sm:px-6 lg:table-cell"
                                onClick={() => rowClicked(id)}
                                width="80px"
                              >
                                <span
                                  className={classNames(
                                    'inline-flex items-center whitespace-nowrap rounded px-2.5 py-1.5 text-sm font-medium uppercase leading-none',
                                    {
                                      'bg-green-500 text-white': status === 'public',
                                      'bg-blue-500 text-white': status === 'internal',
                                      'bg-gray-300/75 text-gray-600': status === 'private',
                                      'bg-orange-500 text-white': status === 'suspended',
                                      'bg-red-600 text-white': status === 'deleted',
                                    },
                                  )}
                                >
                                  <span className="whitespace-pre-line leading-none">{status}</span>
                                </span>
                              </td>
                            )}

                            <td className="cursor-pointer whitespace-nowrap py-3 pr-6 text-right font-medium">
                              <div className="flex items-center justify-end gap-4">
                                {administrating && (
                                  <Tooltip label={`Impersonate ${name}`} placement="bottom">
                                    <Button theme="icon" disabled={deleted_at} onClick={() => onImpersonateUser(user_id)}>
                                      <i className="fad fa-user-secret text-xl leading-none text-gray-600"></i>
                                    </Button>
                                  </Tooltip>
                                )}

                                <div className="flex items-center justify-end" onClick={() => rowClicked(id)}>
                                  <i className="far fa-angle-right text-2xl leading-none text-gray-700"></i>
                                </div>
                              </div>
                            </td>
                          </tr>
                        ),
                      )}

                      {data.length == 0 && (
                        <tr>
                          <td colSpan="8" className="px-4 py-12 sm:px-6">
                            <div className="flex flex-col text-center">
                              <span className="text-2xl font-semibold text-red-600">No PROs match your current filters.</span>
                              <span className="text-lg font-medium text-gray-700">
                                Expecting to see results? Check your filters to make sure you're not excluding results.
                              </span>
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            {data.length > 0 && <Pagination paginator={paginator} />}
          </div>
        </div>
      </div>

      <ContactPreview
        contact={contactPreviewing}
        hideFooter={!contactPreviewing?.id}
        open={contactPreviewing != null}
        onContactChanged={changeContact}
        onClosed={() => setContactPreviewing(null)}
      >
        <ContactPreview.FooterActions>
          {({ contact }) => (
            <div className="flex justify-center">
              <Button
                type="submit"
                theme="solid"
                className="flex items-center gap-2"
                onClick={() =>
                  contact.status === 'internal'
                    ? router.visit(route('pros.show', contact.id))
                    : contact.url
                      ? window.open(contact.url, '_blank')
                      : null
                }
                disabled={!contact.status || !contact.url}
              >
                {contact.status === 'internal' ? 'View Internal' : 'View'} PRO-Website
                <i className="fas fa-external-link text-lg"></i>
              </Button>
            </div>
          )}
        </ContactPreview.FooterActions>
      </ContactPreview>
    </div>
  )
}

// Persistent layout
// Docs: https://inertiajs.com/pages#persistent-layouts
ProIndex.layout = (page) => <MainLayout title="Find a PRO" children={page} />

export default ProIndex
