import { Fragment, useEffect, useRef, useState } from 'react'
import AccordionCard from '@/Shared/AccordionCard'
import Button from '@/Shared/Button'
import Dropdown from '@/Shared/Dropdown'
import DropdownItem from '@/Shared/DropdownItem'
import EditInvitation from './EditInvitation'
import { router } from '@inertiajs/react'
import PropertyListItem from '@/Shared/PropertyListItem'
import SlideOver from '@/Shared/SlideOver'
import { usePage } from '@inertiajs/react'

export default ({ record, onClosed }) => {
  let focusRef = useRef()
  const { lsn } = usePage().props

  const [invitation, setInvitation] = useState(null)
  const [editing, setEditing] = useState(false)

  useEffect(() => {
    if (record) {
      setInvitation(record)
    } else {
      setInvitation(null)
    }
  }, [record])

  const onCancel = () => {
    router.delete(route('lsns.invitations.destroy', { lsn: lsn.id, invitation: record.id }), {
      onFinish: () => {
        onClosed()
      },
    })
  }

  return (
    <SlideOver
      focusRef={focusRef}
      show={invitation != null}
      size="max-w-md"
      title={invitation && `${invitation.name}`}
      onClosed={onClosed}
      hideCloseButton
      hidePadding
    >
      {invitation && (
        <Fragment>
          <div className="relative z-10 flex justify-end p-4">
            {lsn.editable && (
              <Dropdown id="actions-options" label="Actions" orientation="right" size="w-64">
                <DropdownItem colorClasses="text-red-500 group-hover:text-red-600" onClick={() => onCancel()}>
                  <i className="fas fa-unlink w-8"></i>
                  Cancel Invitation
                </DropdownItem>
              </Dropdown>
            )}
          </div>

          <AccordionCard
            title="About this Invitation"
            actions={
              lsn.editable && (
                <Button theme="icon" onClick={() => setEditing(true)}>
                  <span className="sr-only">Member settings</span>
                  <i className="far fa-pen group-hover:text-primaryt-500 text-gray-500 group-hover:font-bold"></i>
                </Button>
              )
            }
            hideBorder
            indented={false}
          >
            <div className="border-b border-gray-200">
              <PropertyListItem label="Name" value={`${invitation.name}`} />
              <PropertyListItem label="Email" value={`${invitation.email}`} />
              {invitation.position && <PropertyListItem label="Management Role" value={invitation?.position?.name} />}
              {invitation.inviter && <PropertyListItem label="Invited By" value={`${invitation.inviter}`} />}
              {lsn.editable && <PropertyListItem label="Invitation Sent" value={invitation.last_sent_at} />}
            </div>
          </AccordionCard>
        </Fragment>
      )}

      <EditInvitation record={invitation} open={editing} onClosed={() => setEditing(false)} />
    </SlideOver>
  )
}
