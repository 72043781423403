import { usePage } from '@inertiajs/react'
import ActiveFilters from '@/Shared/SlideOvers/ActiveFilters'
import MainLayout from '@/Shared/Layouts/Main'
import SearchBar from './SearchBar'
import MyLSNs from './MyLSNs'
import ActiveLSNs from './ActiveLSNs'

const LSNIndex = () => {
  const { myLSNs } = usePage().props

  return (
    <div className="space-y-4">
      <div className="flex flex-wrap items-stretch justify-between bg-white px-6 py-4">
        <SearchBar />
      </div>

      <div className="mx-auto max-w-6xl space-y-1.5 px-4 sm:px-6">
        <ActiveFilters />

        <div className="space-y-10">
          {myLSNs.length > 0 && (
            <>
              <MyLSNs />
              <hr className="border-dashed border-gray-300" />
            </>
          )}

          <ActiveLSNs />
        </div>
      </div>
    </div>
  )
}

// Persistent layout
// Docs: https://inertiajs.com/pages#persistent-layouts
LSNIndex.layout = (page) => <MainLayout title="LSNs" children={page} />

export default LSNIndex
