import React from 'react'
import classNames from 'classnames'
import { Switch as HeadlessUISwitch } from '@headlessui/react'

export default function Switch({ accessibleText, children, disabled, model, reverseLabel, vertical, onChange }) {
  return (
    <HeadlessUISwitch.Group
      as="div"
      className={classNames('flex items-center', {
        'flex-col justify-center': vertical,
      })}
    >
      {reverseLabel && (
        <HeadlessUISwitch.Label
          as="div"
          className={classNames('flex flex-col leading-tight', {
            'mr-3': !vertical,
            'mt-1': vertical,
          })}
        >
          {children}
        </HeadlessUISwitch.Label>
      )}
      <HeadlessUISwitch
        checked={model}
        className={classNames(
          model ? 'bg-primary-500' : 'bg-gray-200',
          disabled ? 'cursor-not-allowed bg-opacity-30' : 'cursor-pointer',
          'relative inline-flex h-6 w-12 flex-shrink-0 rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2',
        )}
        onChange={() => onChange(!model)}
        disabled={disabled || false}
      >
        <span className="sr-only">{accessibleText}</span>
        <span
          aria-hidden="true"
          className={classNames(
            model ? 'translate-x-6' : 'translate-x-0',
            'pointer-events-none -mt-1/2px inline-block h-5 w-5 rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
          )}
        />
      </HeadlessUISwitch>
      {!reverseLabel && (
        <HeadlessUISwitch.Label
          as="div"
          className={classNames('flex flex-col leading-tight', {
            'ml-3': !vertical,
            'mt-1': vertical,
          })}
        >
          {children}
        </HeadlessUISwitch.Label>
      )}
    </HeadlessUISwitch.Group>
  )
}
