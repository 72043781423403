import React, { Fragment, useContext } from 'react'
import classNames from 'classnames'
import Dropdown from '@/Shared/Dropdown'
import DropdownItem from '@/Shared/DropdownItem'
import { router } from '@inertiajs/react'
import { NotificationContext } from '@/Shared/Notifications/NotificationContext'

interface Props {
  item: any
  size?: String
}

const NotificationsItem = ({ item, size = '' }: Props) => {
  const { onArchive, setOpen, toggleRead } = useContext(NotificationContext)
  const handleItemClicked = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    toggleRead(event, item, true).then((_) => {
      if (item.url) {
        router.visit(item.url, {
          onFinish: () => {
            setOpen(false)
          },
        })
      }
    })
  }

  return (
    <div
      className={classNames('group cursor-pointer leading-snug hover:bg-primary-200 hover:bg-opacity-40', {
        'bg-gray-300/60': item.archived,
        'bg-white': !item.archived,
        'text-sm': size === 'small',
      })}
    >
      <div className={`flex cursor-pointer space-x-4 px-4 py-2`}>
        <div
          className={classNames(
            'relative z-0 mt-1 flex h-12 w-12 shrink-0 items-center justify-center rounded-full',
            item.icon && item.icon.includes('text-red')
              ? 'rounded-full border border-red-600 bg-red-100 text-red-600'
              : 'border border-gray-300 bg-white',
          )}
          onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => handleItemClicked(event)}
        >
          {item.icon && item.icon.includes('text-red') && (
            <div className="absolute inset-0 -m-1 animate-littlePing rounded-full border-2 border-red-600"></div>
          )}
          {item.icon ? (
            <i className={classNames('flex items-center justify-center', item.icon)}></i>
          ) : item.creator.avatar ? (
            <div className="flex overflow-hidden rounded-full">
              <img src={item.creator.avatar} />
            </div>
          ) : item.creator.initials ? (
            <Fragment>
              <div className="absolute inset-0 z-0 overflow-hidden rounded-full bg-gray-200"></div>
              <div className="relative z-10 text-lg font-medium tracking-wide text-gray-500">{item.creator.initials}</div>
            </Fragment>
          ) : (
            // prettier-ignore
            <div className="flex h-10 w-10 p-0.5 bg-white items-center justify-center rounded-full">
              <svg className="p-1 relative h-10 w-10 -left-px top-px" viewBox="0 0 91.07 120.42">
                <path fill="#6dc067" d="M42.62,24A117.22,117.22,0,0,1,61.23,36.52a51.42,51.42,0,0,1,17.3,26.39C82.29,43.57,71.68,26.5,91.07,0,66,5.25,54.05,12.84,42.62,24Z"/>
                <path fill="#1c3f95" d="M58.37,40.23C42.65,27.88,30,20.35,0,18.77,23.86,42.62,14.9,61.46,22.61,80.54a28.2,28.2,0,0,0,34.6,16.84c2.84,8,3.61,16.45,1.18,21.29,0,3.31,5.8,1.38,8.56-1.38,2.39-2.39-2-14.89-6.31-21.26A27.4,27.4,0,0,0,73.25,83.3C79,71.87,72.57,51.41,58.37,40.23Z"/>
                <path fill="#ffffff" d="M37.17,60.6C33,44.7,26.17,33.69,25.69,34c.5-.27,11.81,8.34,16,24.23s11,26.91,11.47,26.64C52.61,85.1,41.31,76.5,37.17,60.6Z"/>
              </svg>
            </div>
          )}
        </div>

        <div className="flex-1" onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => handleItemClicked(event)}>
          <div dangerouslySetInnerHTML={{ __html: item.content }}></div>

          <div className="flex justify-between gap-4">
            <div className={classNames('notitication__item-icon mt-1 flex gap-2 leading-none text-gray-500/80')}>
              {
                {
                  note: <i className="fas fa-comment-alt"></i>,
                  email: <i className="fas fa-envelope"></i>,
                  call: <i className="fas fa-phone"></i>,
                  text: <i className="fas fa-comment-alt-dots"></i>,
                  associate: <i className="fas fa-user"></i>,
                  task: <i className="fas fa-list-check"></i>,
                }[item.type]
              }
              <span>{item.elapsed}</span>
            </div>

            <div className="flex flex-1 shrink-0 items-center justify-end gap-2">
              <div className="opacity-0 transition-all duration-300 group-hover:opacity-100">
                <Dropdown
                  id="notification-options"
                  label={<i className="fas fa-ellipsis-h text-lg text-gray-600"></i>}
                  orientation="right"
                  size="w-64"
                  icon="icon-sm"
                >
                  <DropdownItem className="flex items-center gap-3" onClick={(event: any) => toggleRead(event, item, !item.read)}>
                    <i className="far fa-check text-lg text-gray-600"></i>
                    <span>{item.read ? 'Mark as unread' : 'Mark as read'}</span>
                  </DropdownItem>
                  <DropdownItem className="flex items-center gap-3" onClick={(event: any) => onArchive(event, item)}>
                    <i className="far fa-times-square text-lg text-gray-600"></i>
                    {item.archived ? 'Unarchive' : 'Archive'} this notification
                  </DropdownItem>
                </Dropdown>
              </div>

              <div
                className={classNames('inline-flex h-3 w-3 items-center justify-center rounded-full', { 'bg-blue-500': !item.read })}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NotificationsItem
