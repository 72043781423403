import React, { useState } from 'react'

const useInfiniteScroll = (callback) => {
  const [isFetching, setIsFetching] = useState(false)
  const [stopFetching, setStopFetching] = useState(false)

  React.useEffect(() => {
    if (!isFetching) return
    callback()
  }, [isFetching])

  const handleInfiniteScroll = (event) => {
    if (stopFetching) return
    let variance = 50
    if (event.target.offsetHeight + event.target.scrollTop + variance < event.target.scrollHeight) return
    setIsFetching(true)
  }

  return {
    isFetching,
    setIsFetching,
    setStopFetching,
    stopFetching,
    handleInfiniteScroll,
  }
}

export default useInfiniteScroll
