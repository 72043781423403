import { useState } from 'react'
import ActiveFilters from '@/Shared/SlideOvers/ActiveFilters'
import ApprovalDialog from './ApprovalDialog'
import axios from '@/apis/axios'
import classNames from 'classnames'
import { useForm, usePage } from '@inertiajs/react'
import Button from '@/Shared/Button'
import DataTableHeader from './DataTableHeader'
import Main from '@/Shared/Layouts/Main'
import Pagination from '@/Shared/Pagination'
import SearchBar from './SearchBar'

const AdminServicesIndex = () => {
  const { relatedServiceRequests } = usePage().props
  const { data: records, paginator } = relatedServiceRequests
  const [open, setOpen] = useState(false)
  const { data, setData } = useForm({})

  const onClosed = () => {
    setOpen(false)

    setTimeout(() => {
      setData({})
    }, 400)
  }

  const onRowClicked = (request) => {
    axios.get(route('admin.service-relations.show', request.id)).then((response) => {
      setData(response.data)
      setOpen(true)
    })
  }

  return (
    <div className="space-y-4">
      <div className="flex flex-wrap items-stretch justify-between bg-white px-6 py-4">
        <SearchBar onCreate={() => setOpen(true)} />
      </div>

      <div className="space-y-1.5 px-4 sm:px-6">
        <ActiveFilters />

        <div className="bg-white shadow sm:rounded">
          <div className="flex flex-col">
            <div className="overflow-x-auto">
              <div className="inline-block min-w-full align-middle">
                <div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead>
                      <DataTableHeader />
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {records.map((request) => (
                        <tr className="group hover:bg-gray-100" key={request.id}>
                          <td
                            className="hidden cursor-pointer px-4 py-3 text-gray-700 sm:px-6 lg:table-cell"
                            onClick={() => onRowClicked(request.id)}
                          >
                            {request.created_at}
                          </td>

                          <td
                            className="cursor-pointer gap-1.5 space-y-1.5 px-6 py-3 text-gray-700 md:hidden"
                            onClick={() => onRowClicked(request)}
                          >
                            <div>
                              <span className="inline-flex gap-1 whitespace-nowrap rounded bg-blue-200/50 px-2.5 py-1.5 text-sm leading-none text-blue-500">
                                <span className="font-bold">P:</span> {request.primary_service}
                              </span>
                            </div>
                            <div>
                              <span className="inline-flex gap-1 whitespace-nowrap rounded bg-gray-200 px-2.5 py-1.5 text-sm leading-none text-gray-800">
                                <span className="font-bold">R:</span> {request.related_service}
                              </span>
                            </div>
                          </td>

                          <td className="hidden cursor-pointer px-6 py-3 text-gray-700 md:table-cell" onClick={() => onRowClicked(request)}>
                            {request.related_service}
                          </td>

                          <td className="hidden cursor-pointer px-6 py-3 text-gray-700 md:table-cell" onClick={() => onRowClicked(request)}>
                            {request.primary_service}
                          </td>

                          <td
                            className="hidden cursor-pointer whitespace-nowrap px-6 py-3 text-center text-gray-700 md:table-cell"
                            onClick={() => onRowClicked(request)}
                          >
                            <div
                              className={classNames(
                                {
                                  approved: 'bg-green-50 text-green-700',
                                  pending: 'bg-orange-500 text-white',
                                  denied: 'bg-red-100 text-red-700',
                                }[request.status],
                                'inline-flex items-center whitespace-nowrap rounded-full p-1 px-2.5 text-sm font-medium uppercase leading-none',
                              )}
                            >
                              {request.status}
                            </div>
                          </td>

                          <td className="cursor-pointer whitespace-nowrap py-3 pr-6 text-right font-medium">
                            <div className="flex items-center justify-end">
                              <Button theme="icon" onClick={() => onRowClicked(request)}>
                                <i className="far fa-pen text-lg text-gray-500 group-hover:font-bold group-hover:text-blue-500"></i>
                              </Button>
                            </div>
                          </td>
                        </tr>
                      ))}

                      {records.length == 0 && (
                        <tr>
                          <td colSpan="8" className="px-6 py-12">
                            <div className="flex flex-col text-center">
                              <span className="text-2xl font-semibold text-red-600">
                                No service relation requests were found matching your current filters.
                              </span>
                              <span className="text-lg font-medium text-gray-700">
                                Expecting to see results? Check your filters to make sure you're not excluding results.
                              </span>
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            {records.length > 0 && <Pagination paginator={paginator} />}
          </div>
        </div>
      </div>

      <ApprovalDialog open={open} record={data} onClosed={() => onClosed()} />
    </div>
  )
}

// Persistent layout
// Docs: https://inertiajs.com/pages#persistent-layouts
AdminServicesIndex.layout = (page) => <Main title="Services" children={page} />

export default AdminServicesIndex
