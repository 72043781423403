import React, { useRef } from 'react'

import Button from '@/Shared/Button'
import Dialog from '@/Shared/Dialog/Index'

export default ({ buttonText, children, disabled, form, open, size, title, onCanceled, onApproved }) => {
  let focusRef = useRef()

  return (
    <Dialog
      focusRef={focusRef}
      footerActions={
        <Button form={form} type="submit" theme="solid" disabled={disabled} onClick={onApproved}>
          {buttonText || 'Yes, Continue'}
        </Button>
      }
      size={size}
      show={open}
      title={title || 'Confirmation'}
      onClosed={onCanceled}
      focusCancelButton
    >
      {children}
    </Dialog>
  )
}
