import { Fragment, useContext, useEffect, useState } from 'react'
import Button from '@/Shared/Button'
import { CSS } from '@dnd-kit/utilities'
import { DndContext, DragOverlay, closestCenter, KeyboardSensor, PointerSensor, TouchSensor, useSensor, useSensors } from '@dnd-kit/core'
import { arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy, useSortable } from '@dnd-kit/sortable'
import StepDialog from './StepDialog'
import { SubtasksConsumer, SubtasksContext, SubtasksProvider } from './SubtasksContext'

const SortableItem = ({ item }) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: item.id || item.temp_id })
  const { subtasks, onSubtaskEditing, onSubtaskDuplicating } = useContext(SubtasksContext)

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  return (
    <div ref={setNodeRef} className="flex rounded-md border border-gray-200 bg-gray-200 shadow-md" style={style}>
      {subtasks.length > 1 && (
        <div className="flex items-center justify-center gap-2 rounded-l-md">
          <Button className="cursor-move touch-none" theme="icon" {...attributes} {...listeners}>
            <i className="fas fa-grip-vertical text-gray-700 text-opacity-75"></i>
          </Button>
        </div>
      )}

      <div className="flex flex-1 items-center rounded-r-md bg-white px-4 py-2">
        {item.name ? (
          <div className="flex-1" dangerouslySetInnerHTML={{ __html: item.name }}></div>
        ) : (
          <div className="flex-1 italic text-gray-400">Title not set</div>
        )}

        <div className="ml-3 flex items-center justify-end gap-1">
          <Button theme="icon" onClick={() => onSubtaskDuplicating(item)}>
            <i className="far fa-copy text-gray-500 group-hover:font-bold group-hover:text-blue-500"></i>
          </Button>

          <Button theme="icon" onClick={() => onSubtaskEditing(item)}>
            <i className="far fa-pen text-gray-500 group-hover:font-bold group-hover:text-blue-500"></i>
          </Button>
        </div>
      </div>
    </div>
  )
}

const Draggable = ({ activeId }) => {
  const { subtasks } = useContext(SubtasksContext)
  const activeItem = subtasks.find((item) => activeId == (item.id || item.temp_id))

  return (
    <Fragment>
      <SortableContext items={subtasks} strategy={verticalListSortingStrategy}>
        {subtasks.map((item) => (
          <SortableItem key={item.id || item.temp_id} item={item} />
        ))}
      </SortableContext>

      <DragOverlay>{activeItem ? <SortableItem item={activeItem} /> : null}</DragOverlay>
    </Fragment>
  )
}

const Sortable = ({ parent_id, items, onUpdated }) => {
  const [activeId, setActiveId] = useState(null)
  const { subtasks, setSubtasks, onSubtaskCreating } = useContext(SubtasksContext)

  useEffect(() => setSubtasks(items), [items])

  useEffect(() => {
    if (subtasks) onUpdated(subtasks)
  }, [subtasks])

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  )

  function handleDragStart(event) {
    const { active } = event
    setActiveId(active.id || active.temp_id)
  }

  const handleDragEnd = (event) => {
    setActiveId(null)

    const { active, over } = event
    if (active.id !== over.id) {
      const oldIndex = subtasks.findIndex((item) => item.id == active.id)
      const newIndex = subtasks.findIndex((item) => item.id == over.id)

      let newItems = arrayMove(subtasks, oldIndex, newIndex).map((item, index) => ({ ...item, sort_order: index + 1 }))
      setSubtasks(newItems)
    }
  }

  if (!subtasks) return <></>

  return (
    <div className="space-y-6">
      <div className="space-y-4">
        <DndContext sensors={sensors} collisionDetection={closestCenter} onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
          <SortableContext items={subtasks} strategy={verticalListSortingStrategy}>
            <Draggable items={subtasks} activeId={activeId} />
          </SortableContext>
        </DndContext>
      </div>

      <div className="flex justify-center">
        <Button buttonClasses="!ml-0" theme="outline" size="sm" onClick={() => onSubtaskCreating(parent_id)}>
          <i className="fal fa-plus pr-2 text-gray-500"></i>
          <span>Add subtask</span>
        </Button>
      </div>
    </div>
  )
}

export default ({ children, parent_id, items, onUpdated }) => {
  return (
    <SubtasksProvider>
      {children}

      <Sortable parent_id={parent_id} items={items} onUpdated={onUpdated} />

      <SubtasksConsumer>
        {({ activeSubtask, setActiveSubtask }) => (
          <StepDialog open={activeSubtask != null} record={activeSubtask} onClosed={() => setActiveSubtask(null)} />
        )}
      </SubtasksConsumer>
    </SubtasksProvider>
  )
}
