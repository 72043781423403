import { Fragment } from 'react'
import { router } from '@inertiajs/react'
import { usePage } from '@inertiajs/react'
import DataTableHeader from './DataTableHeader'
import MainLayout from '@/Shared/Layouts/Main'
import Pagination from '@/Shared/Pagination'
import SearchBar from './SearchBar'
import classNames from 'classnames'

function TransactionsIndex() {
  const { transactions, filters } = usePage().props
  const { data, paginator } = transactions

  const rowClicked = (id) => {
    router.visit(route('transactions.show', id))
  }

  return (
    <div>
      <div className="flex flex-wrap items-stretch justify-between bg-white px-4 py-4 sm:px-4 sm:px-6">
        <SearchBar />
      </div>

      <div className="m-4 bg-white shadow sm:m-6 sm:rounded">
        <div className="flex flex-col">
          <div className="overflow-x-auto">
            <div className="inline-block min-w-full align-middle">
              <div className="overflow-hidden border-b border-gray-200 shadow">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead>
                    <DataTableHeader sortBy={filters.sortBy} direction={filters.direction} />
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {data.map((item) => {
                      const { id, name, property_state, associates, propertyTypes, stage, states, status, completed_at } = item

                      return (
                        <tr key={id}>
                          <td className="hidden whitespace-nowrap px-2 py-3 md:table-cell">
                            <div className="flex shrink-0 items-center justify-center">
                              {associates.map((associate, index) => (
                                <Fragment key={index}>
                                  {associate.avatar && (
                                    <div className={index > 0 ? '-ml-2' : ''} style={{ zIndex: associates.length - index }}>
                                      <img
                                        className={classNames(
                                          'inline-block rounded-full ring-1',
                                          index == 0 ? 'h-10 w-10 ring-white' : 'h-9 w-9 ring-white',
                                        )}
                                        src={associate.avatar}
                                        alt=""
                                        aria-hidden="true"
                                      />
                                    </div>
                                  )}
                                  {!associate.avatar && (
                                    <div className="flex h-10 w-10 items-center justify-center rounded-full border border-gray-300 bg-gray-200 text-gray-700">
                                      <div className="text-xl font-medium tracking-wide">{associate.initials}</div>
                                    </div>
                                  )}
                                </Fragment>
                              ))}
                            </div>
                          </td>

                          <td className="cursor-pointer space-y-1.5 px-4 py-3 sm:px-6" onClick={() => rowClicked(id)}>
                            <div className="text-lg font-medium text-blue-500 sm:text-base">{name}</div>

                            <div className="hidden flex-wrap items-center gap-1.5 lg:flex">
                              {status === 'Pending' && (
                                <div className="inline-block whitespace-nowrap rounded bg-blue-500 px-2.5 py-1.5 text-sm leading-none text-white">
                                  <span>Sale Pending</span>
                                </div>
                              )}

                              {['Archived', 'Lost'].indexOf(status) >= 0 && (
                                <div className="inline-block whitespace-nowrap rounded bg-red-600 px-2.5 py-1.5 text-sm leading-none text-white">
                                  <span>{status}</span>
                                </div>
                              )}

                              {['On-Hold'].indexOf(status) >= 0 && (
                                <div className="inline-block whitespace-nowrap rounded bg-yellow-200/80 px-2.5 py-1.5 text-sm font-medium leading-none text-yellow-600">
                                  <span>{status}</span>
                                </div>
                              )}

                              {['Compliance', 'Under Review'].indexOf(status) >= 0 && (
                                <div className="inline-block whitespace-nowrap rounded bg-orange-200/60 px-2.5 py-1.5 text-sm font-medium leading-none text-orange-600">
                                  <span>{status}</span>
                                </div>
                              )}

                              {['Closed'].indexOf(status) >= 0 && (
                                <div className="inline-block whitespace-nowrap rounded bg-orange-500 px-2.5 py-1.5 text-sm font-medium uppercase leading-none text-white">
                                  <span>Closed</span>
                                </div>
                              )}

                              {propertyTypes?.map((type, index) => (
                                <span
                                  className="inline-block whitespace-nowrap rounded bg-gray-200 px-2.5 py-1.5 text-sm leading-none text-gray-800"
                                  key={index}
                                >
                                  <span>{type.name}</span>
                                </span>
                              ))}

                              {property_state ? (
                                <span className="inline-block whitespace-nowrap rounded bg-gray-200 px-2.5 py-1.5 text-sm uppercase leading-none text-gray-800">
                                  <span>{property_state}</span>
                                </span>
                              ) : (
                                states?.map((state, index) => (
                                  <span
                                    className="inline-block whitespace-nowrap rounded bg-gray-200 px-2.5 py-1.5 text-sm leading-none text-gray-800"
                                    key={index}
                                  >
                                    <span>{state.state}</span>
                                  </span>
                                ))
                              )}
                            </div>

                            <div className="leading-normal xl:hidden">
                              {item.closing_at && <div>Closing Date: {item.closing_at}</div>}

                              {['created_at', 'updated_at', 'mortgage_commitment_date'].indexOf(filters.sortBy) >= 0 &&
                                item[filters.sortBy] && (
                                  <div>
                                    {(() => {
                                      switch (filters.sortBy) {
                                        case 'created_at':
                                          return 'Date Created'
                                        case 'mortgage_commitment_date':
                                          return 'Mortgage Commitment Date'
                                        default:
                                          return 'Last Activity'
                                      }
                                    })()}
                                    : {item[filters.sortBy]}
                                  </div>
                                )}
                            </div>
                          </td>

                          <td className="hidden cursor-pointer px-4 py-3 sm:px-6 lg:table-cell" onClick={() => rowClicked(id)}>
                            {completed_at ? (
                              <div className="inline-block whitespace-nowrap rounded bg-green-600 px-2.5 py-1.5 text-sm font-medium uppercase leading-none text-white">
                                <span>Completed</span>
                              </div>
                            ) : (
                              <div className="flex items-center justify-start gap-1.5">
                                <span className="inline-block rounded bg-blue-200 bg-opacity-40 px-2.5 py-1.5 text-sm font-medium uppercase leading-none text-blue-700">
                                  <span className="whitespace-pre-line leading-none">{stage.name}</span>
                                </span>
                                <span className="inline-block rounded bg-gray-200 px-2.5 py-1.5 text-sm leading-none text-gray-800">
                                  {stage.elapsed}
                                </span>
                              </div>
                            )}
                          </td>

                          <td
                            className="hidden cursor-pointer whitespace-nowrap px-4 py-3 text-left text-gray-800 sm:px-6 xl:table-cell"
                            onClick={() => rowClicked(id)}
                            width="150px"
                          >
                            {['created_at', 'updated_at', 'closing_at', 'mortgage_commitment_date'].indexOf(filters.sortBy) >= 0
                              ? item[filters.sortBy]
                              : item.closing_at}
                          </td>

                          <td className="cursor-pointer whitespace-nowrap py-3 pr-6 text-right font-medium" onClick={() => rowClicked(id)}>
                            <div className="flex items-center justify-end">
                              <i className="far fa-angle-right text-2xl leading-none text-gray-800"></i>
                            </div>
                          </td>
                        </tr>
                      )
                    })}

                    {data.length == 0 && (
                      <tr>
                        <td colSpan="8" className="px-4 py-12 sm:px-6">
                          <div className="flex flex-col text-center">
                            <span className="text-2xl font-semibold text-red-600">No transactions match your current filters.</span>
                            <span className="text-lg font-medium text-gray-800">
                              Expecting to see results? Check your filters to make sure you're not excluding results.
                            </span>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          {data.length > 0 && <Pagination paginator={paginator} />}
        </div>
      </div>
    </div>
  )
}

// Persistent layout
// Docs: https://inertiajs.com/pages#persistent-layouts
TransactionsIndex.layout = (page) => <MainLayout title="Transactions" children={page} />

export default TransactionsIndex
