import { Fragment, useEffect, useState } from 'react'
import ActiveFilters from '@/Shared/SlideOvers/ActiveFilters'
import axios from '@/apis/axios'
import classNames from 'classnames'
import { router } from '@inertiajs/react'
import { useForm, usePage } from '@inertiajs/react'
import Button from '@/Shared/Button'
import DataTableHeader from './DataTableHeader'
import Edit from './Edit'
import Main from '@/Shared/Layouts/Main'
import Pagination from '@/Shared/Pagination'
import SearchBar from './SearchBar'

const AdminFileTypeIndex = () => {
  const { fileTypes } = usePage().props
  const { data: records, paginator } = fileTypes
  const [open, setOpen] = useState(false)
  const { data, setData, delete: destroy } = useForm({})

  useEffect(() => {
    setOpen(Boolean(data.id))
  }, [data])

  const onClosed = () => {
    setData({})
    setOpen(false)
  }

  const onRowClicked = (fileType) => {
    setData(fileType)
    setOpen(true)
  }

  const onDelete = (id) => {
    destroy(route('admin.file-types.destroy', id), {
      onSuccess: () => {
        setData({})
      },
    })
  }

  const onRestore = (id) => {
    axios
      .patch(route('admin.file-types.update', id), {
        ...data,
        deleted_at: null,
        _method: 'patch',
      })
      .then((_) => {
        setData({})
        router.reload({ only: ['fileTypes', 'flash'] })
      })
  }

  return (
    <div className="space-y-1.5">
      <div className="flex flex-wrap items-stretch justify-between bg-white px-6 py-4">
        <SearchBar onCreate={() => setOpen(true)} />
      </div>

      <div className="mx-6 space-y-1.5 pb-6">
        <ActiveFilters />

        <div className="bg-white shadow sm:rounded">
          <div className="flex flex-col">
            <div className="overflow-x-auto">
              <div className="inline-block min-w-full align-middle">
                <div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead>
                      <DataTableHeader />
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {records.map((fileType) => (
                        <tr className="group hover:bg-gray-100" key={fileType.id}>
                          <td className="hidden cursor-pointer space-y-1 py-3 pl-6 text-center md:table-cell">
                            <span
                              className={classNames(
                                'inline-flex whitespace-nowrap rounded px-2.5 py-1.5 text-sm leading-none',
                                fileType.directory === 'Closing'
                                  ? 'border border-blue-200 bg-blue-200 bg-opacity-30 text-blue-500'
                                  : 'border border-gray-400 bg-gray-100 text-gray-600',
                              )}
                            >
                              <span>{fileType.directory}</span>
                            </span>
                          </td>

                          <td className="cursor-pointer space-y-1 px-6 py-3" onClick={() => onRowClicked(fileType)}>
                            <div className="font-medium text-blue-500">{fileType.name}</div>

                            <div className="flex flex-wrap items-center gap-1.5">
                              <span
                                className={classNames(
                                  'inline-flex whitespace-nowrap rounded px-2.5 py-1.5 text-sm leading-none md:hidden',
                                  fileType.directory === 'Closing'
                                    ? 'border border-blue-200 bg-blue-200 bg-opacity-30 text-blue-500'
                                    : 'border border-gray-400 bg-gray-100 text-gray-600',
                                )}
                              >
                                <span>{fileType.directory}</span>
                              </span>

                              <span
                                className={classNames(
                                  Boolean(fileType.deleted_at)
                                    ? 'bg-red-100 text-red-700'
                                    : {
                                        'bg-green-50 text-green-700': Boolean(fileType.active),
                                        'bg-gray-200 text-gray-600': !Boolean(fileType.active),
                                      },
                                  'inline-flex items-center whitespace-nowrap rounded-full px-3 py-1.5 text-sm font-medium uppercase leading-none sm:hidden',
                                )}
                              >
                                {Boolean(fileType.deleted_at) ? (
                                  <span>Deleted</span>
                                ) : Boolean(fileType.active) ? (
                                  <span>Active</span>
                                ) : (
                                  <span>Inactive</span>
                                )}
                              </span>

                              {fileType.allow_addendums && (
                                <span className="inline-flex whitespace-nowrap rounded bg-gray-200 px-2.5 py-1.5 text-sm leading-none text-gray-800">
                                  <span>Allows Addendums</span>
                                </span>
                              )}

                              {fileType.required_per_associate && (
                                <span className="inline-flex whitespace-nowrap rounded bg-red-100 px-2.5 py-1.5 text-sm leading-none text-red-600">
                                  <span>Required Per Associate</span>
                                </span>
                              )}

                              {fileType.required_per_state && (
                                <span className="inline-flex whitespace-nowrap rounded bg-red-100 px-2.5 py-1.5 text-sm leading-none text-red-600">
                                  <span>Required Per State</span>
                                </span>
                              )}

                              {fileType.optional && (
                                <span className="inline-flex whitespace-nowrap rounded bg-gray-200 px-2.5 py-1.5 text-sm leading-none text-gray-600">
                                  <span>Optional</span>
                                </span>
                              )}
                            </div>
                          </td>

                          <td
                            className="hidden cursor-pointer px-6 py-3 text-gray-700 md:table-cell"
                            onClick={() => onRowClicked(fileType)}
                          >
                            {fileType.filename}
                          </td>

                          <td
                            className="hidden cursor-pointer whitespace-nowrap px-6 py-3 text-gray-700 md:table-cell"
                            onClick={() => onRowClicked(fileType)}
                          >
                            <div
                              className={classNames(
                                Boolean(fileType.deleted_at)
                                  ? 'bg-red-100 text-red-700'
                                  : {
                                      'bg-green-50 text-green-700': Boolean(fileType.active),
                                      'bg-gray-200 text-gray-600': !Boolean(fileType.active),
                                    },
                                'inline-flex items-center whitespace-nowrap rounded-full p-1 px-2.5 text-sm font-medium uppercase leading-none',
                              )}
                            >
                              {Boolean(fileType.deleted_at) ? (
                                <span>Deleted</span>
                              ) : (
                                <Fragment>
                                  {Boolean(fileType.active) && <span>Active</span>}
                                  {!Boolean(fileType.active) && <span>Inactive</span>}
                                </Fragment>
                              )}
                            </div>
                          </td>

                          <td
                            className="hidden cursor-pointer whitespace-nowrap px-6 py-3 text-gray-700 md:table-cell"
                            onClick={() => onRowClicked(fileType)}
                          >
                            {fileType.updated_at}
                          </td>

                          <td className="cursor-pointer whitespace-nowrap py-3 pr-6 text-right font-medium">
                            <div className="flex items-center justify-end">
                              <Button theme="icon" onClick={() => onRowClicked(fileType)}>
                                <i className="far fa-pen text-lg text-gray-500 group-hover:font-bold group-hover:text-blue-500"></i>
                              </Button>

                              {fileType.controlled || !Boolean(fileType.deleted_at) ? (
                                <button
                                  role="button"
                                  className={classNames(
                                    'relative ml-2 inline-flex h-10 w-10 items-center justify-center rounded leading-none',
                                    fileType.controlled
                                      ? 'cursor-not-allowed'
                                      : 'transition-all duration-150 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-red-600',
                                  )}
                                  onClick={() => onDelete(fileType.id)}
                                  disabled={fileType.controlled}
                                >
                                  <span className="sr-only">Delete Email Template</span>
                                  <i
                                    className={classNames('far fa-trash text-lg', fileType.controlled ? 'text-gray-200' : 'text-red-600')}
                                  ></i>
                                </button>
                              ) : (
                                <Button theme="icon" onClick={() => onRestore(fileType.id)}>
                                  <i className="far fa-trash-restore text-lg text-gray-600 group-hover:text-gray-900"></i>
                                </Button>
                              )}
                            </div>
                          </td>
                        </tr>
                      ))}

                      {records.length == 0 && (
                        <tr>
                          <td colSpan="8" className="px-6 py-12">
                            <div className="flex flex-col text-center">
                              <span className="text-2xl font-semibold text-red-600">No file types match your current filters.</span>
                              <span className="text-lg font-medium text-gray-700">
                                Expecting to see results? Check your filters to make sure you're not excluding results.
                              </span>
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            {records.length > 0 && <Pagination paginator={paginator} />}
          </div>
        </div>
      </div>

      <Edit open={open} record={data} onClosed={() => onClosed()} />
    </div>
  )
}

// Persistent layout
// Docs: https://inertiajs.com/pages#persistent-layouts
AdminFileTypeIndex.layout = (page) => <Main title="File Types" children={page} />

export default AdminFileTypeIndex
