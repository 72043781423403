import React, { useEffect, useRef, useState } from 'react'
import Button from '@/Shared/Button'
import Checkbox from '@/Shared/Forms/Checkbox'
import { router } from '@inertiajs/react'
import { usePage } from '@inertiajs/react'
import Select from '@/Shared/Forms/Select'
import SlideOver from '@/Shared/SlideOver'
import TextInput from '@/Shared/Forms/TextInput'

export default ({ open, onClosed }) => {
  let locationRef = useRef(null)
  const { filters } = usePage().props
  const [form, setForm] = useState({
    location: '',
    radius: '5',
    deleted: false,
  })
  const radiuses = [
    { value: '5', label: 'within 5 miles' },
    { value: '10', label: 'within 10 miles' },
    { value: '15', label: 'within 15 miles' },
    { value: '25', label: 'within 25 miles' },
    { value: '50', label: 'within 50 miles' },
    { value: '100', label: 'within 100 miles' },
  ]

  useEffect(() => {
    if (filters) {
      setForm({
        location: filters.location || '',
        radius: filters.radius || '5',
      })
    }
  }, [filters])

  const onSubmit = (e) => {
    e.preventDefault()

    let data = { ...form }
    if (form.location != filters?.location || form.radius != filters?.radius) {
      data = { ...data, sortBy: 'distance', direction: 'asc' }
    }

    const values = filters ? { ...filters, ...data } : { ...data }
    const query = encodeURIComponent(JSON.stringify(values))

    router.get(
      route(route().current(), { filters: query }),
      {},
      {
        preserveState: true,
        onStart: () => onClosed(),
      },
    )
  }

  return (
    <SlideOver
      focusRef={locationRef}
      footerActions={
        <Button theme="solid" type="submit" form="contactFilters">
          Apply
        </Button>
      }
      show={open}
      title="Filters"
      onClosed={onClosed}
      aboveMessages
    >
      <form id="contactFilters" className="flex h-full flex-col" onSubmit={onSubmit}>
        <TextInput
          ref={locationRef}
          label="Near"
          name="location"
          value={form.location}
          onChange={(value) => setForm({ ...form, location: value })}
        />

        <Select
          isClearable={false}
          label="Distance from Above"
          name="location"
          options={radiuses}
          value={form.radius}
          onChange={(selected) => setForm({ ...form, radius: selected.value })}
        />

        <div className="flex justify-center">
          <Checkbox
            name="deleted"
            label="Show Deleted"
            value={form.deleted}
            onChange={(selected) => setForm({ ...form, deleted: selected })}
          />
        </div>
      </form>
    </SlideOver>
  )
}
