import { useContext, useEffect, useRef, useState } from 'react'
import axios from '@/apis/axios'
import { AccordionContext } from '@/Shared/Accordion'
import Button from '@/Shared/Button'
import DualListbox from '@/Shared/DualListbox'
import { router } from '@inertiajs/react'
import Select from '@/Shared/Forms/Select'

export default ({ user }) => {
  const focusRef = useRef()
  const { onSaved } = useContext(AccordionContext)

  const [data, setData] = useState({
    setting: 'service-areas',
    service_areas: user.service_areas,
  })
  const [adding, setAdding] = useState(true)
  const [selectedState, setSelectedState] = useState(null)
  const [selectedCounty, setSelectedCounty] = useState(null)
  const [counties, setCounties] = useState([])
  const [cities, setCities] = useState([])
  const [searchAvailable, setSearchAvailable] = useState(null)
  const [searchSelected, setSearchSelected] = useState(null)
  const [errors, setErrors] = useState({})

  useEffect(() => {
    focusRef.current.focus()
  }, [])

  useEffect(() => {
    if (selectedState) {
      getCities(selectedCounty)
    } else if (cities.length > 0) {
      setCities([])
    }
  }, [selectedState, selectedCounty])

  useEffect(() => {
    if (!adding) {
      filterSelectedCounties()
    }
  }, [data])

  const states = [
    { value: 'MA', label: 'Massachusetts' },
    { value: 'NH', label: 'New Hampshire' },
    { value: 'RI', label: 'Rhode Island' },
  ]

  const filteredCities = () => {
    let selectedStates = data.service_areas.filter((city) => city.value === city.state).map((city) => city.state)
    let selectedCounties = data.service_areas.filter((city) => city.value === city.county).map((city) => city.county)

    return cities.filter(
      (city) =>
        !data.service_areas.find((sc) => sc.value == city.value) &&
        !selectedStates.find((state) => state == city.state) &&
        !selectedCounties.find((county) => county == city.county) &&
        (!searchAvailable || city.label.toLowerCase().includes(searchAvailable.toLowerCase())),
    )
  }

  const filterSelectedCities = (options) => {
    let selected = options || data.service_areas
    let wholeStates = selected.filter((city) => city.value === city.state).map((city) => city.state)
    let wholeCounties = selected.filter((city) => city.value === city.county).map((city) => city.county)

    return selected
      .filter(
        (city) =>
          !wholeStates.find((state) => state != city.value && state == city.state) &&
          !wholeCounties.find((county) => county != city.value && county == city.county) &&
          (!searchSelected || city.label.toLowerCase().includes(searchSelected.toLowerCase())),
      )
      .sort((a, b) => {
        if (!a.label || !b.label) return 0
        return a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 1
      })
  }

  const filterSelectedCounties = () => {
    setAdding(true)

    let counties = data.service_areas
      .filter((c) => c.value !== c.county && c.state === selectedState)
      .reduce(
        (carry, option) =>
          !carry.find((c) => c.county == option.county && c.state == option.state)
            ? carry.concat({ county: option.county, state: option.state })
            : carry,
        [],
      )

    if (counties.length == 0) return

    let updated = data.service_areas
    counties
      .filter((county) => selectedCounty === '' || county.county === selectedCounty)
      .map((county) => {
        let areMoreCities =
          cities
            .filter(
              (city) =>
                !data.service_areas.find((area) => area.value === city.value && area.county === city.county && area.state === city.state),
            )
            .find((city) => city.value !== county.county && city.county === county.county && city.state === county.state) != undefined

        if (!areMoreCities) {
          updated = updated.concat({
            value: county.county,
            label: `All of ${county.county}, ${county.state}`,
            county: county.county,
            state: county.state,
          })
        }
      })

    setData({ ...data, service_areas: filterSelectedCities(updated) })
  }

  // prettier-ignore
  const addToSelected = (highlighted) => {
    setAdding(false)
    setData({...data, service_areas: filterSelectedCities(data.service_areas.concat(
        filteredCities().filter((_, index) => highlighted.indexOf(index) >= 0)
      ))
    })
  }

  const addAllToSelected = () => {
    setAdding(false)
    setData({ ...data, service_areas: filterSelectedCities(data.service_areas.concat(filteredCities())) })
  }

  const getCounties = (state) => {
    setCounties([])

    return axios
      .get(route('api.counties.index'), {
        params: {
          state: state,
        },
      })
      .then((response) => {
        setCounties(response.data || [])
      })
  }

  const getCities = (county) => {
    return axios
      .get(route('api.cities.index'), {
        params: {
          state: selectedState,
          county: county,
        },
      })
      .then((response) => {
        let cities = response.data.areas.map((city) => ({ ...city, label: `${city.label}, ${city.state}` })) || []

        if (cities.length > 0) {
          if (selectedCounty === '') {
            cities.unshift({
              value: selectedState,
              label: `All of ${states.find((state) => state.value === selectedState).value}`,
              county: '',
              state: selectedState,
            })
          } else {
            cities.unshift({
              value: selectedCounty,
              label: `All of ${counties.find((county) => county.value === selectedCounty)?.label}, ${selectedState}`,
              county: selectedCounty,
              state: selectedState,
            })
          }
        }

        setCities(cities)
      })
  }

  const removeFromSelected = (highlighted) => {
    setData({ ...data, service_areas: filterSelectedCities(data.service_areas.filter((_, index) => highlighted.indexOf(index) < 0)) })
  }

  const removeAllFromSelected = () => {
    setData({ ...data, service_areas: [] })
  }

  const stateChanged = (selected) => {
    setSelectedState(selected)
    getCounties(selected).then((_) => setSelectedCounty(''))
  }

  const submit = (event) => {
    event.preventDefault()

    axios
      .post(route('api.users.update', user.id), { ...data, _method: 'PATCH' })
      .then((_) =>
        router.reload({
          onFinish: () => {
            onSaved()
          },
        }),
      )
      .catch((error) => {
        if (error.response != undefined) {
          const { status } = error.response
          const VALIDATION_ERROR = 422
          if (status == VALIDATION_ERROR) {
            setErrors(error.response.data.errors)
          }
        }
      })
  }

  return (
    <div>
      <p>
        This is where you specify the towns you service. The Selected Towns will be used when individuals search for your type(s) of
        services.
      </p>

      {errors.service_areas && (
        <div
          className="mt-3 rounded-lg border border-red-500 bg-red-50 p-2 text-center font-medium text-red-600"
          dangerouslySetInnerHTML={{ __html: errors.service_areas }}
        ></div>
      )}

      <form className="mx-auto mt-4 max-w-3xl space-y-1.5" onSubmit={submit}>
        <div className="mb-4 space-y-3 sm:flex sm:items-start sm:justify-between sm:gap-4 sm:space-y-0">
          <div className="flex-1">
            <Select
              ref={focusRef}
              label="State"
              classes="mb-1"
              name="state"
              options={states}
              placeholder="Select a State"
              value={selectedState}
              onChange={(selected) => stateChanged(selected && selected.value)}
              isClearable={false}
              required
            />
          </div>
          <div className="flex-1">
            <Select
              label="County"
              classes="mb-1"
              name="counties"
              options={counties}
              placeholder="Select a County"
              value={selectedCounty}
              onChange={(selected) => setSelectedCounty(selected && selected.value)}
              disabled={counties.length == 0}
              isClearable={false}
              required
            />
          </div>
        </div>

        <p className="mb-12 text-sm text-gray-800">
          Select the town(s) you wish to service in the <strong>Available Towns</strong> box and use the add button{' '}
          <i className="fas fa-arrow-right rotate-90 sm:rotate-0"></i> to add your selection to your <strong>Selected Towns</strong>. To
          remove a selected town, select the town(s) you wish to remove from the <strong>Selected Towns</strong> box and use the remove
          button <i className="fas fa-arrow-left rotate-90 sm:rotate-0"></i>.
        </p>

        <DualListbox
          available={filteredCities()}
          availableLabel="Available Towns"
          searchAvailable={searchAvailable}
          searchSelected={searchSelected}
          selected={filterSelectedCities()}
          selectedLabel="Selected Towns"
          onAdd={addToSelected}
          onAddAll={addAllToSelected}
          onRemove={removeFromSelected}
          onRemoveAll={removeAllFromSelected}
          onSearchAvailable={setSearchAvailable}
          onSearchSelected={setSearchSelected}
        />

        <div className="flex justify-end">
          <Button type="submit" theme="solid">
            Save
          </Button>
        </div>
      </form>
    </div>
  )
}
