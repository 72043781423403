import React from 'react'
import Button from '@/Shared/Button'
import Dropdown from '@/Shared/Dropdown'
import DropdownItem from '@/Shared/DropdownItem'
import { Link } from '@inertiajs/react'
import { Tooltip } from '@/Shared/Tooltip'

export default ({ checklist, onEdit }) => {
  return (
    <div className="relative border-b border-gray-200 bg-white pb-6 pt-3">
      <div className="mb-3 px-3">
        <div className="flex items-center justify-between">
          <div>
            <Link
              href={route('admin.checklists.index')}
              className="inline-flex h-10 items-center rounded-md px-3 leading-none text-blue-500 outline-none transition-all duration-150 ease-in-out hover:bg-blue-100 hover:text-gray-800 focus:ring-2 focus:ring-blue-500"
            >
              <i className="far fa-chevron-left mr-3 text-xl"></i>
              <span className="font-medium">Checklists</span>
            </Link>
          </div>

          <div className="flex items-center justify-end gap-0.5">
            <Tooltip label="Edit" placement="bottom">
              <Button theme="icon" onClick={onEdit}>
                <i className="far fa-pen text-gray-500 group-hover:font-bold group-hover:text-blue-500"></i>
              </Button>
            </Tooltip>

            <Dropdown id="actions-options" label="Actions" orientation="right" size="w-64">
              <DropdownItem colorClasses="text-red-500 hover:text-red-600" onClick={() => console.log('marked as deleted')}>
                <i className="fas fa-trash mr-4"></i>
                Delete
              </DropdownItem>
            </Dropdown>
          </div>
        </div>
      </div>

      <div className="px-6">
        <div className="text-center text-2xl">
          <span className="font-medium">{checklist.name} Transaction Checklist</span>
        </div>

        {Boolean(checklist.deleted_at) && (
          <div className="mt-2 flex justify-center">
            <div className="inline-block whitespace-nowrap rounded bg-red-100 px-2.5 py-1.5 text-sm font-medium uppercase leading-none text-red-600">
              <span>Deleted</span>
            </div>
          </div>
        )}

        {!Boolean(checklist.active) && (
          <div className="mt-2 flex justify-center">
            <div className="inline-block whitespace-nowrap rounded bg-yellow-100 px-2.5 py-1.5 text-sm font-medium uppercase leading-none text-yellow-600">
              <span>Inactive</span>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
