import Commissions from '@/Shared/Transaction/Forms/ClosingReport/Commissions'
import Button from '@/Shared/Button'
import FormDialog from '@/Shared/Transaction/Forms/FormDialog'
import Link from '@/Shared/Link'
import Summary from '@/Shared/Transaction/Forms/ClosingReport/Summary'
import { Fragment, useEffect, useState } from 'react'
import { usePage } from '@inertiajs/react'
import classNames from 'classnames'
import Dropdown from '@/Shared/Dropdown'
import DropdownItem from '@/Shared/DropdownItem'
import DatesEdit from './Forms/DatesEdit'
import { DatesProvider } from './Forms/DatesContext'

const CondominiumAssociationManagerOrTrustee = ({ transaction }) => {
  return transaction.association_management_company?.name ? (
    <div className="flex-1 divide-y divide-gray-200 rounded-md border border-gray-200 bg-white shadow">
      <PortletItem label="Name" value={transaction.association_management_company.name} />
      <PortletItem label="Address" value={transaction.association_management_company.address_inline} />
      <PortletItem
        label="Phone"
        value={
          transaction.association_management_company.phone_formatted ? (
            <Link href={`tel:${transaction.association_management_company.phone}`} target="_blank">
              {transaction.association_management_company.phone_formatted}
            </Link>
          ) : (
            ''
          )
        }
      />
      <PortletItem
        label="Email"
        value={
          transaction.association_management_company.email ? (
            <Link href={`mailto:${transaction.association_management_company.email}`} target="_blank">
              {transaction.association_management_company.email}
            </Link>
          ) : (
            ''
          )
        }
      />
    </div>
  ) : (
    <div className="flex-1 text-center">The association management company has not been specified yet.</div>
  )
}

const Portlet = ({ name, children }) => (
  <div className="flex flex-grow flex-col space-y-5 rounded-md border-b border-t border-gray-200 bg-white pb-4 shadow sm:pb-5">
    <div className="flex h-14 items-center justify-between border-b border-gray-200 px-4 sm:px-6">
      {typeof name === 'string' ? <h3 className="text-lg font-medium text-gray-800">{name}</h3> : name}
    </div>

    <div className="space-y-6 px-4 sm:gap-6 sm:px-6">{children}</div>
  </div>
)

const PortletItem = ({ label, value, width }) => (
  <div className="flex flex-col md:flex-row md:divide-x md:divide-gray-300">
    <span
      className={classNames(
        'px-4 pt-2 font-medium leading-tight text-gray-500 md:min-w-[6.75rem] md:bg-gray-100 md:pb-2 md:leading-normal md:text-inherit',
        width,
      )}
    >
      {label}
    </span>
    <span className="px-4 pb-2 text-lg font-medium md:flex-1 md:pt-2 md:text-base">
      {value || <span className="text-gray-400">--</span>}
    </span>
  </div>
)

const ContactGroup = ({ name, contacts }) => {
  return (
    <Portlet
      name={
        <span>
          {name}
          {contacts.length > 1 ? `s (${contacts.length})` : ''}
        </span>
      }
    >
      {contacts.length > 0 ? (
        contacts.map((contact) => (
          <div className="flex-1 divide-y divide-gray-200 rounded-md border border-gray-200 bg-white shadow" key={contact.id}>
            <PortletItem label="Name" value={contact.full_name} />
            {(contact.company || contact.contact?.company) && (
              <PortletItem label="Company" value={contact.company || contact.contact?.company} />
            )}
            <PortletItem label="Address" value={contact.address_inline || contact.contact?.address_inline} />
            <PortletItem
              label={contact.phone_type || 'Phone'}
              value={
                contact.phone || contact.contact?.phone ? (
                  <Link href={`tel:${contact.phone || contact.contact?.phone}`} target="_blank">
                    {contact.phone_formatted || contact.contact?.phone_formatted}
                  </Link>
                ) : (
                  ''
                )
              }
            />
            <PortletItem
              label="Email"
              value={
                contact.email || contact.contact?.email ? (
                  <Link href={`mailto:${contact.email || contact.contact?.email}`} target="_blank">
                    {contact.email || contact.contact?.email}
                  </Link>
                ) : (
                  ''
                )
              }
            />
            {contact.source && <PortletItem label={`How did the ${name.toLowerCase()} hear of us?`} value={contact.source} />}
          </div>
        ))
      ) : (
        <div className="flex-1 text-center">A {name.toLowerCase()} has not been added yet.</div>
      )}
    </Portlet>
  )
}

export default function TransactionSummary() {
  const [form, setForm] = useState(null)
  const [editing, setEditing] = useState(false)
  const [editingDates, setEditingDates] = useState(false)
  const { transaction } = usePage().props
  const sellers = transaction.contacts.filter((tc) => tc.roles.find((r) => r.party_representing === 'Seller' && r.name === 'Seller'))
  const sellersAttorneys = transaction.contacts.filter((tc) =>
    tc.roles.find((r) => r.party_representing === 'Seller' && r.name === 'Real Estate Attorney'),
  )
  const buyers = transaction.contacts.filter((tc) => tc.roles.find((r) => r.party_representing === 'Buyer' && r.name === 'Buyer'))
  const buyersAttorneys = transaction.contacts.filter((tc) =>
    tc.roles.find((r) => r.party_representing === 'Buyer' && r.name === 'Real Estate Attorney'),
  )
  const closingAttorneys = transaction.contacts.filter((tc) => tc.roles.find((r) => r.name === 'Closing Title Attorney'))
  const condominiumAssociationManagerOrTrustee = transaction.contacts
    .filter((tc) => tc.roles.find((r) => r.name === 'Condominium Association Manager or Trustee'))
    .slice(0, 1)
  const associates = transaction.contacts.filter((tc) => tc.contact_type === 'Associate' && !tc.is_transaction_owner)
  const leadAssociate = transaction.contacts.find((tc) => tc.is_transaction_owner)
  const closingReportComplete = transaction.total_purchase_price > 0 && transaction[`commission_${transaction.type.toLowerCase()}`] > 0
  const commissionStatement = transaction.allFiles.find((file) => file.controlled && file.type.includes('Agent Commission Statement'))

  useEffect(() => setEditing(Boolean(form)), [form])

  const formatDate = (date) => {
    if (!date.value) {
      return 'N/A'
    }

    const currentDate = new Date()
    const targetDate = new Date(date.value)
    const options = {
      weekday: 'long',
      month: 'long',
      day: 'numeric',
      hour: date.show_time && 'numeric',
      minute: date.show_time && 'numeric',
    }

    // Check if the year of the target date is different from the current year
    if (targetDate.getFullYear() !== currentDate.getFullYear()) {
      options.year = 'numeric'
    }

    return targetDate.toLocaleDateString('en-US', options)
  }

  const viewClosingReport = () => {
    window.open(route('transactions.closing-report.show', transaction.id), '_blank')
  }

  const viewCommissionStatement = () => {
    window.open(route('transactions.files.show', commissionStatement.id), '_blank')
  }

  const openForm = () => {
    axios.get(route('transactions.forms.show', transaction.forms.find((f) => f.name === 'Closing Worksheet').id)).then((response) => {
      setForm(response.data)
    })
  }

  return (
    <div className="mb-16 space-y-4 py-4 md:py-0">
      <div className="mt-1 flex justify-end gap-1">
        {!transaction.restricted && transaction.editable && (
          <Button theme="solid" onClick={() => openForm()}>
            <i className="fas fa-file-spreadsheet mr-2 text-lg"></i>
            <span className="pr-1">Closing Worksheet</span>
          </Button>
        )}

        <Dropdown id="actions-options" label="Reports" orientation="right" size="w-64">
          <DropdownItem onClick={() => viewClosingReport()} disabled={!closingReportComplete}>
            <div className="flex items-center gap-3">
              <i className={classNames('fas fa-file-pdf text-2xl', !closingReportComplete ? 'text-gray-300' : 'text-gray-600')}></i>
              <span>Closing Report</span>
            </div>
          </DropdownItem>

          <DropdownItem onClick={() => viewCommissionStatement()} disabled={!commissionStatement}>
            <div className="flex items-center gap-3">
              <i className={classNames('fas fa-file-pdf text-2xl', !commissionStatement ? 'text-gray-300' : 'text-gray-600')}></i>
              <span>Commission Statement</span>
            </div>
          </DropdownItem>
        </Dropdown>
      </div>

      <div className="flex flex-col gap-4 xl:flex-row xl:gap-8">
        <div className="order-2 flex-1 space-y-4 xl:order-1">
          {transaction.type === 'Buyer' ? (
            <ContactGroup name="Buyer" contacts={buyers} />
          ) : (
            <ContactGroup name="Seller" contacts={sellers} />
          )}
          {transaction.type === 'Buyer' ? (
            <ContactGroup name="Seller" contacts={sellers} />
          ) : (
            <ContactGroup name="Buyer" contacts={buyers} />
          )}

          {transaction.type === 'Buyer' ? (
            <Fragment>
              <Portlet name="Purchase Property">
                <div className="flex-1 divide-y divide-gray-200 rounded-md border border-gray-200 bg-white">
                  {transaction.property_address_inline ? (
                    <PortletItem label="Address" value={transaction.property_address_inline} />
                  ) : (
                    <div className="flex-1 text-center">A property has not been specified yet.</div>
                  )}
                </div>
              </Portlet>

              {transaction.propertyTypes.find((type) => type.name === 'Condominium') && (
                <Fragment>
                  {condominiumAssociationManagerOrTrustee.length == 1 ? (
                    <ContactGroup name="Condominium Association Manager or Trustee" contacts={condominiumAssociationManagerOrTrustee} />
                  ) : (
                    <Portlet name="Condominium Association Manager or Trustee">
                      <CondominiumAssociationManagerOrTrustee transaction={transaction} />
                    </Portlet>
                  )}
                </Fragment>
              )}

              <ContactGroup name="Buyer's Real Estate Attorney" contacts={buyersAttorneys} />
            </Fragment>
          ) : (
            <Fragment>
              {transaction.propertyTypes.find((type) => type.name === 'Condominium') && (
                <Fragment>
                  {condominiumAssociationManagerOrTrustee.length == 1 ? (
                    <ContactGroup name="Condominium Association Manager or Trustee" contacts={condominiumAssociationManagerOrTrustee} />
                  ) : (
                    <Portlet name="Condominium Association Manager or Trustee">
                      <CondominiumAssociationManagerOrTrustee transaction={transaction} />
                    </Portlet>
                  )}
                </Fragment>
              )}

              <ContactGroup name="Seller's Attorney" contacts={sellersAttorneys} />
            </Fragment>
          )}

          <ContactGroup name="Closing Attorney" contacts={closingAttorneys} />
        </div>

        <div className="order-1 flex-1 space-y-4 xl:order-2">
          {transaction.dates.length > 0 && (
            <Portlet
              name={
                <Fragment>
                  <h3 className="text-lg font-medium text-gray-800">Important Dates</h3>
                  <div className="flex items-end">
                    <Button theme="icon" onClick={() => setEditingDates(true)} disabled={!transaction.editable}>
                      <i className="far fa-pen text-gray-500 group-hover:font-bold group-hover:text-blue-500"></i>
                    </Button>
                  </div>
                </Fragment>
              }
            >
              <div className="flex-1 divide-y divide-gray-200 rounded-md border border-gray-200 bg-white">
                {transaction.dates.map((date, index) => (
                  <PortletItem label={date.label} value={formatDate(date)} width="sm:w-1/2 lg:w-64" key={index} />
                ))}
              </div>
            </Portlet>
          )}

          <Portlet name="Closing Details">
            <div className="flex-1 divide-y divide-gray-200 rounded-md border border-gray-200 bg-white">
              <PortletItem label={`Lead Associate Name`} value={leadAssociate.full_name} width="md:w-64" />
              {associates.length > 0 &&
                associates
                  .filter((associate) => associate.split > 0)
                  .map((associate) => (
                    <PortletItem label="Co-Serve Associate Name" value={associate.full_name} width="md:w-64" key={associate.id} />
                  ))}
            </div>

            <Summary transaction={transaction} />
          </Portlet>

          <Portlet name="Commissions">
            <Commissions data={transaction} />
          </Portlet>
        </div>
      </div>

      {form && (
        <FormDialog
          open={editing}
          record={form}
          onClosed={() => {
            setEditing(false)
            setTimeout(() => {
              setForm(null)
            }, 100)
          }}
        />
      )}

      {transaction.editable && (
        <DatesProvider>
          <DatesEdit open={editingDates} onClosed={() => setEditingDates(false)} />
        </DatesProvider>
      )}
    </div>
  )
}
