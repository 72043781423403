import React from 'react'
import classNames from 'classnames'
import { Link as InertiaLink } from '@inertiajs/react'

const Link = React.forwardRef(({ children, className, colors, disabled, focusRef, inertia, theme, ...props }, ref) => {
  let baseClasses = classNames(
    'group inline-flex items-center relative font-medium leading-none rounded-md outline-none transition-all duration-150 ease-in-out focus:ring-2',
  )

  const borderClasses = (size) =>
    classNames(
      'ml-2 bg-white border border-primary-500 shadow-sm text-gray-700',
      {
        'px-4 py-2.5 text-base': size === 'sm',
        'h-10 px-3 xl:px-6': !size,
      },
      !disabled && 'hover:bg-gray-200 hover:text-gray-800 focus:border-transparent focus:ring-primary-500',
      disabled && 'opacity-50 cursor-not-allowed',
    )
  const cleanClasses = () => 'px-4 h-10 ml-2 text-gray-600 hover:bg-primary-500 hover:text-white focus:ring-primary-500'
  const cleanIconClasses = () => 'justify-center w-10 h-10 ml-2 focus:ring-primary-500'
  const iconClasses = (size) => {
    return classNames(
      'justify-center ml-2',
      {
        'w-7 h-7': size === 'sm',
        'w-10 h-10': !size,
      },
      disabled
        ? ['cursor-not-allowed', colors || 'text-gray-300']
        : ['focus:ring-primary-500', colors || 'hover:bg-primary-100 hover:text-gray-800'],
    )
  }
  const linkClasses = () =>
    classNames(
      'justify-center',
      disabled
        ? 'cursor-not-allowed text-gray-300'
        : 'text-primary-500 hover:underline underline-offset-4 focus:ring-offset-4 focus:ring-primary-500',
    )
  const outlineClasses = (size) =>
    classNames(
      'ml-2 bg-white border border-gray-300 shadow-sm text-gray-700',
      {
        'px-4 py-2.5 text-base': size === 'sm',
        'h-10 px-3 xl:px-6': !size,
      },
      !disabled && 'hover:bg-gray-200 hover:text-gray-800 focus:border-transparent focus:ring-primary-500',
      disabled && 'opacity-50 cursor-not-allowed',
    )
  const solidClasses = () => {
    return classNames(
      'px-6 ml-2 h-10 text-white shadow-sm',
      colors || 'focus:ring-primary-500',
      disabled
        ? ['shadow-inner cursor-not-allowed', colors || 'bg-gray-300 text-gray-500']
        : ['focus:ring-offset-1', colors || 'bg-primary-500 hover:bg-primary-900'],
    )
  }
  const toolbarIconClasses = () => {
    return 'py-2 px-3 ml-1 shadow-sm h-10 hover:bg-white hover:bg-opacity-10 focus:ring-inset focus:ring-white'
  }

  return inertia ? (
    <InertiaLink
      ref={ref}
      className={classNames(
        baseClasses,
        {
          border: borderClasses(),
          clean: cleanClasses(),
          'clean-icon': cleanIconClasses(),
          icon: iconClasses(),
          'icon-sm': iconClasses('sm'),
          link: linkClasses(),
          outline: outlineClasses(),
          'outline-sm': outlineClasses('sm'),
          solid: solidClasses(),
          'toolbar-icon': toolbarIconClasses(),
        }[theme],
        className,
      )}
      disabled={disabled}
      {...props}
    >
      {children}
    </InertiaLink>
  ) : (
    <a
      ref={ref}
      className={classNames(
        baseClasses,
        {
          border: borderClasses(),
          clean: cleanClasses(),
          'clean-icon': cleanIconClasses(),
          icon: iconClasses(),
          'icon-sm': iconClasses('sm'),
          link: linkClasses(),
          outline: outlineClasses(),
          'outline-sm': outlineClasses('sm'),
          solid: solidClasses(),
          'toolbar-icon': toolbarIconClasses(),
        }[theme],
        className,
      )}
      disabled={disabled}
      {...props}
    >
      {children}
    </a>
  )
})

Link.defaultProps = {
  theme: 'link',
}

export default Link
