import { Fragment, useState } from 'react'
import AccordionCard from '@/Shared/AccordionCard'
import Button from '@/Shared/Button'
import classNames from 'classnames'
import Edit from './Pages/Edit'
import { router } from '@inertiajs/react'
import { Tooltip } from '@/Shared/Tooltip'
import { usePage } from '@inertiajs/react'
import { CSS } from '@dnd-kit/utilities'
import { DndContext, DragOverlay, closestCenter, KeyboardSensor, PointerSensor, TouchSensor, useSensor, useSensors } from '@dnd-kit/core'
import { arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy, useSortable } from '@dnd-kit/sortable'

const SortableItem = ({ editable, item, onClick }) => {
  const disabled = !item.published

  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: item.id })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  return (
    <div
      ref={setNodeRef}
      className={classNames('flex h-12 items-center justify-between gap-3 bg-white pr-3', editable ? 'pl-1.5' : 'pl-3')}
      style={style}
    >
      {editable && (
        <div className="shrink-0">
          <Button className="cursor-move touch-none" theme="icon" {...attributes} {...listeners}>
            <i className="fas fa-grip-vertical text-gray-400 text-opacity-75"></i>
          </Button>
        </div>
      )}

      <div className={classNames('flex-1 font-semibold leading-tight text-gray-900', { 'text-opacity-40': disabled })}>{item.title}</div>

      <div className="flex shrink-0 items-center">
        <Tooltip label="Visit Page" placement="bottom">
          <Button theme="icon" disabled={disabled} onClick={() => onClick(item)}>
            <span className="sr-only">Visit Page</span>
            <i
              className={classNames(
                'far fa-external-link text-lg text-gray-500',
                disabled ? 'text-opacity-40' : 'group-hover:font-bold group-hover:text-primary-500',
              )}
            ></i>
          </Button>
        </Tooltip>
      </div>
    </div>
  )
}

const Draggable = ({ editable, items, activeId, onClick }) => {
  const activeItem = items.find((item) => item.id === activeId)

  return (
    <Fragment>
      <SortableContext items={items} strategy={verticalListSortingStrategy}>
        {items.map((item, index) => (
          <SortableItem key={item.id} editable={editable} index={index} item={item} onClick={onClick} />
        ))}
      </SortableContext>

      <DragOverlay>{activeItem ? <SortableItem editable={editable} item={activeItem} onClick={onClick} /> : null}</DragOverlay>
    </Fragment>
  )
}

const Sortable = ({ editable, items: original, onSort, onClick }) => {
  const [activeId, setActiveId] = useState(null)
  const [items, setItems] = useState(original)

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  )

  function handleDragStart(event) {
    const { active } = event
    setActiveId(active.id)
  }

  const handleDragEnd = (event) => {
    setActiveId(null)

    const { active, over } = event
    if (active.id !== over.id) {
      const oldIndex = items.findIndex((item) => item.id == active.id)
      const newIndex = items.findIndex((item) => item.id == over.id)

      let newItems = arrayMove(items, oldIndex, newIndex).map((item, index) => ({ ...item, rank: index + 1 }))

      setItems(newItems)
      onSort(newItems)
    }
  }

  return (
    <DndContext sensors={sensors} collisionDetection={closestCenter} onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
      <SortableContext items={items} strategy={verticalListSortingStrategy}>
        <Draggable editable={editable} items={items} activeId={activeId} onClick={onClick} />
      </SortableContext>
    </DndContext>
  )
}

export default () => {
  const { lsn } = usePage().props
  const { pages, servicePages } = lsn
  const [editing, setEditing] = useState(false)
  const [page, setPage] = useState(null)

  const onSortPages = (sorted) => {
    router.patch(route('lsns.pages.sort', { lsn: lsn.id }), { pages: sorted })
  }

  const onClose = () => {
    setEditing(false)
    setTimeout(() => {
      setPage(null)
    }, 300)
  }

  const onEdit = (page) => {
    setPage(page)
    setEditing(true)
  }

  const onVisit = (permalink) => {
    window.open(permalink, '_blank')
  }

  return (
    <div className="space-y-5">
      {(lsn.editable || lsn.published) && (
        <AccordionCard title={`Standard Pages (${pages.length})`}>
          <div className="divide-y divide-dashed divide-gray-200 overflow-hidden rounded-md border border-gray-200">
            {pages.map((page) => (
              <div className="flex h-12 items-center justify-between gap-4 pl-6 pr-4" key={page.id}>
                <div className="font-semibold leading-tight text-gray-900">{page.name}</div>

                <div className="flex shrink-0 items-center">
                  <Tooltip label="Visit Page" placement="bottom">
                    <Button theme="icon" onClick={() => onVisit(page.permalink)}>
                      <span className="sr-only">Visit Page</span>
                      <i className="far fa-external-link text-lg text-gray-500 group-hover:font-bold group-hover:text-primary-500"></i>
                    </Button>
                  </Tooltip>

                  {lsn.editable && (
                    <Tooltip label="Edit" placement="bottom">
                      <Button theme="icon" onClick={() => onEdit(page)}>
                        <span className="sr-only">Edit Page</span>
                        <i className="far fa-pen text-gray-500 group-hover:font-bold group-hover:text-primary-500"></i>
                      </Button>
                    </Tooltip>
                  )}
                </div>
              </div>
            ))}
          </div>
        </AccordionCard>
      )}

      <AccordionCard title={`Member Pages (${servicePages.length})`}>
        <div className="divide-y divide-dashed divide-gray-200 overflow-hidden rounded-md border border-gray-200">
          <Sortable
            editable={lsn.editable}
            items={servicePages}
            onSort={(sorted) => onSortPages(sorted)}
            onClick={(page) => onVisit(`${lsn.permalink}/${page.slug}${!lsn.published ? `?k=${lsn.preview_key}` : ''}`)}
          />
        </div>
      </AccordionCard>

      <Edit page={page} open={editing} onClosed={() => onClose()} />
    </div>
  )
}
