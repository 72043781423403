import React from 'react'
import { router } from '@inertiajs/react'
import { usePage } from '@inertiajs/react'
import HeaderColumn from '@/Shared/Table/HeaderColumn'

export default () => {
  const { filters } = usePage().props
  const { sortBy, direction } = filters

  const sort = (field) => {
    const values = filters
      ? { ...filters, sortBy: field, direction: field === sortBy ? (direction === 'asc' ? 'desc' : 'asc') : 'asc' }
      : {
          sortBy: field,
          direction: field === sortBy ? (direction === 'asc' ? 'desc' : 'asc') : 'asc',
        }
    const query = encodeURIComponent(JSON.stringify(values))

    router.get(
      route(route().current(), { filters: query }),
      {},
      {
        preserveState: true,
      },
    )
  }

  return (
    <tr>
      <HeaderColumn sorting={sortBy} direction={direction} field="name" onSort={(field) => sort(field)} noBorder>
        Name
      </HeaderColumn>
      <HeaderColumn classes="hidden sm:table-cell" width="120px">
        Category
      </HeaderColumn>
      <HeaderColumn classes="hidden sm:table-cell">States</HeaderColumn>
      <HeaderColumn classes="hidden md:table-cell" width="120px">
        Status
      </HeaderColumn>
      <HeaderColumn
        classes="hidden lg:table-cell"
        direction={direction}
        field="updated_at"
        width="150px"
        sorting={sortBy}
        onSort={(field) => sort(field)}
      >
        Last Activity
      </HeaderColumn>
      <HeaderColumn width="50px" noHover>
        <span className="sr-only">Edit</span>
      </HeaderColumn>
    </tr>
  )
}
