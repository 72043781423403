import classNames from 'classnames'
import Switch from '@/Shared/Forms/Switch'

export default ({ category, disabled, setting, onChange }) => {
  return (
    <div className={classNames('relative flex items-center py-4', { 'cursor-not-allowed opacity-40': disabled })}>
      <i
        className={classNames(
          'mx-2 flex h-8 w-8 justify-center text-2xl text-gray-400',
          {
            admin: 'far fa-user-crown',
            contacts: 'fas fa-users',
            lsn: 'far fa-users',
            human_resources: 'fas fa-user-crown',
            moderator: 'fas fa-user-shield',
            openhouse: 'far fa-home',
            other: 'fas fa-ellipsis-h',
          }[category.type],
        )}
      ></i>

      <div className="mx-4 flex-1">
        <label htmlFor={`notification-${category.type}`} className="select-none font-medium text-gray-900">
          <p>{category.label}</p>
          <p id={`notification-${category.type}-description`} className="text-gray-500">
            {category.description}
          </p>
        </label>
      </div>

      <div className="mx-3 flex h-7 items-center">
        <Switch accessibleText={category.label} disabled={disabled} model={setting.active} onChange={() => onChange(!setting.active)} />
      </div>
    </div>
  )
}
