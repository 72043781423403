import { useState } from 'react'
import AccordionCard from '@/Shared/AccordionCard'
import Button from '@/Shared/Button'
import Edit from './Edit'
import Helpers from '@/utils/helpers'
import PropertyListItem from '@/Shared/PropertyListItem'
import { usePage } from '@inertiajs/react'

export default () => {
  const { associate } = usePage().props.contact
  const { user } = usePage().props.auth
  const [open, setOpen] = useState(false)

  return (
    <AccordionCard
      title={`HR Dates (${associate.hr_dates?.length || 0})`}
      actions={
        Helpers.isAdminOrHR(user) && (
          <Button theme="icon" onClick={() => setOpen(true)}>
            <span className="sr-only">Edit</span>
            <i className="far fa-pen text-gray-500 group-hover:font-bold group-hover:text-blue-500"></i>
          </Button>
        )
      }
      indented={associate.hr_dates?.length == 0}
      collapsed
    >
      {associate.hr_dates?.length > 0 ? (
        associate.hr_dates.map((date, index) => <PropertyListItem label={date.type} value={<span>{date.value}</span>} key={index} />)
      ) : (
        <div className="text-gray-700">No HR Dates have been added.</div>
      )}

      <Edit open={open} onClosed={() => setOpen(false)} />
    </AccordionCard>
  )
}
