import { Fragment, useState } from 'react'
import AccordionCard from '@/Shared/AccordionCard'
import Button from '@/Shared/Button'
import classNames from 'classnames'
import MySupportNetwork from './MySupportNetwork'
import PropertyListItem from '@/Shared/PropertyListItem'
import { Tooltip } from '@/Shared/Tooltip'
import { router, usePage } from '@inertiajs/react'
import helpers from '../../utils/helpers'

const Wrapper = ({ children, editable, preview, title, onEdit }) =>
  preview ? (
    <AccordionCard
      title={title || 'About this Contact'}
      actions={
        editable && (
          <Tooltip label="Edit" placement="left">
            <Button theme="icon" onClick={onEdit}>
              <i className="far fa-pen text-gray-500 group-hover:font-bold group-hover:text-primary-500"></i>
            </Button>
          </Tooltip>
        )
      }
      hideBorder
      indented={false}
    >
      <div className="border-b border-gray-200">{children}</div>
    </AccordionCard>
  ) : (
    <div className="border-b border-gray-200">{children}</div>
  )

export default ({ contact, editable, preview, title, onContactChanged, onEdit, onOpenTags }) => {
  const { user } = usePage().props.auth
  const administrating = route().current().startsWith('admin')
  const associate = contact.associate || null
  const [detailsExpanded, setDetailsExpanded] = useState(preview ? false : true)

  return (
    <Fragment>
      <Wrapper editable={editable} preview={preview} title={title} onEdit={onEdit}>
        {contact.status != undefined && (
          <PropertyListItem
            label="Status"
            value={
              <span
                className={classNames(
                  'inline-flex items-center whitespace-nowrap rounded px-2.5 py-1.5 text-sm font-medium uppercase leading-none',
                  {
                    'bg-green-50 text-green-700': contact.status === 'public',
                    'bg-yellow-100 text-yellow-600': contact.status === 'internal',
                    'bg-red-100 text-red-600': !contact.status,
                  },
                )}
              >
                <span className="whitespace-pre-line leading-none">
                  {contact.status === 'internal' ? 'PROs Only' : contact.status || 'Private'}
                </span>
              </span>
            }
          />
        )}

        {!preview && associate && (
          <PropertyListItem
            as={['public', 'internal'].indexOf(associate.status) >= 0 ? 'a' : 'div'}
            href={['public', 'internal'].indexOf(associate.status) >= 0 ? associate.permalink : null}
            icon="fas fa-link text-xl"
            label={
              <span className="capitalize">
                Pro-Website (
                <span
                  className={classNames('font-bold uppercase', {
                    'text-red-500': ['disabled', 'private', 'incomplete'].indexOf(associate.status) >= 0,
                    'text-blue-500': ['internal'].indexOf(associate.status) >= 0,
                    'text-green-600': ['public'].indexOf(associate.status) >= 0,
                  })}
                >
                  {associate.status}
                </span>
                )
              </span>
            }
            value={<span className="font-medium">{associate.permalink}</span>}
          />
        )}

        {!Array.isArray(contact.notes) && <PropertyListItem label="Notes" value={contact.notes} />}

        {detailsExpanded && <PropertyListItem label="Prefix" value={contact.prefix} />}
        <PropertyListItem label="First name" value={contact.first_name} />
        {(contact.nickname || detailsExpanded) && <PropertyListItem label="Nickname" value={contact.nickname} />}
        {detailsExpanded && <PropertyListItem label="Middle name" value={contact.middle_name} />}
        <PropertyListItem label="Last name" value={contact.last_name} />
        {detailsExpanded && <PropertyListItem label="Suffix" value={contact.suffix} />}
        <PropertyListItem
          icon="fas fa-building text-xl"
          label="Company"
          value={associate ? 'Better Living Real Estate' : contact.company}
        />
        {!associate && contact.source && (
          <PropertyListItem
            icon="far fa-screen-users text-xl"
            label="Source"
            value={<div className="leading-snug">{typeof contact.source === 'object' ? contact.source.label : contact.source}</div>}
            onClick={typeof contact.source === 'object' ? () => router.visit(contact.source.url) : null}
          />
        )}
        <PropertyListItem label="Title" value={contact.title} />

        {!associate ? (
          <Fragment>
            <PropertyListItem label="License" value={contact.license} />
          </Fragment>
        ) : (
          !administrating &&
          associate.licenses?.length > 0 && (
            <PropertyListItem
              label="Licenses"
              value={associate.licenses.map((license) => (
                <div key={license.license} className={classNames('leading-snug')}>
                  {license.id ? `${license.state} ${license.type} - ` : ''}
                  {license.license}
                </div>
              ))}
            />
          )
        )}

        {contact.industry ? (
          <PropertyListItem label="Industry" value={contact.industry} />
        ) : (
          contact.services && (
            <PropertyListItem
              label="Services Provided"
              value={
                contact.services.length > 0 ? (
                  contact.services.map((service) => (
                    <div key={service.id} className={classNames('leading-snug', { 'font-bold text-orange-500': service.primary })}>
                      {service.name}
                    </div>
                  ))
                ) : (
                  <span className="italic text-gray-500">No services specified</span>
                )
              }
            />
          )
        )}

        {contact.phone_numbers &&
          contact.phone_numbers.length > 0 &&
          contact.phone_numbers
            .filter((phone) => !preview || detailsExpanded || phone.primary)
            .map((phone) => (
              <PropertyListItem
                as="a"
                href={`tel:${phone.phone}`}
                icon={`fas ${phone.type.toLowerCase() === 'mobile' ? 'fa-mobile-alt text-xl' : 'fa-phone text-xl'}`}
                label={phone.type}
                value={phone.phone_formatted}
                primary={phone.primary}
                key={phone.id}
              />
            ))}

        {!contact.phone_numbers && contact.phone && (
          <PropertyListItem
            as="a"
            href={`tel:${contact.phone}`}
            icon={`fas ${contact.phone_type.toLowerCase() === 'mobile' ? 'fa-mobile-alt text-xl' : 'fa-phone text-xl'}`}
            label={contact.phone_type}
            value={contact.phone_formatted}
          />
        )}

        {contact.emails &&
          contact.emails.length > 0 &&
          contact.emails
            .filter((email) => !preview || detailsExpanded || email.primary)
            .map((email) => (
              <PropertyListItem
                as="a"
                href={`mailto:${email.email}`}
                icon="far fa-envelope text-xl"
                label={email.type}
                value={email.email}
                primary={email.primary}
                key={email.id}
                breakAll
              />
            ))}

        {!contact.emails && contact.email && (
          <PropertyListItem
            as="a"
            href={`mailto:${contact.email}`}
            icon="far fa-envelope text-xl"
            label="Email"
            value={contact.email}
            breakAll
          />
        )}

        {contact.addresses &&
          contact.addresses.length > 0 &&
          contact.addresses
            .filter((address) => !preview || detailsExpanded || address.primary)
            .map((address) => (
              <PropertyListItem
                as="a"
                href={`https://maps.google.com/maps?q=${address.query}`}
                icon="fas fa-map-marker-alt text-xl"
                label={address.type}
                value={address.address_inline}
                primary={address.primary}
                key={address.id}
              />
            ))}

        {!contact.addresses && contact.address_inline && (
          <PropertyListItem
            as="a"
            href={`https://maps.google.com/maps?q=${contact.address_query}`}
            icon="fas fa-map-marker-alt text-xl"
            label="Address"
            value={contact.address_inline}
          />
        )}

        {contact.primary_market_location && (
          <PropertyListItem
            icon="far fa-magnifying-glass-location text-xl"
            label="Primary Market Location"
            value={contact.primary_market_location}
          />
        )}

        {detailsExpanded &&
          contact.lsns &&
          contact.lsns.length > 0 &&
          contact.lsns.map((lsn) => (
            <PropertyListItem
              as="a"
              href={`${lsn.url}`}
              icon="text-xl fas fa-users"
              label="Local Services Network (LSN)"
              value={lsn.name}
              key={lsn.id}
            />
          ))}

        {detailsExpanded &&
          contact.social_accounts &&
          contact.social_accounts.length > 0 &&
          contact.social_accounts.map((account) => (
            <PropertyListItem
              as="a"
              href={`${account.url}`}
              icon={classNames('text-xl fab', {
                'fa-facebook': account.type === 'Facebook',
                'fa-flickr': account.type === 'Flickr',
                'fa-instagram': account.type === 'Instagram',
                'fa-linkedin-in': account.type === 'LinkedIn',
                'fa-pinterest-p': account.type === 'Pinterest',
                'fa-snapchat-ghost': account.type === 'Snapchat',
                'fa-tiktok': account.type === 'TikTok',
                'fa-x-twitter': account.type === 'Twitter',
                'fa-youtube': account.type === 'YouTube',
              })}
              label={account.type}
              value={account.url}
              key={account.id}
            />
          ))}

        {detailsExpanded &&
          contact.significant_dates &&
          contact.significant_dates.length > 0 &&
          contact.significant_dates.map((date) => (
            <PropertyListItem icon="far fa-calendar-day text-xl" label={date.type} value={date.date} key={date.id} />
          ))}

        {detailsExpanded &&
          contact.websites &&
          contact.websites.length > 0 &&
          contact.websites.map((website) => (
            <PropertyListItem
              as="a"
              href={`${website.url}`}
              icon="fas fa-link text-xl"
              label={website.type ? `${website.type} website` : 'Website'}
              primary={website.primary}
              value={website.url}
              key={website.id}
              breakAll
            />
          ))}

        {!contact.websites && contact.website && (
          <PropertyListItem
            as="a"
            href={contact.website}
            icon="far fa-external-link text-xl"
            label="Website"
            value={contact.website}
            breakAll
          />
        )}

        {detailsExpanded && contact.tags && contact.tags.length > 0 && (
          <PropertyListItem
            icon="far fa-tag text-xl"
            label="Tags"
            value={contact.tags.map((tag) => (
              <div
                className={classNames('m-0.5 inline-block whitespace-nowrap rounded px-2 py-1.5 leading-none', {
                  'bg-primary-500 text-white': tag.label === 'Unqualified Contacts',
                  'bg-green-500 text-white': tag.label === 'Active Prospects',
                  'bg-yellow-500 text-white': ['Long-Term Prospects', 'On-Hold Clients'].indexOf(tag.label) >= 0,
                  'bg-green-900 text-white': tag.label === 'Active Clients',
                  'bg-gray-200 text-gray-700':
                    ['Unqualified Contacts', 'Long-Term Prospects', 'Active Prospects', 'Active Clients', 'On-Hold Clients'].indexOf(
                      tag.label,
                    ) < 0,
                })}
                key={tag.id}
              >
                <span>{tag.label}</span>
              </div>
            ))}
            onClick={!associate ? onOpenTags : null}
          />
        )}

        {associate && (!preview || detailsExpanded) && (
          <>
            {(associate.vehicle_year ||
              associate.vehicle_color ||
              associate.vehicle_make ||
              associate.vehicle_model ||
              associate.vehicle_state ||
              associate.vehicle_plate) && (
              <PropertyListItem
                label="Vehicle"
                value={
                  <span>
                    {associate.vehicle_year && `${associate.vehicle_year} `}
                    {associate.vehicle_color && `${associate.vehicle_color} `}
                    {associate.vehicle_make && `${associate.vehicle_make} `}
                    {associate.vehicle_model && `${associate.vehicle_model} `}
                    {associate.vehicle_state && `${associate.vehicle_state} `}
                    {associate.vehicle_plate && associate.vehicle_plate}
                  </span>
                }
              />
            )}

            {(associate.drivers_license_state || associate.drivers_license_expires_at) && (
              <PropertyListItem
                label="Driver's License"
                value={
                  <span>
                    {associate.drivers_license_state && `#${associate.drivers_license_state} `}
                    {associate.drivers_license_expires_at && <span>(expires on {associate.drivers_license_expires_at_formatted})</span>}
                  </span>
                }
              />
            )}
          </>
        )}

        {associate && detailsExpanded && (
          <PropertyListItem icon="far fa-calendar-day text-xl" label="Birthday" value={associate.dob_formatted} />
        )}

        {!preview &&
          associate?.hr_dates?.length > 0 &&
          associate.hr_dates?.map((date) => <PropertyListItem label={date.type} value={date.date} key={date.id} />)}

        {detailsExpanded && contact.updated_at != contact.created_at && route().current() !== 'my-card' && (
          <PropertyListItem label="Last Updated" value={contact.updated_at} />
        )}
        {(helpers.isAssociate(user) || helpers.isAdminOrHR(user)) && detailsExpanded && (
          <PropertyListItem label="Date Added" value={contact.created_at} />
        )}

        {preview && (
          <div className="mb-3 mr-6 mt-1 flex justify-end">
            <Button theme="link" onClick={() => setDetailsExpanded(!detailsExpanded)}>
              <span className="font-bold uppercase">{detailsExpanded ? 'Less' : 'More'}</span>
            </Button>
          </div>
        )}
      </Wrapper>

      {(helpers.isAssociate(user) || helpers.isAdminOrHR(user)) && preview && associate && (
        <Fragment>
          <MySupportNetwork associate={associate} onClick={onContactChanged} />

          {associate.realtor_associations?.length > 0 && (
            <AccordionCard title="Realtor Associations" indented={false}>
              <PropertyListItem
                value={associate.realtor_associations.map((association) => (
                  <div
                    key={association.value}
                    className={classNames('leading-loose', { 'font-bold text-orange-500': association.primary })}
                  >
                    {association.value}
                  </div>
                ))}
              />
            </AccordionCard>
          )}
          {associate.mls_associations?.length > 0 && (
            <AccordionCard title="MLS Systems" indented={false}>
              <PropertyListItem
                value={associate.mls_associations.map((association, index) => (
                  <div key={index} className={classNames('leading-loose')}>
                    {association.name || `${association.value} #${association.label}`}
                  </div>
                ))}
              />
            </AccordionCard>
          )}
        </Fragment>
      )}
    </Fragment>
  )
}
