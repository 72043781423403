import React, { useEffect, useRef, useState } from 'react'
import Button from '@/Shared/Button'
import Dropdown from '@/Shared/Dropdown'
import DropdownItem from '@/Shared/DropdownItem'
import Radio from '@/Shared/Forms/Radio'
import TextInput from '@/Shared/Forms/TextInput'
import classNames from 'classnames'
import { Tooltip } from '@/Shared/Tooltip'
import useDebounce from '@/hooks/useDebounce'
import { router, usePage } from '@inertiajs/react'

export default ({ actions, hideFilters, label, otherActions, placeholder, sortOptions, onShowFilters }) => {
  const searchRef = useRef()
  const { filters } = usePage().props
  const [search, setSearch] = useState(filters?.search || '')
  const [loading, setLoading] = useState(true)

  useDebounce(
    () => {
      if (!loading) {
        filterData()
      }
    },
    300,
    [search],
  )

  useEffect(() => {
    setLoading(false)
  }, [])

  const filterData = () => {
    reloadFilters(filters ? { ...filters, search: search } : { search: search })
  }

  const sort = (field) => {
    reloadFilters(
      filters
        ? { ...filters, sortBy: field }
        : {
            sortBy: field,
          },
    )
  }

  const changeDirection = (direction) => {
    reloadFilters(
      filters
        ? { ...filters, direction: direction }
        : {
            direction: direction,
          },
    )
  }

  const reloadFilters = (values) => {
    const query = encodeURIComponent(JSON.stringify(values))

    router.get(
      route(route().current(), { filters: query }),
      {},
      {
        preserveState: true,
      },
    )
  }

  const onReset = () => {
    setSearch('')
    searchRef.current.focus()
  }

  return (
    <div className="flex w-full flex-col gap-3 md:flex-row md:items-center md:justify-end lg:flex-wrap">
      <div className="flex items-center justify-between gap-3">
        {label && <h1 className="flex-1 text-xl font-medium text-gray-800 sm:flex-none">{label}</h1>}
        <div className="ml-3 flex items-center justify-end md:hidden">{actions}</div>
      </div>
      <div className="mx-3 hidden h-9 border-r border-gray-300 md:inline-flex"></div>
      <div className="flex w-full flex-1 flex-grow items-center space-x-3 sm:mt-0 sm:w-auto xl:w-1/2 xl:flex-auto">
        <div className="relative flex w-full flex-grow sm:min-w-sm lg:max-w-xl">
          <TextInput
            ref={searchRef}
            autoFocus
            classes="flex-grow"
            inputClasses="px-4 pr-14 py-2 bg-gray-100"
            placeholder={placeholder}
            value={search}
            onChange={(value) => setSearch(value)}
          />

          <span className={classNames('absolute inset-y-0 flex w-10 justify-center', search ? 'right-2' : 'right-0')}>
            <span className="flex h-full items-center justify-center">
              {search ? (
                <Button theme="icon-sm" onClick={() => onReset()}>
                  <i className="fal fa-times text-lg"></i>
                </Button>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                  <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <rect id="bound" x="0" y="0" width="24" height="24"></rect>
                    <path
                      className="fill-current text-primary-500"
                      d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                      id="Path-2"
                      fillRule="nonzero"
                      opacity="0.3"
                    ></path>
                    <path
                      className="fill-current text-primary-500"
                      d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                      id="Path"
                      fillRule="nonzero"
                    ></path>
                  </g>
                </svg>
              )}
            </span>
          </span>
        </div>

        {!hideFilters && (
          <Button theme="outline" onClick={onShowFilters}>
            <i className="fas fa-sliders-v sm:mr-2"></i>
            <span className="hidden sm:inline">Filters</span>
          </Button>
        )}

        {sortOptions?.length > 0 && (
          <Dropdown id="sort-options" orientation="right" size="w-72">
            <Dropdown.DropdownButton>
              {({ id, clickRef, open, setOpen }) => (
                <Tooltip label="Sort">
                  <Button theme="outline-sm" className="ml-0" id={id} ref={clickRef} onClick={() => setOpen(!open)}>
                    <i className="fas fa-arrow-down-arrow-up" aria-hidden="true"></i>
                    <span className="sr-only">Sort</span>
                  </Button>
                </Tooltip>
              )}
            </Dropdown.DropdownButton>
            <DropdownItem colorClasses="hover:bg-transparent">
              <div className="flex-1">
                <legend>Sort by</legend>

                <div className="mt-2 space-y-3">
                  {sortOptions.map((option, index) => (
                    <Radio
                      id={`sortby-${index}`}
                      name="sortby"
                      label={option.label}
                      value={filters.sortBy === option.value}
                      onChange={() => sort(option.value)}
                      key={index}
                    />
                  ))}
                </div>

                <hr className="-mx-4 my-3" />

                <div className="flex flex-col gap-y-2">
                  <button
                    type="button"
                    className={classNames('flex items-center gap-2 rounded-lg px-3 py-1.5 font-medium', {
                      'bg-gray-200/70': filters.direction === 'desc',
                      'hover:bg-gray-200/50 focus:ring-2 focus:ring-inset focus:ring-blue-500': filters.direction === 'asc',
                    })}
                    onClick={filters.direction === 'asc' ? () => changeDirection('desc') : null}
                  >
                    <i className="fas fa-arrow-up"></i>

                    {(() => {
                      switch (sortOptions.find((option) => option.value === filters.sortBy)?.type || filters.sortBy) {
                        case 'date':
                          return 'Oldest to Newest'
                        case 'text':
                          return 'Z-A'
                        case 'distance':
                          return 'Farthest to Nearest'
                        default:
                          return 'Sort option N/A'
                      }
                    })()}
                  </button>

                  <button
                    type="button"
                    className={classNames('flex items-center gap-2 rounded-lg px-3 py-1.5 font-medium', {
                      'bg-gray-200/70': filters.direction === 'asc',
                      'hover:bg-gray-200/50 focus:ring-2 focus:ring-inset focus:ring-blue-500': filters.direction === 'desc',
                    })}
                    onClick={filters.direction === 'desc' ? () => changeDirection('asc') : null}
                  >
                    <i className="fas fa-arrow-down"></i>

                    {(() => {
                      switch (sortOptions.find((option) => option.value === filters.sortBy)?.type || filters.sortBy) {
                        case 'date':
                          return 'Newest to Oldest'
                        case 'text':
                          return 'A-Z'
                        case 'distance':
                          return 'Nearest to Farthest'
                        default:
                          return 'Sort option N/A'
                      }
                    })()}
                  </button>
                </div>
              </div>
            </DropdownItem>
          </Dropdown>
        )}

        {otherActions}
      </div>
      <div className="ml-3 hidden items-center justify-end md:flex">{actions}</div>
    </div>
  )
}
