import { useContext, useState } from 'react'
import { AccordionContext } from '@/Shared/Accordion'
import Switch from '@/Shared/Forms/Switch'
import { router } from '@inertiajs/react'

export default ({ user }) => {
  const { onSaved } = useContext(AccordionContext)

  const [data, setData] = useState({
    setting: 'nearby',
    nearby: user.nearby == undefined ? false : user.nearby,
  })

  const submit = (enabled) => {
    let updated = { ...data, nearby: enabled }
    setData(updated)

    axios.post(route('api.users.update', user.id), { ...updated, _method: 'PATCH' }).then((_) =>
      router.reload({
        onFinish: () => {
          onSaved()
        },
      }),
    )
  }

  return (
    <div>
      <form className="mx-auto mt-4 max-w-lg space-y-1.5" onSubmit={submit}>
        <div className="mb-4 flex justify-center">
          <Switch
            accessibleText="Show in Nearby Town search results"
            model={data.nearby}
            onChange={(enabled) => submit(enabled)}
            reverseLabel
          >
            <span className="text-gray-800">Appear in searches near your service area</span>
          </Switch>
        </div>
      </form>
    </div>
  )
}
