import React, { Fragment, useEffect, useState } from 'react'
import AccordionCard from '@/Shared/AccordionCard'
import Button from '@/Shared/Button'
import axios from 'axios'
import classNames from 'classnames'
import Visit from './Row'
import Edit from './Edit'
import { router } from '@inertiajs/react'
import { usePage } from '@inertiajs/react'

const Index = () => {
  let currentPoll = React.useRef(0)
  const [fetchDate, setFetchDate] = useState(new Date())
  const { openhouse }: any = usePage().props
  const [visit, setVisit] = useState(null)
  const [submitting, setSubmitting] = useState(false)

  useEffect(() => {
    if (openhouse.cohosts.length == 0) return

    poll()

    return () => {
      clearInterval(currentPoll.current)
    }
  }, [])

  const poll = () => {
    var interval = 1000 * 5 // milliseconds * seconds * minutes

    currentPoll.current = window.setInterval(() => {
      if (!submitting) {
        axios.get(route('api.openhouses.poll', openhouse.id), { params: { timestamp: fetchDate } }).then((response: any) => {
          if (response.data.refresh) {
            router.reload({
              onFinish: () => {
                setFetchDate(new Date())
              },
            })
          }
        })
      }
    }, interval)
  }

  const update = (id: number, data: any) => {
    setSubmitting(true)

    router.post(
      route('api.openhouses.visits.update', { openhouse: openhouse.id, visit: id }),
      { ...data, _method: 'patch' },
      {
        onFinish: (_) => {
          setSubmitting(false)
          setVisit(null)
        },
      },
    )
  }

  const getVisits = (type: string) => {
    let filtered =
      {
        in: openhouse.visits.filter((visit: any) => visit.checked_in_time && !visit.checked_out_time),
        waiting: openhouse.visits.filter((visit: any) => !visit.checked_in_time && !visit.checked_out_time),
        out: openhouse.visits.filter((visit: any) => visit.checked_out_time),
      }[type] || openhouse.visits

    return (
      <AccordionCard
        title={
          <span
            className={classNames('text-lg font-semibold uppercase tracking-wider', {
              'text-orange-500': type === 'in',
              'text-gray-400': type === 'waiting',
              'text-gray-700': type === 'out',
            })}
          >
            {type || 'Visitors'} ({openhouse.visits.length})
          </span>
        }
      >
        {filtered.length > 0 ? (
          <div className="divide-y divide-dashed divide-gray-200 rounded-md border border-gray-200">
            {filtered.map((visit: any) => (
              <Visit
                key={visit.id + visit.name}
                visit={visit}
                onEdit={(visit: any) => setVisit(visit)}
                onUpdate={(id: number, data: any) => update(id, data)}
              />
            ))}
          </div>
        ) : (
          <div className="text-gray-700">There are no {type && <span className="font-medium">{type}</span>} visitors.</div>
        )}
      </AccordionCard>
    )
  }

  return (
    <Fragment>
      <div className="flex justify-center border-b border-gray-200 p-4">
        <Button theme="solid" onClick={() => setVisit({})}>
          <i className="fas fa-user-plus mr-2 text-lg"></i>
          <span className="pr-1">Register Visitor</span>
        </Button>
      </div>

      {openhouse.checkins_enabled ? (
        <Fragment>
          {getVisits('in')}
          {getVisits('waiting')}
          {getVisits('out')}
        </Fragment>
      ) : (
        <Fragment>{getVisits('')}</Fragment>
      )}

      <Edit
        visit={visit}
        open={visit != undefined}
        onClosed={() => setVisit(null)}
        onUpdate={(id: number, data: any) => update(id, data)}
      />
    </Fragment>
  )
}

export default Index
