import { Fragment, useRef, useState } from 'react'
import classNames from 'classnames'
import PhotoEditor from '@/Shared/PhotoEditor/Index'

export default ({ image, onChanged }) => {
  const fileRef = useRef()
  const [file, setFile] = useState(null)

  const readFile = (files) => {
    return new Promise((resolve, reject) => {
      if (!files || files.length === 0) {
        resolve()
        return
      }

      const file = files[0]

      if (/^image\/\w+$/.test(file.type)) {
        if (URL) {
          resolve({
            loaded: true,
            name: file.name,
            type: file.type,
            url: URL.createObjectURL(file),
          })
        } else {
          reject(new Error('Your browser is not supported.'))
        }
      } else {
        reject(new Error('Please choose an image file.'))
      }
    }).then((data) => {
      setFile(data)
    })
  }

  const photoCropped = (image) => {
    setFile(null)
    onChanged(image)
  }

  const resetImage = () => {
    setFile(null)
    onChanged(null)
  }

  return (
    <Fragment>
      <div
        className={classNames('relative mb-3 overflow-hidden shadow-lg ring-2 ring-white', {
          'bg-gray-400': !image,
        })}
        style={{ paddingBottom: '25%' }}
      >
        <div className="absolute inset-0 inline-flex w-full items-center justify-center outline-none">
          <span className="inline-flex w-full items-center justify-center overflow-hidden outline-none">
            <img className="max-h-full" src={image || '/images/layout/banner_ph.jpg'} />
          </span>

          <div className="absolute inset-0 flex items-center justify-center gap-6">
            <button
              type="button"
              className="rounded-full bg-black bg-opacity-60 transition-colors duration-150 hover:bg-gray-500 hover:bg-opacity-50 focus:bg-gray-500 focus:bg-opacity-75"
              aria-haspopup="true"
              onClick={() => fileRef.current.click()}
            >
              <div className="flex h-14 w-14 items-center justify-center">
                <i className="far fa-camera text-2xl text-white opacity-90"></i>
              </div>
              <input
                ref={fileRef}
                accept="image/jpeg,image/png"
                tabIndex="-1"
                type="file"
                className="hidden"
                onChange={(event) => readFile(event.target.files)}
              />
            </button>

            {image && !image.includes('/services') && (
              <button
                type="button"
                className="rounded-full bg-black bg-opacity-60 transition-colors duration-150 hover:bg-gray-500 hover:bg-opacity-50 focus:bg-gray-500 focus:bg-opacity-75"
                onClick={() => resetImage()}
              >
                <div className="flex h-14 w-14 items-center justify-center">
                  <i className="fal fa-times text-3xl leading-none text-white opacity-90"></i>
                </div>
              </button>
            )}
          </div>
        </div>
      </div>

      <PhotoEditor aspectRatio={4 / 1} dialogSize="max-w-7xl" file={file} onApply={photoCropped} onClose={() => setFile(null)} />
    </Fragment>
  )
}
