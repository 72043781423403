import { usePage } from '@inertiajs/react'
import classNames from 'classnames'
import DataTableHeader from './DataTableHeader'
import Helpers from '@/utils/helpers'
import { router } from '@inertiajs/react'
import Pagination from '@/Shared/Pagination'

export default () => {
  const { auth, filters, myLSNs } = usePage().props
  const { data, paginator } = usePage().props.lsns

  const rowClicked = (lsn) => {
    if (lsn.editable) {
      router.visit(route('lsns.show', lsn.id))
    } else {
      window.open(lsn.permalink, '_blank')
    }
  }

  return (
    <div className="bg-white shadow sm:rounded">
      <div className="flex flex-col">
        <div className="overflow-x-auto">
          <div className="inline-block min-w-full align-middle">
            <div className="overflow-hidden border-b border-gray-200 shadow">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  {myLSNs.length > 0 && (
                    <tr>
                      <th
                        className="h-10 cursor-pointer rounded-t-md bg-gray-200 px-4 py-4 text-left font-medium uppercase text-gray-800 sm:px-6"
                        colSpan="5"
                      >
                        <div className="flex items-center leading-none">
                          <i className="far fa-tag pr-4 text-xl text-gray-500"></i>
                          Other LSNs
                        </div>
                      </th>
                    </tr>
                  )}
                  <DataTableHeader editable={false} sortBy={filters.sortBy} direction={filters.direction} />
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {data.map((lsn) => (
                    <tr className="group hover:bg-gray-100" key={lsn.id}>
                      <td className="mx-auto cursor-pointer px-4 py-3 text-gray-700 sm:px-6" onClick={() => rowClicked(lsn)}>
                        <span className="font-bold text-primary-500">{lsn.name}</span>
                        <div className="flex flex-col md:hidden">
                          <div>{lsn.location}</div>
                        </div>

                        <div className="flex items-center space-x-1.5 sm:space-x-0 lg:hidden">
                          <span className="inline-flex items-center whitespace-nowrap rounded bg-gray-200 px-2.5 py-1.5 text-sm leading-none text-gray-800 sm:hidden">
                            {lsn.pros || 0} Members
                          </span>

                          {(Helpers.isAssociate(auth.user) || Helpers.isAdminOrHR(auth.user)) && (
                            <span className="inline-flex items-center whitespace-nowrap rounded bg-gray-200 px-2.5 py-1.5 text-sm leading-none text-gray-800 lg:hidden">
                              Manager: {lsn.manager}
                            </span>
                          )}
                        </div>
                      </td>

                      {(Helpers.isAssociate(auth.user) || Helpers.isAdminOrHR(auth.user)) && (
                        <td className="hidden cursor-pointer px-4 py-3 text-gray-700 lg:table-cell xl:px-6" onClick={() => rowClicked(lsn)}>
                          <div className="flex flex-col">{lsn.manager}</div>
                        </td>
                      )}

                      <td className="hidden cursor-pointer px-4 py-3 text-gray-700 md:table-cell xl:px-6" onClick={() => rowClicked(lsn)}>
                        {lsn.location ? (
                          <div className="flex flex-col">{lsn.location}</div>
                        ) : (
                          <span className="text-gray-300">
                            <i className="fas fa-ban"></i>
                          </span>
                        )}
                      </td>

                      <td
                        className="hidden cursor-pointer whitespace-nowrap px-4 py-3 text-center text-gray-700 sm:table-cell sm:px-6"
                        onClick={() => rowClicked(lsn)}
                      >
                        {lsn.pros > 0 ? (
                          <span className="font-bold text-primary-500">{lsn.pros}</span>
                        ) : (
                          <span className="text-gray-300">
                            <i className="fas fa-ban"></i>
                          </span>
                        )}
                      </td>

                      <td
                        className="w-16 cursor-pointer whitespace-nowrap py-3 pr-6 text-right font-medium"
                        onClick={() => rowClicked(lsn)}
                      >
                        <div className="flex items-center justify-end text-xl md:text-2xl lg:text-base">
                          <i className={classNames('far', lsn.editable ? 'fa-pen' : 'fa-external-link', 'leading-none text-gray-700')}></i>
                        </div>
                      </td>
                    </tr>
                  ))}

                  {data.length == 0 && (
                    <tr>
                      <td colSpan="8" className="px-4 py-12 sm:px-6">
                        <div className="flex flex-col text-center">
                          <span className="text-2xl font-semibold text-red-600">
                            No Local Service Networks (LSNs) match your current filters.
                          </span>
                          <span className="text-lg font-medium text-gray-700">
                            Expecting to see results? Check your filters to make sure you're not excluding results.
                          </span>
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        {data.length > 0 && <Pagination paginator={paginator} />}
      </div>
    </div>
  )
}
