import { createRef, useContext, useEffect, useRef, useState } from 'react'
import { AccordionContext } from '@/Shared/Accordion'
import Button from '@/Shared/Button'
import { Editor } from '@tinymce/tinymce-react'
import Helpers from '@/utils/helpers'
import { router, usePage } from '@inertiajs/react'

export default ({ user }) => {
  const { user: currentUser } = usePage().props.auth

  const scrollRef = createRef()
  const focusRef = useRef()
  const { onSaved } = useContext(AccordionContext)
  const [data, setData] = useState({
    setting: 'biography',
    biography: user.biography ?? '',
  })
  const [value, setValue] = useState(user.biography ?? '')
  const [errors, setErrors] = useState({})

  useEffect(() => {
    setTimeout(() => {
      scrollRef?.current?.scrollIntoView()
    }, 100)
  }, [])

  const submit = (event) => {
    event.preventDefault()

    let finalData = { ...data, biography: value, _method: 'PATCH' }

    axios
      .post(route('api.users.update', user.id), finalData)
      .then((_) =>
        router.reload({
          onFinish: () => {
            onSaved()
          },
        }),
      )
      .catch((error) => {
        if (error.response != undefined) {
          const { status } = error.response
          const VALIDATION_ERROR = 422
          if (status == VALIDATION_ERROR) {
            setErrors(error.response.data.errors)
          }
        }
      })
  }

  return (
    <div ref={scrollRef}>
      <p className="mb-3">
        This can be as long or as short as you'd like; about 3 to 5 paragraphs, or 300 words is typical and can help with SEO (Search Engine
        Optimization for finding your page using important keywords from your text). You may talk about your firm or your own personal
        background, history, areas of expertise, specialties, geographical service areas, or other parameters that are relevant to your
        professional services.
      </p>
      <p className="mb-3">
        Provide your biography text in the text field below, then click on save changes below the text field to save as your biography.
      </p>

      <Editor
        apiKey="07m0mu84gob420qe9gdc07i6toit15n8qfyj1nmbukylsc7z"
        onInit={(evt, editor) => (focusRef.current = editor)}
        initialValue={user.biography}
        value={value}
        init={{
          height: 500,
          menubar: false,
          plugins: 'code image link lists media',
          toolbar:
            'undo redo | bold italic underline | media image | bullist numlist outdent indent | link unlink' +
            (Helpers.isDeveloper(currentUser) ? ' | code' : ''),
          mobile: {
            toolbar_mode: 'wrap',
          },
          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
        }}
        onEditorChange={(value, editor) => setValue(value)}
      />
      <form className="mt-4" onSubmit={submit}>
        <div className="flex justify-end">
          <Button type="submit" theme="solid">
            Save
          </Button>
        </div>
      </form>
    </div>
  )
}
