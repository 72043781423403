import { useState } from 'react'
import Button from '@/Shared/Button'
import Edit from './Edit'
import Rule from './Rule'

export default ({ fields, operators, rules, step, onUpdated }) => {
  const [activeIndex, setActiveIndex] = useState(null)
  const [activeRule, setActiveRule] = useState(null)

  const handleEditing = (index, rule) => {
    setActiveIndex(index)
    setActiveRule(rule)
  }

  const handleSaving = (rule) => {
    setActiveIndex(null)
    setActiveRule(null)
    onUpdated(activeIndex != null ? rules.map((r, index) => (index == activeIndex ? rule : r)) : rules.concat(rule))
  }

  return (
    <div className="space-y-6">
      {rules.map((rule, index) => (
        <Rule
          index={index}
          operators={operators}
          rule={rule}
          key={index}
          onEdit={() => handleEditing(index, rule)}
          onDelete={() => onUpdated(rules.filter((r) => r.id != rule.id))}
        />
      ))}

      <Edit
        fields={fields}
        operators={operators}
        rule={activeRule}
        step={step}
        open={activeRule != null}
        onClosed={() => {
          setActiveIndex(null)
          setActiveRule(null)
        }}
        onSaving={(rule) => handleSaving(rule)}
      />

      <Button buttonClasses="-ml-0" theme="outline" size="sm" onClick={() => setActiveRule({})}>
        <i className="fal fa-plus pr-2 text-gray-500"></i>
        <span>Add Rule</span>
      </Button>
    </div>
  )
}
