import React, { useEffect, useRef } from 'react'
import Button from '@/Shared/Button'
import { router } from '@inertiajs/react'
import Select from '@/Shared/Forms/Select'
import SlideOver from '@/Shared/SlideOver'
import { useForm, usePage } from '@inertiajs/react'

export default ({ open, onClosed }) => {
  let focusRef = useRef(null)
  const { directories, filters, statuses } = usePage().props
  const { data, setData } = useForm({
    directories: [],
    status: [],
  })

  useEffect(() => {
    if (filters) {
      setData({
        directories: filters.directories || [],
        status: filters.status || [],
      })
    }
  }, [filters])

  const onSubmit = (e) => {
    e.preventDefault()

    const values = filters
      ? { ...filters, directories: data.directories, status: data.status }
      : { directories: data.directories, status: data.status }
    const query = encodeURIComponent(JSON.stringify(values))

    router.get(
      route(route().current(), { filters: query }),
      {},
      {
        preserveState: true,
        onStart: () => onClosed(),
      },
    )
  }

  return (
    <SlideOver
      focusRef={focusRef}
      footerActions={
        <Button theme="solid" type="submit" form="filters">
          Apply
        </Button>
      }
      show={open}
      title="Filters"
      onClosed={onClosed}
      aboveMessages
    >
      <form id="filters" className="flex h-full flex-col" onSubmit={onSubmit}>
        <Select
          ref={focusRef}
          label="Directory"
          name="directory"
          options={directories}
          placeholder="Any Directory"
          value={directories.filter((p) => data.directories.find((f) => f === p.value))}
          onChange={(selected) => setData({ ...data, directories: selected ? Array.from(selected, (s) => s.value) : [] })}
          multiple
        />

        <Select
          label="Status"
          name="status"
          options={statuses}
          placeholder="Any Status"
          value={statuses.filter((p) => data.status.find((f) => f === p.value))}
          onChange={(selected) => setData({ ...data, status: selected ? Array.from(selected, (s) => s.value) : [] })}
          multiple
        />
      </form>
    </SlideOver>
  )
}
