import React from 'react'
import { router } from '@inertiajs/react'
import { usePage } from '@inertiajs/react'
import HeaderColumn from '@/Shared/Table/HeaderColumn'

export default () => {
  const { filters } = usePage().props
  let sortBy = filters?.sortBy || 'last_name'
  let direction = filters?.direction || 'asc'
  const administrating = route().current().startsWith('admin')
  const sort = (field) => {
    const values = filters
      ? { ...filters, sortBy: field, direction: field === sortBy ? (direction === 'asc' ? 'desc' : 'asc') : 'asc' }
      : {
          sortBy: field,
          direction: field === sortBy ? (direction === 'asc' ? 'desc' : 'asc') : 'asc',
        }
    const query = encodeURIComponent(JSON.stringify(values))

    router.get(
      route(route().current(), { filters: query }),
      {},
      {
        preserveState: true,
      },
    )
  }

  return (
    <tr>
      <HeaderColumn colSpan={2} sorting={sortBy} direction={direction} field="first_name" onSort={(field) => sort(field)} noBorder>
        Name
      </HeaderColumn>
      <HeaderColumn classes="hidden sm:table-cell" sorting={sortBy} direction={direction} field="industry" onSort={(field) => sort(field)}>
        Industry
      </HeaderColumn>
      <HeaderColumn classes="hidden xl:table-cell">Phone</HeaderColumn>
      <HeaderColumn classes="hidden 2xl:table-cell">Email</HeaderColumn>
      <HeaderColumn classes="hidden lg:table-cell" sorting={sortBy} direction={direction} field="location" onSort={(field) => sort(field)}>
        Location
      </HeaderColumn>
      {administrating && <HeaderColumn className="hidden lg:table-cell">Status</HeaderColumn>}
      <HeaderColumn noHover>
        <span className="sr-only">Edit</span>
      </HeaderColumn>
    </tr>
  )
}
