import { useEffect, useState } from 'react'
import { router } from '@inertiajs/react'
import { usePage } from '@inertiajs/react'
import classNames from 'classnames'
import MainLayout from '@/Shared/Layouts/Main'
import SearchBar from './SearchBar'
import SearchResults from './SearchResults'
import MergeReview from './MergeReview'
import Table from '@/Shared/Table/Index'

function debounce(fn, ms) {
  let timer
  return (_) => {
    clearTimeout(timer)
    timer = setTimeout((_) => {
      timer = null
      fn.apply(this, arguments)
    }, ms)
  }
}

const TagGroup = ({ group, tags, rowClicked }) => {
  const { filters } = usePage().props
  const filteredTags = filters?.tags

  return (
    tags.filter((group) => group.contacts_count > 0).length > 0 && (
      <Table>
        <Table.Header>
          <tr>
            <th className="h-12 cursor-pointer rounded-t bg-gray-200 px-4 font-medium uppercase text-gray-800 sm:px-6" colSpan="2">
              <div className="flex items-center leading-none">{group} Tags</div>
            </th>
          </tr>
        </Table.Header>
        <Table.Body>
          {tags &&
            tags.map(({ id, label, contacts_count }) => {
              return (
                contacts_count > 0 && (
                  <TableRow
                    icon="far fa-tag text-xl"
                    text={
                      <span
                        className={classNames({
                          'text-blue-500': label === 'Unqualified Contacts',
                          'text-green-500': label === 'Active Prospects',
                          'text-yellow-500': ['Long-Term Prospects', 'On-Hold Clients'].indexOf(label) >= 0,
                          'text-green-900': label === 'Active Clients',
                        })}
                      >
                        {label} ({contacts_count})
                      </span>
                    }
                    active={filteredTags?.length == 1 && filteredTags.find((t) => t == label)}
                    onClick={() => rowClicked(label)}
                    key={id}
                  />
                )
              )
            })}
        </Table.Body>
      </Table>
    )
  )
}

const TableRow = ({ active, icon, text, onClick }) => (
  <tr className={classNames(active ? 'bg-blue-100 text-blue-500' : 'text-gray-700 hover:bg-gray-100')}>
    <td className="cursor-pointer px-4 py-3 font-medium sm:px-6" onClick={() => onClick()}>
      <div className="flex items-center gap-3">
        <div className="flex w-6 items-center justify-center">
          <i className={classNames(icon, active ? 'text-blue-500' : 'text-gray-400')}></i>
        </div>
        <div>{text}</div>
      </div>
    </td>

    <td className="w-16 cursor-pointer whitespace-nowrap py-3 pr-6 text-right font-medium sm:hidden" onClick={() => onClick()}>
      <div className="flex items-center justify-end">
        <i className="far fa-angle-right text-2xl leading-none text-gray-700"></i>
      </div>
    </td>
  </tr>
)

const ContactIndex = () => {
  const { contacts_count, duplicates_count, revisions_count, filters, tags } = usePage().props
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
  })

  const debouncedHandleResize = debounce(function handleResize() {
    setDimensions({
      width: window.innerWidth,
    })
  }, 200)

  useEffect(() => {
    window.addEventListener('resize', debouncedHandleResize)

    return (_) => {
      window.removeEventListener('resize', debouncedHandleResize)
    }
  })

  const exportContacts = () => {
    axios
      .post(route('contacts.export'), {
        ...filters,
        responseType: 'blob',
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'contacts.csv')
        document.body.appendChild(link)
        link.click()
      })
  }

  const rowClicked = (label) => {
    let values
    if (label === 'starred') {
      values = { starred: true }
    } else {
      values = { tags: [label] }
    }

    const query = encodeURIComponent(JSON.stringify(values))

    router.get(
      route('contacts.index', { filters: query }),
      {},
      {
        preserveState: true,
      },
    )
  }

  return (
    <div className="sm:flex sm:flex-col sm:overflow-hidden">
      <div className="sm:py-auto flex flex-wrap items-stretch justify-between border-b border-gray-200 bg-white px-4 py-4 md:min-h-[68px] md:px-6">
        <SearchBar />
      </div>

      <div className="sm:flex sm:flex-col sm:overflow-hidden">
        <div className="sm:flex sm:h-full">
          {(dimensions.width >= 768 ||
            (['contacts.all', 'contacts.merge.index', 'contacts.revisions.index'].indexOf(route().current()) < 0 && !filters)) && (
            <div className="space-y-3 bg-gray-100 p-2 pb-4 sm:w-[28rem] sm:overflow-y-auto">
              <Table>
                <Table.Body>
                  <TableRow
                    icon="far fa-users text-lg"
                    text={`Contacts (${contacts_count})`}
                    active={route().current() === 'contacts.all'}
                    onClick={() => router.visit(route('contacts.all'))}
                  />

                  {Boolean(tags?.starred) && (
                    <TableRow
                      icon="fas fa-star text-lg text-orange-500"
                      text={`Starred Contacts (${tags.starred})`}
                      active={filters?.starred}
                      onClick={() => rowClicked('starred')}
                    />
                  )}

                  <TableRow
                    icon="far fa-people-arrows text-lg"
                    text={`Merge Duplicates (${duplicates_count})`}
                    active={route().current() === 'contacts.merge.index'}
                    onClick={() => router.visit(route('contacts.merge.index'))}
                  />

                  <TableRow
                    icon="far fa-user-pen text-lg"
                    text={`Pending Revisions (${revisions_count})`}
                    active={route().current() === 'contacts.revisions.index'}
                    onClick={() => router.visit(route('contacts.revisions.index'))}
                  />

                  <tr className="text-gray-700 hover:bg-gray-100">
                    <td colSpan="2" className="cursor-pointer px-4 py-3 font-medium sm:px-6" onClick={() => exportContacts()}>
                      <div className="flex items-center gap-3">
                        <div className="flex w-6 items-center justify-center">
                          <i className="far fa-cloud-arrow-down text-lg text-gray-400"></i>
                        </div>
                        Export
                      </div>
                    </td>
                  </tr>
                </Table.Body>
              </Table>

              {Object.keys(tags)
                .filter((group) => ['corporate', 'smart'].indexOf(group) >= 0)
                .map(
                  (group) => tags[group].length > 0 && <TagGroup group={group} tags={tags[group]} key={group} rowClicked={rowClicked} />,
                )}

              {Object.keys(tags)
                .filter((group) => ['personal'].indexOf(group) >= 0)
                .map((group) => (
                  <TagGroup group={group} tags={tags[group]} key={group} rowClicked={rowClicked} />
                ))}
            </div>
          )}

          {(dimensions.width >= 768 ||
            ['contacts.all', 'contacts.merge.index', 'contacts.revisions.index'].indexOf(route().current()) >= 0 ||
            filters) && (
            <div className="w-full overflow-y-auto px-4 sm:flex sm:flex-col sm:px-6">
              <div className="my-2 flex flex-1 md:ml-2 md:mr-3">
                {route().current() === 'contacts.merge.index' ? <MergeReview /> : <SearchResults />}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

// Persistent layout
// Docs: https://inertiajs.com/pages#persistent-layouts
ContactIndex.layout = (page) => <MainLayout title="Contacts" children={page} />

export default ContactIndex
