import { useState } from 'react'
import Card from './Card'
import Details from '@/Shared/Layouts/Details'
import ChecklistEdit from './Edit'
import Filters from './Filters'
import Layout from '@/Shared/Layouts/Main'
import Sections from './Sections/Index'

const AdminChecklistShow = ({ checklist }) => {
  const [openDrawer, setOpenDrawer] = useState(null)

  return (
    <Details columns={2}>
      <Details.Card>
        <Card checklist={checklist} onEdit={() => setOpenDrawer('edit')} />
      </Details.Card>

      <Details.LeftColumn>
        <Filters checklist={checklist} />
      </Details.LeftColumn>

      <Details.MiddleColumn overflowHidden>
        <div className="h-full">
          <Sections onEditChecklist={() => setOpenDrawer('edit')} />

          <ChecklistEdit open={openDrawer === 'edit'} record={checklist} onClosed={() => setOpenDrawer(null)} />
        </div>
      </Details.MiddleColumn>
    </Details>
  )
}

AdminChecklistShow.layout = (page) => <Layout title={`${page.props.checklist.name} Transaction Checklist`} children={page} overflowHidden />

export default AdminChecklistShow
