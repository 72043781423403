import { Fragment, useEffect, useRef, useState } from 'react'
import Button from '@/Shared/Button'
import Heading from '@/Shared/Forms/Heading'
import { router } from '@inertiajs/react'
import { usePage } from '@inertiajs/react'
import Select from '@/Shared/Forms/Select'
import SlideOver from '@/Shared/SlideOver'
import TextInput from '@/Shared/Forms/TextInput'

export default ({ open, onClosed }) => {
  let focusRef = useRef(null)
  const { auth, filters } = usePage().props
  const canFilterAssociates = auth.user.roles.some((r) => ['Admin', 'hr_controller', 'Associate'].indexOf(r) >= 0)
  const defaultFilters = {
    location: '',
    radius: '5',
  }
  const defaultAssociateFilters = {
    managers: [],
  }
  const [form, setForm] = useState(canFilterAssociates ? { ...defaultFilters, ...defaultAssociateFilters } : defaultFilters)
  const radiuses = [
    { value: '5', label: 'within 5 miles' },
    { value: '10', label: 'within 10 miles' },
    { value: '15', label: 'within 15 miles' },
    { value: '25', label: 'within 25 miles' },
    { value: '50', label: 'within 50 miles' },
    { value: '100', label: 'within 100 miles' },
  ]

  useEffect(() => {
    if (filters) {
      let f = {
        location: filters.location || '',
        radius: filters.radius || '5',
      }

      if (canFilterAssociates) {
        f = {
          ...f,
          managers: filters.managers || [],
        }
      }

      setForm(f)
    }
  }, [filters])

  const fetchAssociates = (value) =>
    axios.get('/api/contacts', {
      params: {
        limit: 25,
        search: value,
        groups: 'associates',
      },
    })

  const showOption = (option) => (
    <div className="group flex items-center space-x-3" key={option.id}>
      {option.avatar ? (
        <img className="h-10 w-10 rounded-full" src={option.avatar} alt="" />
      ) : (
        <div className="flex h-10 w-10 items-center justify-center rounded-full bg-gray-200 text-gray-700">
          <div className="text-base font-medium tracking-wide">{option.initials}</div>
        </div>
      )}

      <div>
        <div className="space-x-1">
          <span className="space-x-1 font-medium text-gray-900">
            <span>{option.name}</span>
            {option.industry && <span className="text-gray-500">in {option.industry}</span>}
          </span>
        </div>

        {option.company && <div className="text-gray-500">{option.company}</div>}
      </div>
    </div>
  )

  const onSubmit = (e) => {
    e.preventDefault()

    let data = { ...form }
    if (form.location != filters.location || form.radius != filters.radius) {
      data = { ...data, sortBy: 'distance', direction: 'asc' }
    }

    if (form.managers) {
      data = { ...data, managers: form.managers?.map((a) => a.user_id) }
    }

    const values = filters ? { ...filters, ...data } : { ...data }
    const query = encodeURIComponent(JSON.stringify(values))

    router.get(
      route(route().current(), { filters: query }),
      {},
      {
        preserveState: true,
        onStart: () => onClosed(),
      },
    )
  }

  return (
    <SlideOver
      focusRef={focusRef}
      footerActions={
        <Button theme="solid" type="submit" form="lsnFilters">
          Apply
        </Button>
      }
      show={open}
      title="Filters"
      onClosed={onClosed}
      aboveMessages
    >
      <form id="lsnFilters" className="flex h-full flex-col" onSubmit={onSubmit}>
        <TextInput
          label="Near"
          name="location"
          value={form.location}
          placeholder="i.e. Foxboro, MA 02035"
          onChange={(value) => setForm({ ...form, location: value })}
        />

        <Select
          isClearable={false}
          label="Distance from Above"
          name="location"
          options={radiuses}
          value={form.radius}
          onChange={(selected) => setForm({ ...form, radius: selected.value })}
        />

        {canFilterAssociates && (
          <Fragment>
            <Heading>Associate-Only Filters</Heading>

            <Select
              label="Managed By"
              name="managers"
              value={form.managers}
              optionLabel={(option) => !option.label && showOption(option)}
              optionValue={(option) => option.id}
              placeholder="Search Associates"
              onChange={(selected) => setForm({ ...form, managers: selected })}
              onInputChanged={(value) => fetchAssociates(value)}
              multiple
              async
            />
          </Fragment>
        )}
      </form>
    </SlideOver>
  )
}
