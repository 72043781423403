export default ({ children, transaction, onTabChange }) => {
  return (
    <div className="relative z-0 h-full pb-4">
      {children}

      <div>
        <button
          className="inline-flex h-20 w-full items-center justify-between border-b border-gray-300 bg-gray-50 px-8 py-4 active:bg-gray-200"
          onClick={() => onTabChange('summary')}
        >
          <div className="text-lg font-semibold text-gray-600">Summary</div>
          <i className="fal fa-angle-right text-2xl font-medium"></i>
        </button>

        <button
          className="inline-flex h-20 w-full items-center justify-between border-b border-gray-300 bg-gray-50 px-8 py-4 active:bg-gray-200"
          onClick={() => onTabChange('checklist')}
        >
          <div className="text-lg font-semibold text-gray-600">{transaction.type} Checklist</div>
          <i className="fal fa-angle-right text-2xl font-medium"></i>
        </button>

        <button
          className="inline-flex h-20 w-full items-center justify-between border-b border-gray-300 bg-gray-50 px-8 py-4 active:bg-gray-200"
          onClick={() => onTabChange('contacts')}
        >
          <div className="text-lg font-semibold text-gray-700">
            Contacts <span className="font-medium text-gray-500">({transaction.contacts.length})</span>
          </div>
          <i className="fal fa-angle-right text-2xl font-medium"></i>
        </button>

        <button
          className="inline-flex h-20 w-full items-center justify-between border-b border-gray-300 bg-gray-50 px-8 py-4 active:bg-gray-200"
          onClick={() => onTabChange('notes')}
        >
          <div className="text-lg font-semibold text-gray-700">Notes</div>
          <i className="fal fa-angle-right text-2xl font-medium"></i>
        </button>

        <button
          className="inline-flex h-20 w-full items-center justify-between border-b border-gray-300 bg-gray-50 px-8 py-4 active:bg-gray-200"
          onClick={() => onTabChange('files')}
        >
          <div className="text-lg font-semibold text-gray-700">Files</div>
          <i className="fal fa-angle-right text-2xl font-medium"></i>
        </button>

        <button
          className="inline-flex h-20 w-full items-center justify-between border-b border-gray-300 bg-gray-50 px-8 py-4 active:bg-gray-200"
          onClick={() => onTabChange('forms')}
        >
          <div className="text-lg font-semibold text-gray-700">Forms</div>
          <i className="fal fa-angle-right text-2xl font-medium"></i>
        </button>

        <button
          className="inline-flex h-20 w-full items-center justify-between border-b border-gray-300 bg-gray-50 px-8 py-4 active:bg-gray-200"
          onClick={() => onTabChange('payments')}
        >
          <div className="text-lg font-semibold text-gray-600">Payments</div>
          <i className="fal fa-angle-right text-2xl font-medium"></i>
        </button>

        <button
          className="inline-flex h-20 w-full items-center justify-between border-b border-gray-300 bg-gray-50 px-8 py-4 active:bg-gray-200"
          onClick={() => onTabChange('timeline')}
        >
          <div className="text-lg font-semibold text-gray-600">Activity</div>
          <i className="fal fa-angle-right text-2xl font-medium"></i>
        </button>
      </div>
    </div>
  )
}
