import AccordionCard from '@/Shared/AccordionCard'
import Button from '@/Shared/Button'
import { router } from '@inertiajs/react'
import { usePage } from '@inertiajs/react'
import classNames from 'classnames'

export default ({ onSettingClicked, onNewMember }) => {
  const { lsn } = usePage().props
  const incomplete = lsn.requirements.find((requirement) => !requirement.completed)

  const onRequirementClicked = (requirement) => {
    if (requirement.type === 'setting') {
      onSettingClicked(requirement.field)
    } else if (requirement.type === 'member') {
      onNewMember()
    }
  }

  const launchLSN = () => {
    router.post(route('lsns.publish', lsn.id))
  }

  const onView = () => {
    // prettier-ignore
    window.open(lsn.published ? lsn.permalink : lsn.previewLink, '_blank')
  }

  return (
    <div className="space-y-5 border-t border-gray-300 bg-yellow-100 bg-opacity-60 md:border-t-0 md:border-b md:border-gray-200">
      <AccordionCard title="Launch Checklist" hideBorder>
        <p>To make this LSN available to the general public, the following items are required:</p>

        <div className="mt-4 mb-6">
          {lsn.requirements.map((requirement, index) => (
            <div className="text-lg leading-loose" key={index}>
              <a
                className={classNames(
                  'group mx-auto flex w-[185px] items-center space-x-4 font-medium',
                  requirement.completed ? 'text-green-700' : 'cursor-pointer text-red-600',
                )}
                onClick={() => onRequirementClicked(requirement)}
              >
                <i
                  className={classNames(
                    'flex w-6 justify-start',
                    requirement.completed
                      ? { member: 'fas fa-user-check', setting: 'fas fa-check' }[requirement.type]
                      : { member: 'fas fa-user-xmark', setting: 'fas fa-cog' }[requirement.type],
                    'text-center text-xl',
                  )}
                ></i>

                <span className={!requirement.completed ? 'group-hover:underline' : ''}>{requirement.name}</span>
              </a>
            </div>
          ))}
        </div>

        <div className="flex justify-center gap-2">
          <Button theme="outline" onClick={() => onView()}>
            <span className="pr-1">Preview</span>
            <i className="fas fa-external-link ml-3 text-lg"></i>
          </Button>

          <Button
            theme="solid"
            className={incomplete ? '' : 'bg-green-600 hover:bg-green-900'}
            disabled={incomplete}
            onClick={() => launchLSN()}
          >
            <i className="fas fa-check mr-2 text-xl"></i>
            <span className="pr-1">Make Public</span>
          </Button>
        </div>
      </AccordionCard>
    </div>
  )
}
