import React, { forwardRef } from 'react'
import classNames from 'classnames'
import { CSS } from '@dnd-kit/utilities'
import Checkbox from '@/Shared/Forms/Checkbox'
import { useSortable } from '@dnd-kit/sortable'

export const Item = forwardRef(
  (
    {
      attributes,
      listeners,
      activeIndex,
      hasError,
      hasPrimary,
      index,
      isSortable,
      item,
      render,
      style,
      disablePrimary,
      onChange,
      onRowReset,
      ...props
    },
    ref,
  ) => {
    const disabled = item.disableDeleting != undefined && item.disableDeleting

    return (
      <div
        ref={isSortable ? ref : null}
        className={classNames(
          'px-4 py-3 sm:px-6',
          disabled ? 'bg-gray-200' : [index % 2 ? 'bg-gray-100' : ''],
          isSortable ? ['rounded-md p-2', hasError ? 'border-2 border-red-600 bg-red-50' : 'border border-gray-300'] : '',
        )}
        style={style}
        {...props}
      >
        <div className="flex items-start justify-between">
          {render != undefined &&
            render({
              focusRef: ref,
              data: item,
              index: index,
              attributes: attributes,
              listeners: listeners,
              onChange: (value, field) => (field ? onChange({ ...item, [field]: value }) : onChange({ ...item, ...value })),
            })}

          <div className="flex items-start justify-between">
            {hasPrimary && !disablePrimary && (
              <div className="ml-3 flex items-center">
                <Checkbox
                  name={`primary_${index}`}
                  label="Primary"
                  value={item.primary ?? false}
                  disabled={disabled}
                  onChange={(selected) => onChange({ ...item, primary: selected })}
                  vertical
                />
              </div>
            )}

            <div className="ml-3">
              <button
                type="button"
                className={classNames(
                  'flex h-10 items-center justify-center rounded border-0 px-3 py-2 text-gray-500',
                  disabled
                    ? 'invisible'
                    : 'outline-none transition-all duration-150 hover:bg-red-100 hover:text-red-600 focus:ring-2 focus:ring-red-600',
                )}
                onClick={(!disabled && onRowReset) || undefined}
              >
                <span className="sr-only">Delete</span>
                <i className="far fa-times text-xl leading-none"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  },
)

export const SortableItem = (props) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: props.item.id })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  return <Item ref={setNodeRef} style={style} attributes={attributes} listeners={listeners} {...props} />
}
