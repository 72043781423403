import { Fragment, useEffect, useState } from 'react'
import { router } from '@inertiajs/react'
import Portlet from '@/Shared/Portlet'
import NoResults from '../NoResults'
import NoteDialog from '@/Shared/NoteDialog'
import { Tooltip } from '@/Shared/Tooltip'
import { usePage } from '@inertiajs/react'

export default () => {
  const { filters, cap_anniversaries: records } = usePage().props
  const [activeContact, setActiveContact] = useState(null)
  const [noteDialogOpen, setNoteDialogOpen] = useState(false)
  const [contacts, setContacts] = useState([])

  useEffect(() => {
    setContacts(records)
  }, [records])

  const rowClicked = (id) => {
    router.visit(route('admin.associates.show', id))
  }

  const noteClicked = (e, contact) => {
    e.stopPropagation()
    setActiveContact(contact)
    setNoteDialogOpen(true)
  }

  return (
    <Fragment>
      <Portlet>
        <Portlet.Header>
          <div className="flex items-center justify-between gap-3">
            <div className="flex flex-1 flex-col">
              <span className="text-sm font-normal text-gray-500">Upcoming/Recent</span>
              <span>Cap Anniversaries</span>
            </div>
          </div>
        </Portlet.Header>

        <Portlet.Body>
          {contacts.length > 0 ? (
            <div className="divide-y divide-gray-200 border-b border-t border-gray-200">
              {contacts.map(({ id, anniversary_date, associate_id, avatar, initials, name, notes }) => (
                <div className="flex gap-8 hover:bg-gray-100" key={id}>
                  <div className="flex flex-1 cursor-pointer items-center gap-4 py-3 pl-4" onClick={() => rowClicked(associate_id)}>
                    <div className="flex h-10 w-10 flex-shrink-0 cursor-pointer items-center justify-center whitespace-nowrap">
                      <div>
                        {avatar && <img className="h-10 w-10 rounded-full" src={avatar} alt="" />}
                        {!avatar && (
                          <div className="flex h-10 w-10 items-center justify-center rounded-full bg-gray-200 text-gray-700">
                            <div className="text-base font-medium tracking-wide">{initials}</div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="flex-1 xl:flex xl:items-center xl:gap-8">
                      <div className="max-w-[210px] flex-1 items-center leading-snug">
                        <div className="truncate font-bold">{name}</div>
                      </div>

                      <div className="text-sm font-medium text-blue-500">{anniversary_date}</div>
                    </div>
                  </div>

                  <div className="flex shrink-0 items-center gap-1 py-3 pr-4">
                    <span className="text-gray-400">({notes || 0})</span>

                    <Tooltip label="Add a note">
                      <button
                        type="button"
                        className="inline-flex h-9 w-9 items-center justify-center rounded-full outline-none transition-all duration-100 hover:bg-gray-300 hover:bg-opacity-75 focus:ring-2 focus:ring-blue-500"
                        onClick={(e) => noteClicked(e, { id: id, name: name })}
                      >
                        <i className="far fa-note-medical text-xl leading-none text-blue-500"></i>
                      </button>
                    </Tooltip>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="flex max-h-[400px] min-h-[400px] items-center justify-center py-6">
              <NoResults label="no upcoming or recent CAP Anniversaries." />
            </div>
          )}
        </Portlet.Body>
      </Portlet>

      {activeContact && (
        <NoteDialog
          open={noteDialogOpen}
          id={activeContact.id}
          title={`Add a Note to ${activeContact.name}`}
          type="Contact"
          record={{ type: 'Note' }}
          onClosed={() => {
            setNoteDialogOpen(false)
            setTimeout(() => {
              setActiveContact(null)
            }, 500)
          }}
        />
      )}
    </Fragment>
  )
}
