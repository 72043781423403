import React from 'react';
import Timeline from '@/Shared/Timeline/Index';

export default ({ changeTab }) => {
  return (
    <div>
      <div className="mb-4">
        <a role="button" className="flex items-center text-blue-500 hover:underline font-medium" onClick={() => changeTab('')}>
          <i className="fas fa-angle-left pr-2"></i>
          <span>Back to checklist</span>
        </a>
      </div>

      <Timeline />
    </div>
  );
};