import React, { useContext, useRef } from 'react'
import Button from '@/Shared/Button'
import { BlockUIContext } from '@/Shared/BlockUI/BlockUIContext'
import { router } from '@inertiajs/react'
import Select from '@/Shared/Forms/Select'
import SlideOver from '@/Shared/SlideOver'
import TextInput from '@/Shared/Forms/TextInput'
import { useForm, usePage } from '@inertiajs/react'

export default ({ open, onClosed }) => {
  let focusRef = useRef(null)
  const { filters, account_statuses, website_statuses } = usePage().props
  const { data, setData } = useForm({
    ...filters,
    licenseStates: filters.licenseStates || [],
    location: filters.location || '',
    mlsAssociations: filters.mlsAssociations || [],
    radius: filters.radius || '5',
    realtorAssociations: filters.realtorAssociations || [],
    roles: filters.roles || [],
    website_status: filters.website_status || [],
    status: filters.status || [],
  })

  const { setLoading } = useContext(BlockUIContext)

  const mlsAssociations = [
    { value: 'MLS PIN', label: 'MLS PIN' },
    { value: 'Cape Cod Multiple Listing Services', label: 'Cape Cod Multiple Listing Services' },
    { value: 'Statewide MLS', label: 'Statewide MLS' },
    { value: 'PrimeMLS', label: 'PrimeMLS' },
  ]
  const radiuses = [
    { value: '5', label: 'within 5 miles' },
    { value: '10', label: 'within 10 miles' },
    { value: '15', label: 'within 15 miles' },
    { value: '25', label: 'within 25 miles' },
    { value: '50', label: 'within 50 miles' },
    { value: '100', label: 'within 100 miles' },
  ]
  const realtorAssociations = [
    { value: 'Cape Cod & Islands Assoc. of Realtors (CCIAOR)', label: 'Cape Cod & Islands Assoc. of Realtors (CCIAOR)' },
    { value: 'Greater Boston Real Estate Board', label: 'Greater Boston Real Estate Board' },
    { value: 'Greater Providence Board of Realtors', label: 'Greater Providence Board of Realtors' },
    { value: 'Massachusetts Association of Realtors', label: 'Massachusetts Association of Realtors' },
    { value: 'New Hampshire Association of Realtors', label: 'New Hampshire Association of Realtors' },
    { value: 'Northern RI Board of Realtors', label: 'Northern RI Board of Realtors' },
    { value: 'Realtor Association of Central Massachusetts', label: 'Realtor Association of Central Massachusetts' },
    { value: 'Realtor Association of Southeastern MA (RASEM)', label: 'Realtor Association of Southeastern MA (RASEM)' },
    { value: 'Rhode Island Association of Realtors', label: 'Rhode Island Association of Realtors' },
    { value: 'Seacoast Board of Realtors', label: 'Seacoast Board of Realtors' },
  ]
  const states = [
    { value: 'MA', label: 'Massachusetts' },
    { value: 'NH', label: 'New Hampshire' },
    { value: 'RI', label: 'Rhode Island' },
  ]
  const roles = [
    { value: 'svp', label: 'Support Vice President' },
    { value: 'pal', label: 'PAL' },
  ]

  const onSubmit = (e) => {
    e.preventDefault()

    setLoading(true)

    let values = data
    if (data.location !== '' && (data.location != filters.location || data.radius != filters.radius)) {
      values = { ...values, sortBy: 'distance', direction: 'asc' }
    }

    const query = encodeURIComponent(JSON.stringify(values))

    router.get(
      route(route().current(), { filters: query }),
      {},
      {
        preserveState: true,
        onFinish: () => {
          onClosed()
          setLoading(false)
        },
      },
    )
  }

  return (
    <SlideOver
      focusRef={focusRef}
      footerActions={
        <Button theme="solid" type="submit" form="filters">
          Apply
        </Button>
      }
      show={open}
      title="Filters"
      onClosed={onClosed}
      aboveMessages
    >
      <form id="filters" className="flex h-full flex-col" onSubmit={onSubmit}>
        <Select
          label="Has a License in"
          name="licenseStates"
          options={states}
          placeholder="Any State"
          value={states.filter((state) => data.licenseStates.find((s) => s === state.value))}
          onChange={(selected) => setData({ ...data, licenseStates: selected ? Array.from(selected, (s) => s.value) : [] })}
          multiple
        />

        <Select
          label="Realtor Associations"
          name="realtorAssociations"
          options={realtorAssociations}
          placeholder="Any Realtor Association"
          value={realtorAssociations.filter((association) => data.realtorAssociations.find((a) => a === association.value))}
          onChange={(selected) => setData({ ...data, realtorAssociations: selected ? Array.from(selected, (s) => s.value) : [] })}
          multiple
        />

        <Select
          label="MLS Systems"
          name="mlsAssociations"
          options={mlsAssociations}
          placeholder="Any MLS System"
          value={mlsAssociations.filter((association) => data.mlsAssociations.find((a) => a === association.value))}
          onChange={(selected) => setData({ ...data, mlsAssociations: selected ? Array.from(selected, (s) => s.value) : [] })}
          multiple
        />

        <TextInput
          label="Near"
          name="location"
          value={data.location}
          placeholder="i.e. Foxboro, MA 02035"
          onChange={(value) => setData({ ...data, location: value })}
        />

        <Select
          isClearable={false}
          label="Distance from Above"
          name="location"
          options={radiuses}
          value={data.radius}
          onChange={(selected) => setData({ ...data, radius: selected.value })}
        />

        <Select
          label="Roles"
          name="roles"
          options={roles}
          placeholder="Any Role"
          value={roles.filter((p) => data.roles.find((f) => f === p.value))}
          onChange={(selected) => setData({ ...data, roles: selected ? Array.from(selected, (s) => s.value) : [] })}
          multiple
        />

        <Select
          label="Website Status"
          name="website_status"
          options={website_statuses}
          placeholder="Any Status"
          value={website_statuses.filter((p) => data.website_status.find((f) => f === p.value))}
          onChange={(selected) => setData({ ...data, website_status: selected ? Array.from(selected, (s) => s.value) : [] })}
          multiple
        />

        <Select
          label="Status"
          name="status"
          options={account_statuses}
          placeholder="Any Status"
          value={account_statuses.filter((p) => data.status.find((f) => f === p.value))}
          onChange={(selected) => setData({ ...data, status: selected ? Array.from(selected, (s) => s.value) : [] })}
          multiple
        />
      </form>
    </SlideOver>
  )
}
