import { Fragment } from 'react'
import { usePage } from '@inertiajs/react'
import Button from '@/Shared/Button'
import { Tooltip } from '@/Shared/Tooltip'

const Highlight = ({ children }) => {
  return (
    <span className="ml-1.5 inline-block rounded-md border border-gray-300 bg-gray-200 bg-opacity-70 px-1.5 py-1 font-semibold leading-tight">
      {children}
    </span>
  )
}

export default ({ index, rule, operators, onEdit, onDelete }) => {
  const { checklist } = usePage().props
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]

  const getOperatorText = () => {
    return operators.find((o) => o.value === rule.condition.operator)?.label
  }

  const getStepName = (stepId) => {
    let name
    checklist.sections.every((section) =>
      section.steps.every((step) => {
        if (step.id.toString() == stepId) {
          name = `${step.index}. ${step.nameWithoutTags}`
          return false
        }

        if (step.subtasks?.length > 0) {
          step.subtasks.every((subtask, index) => {
            if (subtask.id.toString() == stepId) {
              name = `${step.index}${String.fromCharCode(index + 'A'.charCodeAt(0))}. ${subtask.nameWithoutTags}`
              return false
            }

            return true
          })
        }

        return true
      }),
    )

    return name || '(Step not found)'
  }

  const getValueText = (value) => {
    if (rule.condition.type == 'Date') {
      let date = new Date(value)
      return `${monthNames[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`
    } else {
      return value
    }
  }

  const ruleDescription = () => {
    if (['step_complete', 'step_incomplete', 'step_not_applicable'].indexOf(rule.event) >= 0) {
      return (
        <div className="flex flex-wrap items-center leading-[2.3]">
          When the status for step <Highlight>{getStepName(rule.condition.value)}</Highlight> is changed to{' '}
          <Highlight>
            {rule.event === 'step_complete' ? 'complete' : rule.event === 'step_incomplete' ? 'incomplete' : 'not applicable'}
          </Highlight>
          , change the status of this step to{' '}
          <Highlight>
            {rule.action === 'step_complete' ? 'complete' : rule.action === 'step_incomplete' ? 'incomplete' : 'not applicable'}
          </Highlight>
          .
        </div>
      )
    } else if (rule.event === 'data_changed') {
      return (
        <div className="flex flex-wrap items-center leading-[2.3]">
          When <Highlight>{rule.condition.label}</Highlight> <Highlight>{getOperatorText()}</Highlight>{' '}
          <Fragment>
            {['is_true', 'is_false', 'known', 'unknown'].indexOf(rule.condition.operator) < 0 && (
              <Highlight>{getValueText(rule.condition.value)}</Highlight>
            )}
            {rule.condition.value_end && (
              <Fragment>
                {' '}
                to <Highlight>{getValueText(rule.condition.value_end)}</Highlight>
              </Fragment>
            )}
          </Fragment>
          , change the status of this step to{' '}
          <Highlight>
            {rule.action === 'step_complete' ? 'complete' : rule.action === 'step_incomplete' ? 'incomplete' : 'not applicable'}
          </Highlight>
          .
        </div>
      )
    }
  }

  return (
    <div className="flex gap-3 rounded-md border border-gray-200 bg-white shadow-md">
      <div className="flex w-12 items-center justify-center rounded-l-md bg-gray-200 text-2xl font-bold text-gray-600">{index + 1}.</div>

      <div className="flex flex-1 items-center px-2 py-4">{ruleDescription()}</div>

      <div className="mr-4 flex items-center py-4">
        <Tooltip label="Edit" placement="bottom">
          <Button theme="icon" onClick={onEdit}>
            <i className="far fa-pen text-gray-500 group-hover:font-bold group-hover:text-blue-500"></i>
          </Button>
        </Tooltip>

        <Tooltip label="Remove" placement="bottom">
          <Button theme="icon" onClick={onDelete}>
            <i className="far fa-trash text-red-500 group-hover:font-bold group-hover:text-red-600"></i>
          </Button>
        </Tooltip>
      </div>
    </div>
  )
}
