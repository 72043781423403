import React, { createRef, Fragment, useEffect, useState } from 'react'
import AccountSettings from './Account'
import classNames from 'classnames'
import DeleteConfirmationDialog from '@/Shared/Dialog/DeleteConfirmationDialog'
import Dropdown from '@/Shared/Dropdown'
import DropdownItem from '@/Shared/DropdownItem'
import IncompleteProfileRequirements from './IncompleteProfileRequirements'
import { Link as InertiaLink, useForm } from '@inertiajs/react'
import Link from '@/Shared/Link'
import Main from '@/Shared/Layouts/Main'
import LSNSettings from './LSNs'
import NotificationSettings from './Notifications'
import Preferences from './Preferences'
import ServicesTownsSettings from './ServicesTowns'
import WebsiteSettings from './Website'
import { usePage } from '@inertiajs/react'
import { SettingProvider } from './SettingContext'
import { router } from '@inertiajs/react'

export default function Settings() {
  const scrollRef = createRef()
  const { user, group, support } = usePage().props
  const administrating = route()?.current()?.startsWith('admin')
  const { delete: destroy } = useForm({})
  const [deleting, setDeleting] = useState(false)

  const subNavigation = [
    {
      name: 'Account',
      href: administrating ? route('admin.pros.show', { contact: user.contact_id, subpage: 'account' }) : '/account/settings',
      icon: 'far fa-cog',
      current: group === 'account',
    },
    {
      name: `${administrating ? 'User' : 'Your'} Preferences`,
      href: administrating
        ? route('admin.pros.show', { contact: user.contact_id, subpage: 'preferences' })
        : '/account/settings/preferences',
      icon: 'far fa-list-check',
      current: group === 'preferences',
      roles: ['Associate'],
    },
    {
      name: 'Notifications',
      href: administrating
        ? route('admin.pros.show', { contact: user.contact_id, subpage: 'notifications' })
        : '/account/settings/notifications',

      icon: 'far fa-bell',
      current: group === 'notifications',
    },
    {
      name: 'My PRO-Website',
      href: administrating ? route('admin.pros.show', { contact: user.contact_id, subpage: 'my-website' }) : '/account/settings/my-website',
      icon: 'far fa-id-card',
      current: group === 'my-website',
    },
    {
      name: 'Services & Towns',
      href: administrating
        ? route('admin.pros.show', { contact: user.contact_id, subpage: 'services-towns' })
        : '/account/settings/services-towns',
      icon: 'far fa-city',
      current: group === 'services-towns',
    },
    {
      name: 'Local Services Network® (LSNs)',
      href: administrating ? route('admin.pros.show', { contact: user.contact_id, subpage: 'lsns' }) : '/account/settings/lsns',
      icon: 'far fa-users',
      current: group === 'lsns',
    },
  ]

  useEffect(() => {
    if (Boolean(support)) {
      FreshworksWidget('open')
    }

    if (group !== 'account') {
      setTimeout(() => {
        scrollRef.current?.scrollIntoView({
          behavior: 'smooth',
        })
      }, 100)
    }
  }, [])

  const onRestoreAccount = () => {
    router.patch(
      route('admin.pros.update', user.contact_id),
      {
        deleted_at: null,
        published: true,
      },
      {
        only: ['user', 'flash'],
      },
    )
  }

  const onSuspendAccount = () => {
    router.patch(
      route('admin.pros.update', user.contact_id),
      {
        published: false,
      },
      {
        only: ['user', 'flash'],
      },
    )
  }

  const onDeleteAccount = () => {
    destroy(route('admin.pros.destroy', user.contact_id), {
      onSuccess: () => {
        setDeleting(false)
      },
    })
  }

  return (
    <div className="flex flex-1 flex-col">
      <div className="flex items-center justify-between gap-6 bg-white px-4 py-4 sm:px-8">
        <div className="flex w-full flex-wrap items-center justify-start">
          <h1 className="pr-4 text-lg font-medium text-gray-800">
            {administrating ? (
              <div className="flex flex-col gap-3 leading-none md:flex-row md:items-center">
                <div className="flex items-center gap-3">
                  <Link href={route('admin.pros.index')} inertia>
                    <span className="font-medium">PROs</span>
                  </Link>
                  <i className="far fa-angle-right"></i>
                </div>
                <span>{user.full_name}</span>
              </div>
            ) : (
              'Settings'
            )}
          </h1>
        </div>
        <div className="flex shrink-0 items-center gap-2">
          {!user.deleted_at && Boolean(user.published) && (
            <Link theme="solid" disabled={user.services.length == 0} href={user.services.length > 0 ? user.url : null} target="_blank">
              <div className="flex items-center gap-3">
                <span>
                  {user.visible === 'public' ? 'View' : 'Preview'}
                  {!administrating && ' My'} Website
                </span>
                <i className="fas fa-external-link"></i>
              </div>
            </Link>
          )}

          {administrating && (
            <Dropdown
              id="actions-options"
              label={<i className="fas fa-ellipsis-v text-xl"></i>}
              tooltip="Actions"
              orientation="right"
              icon
              size="w-64"
            >
              {user.deleted_at || !user.published ? (
                <DropdownItem onClick={() => onRestoreAccount()}>
                  <i className="fas fa-undo mr-4"></i>
                  Restore Account
                </DropdownItem>
              ) : (
                <Fragment>
                  <DropdownItem colorClasses="text-red-500 hover:text-red-600" onClick={() => onSuspendAccount()}>
                    <i className="fas fa-ban mr-4"></i>
                    Suspend Account
                  </DropdownItem>

                  <DropdownItem colorClasses="text-red-500 hover:text-red-600" onClick={() => setDeleting(true)}>
                    <i className="fas fa-trash mr-4"></i>
                    {user.associate_id ? 'Terminate' : 'Delete'} Account
                  </DropdownItem>
                </Fragment>
              )}
            </Dropdown>
          )}
        </div>
      </div>

      <div className="mx-auto flex w-full max-w-screen-xl flex-grow flex-col px-4 py-6 sm:px-6 lg:px-8 lg:py-8">
        <div className="flex flex-grow overflow-hidden rounded-lg bg-white shadow">
          <div className="grid flex-grow divide-y divide-gray-200 lg:grid-cols-12 lg:divide-x lg:divide-y-0">
            <div className="md:hidden">
              <IncompleteProfileRequirements user={user} />
            </div>

            <aside className="space-y-4 py-6 lg:col-span-3">
              <div className="mb-4 flex justify-center">
                <div className="relative inline-flex h-40 w-40 items-center justify-center rounded-full text-white outline-none ring-2 ring-white">
                  <span className="inline-flex h-40 w-40 items-center justify-center overflow-hidden rounded-full border border-gray-300 outline-none">
                    <img src={user.avatar || '/images/layout/headshot_ph.png'} alt={user.full_name} />
                  </span>
                </div>
              </div>

              {administrating && (
                <div className="text-center">
                  <span
                    className={classNames(
                      'inline-flex items-center whitespace-nowrap rounded px-2.5 py-1.5 text-sm font-medium uppercase leading-none',
                      user.deleted_at
                        ? 'bg-red-600 text-white'
                        : !user.published
                          ? 'bg-orange-500 text-white'
                          : {
                              'bg-green-500 text-white': user.visible === 'public',
                              'bg-blue-500 text-white': user.visible === 'internal',
                              'bg-gray-300/75 text-gray-600': !user.visible,
                            },
                    )}
                  >
                    <span className="whitespace-pre-line leading-none">
                      {user.deleted_at
                        ? 'Deleted'
                        : !user.published
                          ? 'Suspended'
                          : user.visible === 'public'
                            ? 'Public'
                            : user.visible === 'internal'
                              ? 'Internal'
                              : 'Private'}
                    </span>
                  </span>
                </div>
              )}

              <nav className="space-y-1">
                {subNavigation
                  .filter((item) => item.roles == undefined || user.roles.some((r) => item.roles.indexOf(r) >= 0))
                  .map((item) => (
                    <InertiaLink
                      key={item.name}
                      href={item.href}
                      className={classNames(
                        item.current
                          ? 'border-primary-500 bg-primary-50 text-primary-700 hover:bg-primary-50 hover:text-primary-700'
                          : 'border-transparent text-gray-900 hover:bg-gray-50 hover:text-gray-900 focus:bg-gray-50 focus:text-gray-900',
                        'group flex items-center border-l-4 px-3 py-2 font-medium outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500',
                      )}
                      aria-current={item.current ? 'page' : undefined}
                    >
                      <i
                        className={classNames(
                          item.icon,
                          item.current
                            ? 'text-primary-500 group-hover:text-primary-500'
                            : 'text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500',
                          'mr-3 flex h-8 w-8 flex-shrink-0 items-center justify-center text-2xl',
                        )}
                        aria-hidden="true"
                      ></i>
                      <span className="truncate leading-none">{item.name}</span>
                    </InertiaLink>
                  ))}
              </nav>
            </aside>

            <div ref={scrollRef} className="lg:col-span-9">
              <SettingProvider>
                <React.Fragment>
                  <div className="hidden md:block">
                    <IncompleteProfileRequirements user={user} />
                  </div>

                  {
                    {
                      account: <AccountSettings />,
                      preferences: <Preferences />,
                      notifications: <NotificationSettings />,
                      'my-website': <WebsiteSettings />,
                      lsns: <LSNSettings />,
                      'services-towns': <ServicesTownsSettings />,
                    }[group]
                  }
                </React.Fragment>
              </SettingProvider>
            </div>
          </div>
        </div>
      </div>

      <DeleteConfirmationDialog
        buttonText="Yes, Continue"
        open={deleting}
        onCanceled={() => setDeleting(false)}
        onApproved={() => onDeleteAccount()}
      >
        <Fragment>
          <p>Are you sure you want to delete this PRO?</p>
        </Fragment>
      </DeleteConfirmationDialog>
    </div>
  )
}

Settings.layout = (page) => <Main title="Account Settings" children={page} />
