import { useEffect, useState } from 'react'
import Button from '@/Shared/Button'
import Dropdown from '@/Shared/Dropdown'
import DropdownItem from '@/Shared/DropdownItem'
import Preview from '@/Pages/Contacts/Preview'
import { router } from '@inertiajs/react'

export default ({ relationship: record, onClosed }) => {
  const [contact, setContact] = useState(null)

  useEffect(() => loadContact(record), [record])

  const loadContact = (relationship) => {
    if (relationship) {
      axios.get(route('api.contacts.show', relationship.related_id)).then((response) => {
        setContact({ ...response.data, relationship: relationship.label })
      })
    } else {
      setContact(null)
    }
  }

  const handleClosed = () => {
    setContact(null)
    setTimeout(() => onClosed(), 500)
  }

  const onRemove = () => {
    router.delete(route('api.contacts.relationships.destroy', { contact: record.contact_id, relationship: record.id }), {
      onSuccess: () => {
        handleClosed()
      },
    })
  }

  return (
    <Preview
      linkedId={record.id}
      linkedType="relationship"
      contact={contact}
      open={contact != null}
      onClosed={() => handleClosed()}
      editable
    >
      <Preview.Actions>
        {({ contact }) => (
          <Dropdown id="actions-options" label="Actions" orientation="right" size="w-64">
            <DropdownItem onClick={() => onRemove()}>
              <i className="fas fa-unlink w-8 text-gray-500 group-hover:text-gray-600"></i>
              Remove Relationship
            </DropdownItem>
          </Dropdown>
        )}
      </Preview.Actions>

      <Preview.FooterActions>
        {({ contact, onVisit }) => (
          <div className="flex justify-center">
            <Button type="submit" theme="solid" onClick={() => onVisit(contact.id)}>
              View Contact
            </Button>
          </div>
        )}
      </Preview.FooterActions>
    </Preview>
  )
}
