import classNames from 'classnames'
import React from 'react'

const Icon = ({ icon, action }) =>
  icon ? (
    <div
      className={classNames(
        'absolute left-0 -ml-2.5 inline-flex h-10 w-10 items-center justify-center overflow-hidden rounded-full border border-gray-100 shadow',
        ['transaction_completed'].indexOf(action) >= 0 ? 'bg-blue-500' : 'bg-white',
      )}
      dangerouslySetInnerHTML={{ __html: icon }}
    ></div>
  ) : (
    <div className="absolute left-0 -ml-0.5 inline-flex h-6 w-6 items-center justify-center overflow-hidden rounded-full border border-gray-100 bg-white shadow">
      <div className="rounded-full bg-primary-500" style={{ width: '6px', height: '6px' }}></div>
    </div>
  )

export default ({ children, icon, timestamp, action }) => {
  return (
    <div className="static ml-11 mt-6 flex items-start">
      <Icon icon={icon} action={action} />

      <div className="flex flex-grow flex-col">
        {children}
        <div className="text-sm text-gray-500">{timestamp}</div>
      </div>
    </div>
  )
}
