import React, { useEffect, useRef, useState } from 'react'
import { router } from '@inertiajs/react'
import { useForm, usePage } from '@inertiajs/react'
import Alert from '@/Shared/Alert'
import Button from '@/Shared/Button'
import Checkbox from '@/Shared/Forms/Checkbox'
import Select from '@/Shared/Forms/Select'
import SlideOver from '@/Shared/SlideOver'
import TextInput from '@/Shared/Forms/TextInput'
import { Tooltip } from '@/Shared/Tooltip'

export default ({ open, onClosed, record }) => {
  let focusRef = useRef()
  const { directories } = usePage().props
  const { clearErrors, data, setData, errors, setError, post } = useForm()
  const notificationGroups = [
    { value: 'associates', label: 'Associates' },
    { value: 'hr', label: 'Human Resources' },
    // { value: 'sponsors', label: 'Sponsors' },
    { value: 'svp', label: 'Support Vice President' },
  ]

  useEffect(() => {
    if (record) {
      setData({
        id: record.id || null,
        directory: record.directory || '',
        name: record.name || '',
        filename: record.filename || '',
        active: record.active != undefined ? record.active : true,
        metadata: record.metadata || { notifications: [] },
        allow_addendums: record.allow_addendums != undefined ? record.allow_addendums : false,
        required_per_associate: record.required_per_associate != undefined ? record.required_per_associate : false,
        required_per_state: record.required_per_state != undefined ? record.required_per_state : false,
        optional: record.optional || false,
      })
    }
  }, [record])

  const submit = (event) => {
    event.preventDefault()

    if (data.id) {
      axios
        .patch(route('admin.file-types.update', data.id), { ...data, _method: 'patch' })
        .then((_) => {
          onClosed()
          setTimeout(() => {
            router.visit(window.location.href, {
              preserveScroll: true,
              replace: true,
            })
          }, 300)
        })
        .catch((error) => {
          if (error.response != undefined) {
            const { status } = error.response
            const VALIDATION_ERROR = 422
            if (status == VALIDATION_ERROR) {
              setError(error.response.data.errors)
            }
          }
        })
    } else {
      post(route('admin.file-types.store'), {
        onSuccess: () => {
          clearErrors()
          onClosed()
        },
      })
    }
  }

  return (
    <SlideOver
      focusRef={focusRef}
      footerActions={
        <Button type="submit" theme="solid" form="newChecklist">
          {data.id ? 'Save Changes' : 'Create'}
        </Button>
      }
      show={open}
      size="max-w-lg"
      title={data.id ? data.name : 'New'}
      subTitle="Checklist File Type"
      onClosed={onClosed}
    >
      {record?.controlled && (
        <Alert
          type="notice"
          heading="This is a system-controlled File Type"
          subtext="Certain attributes have been disabled to maintain system functonality."
        />
      )}

      <form id="newChecklist" className="space-y-6 pb-4" onSubmit={submit}>
        <div className="space-y-4">
          <div className="flex justify-end">
            <Checkbox
              name="active"
              label="Active"
              value={data.active}
              onChange={(selected) => setData({ ...data, active: selected })}
              disabled={record?.controlled}
            />
          </div>

          <Select
            ref={focusRef}
            label="Directory"
            name="directory"
            options={directories}
            placeholder="Choose a Directory"
            error={errors.directory}
            value={data.directory}
            isClearable={false}
            onChange={(selected) => setData({ ...data, directory: selected?.value })}
            disabled={record?.controlled}
            required
          />

          <TextInput
            label="Type"
            name="name"
            value={data.name}
            error={errors.name}
            onChange={(value) => setData({ ...data, name: value })}
            required
            disabled={record?.controlled}
          />

          <TextInput
            label={
              <div className="flex items-center">
                <span>
                  Filename <span className="pl-1 text-red-600">*</span>
                </span>
                <span className="ml-1.5 normal-case">(Don't include extension)</span>
              </div>
            }
            name="filename"
            value={data.filename}
            error={errors.filename}
            onChange={(value) => setData({ ...data, filename: value })}
          />

          <Select
            label={
              <div className="flex items-center gap-2">
                <span>Notifications</span>
                <Tooltip label="Notifies the specified groups when this file is uploaded or replaced.">
                  <i className="far fa-question-circle cursor-help text-lg leading-none text-gray-400"></i>
                </Tooltip>
              </div>
            }
            name="notifications"
            options={notificationGroups}
            placeholder="None"
            value={notificationGroups.filter((group) => data.metadata?.notifications?.find((f) => f === group.value))}
            onChange={(selected) =>
              setData({ ...data, metadata: { ...data.metadata, notifications: selected ? Array.from(selected, (s) => s.value) : [] } })
            }
            multiple
          />

          <div className="space-y-5 pt-3">
            <div>
              <Checkbox
                name="allow_addendums"
                label="Allow Addendums"
                description="Any number of addendums can be uploaded for this document.  Each addendum will be labeled as an addendum including the date & time when it was uploaded."
                value={data.allow_addendums}
                onChange={(selected) => setData({ ...data, allow_addendums: selected })}
                disabled={record?.controlled}
              />
            </div>

            <div>
              <Checkbox
                name="required_per_associate"
                label="Required per Associate"
                description="A file must be supplied for each Associate."
                value={data.required_per_associate}
                onChange={(selected) => setData({ ...data, required_per_associate: selected })}
                disabled={record?.controlled}
              />
            </div>

            <div>
              <Checkbox
                name="required_per_state"
                label="Required per State"
                description="A file must be supplied for each State."
                value={data.required_per_state}
                onChange={(selected) => setData({ ...data, required_per_state: selected })}
                disabled={record?.controlled}
              />
            </div>

            <div>
              <Checkbox
                name="optional"
                label="Optional"
                description="Allows this file to be marked as not applicable bypassing requirements for this file."
                value={data.optional}
                onChange={(selected) => setData({ ...data, optional: selected })}
                disabled={record?.controlled}
              />
            </div>
          </div>
        </div>

        {record.id && (
          <div className="mt-6 space-y-3 border-t border-dashed border-gray-300 pt-6">
            <div className="flex items-center justify-center gap-4">
              <label className="block w-40 text-right text-sm font-semibold uppercase text-gray-700">Created</label>
              <div className="flex-1">{record.created_at}</div>
            </div>
            <div className="flex items-center justify-center gap-4">
              <label className="block w-40 text-right text-sm font-semibold uppercase text-gray-700">Last Updated</label>
              <div className="flex-1">{record.updated_at}</div>
            </div>
          </div>
        )}
      </form>
    </SlideOver>
  )
}
