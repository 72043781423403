import { createContext, useState } from 'react'
import { nanoid } from 'nanoid'

export const SubtasksContext = createContext({
  activeSubtask: null,
  subtasks: null,
  setActiveSubtask: (subtask) => {},
  setSubtasks: () => {},
  onSubtaskAdding: (subtask) => {},
  onSubtaskCreating: (stepId) => {},
  onSubtaskDeleting: (index) => {},
  onSubtaskDuplicating: (subtask) => {},
  onSubtaskEditing: (subtask) => {},
  onSubtaskUpdating: (index, data) => {},
})

export const SubtasksProvider = ({ children }) => {
  const [activeSubtask, setActiveSubtask] = useState(null)
  const [subtasks, setSubtasks] = useState(null)

  const onSubtaskCreating = (stepId) => {
    setActiveSubtask({
      parent_id: stepId,
      sort_order: subtasks.length + 1,
    })
  }

  const onSubtaskAdding = (subtask) => {
    setSubtasks(subtasks.concat({ ...subtask, id: nanoid(), sort_order: subtasks.length + 1 }))
  }

  const onSubtaskDeleting = (id) => {
    setSubtasks(subtasks.filter((subtask) => (subtask.id ? subtask.id != id : subtask.temp_id != id)))
  }

  const onSubtaskDuplicating = (subtask) => {
    setActiveSubtask({ ...subtask, id: undefined, temp_id: undefined, sort_order: subtasks.length + 1 })
  }

  const onSubtaskEditing = (subtask) => {
    setActiveSubtask(subtask)
  }

  const onSubtaskUpdating = (data) => {
    setSubtasks(
      subtasks.map((subtask) => {
        let field = subtask.id != undefined ? 'id' : 'temp_id'
        return subtask[field] == data[field] ? data : subtask
      }),
    )
  }

  const context = {
    activeSubtask,
    subtasks,
    setActiveSubtask,
    setSubtasks,
    onSubtaskAdding,
    onSubtaskCreating,
    onSubtaskDeleting,
    onSubtaskDuplicating,
    onSubtaskEditing,
    onSubtaskUpdating,
  }

  return <SubtasksContext.Provider value={context}>{children}</SubtasksContext.Provider>
}

export const SubtasksConsumer = SubtasksContext.Consumer
