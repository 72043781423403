import { useState } from 'react'
import Button from '@/Shared/Button'
import Notice from '@/Shared/Notice'
import RevisionDialog from './RevisionDialog'

export default ({ revisions }) => {
  const [reviewing, setReviewing] = useState(false)

  return (
    <div className="border-b border-gray-200 bg-white p-4">
      <Notice
        heading={
          <span className="font-medium text-gray-600">
            This contact has <span className="font-bold text-blue-500">{revisions.length}</span> pending revisions requiring your review.
          </span>
        }
        action={
          <Button
            type="submit"
            theme="solid-sm"
            colors="bg-orange-500 text-white hover:bg-opacity-75 focus:ring-2 focus:ring-orange-500"
            onClick={() => setReviewing(true)}
          >
            Review
          </Button>
        }
      />

      <RevisionDialog open={reviewing} revisions={revisions} onClosing={() => setReviewing(false)} />
    </div>
  )
}
