import React, { useEffect, useRef, useState } from 'react'
import { router } from '@inertiajs/react'
import { useForm } from '@inertiajs/react'
import Button from '@/Shared/Button'
import Checkbox from '@/Shared/Forms/Checkbox'
import SlideOver from '@/Shared/SlideOver'
import TextInput from '@/Shared/Forms/TextInput'

export default ({ open, onClosed, record }) => {
  let focusRef = useRef()
  const category = record || {}
  const form = useForm({
    id: category.id || null,
    name: category.name || '',
    active: category.active || true,
  })
  const { clearErrors, data, errors, setData, setError, post } = form

  useEffect(() => {
    if (record) {
      setData({
        id: record.id || null,
        name: record.name || '',
        active: record.active != undefined ? record.active : true,
      })
    }
  }, [record])

  const onClosing = () => {
    clearErrors()
    onClosed()
  }

  const submit = (event) => {
    event.preventDefault()

    if (data.id) {
      router.patch(route('admin.email-templates.categories.update', data.id), data, {
        onSuccess: () => onClosing(),
        onError: (errors) => setError(errors),
      })
    } else {
      post(route('admin.email-templates.categories.store'), {
        onSuccess: () => {
          onClosing()
        },
      })
    }
  }

  return (
    <SlideOver
      focusRef={focusRef}
      footerActions={
        <Button type="submit" theme="solid" form="newChecklist">
          {data.id ? 'Save Changes' : 'Create'}
        </Button>
      }
      show={open}
      size="max-w-lg"
      title={data.id ? data.name : 'New'}
      subTitle="Email Template Category"
      onClosed={onClosing}
    >
      <form id="newChecklist" className="mb-5 flex flex-col" onSubmit={submit}>
        <TextInput
          ref={focusRef}
          label="Name"
          name="name"
          value={data.name}
          error={errors.name}
          onChange={(value) => setData({ ...data, name: value })}
          required
        />

        <div className="mb-3">
          <Checkbox name="active" label="Active" value={data.active} onChange={(selected) => setData({ ...data, active: selected })} />
        </div>
      </form>
    </SlideOver>
  )
}
