import Button from '@/Shared/Button'
import TextArea from '@/Shared/Forms/TextArea'
import TextInput from '@/Shared/Forms/TextInput'
import { useForm, usePage } from '@inertiajs/react'

export default ({ contact }) => {
  const { user } = usePage().props.auth
  const form = useForm({
    name: user?.full_name || '',
    email: user?.email || '',
    phone: user?.phone_numbers?.find((phone) => phone.primary)?.phone_formatted || '',
    message: '',
  })
  const { clearErrors, data, errors, setData, post } = form

  const submit = (e) => {
    e.preventDefault()

    post(route('pros.contact', contact.id), {
      onSuccess: () => {
        clearErrors()
        setData({
          name: user?.full_name || '',
          email: user?.email || '',
          phone: user?.phone_numbers?.find((phone) => phone.primary)?.phone_formatted || '',
          message: '',
        })
      },
    })
  }

  return (
    <div id="contact" className="space-y-5 pb-8">
      <h2 className="inline-block bg-primary-200 bg-opacity-40 py-3 px-8 font-bebas text-4xl font-bold text-primary-500">Contact Me</h2>

      <p>Use the contact form below to email me directly or, if you prefer, contact me via phone or one of the other methods listed.</p>

      <form onSubmit={submit}>
        <TextInput
          label="name"
          name="Name"
          error={errors.name}
          value={data.name}
          onChange={(value) => setData({ ...data, name: value })}
          required
        />
        <TextInput
          label="email"
          name="Email"
          error={errors.email}
          value={data.email}
          onChange={(value) => setData({ ...data, email: value })}
          required
        />
        <TextInput
          label="phone"
          name="Phone"
          error={errors.phone}
          value={data.phone}
          onChange={(value) => setData({ ...data, phone: value })}
        />
        <TextArea
          label="message"
          name="Message"
          value={data.message}
          error={errors.message}
          rows="5"
          onChange={(value) => setData({ ...data, message: value })}
          required
          multiline
        />

        <div className="flex justify-end">
          <Button type="submit" theme="solid">
            Submit
          </Button>
        </div>
      </form>
    </div>
  )
}
