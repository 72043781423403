import { Fragment, useEffect, useState } from 'react'
import About from './About'
import Button from '@/Shared/Button'
import Card from './Card'
import Checklist from './Checklist'
import classNames from 'classnames'
import Details from '@/Shared/Layouts/Details'
import Edit from './Edit'
import EditInvitation from './Members/EditInvitation'
import Info from '@/Shared/Info'
import LSNMobile from './Mobile/Index'
import Main from '@/Shared/Layouts/Main'
import Members from './Members/Index'
import Pages from './Pages'
import SearchDialog from './Members/SearchDialog'
import Tab from '@/Shared/Tabs/Tab'

function debounce(fn, ms) {
  let timer
  return (_) => {
    clearTimeout(timer)
    timer = setTimeout((_) => {
      timer = null
      fn.apply(this, arguments)
    }, ms)
  }
}

const LSNShow = ({ lsn }) => {
  const [editing, setEditing] = useState(false)
  const [activeTab, setActiveTab] = useState('members')
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
  })
  const [focusField, setFocusField] = useState(null)
  const [searching, setSearching] = useState(false)
  const [invitation, setInvitation] = useState(null)

  const replacementDate = lsn.replace_leaders_at && new Date(lsn.replace_leaders_at)
  const isReplacementExpired = replacementDate != undefined && replacementDate <= new Date()
  const isReplacementExpiring =
    replacementDate != undefined && !isReplacementExpired && replacementDate.setDate(replacementDate.getDate() - 30) <= new Date()
  const debouncedHandleResize = debounce(function handleResize() {
    setDimensions({
      width: window.innerWidth,
    })
  }, 200)

  useEffect(() => {
    window.addEventListener('resize', debouncedHandleResize)

    return (_) => {
      window.removeEventListener('resize', debouncedHandleResize)
    }
  })

  const onInviting = (search) => {
    setSearching(false)

    let first, last, email
    if (typeof search === 'object') {
      setTimeout(() => {
        setInvitation(search)
      }, 250)
    } else {
      if (!search.includes('@')) {
        let fullName = search.split(' ')
        first = fullName[0]
        last = search.substring(fullName[0].length).trim()
      } else {
        email = search
      }

      setTimeout(() => {
        setInvitation({
          first_name: first,
          last_name: last,
          email: email,
        })
      }, 250)
    }
  }

  const onView = () => {
    // prettier-ignore
    window.open(lsn.published ? lsn.permalink : lsn.previewLink, '_blank')
  }

  return (
    <Details columns={3}>
      <Details.Card>
        <Card
          onEdit={() => {
            setFocusField(null)
            setEditing(true)
          }}
        />

        {lsn.published && (
          <div className="hidden justify-center border-b border-gray-200 bg-primary-100 py-4 md:flex xl:hidden">
            <Button theme="solid" onClick={() => onView()}>
              <span className="pr-1">View LSN</span>
              <i className="fas fa-external-link ml-3 text-lg"></i>
            </Button>
          </div>
        )}
      </Details.Card>

      <Details.LeftColumn>
        {dimensions.width >= 768 ? (
          <About />
        ) : (
          <LSNMobile onSearching={() => setSearching(true)}>
            {!lsn.published ? (
              <Checklist
                onSettingClicked={(field) => {
                  setFocusField(field)
                  setEditing(true)
                }}
                onNewMember={() => setSearching(true)}
              />
            ) : (
              <div className="flex justify-center bg-white pb-6">
                <Button theme="solid" onClick={() => onView()}>
                  <span className="pr-1">View LSN</span>
                  <i className="fas fa-external-link ml-3 text-lg"></i>
                </Button>
              </div>
            )}
          </LSNMobile>
        )}
      </Details.LeftColumn>

      <Details.MiddleColumn>
        {dimensions.width >= 768 && (
          <div className="flex h-full flex-col overflow-hidden bg-gray-100">
            <div className="flex h-full flex-col">
              <div className="relative z-0 flex w-full flex-col self-stretch overflow-hidden">
                {isReplacementExpired ? (
                  <div className="mx-4 mt-6 flex items-center justify-center rounded-md bg-red-500 p-4 text-lg font-medium text-white shadow-md sm:mx-6">
                    <i className="fas fa-warning mr-3 text-2xl"></i>
                    The term for the Leadership Team of this LSN expired on {lsn.replace_leaders_at}.
                  </div>
                ) : (
                  isReplacementExpiring && (
                    <div className="mx-4 mt-6 flex items-center justify-center rounded-md bg-yellow-100 p-4 text-lg font-medium text-yellow-600 shadow-md sm:mx-6">
                      <i className="fas fa-warning mr-3 text-2xl"></i>
                      The term for the Leadership Team of this LSN will expire on {lsn.replace_leaders_at}.
                    </div>
                  )
                )}

                <div className="mt-6 flex h-full flex-col overflow-hidden">
                  <div className="mb-6 mt-2 flex items-center justify-between gap-6 px-4 sm:px-6">
                    <div className="flex-1 border-b border-gray-400 border-opacity-20">
                      <ul role="tablist" className="list-reset flex flex-auto flex-wrap justify-start">
                        <Tab active={activeTab === 'members'} onClick={() => setActiveTab('members')}>
                          Members
                        </Tab>
                      </ul>
                    </div>

                    {lsn.editable && (
                      <Button theme="solid" onClick={() => setSearching(true)}>
                        <i className="fas fa-user-plus mr-2 text-lg"></i>
                        <span className="pr-1">New</span>
                      </Button>
                    )}
                  </div>

                  <div className="flex flex-col overflow-hidden">
                    <div
                      id="members"
                      className={classNames('flex flex-col overflow-y-auto px-4 sm:px-6', {
                        hidden: activeTab !== 'members',
                      })}
                    >
                      <Members />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <Edit record={lsn} focusField={focusField} open={editing} onClosed={() => setEditing(false)} />
        <SearchDialog open={searching} onClose={() => setSearching(false)} onCreateContact={(search) => onInviting(search)} />
        <EditInvitation record={invitation} open={invitation != null} onClosed={() => setInvitation(null)} />
      </Details.MiddleColumn>

      {dimensions.width >= 768 && (
        <Details.RightColumn>
          {!lsn.published && (
            <Fragment>
              {lsn.editable ? (
                <Checklist
                  onSettingClicked={(field) => {
                    setFocusField(field)
                    setEditing(true)
                  }}
                  onNewMember={() => setSearching(true)}
                />
              ) : (
                <div className="flex justify-center border-b border-gray-200 px-6 py-4 font-medium">
                  <Info
                    text="This LSN is not currently available to the general public. Please contact a member of your LSN's Leadership Team for
                    more information."
                  />
                </div>
              )}
            </Fragment>
          )}

          {lsn.published && (
            <div className="hidden justify-center border-b border-gray-200 bg-primary-100 py-4 xl:flex">
              <Button theme="solid" onClick={() => onView()}>
                <span className="pr-1">View LSN</span>
                <i className="fas fa-external-link ml-3 text-lg"></i>
              </Button>
            </div>
          )}
          <Pages />
        </Details.RightColumn>
      )}
    </Details>
  )
}

// Persistent layout
// Docs: https://inertiajs.com/pages#persistent-layouts
LSNShow.layout = (page) => <Main title={page.props.lsn.name} children={page} overflowHidden />

export default LSNShow
