import { Fragment, createRef, useEffect, useState } from 'react'
import axios from '@/apis/axios'
import classNames from 'classnames'
import { router } from '@inertiajs/react'
import NoResults from './NoResults'
import Portlet from '@/Shared/Portlet'
import { Tooltip } from '@/Shared/Tooltip'
import useInfiniteScroll from '@/hooks/useInfiniteScroll'
import { usePage } from '@inertiajs/react'

export default () => {
  const scrollRef = createRef()
  const { filters, openhouses: records } = usePage().props
  const [openhouses, setOpenHouses] = useState([])
  const [status, setStatus] = useState(filters.openhouses?.status || '')
  const { isFetching, setIsFetching, setStopFetching, handleInfiniteScroll } = useInfiniteScroll(fetchMore)
  const [page, setPage] = useState(1)

  useEffect(() => {
    setOpenHouses(records)
  }, [records])

  function fetchMore() {
    if (isFetching) {
      setStopFetching(true)

      axios
        .get(route('api.openhouses.index'), {
          params: {
            page: page + 1,
          },
        })
        .then((response) => {
          let records = response.data?.openhouses?.data
          if (records.length > 0) {
            setOpenHouses(openhouses.concat(records))
            setPage(page + 1)
            setIsFetching(false)
            setStopFetching(false)
          }
        })
    }
  }

  const filter = (status) => {
    setIsFetching(false)

    scrollRef.current.scrollTo({
      top: 0,
    })

    router.reload({
      data: { openhouses: { status: status.toLowerCase() } },
      only: ['openhouses'],
      onFinish: () => {
        setStatus(status)
        setPage(1)
        setStopFetching(false)
      },
    })
  }

  const rowClicked = (id) => {
    router.visit(route('openhouses.show', id))
  }

  return (
    <Fragment>
      <Portlet ref={scrollRef} onScroll={handleInfiniteScroll}>
        <Portlet.Header>
          <div className="-my-0.5 flex items-center justify-between gap-3">
            <div className="flex-1">Open Houses</div>
            <span className="relative z-0 inline-flex space-x-1.5">
              <button
                type="button"
                className={classNames(
                  'relative inline-flex items-center rounded-md px-4 py-2.5 font-medium uppercase text-blue-500 hover:bg-gray-50 focus:z-10 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500',
                  status === '' ? 'bg-white shadow' : 'bg-gray-200',
                )}
                onClick={() => (status === '' ? null : filter(''))}
              >
                All
              </button>
              <button
                type="button"
                className={classNames(
                  'relative inline-flex items-center rounded-md px-4 py-2.5 font-medium uppercase text-blue-500 hover:bg-gray-50 focus:z-10 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500',
                  status === 'Scheduled' ? 'bg-white shadow' : 'bg-gray-200',
                )}
                onClick={() => (status === 'Scheduled' ? null : filter('Scheduled'))}
              >
                Scheduled
              </button>
              <button
                type="button"
                className={classNames(
                  'relative inline-flex items-center rounded-md px-4 py-2.5 font-medium uppercase text-blue-500 hover:bg-gray-50 focus:z-10 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500',
                  status === 'Past' ? 'bg-white shadow' : 'bg-gray-200',
                )}
                onClick={() => (status === 'Past' ? null : filter('Past'))}
              >
                Past
              </button>
            </span>
          </div>
        </Portlet.Header>

        <Portlet.Body>
          {openhouses.length > 0 ? (
            <div className="divide-y divide-gray-200 border-b border-gray-200">
              {openhouses.map(({ id, address, end, start, image, visitors, when }) => (
                <div className="flex gap-4 hover:bg-gray-100" key={id}>
                  <div className="flex items-center whitespace-nowrap py-3 pl-4" onClick={() => rowClicked(id)}>
                    <div className="flex h-10 w-10 flex-shrink-0 cursor-pointer items-center justify-center">
                      <div>{image && <img className="h-10 w-10 rounded-full" src={image} alt="" />}</div>
                    </div>
                  </div>

                  <div className="flex flex-1 cursor-pointer flex-col justify-center py-3 leading-snug" onClick={() => rowClicked(id)}>
                    <div className="font-bold">{address}</div>
                    <div>
                      {when} • {start} ~ {end}
                    </div>
                  </div>

                  <Tooltip label="Visitors">
                    <div className="flex shrink-0 items-center gap-2 py-3 pr-4">
                      <span className="text-gray-400">({visitors || 0})</span>

                      <i className="fas fa-house-person-return text-xl leading-none text-gray-400"></i>
                    </div>
                  </Tooltip>
                </div>
              ))}
            </div>
          ) : (
            <div className="flex max-h-[400px] min-h-[400px] items-center justify-center py-6">
              <NoResults label={`${status ? status.replace(/^\w/, (c) => c.toUpperCase()) : ''} Open Houses`} />
            </div>
          )}
        </Portlet.Body>
      </Portlet>
    </Fragment>
  )
}
