import { createRef, useContext, useEffect, useRef, useState } from 'react'
import { AccordionContext } from '@/Shared/Accordion'
import classNames from 'classnames'
import Button from '@/Shared/Button'
import PhotoEditor from '@/Shared/PhotoEditor/Index'
import { router } from '@inertiajs/react'

export default ({ user }) => {
  const scrollRef = createRef()
  const fileRef = useRef()
  const { onSaved } = useContext(AccordionContext)
  const [file, setFile] = useState(null)
  const [image, setImage] = useState(user.logo)

  useEffect(() => {
    setTimeout(() => {
      scrollRef.current.scrollIntoView()
    }, 100)
  }, [])

  const readFile = (files) => {
    return new Promise((resolve, reject) => {
      if (!files || files.length === 0) {
        resolve()
        return
      }

      const file = files[0]

      if (/^image\/\w+$/.test(file.type)) {
        if (URL) {
          resolve({
            loaded: true,
            name: file.name,
            type: file.type,
            url: URL.createObjectURL(file),
          })
        } else {
          reject(new Error('Your browser is not supported.'))
        }
      } else {
        reject(new Error('Please choose an image file.'))
      }
    })
      .then((data) => {
        setFile(data)
      })
      .catch((error) => {
        this.alert(error)
      })
  }

  const photoCropped = (image) => {
    setFile(null)
    setImage(image)
  }

  const submit = (event) => {
    event.preventDefault()

    if (image === user.logo) {
      onSaved()
      return
    }

    const formData = new FormData()

    // Convert blob data url to blob, where applicable and submit
    fetch(image)
      .then((res) => res.blob())
      .then((blob) => {
        formData.append('setting', 'logo')
        formData.append('_method', 'PATCH')
        formData.append('file', blob, image)

        axios
          .post(route('api.users.update', user.id), formData, {
            headers: { 'Content-Type': `multipart/form-data; boundary=${formData._boundary}` },
          })
          .then((_) =>
            router.reload({
              onFinish: () => {
                onSaved()
              },
            }),
          )
      })
  }

  return (
    <div ref={scrollRef}>
      <form onSubmit={submit}>
        <div className="mb-4 flex items-center gap-6">
          <div className="flex-shrink-0">
            <div className="relative inline-flex h-40 w-40 items-center justify-center shadow-lg outline-none ring-2 ring-white">
              <span
                className={classNames('inline-flex w-40 items-center justify-center overflow-hidden outline-none', {
                  'bg-gray-400': !image,
                })}
              >
                {image ? (
                  <img className="max-h-full" src={image} alt={user.company} />
                ) : (
                  <i className="fas fa-ban text-9xl text-gray-200"></i>
                )}
              </span>

              <button
                type="button"
                className="group absolute inset-0 flex items-center justify-center"
                aria-haspopup="true"
                onClick={() => fileRef.current.click()}
              >
                <div className="flex h-14 w-14 items-center justify-center rounded-full bg-black bg-opacity-40 transition-colors duration-150 group-hover:bg-opacity-30">
                  <i className="far fa-camera text-2xl text-white opacity-90"></i>
                </div>
                <input
                  ref={fileRef}
                  accept="image/jpeg,image/png"
                  tabIndex="-1"
                  type="file"
                  className="hidden"
                  onChange={(event) => readFile(event.target.files)}
                />
              </button>
            </div>
          </div>

          <div className="mb-3 flex-1 space-y-3">
            <p>You may optionally upload a logo that will appear on your PRO-Website at LSNPros.com.</p>
            <p>Adding a logo is optional and your PRO-Website will not appear as if anything is missing if you leave this out.</p>
          </div>
        </div>

        <div className="flex justify-end">
          <Button type="submit" theme="solid">
            Save
          </Button>
        </div>
      </form>

      <PhotoEditor file={file} onApply={photoCropped} onClose={() => setFile(null)} />
    </div>
  )
}
