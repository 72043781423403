import { Fragment, useState } from 'react'

import Button from '@/Shared/Button'
import ContactEdit from './Edit'
import Filters from './Filters'
import SearchBar from '@/Shared/SearchBar'
import Tags from '@/Shared/Tags/Index'
import { Tooltip } from '@/Shared/Tooltip'

export default () => {
  const [openDrawer, setOpenDrawer] = useState(null)

  return (
    <Fragment>
      {
        {
          'contacts.merge.index': (
            <div className="flex w-full flex-col gap-3 sm:flex-row sm:flex-wrap sm:items-center">
              <div className="flex items-center gap-3 sm:justify-between">
                <h1 className="text-xl font-medium text-gray-800 sm:flex-none">Contacts</h1>
                <div className="mx-3 hidden h-9 border-r border-gray-300 sm:inline-flex"></div>
                <div className="font-medium text-gray-500">Merge Duplicates</div>
              </div>
            </div>
          ),
          'contacts.revisions.index': (
            <div className="flex w-full flex-col gap-3 sm:flex-row sm:flex-wrap sm:items-center">
              <div className="flex items-center gap-3 sm:justify-between">
                <h1 className="text-xl font-medium text-gray-800 sm:flex-none">Contacts</h1>
                <div className="mx-3 hidden h-9 border-r border-gray-300 sm:inline-flex"></div>
                <div className="font-medium text-gray-500">Pending Revisions</div>
              </div>
            </div>
          ),
        }[route().current()]
      }

      {['contacts.merge.index', 'contacts.revisions.index'].indexOf(route().current()) < 0 && (
        <SearchBar
          actions={
            <Fragment>
              <Tooltip label="Tags" placement="bottom">
                <Button theme="icon" onClick={() => setOpenDrawer('tags')}>
                  <i className="fas fa-user-tag text-lg"></i>
                </Button>
              </Tooltip>

              <Button theme="solid" onClick={() => setOpenDrawer('edit')}>
                <i className="fas fa-user-plus mr-2 text-lg"></i>
                <span className="pr-1">New</span>
              </Button>
            </Fragment>
          }
          sortOptions={[
            { value: 'created_at', label: 'Date Created', type: 'date' },
            { value: 'updated_at', label: 'Date Updated', type: 'date' },
            { value: 'first_name', label: 'First Name', type: 'text' },
            { value: 'last_name', label: 'Last Name', type: 'text' },
            { value: 'nickname', label: 'Nickname', type: 'text' },
            { value: 'company', label: 'Company', type: 'text' },
            { value: 'email', label: 'Email', type: 'text' },
            { value: 'location', label: 'Location', type: 'text' },
          ]}
          label="Contacts"
          placeholder="Search name or company"
          onShowFilters={() => setOpenDrawer('filters')}
        />
      )}

      <Filters open={openDrawer === 'filters'} onClosed={() => setOpenDrawer(null)} />
      <Tags open={openDrawer === 'tags'} onClosed={() => setOpenDrawer(null)} />
      <ContactEdit open={openDrawer === 'edit'} onClosed={() => setOpenDrawer(null)} />
    </Fragment>
  )
}
