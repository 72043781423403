import { Fragment, useRef, useState } from 'react'
import { useForm } from '@inertiajs/react'
import ClosingWorksheet from './ClosingReport/Index'
import SellerVisitQuestionnaire from './SellerVisitQuestionnaire'

export default ({ open, record, disabled, onClosed }) => {
  const scrollRef = useRef()

  const { data, setData, clearErrors, errors, setError, patch } = useForm({ ...record })

  const handleScroll = (ref) => {
    setTimeout(() => {
      let container = scrollRef.current
      let scrollable = ref.current
      let scrollableOffsetTop = scrollable.offsetTop - 105

      if (scrollableOffsetTop < container.scrollTop || scrollableOffsetTop > container.clientHeight)
        container.scrollTo({
          behavior: 'smooth',
          top: scrollableOffsetTop,
        })
    }, 300)
  }

  const setRadioValue = (field, value, scrollIntoView) => {
    scrollIntoView()
    setData({ ...data, [field]: value })
  }

  const submit = (event) => {
    event.preventDefault()

    patch(route('transactions.forms.update', data.id), {
      only: ['errors', 'flash', 'history', 'transaction'],
      onSuccess: () => {
        clearErrors()
        onClosed()
      },
    })
  }

  return (
    <Fragment>
      {record.form_id == 1 && (
        <SellerVisitQuestionnaire
          ref={scrollRef}
          data={data}
          errors={errors}
          open={open}
          disabled={disabled}
          handleScroll={handleScroll}
          setData={setData}
          setRadioValue={setRadioValue}
          onClosed={onClosed}
          onSubmit={submit}
        />
      )}

      {record.form_id == 2 && (
        <ClosingWorksheet
          ref={scrollRef}
          clearErrors={clearErrors}
          data={data}
          errors={errors}
          open={open}
          setData={setData}
          setError={setError}
          onClosed={onClosed}
          onSubmit={submit}
        />
      )}
    </Fragment>
  )
}
