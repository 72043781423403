import Helpers from '@/utils/helpers'
import { usePage } from '@inertiajs/react'

export default ({ transaction: data }) => {
  const { user } = usePage().props.auth
  const administrating = Helpers.isAdminOrHR(user)

  const totalHeldInEscrow = () => {
    return Helpers.parseCurrency(data.escrow_deposit1) + Helpers.parseCurrency(data.escrow_deposit2)
  }

  const purchasePrice = () => {
    return data.commission_basis === 'net'
      ? Helpers.parseCurrency(data.total_purchase_price) - Helpers.parseCurrency(data.closing_cost)
      : Helpers.parseCurrency(data.total_purchase_price)
  }

  const totalReimbursements = () => {
    return (
      Helpers.parseCurrency(data.smoke_co_detector_inspection_fee) +
      Helpers.parseCurrency(data.water_inspection_fee) +
      Helpers.parseCurrency(data.other_reimbursements)
    )
  }

  const totalTransactionCommission = () => {
    return Helpers.parseCurrency(data.commission_buyer) + Helpers.parseCurrency(data.commission_seller)
  }

  const totalTransactionCommissionPct = () => {
    if (!data.total_purchase_price && !(data.commission_buyer && data.commission_seller)) {
      return 0
    }

    let commissions =
      Helpers.parseCurrency(data.commission_buyer) +
      (data.type === 'Buyer' ? Helpers.parseCurrency(data.commission_buyer_additional || 0) : 0) +
      Helpers.parseCurrency(data.commission_seller)

    let price = purchasePrice()

    return Helpers.formatDecimal(Helpers.round((commissions / price) * 100), 2)
  }

  const buyerSplit = () => {
    if (!(data.total_purchase_price && data.commission_buyer)) {
      return 0
    }

    return Helpers.round((Helpers.parseCurrency(data.commission_buyer) / purchasePrice()) * 100)
  }

  const sellerSplit = () => {
    if (!(data.total_purchase_price && data.commission_seller)) {
      return 0
    }

    return Helpers.round((Helpers.parseCurrency(data.commission_seller) / purchasePrice()) * 100)
  }

  const referralsAndRebates = () => {
    return (
      (data.referral_type === 'external' ? Helpers.parseCurrency(data.referral_fee) : 0) +
      Helpers.parseCurrency(data.community_protector_rebate) +
      Helpers.parseCurrency(data.mygivebackprogram_rebate)
    )
  }

  const netRetained = () => {
    return (
      Helpers.parseCurrency(data[`commission_${data.type.toLowerCase()}`]) +
      (data.type === 'Buyer' ? Helpers.parseCurrency(data.commission_buyer_additional || 0) : 0) -
      (data.referral_type === 'external' && data.referral_fee ? data.referral_fee : 0) -
      Helpers.parseCurrency(data.community_protector_rebate) -
      Helpers.parseCurrency(data.mygivebackprogram_rebate)
    )
  }

  return (
    <div>
      <div className="flex items-end divide-x divide-gray-300 border-l border-r border-transparent">
        <span className="flex-1 py-1.5"></span>
        <span className="hidden w-20 py-1.5 text-center font-bold sm:inline">%</span>
        <span className="flex w-32 items-center justify-center py-1.5 font-bold">Amount</span>
      </div>

      <div className="space-y-4">
        <div className="divide-y divide-gray-300 overflow-hidden rounded-md border border-gray-300">
          <div className="flex items-end divide-x divide-gray-300">
            <span className="flex-1 bg-gray-100 px-4 py-2 font-bold">Total Purchase Price per P&S:</span>
            <span className="flex w-32 items-center justify-center px-4 py-2 font-bold">
              <span className="truncate">${Helpers.formatDecimal(Helpers.parseCurrency(data.total_purchase_price), 2)}</span>
            </span>
          </div>
          <div className="flex items-end divide-x divide-gray-300">
            <span className="flex-1 bg-gray-100 px-4 py-2 font-bold">
              {data.type === 'Buyer' ? 'Minus Seller Concessions' : 'Closing Cost Credit to Buyer'}:
            </span>
            <span className="flex w-32 items-center justify-center px-4 py-2 font-bold">
              <span className="truncate">${Helpers.formatDecimal(Helpers.parseCurrency(data.closing_cost), 2)}</span>
            </span>
          </div>

          {Boolean(data.escrow_held_by_blre) && (
            <div className="flex items-end divide-x divide-gray-300">
              <span className="flex-1 bg-gray-100 px-4 py-2 font-bold">Total Held in Escrow by BLRE:</span>
              <span className="flex w-32 items-center justify-center px-4 py-2 font-bold">
                <span className="truncate">${Helpers.formatDecimal(totalHeldInEscrow(), 2)}</span>
              </span>
            </div>
          )}
        </div>

        <div className="divide-y divide-gray-300 overflow-hidden rounded-md border border-gray-300">
          <div className="flex divide-x divide-gray-300">
            <span className="flex flex-1 items-center bg-gray-100 px-4 py-2 font-bold">Total Transaction Commission:</span>
            <span className="hidden w-20 truncate px-2 py-2 text-center font-medium sm:inline">{totalTransactionCommissionPct()}%</span>
            <span className="flex w-32 flex-col items-center justify-center px-4 py-2 font-bold leading-tight sm:flex-row">
              <span className="truncate">${Helpers.formatDecimal(totalTransactionCommission(), 2)}</span>
              <div className="text-sm text-gray-500 sm:hidden">({totalTransactionCommissionPct()}%)</div>
            </span>
          </div>

          <div className="flex items-center divide-x divide-gray-300 bg-gray-100">
            <span className="flex-1 px-4 font-bold">
              {['Buyer', 'Tenant'].some((type) => type === data.type) ? (
                <div className="flex items-end gap-0.5">
                  <div className="w-5">
                    <img className="object-fill" src="/images/layout/BLRE_Leaf.png" />
                  </div>

                  <span className="font-bold leading-none text-green-900">Buyer Agent Commission:</span>
                </div>
              ) : (
                <span>Buyer Agent Commission:</span>
              )}
            </span>
            <span className="hidden w-20 truncate bg-white px-2 py-2 text-center font-medium sm:inline">{buyerSplit()}%</span>
            <span className="flex w-32 flex-col items-center justify-center bg-white px-4 py-2 font-bold leading-tight sm:flex-row">
              <span className="truncate">${Helpers.formatDecimal(Helpers.parseCurrency(data.commission_buyer), 2)}</span>
              <div className="text-sm text-gray-500 sm:hidden">({buyerSplit()}%)</div>
            </span>
          </div>

          {['Buyer', 'Tenant'].some((type) => type === data.type) && parseFloat(data.commission_buyer_additional) > 0 && (
            <div className="flex divide-x divide-gray-300">
              <span className="flex flex-1 items-center bg-gray-100 px-4 py-2 font-bold">
                <div className="font-bold leading-none text-black">
                  <div>
                    <span className="uppercase text-blue-500">Extra</span> Buyer Agent Commission:
                  </div>
                  <span className="text-xs font-semibold uppercase text-gray-500/80">Paid by the Buyer</span>
                </div>
              </span>
              <span className="hidden w-20 items-center justify-center truncate px-2 py-2 font-medium sm:inline-flex">
                {Helpers.round(data.commission_buyer_additional_pct)}%
              </span>
              <span className="flex w-32 flex-col items-center justify-center px-4 py-2 font-bold leading-tight sm:flex-row">
                <span className="truncate">${Helpers.formatDecimal(Helpers.parseCurrency(data.commission_buyer_additional || 0), 2)}</span>
                <div className="text-sm text-gray-500 sm:hidden">({Helpers.round(data.commission_buyer_additional_pct)}%)</div>
              </span>
            </div>
          )}

          {(['Seller', 'Landlord'].some((type) => type === data.type) || administrating) && (
            <div className="flex divide-x divide-gray-300">
              <span className="flex flex-1 items-center bg-gray-100 px-4 py-2 font-bold">
                {['Seller', 'Landlord'].some((type) => type === data.type) ? (
                  <div className="flex items-end gap-0.5">
                    <div className="w-5">
                      <img className="object-fill" src="/images/layout/BLRE_Leaf.png" />
                    </div>

                    <span className="font-bold text-green-900">BLRE Seller Agent</span>
                  </div>
                ) : (
                  <span>Seller Agent Commission:</span>
                )}
              </span>
              <span className="hidden w-20 truncate px-2 py-2 text-center font-medium sm:inline">{sellerSplit()}%</span>
              <span className="flex w-32 flex-col items-center justify-center px-4 py-2 font-bold leading-tight sm:flex-row">
                <span className="truncate">${Helpers.formatDecimal(Helpers.parseCurrency(data.commission_seller), 2)}</span>
                <div className="text-sm text-gray-500 sm:hidden">({sellerSplit()}%)</div>
              </span>
            </div>
          )}

          {referralsAndRebates() > 0 && (
            <div className="flex items-end divide-x divide-gray-300">
              <span className="flex flex-1 items-center justify-between bg-gray-100 px-4 py-2">
                <span className="font-bold">Referrals & Rebates:</span>
                <i className="fas fa-minus text-red-600"></i>
              </span>
              <span className="flex w-32 items-center justify-center px-4 py-2 font-bold text-red-600">
                <span className="truncate">${Helpers.formatDecimal(referralsAndRebates(), 2)}</span>
              </span>
            </div>
          )}

          <div className="flex items-end divide-x divide-gray-300">
            <span className="flex-1 bg-gray-100 px-4 py-2 font-bold">Total Net Office Retained:</span>
            <span className="flex w-32 items-center justify-center px-4 py-2 font-bold">
              <span className="truncate">${Helpers.formatDecimal(netRetained(), 2)}</span>
            </span>
          </div>

          {totalReimbursements() > 0 && (
            <div className="flex items-end divide-x divide-gray-300">
              <span className="flex-1 bg-gray-100 px-4 py-2 font-bold">Total Reimbursements:</span>
              <span className="flex w-32 items-center justify-center px-4 py-2 font-bold">
                <span className="truncate">${Helpers.formatDecimal(totalReimbursements(), 2)}</span>
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
