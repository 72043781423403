import classNames from 'classnames'

export default ({ active, children, disabled, onClick }) => {
  return (
    <li className="-mb-0.5">
      <a
        role="tab"
        className={classNames(
          'relative block cursor-pointer px-6 pb-4',
          disabled
            ? {
                'cursor-not-allowed text-gray-400 text-opacity-80': !active,
              }
            : {
                'font-medium text-primary-500': active,
                'text-gray-600': !active,
              },
        )}
        onClick={disabled ? null : onClick}
      >
        {children}
        {active && <div className="absolute inset-x-0 bottom-0 h-1 rounded bg-primary-500"></div>}
      </a>
    </li>
  )
}
