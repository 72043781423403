import React, { useContext } from 'react'
import Button from '@/Shared/Button'
import Dropdown from '@/Shared/Dropdown'
import DropdownItem from '@/Shared/DropdownItem'
import { router } from '@inertiajs/react'
import { NotificationContext } from '@/Shared/Notifications/NotificationContext'

const Actions = ({ filters, onClose, onFilterChanged }: Props) => {
  const filtersRef = React.useRef<HTMLDivElement>(null)
  const { archiveAll, markAllAsRead } = useContext(NotificationContext)
  const isFiltering = filters.filter((item: any) => !item.filtering).length > 0

  const handleChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    let item = event.target.name
    let isChecked = event.target.checked
    onFilterChanged(item, isChecked)
  }

  return (
    <div ref={filtersRef} className="-ml-2 flex flex-1 items-center justify-between py-2">
      <div className="flex items-center gap-3">
        <Dropdown
          id="notification-filters"
          label={
            <span className="font-medium text-primary-500">
              Filter by{' '}
              <b>
                ({isFiltering ? filters.filter((item: any) => item.filtering).length : filters.length}/{filters.length})
              </b>
            </span>
          }
          orientation="left"
          size="w-64"
        >
          {filters.map((filter: any, index: number) => (
            <DropdownItem key={index}>
              <label className="flex flex-1 cursor-pointer items-center pb-0">
                <input
                  type="checkbox"
                  name={filter.name}
                  className="form-checkbox mr-3 h-5 w-5 text-primary-500"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChanged(event)}
                  checked={filter.filtering}
                ></input>{' '}
                {filter.label}
              </label>
            </DropdownItem>
          ))}
        </Dropdown>

        <Dropdown id="notification-actions" label={<span className="font-medium text-primary-500">Actions</span>} orientation="left">
          <DropdownItem onClick={archiveAll}>Archive all</DropdownItem>
          <DropdownItem onClick={markAllAsRead}>Mark all as read</DropdownItem>
        </Dropdown>
      </div>

      <div className="flex justify-end">
        <Button
          theme="icon"
          onClick={() =>
            router.visit('/account/settings/notifications', {
              onFinish: () => onClose(),
            })
          }
        >
          <span className="sr-only">Notification settings</span>
          <i className="fas fa-cog text-xl text-primary-500"></i>
        </Button>
      </div>
    </div>
  )
}

interface Props {
  filters: any
  onClose: () => void
  onFilterChanged: (item: string, isChecked: boolean) => void
}

export default Actions
