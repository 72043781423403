import React from 'react'

import AccordionCard from '@/Shared/AccordionCard'
import Avatar from '@/Shared/Avatar'
import Button from '@/Shared/Button'
import { Tooltip } from '@/Shared/Tooltip'
import { usePage } from '@inertiajs/react'
import { router } from '@inertiajs/react'

const Contact = ({ contact, dismissable, onDismiss }) => {
  return (
    <div className="relative m-1">
      {dismissable && (
        <div className="absolute right-2 top-2">
          <Tooltip label="Dismiss" placement="bottom">
            <Button theme="clean-icon" onClick={() => onDismiss()}>
              <span className="sr-only">Close</span>
              <i className="fal fa-times text-2xl leading-none text-opacity-80"></i>
            </Button>
          </Tooltip>
        </div>
      )}

      <button
        type="button"
        className="flex w-full items-start justify-center gap-3 rounded-md px-4 py-3 text-left hover:bg-gray-100 focus:ring-2 focus:ring-blue-500"
        onClick={() => router.visit(route('contacts.show', contact.id))}
      >
        <div className="w-18 flex flex-col items-center justify-center gap-1">
          <Avatar contact={contact} height="h-14" width="w-14" />

          {contact.following && (
            <div className="mt-0.5 inline-flex items-center justify-center gap-1 whitespace-nowrap rounded-full bg-purple-100 px-1.5 py-1.5 text-xs font-medium uppercase leading-none text-purple-800">
              <i className="far fa-link-horizontal"></i>
              <span>PRO</span>
            </div>
          )}
        </div>

        <div className="mr-8 flex-1 leading-snug text-gray-600 sm:mb-0">
          <div className="line-clamp-1 font-semibold">{contact.full_name}</div>
          {contact.industry && <div className="line-clamp-1">{contact.industry}</div>}
          {contact.company && <div className="line-clamp-1">{contact.company}</div>}
          {contact.phone && <div className="line-clamp-1">{contact.phone}</div>}
          {contact.email && <div className="line-clamp-1">{contact.email}</div>}
        </div>
      </button>
    </div>
  )
}

export default () => {
  const { contact } = usePage().props

  const dismissContact = (contact, similarContact) => {
    router.post(route('contacts.dismiss'), {
      id: contact.id,
      followable_id: similarContact.id,
    })
  }

  const dismissAll = (contact_id) => {
    router.post(route('contacts.dismissAllDuplicates'), {
      contact_id: contact_id,
    })
  }

  const merge = (contact_id) => {
    router.post(route('contacts.mergeDuplicates'), {
      contact_id: contact_id,
      redirect: true,
    })
  }

  if (!contact.similar?.contacts || contact.similar.contacts.length == 0) return <></>

  return (
    <AccordionCard title={`Possible Duplicates (${contact.similar.contacts.length})`} collapsed={false}>
      <div className="divide-y divide-dashed divide-gray-200 overflow-hidden rounded-md border border-gray-200">
        {contact.similar.contacts.map((similarContact) => (
          <Contact contact={similarContact} dismissable onDismiss={() => dismissContact(contact, similarContact)} key={similarContact.id} />
        ))}
      </div>

      <div className="flex items-center justify-center gap-4 px-6 pt-4 sm:px-6">
        <Button type="submit" theme="link" onClick={() => dismissAll(contact.id)}>
          Dismiss
        </Button>

        <Button type="submit" theme="solid" onClick={() => merge(contact.id)}>
          Merge
        </Button>
      </div>
    </AccordionCard>
  )
}
