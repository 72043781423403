import React, { Fragment, useContext, useEffect, useRef, useState } from 'react'
import Accordion from '@/Shared/Accordion'
import axios from '@/apis/axios'
import { useForm, usePage } from '@inertiajs/react'
import Checkbox from '@/Shared/Forms/Checkbox'
import RichTextEditor from '@/Shared/RichTextEditor/Index'
import Rules from './Rules/Index'
import Select from '@/Shared/Forms/Select'
import Subtasks from './Subtasks'
import { SubtasksContext } from './SubtasksContext'
import { nanoid } from 'nanoid'
import Notice from '@/Shared/Notice'

export default ({ record, onClosed, onSubmitting }) => {
  const { checklist, errors: otherErrors, fileTypes: originalFileTypes, forms, propertyTypes, roles, sections, states } = usePage().props
  const emailTemplateRef = useRef()

  const step = record || {}
  const type = step.parent_id == undefined ? 'step' : 'subtask'

  const required_roles = step.required_roles || step.settings?.required_roles || []

  const form = useForm({
    id: step.id || null,
    temp_id: step.temp_id,
    name: step.name || '',
    content: step.content || '',
    rules: step.rules || [],
    subtasks: step.subtasks || [],
    actionable_type: step.actionable_type || null,
    actionable_id: Number(step.actionable_id) || null,
    actionable_new: null,
    fields: step.fields || step.settings?.fields || null,
    section_id: step.section_id ? step.section_id.toString() : '',
    sort_order: step.sort_order || 1,
    property_types:
      step.property_types?.length > 0
        ? step.property_types.map((s) =>
            Object.assign(
              {},
              {
                label: s.name,
                name: s.name,
                value: s.id.toString(),
              },
            ),
          )
        : null,
    states: step.states?.length > 0 ? states.filter((state) => step.states.find((s) => state.value == s.name)) : null,
    required_steps:
      step.required_steps?.length > 0
        ? step.required_steps.map((s) =>
            Object.assign(
              {},
              {
                label: `${s.nameWithoutTags}`,
                name: s.name,
                value: s.id.toString(),
              },
            ),
          )
        : null,
    required_roles:
      required_roles.length > 0
        ? roles.filter((role) =>
            required_roles.find((r) => role.value == (r.value || (r.party_representing ? `${r.party_representing}-${r.role}` : r.role))),
          )
        : null,
    optional: step.id ? step.optional : false,
    controlled: step.id ? step.controlled : false,
    reset_on_closing_canceled: step.id ? step.reset_on_closing_canceled : false,
    active: step.id ? step.active : true,
  })

  const fields = [
    { value: 'commission_buyer', label: "Buyer Agent's Compensation", type: 'Currency' },
    { value: 'commission_buyer_additional', label: "EXTRA Buyer Agent's Compensation", type: 'Currency' },
    { value: 'closing_at', label: 'Closing Date', type: 'Date' },
    { value: 'closing_report_status', label: 'Closing Worksheet Status' },
    { value: 'closing_cost', label: 'Closing Cost Credit to Buyer (Seller) / Seller Concessions (Buyer)', type: 'Currency' },
    { value: 'commission_basis', label: 'Commission Based On' },
    { value: 'escrow_held_by_blre', label: 'Escrow Held By BLRE', type: 'Boolean' },
    { value: 'escrow_deposit1', label: 'Initial Escrow Deposit', type: 'Currency' },
    { value: 'escrow_deposit2', label: '2nd Escrow Deposit', type: 'Currency' },
    { value: 'water_inspection_fee', label: 'Fee for Final Water Inspection and/or Bill', type: 'Currency' },
    { value: 'smoke_co_detector_inspection_fee', label: 'Fee for Smoke Detector and CO Detector Inspection', type: 'Currency' },
    { value: 'hiring_date', label: 'Hiring / Engagement Date', type: 'Date' },
    { value: 'home_inspected_at', label: 'Home Inspection Date & Time', type: 'Date' },
    { value: 'home_inspection_contingency_date', label: 'Home Inspection Contingency Date', type: 'Date' },
    {
      value: 'deadline_for_sale_of_buyers_property_contingency_date',
      label: 'Deadline for Sale of Buyer’s Property Contingency',
      type: 'Date',
    },
    {
      value: 'deadline_for_sellers_suitable_housing_contingency_date',
      label: 'Deadline for Seller’s Suitable Housing Contingency',
      type: 'Date',
    },
    {
      value: 'deadline_for_receipt_review_of_condo_documents_date',
      label: 'Deadline for Receipt and Review of Condo Documents',
      type: 'Date',
    },
    { value: 'appraisal_date', label: 'Appraisal Date', type: 'Date' },
    { value: 'smoke_detector_certification_date', label: 'Smoke Detector Certification Date', type: 'Date' },
    { value: 'final_water_reading_date', label: 'Final Water Reading Date', type: 'Date' },
    { value: 'mortgage_commitment_date', label: 'Mortgage Commitment Date', type: 'Date' },
    { value: 'offer_to_purchase_date', label: 'Offer to Purchase Date', type: 'Date' },
    { value: 'other_reimbursements', label: 'Other Reimbursements', type: 'Currency' },
    { value: 'property_address', label: 'Property Address' },
    { value: 'purchase_and_sale_date', label: 'Purchase and Sale Date', type: 'Date' },
    { value: 'radon_inspection_results', label: 'Radon Inspection Results' },
    { value: 'reinspection_date', label: 'Re-inspection Date & Time', type: 'Date' },
    { value: 'commission_seller', label: "Seller Agent's Compensation", type: 'Currency' },
    { value: 'total_purchase_price', label: 'Total Purchase Price', type: 'Currency' },
    { value: 'walk_through_date', label: 'Walk Through Date & Time', type: 'Date' },
    { value: 'property_built_year', label: 'Year Property Built', type: 'Integer' },
  ].sort((a, b) => {
    if (a.label < b.label) return -1
    if (a.label > b.label) return 1
    return 0
  })
  const operators = [
    { value: 'eq', label: 'is equal to', allowed_types: ['Currency', 'Date', 'Integer'] },
    { value: 'neq', label: 'is not equal to', allowed_types: ['Currency', 'Date', 'Integer'] },
    { value: 'is_true', label: 'is set to Yes', allowed_types: ['Boolean'] },
    { value: 'is_false', label: 'is set to No', allowed_types: ['Boolean'] },
    { value: 'greater_than', label: 'is greater than', allowed_types: ['Currency', 'Date', 'Integer'] },
    { value: 'greater_than_eq', label: 'is greater than or equal to', allowed_types: ['Currency', 'Date', 'Integer'] },
    { value: 'less_than', label: 'is less than', allowed_types: ['Currency', 'Date', 'Integer'] },
    { value: 'less_than_eq', label: 'is less than or equal to', allowed_types: ['Currency', 'Date', 'Integer'] },
    { value: 'between', label: 'is between', allowed_types: ['Currency', 'Date', 'Integer'] },
    { value: 'known', label: 'is known', allowed_types: ['Currency', 'Date', 'Integer'] },
    { value: 'unknown', label: 'is unknown', allowed_types: ['Currency', 'Date', 'Integer'] },
  ]

  const { errors, clearErrors, data, setData, patch, post, processing } = form
  const [fileTypes, setFileTypes] = useState(originalFileTypes)
  const [otherSteps, setOtherSteps] = useState([])
  const [emailCategories, setEmailCategories] = useState([])
  const [emailTemplateCategory, setEmailTemplateCategory] = useState('')
  const [emailTemplates, setEmailTemplates] = useState([])
  const { onSubtaskAdding, onSubtaskUpdating } = useContext(SubtasksContext)

  useEffect(() => {
    if (record) {
      getAvailableOtherSteps()
    }
    fetchEmailCategories()
  }, [data])

  useEffect(() => {
    if (emailTemplateCategory) {
      fetchEmailTemplates(data.states)
    }
  }, [emailTemplateCategory])
  useEffect(() => {
    if (emailCategories.length > 0 && step.actionable?.category_id) {
      setEmailTemplateCategory(Number(step.actionable.category_id))
    }
  }, [emailCategories])

  const fetchEmailCategories = () => {
    if (data.actionable_type === 'Email') {
      if (emailCategories.length == 0) {
        axios.get(route('email-templates.categories.index')).then((response) => {
          setEmailCategories(response.data)
        })
      }
    } else {
      setEmailTemplateCategory('')
    }
  }

  const fetchEmailTemplates = async (selectedStates) => {
    const states = selectedStates ? selectedStates.reduce((carry, s) => carry + (carry ? `,${s.value}` : s.value), '') : ''
    axios.get(route('email-templates.index'), { params: { category_id: emailTemplateCategory, states: states } }).then((response) => {
      setEmailTemplates(response.data)
    })
  }

  const changeTemplateCategory = (selected) => {
    setEmailTemplateCategory(selected?.value)

    if (selected?.value) {
      setTimeout(() => {
        emailTemplateRef.current.focus()
      }, 100)
    } else {
      setData({ ...data, actionable_id: null })
      setEmailTemplates([])
    }
  }

  const handleActionChanging = (selected) => {
    let optional = ['Role', 'File'].indexOf(selected?.value) >= 0 ? false : data.optional

    setData({
      ...data,
      actionable_type: selected?.value || null,
      actionable_id: null,
      actionable_new: null,
      fields: null,
      optional: optional,
    })
  }

  const handleSettingsChanging = (selected, field) => {
    let updated = {
      ...data,
      [field]: selected,
      required_steps: null,
    }
    setData(updated)

    if (field === 'states') {
      fetchEmailTemplates(selected).then((_) => {
        if (data.actionable_id && !emailTemplates.find((t) => t.value === data.actionable_id)) {
          setData({ ...updated, actionable_id: null })
        }
      })
    }
  }

  const matching = (step, attribute) => {
    let emptyAttribute = !data[attribute] || data[attribute].length == 0 || step[attribute].length == 0

    if (emptyAttribute) return true

    let containsAttribute =
      step[attribute].filter((attr) =>
        data[attribute]?.find((a) => {
          return a.label == attr.name || a.value == attr.name
        }),
      ).length > 0

    return emptyAttribute || containsAttribute
  }

  const getAvailableOtherSteps = () => {
    let parentIndex,
      steps = []

    if (record.parent_id) {
      checklist.sections.find((section) => {
        return (parentIndex = section.steps.find((step) => step.id == record.parent_id)?.index)
      })
    }

    checklist.sections.forEach((section) => {
      section.steps
        .filter((s) =>
          s.id != record.id && // Exclude current step
          record.parent_id
            ? s.id == record.parent_id || s.index < parentIndex
            : s.index < record.index && // Exclude steps after current step
              matching(s, 'property_types') &&
              matching(s, 'states'),
        )
        .forEach((s) => {
          if (s.id != record.parent_id) {
            steps.push(
              Object.assign(
                {},
                {
                  label: `${s.index}. ${s.nameWithoutTags}`,
                  value: s.id.toString(),
                },
              ),
            )
          }

          if (s.subtasks?.length > 0) {
            s.subtasks.forEach((subtask, index) => {
              if (subtask.id != record.id) {
                steps.push(
                  Object.assign(
                    {},
                    {
                      label: `${s.index}${String.fromCharCode(index + 'A'.charCodeAt(0))}. ${subtask.nameWithoutTags}`,
                      value: subtask.id.toString(),
                    },
                  ),
                )
              }
            })
          }
        })
    })

    setOtherSteps(steps)
  }

  const getFileType = () => {
    let selected
    fileTypes.map((group) => {
      group.options.map((option) => {
        if (option.value == data.actionable_id || (data.actionable_new && option.value == data.actionable_new.value)) {
          selected = option
        }
      })
    })

    return selected?.label || ''
  }

  const handleFileTypeChanged = (selected) => {
    if (selected) {
      setData({
        ...data,
        actionable_type: data.actionable_type,
        actionable_id: !selected.__isNew__ ? selected.value : null,
        actionable_new: selected.__isNew__ ? selected : null,
      })

      setFileTypes(
        selected.__isNew__
          ? originalFileTypes.map((type) => (type.label === 'Closing' ? { ...type, options: [selected].concat(type.options) } : type))
          : originalFileTypes,
      )
    } else {
      setData({ ...data, actionable_id: null, actionable_new: null })
    }
  }

  const submit = (event) => {
    event.preventDefault()
    event.stopPropagation()

    onSubmitting(true)

    if (step.parent_id) {
      if (step.id == undefined && step.temp_id == undefined) {
        onSubtaskAdding({ ...step, ...data })
      } else {
        onSubtaskUpdating({ ...step, ...data })
      }

      clearErrors()
      onClosed()
      onSubmitting(false)
    } else {
      if (data.id) {
        patch(route('admin.steps.update', data.id), {
          onSuccess: () => {
            clearErrors()
            onClosed()
          },
          onFinish: () => onSubmitting(false),
        })
      } else {
        post(route('admin.steps.store'), {
          onSuccess: () => {
            clearErrors()
            onClosed()
          },
          onFinish: () => onSubmitting(false),
        })
      }
    }
  }

  return (
    <form id={step.parent_id == undefined ? 'stepForm' : 'subtaskForm'} className="flex h-full flex-col" onSubmit={submit}>
      <div className="space-y-4 md:space-y-0 lg:flex lg:gap-6">
        <div className="space-y-4 lg:w-2/3">
          <div>
            {errors.move_restricted && (
              <div
                className="mx-auto mb-4 inline-block rounded-lg bg-red-100 bg-opacity-70 px-6 py-2 text-center text-lg leading-tight text-red-600"
                dangerouslySetInnerHTML={{ __html: errors.move_restricted }}
              ></div>
            )}

            {otherErrors?.restricted && (
              <div className="mx-auto mb-4 flex items-center gap-3 rounded-lg bg-red-100 bg-opacity-70 px-6 py-2 text-lg leading-tight text-red-600">
                <i className="fas fa-exclamation-circle text-xl"></i>
                <p dangerouslySetInnerHTML={{ __html: otherErrors?.restricted }}></p>
              </div>
            )}

            <Accordion id="checklist-features">
              <Accordion.Items>
                <Accordion.Item active={true}>
                  <Accordion.Button>
                    <div className="text-lg font-medium">Content</div>
                  </Accordion.Button>

                  <Accordion.Panel>
                    <div>
                      <div className="pb-3">
                        <label className="text-sm font-medium uppercase text-gray-700">
                          Title <span className="text-red-500">*</span>
                        </label>
                        <RichTextEditor
                          value={data.name}
                          height="overflow-y-auto min-h-[75px] max-h-[400px]"
                          theme="checklist"
                          onChange={(value) => setData({ ...data, name: value })}
                          autoFocus
                        />

                        {errors.name && <div className="mt-1 text-red-600">{errors.name}</div>}
                      </div>

                      <div className="pb-3">
                        <label className="text-sm font-medium uppercase text-gray-700">Description</label>
                        <RichTextEditor
                          value={data.content}
                          height="overflow-y-auto min-h-[75px] max-h-[400px]"
                          theme="checklist"
                          onChange={(value) => setData({ ...data, content: value })}
                        />

                        {errors.content && <div className="mt-1 text-red-600">{errors.content}</div>}
                      </div>
                    </div>
                  </Accordion.Panel>
                </Accordion.Item>

                {record?.parent_id == undefined && (
                  <Accordion.Item>
                    <Accordion.Button>
                      <div className="text-lg font-medium">Subtasks ({data.subtasks.length || 0})</div>
                    </Accordion.Button>

                    <Accordion.Panel>
                      <Subtasks
                        parent_id={data.id || nanoid()}
                        items={data.subtasks}
                        onUpdated={(subtasks) => {
                          setData({
                            ...{
                              ...data,
                              subtasks: subtasks,
                            },
                            ...(data.subtasks.length == 0 &&
                              subtasks.length > 0 && {
                                actionable_id: null,
                                actionable_type: null,
                              }),
                          })
                        }}
                      >
                        {data.actionable_type && data.subtasks.length == 0 && (
                          <div className="mx-auto max-w-xl">
                            <Notice
                              heading="Interactive Action Removal Warning"
                              subHeading="Adding a subtask to this step will remove the current Interactive Action.  Consider adding your Interactive Action as a new subtask."
                            />
                          </div>
                        )}
                      </Subtasks>
                    </Accordion.Panel>
                  </Accordion.Item>
                )}

                <Accordion.Item>
                  <Accordion.Button>
                    <div className="text-lg font-medium">Rules ({data.rules?.length || 0})</div>
                  </Accordion.Button>

                  <Accordion.Panel>
                    <Rules
                      fields={fields}
                      operators={operators}
                      rules={data.rules}
                      step={{ id: data.id, parent_id: record?.parent_id }}
                      onUpdated={(rules) => setData({ ...data, rules: rules })}
                    />
                  </Accordion.Panel>
                </Accordion.Item>
              </Accordion.Items>
            </Accordion>
          </div>
        </div>

        <div className="space-y-6 lg:w-1/3">
          <div className="space-y-4 rounded-md border border-gray-300">
            <div className="border-b border-gray-200 px-4 py-3 font-medium uppercase text-blue-500">Settings</div>

            <div className="px-4">
              {record?.parent_id == undefined && (
                <Fragment>
                  <Select
                    error={errors.section_id}
                    label="Section"
                    name="section_id"
                    options={sections}
                    getOptionValue={(option) => option.value}
                    placeholder="Any"
                    placement="bottom"
                    value={data.section_id}
                    onChange={(selected) => setData({ ...data, section_id: selected?.value })}
                    required
                  />

                  <Select
                    label="Property Types"
                    name="property_type"
                    options={propertyTypes}
                    placeholder="Any"
                    placement="bottom"
                    value={data.property_types}
                    onChange={(selected) => handleSettingsChanging(selected, 'property_types')}
                    multiple
                  />

                  <Select
                    label="States"
                    name="states"
                    options={states}
                    placeholder="Any"
                    placement="bottom"
                    value={data.states}
                    onChange={(selected) => handleSettingsChanging(selected, 'states')}
                    multiple
                  />
                </Fragment>
              )}

              <div className="mb-4">
                <Checkbox
                  name={`optional-${data.id || 'new'}`}
                  label="Optional"
                  description={`Allows this ${type} to be skipped when it is not applicable.`}
                  value={data.optional}
                  onChange={(selected) => setData({ ...data, optional: selected })}
                  disabled={['Role', 'File'].indexOf(data.actionable_type) >= 0}
                />
              </div>

              <div className="mb-4">
                <Checkbox
                  name={`controlled-${data.id || 'new'}`}
                  label={`System Controlled`}
                  description={
                    <div>
                      <div>
                        If selected, users will <b>NOT</b> be able to mark this {type} as completed. It can only be completed by a rule
                        found in this {type}.
                      </div>
                      {data.controlled && (
                        <div className="mb-2 mt-1 flex gap-2 rounded-md bg-red-50 px-3.5 py-2 font-medium normal-case text-red-700">
                          <i className="fas fa-exclamation-circle mt-1 text-red-600"></i>
                          <span>IMPORTANT NOTE: Rules MUST cover every scenario or users will be unable to complete their checklists.</span>
                        </div>
                      )}
                    </div>
                  }
                  value={data.controlled}
                  onChange={(selected) => setData({ ...data, controlled: selected })}
                />
              </div>

              {record?.parent_id == undefined && (
                <div className="mb-4">
                  <Checkbox
                    name={`reset_on_closing_canceled-${data.id || 'new'}`}
                    label="Reset When Closing Canceled"
                    description={`This ${type} will be marked incomplete when an Associate cancels a closing.`}
                    value={data.reset_on_closing_canceled}
                    onChange={(selected) => setData({ ...data, reset_on_closing_canceled: selected })}
                  />
                </div>
              )}

              <div className="mb-4">
                <Checkbox
                  name={`active-${data.id || 'new'}`}
                  label="Active"
                  value={data.active}
                  onChange={(selected) => setData({ ...data, active: selected })}
                />
              </div>
            </div>
          </div>

          <div className="space-y-4 rounded-md border border-gray-300">
            <div className="border-b border-gray-200 px-4 py-3 font-medium uppercase text-blue-500">Completion Requirements</div>

            <div className="px-4">
              <Select
                label="Steps required"
                name="required_steps"
                options={otherSteps}
                placeholder="None"
                placement="top"
                disabled={!Boolean(data.id)}
                disabledMessage={`Not available when creating a new ${type}.`}
                value={data.required_steps}
                onChange={(selected) => setData({ ...data, required_steps: selected })}
                multiple
              />

              <Select
                label="Roles required"
                name="required_roles"
                options={roles.filter(
                  (role) =>
                    data.actionable_type !== 'Role' ||
                    !roles.find((r) => r.value === role.value && data.actionable_id?.toString() === r.id),
                )}
                placeholder="None"
                placement="top"
                disabled={!Boolean(data.id)}
                disabledMessage={`Not available when creating a new ${type}.`}
                value={data.required_roles}
                onChange={(selected) => setData({ ...data, required_roles: selected })}
                multiple
              />
            </div>
          </div>

          <div className="space-y-4 rounded-md border border-gray-300">
            <div className="border-b border-gray-200 px-4 py-3 font-medium uppercase text-blue-500">Interactive Action</div>

            <div className="px-4">
              <Select
                error={errors.actionable_type}
                label="Action"
                placement="top"
                name="actionable_type"
                options={[
                  { value: 'Role', label: 'Add a Contact / Role' },
                  { value: 'Data', label: 'Collect Data' },
                  { value: 'Form', label: 'Fill out a Form' },
                  { value: 'Email', label: 'Send a Templated Email' },
                  { value: 'File', label: 'Upload a File' },
                ]}
                placeholder="None"
                value={data.actionable_type}
                onChange={(selected) => handleActionChanging(selected)}
              />

              {data.actionable_type === 'Role' && (
                <div>
                  <Select
                    error={errors.actionable_id}
                    label="Role"
                    placement="top"
                    name="actionable_id"
                    options={roles}
                    placeholder="Select a Role"
                    value={roles.filter((r) => data.actionable_id?.toString() === r.id)}
                    onChange={(selected) =>
                      setData({
                        ...data,
                        actionable_id: selected?.id || null,
                      })
                    }
                    required
                  />
                </div>
              )}

              {data.actionable_type === 'Data' && (
                <div>
                  <Select
                    error={errors.fields}
                    label="Data to Collect"
                    placement="top"
                    name="fields"
                    options={fields}
                    placeholder="Select field(s) you wish to collect"
                    value={fields.filter((field) => data.fields?.find((f) => f == field.value))}
                    onChange={(selected) =>
                      setData({
                        ...data,
                        fields: selected?.map((field) => field.value),
                      })
                    }
                    multiple
                    required
                  />
                </div>
              )}

              {data.actionable_type === 'Email' && (
                <div>
                  <Select
                    label="Category"
                    placement="top"
                    name="email_template_category"
                    options={emailCategories}
                    placeholder="None"
                    value={emailTemplateCategory}
                    onChange={(selected) => changeTemplateCategory(selected)}
                  />

                  <Select
                    ref={emailTemplateRef}
                    error={errors.actionable_id}
                    label="Email Template"
                    placement="top"
                    name="actionable_id"
                    options={emailTemplates}
                    placeholder="Select One"
                    value={emailTemplates.filter((p) => data.actionable_id === p.value)}
                    onChange={(selected) =>
                      setData({
                        ...data,
                        actionable_id: selected?.value || null,
                      })
                    }
                    required
                    disabled={!Boolean(emailTemplateCategory)}
                  />
                </div>
              )}

              {data.actionable_type === 'File' && (
                <Select
                  label="Type"
                  error={errors['file.type']}
                  placement="top"
                  name="file_type"
                  options={fileTypes}
                  placeholder="Select One or Create"
                  value={getFileType()}
                  required
                  onChange={(selected) => handleFileTypeChanged(selected)}
                  creatable
                />
              )}

              {data.actionable_type === 'Form' && (
                <Select
                  error={errors.actionable_id}
                  label="Form"
                  placement="top"
                  name="actionable_id"
                  options={forms}
                  placeholder="Select One"
                  value={forms.filter((f) => data.actionable_id?.toString() === f.value)}
                  onChange={(selected) =>
                    setData({
                      ...data,
                      actionable_id: selected?.value || null,
                    })
                  }
                  required
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </form>
  )
}
