import { useEffect, useState } from 'react'
import Button from '@/Shared/Button'
import classNames from 'classnames'
import { DatePicker } from '@/Shared/DatePicker/DatePicker'
import Dialog from '@/Shared/Dialog/Index'
import RichTextEditor from '@/Shared/RichTextEditor/Index'
import TimePicker from '@/Shared/TimePicker'
import { useForm } from '@inertiajs/react'

export default function NoteDialog({ id, record, noteOnly, open, title, type, onClosed }) {
  const note = record || {}
  const form = useForm({
    id: note.id || null,
    noteable_id: id,
    noteable_type: type,
    note: '',
    type: note.type || '',
    date: note.date ? new Date(note.date) : new Date(),
  })
  const { errors, clearErrors, data, setData, patch, post, processing } = form
  const [content, setContent] = useState(note.content || '')

  useEffect(() => {
    if (content != data.note) {
      setData({ ...data, note: content })
    }
  }, [content])

  const updateDate = (newDate) => {
    if (newDate) {
      newDate.setHours(data.date.getHours())
      newDate.setMinutes(data.date.getMinutes())
    }

    setData({ ...data, date: newDate || new Date() })
  }

  const submit = (e) => {
    e.preventDefault()

    if (Boolean(data.id)) {
      patch(route('api.notes.update', data.id), {
        onFinish: () => {
          clearErrors()
          onClosed()
        },
      })
    } else {
      post(route('api.notes.store'), {
        onSuccess: () => {
          clearErrors()
          onClosed()
        },
      })
    }
  }

  return (
    <Dialog
      footerActions={
        <Button form="note-dialog" type="submit" theme="solid" disabled={!open || processing}>
          Save
        </Button>
      }
      show={open}
      size="h-full sm:h-auto sm:max-w-3xl"
      title={title || `Add a ${data.type}`}
      onClosed={onClosed}
      focusCancelButton
    >
      {note && (
        <form id="note-dialog" className="space-y-3" onSubmit={submit}>
          {!noteOnly && (
            <div>
              <nav className="relative z-0 flex divide-x divide-gray-200 overflow-hidden rounded border border-gray-200" aria-label="Tabs">
                {['Call', 'Mail', 'Text', 'Note'].map((type) => (
                  <button
                    type="button"
                    className={classNames(
                      'group relative min-w-0 flex-1 overflow-hidden bg-gray-50 px-4 py-4 text-center font-medium hover:bg-gray-50 focus:z-10',
                      data.type === type ? 'bg-primary-100 text-primary-500' : 'text-gray-500 hover:text-gray-700',
                    )}
                    aria-current="page"
                    key={type}
                    onClick={() => setData({ ...data, type: type })}
                  >
                    <span>{type}</span>
                    <span
                      aria-hidden="true"
                      className={classNames('absolute inset-x-0 bottom-0 h-0.5', data.type === type ? 'bg-primary-500' : 'bg-transparent')}
                    ></span>
                  </button>
                ))}
              </nav>
            </div>
          )}

          {data.type !== 'Note' && (
            <div className="space-y-3 pb-1 sm:flex sm:justify-between sm:gap-4 sm:space-y-0">
              <div className="sm:w-1/2">
                <label htmlFor="dob" className="mb-0.5 block text-sm font-medium uppercase text-gray-500">
                  Date
                </label>
                <DatePicker date={data.date} onChange={(date) => updateDate(date)} />
              </div>
              <div className="sm:w-1/2">
                <TimePicker
                  id="time"
                  label="time"
                  value={data.date}
                  onChange={(date) => setData({ ...data, date: date })}
                  defaultToCurrentTime
                />
              </div>
            </div>
          )}

          <div>
            <RichTextEditor
              theme="simple"
              value={content}
              height="lg:overflow-y-auto min-h-350px max-h-350px"
              onChange={(value) => setContent(value)}
              autoFocus
            />
            {errors.note && <div className="mt-1 text-red-600">{errors.note}</div>}
          </div>
        </form>
      )}
    </Dialog>
  )
}

NoteDialog.defaultProps = {
  noteOnly: true,
}
