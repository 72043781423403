import { useContext } from 'react'
import { DatePicker } from '@/Shared/DatePicker/DatePicker'
import TimePicker from '@/Shared/TimePicker'
import { DatesContext } from './DatesContext'

const Dates = ({ data, setData, errors }) => {
  const { dates, updateDate } = useContext(DatesContext)

  return dates.map((field) => (
    <div className="mb-4" key={field.name}>
      <label htmlFor="home_inspected_at" className="mb-0.5 block text-sm font-medium uppercase text-gray-500">
        {field.label}
      </label>

      <div className="flex items-center gap-4">
        <div className="flex-1">
          <DatePicker
            date={data[field.name] && new Date(data[field.name])}
            error={errors[field.name]}
            onChange={(date) => {
              setData({
                ...data,
                [field.name]: field.type === 'datetime' ? (date ? updateDate(date, data[field.name]) : null) : date || null,
              })
            }}
          />
        </div>

        {field.type === 'datetime' && (
          <div className="flex-1">
            <TimePicker
              id={field.name}
              value={data[field.name]}
              onChange={(date) => setData({ ...data, [field.name]: updateDate(data[field.name], date) })}
              disabled={!data[field.name]}
              isClearable={true}
            />
          </div>
        )}
      </div>
    </div>
  ))
}

export default Dates
