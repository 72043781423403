import { Fragment, forwardRef, useRef, useState } from 'react'
import classNames from 'classnames'
import { Transition } from '@headlessui/react'
import { useClickAway } from 'use-click-away'

const GroupOptions = () => null

const Wrapper = forwardRef(({ children, grouped }, ref) => {
  return grouped ? (
    <div ref={ref} className="inline-flex rounded-md shadow-sm">
      {children}
    </div>
  ) : (
    children
  )
})

const Button = forwardRef(
  ({ buttonClasses, children, className, colors, disabled, form, focusRef, grouped, theme, type, onClick, ...props }, ref) => {
    const clickRef = useRef(null)
    const groupOptions = Array.isArray(children)
      ? children.find((el) => el?.type === GroupOptions)
      : children.type === GroupOptions
        ? children
        : null
    let baseClasses = classNames(
      'group inline-flex items-center relative font-medium leading-none outline-none transition-all duration-150 ease-in-out focus:ring-2',
      ['icon', 'toolbar-icon'].indexOf(theme) < 0 && !className?.includes('ml-') ? 'ml-2' : '',
      grouped ? 'rounded-l-md' : 'rounded-md',
      buttonClasses,
    )

    const borderClasses = (size) =>
      classNames(
        'bg-white border border-gray-400 shadow-sm text-gray-700',
        {
          'px-4 py-2.5 text-base': size === 'sm',
          'h-10 px-3 xl:px-6': !size,
        },
        !disabled && 'hover:bg-gray-200 hover:text-gray-800 focus:border-transparent focus:ring-primary-500',
        disabled && 'opacity-50 cursor-not-allowed',
      )
    const cleanClasses = () => 'px-4 h-10 text-gray-600 hover:bg-primary-500 hover:text-white focus:ring-primary-500'
    const cleanIconClasses = (size) =>
      classNames('justify-center focus:ring-primary-500', {
        'w-7 h-7': size === 'sm',
        'w-10 h-10': !size,
      })
    const iconClasses = (size) => {
      return classNames(
        'justify-center',
        {
          'w-7 h-7': size === 'sm',
          'w-10 h-10': !size,
        },
        disabled
          ? ['cursor-not-allowed', colors || 'text-gray-300']
          : ['focus:ring-primary-500', colors || 'hover:bg-primary-100 hover:text-gray-800'],
      )
    }
    const linkClasses = () =>
      classNames(
        'justify-center px-4 h-10',
        disabled ? 'cursor-not-allowed text-gray-300' : 'text-primary-500 hover:bg-primary-100 focus:ring-primary-500',
      )
    const outlineClasses = (size) =>
      classNames(
        'bg-white border border-gray-300 shadow-sm text-gray-700',
        {
          'px-3 py-2.5 text-base': size === 'sm',
          'h-10 px-3 xl:px-6': !size,
        },
        !disabled && 'hover:bg-gray-200 hover:text-gray-800 focus:border-transparent focus:ring-primary-500',
        disabled && 'opacity-50 cursor-not-allowed',
      )
    const solidClasses = (size) => {
      return classNames(
        'text-white shadow-sm',
        {
          'px-4 py-2.5': size === 'sm',
          'px-6 h-10': !size,
        },
        colors || 'focus:ring-primary-500',
        disabled
          ? ['shadow-inner cursor-not-allowed', colors || 'bg-gray-300 text-gray-500']
          : ['focus:ring-offset-1', colors || 'bg-primary-500 hover:bg-primary-900'],
      )
    }
    const toolbarIconClasses = () => {
      return 'py-2 px-3 ml-1 h-10 hover:bg-white hover:bg-opacity-10 focus:ring-inset focus:ring-white'
    }
    const [open, setOpen] = useState(false)

    useClickAway(clickRef, () => {
      setTimeout(() => setOpen(false), 50)
    })

    return (
      <Wrapper ref={clickRef} grouped={grouped}>
        <button
          ref={ref}
          className={classNames(
            baseClasses,
            {
              border: borderClasses(),
              clean: cleanClasses(),
              'clean-icon': cleanIconClasses(),
              'clean-icon-sm': cleanIconClasses('sm'),
              icon: iconClasses(),
              'icon-sm': iconClasses('sm'),
              link: linkClasses(),
              outline: outlineClasses(),
              'outline-sm': outlineClasses('sm'),
              solid: solidClasses(props.size),
              'solid-sm': solidClasses('sm'),
              'toolbar-icon': toolbarIconClasses(),
            }[theme],
            className,
          )}
          disabled={disabled}
          form={form || null}
          type={type || 'button'}
          onClick={onClick}
          {...props}
        >
          {children}
        </button>
        {grouped && groupOptions && (
          <Fragment>
            <div className="relative -ml-px block">
              <button
                type="button"
                className={classNames(
                  'relative inline-flex h-10 items-center rounded-r-md bg-white px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-100 focus:z-10',
                  {
                    'cursor-not-allowed opacity-50': disabled,
                  },
                )}
                id="option-menu-button"
                aria-expanded="true"
                aria-haspopup="true"
                disabled={disabled}
                onClick={() => setOpen(!open)}
              >
                <span className="sr-only">Open options</span>
                <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path
                    fillRule="evenodd"
                    d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>

              <Transition
                show={open}
                enter="transition ease-out duration-100 transform"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="transition ease-in duration-75 transform"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div
                  className="absolute right-0 z-10 -mr-1 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="option-menu-button"
                  tabIndex="-1"
                >
                  <div className="py-1" role="none">
                    {groupOptions.props.children}
                  </div>
                </div>
              </Transition>
            </div>
          </Fragment>
        )}
      </Wrapper>
    )
  },
)

Button.GroupOptions = GroupOptions

export default Button
