import classNames from 'classnames'

export default ({ children, className, noMargin, ...props }) => {
  return (
    <div
      className={classNames(
        'border-b border-gray-200 pb-1 font-medium uppercase',
        { 'mb-3': noMargin == undefined },
        { 'text-primary-500': !className || !className.includes('text-') },
        className,
      )}
      {...props}
    >
      {children}
    </div>
  )
}
