import { Fragment } from 'react'
import Clients from './Clients'
import HRAssociateLicenses from './HR/AssociateLicenses'
import HRCapAnniversaries from './HR/CapAnniversaries'
import HRInactiveRealtorAssociations from './HR/InactiveRealtorAssociations'
import HRInactiveUnpaidMLSSystems from './HR/InactiveUnpaidMLSSystems'
import HROrientations from './HR/Orientations'
import HRTransactionsUnderReview from './HR/TransactionsUnderReview'
import Main from '@/Shared/Layouts/Main'
import OpenHouses from './OpenHouses'
import Prospects from './Prospects'
import { usePage } from '@inertiajs/react'

export default function Dashboard() {
  const { user } = usePage().props.auth

  return (
    <div>
      <div className="flex items-center justify-between gap-6 bg-white px-4 py-4 md:px-8">
        <div className="flex w-full flex-wrap items-center justify-start">
          <h1 className="pr-4 text-lg font-medium text-gray-800">Dashboard</h1>
        </div>
      </div>

      <div className="mx-auto grid max-w-2xl gap-6 px-4 py-4 md:max-w-7xl md:grid-cols-2 md:px-6 2xl:max-w-full 2xl:grid-cols-3">
        {user.roles.find((r) => r === 'Associate') && !user.roles.find((r) => r === 'hr_controller') && (
          <Fragment>
            <Prospects />
            <Clients />
            <OpenHouses />
          </Fragment>
        )}

        {user.roles.some((r) => ['Admin', 'hr_controller'].indexOf(r) >= 0) && (
          <Fragment>
            <HRCapAnniversaries />
            <HRTransactionsUnderReview />
            <HROrientations />
            <HRAssociateLicenses />
            <HRInactiveRealtorAssociations />
            <HRInactiveUnpaidMLSSystems />
          </Fragment>
        )}
      </div>
    </div>
  )
}

Dashboard.layout = (page) => <Main title="Dashboard" children={page} />
