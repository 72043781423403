import { useState } from 'react'
import AccordionCard from '@/Shared/AccordionCard'
import Button from '@/Shared/Button'
import classNames from 'classnames'
import Edit from './Edit'
import Label from '@/Shared/Label'
import PropertyListItem from '@/Shared/PropertyListItem'
import { usePage } from '@inertiajs/react'

export default () => {
  const { associate } = usePage().props.contact
  const [realtorAssociation, setRealtorAssociation] = useState(null)

  return (
    <AccordionCard
      title={`Realtor Associations (${associate.realtor_associations?.length || 0})`}
      actions={
        <Button theme="icon" onClick={() => setRealtorAssociation({})}>
          <span className="sr-only">Edit</span>
          <i className="far fa-pen text-gray-500 group-hover:font-bold group-hover:text-blue-500"></i>
        </Button>
      }
      indented={associate.realtor_associations?.length == 0}
      collapsed
    >
      <PropertyListItem label="NRDS ID" value={associate.nrds_id} />

      {associate.realtor_associations?.length > 0 ? (
        associate.realtor_associations.map((association, index) => (
          <PropertyListItem
            value={
              <div className="flex items-center justify-between">
                {association.primary && <i className="fas fa-star text-lg text-orange-500"></i>}

                <div className="mx-3 flex-1">
                  <div className={classNames('leading-none', association.primary ? 'font-medium text-orange-500' : '')}>
                    {association.value}
                  </div>

                  {!association.active && association.inactive_reason && (
                    <span className="italic text-gray-500">
                      Inactive reason{association.inactive_reason.length > 1 ? 's' : ''}:{' '}
                      {association.inactive_reason.reduce((output, reason) => output + (output ? ', ' : '') + reason.label, '')}
                    </span>
                  )}
                </div>

                <div className="space-x-2">
                  {association.active ? <Label type="success">Active</Label> : <Label type="error">Inactive</Label>}
                </div>
              </div>
            }
            key={index}
          />
        ))
      ) : (
        <div className="text-gray-700">No realtor associations have been added.</div>
      )}

      <Edit open={realtorAssociation != undefined} onClosed={() => setRealtorAssociation(null)} />
    </AccordionCard>
  )
}
