import { useEffect, useState } from 'react'
import Button from '@/Shared/Button'
import Dropdown from '@/Shared/Dropdown'
import DropdownItem from '@/Shared/DropdownItem'
import Edit from './Edit'
import Preview from '@/Pages/Contacts/Preview'
import Info from '@/Shared/Info'
import { router } from '@inertiajs/react'
import { usePage } from '@inertiajs/react'

export default ({ record, onClosed }) => {
  const { lsn } = usePage().props

  const [contact, setContact] = useState(null)
  const [editing, setEditing] = useState(false)

  useEffect(() => loadContact(record), [record])

  const loadContact = (record) => {
    if (record) {
      axios.get(route('api.contacts.show', record.contact_id)).then((response) => {
        setContact({ ...response.data })
      })
    } else {
      setContact(null)
    }
  }

  const onRemove = () => {
    router.delete(route('lsns.members.destroy', { lsn: lsn.id, member: record.id }), {
      onSuccess: () => {
        onClosed()
      },
    })
  }

  const onView = () => {
    window.open(`${lsn.permalink}/${record?.slug}${lsn.published ? '' : `?k=${lsn.preview_key}`}`, '_blank')
  }

  return (
    <Preview
      contact={contact}
      open={contact != null}
      title="About this Member"
      onClosed={() => onClosed()}
      onEdit={() => setEditing(true)}
      editable={lsn.editable}
    >
      {lsn?.editable &&
        (lsn?.manager_id == record?.advisor_id ? (
          <div className="px-4 sm:px-6">
            <Info
              text={
                <span>
                  This member is the managing Agent of <b>Better Living Real Estate</b> for this LSN.
                </span>
              }
            />
          </div>
        ) : (
          <Preview.Actions>
            {() =>
              lsn.editable &&
              !(lsn.published && [1, 2, 3].indexOf(record?.position_id) >= 0) && (
                <Dropdown id="actions-options" label="Actions" orientation="right" size="w-64">
                  <DropdownItem
                    colorClasses="text-red-500 group-hover:text-red-600"
                    onClick={() => onRemove()}
                    disabled={lsn?.manager_id == record?.advisor_id}
                  >
                    <i className="fas fa-unlink w-8"></i>
                    Remove Member
                  </DropdownItem>
                </Dropdown>
              )
            }
          </Preview.Actions>
        ))}

      <Edit record={record} open={editing} onClosed={() => setEditing(false)} />

      {record?.slug && (
        <Preview.FooterActions>
          {() => (
            <div className="flex justify-center">
              <Button type="submit" theme="solid" disabled={record.disabled || !record?.active} onClick={() => onView()}>
                View Member Page
                <i className="fas fa-external-link ml-3"></i>
              </Button>
            </div>
          )}
        </Preview.FooterActions>
      )}
    </Preview>
  )
}
