import Main from '@/Shared/Layouts/Main'
import Profile from '@/Shared/Profile/Profile'

const ProShow = ({ contact }) => {
  return <Profile contact={contact} />
}

// Persistent layout
// Docs: https://inertiajs.com/pages#persistent-layouts
ProShow.layout = (page) => <Main title={page.props.contact.full_name} children={page} overflowHidden />

export default ProShow
