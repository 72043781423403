import { Fragment, useState } from 'react'
import Button from '@/Shared/Button'
import Edit from './Edit'
import Filters from './Filters'
import SearchBar from '@/Shared/SearchBar'
import { usePage } from '@inertiajs/react'

export default () => {
  const { user } = usePage().props.auth
  const isAdminAssociate = user.roles.some((r) => ['Admin', 'Associate'].indexOf(r) >= 0)
  const [openDrawer, setOpenDrawer] = useState(null)

  return (
    <Fragment>
      <SearchBar
        actions={
          isAdminAssociate && (
            <Button theme="solid" onClick={() => setOpenDrawer('edit')}>
              <i className="fas fa-plus mr-2 text-lg"></i>
              <span className="pr-1">New</span>
            </Button>
          )
        }
        label="LSNs"
        placeholder="Search by name"
        onShowFilters={() => setOpenDrawer('filters')}
      />

      <Edit open={openDrawer === 'edit'} onClosed={() => setOpenDrawer(null)} />
      <Filters open={openDrawer === 'filters'} onClosed={() => setOpenDrawer(null)} />
    </Fragment>
  )
}
