import React from 'react'
import { Head, usePage } from '@inertiajs/react'
import Helpers from '../utils/helpers'

export default ({ title, children }) => {
  const { user } = usePage().props.auth
  const siteTitle = Helpers.isPRO(user) ? 'LSNPros' : 'BetterLivingPRO'

  return (
    <Head>
      <title>{title ? `${title} | ${siteTitle}` : siteTitle}</title>
      {children}
    </Head>
  )
}
