import { useEffect, useRef, useState } from 'react'
import { useForm, usePage } from '@inertiajs/react'

import Button from '@/Shared/Button'
import Select from '@/Shared/Forms/Select'
import SlideOver from '@/Shared/SlideOver'
import helpers from '../../utils/helpers'

export default ({ transaction, open, onClosed }) => {
  let focusRef = useRef()

  const { fileTypes } = usePage().props
  const form = useForm({})
  const { clearErrors, data, errors, setData, post, reset } = form
  const [availableStates, setAvailableStates] = useState([])
  const [availableAssociates, setAvailableAssociates] = useState([])
  const associates = transaction.contacts.filter((transactionContact) => transactionContact.contact_type === 'Associate')

  useEffect(() => {
    if (open) {
      setData({
        ...data,
        type_id: '',
        file: null,
      })
    } else {
      clearErrors()
    }
  }, [open])

  useEffect(() => {
    if (availableAssociates.length > 0) {
      setData({
        ...data,
        transaction_contact_id: availableAssociates?.length == 1 ? availableAssociates[0].value : data.transaction_contact_id,
      })
    }
  }, [availableAssociates])

  useEffect(() => {
    setData({
      ...data,
      state: availableStates?.length == 1 ? availableStates[0].value : data.state,
    })
  }, [availableStates])

  useEffect(() => {
    if (data.transaction_contact_id) {
      let selectedFileType = fileTypes.find((type) => type.value == data.type_id)
      let associate = associates.find((associate) => associate.id == data.transaction_contact_id)

      if (data.required_per_state) {
        if (transaction.type === 'Seller') {
          setAvailableStates([{ value: transaction.property_state, label: transaction.property_state }])
        } else {
          setAvailableStates(
            getMissingStateFiles(selectedFileType?.label, associate)
              .map((state) => ({ value: state.state, label: state.state }))
              .sort((a, b) => helpers.sortAlpha(a.label, b.label)),
          )
        }
      }
    }
  }, [data.transaction_contact_id])

  const fileChanged = (event) => {
    setData({ ...data, file: Array.from(event.target.files)[0] })
  }

  const findFileType = (value) => {
    let selected
    fileTypes.map((group) => {
      group.options.map((option) => {
        if (option.value === value) {
          selected = option
        }
      })
    })
    return selected?.label || ''
  }

  function getMissingStateFiles(name, transactionContact) {
    if (transaction.type === 'Seller') {
      let fileFound = transaction.allFiles.find(
        (file) => file.type === name && file.transaction_contact_id == transactionContact.id && file.state == transaction.property_state,
      )
      return fileFound ? [] : [transaction.property_state]
    }

    return transaction.states.filter(
      (state) =>
        transactionContact.contact.associate.licenses.find((license) => !license.expired && license.state == state.state) &&
        !transaction.allFiles
          .filter((file) => file.type === name)
          .find((file) => file.transaction_contact_id == transactionContact.id && file.state == state.state),
    )
  }

  const getReadableFileSizeString = (Num = 0, dec = 1) => {
    if (Num < 1000) return Num + ' Bytes'
    Num = ('0'.repeat(((Num += '').length * 2) % 3) + Num).match(/.{3}/g)
    return Number(Num[0]) + '.' + Num[1].substring(0, dec) + '  KMGTPEZY'[Num.length]
  }

  const onTypeChanged = (selected) => {
    setData({
      ...data,
      type_id: selected?.value,
      state: undefined,
      transaction_contact_id: associates.find((associate) => associate.is_transaction_owner)?.id,
      required_per_associate: selected?.required_per_associate,
      required_per_state: selected?.required_per_state,
      addendum: selected?.addendum || false,
    })

    let selectedFileType = fileTypes.find((type) => type.value == selected?.value)

    if (selected?.required_per_associate && associates.length > 0) {
      let availableAssociates = selected?.required_per_state
        ? associates.filter((associate) => getMissingStateFiles(selectedFileType?.label, associate).length > 0)
        : associates.filter(
            (associate) =>
              !transaction.allFiles?.find((file) => file.type === selectedFileType?.label && file.transaction_contact_id == associate.id),
          )

      setAvailableAssociates(
        availableAssociates
          .map((associate) => ({ value: associate.id, label: associate.contact?.full_name || associate.full_name }))
          .sort((a, b) => helpers.sortAlpha(a.label, b.label)),
      )
    } else if (selected?.required_per_state && transaction.states.length > 0) {
      setAvailableStates(
        transaction.states
          .filter((state) => !transaction.allFiles?.find((file) => file.type === selectedFileType?.label && file.state == state.state))
          .map((state) => ({ value: state.state, label: state.state }))
          .sort((a, b) => helpers.sortAlpha(a.label, b.label)),
      )
    } else if (selected?.required_per_state && transaction.type === 'Seller') {
      setAvailableStates([{ value: transaction.property_state, label: transaction.property_state }])
    }
  }

  const submit = (event) => {
    event.preventDefault()

    post(route('transactions.files.store', transaction.id), {
      onSuccess: () => {
        clearErrors()
        reset()
        onClosed()
      },
    })
  }

  return (
    <SlideOver
      focusRef={focusRef}
      footerActions={
        <Button type="submit" theme="solid" form="newFile">
          Upload
        </Button>
      }
      show={open}
      size="max-w-xl"
      title="New"
      subTitle="File"
      onClosed={() => {
        reset()
        onClosed()
      }}
      aboveMessages
    >
      <form id="newFile" className="space-y-4" onSubmit={submit}>
        <Select
          label="Type"
          error={errors['type_id']}
          placement="bottom"
          name="type_id"
          options={fileTypes}
          placeholder="Select a type"
          value={findFileType(data.type_id)}
          required
          onChange={(selected) => onTypeChanged(selected)}
        />

        {data.required_per_associate && (
          <Select
            label="Associate"
            error={errors['transaction_contact_id']}
            placement="bottom"
            name="associate"
            options={availableAssociates}
            placeholder="None"
            value={data.transaction_contact_id}
            required
            isClearable={availableAssociates?.length > 1}
            onChange={(selected) => setData({ ...data, transaction_contact_id: selected?.value })}
          />
        )}

        {data.required_per_state && (
          <Select
            label="State"
            error={errors['state']}
            placement="bottom"
            name="state"
            options={availableStates}
            placeholder={data.required_per_associate ? 'Select an Associate' : 'None'}
            value={data.state}
            required
            onChange={(selected) => setData({ ...data, state: selected?.value })}
            isClearable={availableStates?.length > 1}
            disabled={data.required_per_associate && !data.transaction_contact_id}
          />
        )}

        <div>
          <label className="text-sm font-medium uppercase text-gray-600">
            File <span className="text-red-500">*</span>
          </label>

          {data.file ? (
            <div className="mt-1">
              <div className="flex items-center justify-between bg-gray-100 py-2 px-3">
                <div className="font-semibold">
                  {fileTypes.find((type) => type.value === data.type_id)?.label || data.file.name}.pdf
                  <span className="ml-1 text-gray-600">({getReadableFileSizeString(data.file.size)})</span>
                </div>
                <div>
                  <button
                    className="flex h-5 items-center justify-center rounded px-1 outline-none hover:bg-green-500 hover:text-white focus:bg-green-100 focus:text-blue-500 focus:ring-2 focus:ring-inset focus:ring-blue-500"
                    onClick={() => setData({ ...data, file: null })}
                  >
                    <i className="far fa-times text-lg"></i>
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div className="relative">
              <label
                htmlFor="url"
                className="font-md transition-border block h-11 w-full rounded border border-gray-300 px-4 py-2 placeholder-gray-400 outline-none duration-150 ease-in-out focus-within:border-transparent focus-within:ring-2 focus-within:ring-primary-500 hover:border-gray-400"
              >
                <input type="file" id="url" className="sr-only" accept="application/pdf" onChange={fileChanged} />
                Choose file
                <div className="absolute inset-y-0 right-0 my-px mr-0.5 flex h-[calc(100%-2px)] items-center rounded-r border border-transparent bg-gray-100 px-4 text-gray-700">
                  Browse
                </div>
              </label>
              {errors.file && <div className="mt-1 text-red-600" dangerouslySetInnerHTML={{ __html: errors.file }}></div>}
            </div>
          )}
        </div>
      </form>
    </SlideOver>
  )
}
