import AccordionCard from '@/Shared/AccordionCard'
import Button from '@/Shared/Button'
import PropertyListItem from '@/Shared/PropertyListItem'
import { usePage } from '@inertiajs/react'

const Wrapper = ({ children, preview, onEdit }) =>
  preview ? (
    <AccordionCard
      title="About this Open House"
      actions={
        <Button theme="icon" onClick={onEdit}>
          <i className="far fa-pen text-gray-500 group-hover:font-bold group-hover:text-blue-500"></i>
        </Button>
      }
      hideBorder
      indented={false}
    >
      <div className="border-b border-gray-200">{children}</div>
    </AccordionCard>
  ) : (
    <div className="border-b border-gray-200">{children}</div>
  )

export default ({ preview, onEdit }) => {
  const { openhouse } = usePage().props
  return (
    <Wrapper preview={preview} onEdit={onEdit}>
      <PropertyListItem
        as="a"
        href={`https://maps.google.com/maps?q=${openhouse.address_query}`}
        icon="fas fa-map-marker-alt text-xl"
        label="Address"
        value={openhouse.address}
      />
      <PropertyListItem label="MLS" value={openhouse.mls_id} />
      <PropertyListItem label="List Price" value={'$' + openhouse.list_price} />
      <PropertyListItem label="Host" value={openhouse.host.full_name} primary />
      {openhouse.cohosts?.length > 0 && <PropertyListItem label="Co-Hosts" value={openhouse.cohosts_list} />}
      {openhouse.otherAttendees?.length > 0 && <PropertyListItem label="Other Attendees" value={openhouse.otherAttendees_list} />}

      {openhouse.updated_at != openhouse.created_at && <PropertyListItem label="Last Updated" value={openhouse.updated_at} />}
      {<PropertyListItem label="Date Added" value={openhouse.created_at} />}

      {openhouse.files?.length > 0 && (
        <div className="mt-4 px-4">
          <div className="text-lg font-semibold text-gray-800">Files</div>

          <div className="mx-auto mt-2 mb-3 flex max-w-[70%] flex-col justify-center space-y-2 text-gray-700">
            {openhouse.files.map((file) => (
              <a
                href={route('openhouses.files.show', file.id)}
                className="rounded-lg border-2 border-gray-300 py-2 px-4"
                target="_blank"
                key={file.id}
              >
                <i className="far fa-file pr-2 text-blue-500"></i> {file.type}
              </a>
            ))}
          </div>
        </div>
      )}

      {(Boolean(openhouse.checkins_enabled) || Boolean(openhouse.include_other_attendees_in_emails)) && (
        <div className="mt-4 px-4">
          <div className="text-lg font-semibold text-gray-800">Enabled Features</div>

          <ul className="mt-2 mb-3 space-y-3 text-gray-700">
            {Boolean(openhouse.checkins_enabled) && (
              <li>
                <i className="fas fa-check-circle pr-2 text-green-700"></i> Check IN / Check OUT mode
              </li>
            )}
            {Boolean(openhouse.notifications_enabled) && (
              <li>
                <i className="fas fa-check-circle pr-2 text-green-700"></i> Host & Co-Hosts are notified of new registrations
              </li>
            )}
            {Boolean(openhouse.include_other_attendees_in_emails) && (
              <li>
                <i className="fas fa-check-circle pr-2 text-green-700"></i> Other attendees appear in registration emails
              </li>
            )}
          </ul>
        </div>
      )}

      {openhouse.optional_fields && Object.keys(openhouse.optional_fields).length > 0 && (
        <div className="mt-4 px-4">
          <div className="text-lg font-semibold text-gray-800">Enabled Optional Fields</div>

          <ul className="mt-2 mb-3 space-y-3 text-gray-700">
            {openhouse.optional_fields.map((field) => (
              <li key={field.name}>
                <i className="fas fa-check-circle pr-2 text-green-700"></i> {field.display_name} {field.required ? '(required)' : ''}
              </li>
            ))}
          </ul>
        </div>
      )}
    </Wrapper>
  )
}
