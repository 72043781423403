import { Fragment, useContext, useEffect, useRef, useState } from 'react'
import { BlockUIContext } from '@/Shared/BlockUI/BlockUIContext'
import Button from '@/Shared/Button'
import Dialog from '@/Shared/Dialog/Index'
import Dropdown from '@/Shared/Dropdown'
import DropdownItem from '@/Shared/DropdownItem'
import classNames from 'classnames'
import { useForm, usePage } from '@inertiajs/react'
import TextArea from '@/Shared/Forms/TextArea'
import { router } from '@inertiajs/react'

export default ({ file, open, onCanceled }) => {
  if (!file) return <></>

  let focusRef = useRef()
  const { errors } = usePage().props
  const { loading, setLoading } = useContext(BlockUIContext)
  const [fetching, setFetching] = useState(true)
  const [url, setUrl] = useState('')
  const [beforeSubmitting, setBeforeSubmitting] = useState(false)
  const { data, setData, clearErrors } = useForm({
    comments: file.comments || '',
    status: file.status || 'Approved',
  })

  useEffect(() => {
    if (open && !url) {
      const url = new URL(route('transactions.files.review', file.id))
      url.searchParams.append('mode', 'url')

      fetch(url, {
        method: 'GET',
        credentials: 'include',
      })
        .then((response) => response.json())
        .then((data) => {
          setUrl(data.url)
          setTimeout(() => {
            setFetching(false)
          }, 150)
        })
    }
  }, [open])

  const onCloseDialog = () => {
    onCanceled()
    setLoading(false)
  }

  const onSubmit = (status) => {
    setLoading(true)

    router.post(
      route('transactions.files.change-status', { transaction: file.transaction_id, file: file.id }),
      { ...data, _method: 'put', status: status || data.status },
      {
        forceFormData: true,
        onSuccess: () => {
          clearErrors()
          onCanceled()
          setBeforeSubmitting(false)
        },
        onFinish: (response) => {
          setLoading(false)
        },
      },
    )
  }

  return (
    <Dialog
      cancelText="Close"
      focusRef={focusRef}
      footerActionsLeft={
        <Fragment>
          {Boolean(file.status_changed_at) && (
            <div
              className={classNames({
                'text-green-700': file.status === 'Approved',
                'text-red-600': file.status !== 'Approved',
              })}
            >
              Marked <span className="font-semibold">{file.status}</span> on {file.status_changed_at}
            </div>
          )}
        </Fragment>
      }
      footerActions={
        <Fragment>
          <Dropdown id="review-actions" buttonTheme="solid" label="Actions" orientation="right" size="w-64" disabled={loading} dropup>
            {file.status !== 'Approved' && (
              <DropdownItem
                onClick={() => {
                  onSubmit('Approved')
                }}
              >
                <div className="flex items-center gap-3">
                  <div className="w-9 text-center">
                    <i className="fas fa-check text-xl text-green-600"></i>
                  </div>
                  Approve
                </div>
              </DropdownItem>
            )}

            {file.status !== 'Declined' && (
              <DropdownItem
                onClick={() => {
                  setData({ ...data, status: 'Declined' })
                  setBeforeSubmitting(true)
                }}
              >
                <div className="flex items-center gap-3">
                  <div className="w-9 text-center">
                    <i className="fas fa-times text-xl text-red-600"></i>
                  </div>
                  Decline
                </div>
              </DropdownItem>
            )}

            {file.addendum && file.status !== 'Not Applicable' && (
              <DropdownItem
                onClick={() => {
                  setData({ ...data, status: 'Not Applicable' })
                  setBeforeSubmitting(true)
                }}
              >
                <div className="flex items-center gap-3">
                  <div className="w-9 text-center">
                    <span className="font-semibold text-red-600">N/A</span>
                  </div>
                  Not Applicable
                </div>
              </DropdownItem>
            )}
          </Dropdown>
        </Fragment>
      }
      isLoading={loading}
      show={open}
      size="w-full h-full"
      title="File Review"
      onClosed={onCloseDialog}
      focusCancelButton
      fullHeight
    >
      {fetching ? (
        <div
          className={classNames('absolute inset-x-0 inset-y-0 mb-8 flex h-full grow items-center justify-center bg-gray-200 px-10 py-32', {
            'bg-opacity-75': url,
          })}
        >
          <div className="flex flex-col items-center gap-3 px-4 py-3 sm:px-6">
            <span className="sr-only">Loading, please wait...</span>
            <div className="h-4">
              <svg width="120" height="30" viewBox="0 0 120 30" xmlns="http://www.w3.org/2000/svg">
                <circle cx="15" cy="15" r="15">
                  <animate
                    attributeName="r"
                    from="15"
                    to="15"
                    begin="0s"
                    dur="0.8s"
                    values="15;9;15"
                    calcMode="linear"
                    repeatCount="indefinite"
                  />
                  <animate
                    attributeName="fill-opacity"
                    from="1"
                    to="1"
                    begin="0s"
                    dur="0.8s"
                    values="1;.5;1"
                    calcMode="linear"
                    repeatCount="indefinite"
                  />
                </circle>
                <circle cx="60" cy="15" r="9" fillOpacity="0.3">
                  <animate
                    attributeName="r"
                    from="9"
                    to="9"
                    begin="0s"
                    dur="0.8s"
                    values="9;15;9"
                    calcMode="linear"
                    repeatCount="indefinite"
                  />
                  <animate
                    attributeName="fill-opacity"
                    from="0.5"
                    to="0.5"
                    begin="0s"
                    dur="0.8s"
                    values=".5;1;.5"
                    calcMode="linear"
                    repeatCount="indefinite"
                  />
                </circle>
                <circle cx="105" cy="15" r="15">
                  <animate
                    attributeName="r"
                    from="15"
                    to="15"
                    begin="0s"
                    dur="0.8s"
                    values="15;9;15"
                    calcMode="linear"
                    repeatCount="indefinite"
                  />
                  <animate
                    attributeName="fill-opacity"
                    from="1"
                    to="1"
                    begin="0s"
                    dur="0.8s"
                    values="1;.5;1"
                    calcMode="linear"
                    repeatCount="indefinite"
                  />
                </circle>
              </svg>
            </div>
          </div>
        </div>
      ) : (
        <Fragment>
          <div className="flex justify-center">
            <span className="-mt-3 mb-2 px-4 py-1 text-2xl font-bold">{file.filename}</span>
          </div>

          <iframe src={url} width="100%" className="h-full"></iframe>

          <Dialog
            focusRef={focusRef}
            cancelText="Close"
            footerActions={
              <Button type="submit" theme="solid" disabled={loading} onClick={() => onSubmit()}>
                Continue
              </Button>
            }
            isLoading={loading}
            show={beforeSubmitting}
            size="sm:max-w-2xl"
            title="File Comments"
            onClosed={() => {
              clearErrors()
              setBeforeSubmitting(false)
            }}
          >
            <p className="mb-3">
              Provide some comments for why this file is being marked as <b>{data.status}</b> (visible to Associates on this transaction).
            </p>

            <TextArea
              ref={focusRef}
              label="Comments"
              name="reason"
              value={data.comments}
              error={errors.comments}
              rows="4"
              onChange={(value) => setData({ ...data, comments: value })}
              required
              multiline
            />
          </Dialog>
        </Fragment>
      )}
    </Dialog>
  )
}
