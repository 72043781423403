import { useEffect, useRef, useState } from 'react'
import Button from '@/Shared/Button'
import { DatePicker } from '@/Shared/DatePicker/DatePicker'
import MultipleInputBlock from '@/Shared/Forms/MultipleInputBlock'
import Select from '@/Shared/Forms/Select'
import SlideOver from '@/Shared/SlideOver'
import { useForm, usePage } from '@inertiajs/react'

export default ({ open, onClosed }) => {
  let focusRef = useRef()
  const { contact, hr_date_types } = usePage().props
  const { clearErrors, data, setData, patch, reset } = useForm({
    id: null,
    hr_dates: [{}],
  })
  const [types, setTypes] = useState(hr_date_types)

  useEffect(() => {
    if (open) {
      setData({
        id: contact.associate.id,
        hr_dates: contact.associate.hr_dates.length > 0 ? contact.associate.hr_dates : [{}],
      })
    }
  }, [open])

  const submit = (event) => {
    event.preventDefault()

    patch(route('admin.associates.update', data.id), {
      onSuccess: () => {
        clearErrors()
        reset()
        onClosed()
      },
    })
  }

  return (
    <SlideOver
      focusRef={focusRef}
      footerActions={
        <Button type="submit" theme="solid" form="hrDatesForm">
          Save
        </Button>
      }
      show={open}
      size="max-w-lg"
      title="Edit"
      subTitle="HR Dates"
      onClosed={onClosed}
    >
      <form id="hrDatesForm" className="-mt-4 mb-5" onSubmit={submit}>
        <MultipleInputBlock
          data={data.hr_dates}
          onChange={(value) => setData({ ...data, hr_dates: value })}
          render={({ data: innerData, onChange }) => (
            <div className="flex-1 sm:flex">
              <DatePicker date={innerData.date && new Date(innerData.date)} onChange={(date) => onChange(date, 'date')} />

              <Select
                creatable={true}
                classes="mt-2 sm:flex-grow sm:ml-3 sm:mt-0"
                name="type"
                options={types.filter((type) => type.label === innerData.type || !data.hr_dates.find((date) => date.type === type.label))}
                placeholder="Type"
                value={innerData.type}
                isClearable={false}
                onChange={(selected) => {
                  if (selected) {
                    if (types.find((i) => i.value === selected.value) == undefined) {
                      setTypes(types.concat(selected))
                    }
                  }

                  onChange(selected && selected.value, 'type')
                }}
              />
            </div>
          )}
        />
      </form>
    </SlideOver>
  )
}
