import React, { useState } from 'react'
import AccordionCard from '@/Shared/AccordionCard'
import AccordionItem from '@/Shared/AccordionItem'
import Button from '@/Shared/Button'
import Stage from '@/Pages/Transactions/Stage'
import TransactionCreate from '@/Pages/Transactions/Create'

export default function TransactionsIndex({ contact, onTransactionView }) {
  const [openDrawer, setOpenDrawer] = useState(null)

  const handleClosed = (event, transaction) => {
    setOpenDrawer(null)

    if (transaction) {
      setTimeout(() => {
        onTransactionView(transaction || null)
      }, 300)
    }
  }

  return (
    <AccordionCard
      title={`Transactions (${contact.transactions ? contact.transactions.length : 0})`}
      actions={
        contact.type === 'Contact' && (
          <Button theme="link" onClick={() => setOpenDrawer('create')}>
            <i className="far fa-plus pr-1 text-sm"></i>
            <span>Add</span>
          </Button>
        )
      }
      collapsed
    >
      {contact.transactions && contact.transactions.length > 0 ? (
        <div className="divide-y divide-dashed divide-gray-200 overflow-hidden rounded-md border border-gray-200">
          {contact.transactions.map((transaction) => (
            <AccordionItem key={transaction.id} onClick={() => onTransactionView(transaction)}>
              <div className="font-semibold leading-tight text-gray-900">{transaction.name}</div>
              {['Compliance', 'Under Review'].find((status) => status === transaction.status) ? (
                <div className="mt-1 inline-block whitespace-nowrap rounded bg-orange-200/60 px-2.5 py-1.5 text-sm font-medium leading-none text-orange-600">
                  <span>{transaction.status}</span>
                </div>
              ) : (
                <Stage transaction={transaction} horizontal />
              )}
            </AccordionItem>
          ))}
        </div>
      ) : (
        <div className="text-gray-700">No transactions have been added.</div>
      )}

      {contact.type === 'Contact' && <TransactionCreate contact={contact} open={openDrawer === 'create'} onClosed={handleClosed} />}
    </AccordionCard>
  )
}
