import React, { useEffect, useRef, useState } from 'react'
import { useForm, usePage } from '@inertiajs/react'

import Button from '@/Shared/Button'
import Dialog from '@/Shared/Dialog/Index'
import TextInput from '@/Shared/Forms/TextInput'
import { router } from '@inertiajs/react'

export default ({ open, record, onClosed }) => {
  let focusRef = useRef()
  const { checklist } = usePage().props
  const form = useForm({})
  const { clearErrors, data, setData, errors } = form
  const [stepDoesntExist, setStepDoesntExist] = useState(false)
  const [movementRestrictedError, setMovementRestrictedError] = useState('')

  useEffect(() => {
    const step = record || {}
    setData({
      id: step.id,
      index: step.index,
      name: step.name,
      nameWithoutTags: step.nameWithoutTags,
      section_id: step.section_id,
      sort_order: step.sort_order,
    })
  }, [record])

  const submit = (event) => {
    event.preventDefault()

    let match = null

    checklist.sections.some((section) => {
      match = section.steps.find((step) => step.index == data.index)

      if (match) {
        return true
      }
    })

    if (!match) {
      setStepDoesntExist(true)
      return
    }

    router.post(
      route('admin.steps.update', data.id),
      {
        _method: 'put',
        section_id: match.section_id,
        sort_order: match.sort_order,
      },
      {
        onSuccess: () => {
          onClosed()
          clearErrors()
        },
        onError: (response) => {
          setMovementRestrictedError(response.move_restricted)
        },
      },
    )
  }

  return (
    <Dialog
      focusRef={focusRef}
      footerActions={
        <Button form="moveForm" type="submit" theme="solid">
          Save
        </Button>
      }
      show={open}
      title="Move Step"
      onClosed={onClosed}
    >
      <form id="moveForm" className="flex h-full flex-col" onSubmit={submit}>
        <p className="pb-3">
          Where would you like to move <span className="font-medium">"{data.nameWithoutTags}"</span> to?
        </p>

        <div className="flex flex-col items-center">
          <TextInput
            type="number"
            label="Step #"
            name="index"
            value={data.index}
            onChange={(value) => setData({ ...data, index: value })}
            size="w-20"
            inline
            required
          />

          {stepDoesntExist && (
            <div className="mt-4 rounded-lg border-2 border-red-600 bg-red-50 p-4 font-medium text-red-600">The step doesn't exist</div>
          )}
          {errors.move_restricted && (
            <div className="mt-4 rounded-lg border-2 border-red-600 bg-red-50 p-4 font-medium text-red-600">{errors.move_restricted}</div>
          )}
        </div>
      </form>

      <Dialog
        headerBackgroundColor="bg-red-600"
        show={Boolean(movementRestrictedError)}
        title="Action Not Allowed"
        cancelText="Close"
        onClosed={() => setMovementRestrictedError('')}
        focusCancelButton
      >
        <div className="text-center text-lg">
          <p>{movementRestrictedError}</p>
        </div>
      </Dialog>
    </Dialog>
  )
}
