import { createRef, useEffect, useRef, useState } from 'react'
import { useForm, usePage } from '@inertiajs/react'
import axios from '@/apis/axios'
import AddressField from './AddressField'
import Alert from '@/Shared/Alert'
import Button from '@/Shared/Button'
import Dialog from '@/Shared/Dialog/Index'
import { router } from '@inertiajs/react'
import RichTextEditor from '@/Shared/RichTextEditor/Index'
import TextInput from '@/Shared/Forms/TextInput'

export default ({ open, step, template, transaction, onClosed }) => {
  const { testing } = usePage().props
  const focusRef = useRef(),
    scrollRef = useRef(),
    toRef = useRef(),
    ccRef = useRef(),
    bccRef = useRef()
  const attachmentRef = createRef()

  const record = template

  const form = useForm({
    step_id: step.id,
    template_id: record.id,
    transaction_id: transaction.id,
    subject: record.subject,
    body: record.content + '<p>&nbsp;</p>' + record.signature + record.disclaimer,
    to: [],
    cc: [],
    bcc: [],
    requiredFiles: record.files,
    attachments: record.attachments,
  })
  const { data, setData } = form
  const [ccOpen, setCcOpen] = useState(false)
  const [bccOpen, setBccOpen] = useState(false)
  const [errors, setErrors] = useState({})
  const [content, setContent] = useState(record.content + '<p>&nbsp;</p>' + record.signature + record.disclaimer || '')

  useEffect(() => {
    setTimeout(() => {
      toRef.current.focus()
      getContacts()
    }, 100)
  }, [])

  useEffect(
    () =>
      setData((prevData) => ({
        ...prevData,
        body: content,
      })),
    [content],
  )

  const clearError = (property) => {
    delete errors[property]
    setErrors({ ...errors })
  }

  const getContacts = () => {
    let associates = []
    let to = []
    let cc = []
    let bcc = []

    let toRecipients = filterRecipients(record.to || [])
    let ccRecipients = filterRecipients(record.cc || [])
    let bccRecipients = filterRecipients(record.bcc || [])

    transaction.contacts
      .filter((c) => c.contact_type !== 'Associate' || c.invitation_accepted_at)
      .map((c) => {
        if (c.contact_type === 'Associate') {
          associates = associates.concat({
            name: c.full_name,
            value: c.email,
            label: `${c.full_name} <${c.email}>`,
            me: c.is_me,
            preferences: c.contact.preferences,
          })
        }

        if (isRecipient(toRecipients, c) && c.email) {
          to = to.concat({
            name: c.full_name,
            first_name: c.nickname || c.first_name || c.contact?.nickname || c.contact?.first_name,
            email: c.email,
          })

          c.supporting
            .filter((supportContact) => supportContact.cc_for_supported_contact)
            .map((c) => {
              cc = cc.concat({
                name: c.full_name,
                first_name: c.nickname || c.first_name || c.contact?.nickname || c.contact?.first_name,
                email: c.email,
              })
            })
        }

        if (isRecipient(ccRecipients, c) && c.email) {
          cc = cc.concat({
            name: c.full_name,
            first_name: c.nickname || c.first_name || c.contact?.nickname || c.contact?.first_name,
            email: c.email,
          })

          c.supporting
            .filter((supportContact) => supportContact.cc_for_supported_contact)
            .map((c) => {
              cc = cc.concat({
                name: c.full_name,
                first_name: c.nickname || c.first_name || c.contact?.nickname || c.contact?.first_name,
                email: c.email,
              })
            })
        }

        if (isRecipient(bccRecipients, c) && c.email) {
          bcc = bcc.concat({
            name: c.full_name,
            first_name: c.nickname || c.first_name || c.contact?.nickname || c.contact?.first_name,
            email: c.email,
          })

          c.supporting
            .filter((supportContact) => supportContact.cc_for_supported_contact)
            .map((c) => {
              bcc = bcc.concat({
                name: c.full_name,
                first_name: c.nickname || c.first_name || c.contact?.nickname || c.contact?.first_name,
                email: c.email,
              })
            })
        }
      })

    if (associates.length > 0) {
      cc = cc.concat(associates.filter((associate) => !associate.me).map((associate) => ({ name: associate.name, email: associate.value })))

      bcc = bcc.concat(
        associates
          .filter((associate) => associate.me && associate.preferences?.email?.bcc_transaction_emails)
          .map((associate) => ({ name: associate.name, email: associate.value })),
      )
    }

    let greeting = []
    if (to.length > 0) {
      greeting = generateGreeting(to)
    }

    setData({ ...data, to: to, cc: cc, bcc: bcc, body: greeting + content })
    setCcOpen(cc.length > 0)
    setBccOpen(bcc.length > 0)
  }

  const filterRecipients = (recipients) => {
    return recipients.map((recipient) => {
      if (recipient.value.includes(' or ')) {
        recipient.value.split(' or ').some((group) => {
          let values = group.split('-')
          if (
            transaction.contacts.some((contact) => {
              return contact.roles.find(
                (r) =>
                  r.primary &&
                  (values.length == 2
                    ? r.party_representing.toLowerCase() == values[0] && r.name.toLowerCase() === values[1]
                    : r.name.toLowerCase() === values[0]),
              )
            })
          ) {
            recipient = group
            return true
          }
        })

        return recipient
      }

      return recipient.value
    })
  }

  const generateGreeting = (recipients) => {
    let names = recipients.reduce(
      (output, r, index) =>
        output + (index > 0 ? (recipients.length > 2 && index + 1 < recipients.length ? ', ' : ' and ') : '') + r.first_name,
      '',
    )

    setContent(`<p>Hi ${names},</p>` + content)
  }

  const isRecipient = (recipients, contact) => {
    return recipients.some((recipient) => {
      let values = (typeof recipient === 'string' ? recipient : recipient.value).split('-')

      return contact.roles.find((r) =>
        values.length == 2
          ? r.party_representing.toLowerCase() == values[0] && r.name.toLowerCase() === values[1]
          : r.name.toLowerCase() === values[0],
      )
    })
  }

  const getReadableFileSizeString = (Num = 0, dec = 1) => {
    if (Num < 1000) return Num + ' Bytes'
    Num = ('0'.repeat(((Num += '').length * 2) % 3) + Num).match(/.{3}/g)
    return Number(Num[0]) + '.' + Num[1].substring(0, dec) + '  KMGTPEZY'[Num.length]
  }

  const onAttachmentsChanged = (event) => {
    setData({ ...data, attachments: data.attachments.concat(Array.from(event.target.files)) })

    setTimeout(() => {
      scrollRef.current.scrollTo({ top: scrollRef.current.scrollHeight })
    }, 100)
  }

  const removeAttachment = (index) => {
    let attachments = data.attachments
    attachments.splice(index, 1)
    setData({ ...data, attachments: attachments })
  }

  const removeRequiredFile = (index) => {
    let requiredFiles = data.requiredFiles
    requiredFiles.splice(index, 1)
    setData({ ...data, requiredFiles: requiredFiles })
  }

  const submit = (event) => {
    event.preventDefault()

    const formData = new FormData()
    for (const [key, value] of Object.entries(data)) {
      if (!Array.isArray(value)) {
        formData.append(key, value)
      } else {
        for (let i = 0; i < value.length; i++) {
          const properties = Object.keys(value[i])
          if (properties.length == 0) {
            formData.append(`${key}[${i}]`, value[i])
          } else {
            for (let p = 0; p < properties.length; p++) {
              formData.append(`${key}[${i}][${properties[p]}]`, value[i][properties[p]])
            }
          }
        }
      }
    }

    axios
      .post(route('email-templates.store'), formData, {
        'content-type': 'multipart/form-data',
      })
      .then((_) => {
        onClosed()
        router.reload({ only: ['flash', 'history', 'contact', 'transaction'] })
      })
      .catch((response) => {
        if (response.response != undefined) {
          const { status } = response.response
          const VALIDATION_ERROR = 422
          if (status == VALIDATION_ERROR) {
            setErrors(response.response.data.errors)
          }
        }
      })
  }

  return (
    <Dialog
      ref={scrollRef}
      focusRef={focusRef}
      footerActionsLeft={
        <label
          htmlFor="attachments"
          className="relative inline-flex h-10 w-10 cursor-pointer items-center justify-center rounded-md font-medium leading-none outline-none transition-all duration-150 ease-in-out focus-within:ring-2 focus-within:ring-blue-500 hover:bg-blue-100 hover:text-gray-800"
        >
          <i className="far fa-paperclip text-lg"></i>
          <span className="sr-only">Attachment</span>
          <input id="attachments" name="attachments" type="file" className="sr-only" multiple onChange={onAttachmentsChanged}></input>
        </label>
      }
      footerActions={
        <Button form="composeEmailForm" type="submit" theme="solid" disabled={record.invalid_shortcodes.length > 0}>
          <i className="far fa-paper-plane mr-3"></i>
          Send
        </Button>
      }
      position="top"
      show={open}
      size="max-w-6xl"
      title={'New message'}
      onClosed={onClosed}
    >
      {template && (
        <form id="composeEmailForm" className="-mx-6 -mt-6 flex h-full flex-col space-y-4" onSubmit={submit}>
          <div>
            {testing && (
              <div className="border-b border-red-300 bg-red-100 px-6 py-2 leading-snug text-red-600">
                <div className="mx-auto max-w-2xl space-y-1">
                  <p className="text-center text-lg font-bold">BETA Email Restrictions:</p>
                  <ul className="list-disc space-y-1">
                    <li>Emails will only be sent to you regardless of the recipients in the TO field.</li>
                    <li>For CC/BCC recipients, only emails with the betterlivingre.com domain will be sent.</li>
                  </ul>
                </div>
              </div>
            )}

            <div className="border-b border-gray-300 bg-gray-200 bg-opacity-75 px-6 py-4 leading-snug">
              <div className="text-sm font-bold uppercase text-gray-700">Task:</div>
              <p className="text-base text-gray-800">{step.nameWithoutTags}</p>
            </div>
          </div>

          <div>
            <AddressField
              ref={toRef}
              name="to"
              data={transaction.contacts}
              emails={data.to}
              error={errors.to}
              onInput={() => clearError('to')}
              onUpdate={(addresses) => setData({ ...data, to: addresses })}
            >
              <div className="-my-1 ml-4 flex-shrink-0">
                {!ccOpen && (
                  <button
                    type="button"
                    className="rounded-md px-1.5 py-0.5 font-medium uppercase text-blue-500 hover:bg-blue-100 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    onClick={() => {
                      setCcOpen(true)
                      setTimeout(() => {
                        ccRef.current.focus()
                      }, 300)
                    }}
                  >
                    Cc
                  </button>
                )}
                {!bccOpen && (
                  <button
                    type="button"
                    className="ml-3 rounded-md px-1.5 py-0.5 font-medium uppercase text-blue-500 hover:bg-blue-100 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    onClick={() => {
                      setBccOpen(true)
                      setTimeout(() => {
                        bccRef.current.focus()
                      }, 300)
                    }}
                  >
                    Bcc
                  </button>
                )}
              </div>
            </AddressField>

            {ccOpen && (
              <AddressField
                ref={ccRef}
                name="cc"
                data={transaction.contacts}
                emails={data.cc}
                error={errors.cc}
                onInput={() => clearError('cc')}
                onUpdate={(addresses) => setData({ ...data, cc: addresses })}
              />
            )}

            {bccOpen && (
              <AddressField
                ref={bccRef}
                name="bcc"
                data={transaction.contacts}
                emails={data.bcc}
                error={errors.bcc}
                onInput={() => clearError('bcc')}
                onUpdate={(addresses) => setData({ ...data, bcc: addresses })}
              />
            )}
          </div>

          {record.invalid_shortcodes.length > 0 && (
            <div className="mx-auto max-w-xl">
              <Alert
                className="!mb-0"
                type="notice"
                heading="The following information, used in this template, is not available in this transaction:"
                subtext={
                  <ul className="mx-auto max-w-xs list-disc space-y-1">
                    {record.invalid_shortcodes.map((shortcode, index) => (
                      <li className="capitalize" key={index}>
                        {shortcode.replaceAll('-', ' ')}
                      </li>
                    ))}
                  </ul>
                }
              />
            </div>
          )}

          <div className="px-6">
            <TextInput
              label="Subject"
              name="subject"
              error={errors.subject}
              placeholder="Subject"
              value={data.subject}
              onChange={(value) => setData({ ...data, subject: value })}
              required
            />
          </div>

          <div className="px-6">
            <label className="block text-sm font-medium uppercase text-gray-500 sm:hidden">
              Body <span className="pl-1 text-red-600">*</span>
            </label>
            <RichTextEditor theme="checklist" value={content} height="overflow-y-scroll" onChange={(value) => setContent(value)} />
            {errors.body && <div className="mt-1 text-red-600">{errors.body}</div>}
          </div>

          <div className="flex flex-wrap items-center gap-4 px-6" ref={attachmentRef}>
            {data.requiredFiles.map((requiredFile, index) =>
              transaction.allFiles
                .filter((file) => file.file_type_id == requiredFile.id && !file.not_applicable)
                .map((file) => (
                  <div className="inline-flex items-center justify-between gap-4 bg-green-100 px-4 py-2" key={file.id}>
                    <div className="font-semibold">
                      {file.full_filename}.{file.extension}
                      <span className="ml-1 text-gray-600">({getReadableFileSizeString(file.size)})</span>
                    </div>
                    <div className="flex items-center gap-3 leading-none">
                      <Button theme="clean-icon-sm" onClick={() => window.open(route('transactions.files.show', file.id), '_blank')}>
                        <i className="fas fa-external-link text-lg leading-none text-gray-700"></i>
                      </Button>

                      <i className="fas fa-check mr-1 text-xl leading-none text-green-600"></i>

                      <button
                        type="button"
                        className="flex h-5 items-center justify-center rounded px-1 outline-none hover:bg-green-500 hover:text-white focus:bg-green-100 focus:text-blue-500 focus:ring-2 focus:ring-inset focus:ring-blue-500"
                        onClick={() => removeRequiredFile(index)}
                      >
                        <i className="far fa-times text-lg"></i>
                      </button>
                    </div>
                  </div>
                )),
            )}

            {data.attachments.map((file, index) => (
              <div className="inline-flex items-center justify-between gap-4 bg-gray-100 px-3 py-2" key={index}>
                <div className="font-semibold">
                  {file.name}
                  <span className="ml-1 text-gray-600">({getReadableFileSizeString(file.size)})</span>
                </div>
                <div>
                  <button
                    type="button"
                    className="flex h-5 items-center justify-center rounded px-1 outline-none hover:bg-green-500 hover:text-white focus:bg-green-100 focus:text-blue-500 focus:ring-2 focus:ring-inset focus:ring-blue-500"
                    onClick={() => removeAttachment(index)}
                  >
                    <i className="far fa-times text-lg"></i>
                  </button>
                </div>
              </div>
            ))}
          </div>
        </form>
      )}
    </Dialog>
  )
}
