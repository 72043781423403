import classNames from 'classnames'

export default ({ heading, subHeading, action }) => (
  <div
    className={classNames(
      'flex gap-4 rounded-md border-2 border-orange-300 border-opacity-75 bg-yellow-100 bg-opacity-75 px-4 py-2 font-medium',
      { 'items-center': !subHeading },
    )}
  >
    <i className="far fa-info-circle text-2xl text-orange-500"></i>{' '}
    <div className="flex-1">
      {heading && <div className="font-bold leading-snug">{heading}</div>}
      {subHeading && <div className="font-normal">{subHeading}</div>}
    </div>
    {action}
  </div>
)
