import { Fragment, useState } from 'react'
import Button from '@/Shared/Button'
import Filters from './Filters'
import SearchBar from '@/Shared/SearchBar'

export default ({ onCreate }) => {
  const [open, setOpen] = useState(false)

  return (
    <Fragment>
      <SearchBar label="Service Relation Requests" onShowFilters={() => setOpen(true)} placeholder="Search requests" />

      <Filters open={open} onClosed={() => setOpen(false)} />
    </Fragment>
  )
}
