import { useContext, useEffect, useRef, useState } from 'react'
import { AccordionContext } from '@/Shared/Accordion'
import Button from '@/Shared/Button'
import TextInput from '@/Shared/Forms/TextInput'

export default ({ user }) => {
  const focusRef = useRef()
  const { onSaved } = useContext(AccordionContext)
  const [data, setData] = useState({
    setting: 'email',
  })
  const [errors, setErrors] = useState({})

  useEffect(() => {
    focusRef.current.focus()
  }, [])

  const submit = (event) => {
    event.preventDefault()

    axios
      .post(route('api.users.update', user.id), { ...data, _method: 'PATCH' })
      .then((_) => onSaved())
      .catch((error) => {
        if (error.response != undefined) {
          const { status } = error.response
          const VALIDATION_ERROR = 422
          if (status == VALIDATION_ERROR) {
            setErrors(error.response.data.errors)
          }
        }
      })
  }

  return (
    <div>
      <p>
        An email will be sent to you at the new email address containing an activation link. Once the activation link is clicked and
        verified, your account will be updated and the new email address will also become your new login.
      </p>

      <form className="mx-auto mt-4 max-w-lg" onSubmit={submit}>
        <TextInput
          ref={focusRef}
          label="Email"
          name="email"
          error={errors.email}
          value={data.email}
          onChange={(value) => setData({ ...data, email: value })}
          required
        />

        <TextInput
          label="Current Password"
          name="password"
          type="password"
          error={errors.password}
          value={data.password}
          onChange={(value) => setData({ ...data, password: value })}
          required
        />

        <div className="flex justify-end">
          <Button type="submit" theme="solid">
            Save
          </Button>
        </div>
      </form>
    </div>
  )
}
