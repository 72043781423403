import classNames from 'classnames'
import Button from '@/Shared/Button'
import { usePage } from '@inertiajs/react'
import { router } from '@inertiajs/react'

const RequiredRole = ({ field, completed, optional, onEditing }) => {
  return (
    <li className="flex">
      <button
        className={classNames(
          'flex flex-1 items-center justify-between p-4',
          completed ? 'bg-gray-100' : 'bg-white hover:bg-gray-100 focus:relative focus:rounded-md focus:ring-2 focus:ring-primary-500',
        )}
        key={field}
        onClick={() => onEditing()}
      >
        <div className="flex items-center gap-3">
          <i
            className={classNames('w-6 flex-shrink-0 text-2xl leading-none', {
              'far fa-check text-[#2cb833]': !optional && completed,
              'far fa-ban text-gray-400': optional,
              'far fa-square text-gray-400': !optional && !completed,
            })}
            aria-hidden="true"
          ></i>
          <span
            className={classNames('text-base', {
              'text-gray-600 text-opacity-70 line-through': completed,
              'font-semibold text-black': !completed,
            })}
          >
            {field}
          </span>
        </div>

        {optional && (
          <span className="inline-block whitespace-nowrap rounded bg-gray-300/75 px-2.5 py-1.5 text-sm leading-none text-gray-600">
            Optional
          </span>
        )}
      </button>
    </li>
  )
}

export default ({ contact, disabled, inactive, missing, role, onEditing, onSearching }) => {
  const { transaction } = usePage().props
  const requiredContactFields = [
    { value: 'first_name', label: 'First Name' },
    { value: 'last_name', label: 'Last Name' },
    { value: 'company', label: 'Company' },
    { value: 'phone', label: 'Phone' },
    { value: 'email', label: 'Email' },
    { value: 'address', label: 'Address' },
  ]

  const getRoleCompletedPct = () => {
    return (
      ((requiredContactFields.filter((r) => !missing.find((mr) => r.label == mr)).length / requiredContactFields.length) * 100).toFixed(0) +
      '%'
    )
  }

  const onNotApplicable = () => {
    let inactive_roles = transaction.inactive_roles || []
    inactive_roles = inactive_roles.concat(role.value)

    router.post(route('transactions.update', transaction.id), {
      _method: 'put',
      inactive_roles: inactive_roles,
    })
  }

  return (
    <div className={classNames('mx-auto flex justify-center gap-3', contact ? 'max-w-lg' : 'max-w-2xl')}>
      {contact ? (
        <div className="flex-1 space-y-4 rounded-md border-2 border-red-600 p-6 shadow-md">
          <div className="text-center text-xl text-red-600">
            Complete the required information for
            <br />
            <span className="font-bold">
              {contact.full_name} ({role.label})
            </span>
          </div>

          <div className="w-full rounded-full bg-red-100">
            <div
              className="flex h-7 items-center justify-center rounded-l-full rounded-r-full bg-red-500 leading-none"
              style={{ width: getRoleCompletedPct() }}
            >
              <span className="text-base font-medium text-white">{getRoleCompletedPct()}</span>
            </div>
          </div>

          <ul role="list" className="divide-y divide-gray-200">
            {requiredContactFields.map((field) => (
              <RequiredRole
                field={field.label}
                completed={!missing.find((mf) => mf == field.label)}
                optional={!missing.find((mf) => mf == field.label) && !Boolean((contact.contact || contact)[field.value])}
                key={field.value}
                onEditing={() => onEditing(contact.contact || contact, field.label)}
              />
            ))}
          </ul>
        </div>
      ) : (
        <div
          className={
            inactive
              ? 'flex items-center gap-2 rounded-md border border-gray-400 bg-gray-100 px-4 py-1.5 font-medium text-gray-500'
              : 'flex flex-col items-center gap-1.5'
          }
        >
          {inactive && <span>Currently Not Applicable</span>}

          <Button theme={inactive ? 'border' : 'solid'} onClick={() => onSearching({ role: role.value })} disabled={disabled}>
            <i className="fas fa-user-plus mr-2 text-lg"></i>
            Add {role.label}
          </Button>
        </div>
      )}

      {!contact && !inactive && role.optional && (
        <div className="flex items-center space-x-3">
          <div className="flex h-10 items-center">
            <span className="font-bold">or</span>
          </div>

          <Button theme="border" onClick={() => onNotApplicable()}>
            Not Applicable
          </Button>
        </div>
      )}
    </div>
  )
}
