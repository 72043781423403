import { Fragment, useEffect, useState } from 'react'
import Button from '@/Shared/Button'
import Checklist from '@/Shared/Checklists/Index'
import classNames from 'classnames'
import RequestReviewDialog from '@/Pages/Transactions/RequestReviewDialog'
import Section from './Section'
import Radio from '../Forms/Radio'

const AvailableSection = ({ section, onViewSection }) => {
  const availableSteps = section.steps.filter((step) => !step.completed_at)

  return (
    <button
      className="min-h-20 inline-flex w-full items-center border-b border-gray-300 bg-gray-50 px-8 py-4 active:bg-gray-200"
      onClick={() => onViewSection(section)}
    >
      <div className="flex-1 space-y-0.5 text-left text-lg leading-snug">
        <div className="font-semibold text-gray-900 ">{section.name}</div>

        {availableSteps.length > 0 ? (
          <div className="inline-block whitespace-nowrap rounded bg-gray-200 px-2.5 py-1.5 text-sm leading-none text-gray-800 md:hidden">
            <span className="font-semibold">{availableSteps.length}</span> steps remaining
          </div>
        ) : (
          <span className="inline-block whitespace-nowrap rounded bg-green-50 px-2.5 py-1.5 text-sm font-semibold uppercase leading-none text-green-800">
            <span>All Steps Completed</span>
          </span>
        )}
      </div>
      <i className="fal fa-angle-right text-2xl font-medium"></i>
    </button>
  )
}

export default ({ open, transaction, onBack, onClose }) => {
  const [activeSection, setActiveSection] = useState(null)
  const [beforeRequestingReview, setBeforeRequestingReview] = useState(false)
  const [showCompleted, setShowCompleted] = useState(false)
  const [displayView, setDisplayView] = useState('sections')
  const availableSections = transaction.checklist.sections.filter(
    (section) => showCompleted || section.steps.find((step) => !step.completed_at),
  )

  useEffect(() => {
    if (activeSection) {
      setActiveSection(transaction.checklist.sections.find((section) => section.id == activeSection.id))
    }
  }, [transaction])

  const handleReviewRequested = () => {
    setBeforeRequestingReview(true)
  }

  const handleClosed = () => {
    setActiveSection(null)
    onClose()
  }

  return (
    <Fragment>
      <div
        className={classNames(
          'fixed inset-y-0 right-0 z-20 h-full w-full overflow-y-auto bg-gray-100 transition duration-300 ease-in-out',
          open ? 'translate-x-0' : 'translate-x-full',
        )}
      >
        <div className="sticky top-0 z-20 shadow-md">
          <div className="bg-blue-700 py-2.5 pl-4 pr-3 text-white sm:pl-6">
            <div className="flex items-center justify-between">
              <button className="inline-flex h-10 items-center leading-none text-white outline-none" type="button" onClick={onBack}>
                <span className="sr-only">Back</span>
                <i className="far fa-chevron-left mr-3 text-xl leading-none"></i>
                <span className="font-medium">Menu</span>
              </button>

              <div className="ml-3 flex h-10 items-center">
                <Button theme="clean" onClick={() => setShowCompleted(!showCompleted)}>
                  <div className="flex items-center gap-2.5 text-white">
                    <i className={classNames('far', showCompleted ? 'fa-eye' : 'fa-eye-slash')}></i>
                    <span>{showCompleted ? 'Hide' : 'Show'} Completed</span>
                  </div>
                </Button>

                <Button theme="toolbar-icon" onClick={() => handleClosed()}>
                  <span className="sr-only">Close</span>
                  <i className="fal fa-times text-2xl leading-none text-opacity-80"></i>
                </Button>
              </div>
            </div>
          </div>

          <div className="bg-white px-3 py-3 sm:px-6">
            <div className="text-center text-xl">
              <span>{transaction.name}</span>
            </div>

            <div className="text-center text-2xl font-semibold">
              <span className="text-gray-900">{transaction.type} Checklist</span>
            </div>
          </div>
        </div>

        <div>
          <div className="flex justify-center space-x-4 bg-gray-200 px-8 py-4">
            <Radio
              id="display_view_sections"
              name="display_view"
              label="Section View"
              value={displayView === 'sections'}
              onChange={() => setDisplayView('sections')}
            />
            <Radio
              id="display_view_checklist"
              name="display_view"
              label="Full Checklist View"
              value={displayView === 'checklist'}
              onChange={() => setDisplayView('checklist')}
            />
          </div>

          {displayView === 'sections' && availableSections.length > 0 ? (
            availableSections.map((section) => (
              <AvailableSection section={section} onViewSection={(section) => setActiveSection(section)} key={section.id} />
            ))
          ) : (
            <Checklist transaction={transaction} completed={showCompleted} onReviewRequested={handleReviewRequested} />
          )}
        </div>
      </div>

      <Section
        section={activeSection}
        transaction={transaction}
        showCompleted={showCompleted}
        onBack={() => setActiveSection(null)}
        onClose={() => handleClosed()}
        onShowCompleted={(completed) => setShowCompleted(completed)}
      />

      <RequestReviewDialog
        open={beforeRequestingReview}
        onCanceled={() => setBeforeRequestingReview(false)}
        onRequested={() => onClose()}
      />
    </Fragment>
  )
}
