import Avatar from '@/Shared/Avatar'
import Button from '@/Shared/Button'
import { Tooltip } from '@/Shared/Tooltip'
import { router } from '@inertiajs/react'
import { usePage } from '@inertiajs/react'

const Contact = ({ contact, dismissable, onDismiss }) => {
  return (
    <div className="relative">
      {dismissable && (
        <div className="absolute right-0 top-0">
          <Tooltip label="Dismiss" placement="bottom">
            <Button theme="clean-icon" onClick={() => onDismiss()}>
              <span className="sr-only">Close</span>
              <i className="fal fa-times text-2xl leading-none text-opacity-80"></i>
            </Button>
          </Tooltip>
        </div>
      )}

      <button
        type="button"
        className="flex w-full items-start justify-center gap-3 rounded-md px-4 py-3 text-left hover:bg-gray-100 focus:ring-2 focus:ring-blue-500"
        onClick={() => router.visit(route('contacts.show', contact.id))}
      >
        <div className="flex flex-col justify-center gap-1">
          <Avatar contact={contact} height="h-14" width="w-14" />

          {contact.following && (
            <div className="mt-0.5 inline-flex items-center whitespace-nowrap rounded-full bg-purple-100 px-2.5 py-1.5 text-sm font-medium uppercase leading-none text-purple-800">
              <span>PRO</span>
            </div>
          )}
        </div>

        <div className="mr-8 flex-1 truncate leading-snug text-gray-600 sm:mb-0">
          <div className="overflow-hidden text-ellipsis font-semibold">{contact.full_name}</div>
          {contact.industry && <div className="overflow-hidden text-ellipsis">{contact.industry}</div>}
          {contact.company && <div className="overflow-hidden text-ellipsis">{contact.company}</div>}
          {contact.phone && <div className="overflow-hidden text-ellipsis">{contact.phone}</div>}
          {contact.email && <div className="overflow-hidden text-ellipsis">{contact.email}</div>}
        </div>
      </button>
    </div>
  )
}

export default () => {
  const { contacts, duplicates_count } = usePage().props

  const dismissContact = (contact, similarContact) => {
    router.post(route('contacts.dismiss'), {
      id: contact.id,
      followable_id: similarContact.id,
    })
  }

  const dismissAll = (contact_id) => {
    router.post(
      route('contacts.dismissAllDuplicates'),
      contact_id && {
        contact_id: contact_id,
      },
    )
  }

  const merge = (contact_id) => {
    router.post(
      route('contacts.mergeDuplicates'),
      contact_id && {
        contact_id: contact_id,
      },
    )
  }

  return (
    <div className="mx-auto flex max-w-4xl">
      {duplicates_count == 0 ? (
        <div className="flex h-[calc(100vh-200px)] flex-grow flex-col items-center justify-center p-4 text-center sm:px-6">
          <span className="mb-8">
            <svg className="w-40" viewBox="0 0 91.07 120.42">
              <path
                className="fill-gray-200"
                d="M42.62,24A117.22,117.22,0,0,1,61.23,36.52a51.42,51.42,0,0,1,17.3,26.39C82.29,43.57,71.68,26.5,91.07,0,66,5.25,54.05,12.84,42.62,24Z"
              />
              <path
                className="fill-gray-300"
                d="M58.37,40.23C42.65,27.88,30,20.35,0,18.77,23.86,42.62,14.9,61.46,22.61,80.54a28.2,28.2,0,0,0,34.6,16.84c2.84,8,3.61,16.45,1.18,21.29,0,3.31,5.8,1.38,8.56-1.38,2.39-2.39-2-14.89-6.31-21.26A27.4,27.4,0,0,0,73.25,83.3C79,71.87,72.57,51.41,58.37,40.23Z"
              />
              <path
                className="fill-white"
                d="M37.17,60.6C33,44.7,26.17,33.69,25.69,34c.5-.27,11.81,8.34,16,24.23s11,26.91,11.47,26.64C52.61,85.1,41.31,76.5,37.17,60.6Z"
              />
            </svg>
          </span>
          <span className="mb-5 text-3xl font-medium text-gray-600">
            Good work!
            <br className="sm:hidden" /> No new suggestions.
          </span>
        </div>
      ) : (
        <div className="space-y-4">
          <div className="flex flex-col items-center justify-between gap-4 border-b border-gray-300 py-3 sm:flex-row">
            <span className="text-lg font-medium text-gray-900 sm:text-base sm:text-gray-500">Merge duplicates ({duplicates_count})</span>

            <div className="space-x-4">
              <Button type="submit" theme="link" onClick={() => dismissAll()}>
                Dismiss All
              </Button>

              <Button type="submit" theme="solid" onClick={() => merge()}>
                Merge All
              </Button>
            </div>
          </div>

          {contacts.map((contact) => (
            <div className="rounded-md border border-gray-300 bg-white" key={contact.id}>
              <div className="grid items-start gap-4 p-4 sm:grid-cols-2 sm:px-6">
                <Contact contact={contact} />

                {contact.similar.map((similarContact) => (
                  <Contact
                    contact={similarContact}
                    dismissable
                    onDismiss={() => dismissContact(contact, similarContact)}
                    key={similarContact.id}
                  />
                ))}
              </div>

              <div className="flex items-center justify-end gap-4 border-t border-gray-300 p-4 sm:px-6">
                <Button type="submit" theme="link" onClick={() => dismissAll(contact.id)}>
                  Dismiss
                </Button>

                <Button type="submit" theme="link" onClick={() => merge(contact.id)}>
                  Merge
                </Button>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
