import { useRef } from 'react'

export default ({ children, onScroll, ...props }) => {
  const scrollableRef = useRef()

  const scrollIntoView = () => {
    onScroll(scrollableRef)
  }

  return (
    <div ref={scrollableRef} {...props}>
      {children({ scrollIntoView })}
    </div>
  )
}
