import Dialog from '@/Shared/Dialog/Index'
import { usePage } from '@inertiajs/react'

export default function PausedComplianceReviewDialog({ open, onCanceled }) {
  const { transaction } = usePage().props
  const declinedFiles = transaction.allFiles.filter((file) => file.declined)
  const notApplicableFiles = transaction.allFiles.filter((file) => file.review_not_applicable)

  return (
    <Dialog show={open} title="Compliance Review Report" cancelText="Close" size="sm:max-w-4xl" onClosed={() => onCanceled()}>
      <div className="space-y-6 font-normal">
        {(declinedFiles.length > 0 || notApplicableFiles.length > 0) && (
          <p className="text-center text-lg font-medium text-red-600">
            The following issues must be resolved before you can request another Compliance Review.
          </p>
        )}

        {declinedFiles.length > 0 || notApplicableFiles.length > 0 ? (
          <div className="space-y-2">
            <div>
              <p className="text-lg font-medium">File Issues:</p>

              <ul className="list-inside divide-y divide-gray-400/50 overflow-hidden rounded-md border border-gray-400/50 text-black">
                {declinedFiles.length > 0 && (
                  <li className="flex items-center gap-3 bg-white px-3 py-1.5">
                    <span className="flex w-6 items-center justify-center">
                      <i className="fas fa-exclamation-circle text-xl leading-none text-yellow-700"></i>
                    </span>

                    <span>
                      <span className="font-bold">{declinedFiles.length}</span> file{declinedFiles.length > 1 ? 's' : ''}{' '}
                      <span className="rounded bg-yellow-100 px-1.5 py-1 text-sm font-bold uppercase leading-none text-yellow-700">
                        <i className="fas fa-file mr-1.5"></i>
                        <span>{declinedFiles.length > 1 ? 'require' : 'requires'} attention</span>
                      </span>
                      .
                    </span>
                  </li>
                )}
                {notApplicableFiles.length > 0 && (
                  <li className="flex items-center gap-3 bg-white px-3 py-1.5">
                    <span className="flex w-6 items-center justify-center">
                      <i className="far fa-times text-2xl leading-none text-red-600"></i>
                    </span>

                    <span>
                      <span className="font-bold">{notApplicableFiles.length}</span> file
                      {notApplicableFiles.length > 1 ? 's have' : ' has'} been marked{' '}
                      <span className="rounded bg-red-500 px-1.5 py-1 text-sm font-bold uppercase leading-none text-white">
                        <i className="fas fa-file-slash mr-1.5"></i>
                        <span>Not Applicable</span>
                      </span>{' '}
                      and must be deleted.
                    </span>
                  </li>
                )}
              </ul>
            </div>

            <div className="flex items-center gap-1.5">
              <span className="far fa-question-circle text-blue-500"></span>
              <span className="text-gray-700">Please review the Closing folder in the Files section for details.</span>
            </div>
          </div>
        ) : (
          <p className="text-gray-500">All file issues have been resolved.</p>
        )}

        <div>
          <p className="text-lg font-medium">Additional Reviewer Comments:</p>

          {transaction.paused_comments ? (
            <div className="space-y-2">
              <p className="text-gray-700">
                Please review following comments for possible additional considerations before requesting a new Compliance Review.
              </p>

              <blockquote className="!bg-gray-200/60">
                <div dangerouslySetInnerHTML={{ __html: transaction.paused_comments }}></div>
              </blockquote>
            </div>
          ) : (
            <span className="text-gray-500">No additional comments.</span>
          )}
        </div>
      </div>
    </Dialog>
  )
}
