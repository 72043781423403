import { useEffect, useRef } from 'react'
import Button from '@/Shared/Button'
import Checkbox from '@/Shared/Forms/Checkbox'
import Select from '@/Shared/Forms/Select'
import SlideOver from '@/Shared/SlideOver'
import TextInput from '@/Shared/Forms/TextInput'
import { useForm, usePage } from '@inertiajs/react'

export default ({ record, open, onClosed }) => {
  let focusRef = useRef()
  const { availableRoles, lsn, roles: allRoles } = usePage().props
  const roles = allRoles.filter((role) => availableRoles.find((r) => r.value == role.value) || role.value == record?.position_id)
  const form = useForm()
  const { data, errors, clearErrors, setData, patch, post, reset } = form

  useEffect(() => {
    if (open) {
      let invitation = record || {}

      setData({
        id: invitation.id,
        first_name: capitalize(invitation.first_name),
        last_name: capitalize(invitation.last_name),
        email: invitation.email,
        position_id: invitation.position_id,
        resend: false,
      })
    } else {
      clearErrors()
    }
  }, [open])

  const capitalize = (value) => value?.replace(/(^\w{1})|(\s+\w{1})/g, (c) => c.toUpperCase())

  const submit = (e) => {
    e.preventDefault()

    if (data.id) {
      patch(route('lsns.invitations.update', { lsn: lsn.id, invitation: data.id }), {
        onSuccess: (_) => {
          reset()
          onClosed(true)
        },
      })
    } else {
      post(route('lsns.invitations.store', lsn.id), {
        onSuccess: (_) => {
          reset()
          onClosed()
        },
      })
    }
  }

  return (
    <SlideOver
      focusRef={focusRef}
      footerActions={
        <Button type="submit" theme="solid" form="member-invite-form">
          {data.id ? 'Save Changes' : 'Continue'}
        </Button>
      }
      show={open}
      size="max-w-xl"
      title={data.id ? 'Edit' : 'Invite'}
      subTitle={data.id ? 'Invitation' : 'New PRO'}
      onClosed={onClosed}
      aboveMessages
    >
      <form id="member-invite-form" className="space-y-6 pb-4" onSubmit={submit}>
        <div>
          <TextInput
            ref={focusRef}
            label="First Name"
            name="first_name"
            value={data.first_name}
            error={errors.first_name}
            onChange={(value) => setData({ ...data, first_name: value })}
            onBlur={() => setData({ ...data, first_name: capitalize(data.first_name) })}
          />

          <TextInput
            label="Last Name"
            name="last_name"
            value={data.last_name}
            error={errors.last_name}
            onChange={(value) => setData({ ...data, last_name: value })}
            onBlur={() => setData({ ...data, last_name: capitalize(data.last_name) })}
          />

          <TextInput
            label="Email"
            name="email"
            value={data.email}
            error={errors.email}
            onChange={(value) => setData({ ...data, email: value })}
            required
          />

          <Select
            name="position_id"
            label="Management Role (optional)"
            options={roles}
            placeholder="None"
            value={data.position_id}
            onChange={(selected) => setData({ ...data, position_id: selected?.value })}
          />

          {data.id && (
            <div className="mb-4">
              <Checkbox
                name="resend"
                label="Resend Invitation"
                value={data.resend}
                onChange={(selected) => setData({ ...data, resend: selected })}
              />
            </div>
          )}
        </div>
      </form>
    </SlideOver>
  )
}
