import classNames from 'classnames'

const Avatar = ({ contact, height, width, textSize }) => {
  const getText = () => {
    if (Boolean(contact.first_name) || Boolean(contact.last_name))
      return contact.first_name.substring(0, 1).toUpperCase() + contact.last_name.substring(0, 1).toUpperCase()

    if (Boolean(contact.name))
      return contact.name.split(' ').slice(0, -1).join(' ').substring(0, 1) + contact.name.split(' ').slice(-1).join(' ').substring(0, 1)

    if (Boolean(contact.label))
      return contact.label.split(' ').slice(0, -1).join(' ').substring(0, 1) + contact.label.split(' ').slice(-1).join(' ').substring(0, 1)

    if (Boolean(contact.company)) return contact.company.substring(0, 1).toUpperCase()

    return ''
  }

  return (
    <div className={classNames('flex flex-shrink-0 overflow-hidden rounded-full shadow-md ring-1 ring-gray-300', height, width)}>
      {contact.avatar ? (
        <img className={classNames(height, width)} src={contact.avatar} alt="" />
      ) : (
        <span
          className={classNames('flex flex-1 items-center justify-center bg-gray-200 font-medium text-gray-500', height, width, textSize)}
        >
          {contact.initials || getText()}
        </span>
      )}
    </div>
  )
}

Avatar.defaultProps = {
  height: 'lg:w-[125px]',
  width: 'w-[150px]',
  textSize: 'text-lg',
}

export default Avatar
