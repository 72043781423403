import { Fragment, useEffect, useState } from 'react'
import axios from '@/apis/axios'
import classNames from 'classnames'
import { router } from '@inertiajs/react'
import { useForm, usePage } from '@inertiajs/react'
import EmailTemplateEdit from './Edit'
import DataTableHeader from './DataTableHeader'
import Main from '@/Shared/Layouts/Main'
import Pagination from '@/Shared/Pagination'
import SearchBar from './SearchBar'

const AdminEmailTemplateIndex = () => {
  const { templates } = usePage().props
  const { data: records, paginator } = templates
  const [dialogOpen, setDialogOpen] = useState(false)
  const { data, setData, delete: destroy } = useForm({})

  useEffect(() => {
    setDialogOpen(Boolean(data.id))
  }, [data])

  const onClosed = () => {
    setData({})
    setDialogOpen(false)
  }

  const onRowClicked = (id) => {
    axios.get(route('admin.email-templates.show', id)).then((response) => {
      setData(response.data)
    })
  }

  const onDelete = () => {
    destroy(route('admin.email-templates.destroy', data.id), {
      onSuccess: () => {
        setData({})
      },
    })
  }

  const onRestoring = () => {
    axios
      .patch(route('admin.email-templates.update', data.id), {
        ...data,
        deleted_at: null,
        _method: 'patch',
      })
      .then((_) => {
        setData({})
        router.reload({ only: ['templates', 'flash'] })
      })
  }

  return (
    <div>
      <div className="flex flex-wrap items-stretch justify-between bg-white py-4 px-4 sm:px-6">
        <SearchBar onCreate={() => setDialogOpen(true)} />
      </div>

      <div className="m-4 bg-white shadow sm:m-6 sm:rounded">
        <div className="flex flex-col">
          <div className="overflow-x-auto">
            <div className="inline-block min-w-full align-middle">
              <div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead>
                    <DataTableHeader />
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {records.map((template) => (
                      <tr className="group hover:bg-gray-100" key={template.id}>
                        <td className="cursor-pointer space-y-1 py-3 pl-6" onClick={() => onRowClicked(template.id)}>
                          <div className="font-medium text-blue-500">{template.name}</div>

                          <div className="flex flex-wrap items-center gap-1.5 sm:hidden">
                            <span className="inline-block rounded border border-gray-300 bg-gray-200 bg-opacity-75 px-3 py-1.5 text-sm font-medium uppercase leading-none text-gray-700 shadow-md">
                              <span>{template.category}</span>
                            </span>

                            {template.states.length > 0 &&
                              template.states.map((state) => (
                                <span
                                  className="inline-block rounded border border-gray-300 bg-gray-200 bg-opacity-75 px-3 py-1.5 text-sm font-medium uppercase leading-none text-gray-700 shadow-md"
                                  key={state.id}
                                >
                                  {state.state}
                                </span>
                              ))}

                            <span
                              className={classNames(
                                Boolean(template.deleted_at)
                                  ? 'bg-red-100 text-red-700'
                                  : {
                                      'bg-green-50 text-green-700': Boolean(template.active),
                                      'bg-gray-200 text-gray-600': !Boolean(template.active),
                                    },
                                'inline-flex items-center whitespace-nowrap rounded-full px-3 py-1.5 text-sm font-medium uppercase leading-none',
                              )}
                            >
                              {Boolean(template.deleted_at) ? (
                                <span>Deleted</span>
                              ) : Boolean(template.active) ? (
                                <span>Active</span>
                              ) : (
                                <span>Inactive</span>
                              )}
                            </span>
                          </div>
                        </td>

                        <td
                          className="hidden cursor-pointer whitespace-nowrap px-4 py-3 sm:table-cell sm:px-6"
                          onClick={() => onRowClicked(template.id)}
                        >
                          <span>{template.category}</span>
                        </td>

                        <td
                          className="hidden cursor-pointer whitespace-nowrap px-4 py-3 sm:table-cell sm:px-6"
                          onClick={() => onRowClicked(template.id)}
                        >
                          <span>
                            {template.states.reduce((carry, state) => carry + (carry ? `, ${state.state}` : state.state), '') || 'All'}
                          </span>
                        </td>
                        <td
                          className="hidden cursor-pointer whitespace-nowrap px-4 py-3 text-center text-gray-700 sm:px-6 md:table-cell"
                          onClick={() => onRowClicked(template.id)}
                        >
                          <div
                            className={classNames(
                              Boolean(template.deleted_at)
                                ? 'bg-red-100 text-red-700'
                                : {
                                    'bg-green-50 text-green-700': Boolean(template.active),
                                    'bg-gray-200 text-gray-600': !Boolean(template.active),
                                  },
                              'inline-flex items-center whitespace-nowrap rounded-full px-3 py-1.5 text-sm font-medium uppercase leading-none',
                            )}
                          >
                            {Boolean(template.deleted_at) ? (
                              <span>Deleted</span>
                            ) : (
                              <Fragment>
                                {Boolean(template.active) && <span>Active</span>}
                                {!Boolean(template.active) && <span>Inactive</span>}
                              </Fragment>
                            )}
                          </div>
                        </td>
                        <td
                          className="hidden cursor-pointer px-4 py-3 text-center text-gray-700 sm:px-6 lg:table-cell"
                          onClick={() => onRowClicked(template.id)}
                        >
                          {template.updated_at}
                        </td>
                        <td
                          className="w-16 cursor-pointer whitespace-nowrap py-3 pr-6 text-right font-medium"
                          onClick={() => onRowClicked(template.id)}
                        >
                          <div className="flex items-center justify-end">
                            <i className="far fa-pen text-lg text-gray-500 group-hover:font-bold group-hover:text-blue-500"></i>
                          </div>
                        </td>
                      </tr>
                    ))}

                    {records.length == 0 && (
                      <tr>
                        <td colSpan="8" className="py-12 px-4 sm:px-6">
                          <div className="flex flex-col text-center">
                            <span className="text-2xl font-semibold text-red-600">No email templates match your current filters.</span>
                            <span className="text-lg font-medium text-gray-700">
                              Expecting to see results? Check your filters to make sure you're not excluding results.
                            </span>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          {records.length > 0 && <Pagination paginator={paginator} />}
        </div>
      </div>

      <EmailTemplateEdit
        open={dialogOpen}
        record={data}
        onClosed={() => onClosed()}
        onDeleting={() => onDelete()}
        onRestoring={() => onRestoring()}
      />
    </div>
  )
}

// Persistent layout
// Docs: https://inertiajs.com/pages#persistent-layouts
AdminEmailTemplateIndex.layout = (page) => <Main title="Email Templates" children={page} />

export default AdminEmailTemplateIndex
