import React, { Fragment, useState } from 'react'
import classNames from 'classnames'
import { Dialog as HeadlessUIDialog, Transition } from '@headlessui/react'

import Button from '@/Shared/Button'

const Dialog = React.forwardRef(
  (
    {
      background,
      cancelText,
      children,
      focusRef,
      focusCancelButton,
      footerActions,
      footerActionsLeft,
      fullHeight,
      headerActions,
      headerBackgroundColor,
      hideFooter,
      isLoading,
      padding,
      position,
      show,
      size,
      subTitle,
      title,
      onClosed,
    },
    ref,
  ) => {
    let [open, setOpen] = useState(show || false)

    React.useEffect(() => setOpen(show), [show])
    React.useEffect(() => {
      if (show && !open) {
        onClosed()
      }
    }, [open])

    return (
      <Transition.Root show={open} as={Fragment}>
        <HeadlessUIDialog
          as="div"
          className={classNames('fixed inset-0 z-30 h-full w-full xl:z-40')}
          initialFocus={focusRef}
          onClose={setOpen}
        >
          <div
            className={classNames('relative flex h-full flex-col transition-all duration-300', {
              'sm:justify-center': !position,
              'sm:justify-start': position === 'top',
            })}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <HeadlessUIDialog.Overlay className="fixed inset-0 bg-gray-900 bg-opacity-50 transition-opacity" />
            </Transition.Child>

            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div
                className={classNames(
                  'relative',
                  size,
                  {
                    'h-full xl:p-12': fullHeight,
                    'lg:py-12': !fullHeight,
                  },
                  'mx-auto w-full overflow-hidden text-left md:p-2',
                )}
              >
                {/* Reversed so autofocus isn't the close button */}
                <div className="flex h-full flex-col-reverse overflow-hidden rounded-lg shadow-xl transition-all duration-300">
                  {!hideFooter && (
                    <div
                      className={classNames(
                        'relative z-50 flex items-center border-t border-gray-200 bg-white pt-5 sm:p-6',
                        footerActionsLeft ? 'justify-between' : 'justify-end',
                        window.navigator.standalone ? 'px-6 pb-8' : 'px-4 pb-4',
                      )}
                    >
                      {footerActionsLeft && <div>{footerActionsLeft}</div>}
                      <div className="flex flex-row-reverse justify-end">
                        {footerActions}
                        <Button ref={focusCancelButton && focusRef} theme="outline" disabled={isLoading} onClick={() => setOpen(false)}>
                          {cancelText || 'Cancel'}
                        </Button>
                      </div>
                    </div>
                  )}

                  <div ref={ref} className="relative flex h-full flex-col overflow-y-auto bg-white">
                    <div className={classNames(padding, background, fullHeight && ' h-full')}>{children}</div>
                  </div>

                  <div
                    className={classNames(
                      'flex items-center py-2.5 pl-4 pr-3 text-white sm:pl-6',
                      headerBackgroundColor || 'bg-primary-500',
                    )}
                  >
                    <div className="flex flex-1 items-center justify-between overflow-hidden px-0.5">
                      {(title || subTitle) && (
                        <HeadlessUIDialog.Title as="h3" className="truncate text-xl font-medium">
                          {title}
                          {subTitle && <div className="text-sm font-normal uppercase text-gray-100">{subTitle}</div>}
                        </HeadlessUIDialog.Title>
                      )}

                      <div className="ml-3 flex h-10 items-center">
                        {headerActions}
                        <Button theme="toolbar-icon" disabled={isLoading} onClick={() => setOpen(false)}>
                          <span className="sr-only">Close</span>
                          <i className="fal fa-times text-2xl leading-none text-opacity-80"></i>
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </HeadlessUIDialog>
      </Transition.Root>
    )
  },
)

Dialog.defaultProps = {
  background: 'bg-white',
  padding: 'px-4 pt-5 pb-4 sm:p-6',
  size: 'sm:max-w-lg',
}

export default Dialog
