import axios from 'axios'
import { toast } from 'react-toastify'

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status && !isNetworkError(error)) {
      const { status } = error.response

      if ([401, 403, 404, 419, 429, 503].indexOf(status) >= 0) {
        window.location.href = `/${status}`
      }

      if ([400, 500].indexOf(status) >= 0) {
        toast.error(
          error.response.data.error || (
            <div className="mx-4 flex flex-col gap-6 sm:flex-row sm:items-center">
              <p className="w-full">
                <b>An unexpected error has occurred.</b>
                <br />A report of this event has been sent to our support team.
              </p>
              <div className="w-full flex-1 text-center">
                <button
                  type="button"
                  className="group relative flex h-14 items-center gap-2 whitespace-nowrap rounded-md bg-white px-6 font-bold leading-tight text-red-600 shadow-sm outline-none transition-all duration-150 ease-in-out hover:bg-red-100 focus:ring-2 focus:ring-black"
                  onClick={(e) => {
                    e.stopPropagation()
                    FreshworksWidget('open')
                  }}
                >
                  <i className="far fa-question-circle text-xl"></i>
                  Request Support
                </button>
              </div>
            </div>
          ),
          {
            toastId: 'unexpected-error',
            position: 'top-center',
            autoClose: false,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
            theme: 'colored',
          },
        )
      }
    }
    return Promise.reject(error)
  },
)

function isNetworkError(error) {
  // Check for network error conditions
  return error.message === 'Network Error'
}

export default axios
