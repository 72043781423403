import { useContext, useEffect, useRef, useState } from 'react'
import Button from '@/Shared/Button'
import { BlockUIContext } from '@/Shared/BlockUI/BlockUIContext'
import Checkbox from '@/Shared/Forms/Checkbox'
import Heading from '@/Shared/Forms/Heading'
import { router } from '@inertiajs/react'
import { usePage } from '@inertiajs/react'
import Select from '@/Shared/Forms/Select'
import SlideOver from '@/Shared/SlideOver'
import TextInput from '@/Shared/Forms/TextInput'

export default ({ open, onClosed }) => {
  let focusRef = useRef(null)
  const { user } = usePage().props.auth
  const canFilterAssociates = user.roles.some((r) => ['Admin', 'hr_controller', 'Associate'].indexOf(r) >= 0)
  const { filters, services } = usePage().props
  const { setLoading } = useContext(BlockUIContext)
  const defaultFilters = {
    licenseStates: [],
    location: '',
    radius: '5',
    services: [],
  }
  const defaultAssociateFilters = {
    associatesOnly: false,
    realtorAssociations: [],
    mlsAssociations: [],
  }
  const [form, setForm] = useState(canFilterAssociates ? { ...defaultFilters, ...defaultAssociateFilters } : defaultFilters)
  const mlsAssociations = [
    { value: 'MLS PIN', label: 'MLS PIN' },
    { value: 'Cape Cod Multiple Listing Services', label: 'Cape Cod Multiple Listing Services' },
    { value: 'Statewide MLS', label: 'Statewide MLS' },
    { value: 'PrimeMLS', label: 'PrimeMLS' },
  ]
  const radiuses = [
    { value: '5', label: 'within 5 miles' },
    { value: '10', label: 'within 10 miles' },
    { value: '15', label: 'within 15 miles' },
    { value: '25', label: 'within 25 miles' },
    { value: '50', label: 'within 50 miles' },
    { value: '100', label: 'within 100 miles' },
  ]
  const realtorAssociations = [
    { value: 'Cape Cod & Islands Assoc. of Realtors (CCIAOR)', label: 'Cape Cod & Islands Assoc. of Realtors (CCIAOR)' },
    { value: 'Greater Boston Real Estate Board', label: 'Greater Boston Real Estate Board' },
    { value: 'Greater Providence Board of Realtors', label: 'Greater Providence Board of Realtors' },
    { value: 'Massachusetts Association of Realtors', label: 'Massachusetts Association of Realtors' },
    { value: 'New Hampshire Association of Realtors', label: 'New Hampshire Association of Realtors' },
    { value: 'Northern RI Board of Realtors', label: 'Northern RI Board of Realtors' },
    { value: 'Realtor Association of Central Massachusetts', label: 'Realtor Association of Central Massachusetts' },
    { value: 'Realtor Association of Southeastern MA (RASEM)', label: 'Realtor Association of Southeastern MA (RASEM)' },
    { value: 'Rhode Island Association of Realtors', label: 'Rhode Island Association of Realtors' },
    { value: 'Seacoast Board of Realtors', label: 'Seacoast Board of Realtors' },
  ]
  const states = [
    { value: 'MA', label: 'Massachusetts' },
    { value: 'NH', label: 'New Hampshire' },
    { value: 'RI', label: 'Rhode Island' },
  ]

  useEffect(() => {
    if (filters) {
      let f = {
        licenseStates: filters.licenseStates || [],
        location: filters.location || '',
        radius: filters.radius || '5',
        services: filters.services || [],
      }

      if (canFilterAssociates) {
        f = {
          ...f,
          associatesOnly: filters.associatesOnly || false,
          realtorAssociations: filters.realtorAssociations || [],
          mlsAssociations: filters.mlsAssociations || [],
        }
      }

      setForm(f)
    }
  }, [filters])

  const onSubmit = (e) => {
    e.preventDefault()

    setLoading(true)

    let data = { ...form }
    if (form.location != filters.location || form.radius != filters.radius) {
      data = { ...data, sortBy: 'distance', direction: 'asc' }
    }

    const values = filters ? { ...filters, ...data } : { ...data }
    const query = encodeURIComponent(JSON.stringify(values))

    router.get(
      route(route().current(), { filters: query }),
      {},
      {
        preserveState: true,
        onFinish: () => {
          onClosed()
          setLoading(false)
        },
      },
    )
  }

  return (
    <SlideOver
      focusRef={focusRef}
      footerActions={
        <Button theme="solid" type="submit" form="contactFilters">
          Apply
        </Button>
      }
      show={open}
      title="Filters"
      onClosed={onClosed}
      aboveMessages
    >
      <form id="contactFilters" className="flex h-full flex-col" onSubmit={onSubmit}>
        {!route().current().startsWith('admin') && canFilterAssociates && (
          <div className="mb-4 flex justify-center">
            <Checkbox
              name="associatesOnly"
              label="Show Only Better Living Associates"
              value={form.associatesOnly}
              onChange={(checked) => setForm({ ...form, associatesOnly: checked, services: [] })}
            />
          </div>
        )}

        {!form.associatesOnly && (
          <Select
            ref={focusRef}
            label="Services"
            name="services"
            options={services}
            placeholder="Any Service"
            value={services.filter((service) => form.services.find((s) => s === service.value))}
            onChange={(selected) => setForm({ ...form, services: selected ? Array.from(selected, (s) => s.value) : [] })}
            multiple
          />
        )}

        {canFilterAssociates && form.associatesOnly && (
          <div className="mb-4 rounded-md border-2 border-dashed border-blue-300 bg-blue-50 px-5 py-3">
            <Heading>Better Living Associate Filters</Heading>

            <Select
              label="Realtor Associations"
              name="realtorAssociations"
              options={realtorAssociations}
              placeholder="Any Realtor Association"
              value={realtorAssociations.filter((association) => form.realtorAssociations.find((a) => a === association.value))}
              onChange={(selected) => setForm({ ...form, realtorAssociations: selected ? Array.from(selected, (s) => s.value) : [] })}
              multiple
            />

            <Select
              label="MLS Systems"
              name="mlsAssociations"
              options={mlsAssociations}
              placeholder="Any MLS System"
              value={mlsAssociations.filter((association) => form.mlsAssociations.find((a) => a === association.value))}
              onChange={(selected) => setForm({ ...form, mlsAssociations: selected ? Array.from(selected, (s) => s.value) : [] })}
              multiple
            />

            <Select
              label="Has a License in"
              name="licenseStates"
              options={states}
              placeholder="Any State"
              value={states.filter((state) => form.licenseStates.find((s) => s === state.value))}
              onChange={(selected) => setForm({ ...form, licenseStates: selected ? Array.from(selected, (s) => s.value) : [] })}
              multiple
            />
          </div>
        )}

        <TextInput
          label="Near"
          name="location"
          value={form.location}
          placeholder="i.e. Foxboro, MA 02035"
          onChange={(value) => setForm({ ...form, location: value })}
        />

        <Select
          isClearable={false}
          label="Distance from Above"
          name="location"
          options={radiuses}
          value={form.radius}
          onChange={(selected) => setForm({ ...form, radius: selected.value })}
        />
      </form>
    </SlideOver>
  )
}
