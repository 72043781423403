import { useEffect, useState } from 'react'
import ActiveFilters from '@/Shared/SlideOvers/ActiveFilters'
import Avatar from '@/Shared/Avatar'
import Button from '@/Shared/Button'
import classNames from 'classnames'
import { router } from '@inertiajs/react'
import { useForm, usePage } from '@inertiajs/react'
import DataTableHeader from './DataTableHeader'
import Create from './Create'
import Main from '@/Shared/Layouts/Main'
import Pagination from '@/Shared/Pagination'
import SearchBar from './SearchBar'
import { Tooltip } from '@/Shared/Tooltip'

const AdminAssociatesIndex = () => {
  const { associates } = usePage().props
  const { data: records, paginator } = associates
  const [open, setOpen] = useState(false)
  const { data, setData } = useForm({})

  useEffect(() => {
    setOpen(Boolean(data.id))
  }, [data])

  const onClosed = () => {
    setOpen(false)

    setTimeout(() => {
      setData({})
    }, 400)
  }

  const onImpersonateUser = (user_id) => {
    router.post(route('admin.users.impersonate', user_id))
  }

  const onRowClicked = (id) => {
    router.visit(route('admin.associates.show', id))
  }

  return (
    <div className="space-y-4">
      <div className="flex flex-wrap items-stretch justify-between bg-white px-6 py-4">
        <SearchBar onCreate={() => setOpen(true)} />
      </div>

      <div className="space-y-1.5 px-4 sm:px-6">
        <ActiveFilters />

        <div className="bg-white shadow sm:rounded">
          <div className="flex flex-col">
            <div className="overflow-x-auto">
              <div className="inline-block min-w-full align-middle">
                <div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead>
                      <DataTableHeader />
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {records.map((associate) => (
                        <tr
                          className={classNames('group hover:bg-gray-100', associate.status === 'inactive' ? 'bg-gray-100 grayscale' : '')}
                          key={associate.id}
                        >
                          <td
                            className={classNames(
                              'w-10 whitespace-nowrap py-3 pl-4 pr-4 sm:pl-6',
                              associate.status === 'inactive' ? 'opacity-40' : '',
                            )}
                          >
                            <div className="flex h-10 w-10 flex-shrink-0 items-center justify-center">
                              <div>
                                <Avatar
                                  contact={{ avatar: associate.avatar, name: associate.name }}
                                  height="h-10"
                                  width="w-10"
                                  textSize="text-base"
                                />
                              </div>
                            </div>
                          </td>

                          <td
                            className={classNames(
                              'cursor-pointer py-3 pr-4 sm:pr-6',
                              associate.status === 'inactive' ? 'italic opacity-40' : '',
                            )}
                            onClick={() => onRowClicked(associate.id)}
                          >
                            <div className="mb-2 sm:mb-0 sm:flex-1">
                              <div className="font-medium text-primary-500">{associate.name}</div>
                              {associate.pal && (
                                <div className="hidden flex-wrap items-center gap-1.5 lg:flex">
                                  <span className="inline-block whitespace-nowrap rounded bg-gray-200 px-2.5 py-1.5 text-sm leading-none text-gray-800">
                                    <span>PAL</span>
                                  </span>
                                </div>
                              )}
                              {associate.phone && <div className="leading-tight text-gray-700 xl:hidden">{associate.phone}</div>}
                              {associate.email && <div className="leading-tight text-gray-700 2xl:hidden">{associate.email}</div>}
                            </div>
                          </td>

                          <td
                            className={classNames(
                              'hidden cursor-pointer whitespace-nowrap px-4 py-3 text-gray-700 sm:px-6 xl:table-cell',
                              associate.status === 'inactive' ? 'italic opacity-40' : '',
                            )}
                            onClick={() => onRowClicked(associate.id)}
                          >
                            {associate.phone}
                          </td>

                          <td
                            className={classNames(
                              'hidden cursor-pointer whitespace-nowrap px-4 py-3 text-gray-700 sm:px-6 2xl:table-cell',
                              associate.status === 'inactive' ? 'italic opacity-40' : '',
                            )}
                            onClick={() => onRowClicked(associate.id)}
                          >
                            {associate.email}
                          </td>

                          <td
                            className={classNames(
                              'hidden cursor-pointer whitespace-nowrap px-4 py-3 text-gray-700 md:px-6 lg:table-cell',
                              associate.status === 'inactive' ? 'italic opacity-40' : '',
                            )}
                            onClick={() => onRowClicked(associate.id)}
                          >
                            <div>{associate.location}</div>
                            {associate.distance != null && <span className="text-primary-500">{associate.distance} miles away</span>}
                          </td>

                          <td
                            className="hidden cursor-pointer whitespace-nowrap px-6 py-3 text-gray-700 md:table-cell"
                            onClick={() => onRowClicked(associate.id)}
                          >
                            <div
                              className={classNames(
                                {
                                  active: 'bg-green-50 text-green-700',
                                  inactive: 'bg-gray-200 text-gray-600',
                                  terminated: 'bg-red-100 text-red-700',
                                }[associate.status],
                                'inline-flex items-center whitespace-nowrap rounded-full p-1 px-2.5 text-sm font-medium uppercase leading-none',
                              )}
                            >
                              {associate.status}
                            </div>
                          </td>

                          <td className="cursor-pointer whitespace-nowrap py-3 pr-6 text-right font-medium">
                            <div className="flex items-center justify-end gap-4">
                              <Tooltip label={`Impersonate ${associate.name}`} placement="bottom">
                                <Button theme="icon" disabled={associate.deleted_at} onClick={() => onImpersonateUser(associate.user_id)}>
                                  <i className="fad fa-user-secret text-xl leading-none text-gray-600"></i>
                                </Button>
                              </Tooltip>

                              <div className="flex items-center justify-end" onClick={() => onRowClicked(associate.id)}>
                                <i className="far fa-angle-right text-2xl leading-none text-gray-700"></i>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}

                      {records.length == 0 && (
                        <tr>
                          <td colSpan="8" className="px-6 py-12">
                            <div className="flex flex-col text-center">
                              <span className="text-2xl font-semibold text-red-600">No associates match your current filters.</span>
                              <span className="text-lg font-medium text-gray-700">
                                Expecting to see results? Check your filters to make sure you're not excluding results.
                              </span>
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            {records.length > 0 && <Pagination paginator={paginator} />}
          </div>
        </div>
      </div>

      <Create open={open} record={data} onClosed={() => onClosed()} />
    </div>
  )
}

// Persistent layout
// Docs: https://inertiajs.com/pages#persistent-layouts
AdminAssociatesIndex.layout = (page) => <Main title="Associates" children={page} />

export default AdminAssociatesIndex
