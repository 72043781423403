import { Fragment, useContext, useEffect, useRef, useState } from 'react'
import { NotificationConsumer, NotificationContext } from '@/Shared/Notifications/NotificationContext'
import NotificationActions from '@/Shared/Notifications/Actions'
import NotificationGroup from '@/Shared/Notifications/Group'
import SlideOver from '@/Shared/SlideOver'

export default () => {
  let focusRef = useRef()
  const { filters, isFetching, notifications, setFilters } = useContext(NotificationContext)
  const [seen, setSeen] = useState([])
  const [unseen, setUnseen] = useState([])

  useEffect(() => {
    setSeen(notifications.filter((notification) => notification.seen == 1))
    setUnseen(notifications.filter((notification) => notification.seen == 0))
  }, [notifications])

  const handleFilterChanged = (item, isChecked) => {
    let updated = filters.map((f) => {
      return f.name === item ? { ...f, filtering: isChecked } : f
    })
    setFilters(updated)
  }

  return (
    <NotificationConsumer>
      {({ handleInfiniteScroll, notifications, open, setOpen }) => (
        <SlideOver
          focusRef={focusRef}
          show={open}
          size="max-w-xl"
          title="Your Notifications"
          onClosed={() => setOpen(false)}
          aboveMessages
          hideFooter
          hidePadding
        >
          <div className="relative z-10 flex items-center border-b border-gray-300 px-4 sm:px-6">
            <NotificationActions filters={filters} onClose={() => setOpen(false)} onFilterChanged={handleFilterChanged} />
          </div>
          {notifications.length > 0 && (
            <div className="flex-grow overflow-y-auto" onScroll={handleInfiniteScroll}>
              {unseen.length > 0 ? (
                <Fragment>
                  <NotificationGroup type="New" items={unseen} />
                  <NotificationGroup type="Earlier" items={seen} />
                </Fragment>
              ) : (
                <NotificationGroup items={seen} />
              )}
            </div>
          )}

          {notifications.length == 0 && (
            <div className="flex flex-grow flex-col items-center justify-center bg-gray-200 p-4 text-center sm:px-6">
              <span className="mb-8">
                <svg className="w-20" viewBox="0 0 91.07 120.42">
                  <path
                    fill="#babcbe"
                    d="M42.62,24A117.22,117.22,0,0,1,61.23,36.52a51.42,51.42,0,0,1,17.3,26.39C82.29,43.57,71.68,26.5,91.07,0,66,5.25,54.05,12.84,42.62,24Z"
                  />
                  <path
                    fill="6b6c6f"
                    d="M58.37,40.23C42.65,27.88,30,20.35,0,18.77,23.86,42.62,14.9,61.46,22.61,80.54a28.2,28.2,0,0,0,34.6,16.84c2.84,8,3.61,16.45,1.18,21.29,0,3.31,5.8,1.38,8.56-1.38,2.39-2.39-2-14.89-6.31-21.26A27.4,27.4,0,0,0,73.25,83.3C79,71.87,72.57,51.41,58.37,40.23Z"
                  />
                  <path
                    fill="#ffffff"
                    d="M37.17,60.6C33,44.7,26.17,33.69,25.69,34c.5-.27,11.81,8.34,16,24.23s11,26.91,11.47,26.64C52.61,85.1,41.31,76.5,37.17,60.6Z"
                  />
                </svg>
              </span>
              <span className="mb-5 text-xl font-medium">You don't have any notifications</span>
              <span className="mb-5">As soon as an action occurs that you need to be notified for, you'll find it here.</span>
              <span className="mb-5 font-medium">Expecting to see results? Check your filters.</span>
            </div>
          )}
          {isFetching && (
            <div className="flex justify-center border-t border-gray-300 bg-gray-500 px-4 py-3 sm:px-6">
              <span className="sr-only">Loading, please wait...</span>
              <img className="h-4" src="/images/layout/three-dots.svg" />
            </div>
          )}
        </SlideOver>
      )}
    </NotificationConsumer>
  )
}
