import { useState } from 'react'
import Checklist from './Checklist'
import Contacts from './Contacts'
import Notes from './Notes'
import Files from './Files'
import Forms from './Forms'
import Menu from './Menu'
import Payments from './Payments'
import Summary from './Summary'
import Timeline from './Timeline'

export default ({ children, transaction, onClosed }) => {
  const [tab, setTab] = useState('')

  const handleClose = () => {
    typeof onClosed === 'function' ? onClosed() : setTab('')
  }

  return (
    <div className="relative h-full bg-gray-100">
      <Menu transaction={transaction} onTabChange={(selected) => setTab(selected)}>
        {children}
      </Menu>
      <Summary open={tab === 'summary'} transaction={transaction} onClose={() => handleClose()} onBack={() => setTab('')} />
      <Checklist open={tab === 'checklist'} transaction={transaction} onClose={() => handleClose()} onBack={() => setTab('')} />
      <Contacts open={tab === 'contacts'} transaction={transaction} onClose={() => handleClose()} onBack={() => setTab('')} />
      <Notes open={tab === 'notes'} transaction={transaction} onClose={() => handleClose()} onBack={() => setTab('')} />
      <Files open={tab === 'files'} transaction={transaction} onClose={() => handleClose()} onBack={() => setTab('')} />
      <Forms open={tab === 'forms'} transaction={transaction} onClose={() => handleClose()} onBack={() => setTab('')} />
      <Payments open={tab === 'payments'} transaction={transaction} onClose={() => handleClose()} onBack={() => setTab('')} />
      <Timeline open={tab === 'timeline'} transaction={transaction} onClose={() => handleClose()} onBack={() => setTab('')} />
    </div>
  )
}
