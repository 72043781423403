import AccordionCard from '@/Shared/AccordionCard'
import AccordionItem from '@/Shared/AccordionItem'
import classNames from 'classnames'
import { usePage } from '@inertiajs/react'

export default ({ onClick }) => {
  const { contact } = usePage().props

  const groups = [
    { name: 'Associate', contacts: contact.associate.team?.filter((contact) => !contact.pal) },
    { name: 'PAL', contacts: contact.associate.team?.filter((contact) => contact.pal) },
  ]

  if (groups.filter((group) => group.contacts.length > 1).length == 0) return <></>

  const getTitle = () => {
    let count = groups.reduce((carry, group) => {
      return (carry += group.contacts ? group.contacts.length : 0)
    }, 0)

    return `${route().current() === 'my-card' ? 'My ' : ''}Team (${count})`
  }

  return (
    <AccordionCard title={getTitle()} collapsed>
      {groups.map(
        (group, index) =>
          group.contacts?.length > 0 && (
            <div className={classNames('space-y-4', { 'mt-4': index > 0 })} key={group.name}>
              {(group.name !== 'Associate' || groups.find((g) => g.name === 'PAL' && g.contacts.length > 0)) && (
                <div className="rounded-lg border border-blue-200 border-opacity-50 bg-blue-50 p-2 text-center font-medium uppercase">
                  {group.name}s
                </div>
              )}

              <div className="divide-y divide-dashed divide-gray-200 overflow-hidden rounded-md border border-gray-200">
                {group.contacts.map((contact) => (
                  <AccordionItem onClick={() => (onClick ? onClick(contact.id) : null)} key={contact.id}>
                    <div className="flex items-center justify-between space-x-3">
                      <div>
                        {contact.avatar ? (
                          <img className="h-10 w-10 rounded-full" src={contact.avatar} alt="" />
                        ) : (
                          <div className="flex h-10 w-10 items-center justify-center rounded-full bg-gray-200 text-gray-700">
                            <div className="text-base font-medium tracking-wide">{contact.initials}</div>
                          </div>
                        )}
                      </div>
                      <div className="flex flex-1 flex-col">
                        <span className="font-semibold text-blue-500">{contact.name}</span>
                        <span>{group.name}</span>
                      </div>
                    </div>
                  </AccordionItem>
                ))}
              </div>
            </div>
          ),
      )}
    </AccordionCard>
  )
}
