import { useContext, useState } from 'react'
import { AccordionContext } from '@/Shared/Accordion'
import Button from '@/Shared/Button'
import Checkbox from '@/Shared/Forms/Checkbox'
import RichTextEditor from '@/Shared/RichTextEditor/Index'
import { router } from '@inertiajs/react'

export default ({ user }) => {
  const { onSaved } = useContext(AccordionContext)
  const [data, setData] = useState({
    setting: 'preferences',
    email: {
      bcc_transaction_emails: user.settings.preferences?.email?.bcc_transaction_emails
        ? true
        : user.settings.preferences?.email?.bcc_transaction_emails,
    },
  })
  const [value, setValue] = useState(user.settings.preferences?.email?.signature ?? '')

  const submit = (event) => {
    event.preventDefault()

    let transformedData = { ...data, email: { ...data.email, signature: value } }

    axios.post(route('api.users.update', user.id), { ...transformedData, _method: 'PATCH' }).then((_) => {
      router.reload({
        onFinish: () => onSaved(),
      })
    })
  }

  return (
    <div>
      <form className="mt-4 space-y-8" onSubmit={submit}>
        <div>
          <p className="mb-3 ml-2">Appended at the end of all outgoing messages (replaces default signature).</p>

          <RichTextEditor theme="simple" height="min-h-[250px]" value={value} onChange={(value) => setValue(value)} />
        </div>

        <div>
          <Checkbox
            label="BCC me on Transaction emails"
            description={`All emails sent from Transactions will be automatically BCC'd to ${user.email}.`}
            name="bcc_transaction_emails"
            value={data.email.bcc_transaction_emails}
            onChange={(checked) => setData({ ...data, email: { ...data.email, bcc_transaction_emails: checked } })}
          />
        </div>

        <div className="flex justify-end">
          <Button type="submit" theme="solid">
            Save
          </Button>
        </div>
      </form>
    </div>
  )
}
