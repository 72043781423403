import { Fragment } from 'react'
import classNames from 'classnames'
import { Disclosure, Transition } from '@headlessui/react'
import { router } from '@inertiajs/react'
import { Tooltip } from '@/Shared/Tooltip'

export default function Tree({ hideHeader, list }) {
  return (
    <ul className="min-w-full divide-y divide-gray-200">
      {!hideHeader && (
        <li className="flex items-center justify-between bg-gray-100">
          <div
            scope="col"
            className="border-l border-gray-200 px-6 py-3 text-left text-sm font-medium uppercase tracking-wider text-gray-500"
          >
            <span>Name</span>
          </div>

          <div scope="col" className="relative px-6 py-3">
            <span className="sr-only">Edit</span>
          </div>
        </li>
      )}

      {list.map((checklist) => (
        <Disclosure as={Fragment} key={checklist.id}>
          {({ open }) => (
            <li
              className={classNames('group flex flex-wrap items-center justify-between hover:bg-gray-200', {
                'bg-white': hideHeader == undefined,
              })}
              onClick={() => router.visit(route('admin.checklists.show', checklist.id))}
            >
              <Disclosure.Button
                as="div"
                className="flex flex-1 cursor-pointer items-center whitespace-nowrap py-3 pl-6 font-medium uppercase text-gray-700"
              >
                {checklist.checklists && checklist.checklists.length > 0 ? (
                  <i className={`far text-gray-400 ${open ? 'fa-minus-square' : 'fa-plus-square'} pr-3 text-xl`}></i>
                ) : (
                  <i className="far fa-clipboard-list pr-3 text-xl text-gray-400"></i>
                )}
                <span>{checklist.name}</span>
              </Disclosure.Button>

              <div className="whitespace-nowrap px-3 text-right">
                {(hideHeader == undefined || !checklist.checklists) && (
                  <Tooltip label="Edit">
                    <button role="button" className="flex items-center justify-center rounded py-2 px-4 font-medium text-gray-600">
                      <i className="far fa-pen text-lg text-gray-500 group-hover:font-bold group-hover:text-blue-500"></i>
                    </button>
                  </Tooltip>
                )}
              </div>

              {checklist.checklists && checklist.checklists.length > 0 && (
                <Disclosure.Panel as={Fragment}>
                  <div className="w-full pl-6 group-hover:bg-gray-100">
                    <Transition
                      show={open}
                      enter="transition duration-100 ease-out"
                      enterFrom="scale-95 opacity-0"
                      enterTo="scale-100 opacity-100"
                      leave="transition duration-75 ease-out"
                      leaveFrom="scale-100 opacity-100"
                      leaveTo="scale-95 opacity-0"
                    >
                      <Tree list={checklist.checklists} hideHeader />
                    </Transition>
                  </div>
                </Disclosure.Panel>
              )}
            </li>
          )}
        </Disclosure>
      ))}
    </ul>
  )
}
