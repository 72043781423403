import classNames from 'classnames'
import PropertyListItem from '@/Shared/PropertyListItem'
import { usePage } from '@inertiajs/react'

const Wrapper = ({ children }) => <div className="border-b border-gray-200">{children}</div>

export default () => {
  const { lsn } = usePage().props
  const replacementDate = lsn.replace_leaders_at && new Date(lsn.replace_leaders_at)
  const isReplacementExpired = replacementDate != undefined && replacementDate <= new Date()
  const isReplacementExpiring =
    replacementDate != undefined && !isReplacementExpired && replacementDate.setDate(replacementDate.getDate() - 30) <= new Date()

  return (
    <Wrapper>
      {replacementDate != undefined && (
        <PropertyListItem
          className={classNames('bg-opacity-50', isReplacementExpired ? 'bg-red-100' : isReplacementExpiring ? 'bg-yellow-100' : '')}
          label={
            <span className={classNames(isReplacementExpired ? 'text-red-500' : isReplacementExpiring ? 'text-yellow-500' : '')}>
              Leadership Change Date
            </span>
          }
          value={
            <span
              className={classNames(
                isReplacementExpired ? 'font-medium text-red-600' : isReplacementExpiring ? 'font-medium text-yellow-600' : '',
              )}
            >
              {lsn.replace_leaders_at}
            </span>
          }
        />
      )}

      {lsn.published && (
        <PropertyListItem
          label="LSN Website Address"
          icon="fas fa-external-link text-xl"
          value={lsn.permalink || <span className="italic text-gray-400 text-opacity-80">Not specified</span>}
          onClick={() => window.open(lsn.permalink, '_blank')}
        />
      )}

      <PropertyListItem label="Extended Name" value={lsn.extended_name} />
      {lsn.manager && lsn.isAManager && <PropertyListItem label="Manager" value={lsn.manager?.name} primary />}

      <PropertyListItem
        label="Meeting Location & Times"
        icon="fas fa-map-location-dot text-lg text-gray-400"
        value={
          lsn.location ? (
            <div className="flex flex-col leading-snug">
              {lsn.meeting_times && <span className="font-medium">{lsn.meeting_times}</span>}
              {lsn.meeting_site_name && <span>{lsn.meeting_site_name}</span>}
              {lsn.meeting_street && <span>{lsn.meeting_street}</span>}
              <span>
                {lsn.meeting_city}
                {lsn.meeting_city && lsn.meeting_state ? ', ' : ''}
                {lsn.meeting_state}
                {lsn.meeting_state && lsn.meeting_postal_code ? ' ' : ''}
                {lsn.meeting_postal_code}
              </span>
            </div>
          ) : (
            <span className="italic text-gray-400 text-opacity-80">Not specified</span>
          )
        }
      />

      <PropertyListItem
        label="Summary"
        value={
          lsn.summary_display ? (
            <span className="ProseMirror leading-tight" dangerouslySetInnerHTML={{ __html: lsn.summary_display }}></span>
          ) : (
            <span className="italic text-gray-400 text-opacity-80">Not specified</span>
          )
        }
      />

      <PropertyListItem
        label="Copyright & LSN Website Disclaimer"
        value={
          lsn.disclaimer ? (
            <span className="ProseMirror leading-tight" dangerouslySetInnerHTML={{ __html: lsn.disclaimer }}></span>
          ) : (
            <span className="italic text-gray-400 text-opacity-80">Not specified</span>
          )
        }
      />

      {lsn.editable && lsn.updated_at != lsn.created_at && <PropertyListItem label="Last Updated" value={lsn.updated_at} />}
      {<PropertyListItem label="Date Added" value={lsn.created_at} />}
    </Wrapper>
  )
}
