import React from 'react'
import classNames from 'classnames'

export default ({ centered, description, disabled, label, name, value, vertical, onChange }) => {
  return (
    <div
      className={classNames('relative flex items-center', {
        'flex-col gap-y-2': vertical,
        'space-x-3': !vertical,
        'sm:items-start': !vertical && description,
        'justify-center': !vertical && centered,
      })}
    >
      <div className="flex h-8 items-center sm:h-6">
        <input
          id={name}
          aria-describedby={`${name}-description`}
          disabled={disabled}
          name={name}
          type="checkbox"
          className={classNames(
            'h-8 w-8 rounded border-2 sm:h-6 sm:w-6',
            { 'text-primary-500 focus:ring-primary-500': !disabled },
            disabled ? 'cursor-not-allowed border-gray-400 bg-gray-400 opacity-30' : value ? 'border-primary-500' : 'border-gray-400',
          )}
          checked={value || false}
          onChange={() => onChange(!value)}
        />
      </div>
      {label && (
        <label
          htmlFor={name}
          className={classNames('space-y-0.5 leading-none', {
            'mt-1 text-sm': vertical,
          })}
        >
          <span className="font-semibold uppercase leading-none text-gray-600">{label}</span>
          {description && (
            <div id={`${name}-description`} className="leading-snug text-gray-500">
              {description}
            </div>
          )}
        </label>
      )}
    </div>
  )
}
