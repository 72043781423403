import { useContext, useEffect, useRef, useState } from 'react'
import { AccordionContext } from '@/Shared/Accordion'
import Button from '@/Shared/Button'
import Select from '@/Shared/Forms/Select'
import Switch from '@/Shared/Forms/Switch'
import TextInput from '@/Shared/Forms/TextInput'
import { router } from '@inertiajs/react'

export default ({ user }) => {
  const focusRef = useRef()
  const { onSaved } = useContext(AccordionContext)

  const [data, setData] = useState({
    setting: 'address',
    show_street: user.show_street == undefined ? false : user.show_street,
    street: user.primary_address?.street || '',
    street2: user.primary_address?.street2 || '',
    city: user.primary_address?.city || '',
    state: user.primary_address?.state || '',
    postal_code: user.primary_address?.postal_code || '',
    country: 'United States',
  })
  const [errors, setErrors] = useState({})

  const states = [
    { value: 'AK', label: 'Alaska' },
    { value: 'AL', label: 'Alabama' },
    { value: 'AR', label: 'Arkansas' },
    { value: 'AS', label: 'American Samoa' },
    { value: 'AZ', label: 'Arizona' },
    { value: 'CA', label: 'California' },
    { value: 'CO', label: 'Colorado' },
    { value: 'CT', label: 'Connecticut' },
    { value: 'DC', label: 'District of Columbia' },
    { value: 'DE', label: 'Delaware' },
    { value: 'FL', label: 'Florida' },
    { value: 'GA', label: 'Georgia' },
    { value: 'GU', label: 'Guam' },
    { value: 'HI', label: 'Hawaii' },
    { value: 'IA', label: 'Iowa' },
    { value: 'ID', label: 'Idaho' },
    { value: 'IL', label: 'Illinois' },
    { value: 'IN', label: 'Indiana' },
    { value: 'KS', label: 'Kansas' },
    { value: 'KY', label: 'Kentucky' },
    { value: 'LA', label: 'Louisiana' },
    { value: 'MA', label: 'Massachusetts' },
    { value: 'MD', label: 'Maryland' },
    { value: 'ME', label: 'Maine' },
    { value: 'MI', label: 'Michigan' },
    { value: 'MN', label: 'Minnesota' },
    { value: 'MO', label: 'Missouri' },
    { value: 'MS', label: 'Mississippi' },
    { value: 'MT', label: 'Montana' },
    { value: 'NC', label: 'North Carolina' },
    { value: 'ND', label: 'North Dakota' },
    { value: 'NE', label: 'Nebraska' },
    { value: 'NH', label: 'New Hampshire' },
    { value: 'NJ', label: 'New Jersey' },
    { value: 'NM', label: 'New Mexico' },
    { value: 'NV', label: 'Nevada' },
    { value: 'NY', label: 'New York' },
    { value: 'OH', label: 'Ohio' },
    { value: 'OK', label: 'Oklahoma' },
    { value: 'OR', label: 'Oregon' },
    { value: 'PA', label: 'Pennsylvania' },
    { value: 'PR', label: 'Puerto Rico' },
    { value: 'RI', label: 'Rhode Island' },
    { value: 'SC', label: 'South Carolina' },
    { value: 'SD', label: 'South Dakota' },
    { value: 'TN', label: 'Tennessee' },
    { value: 'TX', label: 'Texas' },
    { value: 'UT', label: 'Utah' },
    { value: 'VA', label: 'Virginia' },
    { value: 'VI', label: 'Virgin Islands' },
    { value: 'VT', label: 'Vermont' },
    { value: 'WA', label: 'Washington' },
    { value: 'WI', label: 'Wisconsin' },
    { value: 'WV', label: 'West Virginia' },
    { value: 'WY', label: 'Wyoming' },
  ]

  useEffect(() => {
    focusRef.current.focus()
  }, [])

  const submit = (event) => {
    event.preventDefault()

    axios
      .post(route('api.users.update', user.id), { ...data, _method: 'PATCH' })
      .then((_) =>
        router.reload({
          onFinish: () => {
            onSaved()
          },
        }),
      )
      .catch((error) => {
        if (error.response != undefined) {
          const { status } = error.response
          const VALIDATION_ERROR = 422
          if (status == VALIDATION_ERROR) {
            setErrors(error.response.data.errors)
          }
        }
      })
  }

  return (
    <div>
      <form className="mx-auto mt-4 max-w-lg space-y-1.5" onSubmit={submit}>
        <div className="mb-4 flex justify-center">
          <Switch
            accessibleText="Include street on My PRO-Website"
            model={data.show_street}
            onChange={(enabled) => setData({ ...data, show_street: enabled })}
          >
            <span className="text-sm uppercase leading-none text-gray-600">Include street on My PRO-Website</span>
          </Switch>
        </div>

        <TextInput
          ref={focusRef}
          classes="mb-1"
          label="Street address"
          name="street"
          value={data.street ?? ''}
          error={errors.street}
          onChange={(value) => setData({ ...data, street: value })}
          required
        />

        <TextInput
          classes="mb-1"
          name="street2"
          value={data.street2 ?? ''}
          error={errors.street2}
          onChange={(value) => setData({ ...data, street2: value })}
        />

        <TextInput
          classes="mb-1"
          label="City"
          name="city"
          value={data.city ?? ''}
          error={errors.city}
          onChange={(value) => setData({ ...data, city: value })}
          required
        />

        <Select
          label="State"
          classes="mb-1"
          name="state"
          options={states}
          value={data.state}
          error={errors.state}
          isClearable={false}
          onChange={(selected) => setData({ ...data, state: selected && selected.value })}
          required
        />

        <TextInput
          classes="mb-1"
          label="ZIP code"
          name="postal_code"
          value={data.postal_code ?? ''}
          error={errors.postal_code}
          onChange={(value) => setData({ ...data, postal_code: value })}
          required
        />

        <div className="flex justify-end">
          <Button type="submit" theme="solid">
            Save
          </Button>
        </div>
      </form>
    </div>
  )
}
