import React, { useRef, useContext } from 'react'
import classNames from 'classnames'
import { DatepickerCtx, useDatepickerCtx } from './DatePickerContext'
import { useFloating, autoUpdate, offset, flip, shift } from '@floating-ui/react'
import { Tooltip } from '@/Shared/Tooltip'

const daysOfWeekNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

export const inputStyle = {
  paddingTop: '0.375rem',
  paddingBottom: '0.375rem',
}

interface DatePickerProps {
  date: Date
  disabled: boolean
  error: string
  hideErrorMessage: boolean
  hideYear: boolean
  label: string
  name: string
  placeholder: string
  required: boolean
  onChange: (date?: Date) => void
}

export const DatePicker: React.FC<DatePickerProps> = (props) => <RawDatePicker {...props}></RawDatePicker>

export const RawDatePicker: React.FC<DatePickerProps> = ({
  date,
  disabled,
  error,
  hideErrorMessage,
  hideYear,
  label,
  name,
  placeholder,
  required,
  onChange,
}) => {
  const { x, y, strategy, refs } = useFloating({
    placement: 'bottom-end',
    whileElementsMounted: autoUpdate,
    middleware: [offset(10), flip(), shift()],
  })

  const ctxValue = useDatepickerCtx(date, hideYear, onChange, refs.floating)

  return (
    <DatepickerCtx.Provider value={ctxValue}>
      <div className={classNames('relative', label && 'mb-4')}>
        {label && (
          <label
            htmlFor={name}
            className={classNames('mb-0.5 block text-sm font-medium uppercase', error ? 'text-red-600' : 'text-gray-500')}
          >
            {label}
            {required && <span className="pl-1 text-red-600">*</span>}
          </label>
        )}

        <div ref={refs.setReference} className="flex">
          <div className="relative flex h-11 flex-1 flex-col">
            <input
              id={name}
              className={classNames(
                'font-md h-11 flex-1 flex-grow rounded-l border border-gray-300 px-4 py-2 placeholder-gray-400 outline-none transition-all duration-150 ease-in-out',
                error
                  ? 'border-transparent ring-2 ring-red-500 hover:ring-red-400 focus:ring-red-700'
                  : disabled
                    ? 'cursor-not-allowed bg-gray-200'
                    : 'border border-gray-300 hover:border-gray-400 focus:border-transparent focus:ring-2 focus:ring-primary-500',
              )}
              type="text"
              placeholder={placeholder}
              style={inputStyle}
              onFocus={(e) => ctxValue.showCalendar()}
              value={formattedDate(date, ctxValue.isYearVisible) ?? ''}
              readOnly
              disabled={disabled}
            />

            {formattedDate(date, ctxValue.isYearVisible) && (
              <button
                type="button"
                className="absolute inset-y-0 right-1 m-1 rounded-lg bg-white px-2.5 text-gray-400 hover:text-gray-700"
                onClick={() => !disabled && onChange()}
              >
                <i className="far fa-times text-lg"></i>
              </button>
            )}
          </div>

          <Tooltip className="flex" label={!disabled && 'Calendar'} placement="left">
            <button
              type="button"
              className={classNames(
                'flex items-center rounded-r border-b border-r border-t px-3 text-sm font-semibold transition-all duration-150 ease-in-out',
                error ? 'bg-red-100 text-red-500 ring-2 ring-red-500' : 'border-gray-300 bg-gray-200 text-gray-500',
                disabled ? 'cursor-not-allowed' : '',
              )}
              onClick={(e) => !disabled && ctxValue.toggleCalendar()}
            >
              <i className="fas fa-calendar-alt text-xl"></i>
            </button>
          </Tooltip>
        </div>

        <div ref={refs.setFloating} className="z-50" style={{ position: strategy, top: y ?? 0, left: x ?? 0, width: 'max-content' }}>
          {ctxValue.isVisible && (
            <div className="rounded-lg border border-gray-300 bg-white p-4 shadow-xl" style={{ width: '20rem' }}>
              <Calendar />
            </div>
          )}
        </div>

        {error && !hideErrorMessage && <div className="mt-1 text-red-600" dangerouslySetInnerHTML={{ __html: error }}></div>}
      </div>
    </DatepickerCtx.Provider>
  )
}

interface CalendarProps {}

const Calendar: React.FC<CalendarProps> = () => {
  const { view } = useContext(DatepickerCtx)

  let selectionComponent = null
  switch (view) {
    case 'date':
      selectionComponent = <DateSelection />
      break
    case 'month':
      selectionComponent = <MonthSelection />
      break
    case 'year':
      selectionComponent = <YearSelection />
      break
  }

  return selectionComponent
}

/**
 * Date Selection Component
 * @param props
 */
const DateSelection: React.FC<{}> = (props) => {
  const {
    nextMonth,
    prevMonth,
    viewMonths,
    viewYears,
    selectDate,
    visible: { month, year },
    isCurrentDate,
    isSelectedDate,
    isYearVisible,
  } = useContext(DatepickerCtx)

  const dates = []

  for (let i = 0; i < beginningDayOfWeek(month, year); i++) {
    dates.push(<div key={`emptybefore${i}`}></div>)
  }

  for (let i = 1; i <= daysInMonth(month, year); i++) {
    dates.push(
      <button
        type="button"
        key={`day${i}`}
        className={classNames(
          'mb-1 flex h-8 w-8 items-center justify-center rounded-lg px-1 leading-loose transition duration-100 ease-in-out',
          {
            'bg-primary-500 text-white': isSelectedDate(i),
            'text-gray-700 hover:bg-gray-100 hover:text-primary-500': !isSelectedDate(i),
            'relative bg-primary-200 bg-opacity-50 text-primary-700': !isSelectedDate(i) && isCurrentDate(i),
          },
          'focus:outline-none focus:ring-2 focus:ring-primary-500',
        )}
        onClick={() => selectDate(i)}
        style={{ textAlign: 'center' }}
      >
        {i}
        {!isSelectedDate(i) && isCurrentDate(i) && (
          <span
            style={{
              content: '',
              display: 'inline-block',
              borderColor: '#ebedf2 transparent #1a398d',
              borderStyle: 'solid',
              borderWidth: '0 0 7px 7px',
              position: 'absolute',
              bottom: '4px',
              right: '4px',
            }}
          ></span>
        )}
      </button>,
    )
  }

  return (
    <React.Fragment>
      <div className="mb-2 flex items-center justify-between">
        <div className="flex flex-grow">
          <button
            type="button"
            className="flex items-center justify-center rounded px-2 py-1 text-xl font-bold text-gray-800 hover:bg-gray-100 hover:text-primary-500 focus:outline-none focus:ring-2 focus:ring-primary-500"
            onClick={(e) => viewMonths()}
          >
            {monthNames[month]}
          </button>

          {isYearVisible && (
            <button
              type="button"
              className="ml-1 flex items-center justify-center rounded px-2 py-1 text-lg font-normal text-gray-600 hover:bg-gray-100 hover:text-primary-500 focus:outline-none focus:ring-2 focus:ring-primary-500"
              onClick={(e) => viewYears()}
            >
              {year}
            </button>
          )}
        </div>

        <div className="flex items-center">
          <ChevronButton direction="left" onClick={() => prevMonth()} />
          <ChevronButton direction="right" onClick={() => nextMonth()} />
        </div>
      </div>

      <div
        className="text-gray-800"
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr',
          gridTemplateRows: '2rem auto',
          alignItems: 'stretch',
        }}
      >
        {daysOfWeekNames.map((day) => (
          <div key={(200 + day).toString()} className="p-1 text-center text-sm font-medium text-gray-700" style={{ width: 'calc(100/7)' }}>
            {day.substring(0, 3)}
          </div>
        ))}

        <div className="h-3" style={{ gridColumn: '1/8' }}></div>

        {dates}
      </div>
    </React.Fragment>
  )
}

/**
 * Month Selection Component
 * @param props
 */
const MonthSelection: React.FC<{}> = () => {
  const { viewYears, selectMonth, nextYear, prevYear, visible, isSelectedMonth, isYearVisible } = useContext(DatepickerCtx)

  return (
    <React.Fragment>
      {isYearVisible && (
        <div className="mb-2 flex items-center justify-between">
          <div className="flex-grow">
            <button
              type="button"
              className="flex items-center justify-center rounded px-2 py-1 text-xl font-bold text-gray-800 hover:bg-gray-100 hover:text-primary-500 focus:outline-none focus:ring-2 focus:ring-primary-500"
              onClick={() => viewYears()}
            >
              {visible.year}
            </button>
          </div>
          <div className="flex items-center">
            <ChevronButton direction="left" onClick={() => prevYear()} />
            <ChevronButton direction="right" onClick={() => nextYear()} />
          </div>
        </div>
      )}

      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 1fr 1fr',
          alignItems: 'stretch',
        }}
      >
        {monthNames.map((month, index) => (
          <CalButton
            className={classNames({
              'bg-primary-500 text-white': isSelectedMonth(index),
              'text-gray-800 hover:bg-gray-100 hover:text-primary-500': !isSelectedMonth(index),
            })}
            onClick={() => selectMonth(index)}
            key={month}
          >
            {month.substring(0, 3)}
          </CalButton>
        ))}
      </div>
    </React.Fragment>
  )
}

/**
 * Year Selection Component
 * @param props
 */
const YearSelection: React.FC<{
  className?: string
}> = (props) => {
  const {
    selectYear,
    prevDecade,
    nextDecade,
    visible: { year },
    isSelectedYear,
  } = useContext(DatepickerCtx)

  let years = []
  let [minYear, maxYear] = [year - 6, year + 6]

  for (let i = minYear; i < maxYear; i++) {
    years.push(
      <CalButton
        className={classNames({
          'bg-primary-500 text-white': isSelectedYear(i),
          'text-gray-800 hover:bg-gray-100 hover:text-primary-500': !isSelectedYear(i),
        })}
        onClick={(e) => selectYear(i)}
        key={i}
      >
        {i}
      </CalButton>,
    )
  }

  return (
    <React.Fragment>
      <div className="mb-2 flex items-center justify-between">
        <div className="flex-grow">
          <button
            type="button"
            className={classNames(
              'flex items-center justify-center rounded px-2 py-1 text-xl font-bold hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-primary-500',
              {
                'text-gray-800': !props.className,
              },
            )}
          >
            {`${minYear} - ${maxYear - 1}`}
          </button>
        </div>
        <div className="flex items-center">
          <ChevronButton direction="left" onClick={(e) => prevDecade()} />
          <ChevronButton direction="right" onClick={(e) => nextDecade()} />
        </div>
      </div>

      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 1fr 1fr',
          alignItems: 'stretch',
        }}
      >
        {years}
      </div>
    </React.Fragment>
  )
}

const ChevronButton: React.FC<{
  direction?: 'right' | 'left'
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}> = (props) => {
  return (
    <button
      type="button"
      className={classNames(
        'inline-flex cursor-pointer rounded-lg p-1 text-gray-400 transition duration-100 ease-in-out hover:bg-gray-100 hover:text-primary-500 focus:outline-none focus:ring-2 focus:ring-primary-500',
        {
          'ml-2': props.direction === 'right',
        },
      )}
      onClick={props.onClick}
    >
      <svg className="inline-flex h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        {props.direction === 'left' ? (
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7" />
        ) : (
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7" />
        )}
      </svg>
    </button>
  )
}

const buttonClassName = 'rounded py-3 px-2 mb-2 flex items-center justify-center focus:ring-2 focus:ring-primary-500 focus:outline-none'

const CalButton: React.FC<{
  className?: string
  style?: React.StyleHTMLAttributes<HTMLButtonElement>
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}> = (props) => {
  let children = props.children

  return (
    <button type="button" className={`${buttonClassName} ${props.className || ''}`} style={props.style} onClick={props.onClick}>
      {children}
    </button>
  )
}

/**
 * Util functions
 */
/**
 * For formatting date
 * @param date
 */
function formattedDate(date: Date, isYearVisible: boolean): string {
  return (
    date &&
    (isYearVisible
      ? `${monthNames[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`
      : `${monthNames[date.getMonth()]} ${date.getDate()}`)
  )
}

/**
 * Beginning of Day of Week of a Month
 * @param date
 */
function beginningDayOfWeek(m: number, y: number): number {
  return new Date(y, m, 1).getDay()
}

/**
 * Days in month
 */
function daysInMonth(month: number, year: number) {
  switch (month) {
    case 0:
    case 2:
    case 4:
    case 6:
    case 7:
    case 9:
    case 11:
      return 31
    case 1:
      return isLeapYear(year) ? 29 : 28
    default:
      return 30
  }
}

/**
 * Is Leap Year
 * @param year
 */
function isLeapYear(year: number): boolean {
  return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0
}
