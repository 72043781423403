import { useContext } from 'react'
import Accordion from '@/Shared/Accordion'
import classNames from 'classnames'
import BannerForm from './Forms/Banner'
import LogoForm from './Forms/Logo'
import PageContentForm from './Forms/PageContent'
import SearchResultsDescriptionForm from './Forms/SearchResultsDescription'
import { SettingContext } from './SettingContext'
import VisibilityForm from './Forms/Visibility'
import { usePage } from '@inertiajs/react'

export default () => {
  const { user } = usePage().props
  const { setting, setSetting } = useContext(SettingContext)

  return (
    <div className="divide-y divide-gray-200 lg:col-span-9">
      <div className="px-4 py-6 sm:p-6 lg:pb-8">
        <div>
          <h2 className="flex-1 text-xl font-medium leading-6 text-gray-900">My PRO-Website</h2>
        </div>

        <div className="mt-6">
          <Accordion
            id="website"
            onIndexChanged={(index) => {
              setSetting(
                {
                  1: user.type === 'Advisor' ? 'logo' : 'banner',
                  2: user.type === 'Advisor' ? 'banner' : 'synopsis',
                  3: user.type === 'Advisor' ? 'synopsis' : 'biography',
                  4: user.type === 'Advisor' ? 'biography' : '',
                }[index],
              )
            }}
          >
            <Accordion.Items>
              <Accordion.Item active={setting === 'visibility'}>
                <Accordion.Button>
                  <div className="text-lg font-medium">Visibility Status</div>
                  {user.published ? (
                    <span
                      className={classNames(
                        'inline-block whitespace-nowrap rounded px-2.5 py-1.5 text-sm font-medium capitalize leading-none',
                        { 'bg-green-700 text-white': user.visible === 'public' },
                        { 'bg-primary-500 text-white': user.visible === 'internal' },
                        { 'bg-red-600 text-white': user.visible === '' },
                      )}
                    >
                      {(user.visible === 'internal' ? 'Internal Only' : user.visible) || 'Private'}
                    </span>
                  ) : (
                    <span className="inline-block whitespace-nowrap rounded bg-red-200 px-2.5 py-1.5 text-sm font-medium capitalize leading-none text-red-700">
                      Disabled
                    </span>
                  )}
                </Accordion.Button>

                <Accordion.Panel>
                  <VisibilityForm user={user} />
                </Accordion.Panel>
              </Accordion.Item>

              {user.type === 'Advisor' && (
                <Accordion.Item active={setting === 'logo'}>
                  <Accordion.Button>
                    <div className="text-lg font-medium">Logo</div>
                  </Accordion.Button>

                  <Accordion.Panel>
                    <LogoForm user={user} />
                  </Accordion.Panel>
                </Accordion.Item>
              )}

              <Accordion.Item active={setting === 'banner'}>
                <Accordion.Button>
                  <div className="text-lg font-medium">Banner</div>
                </Accordion.Button>

                <Accordion.Panel>
                  <BannerForm user={user} />
                </Accordion.Panel>
              </Accordion.Item>

              <Accordion.Item active={setting === 'synopsis'}>
                <Accordion.Button>
                  <div>
                    <div className="whitespace-nowrap text-lg font-medium">Search Results Description</div>
                    <div className="text-gray-500">This short 140 character description is shown in the search results at LSNPros.com.</div>
                  </div>
                </Accordion.Button>

                <Accordion.Panel>
                  <SearchResultsDescriptionForm user={user} />
                </Accordion.Panel>
              </Accordion.Item>

              <Accordion.Item active={setting === 'biography'}>
                <Accordion.Button>
                  <div>
                    <div className="text-lg font-medium">Page Content</div>
                    <div className="text-gray-500">The page content is your long-format description of your products and/or services.</div>
                  </div>
                </Accordion.Button>

                <Accordion.Panel>
                  <PageContentForm user={user} />
                </Accordion.Panel>
              </Accordion.Item>
            </Accordion.Items>
          </Accordion>
        </div>
      </div>
    </div>
  )
}
