import React, { useRef, useState } from 'react'
import classNames from 'classnames'
import { Transition } from '@headlessui/react'
import { useClickAway } from 'use-click-away'

const Button = () => null

const TopBarDropdown = ({ children, label, id, orientation, size }) => {
  const [open, setOpen] = useState(false)
  const clickRef = useRef(null)
  const button = children.find((el) => el.type === Button)

  useClickAway(clickRef, () => {
    setTimeout(() => setOpen(false), 50)
  })

  return (
    <div className="relative z-10 flex flex-col">
      {button ? (
        button.props.children({ id, clickRef, open, setOpen })
      ) : (
        <button
          id={id}
          ref={clickRef}
          className="outline-none inline-flex h-10 w-10 items-center justify-center rounded text-white hover:bg-white hover:bg-opacity-10 focus:ring-2 focus:ring-inset focus:ring-white"
          aria-expanded={open}
          aria-haspopup="true"
          onClick={() => setOpen(!open)}
        >
          {label}
        </button>
      )}

      <Transition
        show={open}
        enter="transition ease-out duration-100 transform"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="transition ease-in duration-75 transform"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <div
          className={classNames(
            'focus:outline-none absolute mt-2 rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5',
            size,
            {
              'left-0 origin-top-left': orientation === 'left',
              'right-0 origin-top-right': orientation === 'right',
            },
          )}
          role="menu"
          aria-orientation="vertical"
          aria-labelledby={id}
        >
          {children}
        </div>
      </Transition>
    </div>
  )
}

TopBarDropdown.defaultProps = {
  id: 'options-menu',
  orientation: 'right',
  size: 'w-64',
}

TopBarDropdown.Button = Button

export default TopBarDropdown
