import { Fragment, useEffect, useRef, useState } from 'react'
import DeleteConfirmationDialog from '@/Shared/Dialog/DeleteConfirmationDialog'
import { useForm } from '@inertiajs/react'
import TextArea from '@/Shared/Forms/TextArea'

export default function CloseTransactionDialog({ closingType, open, transaction, onCanceled }) {
  let focusRef = useRef()

  const { data, setData, errors, clearErrors, reset, post } = useForm()

  useEffect(() => {
    if (open) {
      setData({ ...data, closing_type: closingType })

      setTimeout(() => {
        focusRef.current.focus()
      }, 100)
    }
  }, [open])

  const onCloseDialog = () => {
    clearErrors()
    reset()
    onCanceled()
  }

  const onCancelClosing = () => {
    post(route('transactions.change-status', transaction.id), {
      onSuccess: () => {
        onCloseDialog()
      },
    })
  }

  return (
    <DeleteConfirmationDialog
      open={open}
      title={`Change the status of this Transaction to "${data.closing_type}"?`}
      buttonText={
        <span>
          Mark as <span className="font-bold uppercase">{data.closing_type}</span>
        </span>
      }
      size="sm:max-w-2xl"
      onCanceled={() => onCloseDialog()}
      onApproved={() => onCancelClosing()}
    >
      <div className="space-y-4">
        <p>
          Continuing with this action will close out this transaction and change its status to{' '}
          <span className="font-bold text-red-600">{data.closing_type}</span>.
        </p>

        <TextArea
          ref={focusRef}
          label="Reason for change of status"
          name="reason"
          value={data.reason}
          error={errors.reason}
          rows="4"
          onChange={(value) => setData({ ...data, reason: value })}
          required={['Archived', 'Lost'].some((status) => status == data.closing_type)}
          multiline
        />
      </div>
    </DeleteConfirmationDialog>
  )
}
