import { useEffect, useRef } from 'react'
import Alert from '@/Shared/Alert'
import Button from '@/Shared/Button'
import Checkbox from '@/Shared/Forms/Checkbox'
import Helpers from '@/utils/helpers'
import Label from '@/Shared/Label'
import MultipleInputBlock from '@/Shared/Forms/MultipleInputBlock'
import Select from '@/Shared/Forms/Select'
import SlideOver from '@/Shared/SlideOver'
import TextInput from '@/Shared/Forms/TextInput'
import { useForm, usePage } from '@inertiajs/react'
import { router } from '@inertiajs/react'

export default ({ open, onClosed }) => {
  let focusRef = useRef()
  const { contact } = usePage().props
  const { user } = usePage().props.auth

  const form = useForm({
    mls_associations: [{}],
  })
  const { clearErrors, data, errors, setData, setError, reset } = form
  const mlsAssociations = [
    { value: 'MLS PIN', label: 'MLS PIN' },
    { value: 'Cape Cod Multiple Listing Services', label: 'Cape Cod Multiple Listing Services' },
    { value: 'Statewide MLS', label: 'Statewide MLS' },
    { value: 'PrimeMLS', label: 'PrimeMLS' },
  ]

  useEffect(() => {
    if (open) {
      setData({
        id: contact.id,
        mls_associations:
          contact.associate.mls_associations.length > 0
            ? contact.associate.mls_associations.map((association) => ({
                ...association,
                disableDeleting: !Helpers.isAdminOrHR(user) && !association.active,
              }))
            : [{ active: true }],
      })
    }
  }, [open])

  const closeDialog = () => {
    clearErrors()
    reset()
    onClosed()
  }

  const submit = (event) => {
    event.preventDefault()

    router.post(
      Helpers.isAdminOrHR(user) ? route('admin.associates.update', contact.associate.id) : route('my-card.update'),
      {
        ...data,
        mls_associations: data.mls_associations
          .filter((association) => association.value)
          .map((association) => ({ ...association, paid: association.paid == undefined ? true : association.paid })),
        _method: 'put',
      },
      {
        onSuccess: () => {
          closeDialog()
        },
        onError: (errors) => setError(errors),
      },
    )
  }

  return (
    <SlideOver
      focusRef={focusRef}
      footerActions={
        <Button type="submit" theme="solid" form="mlsSystemsForm">
          Save
        </Button>
      }
      show={open}
      size="max-w-lg"
      title="Edit"
      subTitle="MLS Systems"
      onClosed={closeDialog}
    >
      <form id="mlsSystemsForm" className="-mt-4 mb-5" onSubmit={submit}>
        <MultipleInputBlock
          data={data.mls_associations}
          onChange={(value) =>
            setData({
              ...data,
              mls_associations: value.map((item) => ({
                ...item,
                active: item.active == undefined ? true : item.active,
              })),
            })
          }
          hasPrimary={false}
          render={({ data, index, onChange }) => (
            <div className="flex-1 space-y-5">
              <Select
                ref={focusRef}
                name="value"
                label="Name"
                options={mlsAssociations}
                placeholder="Select a MLS"
                value={data.value}
                isClearable={false}
                disabled={!Helpers.isAdminOrHR(user) && !data.active}
                onChange={(selected) => onChange(selected && selected.value, 'value')}
              />

              <TextInput
                label="ID"
                name="label"
                value={data.label}
                disabled={!Helpers.isAdminOrHR(user) && !data.active}
                onChange={(value) => onChange(value, 'label')}
              />

              {Helpers.isAdminOrHR(user) ? (
                <div className="flex items-center gap-4">
                  <Checkbox
                    name={`mls_systems_${index}_active`}
                    label="Active"
                    value={data.active}
                    onChange={(selected) => onChange(selected, 'active')}
                  />

                  <Checkbox
                    name={`mls_systems_${index}_paid`}
                    label="Paid"
                    value={data.paid}
                    onChange={(selected) => onChange(selected, 'paid')}
                  />
                </div>
              ) : (
                data.value &&
                data.label && (
                  <div className="flex items-center justify-between gap-4">
                    <div className="space-x-2">
                      <Label type={data.active ? 'success' : 'error'}>{data.active ? 'Active' : 'Inactive'}</Label>
                      {data.paid != undefined && <Label type={data.paid ? 'success' : 'error'}>{data.paid ? 'Paid' : 'Unpaid'}</Label>}
                    </div>

                    {!data.active && <Alert type="danger" subtext="Contact HR to update" size="small" />}
                  </div>
                )
              )}

              {Boolean(errors[`mls_associations.${index}.value`]) && (
                <div className="mx-1 mt-2 text-red-600">{errors[`mls_associations.${index}.value`]}</div>
              )}
              {Boolean(errors[`mls_associations.${index}.label`]) && (
                <div className="mx-1 mt-2 text-red-600">{errors[`mls_associations.${index}.label`]}</div>
              )}
            </div>
          )}
        />
      </form>
    </SlideOver>
  )
}
