import { useContext, useEffect, useState } from 'react'
import { BlockUIContext } from '@/Shared/BlockUI/BlockUIContext'
import Button from '@/Shared/Button'
import Dialog from '@/Shared/Dialog/Index'
import RichTextEditor from '@/Shared/RichTextEditor/Index'
import { useForm, usePage } from '@inertiajs/react'
import { router } from '@inertiajs/react'

export default function PauseReviewDialog({ open, onCanceled }) {
  const { transaction } = usePage().props
  const { loading, setLoading } = useContext(BlockUIContext)
  const { data, setData, errors, reset } = useForm({
    comments: '',
    // notify_svp_requested: false,
  })
  const [comments, setComments] = useState('')

  useEffect(() => {
    if (comments != data.comments) {
      setData({ ...data, comments: comments })
    }
  }, [comments])

  const onCloseDialog = () => {
    reset()
    onCanceled()
  }

  const onSubmit = () => {
    setLoading(true)

    router.post(
      route('transactions.pause-review', transaction.id),
      { ...data, _method: 'put' },
      {
        forceFormData: true,
        onSuccess: () => onCloseDialog(),
        onFinish: () => {
          setLoading(false)
        },
      },
    )
  }

  return (
    <Dialog
      cancelText="Close"
      footerActions={
        <Button type="submit" theme="solid" disabled={loading} onClick={() => onSubmit()}>
          Continue
        </Button>
      }
      show={open}
      size="sm:max-w-[80%]"
      title="Pause Compliance Review"
      onClosed={() => onCloseDialog()}
    >
      <div className="space-y-6">
        <p>
          You are about to mark this compliance review as <span className="font-bold text-red-600">PAUSED</span>.
        </p>

        <div className="space-y-4 rounded-md border-2 border-gray-300/75 p-5">
          <div>
            <label className="text-sm font-medium uppercase text-gray-700">Comments</label>
            <RichTextEditor value={comments} height="h-[250px] overflow-y-scroll" onChange={(value) => setComments(value)} autoFocus />
            {errors.comments && <div className="mt-1 text-red-600">{errors.comments}</div>}
          </div>

          {/* <div>
            <Checkbox
              name="notify_svp_requested"
              label={<span className="font-medium normal-case">Request to notify the Lead Associate's SVP</span>}
              description="Selecting this option will request that the Lead Associate authorize their SVP to view this transaction and assist with resolving this hold."
              value={data.notify_svp_requested}
              onChange={(selected) => setData({ ...data, notify_svp_requested: selected })}
            />
          </div> */}
        </div>

        <div className="space-y-4">
          <p>Continuing will perform the following actions:</p>

          <ul className="list-inside divide-y divide-gray-400/50 overflow-hidden rounded-md border border-gray-400/50 text-black">
            <li className="flex items-center gap-4 bg-gray-100 px-3 py-1.5">
              <i className="fal fa-rotate-left h-7 w-6 text-center text-xl text-gray-800"></i>
              <span>
                Change the status of this transaction from{' '}
                <div className="inline-block whitespace-nowrap rounded bg-orange-200/60 px-2.5 py-1.5 text-sm font-medium leading-none text-orange-600 ring-1 ring-inset ring-orange-300/75">
                  <span>Under Review</span>
                </div>{' '}
                to{' '}
                <div className="inline-block whitespace-nowrap rounded bg-blue-500 px-2.5 py-1.5 text-sm leading-none text-white">
                  <span>Pending</span>
                </div>
                .
              </span>
            </li>
            <li className="flex items-center gap-4 bg-gray-100 px-3 py-1.5">
              <i className="fal fa-file-pen h-7 w-6 text-center text-xl text-gray-800"></i>
              <span>
                Generate a <span className="font-bold">Paused Compliance Review Report</span> for Associates on this transaction detailing
                any files marked not applicable or declined along with any comments entered above.
              </span>
            </li>
            <li className="flex items-center gap-4 bg-gray-100 px-3 py-1.5">
              <i className="fal fa-list-tree h-7 w-6 text-center text-xl text-gray-800"></i>
              <span>Mark any steps related to any declined or not applicable files as incomplete.</span>
            </li>
            <li className="flex items-center gap-4 bg-gray-100 px-3 py-1.5">
              <i className="fal fa-bell-on h-7 w-6 text-center text-xl text-gray-800"></i>
              <span>
                Notify the Associate(s) on this transaction that the compliance review has been paused.{' '}
                {/* {data.notify_svp_requested && (
                  <span className="font-bold">
                    Additionally, the Lead Associate's SVP will be notified of this hold{' '}
                    <span className="text-red-600">(requires authorization)</span>.
                  </span>
                )} */}
              </span>
            </li>
            <li className="flex items-center gap-4 bg-gray-100 px-3 py-1.5">
              <i className="fal fa-diagram-next h-7 w-6 text-center text-xl text-gray-800"></i>
              <span>
                Create a required task for all Associates with edit access to this transaction to resolve the reason(s) for the pause.
              </span>
            </li>
            <li className="flex items-center gap-4 bg-gray-100 px-3 py-1.5">
              <i className="fal fa-unlock h-7 w-6 text-center text-xl text-gray-800"></i>
              <span>Unlock the transaction so that Associates can perform edits.</span>
            </li>
          </ul>
        </div>
      </div>
    </Dialog>
  )
}
