import React, { useEffect, useState } from 'react'
import { router } from '@inertiajs/react'
import useDebounce from '@/hooks/useDebounce'
import { useForm, usePage } from '@inertiajs/react'

import Select from '@/Shared/Forms/Select'
import TextInput from '@/Shared/Forms/TextInput'

export default ({ checklist }) => {
  const { associates, filters, propertyTypes, roles, sections, states, statuses } = usePage().props
  const [loading, setLoading] = useState(true)

  const form = useForm({
    creators: filters.creators || [],
    property_types: filters.property_types || [],
    required_roles: filters.required_roles || [],
    search: filters.search || '',
    section: filters.section || '',
    states: filters.states || [],
    status: filters.status || [],
    updaters: filters.updaters || [],
  })
  const { data, setData } = form
  const [search, setSearch] = useState(data.search)

  useDebounce(() => (search !== filters.search ? setData({ ...data, search: search }) : null), 300, [search])
  useEffect(() => {
    if (!loading) {
      filterData()
    } else {
      setLoading(false)
    }
  }, [data])

  const filterData = () => {
    const newFilters = (({ trashed, ...o }) => o)(data)
    const query = encodeURIComponent(JSON.stringify(newFilters))

    router.get(
      route(route().current(), {
        checklist: checklist.id,
        filters: query,
      }),
      {},
      {
        preserveState: true,
      },
    )
  }

  return (
    <div className="border-t border-gray-200 px-4 pt-4 sm:px-6 sm:pb-4">
      <div className="mb-4 text-lg font-medium text-gray-900">
        <i className="fas fa-sliders-v mr-2"></i>
        <span>Filters</span>
      </div>

      <form id="filters" className="flex h-full flex-col">
        <div className="mb-4">
          <div className="relative flex">
            <TextInput
              autoFocus
              classes="flex-grow"
              inputClasses="px-4 pr-14 py-2"
              placeholder="Search by title or content"
              type="search"
              value={search}
              onChange={(value) => setSearch(value)}
            />

            <span className="absolute inset-y-0 right-0 flex w-10 justify-center">
              <span className="flex h-full items-center justify-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                  <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <rect id="bound" x="0" y="0" width="24" height="24"></rect>
                    <path
                      className="fill-current text-blue-500"
                      d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                      id="Path-2"
                      fillRule="nonzero"
                      opacity="0.3"
                    ></path>
                    <path
                      className="fill-current text-blue-500"
                      d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                      id="Path"
                      fillRule="nonzero"
                    ></path>
                  </g>
                </svg>
              </span>
            </span>
          </div>
        </div>

        <Select
          label="Section"
          name="section"
          options={sections}
          placeholder="Any"
          value={data.section}
          onChange={(selected) => setData({ ...data, section: selected?.value })}
        />

        <Select
          label="Property Type"
          name="property_type"
          options={propertyTypes}
          placeholder="Any"
          value={propertyTypes.filter((p) => data.property_types.find((f) => f === p.value))}
          onChange={(selected) => setData({ ...data, property_types: selected ? Array.from(selected, (s) => s.value) : [] })}
          multiple
        />

        <Select
          label="State"
          name="states"
          options={states}
          placeholder="Any"
          value={states.filter((p) => data.states.find((f) => f === p.value))}
          onChange={(selected) => setData({ ...data, states: selected ? Array.from(selected, (s) => s.value) : [] })}
          multiple
        />

        <Select
          label="Requires Role(s)"
          name="required_roles"
          options={roles}
          placeholder="Any"
          value={roles.filter((p) => data.required_roles.find((f) => f === p.value))}
          onChange={(selected) => setData({ ...data, required_roles: selected ? Array.from(selected, (s) => s.value) : [] })}
          multiple
        />

        <Select
          label="Created by"
          name="creators"
          options={associates}
          placeholder="Any"
          value={associates.filter((p) => data.creators.find((f) => f === p.value))}
          onChange={(selected) => setData({ ...data, creators: selected ? Array.from(selected, (s) => s.value) : [] })}
          multiple
        />

        <Select
          label="Last updated by"
          name="updaters"
          options={associates}
          placeholder="Any"
          value={associates.filter((p) => data.updaters.find((f) => f === p.value))}
          onChange={(selected) => setData({ ...data, updaters: selected ? Array.from(selected, (s) => s.value) : [] })}
          multiple
        />

        <Select
          classes=""
          label="Status"
          name="status"
          options={statuses}
          placeholder="Any"
          value={data.status}
          onChange={(selected) => setData({ ...data, status: selected?.value })}
        />
      </form>
    </div>
  )
}
