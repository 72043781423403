import { useState } from 'react'
import Menu from './Menu'
import Details from './Details'
import Members from './Members'
import Pages from './Pages'
import { usePage } from '@inertiajs/react'

export default ({ children, onClosed, onSearching }) => {
  const { lsn } = usePage().props
  const [tab, setTab] = useState('')

  const replacementDate = lsn.replace_leaders_at && new Date(lsn.replace_leaders_at)
  const isReplacementExpired = replacementDate != undefined && replacementDate <= new Date()
  const isReplacementExpiring =
    replacementDate != undefined && !isReplacementExpired && replacementDate.setDate(replacementDate.getDate() - 30) <= new Date()

  const handleClose = () => {
    typeof onClosed === 'function' ? onClosed() : setTab('')
  }

  return (
    <div className="relative h-full bg-gray-100">
      <Menu onTabChange={(selected) => setTab(selected)}>
        <div className="bg-white px-4 pb-4">
          {isReplacementExpired ? (
            <div className="flex items-center justify-center rounded-md bg-red-500 p-4 text-lg font-medium text-white shadow-md sm:px-6">
              <i className="fas fa-warning mr-3 text-2xl"></i>
              The term for the Leadership Team of this LSN expired on {lsn.replace_leaders_at}.
            </div>
          ) : (
            isReplacementExpiring && (
              <div className="flex items-center justify-center rounded-md bg-yellow-100 p-4 text-lg font-medium text-yellow-600 shadow-md sm:px-6">
                <i className="fas fa-warning mr-3 text-2xl"></i>
                The term for the Leadership Team of this LSN will expire on {lsn.replace_leaders_at}.
              </div>
            )
          )}
        </div>

        {children}
      </Menu>
      <Details open={tab === 'about'} onClose={() => handleClose()} onBack={() => setTab('')} />
      <Members open={tab === 'members'} onClose={() => handleClose()} onBack={() => setTab('')} onSearch={onSearching} />
      <Pages open={tab === 'pages'} onClose={() => handleClose()} onBack={() => setTab('')} />
    </div>
  )
}
