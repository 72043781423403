import { Fragment, useEffect, useRef, useState } from 'react'
import Button from '@/Shared/Button'
import ConfirmationDialog from '@/Shared/Dialog/ConfirmationDialog'
import Heading from '@/Shared/Forms/Heading'
import Info from '@/Shared/Info'
import PropertyListItem from '@/Shared/PropertyListItem'
import Select from '@/Shared/Forms/Select'
import SlideOver from '@/Shared/SlideOver'
import { useForm, usePage } from '@inertiajs/react'

export default ({ record, open, onClosed }) => {
  let focusRef = useRef()
  const { availableRoles, lsn, roles: allRoles } = usePage().props
  const roles = allRoles.filter((role) => availableRoles.find((r) => r.value == role.value) || role.value == record?.position_id)
  const form = useForm()
  const { data, clearErrors, setData, patch } = form
  const [confirmingReassignment, setConfirmingReassignment] = useState(false)

  useEffect(() => {
    if (open) {
      let member = record || {}
      setData({
        position_id: member.position_id,
      })
    } else {
      clearErrors()
    }
  }, [open])

  const getRoleName = () => allRoles.find((role) => role.value == data.position_id)?.label
  const getRoleOwner = () =>
    lsn.members.find((member) => member.position_id == data.position_id)?.name ||
    lsn.invitations.find((invitation) => invitation.position_id == data.position_id)?.name

  useEffect(() => {
    if (!confirmingReassignment) {
      setTimeout(() => {
        focusRef.current?.blur()
      }, [250])
    }
  }, [confirmingReassignment])

  const positionChanged = (selected) => {
    setData({ ...data, position_id: selected?.value })

    if (selected?.value && !roles.find((role) => role.value == selected.value)) {
      setConfirmingReassignment(true)
    }
  }

  const submit = (e) => {
    e.preventDefault()
    patch(route('lsns.members.update', { lsn: lsn.id, member: record.id }), {
      onSuccess: (_) => {
        onClosed(true)
      },
    })
  }

  return (
    <SlideOver
      focusRef={focusRef}
      footerActions={
        <Button type="submit" theme="solid" form="member-form">
          Save
        </Button>
      }
      show={open}
      size="max-w-xl"
      title="Edit"
      subTitle="Member"
      onClosed={onClosed}
      aboveMessages
    >
      <form id="member-form" className="space-y-6 pb-4" onSubmit={submit}>
        <div>
          <div className="mb-5">
            <Heading>Contact Details</Heading>
            <PropertyListItem label="Name" value={record?.name} noBorder />
            <PropertyListItem label="Service" value={record?.service} noBorder />
          </div>

          <Select
            ref={focusRef}
            name="position_id"
            label="Management Role"
            options={allRoles}
            placeholder="None"
            value={data.position_id}
            disabled={lsn.published && [1, 2, 3].indexOf(data.position_id) >= 0}
            onChange={(selected) => positionChanged(selected)}
          />

          {lsn.published && [1, 2, 3].indexOf(data.position_id) >= 0 && (
            <Info
              text={
                <div>
                  <p className="mb-3">This member is currently on the Leadership Team for this LSN.</p>
                  <p className="mb-3">This role a requirement for an LSN to be available to the general public.</p>
                  <p>
                    To change this role or remove this member, you must first reassign this role to another member or make this LSN private.
                  </p>
                </div>
              }
            />
          )}
        </div>
      </form>

      <ConfirmationDialog
        open={confirmingReassignment}
        title="Reassign Management Role?"
        onCanceled={() => {
          setData({ ...data, position_id: null })
          setConfirmingReassignment(false)
        }}
        onApproved={() => setConfirmingReassignment(false)}
      >
        <Fragment>
          <p className="mb-3">
            The <span className="font-medium">{getRoleName()}</span> management role currently assigned to {getRoleOwner()}. You are about
            to reassign this role to {record?.name}.
          </p>
          <p className="mb-3">Would you like to continue?</p>
        </Fragment>
      </ConfirmationDialog>
    </SlideOver>
  )
}
