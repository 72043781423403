import classNames from 'classnames'

const Header = () => null
const Body = () => null
const Footer = () => null

const Table = ({ children, ...props }) => {
  const header = Array.isArray(children) ? children.find((el) => el.type === Header) : children.type === Header ? children : null
  const body = Array.isArray(children) ? children.find((el) => el.type === Body) : children.type === Body ? children : null
  const footer = Array.isArray(children) ? children.find((el) => el.type === Footer) : children.type === Footer ? children : null

  return (
    <div className={classNames({ 'border-t border-l border-r border-gray-200 shadow sm:rounded-lg': !props.flat }, props.className)}>
      <div className="flex flex-col">
        <div className="overflow-x-auto">
          <div className="inline-block min-w-full align-middle">
            <div className={classNames('border-b border-gray-300', { 'overflow-hidden shadow sm:rounded-lg': !props.flat })}>
              <table className="min-w-full divide-y divide-gray-200">
                {header && <thead>{header.props.children}</thead>}
                <tbody className="divide-y divide-gray-200 bg-white">{body ? body.props.children : null}</tbody>
                {footer && <tfoot>{footer.props.children}</tfoot>}
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Table.Header = Header
Table.Body = Body
Table.Footer = Footer

export default Table
