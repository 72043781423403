import React, { useEffect, useRef, useState } from 'react'
import Button from '@/Shared/Button'
import { router } from '@inertiajs/react'
import Select from '@/Shared/Forms/Select'
import SlideOver from '@/Shared/SlideOver'
import { useForm, usePage } from '@inertiajs/react'

export default ({ open, onClosed }) => {
  let focusRef = useRef(null)
  const { categories, filters, states, shortcodes, statuses } = usePage().props
  const form = useForm({
    category_id: filters.category_id || [],
    status: filters.status,
    shortcodes: filters.shortcodes || [],
    states: filters.states || [],
  })
  const { data, setData } = form

  const onSubmit = (e) => {
    e.preventDefault()

    const values = filters
      ? { ...filters, category_id: data.category_id, status: data.status, shortcodes: data.shortcodes, states: data.states }
      : { category_id: data.category_id, status: data.status, shortcodes: data.shortcodes, states: data.states }
    const query = encodeURIComponent(JSON.stringify(values))

    router.get(
      route(route().current(), { filters: query }),
      {},
      {
        preserveState: true,
        onStart: () => onClosed(),
      },
    )
  }

  return (
    <SlideOver
      focusRef={focusRef}
      footerActions={
        <Button theme="solid" type="submit" form="filters">
          Apply
        </Button>
      }
      show={open}
      title="Filters"
      onClosed={onClosed}
      aboveMessages
    >
      <form id="filters" className="flex h-full flex-col" onSubmit={onSubmit}>
        <Select
          ref={focusRef}
          label="Category"
          name="category"
          options={categories}
          placeholder="Any Category"
          value={categories.filter((p) => data.category_id.find((f) => f === p.value))}
          onChange={(selected) => setData({ ...data, category_id: selected ? Array.from(selected, (s) => s.value) : [] })}
          multiple
        />

        <Select
          label="Status"
          name="status"
          options={statuses}
          placeholder="Any Status"
          value={statuses.filter((p) => data.status.find((f) => f === p.value))}
          onChange={(selected) => setData({ ...data, status: selected ? Array.from(selected, (s) => s.value) : [] })}
          multiple
        />

        <Select
          label="States appearing in"
          name="states"
          options={states}
          placeholder="Any States"
          value={states.filter((p) => data.states.find((f) => f === p.value))}
          onChange={(selected) => setData({ ...data, states: selected ? Array.from(selected, (s) => s.value) : [] })}
          multiple
        />

        <Select
          label="Shortcodes found in"
          name="shortcodes"
          options={shortcodes}
          placeholder="Any Shortcodes"
          value={shortcodes.filter((p) => data.shortcodes.find((f) => f === p.value))}
          onChange={(selected) => setData({ ...data, shortcodes: selected ? Array.from(selected, (s) => s.value) : [] })}
          multiple
        />
      </form>
    </SlideOver>
  )
}
