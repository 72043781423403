import React, { useEffect, useRef, useState } from 'react'
import Button from '@/Shared/Button'
import { router } from '@inertiajs/react'
import Select from '@/Shared/Forms/Select'
import SlideOver from '@/Shared/SlideOver'
import { useForm, usePage } from '@inertiajs/react'

export default ({ open, onClosed }) => {
  let focusRef = useRef(null)
  const { filters, statuses } = usePage().props
  const form = useForm({ status: filters.status })
  const { data, setData } = form

  const onSubmit = (e) => {
    e.preventDefault()

    const values = filters ? { ...filters, status: data.status } : { status: data.status }
    const query = encodeURIComponent(JSON.stringify(values))

    router.get(
      route(route().current(), { filters: query }),
      {},
      {
        preserveState: true,
        onStart: () => onClosed(),
      },
    )
  }

  return (
    <SlideOver
      focusRef={focusRef}
      footerActions={
        <Button theme="solid" type="submit" form="filters">
          Apply
        </Button>
      }
      show={open}
      title="Filters"
      onClosed={onClosed}
      aboveMessages
    >
      <form id="filters" className="flex flex-col h-full" onSubmit={onSubmit}>
        <Select
          ref={focusRef}
          label="Status"
          name="status"
          options={statuses}
          placeholder="Any Status"
          value={statuses.filter((p) => data.status.find((f) => f === p.value))}
          onChange={(selected) => setData({ ...data, status: selected ? Array.from(selected, (s) => s.value) : [] })}
          multiple
        />
      </form>
    </SlideOver>
  )
}
