import { Fragment, useEffect, useState } from 'react'
import axios from '@/apis/axios'
import classNames from 'classnames'
import { router } from '@inertiajs/react'
import { useForm, usePage } from '@inertiajs/react'
import Button from '@/Shared/Button'
import DataTableHeader from './DataTableHeader'
import Edit from './Edit'
import Main from '@/Shared/Layouts/Main'
import Pagination from '@/Shared/Pagination'
import SearchBar from './SearchBar'

const AdminEmailTemplateCategoryIndex = () => {
  const { categories } = usePage().props
  const { data: records, paginator } = categories
  const [open, setOpen] = useState(false)
  const { data, setData, delete: destroy } = useForm({})

  useEffect(() => {
    setOpen(Boolean(data.id))
  }, [data])

  const onClosed = () => {
    setData({})
    setOpen(false)
  }

  const onRowClicked = (category) => {
    setData(category)
    setOpen(true)
  }

  const onDelete = (id) => {
    destroy(route('admin.email-templates.categories.destroy', id), {
      onSuccess: () => {
        setData({})
      },
    })
  }

  const onRestore = (id) => {
    axios
      .patch(route('admin.email-templates.categories.update', id), {
        ...data,
        deleted_at: null,
        _method: 'patch',
      })
      .then((_) => {
        setData({})
        router.reload({ only: ['categories', 'flash'] })
      })
  }

  return (
    <div>
      <div className="flex flex-wrap items-stretch justify-between bg-white py-4 px-6">
        <SearchBar onCreate={() => setOpen(true)} />
      </div>

      <div className="mx-6 my-6 bg-white shadow sm:rounded">
        <div className="flex flex-col">
          <div className="overflow-x-auto">
            <div className="inline-block min-w-full align-middle">
              <div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead>
                    <DataTableHeader />
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {records.map((category) => (
                      <tr className="group hover:bg-gray-100" key={category.id}>
                        <td className="cursor-pointer space-y-1 py-3 pl-6" onClick={() => onRowClicked(category)}>
                          <div className="font-medium text-blue-500">{category.name}</div>

                          <div className="flex flex-wrap items-center gap-1.5 sm:hidden">
                            <span
                              className={classNames(
                                Boolean(category.deleted_at)
                                  ? 'bg-red-100 text-red-700'
                                  : {
                                      'bg-green-50 text-green-700': Boolean(category.active),
                                      'bg-gray-200 text-gray-600': !Boolean(category.active),
                                    },
                                'inline-flex items-center whitespace-nowrap rounded-full px-3 py-1.5 text-sm font-medium uppercase leading-none',
                              )}
                            >
                              {Boolean(category.deleted_at) ? (
                                <span>Deleted</span>
                              ) : Boolean(category.active) ? (
                                <span>Active</span>
                              ) : (
                                <span>Inactive</span>
                              )}
                            </span>
                          </div>
                        </td>
                        <td
                          className="hidden cursor-pointer whitespace-nowrap px-6 py-3 text-center text-gray-700 md:table-cell"
                          onClick={() => onRowClicked(category)}
                        >
                          <div
                            className={classNames(
                              Boolean(category.deleted_at)
                                ? 'bg-red-100 text-red-700'
                                : {
                                    'bg-green-50 text-green-700': Boolean(category.active),
                                    'bg-gray-200 text-gray-600': !Boolean(category.active),
                                  },
                              'inline-flex items-center whitespace-nowrap rounded-full p-1 px-2.5 text-sm font-medium uppercase leading-none',
                            )}
                          >
                            {Boolean(category.deleted_at) ? (
                              <span>Deleted</span>
                            ) : (
                              <Fragment>
                                {Boolean(category.active) && <span>Active</span>}
                                {!Boolean(category.active) && <span>Inactive</span>}
                              </Fragment>
                            )}
                          </div>
                        </td>
                        <td
                          className="hidden cursor-pointer whitespace-nowrap px-6 py-3 text-center text-gray-700 md:table-cell"
                          onClick={() => onRowClicked(category)}
                        >
                          {category.updated_at}
                        </td>
                        <td className="cursor-pointer whitespace-nowrap py-3 pr-6 text-right font-medium">
                          <div className="flex items-center justify-end">
                            <Button theme="icon" onClick={() => onRowClicked(category)}>
                              <i className="far fa-pen text-lg text-gray-500 group-hover:font-bold group-hover:text-blue-500"></i>
                            </Button>

                            {!Boolean(category.deleted_at) ? (
                              <button
                                role="button"
                                className="relative ml-2 inline-flex h-10 w-10 items-center justify-center rounded leading-none transition-all duration-150 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-red-600"
                                onClick={() => onDelete(category.id)}
                              >
                                <span className="sr-only">Delete Email Template</span>
                                <i className="far fa-trash text-lg text-red-600"></i>
                              </button>
                            ) : (
                              <Button theme="icon" onClick={() => onRestore(category.id)}>
                                <i className="far fa-trash-restore text-lg text-gray-600 group-hover:text-gray-900"></i>
                              </Button>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}

                    {records.length == 0 && (
                      <tr>
                        <td colSpan="8" className="py-12 px-6">
                          <div className="flex flex-col text-center">
                            <span className="text-2xl font-semibold text-red-600">No categories match your current filters.</span>
                            <span className="text-lg font-medium text-gray-700">
                              Expecting to see results? Check your filters to make sure you're not excluding results.
                            </span>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          {records.length > 0 && <Pagination paginator={paginator} />}
        </div>
      </div>

      <Edit open={open} record={data} onClosed={() => onClosed()} />
    </div>
  )
}

// Persistent layout
// Docs: https://inertiajs.com/pages#persistent-layouts
AdminEmailTemplateCategoryIndex.layout = (page) => <Main title="Email Templates" children={page} />

export default AdminEmailTemplateCategoryIndex
