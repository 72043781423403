import { useContext, useEffect, useState } from 'react'
import { usePage } from '@inertiajs/react'
import classNames from 'classnames'
import { Link } from '@inertiajs/react'
import { SettingConsumer, SettingContext } from './SettingContext'
import Button from '@/Shared/Button'
import { router } from '@inertiajs/react'

const Requirement = ({ item }) => {
  return (
    <li className="flex">
      <SettingConsumer>
        {({ setting }) => (
          <Link
            className={classNames(
              'flex flex-1 items-center p-4',
              setting === item.setting
                ? 'relative rounded-md ring-2 ring-primary-500'
                : 'hover:bg-gray-100 focus:relative focus:rounded-md focus:ring-2 focus:ring-primary-500',
            )}
            href={item.url}
          >
            <i
              className={classNames(
                'w-6 flex-shrink-0 text-2xl leading-none',
                item.completed ? 'far fa-check text-[#2cb833]' : 'far fa-square text-gray-400',
              )}
              aria-hidden="true"
            ></i>
            <span
              className={classNames('ml-3 text-base', {
                'text-gray-500 text-opacity-70 line-through': item.completed,
                'text-gray-600': !item.completed && item.optional,
                'font-medium text-gray-900': !item.completed && !item.optional,
              })}
            >
              {item.title}
              {item.optional && ' (optional)'}
            </span>
          </Link>
        )}
      </SettingConsumer>
    </li>
  )
}

export default ({ user }) => {
  const administrating = route().current()?.startsWith('admin')
  const basePath = administrating ? `/admin/pros/${user.contact_id}` : '/account/settings'

  const requirements = [
    { setting: 'avatar', url: `${basePath}/account?setting=avatar`, title: 'Upload a headshot', completed: Boolean(user.avatar) },
    {
      setting: 'logo',
      url: `${basePath}/my-website?setting=logo`,
      title: 'Upload your logo',
      completed: Boolean(user.logo),
      optional: true,
    },
    {
      setting: 'synopsis',
      url: `${basePath}/my-website?setting=synopsis`,
      title: 'Add a search results description',
      completed: Boolean(user.synopsis),
      optional: false,
    },
    {
      setting: 'biography',
      url: `${basePath}/my-website?setting=biography`,
      title: 'Add content to your Pro-Website',
      completed: Boolean(user.biography),
      optional: false,
    },
    {
      setting: 'services_provided',
      url: `${basePath}/services-towns?setting=services_provided`,
      title: 'Add your primary service',
      completed: user.services_provided.length > 0,
      optional: false,
    },
    {
      setting: 'service_area',
      url: `${basePath}/services-towns?setting=service_area`,
      title: 'Define your service area',
      completed: user.service_areas.length > 0,
      optional: false,
    },
    {
      setting: 'primary_market_location',
      url: `${basePath}/services-towns?setting=primary_market_location`,
      title: 'Set your primary market location',
      completed: Boolean(user.primary_market_location),
      optional: false,
    },
    {
      setting: 'lsns',
      url: `${basePath}/lsns?setting=lsns`,
      title: 'Join a Local Services Network®!',
      completed: user.lsns.length > 0,
      optional: true,
    },
  ]

  const { setSetting } = useContext(SettingContext)
  const published = Boolean(user.visible)
  const [items, setItems] = useState(requirements)

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    let setting = urlParams.get('setting')
    if (setting) {
      setSetting(setting)
    }
  }, [])

  useEffect(() => {
    setItems(published ? requirements.filter((f) => !f.completed) : requirements)
  }, [user])

  const determineHalf = (requirements) => {
    let half = requirements.length / 2
    return half % 1 == 0 ? half : parseInt(half) + 1
  }

  if (items.filter((f) => f.completed).length === items.length) {
    return <></>
  }

  const complete = items.filter((r) => r.completed && !r.optional).length == items.filter((r) => !r.optional).length
  const width =
    (50 + (items.filter((r) => r.completed && !r.optional).length / items.filter((r) => !r.optional).length) * 50).toFixed(2) + '%'

  return (
    <div className="m-3 rounded-md border border-gray-300 p-6 shadow-md">
      <div className="mb-4 text-center text-xl font-medium text-gray-600">Complete Your PRO-Website</div>

      {published ? (
        <hr />
      ) : (
        <div className="w-full rounded-full bg-gray-200">
          <div
            className="flex h-7 items-center justify-center rounded-l-full rounded-r-full bg-[#2cb833] leading-none"
            style={{ width: width }}
          >
            <span className="text-base font-medium text-white">{width}</span>
          </div>
        </div>
      )}

      <div className="mt-3 md:grid md:grid-cols-2 md:gap-x-8">
        <ul role="list" className="divide-y divide-gray-200">
          {items.slice(0, determineHalf(items)).map((item, index) => (
            <Requirement item={item} key={index} />
          ))}
        </ul>
        {determineHalf(items) != items.length && (
          <ul role="list" className="divide-y divide-gray-200 border-t border-gray-200 md:border-t-0">
            {items.slice(determineHalf(items)).map((item, index) => (
              <Requirement item={item} key={index} />
            ))}
          </ul>
        )}
      </div>

      {!published && (
        <div className="mt-3 border-t border-gray-200 pt-6 text-center">
          <Button
            theme="solid"
            colors={complete && 'bg-green-600 hover:bg-green-900'}
            disabled={!complete}
            onClick={() => router.visit('/account/settings/my-website?setting=visibility')}
          >
            <i className="fas fa-check mr-2 text-xl"></i>
            <span className="pr-1">Make Website Public</span>
          </Button>
        </div>
      )}
    </div>
  )
}
