import React from 'react'

const Header = () => null
const Body = () => null

const Portlet = React.forwardRef(({ children, onScroll }, ref) => {
  const header = Array.isArray(children) ? children.find((el) => el.type === Header) : children.type === Header ? children : null
  const body = Array.isArray(children) ? children.find((el) => el.type === Body) : children.type === Body ? children : null

  return (
    <div className="shadow-sm">
      <div className="min-w-full overflow-hidden rounded-b-lg">
        {header && (
          <div className="flex h-16 cursor-pointer flex-col justify-center rounded-t-lg bg-gray-200 px-4 py-4 font-medium leading-none text-gray-800 sm:pl-6">
            {header.props.children}
          </div>
        )}
        <div
          ref={ref}
          className="max-h-[400px] min-h-[400px] divide-y divide-gray-200 overflow-y-auto border-l border-r border-gray-200 bg-white"
          onScroll={onScroll}
        >
          {body ? body.props.children : null}
        </div>
      </div>
    </div>
  )
})

Portlet.Header = Header
Portlet.Body = Body

export default Portlet
