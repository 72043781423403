import { router } from '@inertiajs/react'
import { usePage } from '@inertiajs/react'
import HeaderColumn from '@/Shared/Table/HeaderColumn'

export default function DataTableHeader() {
  const { filters } = usePage().props
  const { sortBy, direction } = filters

  const sort = (field) => {
    const values = filters
      ? { ...filters, sortBy: field, direction: field === sortBy ? (direction === 'asc' ? 'desc' : 'asc') : 'asc' }
      : {
          sortBy: field,
          direction: field === sortBy ? (direction === 'asc' ? 'desc' : 'asc') : 'asc',
        }
    const query = encodeURIComponent(JSON.stringify(values))

    router.get(
      route(route().current(), { filters: query }),
      {},
      {
        preserveState: true,
      },
    )
  }

  return (
    <tr>
      <HeaderColumn classes="hidden md:table-cell">
        <div className="flex-1 text-center">
          <i className="fas fa-user-circle fa-2x text-gray-500"></i>
        </div>
      </HeaderColumn>
      <HeaderColumn sorting={sortBy} direction={direction} field="name" onSort={(field) => sort(field)} noBorder>
        Name
      </HeaderColumn>
      <HeaderColumn
        classes="hidden lg:table-cell"
        sorting={sortBy}
        direction={direction}
        field="latest_stage"
        onSort={(field) => sort(field)}
      >
        Current Stage
      </HeaderColumn>
      <HeaderColumn
        classes="hidden xl:table-cell"
        sorting={sortBy}
        direction={direction}
        field={
          ['created_at', 'updated_at', 'mortgage_commitment_date', 'closing_at'].indexOf(filters.sortBy) >= 0
            ? filters.sortBy
            : 'closing_at'
        }
        onSort={(field) => sort(field)}
      >
        {(() => {
          switch (filters.sortBy) {
            case 'created_at':
              return 'Date Created'
            case 'updated_at':
              return 'Last Activity'
            case 'mortgage_commitment_date':
              return 'Mortgage Commitment Date'
            default:
              return 'Closing Date'
          }
        })()}
      </HeaderColumn>
      <HeaderColumn noHover>
        <span className="sr-only">Edit</span>
      </HeaderColumn>
    </tr>
  )
}
