import { createContext, useState } from 'react'

export const BlockUIContext = createContext({
  loading: Boolean,
  setLoading: (loading) => {},
})

export const BlockUIProvider = ({ children }) => {
  const [loading, setLoading] = useState(false)

  const context = {
    loading,
    setLoading,
  }

  return <BlockUIContext.Provider value={context}>{children}</BlockUIContext.Provider>
}

export const BlockUIConsumer = BlockUIContext.Consumer
