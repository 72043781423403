export default ({ label }) => {
  return (
    <div className="flex flex-col items-center justify-center">
      <span>
        <svg className="mb-4 w-16 fill-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 91.07 120.42">
          <path
            fillOpacity=".55"
            d="M42.62,24A117.22,117.22,0,0,1,61.23,36.52a51.42,51.42,0,0,1,17.3,26.39C82.29,43.57,71.68,26.5,91.07,0,66,5.25,54.05,12.84,42.62,24Z"
          ></path>
          <path
            fillOpacity=".9"
            d="M58.37,40.23C42.65,27.88,30,20.35,0,18.77,23.86,42.62,14.9,61.46,22.61,80.54a28.2,28.2,0,0,0,34.6,16.84c2.84,8,3.61,16.45,1.18,21.29,0,3.31,5.8,1.38,8.56-1.38,2.39-2.39-2-14.89-6.31-21.26A27.4,27.4,0,0,0,73.25,83.3C79,71.87,72.57,51.41,58.37,40.23Z"
          ></path>
          <path
            fill="#ffffff"
            d="M37.17,60.6C33,44.7,26.17,33.69,25.69,34c.5-.27,11.81,8.34,16,24.23s11,26.91,11.47,26.64C52.61,85.1,41.31,76.5,37.17,60.6Z"
          ></path>
        </svg>
      </span>
      <span className="text-lg font-bold">You don't have any {label}</span>
      <span className="text-gray-500">Expecting to see results? Try a different filter.</span>
    </div>
  )
}
