import { useState } from 'react'
import ConfirmationDialog from '@/Shared/Dialog/ConfirmationDialog'
import Alert from '@/Shared/Alert'
import { usePage, useForm } from '@inertiajs/react'

export default function RequestReviewDialog({ open, onCanceled, onRequested }) {
  const { transaction } = usePage().props
  const { post } = useForm({
    closing_type: 'Compliance',
  })
  const [submitting, setSubmitting] = useState(false)
  const declinedFiles = transaction.allFiles.filter((file) => file.declined)
  const notApplicableFiles = transaction.allFiles.filter((file) => file.declined && file.not_applicable)
  const hasPendingContacts = transaction.contacts.some((contact) => contact.pending)

  const onCloseDialog = () => {
    onCanceled()
    setSubmitting(false)
  }

  const submit = () => {
    setSubmitting(true)
    post(route('transactions.change-status', transaction.id), {
      onSuccess: () => {
        onCloseDialog()
        onRequested()
      },
    })
  }

  return (
    <ConfirmationDialog
      open={open}
      title="Request to Review & Complete Transaction"
      buttonText="Continue"
      size="sm:max-w-4xl"
      onCanceled={() => onCloseDialog()}
      onApproved={() => submit()}
      disabled={submitting || declinedFiles.length > 0 || notApplicableFiles.length > 0 || hasPendingContacts}
    >
      {declinedFiles.length > 0 || notApplicableFiles.length > 0 || hasPendingContacts ? (
        <Alert
          align="top"
          className="mb-0"
          heading={
            <div className="mr-6 space-y-4 text-lg font-normal">
              <div>
                <p className="font-semibold">You can't request a new compliance review until the below issue(s) have been resolved.</p>
              </div>

              {(declinedFiles.length > 0 || notApplicableFiles.length > 0) && (
                <div>
                  <p className="font-medium">File Issues:</p>
                  <ul className="list-inside divide-y divide-gray-400/50 overflow-hidden rounded-md border border-gray-400/50 text-black">
                    {declinedFiles.length > 0 && (
                      <li className="flex items-center gap-3 bg-white px-3 py-1.5">
                        <span className="flex w-6 items-center justify-center">
                          <i className="far fa-times text-2xl leading-none text-red-600"></i>
                        </span>

                        <span>
                          <span className="font-bold">{declinedFiles.length}</span> file
                          {declinedFiles.length > 1 ? 's have' : ' has'} been{' '}
                          <span className="rounded bg-red-500 px-1.5 py-1 text-sm font-bold uppercase leading-none text-white">
                            <i className="fas fa-file-slash mr-1.5"></i>
                            <span>Declined</span>
                          </span>{' '}
                          and must be replaced.
                        </span>
                      </li>
                    )}

                    {notApplicableFiles.length > 0 && (
                      <li className="flex items-center gap-3 bg-white px-3 py-1.5">
                        <span className="flex w-6 items-center justify-center">
                          <i className="far fa-times text-2xl leading-none text-red-600"></i>
                        </span>

                        <span>
                          <span className="font-bold">{notApplicableFiles.length}</span> file
                          {notApplicableFiles.length > 1 ? 's have' : ' has'} been marked{' '}
                          <span className="rounded bg-red-500 px-1.5 py-1 text-sm font-bold uppercase leading-none text-white">
                            <i className="fas fa-file-slash mr-1.5"></i>
                            <span>Not Applicable</span>
                          </span>{' '}
                          and must be deleted.
                        </span>
                      </li>
                    )}
                  </ul>
                </div>
              )}

              {transaction.paused_comments && (
                <div>
                  <p className="font-medium">Reviewer Comments:</p>
                  <blockquote>
                    <div dangerouslySetInnerHTML={{ __html: transaction.paused_comments }}></div>
                  </blockquote>
                </div>
              )}

              {hasPendingContacts && (
                <div>
                  <ul className="list-inside divide-y divide-gray-400/50 overflow-hidden rounded-md border border-gray-400/50 text-black">
                    <li className="flex items-center gap-4 bg-white px-4 py-2.5">
                      <span className="flex w-6 items-center justify-center">
                        <i className="far fa-times text-2xl leading-none text-red-600"></i>
                      </span>

                      <div className="leading-snug">
                        <div className="font-bold">You have 1+ pending Team Member invitations</div>
                        <p className="text-base">
                          Either remove them or ask them to accept their invitation (will affect compensation as outlined in the Associate
                          Co-Servicing Agreement).
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          }
          type="danger"
        />
      ) : (
        <div className="space-y-4">
          <Alert
            heading={
              <p>
                Requesting a compliance review for this transaction will disable your ability to make any further edits once reviewing has
                begun.
              </p>
            }
            type="danger"
          />

          <p>
            Until the review begins, you can cancel this request by choosing the Actions {'>'} Cancel Transaction Review action. Once the
            transaction is under review, however, only a member of the compliance team can cancel the request and re-enable editing.
          </p>
        </div>
      )}
    </ConfirmationDialog>
  )
}
