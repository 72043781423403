import React, { Fragment } from 'react'
import classNames from 'classnames'
import { router } from '@inertiajs/react'
import LimitPicker from '@/Shared/LimitPicker'

export default ({ paginator = {} }) => {
  const { current_page, last_page, links } = paginator

  return (
    <div className="p-4 sm:px-6 lg:flex lg:items-center lg:justify-between">
      <div className="flex flex-wrap justify-around">
        {links.length > 3 && (
          <ul className="flex" role="navigation">
            {links.map((link, key) => (
              <li
                key={key}
                className={classNames('mr-2', {
                  'hidden lg:inline-flex': ['Previous', 'Next'].indexOf(link.label) == -1,
                })}
                aria-current={link.active ? link.label : 'page'}
              >
                <button
                  className={classNames('flex h-8 items-center justify-center rounded p-2 font-medium lg:w-8', {
                    'focus:ring-2 focus:ring-inset focus:ring-primary-500': link.url,
                    'bg-primary-500 text-white': link.active,
                    'text-primary-900 outline-none transition-all hover:bg-primary-500 hover:text-white': !link.active && link.url,
                    'cursor-auto outline-none': !link.url,
                    'opacity-25': (current_page == 1 && link.label == 'Previous') || (current_page == last_page && link.label == 'Next'),
                  })}
                  disabled={(current_page == 1 && link.label == 'Previous') || (current_page == last_page && link.label == 'Next')}
                  tabIndex={!link.url ? -1 : null}
                  onClick={() => router.visit(link.url)}
                >
                  {['Previous', 'Next'].indexOf(link.label) > -1 ? (
                    <div>
                      {link.label == 'Next' && <span className="pr-3 lg:hidden">{link.label}</span>}

                      <i
                        className={classNames({
                          'fas fa-chevron-left': link.label == 'Previous',
                          'fas fa-chevron-right': link.label == 'Next',
                        })}
                      ></i>

                      {link.label == 'Previous' && <span className="pl-3 lg:hidden">{link.label}</span>}
                    </div>
                  ) : (
                    link.label
                  )}
                </button>
              </li>
            ))}
          </ul>
        )}
      </div>

      <LimitPicker paginator={paginator}></LimitPicker>
    </div>
  )
}
