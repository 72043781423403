import { Fragment, useReducer } from 'react'
import Button from '@/Shared/Button'
import classNames from 'classnames'
import Dropdown from '@/Shared/Dropdown'
import DropdownItem from '@/Shared/DropdownItem'
import { router } from '@inertiajs/react'
import { Tooltip } from '@/Shared/Tooltip'
import { usePage } from '@inertiajs/react'

export default ({ onEdit }) => {
  const { lsn } = usePage().props

  const onBack = () => {
    if (history.length > 2) {
      history.back()
    } else {
      router.visit(route('lsns.index'))
    }
  }

  const onChangeVisibility = () => {
    router.post(route('lsns.unpublish', lsn.id))
  }

  const onDestroy = () => {
    // prettier-ignore
    router.delete(route('lsns.destroy', lsn.id), { preserveState: false })
  }

  const onRestore = () => {
    // prettier-ignore
    router.post(route('lsns.restore', lsn.id), { _method: 'patch' }, { preserveState: false })
  }

  return (
    <div className="relative flex bg-white py-3 md:pb-6">
      <div className="flex flex-1 flex-col space-y-3 px-8">
        <div className="relative z-10 flex items-center justify-between">
          <div className="flex flex-1 items-center justify-between">
            <div>
              <button
                type="button"
                className="-ml-5 inline-flex h-10 items-center rounded-md px-3 leading-none text-primary-500 outline-none transition-all duration-150 ease-in-out hover:bg-primary-100 hover:text-gray-800 focus:ring-2 focus:ring-primary-500"
                onClick={() => onBack()}
              >
                <i className="far fa-angle-left pr-3 text-xl"></i>
                <span className="font-medium">LSNs</span>
              </button>
            </div>

            {lsn.editable && (
              <div className="-mr-5 flex items-center justify-end gap-0.5">
                <Tooltip label="Edit" placement="bottom">
                  <Button theme="icon" onClick={onEdit} disabled={lsn.deleted_at}>
                    <span className="sr-only">Edit</span>
                    <i className="far fa-pen text-gray-500 group-hover:font-bold group-hover:text-primary-500"></i>
                  </Button>
                </Tooltip>

                <Dropdown id="actions-options" label="Actions" orientation="right" size="w-72">
                  {lsn.editable && (
                    <Fragment>
                      {lsn.published && (
                        <DropdownItem onClick={() => onChangeVisibility()}>
                          <i className="fas fa-eye-slash -ml-1 mr-4"></i>
                          Make LSN Private
                        </DropdownItem>
                      )}

                      {lsn.isAManager && (
                        <Fragment>
                          {Boolean(lsn.deleted_at) ? (
                            <DropdownItem colorClasses="text-green-600 hover:text-green-700" onClick={() => onRestore()}>
                              <i className="fas fa-undo mr-4"></i>
                              Restore
                            </DropdownItem>
                          ) : (
                            <DropdownItem colorClasses="text-red-500 hover:text-red-600" onClick={() => onDestroy()}>
                              <i className="fas fa-trash mr-4"></i>
                              Delete
                            </DropdownItem>
                          )}
                        </Fragment>
                      )}
                    </Fragment>
                  )}
                </Dropdown>
              </div>
            )}
          </div>
        </div>

        <div className="relative flex-1">
          <div className="text-center">
            <div className="mt-4 text-2xl font-medium">{lsn.name}</div>

            <span
              className={classNames(
                'inline-flex items-center whitespace-nowrap rounded px-2.5 py-1.5 text-sm font-medium uppercase leading-none',
                {
                  'bg-green-50 text-green-700': lsn.published,
                  'bg-red-100 text-red-600': !lsn.published,
                },
              )}
            >
              <span className="whitespace-pre-line leading-none">{lsn.published ? 'Public' : 'Private'}</span>
            </span>

            {Boolean(lsn.deleted_at) && (
              <div className="mt-2 flex justify-center">
                <div className="inline-block whitespace-nowrap rounded bg-red-100 px-2.5 py-1.5 text-sm font-medium uppercase leading-none text-red-600">
                  <span>Deleted</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
