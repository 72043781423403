import _ from 'lodash'
window._ = _

try {
  window.Popper = require('popper.js').default
} catch (e) {}

import axios from 'axios'
window.axios = axios
window.axios.defaults.withCredentails = true
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

let token = document.head.querySelector('meta[name="csrf-token"]')
if (token) {
  window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content
}
