import React from 'react'
import { router } from '@inertiajs/react'
import { usePage } from '@inertiajs/react'
import HeaderColumn from '@/Shared/Table/HeaderColumn'

export default ({ editable }) => {
  const { auth, filters } = usePage().props
  const { sortBy, direction } = filters

  const sort = (field) => {
    let values = filters ? { ...filters, sortBy: field } : { sortBy: field }
    values = { ...values, direction: field === sortBy ? (direction === 'asc' ? 'desc' : 'asc') : 'asc' }

    router.get(
      route(route().current(), { filters: encodeURIComponent(JSON.stringify(values)) }),
      {},
      {
        preserveState: true,
      },
    )
  }

  return (
    <tr>
      <HeaderColumn sorting={sortBy} direction={direction} field="name" onSort={(field) => sort(field)}>
        Name
      </HeaderColumn>
      {auth.user.roles.some((r) => ['Admin', 'hr_controller', 'Associate'].indexOf(r) >= 0) && !editable && (
        <HeaderColumn
          className="hidden md:w-72 lg:table-cell"
          sorting={sortBy}
          direction={direction}
          field="manager"
          onSort={(field) => sort(field)}
        >
          Managed By
        </HeaderColumn>
      )}
      {editable && <HeaderColumn className="hidden sm:table-cell">Status</HeaderColumn>}
      <HeaderColumn
        className="hidden sm:w-48 md:table-cell"
        sorting={sortBy}
        direction={direction}
        field="location"
        onSort={(field) => sort(field)}
      >
        Location
      </HeaderColumn>
      <HeaderColumn
        className="hidden sm:table-cell sm:w-24"
        sorting={sortBy}
        direction={direction}
        field="pros"
        onSort={(field) => sort(field)}
      >
        <i className="fas fa-users flex-1 text-center"></i>
      </HeaderColumn>
      <HeaderColumn noHover>
        <span className="sr-only">Edit</span>
      </HeaderColumn>
    </tr>
  )
}
