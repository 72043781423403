import { useState } from 'react'
import AccordionCard from '@/Shared/AccordionCard'
import AccordionItem from '@/Shared/AccordionItem'
import Button from '@/Shared/Button'
import classNames from 'classnames'
import Edit from './Edit'
import { usePage } from '@inertiajs/react'

export default () => {
  const { associate } = usePage().props.contact
  const [license, setLicense] = useState(null)
  const isExpired = (date) => new Date(date) <= new Date()

  return (
    <AccordionCard
      title={`State Licenses (${associate.licenses?.length || 0})`}
      actions={
        <Button theme="link" onClick={() => setLicense({})}>
          <i className="far fa-plus pr-1 text-sm"></i>
          <span>Add</span>
        </Button>
      }
      collapsed
    >
      {associate.licenses && associate.licenses.length > 0 ? (
        <div className="divide-y divide-dashed divide-gray-200 overflow-hidden rounded-md border border-gray-200">
          {associate.licenses.map((license) => (
            <AccordionItem key={license.id} onClick={() => setLicense(license)}>
              <div className="flex items-center gap-2">
                {associate.licenses?.find((license) => isExpired(license.expires_at)) && (
                  <i className="far fa-exclamation-circle mr-3 text-2xl text-red-600"></i>
                )}

                <div>
                  <div
                    className={classNames('font-semibold leading-tight', isExpired(license.expires_at) ? 'text-red-600' : 'text-gray-900')}
                  >
                    {license.state + ' ' + license.type} - #{license.license}
                  </div>

                  {license.brokerage_name && (
                    <div className={classNames('leading-tight', isExpired(license.expires_at) ? 'text-red-600' : 'text-gray-500')}>
                      {license.brokerage_name}
                    </div>
                  )}

                  <div
                    className={classNames('leading-tight', isExpired(license.expires_at) ? 'font-medium text-red-600' : 'text-gray-500')}
                  >
                    {isExpired(license.expires_at) ? 'Expired' : 'Expires'}{' '}
                    {new Date(license.expires_at).toLocaleDateString('en-us', {
                      month: 'long',
                      day: 'numeric',
                      year: 'numeric',
                    })}
                  </div>
                </div>
              </div>
            </AccordionItem>
          ))}
        </div>
      ) : (
        <div className="text-gray-700">No licenses have been added.</div>
      )}

      <Edit associate={associate} license={license} open={license != undefined} onClosed={() => setLicense(null)} />
    </AccordionCard>
  )
}
