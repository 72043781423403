export default ({ children, group }) => {
  return (
    <div className="my-4">
      <div className="mb-3 flex items-center">
        <div className="text-xl font-medium capitalize">
          {group.name} Tags ({group.tags.filter((tag) => !tag.deleted).length})
        </div>
      </div>

      <div className="flex flex-col">{children}</div>
    </div>
  )
}
