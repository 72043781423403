import { Node, mergeAttributes } from '@tiptap/core'
import { Node as ProseMirrorNode } from 'prosemirror-model'
  
export type VariableOptions = {
  HTMLAttributes: Record<string, any>,
  renderLabel: (props: {
    node: ProseMirrorNode,
  }) => string,
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    variable: {
      setVariable: (attributes?: { label: string }) => ReturnType,
    }
  }
}

export const Variable = Node.create<VariableOptions>({
  name: 'variable',

  addOptions() {
    return {
      HTMLAttributes: {
        class: 'px-1 bg-gray-200 border border-gray-300 rounded-md text-gray-600 uppercase font-medium',
      },
      renderLabel({ node }) {
        return `${node.attrs.label}`
      },
    }
  },

  draggable: true,

  group: 'inline',

  inline: true,

  selectable: true,

  atom: true,

  addAttributes() {
    return {
      label: {
        default: null,
        parseHTML: element => element.getAttribute('data-label'),
        renderHTML: attributes => {
          if (!attributes.label) {
            return {}
          }

          return {
            'data-label': attributes.label,
          }
        },
      },
    }
  },

  parseHTML() {
    return [
      { tag: `span[data-type="${this.name}"]` },
    ]
  },

  renderHTML({ HTMLAttributes, node }) {
    return [
      'span', 
      mergeAttributes({ 'data-type': this.name }, this.options.HTMLAttributes, HTMLAttributes),
      this.options.renderLabel({ node }),
    ]
  },

  addCommands() {
    return {
      setVariable: attributes => ({ chain }) => {
        return chain()
          .insertContent({ 
            type: this.name,
            attrs: attributes,
          })
          .run()
      },
    }
  },
})