import { useEffect, useRef, useState } from 'react'
import Banner from '@/Shared/Banner'
import Button from '@/Shared/Button'
import Dialog from '@/Shared/Dialog/Index'
import { router } from '@inertiajs/react'
import RichTextEditor from '@/Shared/RichTextEditor/Index'
import TextInput from '@/Shared/Forms/TextInput'
import { Tooltip } from '@/Shared/Tooltip'
import { useForm } from '@inertiajs/react'

export default ({ open, page: record, onClosed }) => {
  const focusRef = useRef(),
    scrollRef = useRef()

  const { errors, data, setData } = useForm()
  const [body, setBody] = useState('')
  const shortcodes = [
    { value: 'YEAR', label: 'Current Year' },
    { value: 'LSN-NAME', label: 'Name of your LSN' },
    { value: 'LSN-HOMEPAGE', label: "Link to your LSN's homepage" },
  ]

  useEffect(() => {
    if (record) {
      const page = record || {}
      setData({
        id: page.id,
        banner: page.banner || page.defaultBanner,
        body: page.body,
        seo_title: page.seo_title,
        seo_description: page.seo_description,
      })
      setBody(page.body)
    }
  }, [record])

  useEffect(() => {
    if (body != data.body) {
      setData({ ...data, body: body })
    }
  }, [body])

  const update = (data) => {
    router.post(
      route('lsns.pages.update', { lsn: record.lsn_id, page: data.id }),
      { ...data, _method: 'put' },
      {
        forceFormData: true,
        onSuccess: (_) => {
          onClosed()
        },
      },
    )
  }

  const submit = (event) => {
    event.preventDefault()

    if (data.banner && [record?.banner, record?.defaultBanner].indexOf(data.banner) < 0) {
      fetch(data.banner)
        .then((res) => res.blob())
        .then((blob) => {
          update({
            ...data,
            banner: null,
            file: blob,
          })
        })
    } else {
      update(data)
    }
  }

  return (
    <Dialog
      ref={scrollRef}
      focusRef={focusRef}
      footerActions={
        <Button form="page-form" type="submit" theme="solid">
          Save Changes
        </Button>
      }
      position="top"
      show={open}
      size="max-w-6xl"
      title={`${record?.name} Page`}
      subTitle="Page Settings"
      onClosed={onClosed}
    >
      <form id="page-form" className="space-y-5 sm:px-6" onSubmit={submit}>
        <div>
          <div className="mb-1 flex items-center gap-1">
            <span className="mb-0.5 block text-sm font-medium uppercase text-gray-500">Banner</span>

            <Tooltip
              label={
                <span className="inline-block align-middle">
                  <span>This image will appear at the top of your page. To upload your own image, use the</span>
                  <i className="far fa-camera px-1"></i>
                  <span>button to upload an image from your device.</span>
                </span>
              }
              placement="bottom"
            >
              <i className="far fa-question-circle ml-1.5 cursor-help text-xl text-gray-400"></i>
            </Tooltip>
          </div>
          <Banner image={data.banner} onChanged={(image) => setData({ ...data, banner: image })} />
        </div>

        <div>
          <div className="mb-1 flex items-center gap-1">
            <span className="mb-0.5 block text-sm font-medium uppercase text-gray-500">Page Content</span>
          </div>
          <RichTextEditor value={body} shortcodes={shortcodes} onChange={(value) => setBody(value)} />
          {errors.body && <div className="mt-1 text-red-600">{errors.body}</div>}
        </div>

        <TextInput
          label={
            <div className="mb-1 flex items-center gap-1">
              <span>SEO Title</span>

              <Tooltip
                label="This represents the name of this page and instructs search engines how to display this page in their search results."
                placement="bottom"
              >
                <i className="far fa-question-circle ml-1.5 cursor-help text-xl text-gray-400"></i>
              </Tooltip>
            </div>
          }
          name="seo_title"
          value={data.seo_title}
          maxLength="100"
          error={errors.seo_title}
          onChange={(value) => setData({ ...data, seo_title: value })}
        />

        <TextInput
          label={
            <div className="mb-1 flex items-center gap-1">
              <span>SEO Description</span>

              <Tooltip
                label="This is a summary description of this page that appears alongside your title in search engine results."
                placement="bottom"
              >
                <i className="far fa-question-circle ml-1.5 cursor-help text-xl text-gray-400"></i>
              </Tooltip>
            </div>
          }
          name="seo_description"
          value={data.seo_description}
          maxLength="255"
          error={errors.seo_description}
          onChange={(value) => setData({ ...data, seo_description: value })}
        />
      </form>
    </Dialog>
  )
}
