import classNames from 'classnames'

export default function Stage({ horizontal, transaction, ...props }) {
  const stage = transaction.stage
  const sections = transaction.checklist.sections.map((s) => s.name)
  const latestStageIndex = stage ? transaction.checklist.sections.findIndex((s) => s.base_section_id == stage.section_id) : 0

  return stage ? (
    <div className={props.className}>
      <div className="-mx-1 flex justify-between py-2">
        {sections.map((s, index) => (
          <div className={classNames('mx-1 h-1 w-full', index > latestStageIndex ? 'bg-gray-300' : 'bg-green-900')} key={s}></div>
        ))}
      </div>
      {horizontal ? (
        <div className="mt-0.5 inline-flex items-center rounded bg-blue-200 bg-opacity-40 px-3 pb-1 pt-1.5 text-sm font-medium uppercase leading-none text-blue-700 shadow">
          {stage.name}
        </div>
      ) : (
        <div className="text-center">
          <span className="text-lg font-medium text-black">{stage.name}</span>
          <div className="leading-tight text-gray-500">{stage.elapsed}</div>
        </div>
      )}
    </div>
  ) : (
    <></>
  )
}
