import React, { useRef, useContext } from 'react'
import { BlockUIContext } from '@/Shared/BlockUI/BlockUIContext'
import Button from '@/Shared/Button'
import Dialog from '@/Shared/Dialog/Index'
import TextInput from '@/Shared/Forms/TextInput'
import { useForm } from '@inertiajs/react'

export default ({ checklistId, open, onClosed }) => {
  let focusRef = useRef()
  const { loading, setLoading } = useContext(BlockUIContext)
  const form = useForm()
  const { data, setData, post, processing, reset } = form

  const submit = (event) => {
    event.preventDefault()

    setLoading(true)

    post(route('admin.checklists.publish', checklistId), {
      preserveState: false,
      onFinish: () => {
        reset()
        onClosed()
        setLoading(false)
      },
    })
  }

  return (
    <Dialog
      focusRef={focusRef}
      footerActions={
        <Button form="publishForm" type="submit" theme="solid" disabled={!open || loading || processing}>
          Publish Changes
        </Button>
      }
      isLoading={loading}
      show={open}
      title="Publish to Transaction"
      onClosed={onClosed}
    >
      <form id="publishForm" className="flex h-full flex-col" onSubmit={submit}>
        <p className="pb-3">Which transaction would you like to publish these changes to?</p>

        <div className="flex flex-col items-center">
          <TextInput
            ref={focusRef}
            type="number"
            label="Transaction #"
            value={data.transaction_id}
            onChange={(value) => setData({ ...data, transaction_id: value })}
            required
            disabled={loading}
          />
        </div>
      </form>
    </Dialog>
  )
}
