import { useContext, useEffect, useRef, useState } from 'react'
import { AccordionContext } from '@/Shared/Accordion'
import Button from '@/Shared/Button'
import Checkbox from '@/Shared/Forms/Checkbox'
import { router } from '@inertiajs/react'

export default ({ user }) => {
  const { onSaved } = useContext(AccordionContext)
  const [data, setData] = useState({
    setting: 'visible',
    visible: user.visible === 'public',
    visible_internally: ['public', 'internal'].indexOf(user.visible) >= 0,
  })
  const [errors, setErrors] = useState({})

  const submit = (event) => {
    event.preventDefault()

    axios
      .post(route('api.users.update', user.id), { ...data, _method: 'PATCH' })
      .then((_) => {
        router.reload({
          onFinish: () => {
            onSaved()
          },
        })
      })
      .catch((error) => {
        if (error.response != undefined) {
          const { status } = error.response
          const VALIDATION_ERROR = 422
          if (status == VALIDATION_ERROR) {
            setErrors(error.response.data.errors)
          }
        }
      })
  }

  return (
    <div>
      <p className="mb-3">
        To make your website viewable to others, choose whether to publish it to all LSNPros members internally, the general public or both.
      </p>

      {Object.keys(errors).length > 0 && (
        <div className="mt-3 space-y-3 rounded-lg border border-red-500 bg-red-50 p-4 text-red-600">
          {errors.visible && <p className="text-center" dangerouslySetInnerHTML={{ __html: errors.visible[0] }}></p>}
        </div>
      )}

      <form className="mx-auto mt-4 max-w-xl" onSubmit={submit}>
        <div className="mb-4 ml-2">
          <Checkbox
            label="Viewable Internally"
            description="Your website is viewable to all registered PROs internally."
            name="visible_internally"
            value={data.visible_internally}
            onChange={(checked) => setData({ ...data, visible_internally: checked })}
            disabled={!user.published || data.visible}
          />
        </div>

        <div className="mb-4 ml-2">
          <Checkbox
            label="Public"
            description="Your website is viewable to the General Public and search engines such as Google, Bing, etc."
            name="visible"
            value={data.visible}
            onChange={(checked) => setData({ ...data, visible_internally: checked || data.visible_internally, visible: checked })}
            disabled={!user.published}
          />
        </div>

        <div className="flex justify-end">
          <Button type="submit" theme="solid" disabled={!user.published}>
            Save
          </Button>
        </div>
      </form>
    </div>
  )
}
