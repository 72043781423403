import React, { useRef } from 'react'

import Button from '@/Shared/Button'
import Dialog from '@/Shared/Dialog/Index'

export default ({ buttonText, buttonTheme, children, open, size, title, onCanceled, onApproved }) => {
  let focusRef = useRef()

  return (
    <Dialog
      focusRef={focusRef}
      footerActions={
        <Button
          form="deleteConfirmation"
          type="submit"
          colors={!buttonTheme && 'bg-red-600 text-white hover:ring-red-700 focus:ring-red-600'}
          theme={buttonTheme || 'solid'}
          onClick={onApproved}
        >
          {buttonText || 'Yes, delete it'}
        </Button>
      }
      headerBackgroundColor="bg-red-700"
      size={size}
      show={open}
      title={title || 'Delete Confirmation'}
      onClosed={onCanceled}
      focusCancelButton
    >
      {children}
    </Dialog>
  )
}
