import { useContext, useRef, useState } from 'react'
import { AccordionContext } from '@/Shared/Accordion'
import Banner from '@/Shared/Banner'
import Button from '@/Shared/Button'
import { router } from '@inertiajs/react'

export default ({ user }) => {
  const { onSaved } = useContext(AccordionContext)
  const [image, setImage] = useState(user.banner || user.primary_service_banner)

  const submit = (event) => {
    event.preventDefault()

    if (image === user.banner) {
      onSaved()
      return
    }

    const formData = new FormData()

    // Convert blob data url to blob, where applicable and submit
    fetch(image)
      .then((res) => res.blob())
      .then((blob) => {
        formData.append('setting', 'banner')
        formData.append('_method', 'PATCH')
        if (image) {
          formData.append('file', blob, image)
        }

        axios
          .post(route('api.users.update', user.id), formData, {
            headers: { 'Content-Type': `multipart/form-data; boundary=${formData._boundary}` },
          })
          .then((_) =>
            router.reload({
              onFinish: () => {
                onSaved()
              },
            }),
          )
      })
  }

  return (
    <div>
      <form onSubmit={submit}>
        <p className="mb-3">
          This image appears at the top of your PRO-Website at LSNPros.com. We have provided a default image that relates to your primary
          service. You may optionally upload a custom image to replace this that is unique to you.
        </p>

        <Banner image={image} onChanged={(image) => setImage(image)} />

        <p className="mb-3 text-center font-medium text-primary-400">
          <strong>Note:</strong> Recommended size is 1200 x 300.
        </p>

        <div className="flex justify-end">
          <Button type="submit" theme="solid">
            Save
          </Button>
        </div>
      </form>
    </div>
  )
}
