import React from 'react'
import classNames from 'classnames'

const Element = ({ as, children, className, href, onClick }) => {
  return {
    button: <button className={className}>{children}</button>,
    a: (
      <a className={`block ${className}`} href={href} target="_blank">
        {children}
      </a>
    ),
    div: (
      <div className={className} onClick={onClick}>
        {children}
      </div>
    ),
  }[as]
}

export default ({ as, breakAll, className, href, icon, label, noBorder, primary, value, onClick }) => {
  if (!value) {
    return <></>
  }

  return (
    <Element
      as={as || 'div'}
      className={classNames(
        'py-1.5 px-4 leading-none transition-colors duration-300',
        {
          'border-t border-gray-200': !noBorder,
          'cursor-pointer hover:bg-gray-100': href || onClick,
        },
        className,
      )}
      href={href}
      onClick={onClick}
    >
      <div className="flex items-center">
        <div className="my-1 flex-grow space-y-1">
          {label && (
            <div
              className={classNames('mb-0.5 text-sm leading-none', {
                'text-gray-500': !primary,
                'font-medium text-orange-500': primary,
              })}
            >
              {label}
            </div>
          )}
          {/* prettier-ignore */}
          <div
            className={classNames('whitespace-pre-line', breakAll ? 'break-all' : 'break-words',
              href || onClick ? 'text-primary-500' : 'text-gray-900',
            )}
          >
            {value}
          </div>
        </div>

        {icon && (
          <div className="ml-3 flex h-8 w-8 flex-shrink-0 items-center justify-center text-gray-500">
            <i className={icon}></i>
          </div>
        )}
      </div>
    </Element>
  )
}
