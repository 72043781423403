import Avatar from '@/Shared/Avatar'
import Button from '@/Shared/Button'
import Notice from '@/Shared/Notice'
import { Tooltip } from '@/Shared/Tooltip'

const Actions = () => null

const SimilarContacts = ({ children, contacts, dismissable, heading, subHeading, vertical, onDismiss }) => {
  const actions = children?.type === Actions ? children : null

  return (
    <div>
      <div className="space-y-4 border-t border-b border-gray-200 bg-yellow-50 p-6">
        <Notice
          heading={heading || `The following PRO${contacts.length == 1 ? ' has' : 's have'} similar contact information:`}
          subHeading={
            subHeading ||
            'Linking this contact with a PRO will notify you whenever they make changes to the contact information they share.'
          }
        />

        <div className="mx-auto max-w-xl divide-y divide-gray-300 rounded-b-lg rounded-t-lg border border-gray-300 shadow">
          {contacts.map((similarContact) => (
            <div className="overflow-hidden" key={similarContact.id}>
              <div className="relative z-10 rounded-b-lg rounded-t-lg bg-white px-4 py-3">
                {dismissable && (
                  <div className="absolute right-1 top-1">
                    <Tooltip label="Dismiss" placement="bottom">
                      <Button theme="toolbar-icon" onClick={() => onDismiss(similarContact)}>
                        <span className="sr-only">Close</span>
                        <i className="fal fa-times text-2xl leading-none text-opacity-80"></i>
                      </Button>
                    </Tooltip>
                  </div>
                )}

                <div className="flex flex-grow flex-wrap items-center justify-center gap-3 text-left">
                  <div className="space-y-1">
                    <Avatar contact={similarContact} height="h-14" width="w-14" />

                    <div className="flex items-center justify-center leading-none">
                      <div className="mt-0.5 inline-flex items-center whitespace-nowrap rounded-full bg-purple-100 px-2.5 py-1.5 text-sm font-medium uppercase leading-none text-purple-800">
                        <span>PRO</span>
                      </div>
                    </div>
                  </div>

                  <div className="mr-6 flex-1 leading-snug text-gray-600 sm:mb-0">
                    <div className="font-semibold text-blue-500">{similarContact.full_name}</div>

                    {similarContact.industry && <div className="font-medium">{similarContact.industry}</div>}
                    {similarContact.company && <div>{similarContact.company}</div>}

                    {similarContact.phone_numbers?.length > 0 && (
                      <div>
                        <a
                          className="focus:outline-none focus:ring focus:ring-blue-500"
                          href={`tel:${similarContact.phone_numbers[0].phone}`}
                        >
                          {similarContact.phone_numbers[0].phone_formatted}
                        </a>
                      </div>
                    )}

                    {similarContact.emails?.length > 0 && (
                      <div>
                        <a className="focus:outline-none focus:ring focus:ring-blue-500" href={`mailto:${similarContact.emails[0].email}`}>
                          {similarContact.emails[0].email}
                        </a>
                      </div>
                    )}
                  </div>

                  {!vertical && <div className="mt-6">{actions?.props.children({ contact: similarContact })}</div>}
                </div>

                {vertical && (
                  <div className="mt-3 flex items-center justify-center gap-3 border-t border-gray-200 pt-3">
                    {actions?.props.children({ contact: similarContact })}
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

SimilarContacts.Actions = Actions

SimilarContacts.defaultProps = {
  dismissable: true,
}

export default SimilarContacts
