import { useContext, useEffect, useRef, useState } from 'react'
import { AccordionContext } from '@/Shared/Accordion'
import Button from '@/Shared/Button'
import TextInput from '@/Shared/Forms/TextInput'
import { router } from '@inertiajs/react'

export default ({ user }) => {
  const focusRef = useRef()
  const { onSaved } = useContext(AccordionContext)
  const [data, setData] = useState({
    setting: 'name',
    first_name: user.first_name,
    middle_name: user.middle_name,
    last_name: user.last_name,
    suffix: user.suffix,
  })
  const [errors, setErrors] = useState({})

  useEffect(() => {
    focusRef.current.focus()
  }, [])

  const submit = (event) => {
    event.preventDefault()

    axios
      .post(route('api.users.update', user.id), { ...data, _method: 'PATCH' })
      .then((_) =>
        router.reload({
          onFinish: () => {
            onSaved()
          },
        }),
      )
      .catch((error) => {
        if (error.response != undefined) {
          const { status } = error.response
          const VALIDATION_ERROR = 422
          if (status == VALIDATION_ERROR) {
            setErrors(error.response.data.errors)
          }
        }
      })
  }

  return (
    <div>
      <form className="mx-auto mt-4 max-w-lg" onSubmit={submit}>
        <TextInput
          ref={focusRef}
          label="First name"
          name="first_name"
          error={errors.first_name}
          value={data.first_name}
          onChange={(value) => setData({ ...data, first_name: value })}
          required
        />

        <TextInput
          label="Middle name"
          name="middle_name"
          error={errors.middle_name}
          value={data.middle_name}
          onChange={(value) => setData({ ...data, middle_name: value })}
        />

        <TextInput
          label="Last name"
          name="last_name"
          error={errors.last_name}
          value={data.last_name}
          onChange={(value) => setData({ ...data, last_name: value })}
          required
        />

        <TextInput
          label="Suffix"
          name="suffix"
          error={errors.suffix}
          value={data.suffix}
          onChange={(value) => setData({ ...data, suffix: value })}
        />

        <div className="flex justify-end">
          <Button type="submit" theme="solid">
            Save
          </Button>
        </div>
      </form>
    </div>
  )
}
