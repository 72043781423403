import { Fragment, useContext, useRef, useState } from 'react'
import Button from '@/Shared/Button'
import { useForm } from '@inertiajs/react'
import Edit from './Edit'
import DeleteConfirmationDialog from '@/Shared/Dialog/DeleteConfirmationDialog'
import Dialog from '@/Shared/Dialog/Index'
import { SubtasksContext } from './SubtasksContext'

export default ({ open, record, onClosed }) => {
  let focusRef = useRef()
  const [deleting, setDeleting] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const { onSubtaskDeleting } = useContext(SubtasksContext)
  const { delete: destroy } = useForm({})
  const type = record?.parent_id != undefined ? 'Subtask' : 'Step'
  const name = record?.nameWithoutTags ? `${type}: "${record?.nameWithoutTags}"` : type

  const onDelete = () => {
    if (record.parent_id != undefined) {
      onSubtaskDeleting(record.id || record.temp_id)
      onClosed()
      setDeleting(false)
    } else {
      destroy(route('admin.steps.destroy', { step: record }), {
        onSuccess: () => {
          onClosed()
        },
        onFinish: () => {
          setDeleting(false)
        },
      })
    }
  }

  return (
    <Dialog
      focusRef={focusRef}
      footerActionsLeft={
        (record?.id || record?.temp_id) && (
          <button
            role="button"
            className="group relative inline-flex h-10 w-10 items-center justify-center rounded leading-none transition-all duration-150 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-red-600"
            onClick={() => setDeleting(true)}
          >
            <span className="sr-only">Delete Step</span>
            <i className="fas fa-trash text-xl text-gray-800 group-hover:text-red-600"></i>
          </button>
        )
      }
      footerActions={
        <Button form={record?.parent_id != undefined ? 'subtaskForm' : 'stepForm'} type="submit" theme="solid" disabled={submitting}>
          Save
        </Button>
      }
      headerBackgroundColor={record?.parent_id != undefined && 'bg-gray-600'}
      position="top"
      show={open}
      size={record?.parent_id != undefined ? 'sm:mt-6 lg:max-w-[85%] 2xl:max-w-[75%]' : 'lg:max-w-[90%] 2xl:max-w-[80%]'}
      title={record?.id ? `Edit ${name}` : `New ${type}`}
      onClosed={onClosed}
    >
      <Edit record={record} onSubmitting={(value) => setSubmitting(value)} onClosed={onClosed} />

      <DeleteConfirmationDialog open={deleting} onCanceled={() => setDeleting(false)} onApproved={() => onDelete()}>
        <Fragment>
          <p>Are you sure you want to delete this {type}?</p>

          {record?.parent_id == undefined && <div className="mt-2 bg-gray-100 p-4" dangerouslySetInnerHTML={{ __html: record?.name }} />}
        </Fragment>
      </DeleteConfirmationDialog>
    </Dialog>
  )
}
