import { useEffect, useRef, useState } from 'react'
import Button from '@/Shared/Button'
import ChecklistEdit from './Edit'
import ChecklistTree from './Tree'
import Main from '@/Shared/Layouts/Main'
import { usePage } from '@inertiajs/react'

const AdminChecklistIndex = () => {
  const focusRef = useRef()
  const { checklists } = usePage().props
  const [openDrawer, setOpenDrawer] = useState(null)

  useEffect(() => {
    focusRef.current.focus()
  }, [])

  return (
    <div>
      <div className="flex flex-wrap items-stretch justify-between bg-white px-4 py-4 sm:px-8">
        <div className="flex w-full flex-wrap items-center justify-start">
          <h1 className="pr-4 text-lg font-medium text-gray-800">Checklists</h1>

          <div className="flex flex-1 items-center justify-end">
            <Button ref={focusRef} theme="solid" onClick={() => setOpenDrawer('edit')}>
              <i className="far fa-plus pr-2 text-lg"></i> New
            </Button>
          </div>
        </div>
      </div>

      <div className="mx-4 my-6 max-w-xl rounded bg-white shadow sm:mx-auto">
        <div className="flex flex-col">
          <div className="inline-block min-w-full align-middle">
            <div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
              {checklists && checklists.length > 0 && <ChecklistTree list={checklists} />}
            </div>
          </div>
        </div>
      </div>

      <ChecklistEdit open={openDrawer === 'edit'} onClosed={() => setOpenDrawer(null)} />
    </div>
  )
}

// Persistent layout
// Docs: https://inertiajs.com/pages#persistent-layouts
AdminChecklistIndex.layout = (page) => <Main title="Checklist Administration" children={page} />

export default AdminChecklistIndex
