import React, { Fragment, useState } from 'react'
import classNames from 'classnames'
import { DndContext, DragOverlay, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core'
import { arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { Item, SortableItem } from './Block'
import { nanoid } from 'nanoid'

const Draggable = ({ children, items, activeId, onChange, onRowReset, ...props }) => {
  const activeItem = items.find((item) => item.id === activeId)

  return (
    <Fragment>
      <SortableContext items={items} strategy={verticalListSortingStrategy}>
        {items.map((item, index) => (
          <SortableItem
            key={index}
            index={index}
            item={item}
            onChange={(value) => onChange(index, value)}
            onRowReset={() => onRowReset(index)}
            {...props}
          />
        ))}
      </SortableContext>

      <DragOverlay>{activeItem ? <Item item={activeItem} {...props} /> : null}</DragOverlay>
    </Fragment>
  )
}

const MultipleInputBlock = React.forwardRef(({ data, onChange, containerClassName, hideAddButton, ...props }, ref) => {
  const [activeId, setActiveId] = useState(null)
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  )

  const change = (index, value) => {
    let updated = [...data]
    updated[index] = value

    if (props.hasPrimary && value.primary) {
      updated = updated.map((u, i) => (i == index ? u : { ...u, primary: false }))
    }

    onChange(updated)
  }

  function handleDragStart(event) {
    const { active } = event

    setActiveId(active.id)
  }

  const handleDragEnd = (event) => {
    const { active, over } = event

    if (active.id !== over.id) {
      const oldIndex = data.findIndex((item) => item.id == active.id)
      const newIndex = data.findIndex((item) => item.id == over.id)

      let sorted = arrayMove(data, oldIndex, newIndex).map((item, index) => ({ ...item, sort_order: index + 1 }))

      onChange(sorted)
    }
  }

  const reset = (index) => {
    let updated = data.filter((_, i) => i !== index)

    if (updated.length == 0) {
      updated = [...updated.concat(props.hasPrimary ? { primary: true } : {})]
    } else if (props.hasPrimary && data[index].primary) {
      updated[0].primary = true
    }

    onChange(updated)
  }

  return (
    <Fragment>
      <div className={classNames('-mx-4 divide-y divide-gray-300 sm:-mx-6', containerClassName)}>
        {props.isSortable ? (
          <DndContext sensors={sensors} collisionDetection={closestCenter} onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
            <Draggable
              items={data}
              activeId={activeId}
              onChange={(index, value) => change(index, value)}
              onRowReset={(index) => reset(index)}
              {...props}
            />
          </DndContext>
        ) : (
          data?.map((item, index) => (
            <Item
              ref={ref}
              key={index}
              index={index}
              item={item}
              onChange={(value) => change(index, value)}
              onRowReset={() => reset(index)}
              {...props}
            />
          ))
        )}
      </div>

      {!hideAddButton && (
        <button
          type="button"
          className={classNames(
            'mt-1 rounded p-1.5 font-medium leading-none text-primary-700 outline-none transition-colors duration-150 ease-in-out hover:text-primary-900 focus:ring-2 focus:ring-primary-500',
          )}
          onClick={() =>
            onChange([
              ...data.concat({
                id: nanoid(),
                sort_order: data.length + 1,
              }),
            ])
          }
        >
          + add one more
        </button>
      )}
    </Fragment>
  )
})

MultipleInputBlock.defaultProps = {
  isSortable: false,
}

export default MultipleInputBlock
