import { useContext, useEffect, useRef, useState } from 'react'
import { AccordionContext } from '@/Shared/Accordion'
import Button from '@/Shared/Button'
import MultipleInputBlock from '@/Shared/Forms/MultipleInputBlock'
import Select from '@/Shared/Forms/Select'
import TextInput from '@/Shared/Forms/TextInput'
import { router } from '@inertiajs/react'

export default ({ user }) => {
  const focusRef = useRef()
  const { onSaved } = useContext(AccordionContext)

  const [data, setData] = useState({
    setting: 'phone_numbers',
    phone_numbers: user.phone_numbers?.length > 0 ? user.phone_numbers : [{}],
  })
  const [phoneTypes] = useState([
    { label: 'Mobile', value: 'Mobile' },
    { label: 'Work', value: 'Work' },
    { label: 'Home', value: 'Home' },
    { label: 'Fax', value: 'Fax' },
  ])

  useEffect(() => {
    if (focusRef.current) {
      focusRef.current.focus()
    }
  }, [])

  const submit = (event) => {
    event.preventDefault()

    let transformed = { ...data, phone_numbers: { ...data.phone_numbers.filter((phone) => Boolean(phone.phone)) } }

    axios.post(route('api.users.update', user.id), { ...transformed, _method: 'PATCH' }).then((_) => {
      router.reload({
        onFinish: () => {
          onSaved()
        },
      })
    })
  }

  return (
    <div>
      <p>These numbers will be displayed with your contact information, and are for display purposes only.</p>

      <form className="mx-auto mt-4 max-w-lg" onSubmit={submit}>
        <MultipleInputBlock
          data={data.phone_numbers}
          onChange={(value) => setData({ ...data, phone_numbers: value })}
          hasPrimary
          render={({ data, onChange }) => (
            <div className="flex-1 sm:flex">
              <TextInput
                ref={focusRef}
                classes="sm:flex-grow"
                name="phone"
                placeholder="Number"
                value={data.phone || ''}
                onChange={(value) => onChange(value, 'phone')}
              />

              <Select
                classes="mt-2 sm:flex-grow sm:ml-3 sm:mt-0"
                name="type"
                options={phoneTypes}
                placeholder="Type"
                value={data.type}
                isClearable={false}
                onChange={(selected) => onChange(selected && selected.value, 'type')}
                creatable
              />
            </div>
          )}
        />

        <div className="flex justify-end">
          <Button type="submit" theme="solid">
            Save
          </Button>
        </div>
      </form>
    </div>
  )
}
