import { useEffect, useState } from 'react'
import Button from '@/Shared/Button'
import HeaderColumn from '@/Shared/Table/HeaderColumn'
import { router } from '@inertiajs/react'
import PreviewExisting from './PreviewExisting'
import PreviewTemporary from './PreviewTemporary'
import { Tooltip } from '@/Shared/Tooltip'
import { usePage } from '@inertiajs/react'

const MemberGroup = ({ members, noHeader, onPreview, onResend }) => {
  const { lsn } = usePage().props
  const showServices = members.filter((member) => member.service).length > 0

  return (
    <div className="bg-white shadow sm:rounded">
      <div className="flex flex-col">
        <div className="overflow-x-auto">
          <div className="inline-block min-w-full align-middle">
            <div className="overflow-hidden border-b border-gray-200 shadow">
              <table className="min-w-full divide-y divide-gray-300">
                {!noHeader && (
                  <thead>
                    <tr>
                      <HeaderColumn noBorder>Name</HeaderColumn>
                      <HeaderColumn className="hidden w-[35%] max-w-[300px] 2xl:table-cell">
                        {showServices ? 'Service Provided' : 'Last Sent'}
                      </HeaderColumn>
                      <HeaderColumn className="sm:w-[10%] sm:min-w-[100px]" noBorder noHover>
                        <span className="sr-only">Edit</span>
                      </HeaderColumn>
                    </tr>
                  </thead>
                )}

                <tbody className="divide-gray-20 divide-y bg-white">
                  {members.map((member) => (
                    <tr className="hover:bg-gray-100" key={member.id}>
                      <td className="py-3 pl-4 pr-4 sm:pl-6" onClick={() => !member.disabled && onPreview(member)}>
                        <div className="flex items-center gap-5">
                          <div className="flex h-10 w-10 flex-shrink-0 items-center justify-center">
                            <div>
                              <img className="h-10 w-10 rounded-full ring-1 ring-gray-200" src={member.avatar} alt="" />
                            </div>
                          </div>

                          <div>
                            <div className="space-x-1">
                              <span className="font-semibold">{member.name || member.email}</span>
                            </div>
                            {member.company && <div className="leading-tight text-gray-700">{member.company}</div>}
                            <div className="2xl:hidden">
                              {showServices ? member.service || '--' : <span className="text-sm">Last sent {member.last_sent_at}</span>}
                            </div>
                            {member.position && (
                              <div className="my-0.5 inline-block rounded bg-gray-200 px-2.5 py-1.5 text-sm font-medium uppercase leading-none text-gray-600">
                                <span>{member.position.name}</span>
                              </div>
                            )}
                          </div>
                        </div>
                      </td>

                      <td
                        className="hidden cursor-pointer px-4 py-3 text-gray-700 sm:px-6 2xl:table-cell"
                        onClick={() => !member.disabled && onPreview(member)}
                      >
                        {showServices ? member.service || '--' : member.last_sent_at}
                      </td>

                      <td className="cursor-pointer whitespace-nowrap py-3 pr-3" onClick={() => !member.disabled && onPreview(member)}>
                        <div className="flex items-center justify-end">
                          {lsn.editable && !showServices && (
                            <Tooltip label="Resend Invitation" placement="bottom">
                              <Button
                                theme="icon"
                                onClick={(e) => {
                                  e.stopPropagation()
                                  onResend(member)
                                }}
                              >
                                <i className="far fa-envelope text-xl text-gray-500 group-hover:font-bold group-hover:text-primary-500"></i>
                              </Button>
                            </Tooltip>
                          )}

                          <Button theme="icon">
                            <i className="fal fa-angle-right text-2xl leading-none"></i>
                          </Button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default () => {
  const { lsn } = usePage().props
  const { invitations, members } = lsn
  const [member, setMember] = useState(false)
  const [previewing, setPreviewing] = useState(false)
  const leadershipTeam = members
    .filter((member) => member.position_id)
    .sort((a, b) => {
      return a.position_id - b.position_id
    })
  const activeMembers = members
    .filter((member) => !member.position_id && member.active && !member.disabled)
    .sort((a, b) => sortByName(a, b))
  const inactiveMembers = members
    .filter((member) => !member.position_id && member.disabled | !member.active)
    .sort((a, b) => sortByName(a, b))

  function sortByName(a, b) {
    let nameA = a.name.toLowerCase(),
      nameB = b.name.toLowerCase()

    if (nameA < nameB) {
      return -1
    }
    if (nameA > nameB) {
      return 1
    }
    return 0
  }

  useEffect(() => {
    if (previewing) {
      setPreviewing(null)
    }
  }, [invitations])

  useEffect(() => {
    if (!previewing) {
      setTimeout(() => {
        setMember(null)
      }, 300)
    }
  }, [previewing])

  const preview = (member) => {
    setMember(member)
    setPreviewing(true)
  }

  const onResend = (member) => {
    router.post(route('lsns.invitations.resend', { lsn: lsn.id, invitation: member.id }))
  }

  return (
    <div className="mb-12 space-y-6 p-4 sm:p-0">
      {leadershipTeam.length > 0 && (
        <div>
          <h3 className="mb-4 text-xl font-medium text-primary-500">Leadership Team</h3>

          <MemberGroup members={leadershipTeam} onPreview={(member) => preview(member)} />
        </div>
      )}

      {invitations?.length > 0 && (
        <div>
          <h3 className="mb-4 text-xl font-medium">
            <i className="fal fa-clock mr-3 text-gray-500"></i>
            Pending Invitations
          </h3>

          {invitations.length > 0 ? (
            <div className="mt-4">
              <MemberGroup members={invitations} onPreview={(member) => preview(member)} onResend={(member) => onResend(member)} />
            </div>
          ) : (
            <div className="font-medium text-gray-600">This LSN does not currently have any active members.</div>
          )}
        </div>
      )}

      <div>
        <h3 className="text-xl font-medium text-green-600">Public Members</h3>

        {activeMembers.length > 0 ? (
          <div className="mt-4">
            <MemberGroup members={activeMembers} onPreview={(member) => preview(member)} />
          </div>
        ) : (
          <div className="font-medium text-gray-600">This LSN does not currently have any active members.</div>
        )}
      </div>

      {inactiveMembers.length > 0 && (
        <div>
          <h3 className="mb-4 text-xl font-medium text-gray-500">Unpublished Members</h3>

          <MemberGroup members={inactiveMembers} onPreview={(member) => preview(member)} />
        </div>
      )}

      <PreviewExisting
        record={member != null && member?.contact_id && member}
        onClosed={() => {
          setPreviewing(null)
          setMember(null)
        }}
      />

      <PreviewTemporary
        record={member != null && !member?.contact_id && member}
        onClosed={() => {
          setPreviewing(null)
          setMember(null)
        }}
      />
    </div>
  )
}
