import { Fragment, useEffect, useRef, useState } from 'react'
import AccordionCard from '@/Shared/AccordionCard'
import Button from '@/Shared/Button'
import ContactEdit from './Edit'
import Dropdown from '@/Shared/Dropdown'
import DropdownItem from '@/Shared/DropdownItem'
import { router } from '@inertiajs/react'
import PropertyListItem from '@/Shared/PropertyListItem'
import SlideOver from '@/Shared/SlideOver'
import { usePage } from '@inertiajs/react'

export default ({ relationship: record, onClosed }) => {
  const { contact: currentContact } = usePage().props
  let focusRef = useRef()
  const [contact, setContact] = useState(null)
  const [editing, setEditing] = useState(null)

  useEffect(() => setContact(record), [record])

  const handleClosed = () => {
    setContact(null)
    setTimeout(() => onClosed(), 500)
  }

  const onRemove = () => {
    router.delete(route('api.contacts.relationships.destroy', { contact: record.contact_id, relationship: record.id }), {
      onSuccess: () => {
        handleClosed()
      },
    })
  }

  return (
    <SlideOver
      focusRef={focusRef}
      show={contact != null}
      size="max-w-md"
      title={contact?.name}
      onClosed={handleClosed}
      hideCloseButton
      hideFooter
      hidePadding
    >
      {contact && (
        <Fragment>
          <div className="relative z-10 flex justify-end p-4">
            <Dropdown id="actions-options" label="Actions" orientation="right" size="w-64">
              <DropdownItem onClick={() => onRemove()}>
                <i className="fas fa-unlink w-8 text-gray-500 group-hover:text-gray-600"></i>
                Remove Relationship
              </DropdownItem>
            </Dropdown>
          </div>

          <AccordionCard
            title="About this Contact"
            actions={
              <Button theme="icon" onClick={() => setEditing(contact)}>
                <i className="far fa-pen text-gray-500 group-hover:font-bold group-hover:text-blue-500"></i>
              </Button>
            }
            hideBorder
            indented={false}
          >
            <div className="border-b border-gray-200">
              <PropertyListItem label="Name" value={contact.name} />
              <PropertyListItem label={`Relationship to ${currentContact.full_name}`} value={contact.label} />
              <PropertyListItem label="Type" value={contact.professional ? 'Professional Contact' : 'Personal Contact'} />
            </div>
          </AccordionCard>

          <ContactEdit
            record={contact}
            open={editing != null}
            type={contact?.professional ? 'Professional' : 'Personal'}
            onClosed={() => setEditing(null)}
          />
        </Fragment>
      )}
    </SlideOver>
  )
}
