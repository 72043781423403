import { useEffect, useState } from 'react'
import Button from '@/Shared/Button'
import classNames from 'classnames'
import TextInput from '@/Shared/Forms/TextInput'
import { Transition } from '@headlessui/react'
import { useForm, usePage } from '@inertiajs/react'

export default ({ activeContactId, contact, last, position, status, type, onAdding, onClose, onError }) => {
  const { contact: currentContact } = usePage().props
  const [adding, setAdding] = useState(false)

  const form = useForm({
    id: contact.id,
    type: contact.type,
    label: contact.label,
    related_label: contact.related_label,
    professional: type === 'Professional',
  })
  const { clearErrors, data, errors, post, setData } = form

  useEffect(() => {
    if (activeContactId != contact.id) {
      setAdding(false)
    }
  }, [activeContactId])

  useEffect(() => {
    if (errors.id) {
      onError({ contactExists: errors.id })
    }
  }, [errors])

  const submit = (event) => {
    event.preventDefault()

    post(route('api.contacts.relationships.store', { contact: currentContact.id }), {
      onSuccess: () => {
        clearErrors()
        setAdding(false)
        onClose()
      },
    })
  }

  return (
    <div
      className={classNames(
        'relative transition-all duration-100 ease-in-out',
        adding
          ? 'z-50 -mx-2 -mt-2 -mb-1 rounded-lg border-2 border-gray-400 shadow-md'
          : [
              'border-gray-300',
              {
                'rounded-b-lg': position == 1,
                'border-b border-l border-r': !last,
                'rounded-t-lg border': last,
                border: position == 1 && last,
              },
            ],
      )}
    >
      <div className="overflow-hidden">
        <div
          className={classNames('relative z-10 flex items-start justify-start bg-white p-5', {
            'rounded-b-lg': position == 1,
            'rounded-t-lg': adding || last,
          })}
        >
          <div className="flex flex-grow flex-wrap items-start justify-center text-left sm:justify-start">
            <div className="mr-4 flex h-16 w-16 flex-shrink-0 items-center justify-center">
              {contact.avatar && <img className="h-16 w-16 rounded-full border border-gray-300" src={contact.avatar} alt="" />}
              {!contact.avatar && (
                <div className="flex h-16 w-16 items-center justify-center rounded-full border border-gray-200 bg-gray-200 text-gray-700">
                  <div className="text-xl font-medium tracking-wide">{contact.initials}</div>
                </div>
              )}
            </div>

            <div className="mb-2 flex-grow leading-snug text-gray-600 sm:mb-0">
              <div>
                <span className="font-semibold text-blue-500">{contact.name}</span>
              </div>
              {contact.phone && (
                <div>
                  <a className="focus:outline-none focus:ring focus:ring-blue-500" href={`tel:${contact.phone.number}`}>
                    {contact.phone.type}
                  </a>
                </div>
              )}
              {contact.email && (
                <div>
                  <a className="focus:outline-none focus:ring focus:ring-blue-500" href={`tel:${contact.email}`}>
                    {contact.email}
                  </a>
                </div>
              )}
              <div className="flex items-center leading-none">
                <div
                  className={classNames(
                    'mt-0.5 inline-flex items-center whitespace-nowrap rounded-full px-2.5 py-1.5 text-sm font-medium uppercase leading-none',
                    contact.type === 'Contact' ? 'bg-gray-200 bg-opacity-75 text-gray-800' : 'bg-purple-100 text-purple-800',
                  )}
                >
                  <span>{contact.type === 'Contact' ? 'Contact' : 'PRO'}</span>
                </div>

                {contact.industry && <span className="pl-1.5 font-medium">{contact.industry}</span>}
              </div>
            </div>

            <div
              className={classNames('flex flex-grow justify-end self-stretch sm:w-auto md:pl-4', adding ? 'items-start' : 'items-center')}
            >
              {status ? (
                <div className="flex items-center pr-2">
                  <i className="fas fa-check pr-2 text-lg leading-none text-green-600"></i>
                  {status}
                </div>
              ) : adding ? (
                <Button theme="icon" onClick={() => setAdding(false)}>
                  <span className="sr-only">Close panel</span>
                  <i className="fal fa-times text-2xl leading-none text-opacity-80"></i>
                </Button>
              ) : (
                <Button theme="outline-sm" onClick={() => setAdding(true)}>
                  Add
                  <div className="flex h-5 w-5 justify-end leading-none">
                    <i className="fas fa-angle-down text-base" aria-hidden="true"></i>
                  </div>
                </Button>
              )}
            </div>
          </div>
        </div>

        <Transition
          show={adding}
          enter="transition ease duration-300 transform"
          enterFrom="-translate-y-full"
          enterTo="translate-y-0"
          beforeEnter={() => onAdding(contact.id)}
        >
          <form id="addContact" className="relative z-0 rounded-b-md border-t border-gray-300 bg-gray-100 p-6" onSubmit={submit}>
            <ul role="list" className="divide-y divide-gray-300 rounded-md border border-gray-300 bg-white">
              <li className="bg-blue-100 py-3 px-5 text-center text-sm font-semibold uppercase leading-none text-blue-500">Relationship</li>

              <li className={classNames('py-3 px-5', errors?.label && 'bg-red-100')}>
                <div className="sm:flex sm:items-center sm:justify-between sm:space-x-4">
                  <div className="flex flex-1 items-center text-left sm:text-right">
                    <span className="mb-0.5 flex-1 font-bold text-gray-600 sm:mb-0">
                      {contact.name} <span className="whitespace-nowrap">is</span>
                    </span>
                  </div>

                  <TextInput
                    classes="flex-shrink-0 w-full sm:w-48"
                    name="label"
                    placeholder={type === 'Professional' ? 'i.e. Professional' : 'i.e. Friend'}
                    value={data.label}
                    onChange={(value) => setData({ ...data, label: value })}
                  />

                  <div className="mt-1 flex-1 text-right sm:mt-0 sm:flex sm:items-center sm:justify-between sm:text-left">
                    <span className="flex-1 font-bold text-gray-600">to {currentContact.full_name}</span>
                  </div>
                </div>
                {errors.related_label && <div className="w-full text-red-700">{errors.related_label}</div>}
              </li>
              <li className={classNames('py-3 px-5', errors?.related_label && 'bg-red-100')}>
                <div className="sm:flex sm:items-center sm:justify-between sm:space-x-4">
                  <div className="flex flex-1 items-center text-left sm:text-right">
                    <span className="mb-0.5 flex-1 font-bold text-gray-600 sm:mb-0">
                      {currentContact.full_name} <span className="whitespace-nowrap">is</span>
                    </span>
                  </div>

                  <TextInput
                    classes="flex-shrink-0 w-full sm:w-48"
                    name="related_label"
                    placeholder={type === 'Professional' ? 'i.e. Client' : 'i.e. Friend'}
                    value={data.related_label}
                    onChange={(value) => setData({ ...data, related_label: value })}
                  />

                  <div className="mt-1 flex-1 text-right sm:mt-0 sm:flex sm:items-center sm:justify-between sm:text-left">
                    <span className="flex-1 font-bold text-gray-600">to {contact.name}</span>
                  </div>
                </div>
                {errors.label && <div className="w-full text-red-700">{errors.label}</div>}
              </li>
            </ul>

            <div className="mt-6 sm:grid sm:grid-cols-5">
              <div className="sm:col-span-3 sm:col-start-3 sm:flex sm:items-center sm:justify-end">
                <Button type="submit" theme="border" buttonClasses="w-full sm:col-start-4 sm:col-span-2" form="addContact">
                  <div className="relative flex w-full items-center justify-center py-1 font-medium leading-none">
                    <i className="fas fa-user-plus mr-2"></i> Add
                  </div>
                </Button>
              </div>
            </div>
          </form>
        </Transition>
      </div>
    </div>
  )
}
