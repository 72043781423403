import React from 'react'
import { router } from '@inertiajs/react'
import { usePage } from '@inertiajs/react'
import HeaderColumn from '@/Shared/Table/HeaderColumn'

export default () => {
  const { filters } = usePage().props
  const { sortBy, direction } = filters

  const sort = (field) => {
    const values = filters
      ? { ...filters, sortBy: field, direction: field === sortBy ? (direction === 'asc' ? 'desc' : 'asc') : 'asc' }
      : {
          sortBy: field,
          direction: field === sortBy ? (direction === 'asc' ? 'desc' : 'asc') : 'asc',
        }
    const query = encodeURIComponent(JSON.stringify(values))

    router.get(
      route(route().current(), { filters: query }),
      {},
      {
        preserveState: true,
      },
    )
  }

  return (
    <tr>
      <HeaderColumn
        className="hidden text-center md:table-cell "
        sorting={sortBy}
        direction={direction}
        field="pros_servicing_count"
        onSort={(field) => sort(field)}
        noBorder
        width="120px"
      >
        <div className="flex-1 text-center">
          <i className="far fa-users text-lg text-blue-500"></i>
        </div>
      </HeaderColumn>
      <HeaderColumn sorting={sortBy} direction={direction} field="title" onSort={(field) => sort(field)}>
        Service
      </HeaderColumn>
      <HeaderColumn classes="hidden md:table-cell" width="120px">
        Status
      </HeaderColumn>
      <HeaderColumn width="100px" noHover>
        <span className="sr-only">Edit</span>
      </HeaderColumn>
    </tr>
  )
}
