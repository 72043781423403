import { useState } from 'react'
import MobileMenu from './MobileMenu'
import MobileDetails from './MobileDetails'
import MobileVisits from './MobileVisits'
import Timeline from './Timeline'

export default ({ children, onClosed }) => {
  const [tab, setTab] = useState('')

  const handleClose = () => {
    typeof onClosed === 'function' ? onClosed() : setTab('')
  }

  return (
    <div className="relative h-full bg-gray-100">
      <MobileMenu onTabChange={(selected) => setTab(selected)}>{children}</MobileMenu>
      <MobileDetails open={tab === 'about'} onClose={() => handleClose()} onBack={() => setTab('')} />
      <MobileVisits open={tab === 'visits'} onClose={() => handleClose()} onBack={() => setTab('')} />
      <Timeline open={tab === 'timeline'} onClose={() => handleClose()} onBack={() => setTab('')} />
    </div>
  )
}
