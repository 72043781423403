import React from 'react'
import classNames from 'classnames'

export default React.forwardRef(({ description, label, value, vertical, onChange, ...props }, ref) => {
  const id = props.id || props.name
  return (
    <div
      className={classNames('relative flex items-center', {
        'flex-col': vertical,
        'sm:items-start': !vertical && description,
      })}
    >
      <div className="flex h-8 items-center sm:h-6">
        <input
          ref={ref}
          id={id}
          aria-describedby={`${id}-description`}
          type="radio"
          className={classNames(
            'h-8 w-8 border-2 text-primary-500 focus:ring-primary-500 sm:h-6 sm:w-6',
            value ? 'border-primary-500' : 'border-gray-400',
            {
              'cursor-not-allowed opacity-20': props.disabled,
            },
          )}
          checked={value || false}
          onChange={() => onChange(!value)}
          {...props}
        />
      </div>
      {label && (
        <label
          htmlFor={id}
          className={classNames('leading-none', {
            'ml-3': !vertical,
            'mt-1': vertical,
            'text-sm': vertical || !description,
          })}
        >
          <span className="text-lg font-medium leading-snug text-gray-700">{label}</span>
          {description && (
            <p id={`${id}-description`} className="leading-snug text-gray-500">
              {description}
            </p>
          )}
        </label>
      )}
    </div>
  )
})
