import React, { useEffect, useState } from 'react'
import { usePage } from '@inertiajs/react'
import { Tooltip } from '@/Shared/Tooltip'

const Row = (props: any) => {
  const { openhouse }: any = usePage().props
  const { visit, onEdit, onUpdate } = props
  const { user }: any = usePage().props.auth
  const sms_body = `Please see host.  You're next to enter the open house - ${user.first_name} ${user.last_name}`
  const [timerOutput, setTimerOutput] = useState('00:00:00')

  const checkInOut = () => {
    let data = visit.checked_in_time ? { checked_out_time: true } : { checked_in_time: true }
    onUpdate(visit.id, data)
  }

  const updateTimer = () => {
    let checkedInTime = new Date(visit.checked_in_at.replace(' ', 'T')).getTime()
    let now = new Date().getTime()
    let elapsed = now - checkedInTime

    let hours = pad(Math.floor((elapsed % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)), 2)
    let minutes = pad(Math.floor((elapsed % (1000 * 60 * 60)) / (1000 * 60)), 2)
    let seconds = pad(Math.floor((elapsed % (1000 * 60)) / 1000), 2)

    setTimerOutput(`${hours}:${minutes}:${seconds}`)
  }

  const pad = (num: Number, size: Number) => {
    return ('000000000' + num).substr(-size)
  }

  if (openhouse.checkins_enabled && visit && visit.checked_in_time && !visit.checked_out_time) {
    useEffect(() => {
      updateTimer()

      const timer = setInterval(() => {
        updateTimer()
      }, 1000)

      return () => clearInterval(timer)
    })
  }

  return (
    <div className="flex w-full items-center justify-between px-4 py-2.5 text-gray-600 outline-none hover:bg-gray-100">
      <button role="button" className="flex-grow cursor-pointer pr-2 text-left hover:bg-gray-100" onClick={() => onEdit(visit)}>
        <span className="text-lg font-medium text-gray-900">{visit.name}</span>

        <div className="flex items-center justify-between">
          <div className="space-y-1">
            <div className="-ml-1 flex items-center text-sm">
              <span
                className={`ml-1 inline-flex items-center rounded px-2 py-1 font-medium uppercase leading-none ${
                  visit.type === 'Broker' ? 'bg-gray-200 bg-opacity-75 text-gray-700' : 'bg-orange-100 text-orange-600'
                }`}
              >
                {visit.type}
              </span>

              {visit.type === 'Buyer' && visit.contracted && (
                <Tooltip label="Contracted with a Broker" placement="bottom">
                  <span
                    className={`ml-1 inline-flex items-center rounded bg-gray-100 px-2 py-1 font-medium uppercase leading-none text-gray-600`}
                  >
                    <i className="fas fa-user-tie"></i>
                  </span>
                </Tooltip>
              )}

              {openhouse.checkins_enabled && visit.notified && !visit.checked_in_time && !visit.checked_out_time && (
                <span
                  className={`ml-1 inline-flex items-center rounded bg-yellow-100 px-2 py-1 font-medium uppercase leading-none text-yellow-600`}
                >
                  Notified
                </span>
              )}

              {visit.creator.avatar ? (
                <Tooltip label={visit.creator.name} placement="bottom">
                  <div>
                    <img className="ml-2 h-6 w-6 rounded-full" src={visit.creator.avatar} />
                  </div>
                </Tooltip>
              ) : (
                <Tooltip label={visit.creator.name} placement="bottom">
                  <div className="flex h-6 w-6 items-center justify-center rounded-full bg-gray-200 text-gray-700">
                    <div className="text-xs font-medium tracking-wide">{visit.creator.initials}</div>
                  </div>
                </Tooltip>
              )}
            </div>

            {(visit.checked_out_time || !openhouse.checkins_enabled) && (
              <div className="flex items-center space-x-1.5 text-sm">
                {visit.assigned ? (
                  <span className="inline-flex items-center rounded bg-blue-100 px-2 py-1 font-medium uppercase leading-none text-blue-500">
                    Assigned
                  </span>
                ) : (
                  <span
                    className={`inline-flex items-center rounded bg-yellow-100 px-2 py-1 font-medium uppercase leading-none text-yellow-600`}
                  >
                    Unassigned
                  </span>
                )}

                {visit.assigned &&
                  (visit.assigned.avatar ? (
                    <Tooltip label={visit.assigned.name} placement="bottom">
                      <div>
                        <img className="h-6 w-6 rounded-full" src={visit.assigned.avatar} />
                      </div>
                    </Tooltip>
                  ) : (
                    <div className="flex h-6 w-6 items-center justify-center rounded-full bg-gray-200 text-gray-700">
                      <div className="text-xs font-medium tracking-wide">{visit.assigned.initials}</div>
                    </div>
                  ))}

                {visit.imported_at && (
                  <span
                    className={`inline-flex items-center rounded bg-green-50 px-2 py-1 font-medium uppercase leading-none text-green-700`}
                  >
                    Imported
                  </span>
                )}
              </div>
            )}
          </div>
        </div>
      </button>

      {Boolean(openhouse.checkins_enabled && visit.checked_in_time && !visit.checked_out_time) && (
        <div className="font-medium text-gray-800">{timerOutput}</div>
      )}

      {openhouse.checkins_enabled && (
        <div className="flex flex-grow-0 items-center justify-end">
          {!visit.checked_in_time && !visit.checked_out_time && !visit.notified && (
            <div className="pl-2">
              <Tooltip label="Mark as Notified" placement="bottom">
                <button
                  className="group inline-flex h-9 w-9 items-center justify-center rounded-full border border-gray-500 bg-gray-100 outline-none transition-all duration-300 hover:border-blue-500 hover:bg-blue-100"
                  onClick={() => onUpdate(visit.id, { notified: true })}
                >
                  <i className="fas fa-comment-alt-check text-xl leading-none text-gray-700 group-hover:text-blue-700"></i>
                </button>
              </Tooltip>
            </div>
          )}

          {!visit.checked_in_time && !visit.checked_out_time && (
            <div className="group cursor-pointer pl-2 text-center">
              <Tooltip label="Text Visitor" placement="bottom">
                <a
                  href={`sms:${visit.phone}?&body=${sms_body}`}
                  title="SMS"
                  className="inline-flex h-9 w-9 items-center justify-center rounded-full border border-blue-200 bg-blue-100 outline-none transition-all duration-300 group-hover:border-blue-500"
                  target="_blank"
                >
                  <i className="fas fa-comment-alt-dots text-xl leading-none text-blue-500 group-hover:text-blue-700"></i>
                </a>
              </Tooltip>
            </div>
          )}

          {!visit.checked_out_time && (
            <div className="pl-2">
              <Tooltip label={`Check ${visit.checked_in_time ? 'out' : 'in'}`} placement="bottom">
                <button
                  className="group inline-flex h-9 w-9 items-center justify-center rounded-full border border-gray-500 bg-gray-100 outline-none transition-all duration-300 hover:border-blue-500 hover:bg-blue-100"
                  onClick={() => checkInOut()}
                >
                  <span className="text-sm font-medium leading-none tracking-tighter group-hover:text-blue-500">
                    {visit.checked_in_time ? 'OUT' : 'IN'}
                  </span>
                </button>
              </Tooltip>
            </div>
          )}
        </div>
      )}

      <button role="button" className="cursor-pointer pl-3 text-right leading-none" onClick={() => onEdit(visit)}>
        <i className="fal fa-angle-right text-3xl"></i>
      </button>
    </div>
  )
}

export default Row
