import { createRef, Fragment, useEffect, useState } from 'react'
import axios from '@/apis/axios'
import classNames from 'classnames'
import { router } from '@inertiajs/react'
import Portlet from '@/Shared/Portlet'
import Select from '@/Shared/Forms/Select'
import NoResults from './NoResults'
import NoteDialog from '@/Shared/NoteDialog'
import { Tooltip } from '@/Shared/Tooltip'
import useInfiniteScroll from '@/hooks/useInfiniteScroll'
import { usePage } from '@inertiajs/react'

export default () => {
  const scrollRef = createRef()
  const { filters, clients: records } = usePage().props
  const [activeContact, setActiveContact] = useState(null)
  const [noteDialogOpen, setNoteDialogOpen] = useState(false)
  const { isFetching, setIsFetching, setStopFetching, handleInfiniteScroll } = useInfiniteScroll(fetchMore)
  const [page, setPage] = useState(1)
  const [clients, setClients] = useState([])
  const [tag, setTag] = useState(filters.clients?.tags || 'All Clients')
  const tags = [
    { value: '', label: 'All Clients' },
    { value: 'Active Clients', label: 'Active' },
    { value: 'On-Hold Clients', label: 'On-Hold' },
    { value: 'Closed Clients', label: 'Closed' },
  ]

  useEffect(() => {
    setClients(records)
  }, [records])

  function fetchMore() {
    if (isFetching) {
      setStopFetching(true)

      axios
        .get(route('api.contacts.index'), {
          params: {
            page: page + 1,
            tags: tag ? [tag] : tags.filter((tag) => tag.value).map((tag) => tag.value),
          },
        })
        .then((response) => {
          let records = response.data
          if (records.length > 0) {
            setClients(clients.concat(records))
            setPage(page + 1)
            setIsFetching(false)
            setStopFetching(false)
          }
        })
    }
  }

  const rowClicked = (id) => {
    router.visit(route('contacts.show', id))
  }

  const filter = (tag) => {
    setIsFetching(false)
    setTag(tag)

    scrollRef.current.scrollTo({
      top: 0,
    })

    const { clients, ...rest } = route().params

    router.replace(route(route().current()), {
      method: 'get',
      data: { ...rest, clients: { tags: [tag] } },
      replace: false,
      preserveState: true,
      preserveScroll: true,
      only: ['clients'],
      onFinish: () => {
        setPage(1)
        setStopFetching(false)
      },
    })
  }

  const noteClicked = (e, contact) => {
    e.stopPropagation()
    setActiveContact(contact)
    setNoteDialogOpen(true)
  }

  return (
    <Fragment>
      <Portlet ref={scrollRef} onScroll={handleInfiniteScroll}>
        <Portlet.Header>
          <div className="flex items-center justify-between gap-3">
            <div className="flex-1">Clients</div>

            <Select
              className="w-40"
              isClearable={false}
              name="tag"
              options={tags}
              value={tag}
              onChange={(selected) => filter(selected.value)}
            />
          </div>
        </Portlet.Header>

        <Portlet.Body>
          {clients.length > 0 ? (
            <div className="divide-y divide-gray-200 border-b border-t border-gray-200">
              {clients.map(({ id, avatar, initials, name, phone, email, notes, tags }) => (
                <div className="flex gap-4 hover:bg-gray-100" key={id}>
                  <div className="whitespace-nowrap py-3 pl-4" onClick={() => rowClicked(id)}>
                    <div className="flex h-10 w-10 flex-shrink-0 cursor-pointer items-center justify-center">
                      <div>
                        {avatar && <img className="h-10 w-10 rounded-full" src={avatar} alt="" />}
                        {!avatar && (
                          <div className="flex h-10 w-10 items-center justify-center rounded-full bg-gray-200 text-gray-700">
                            <div className="text-base font-medium tracking-wide">{initials}</div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-1 cursor-pointer flex-col justify-center py-3 leading-snug" onClick={() => rowClicked(id)}>
                    <div
                      className={classNames('max-w-[210px] truncate font-bold', {
                        'text-blue-500': tags.find((t) => t.label === 'Unqualified Contacts'),
                        'text-green-500': tags.find((t) => t.label === 'Active Prospects'),
                        'text-yellow-500': tags.find((t) => ['Long-Term Prospects', 'On-Hold Clients'].indexOf(t.label) >= 0),
                        'text-green-900': tags.find((t) => t.label === 'Active Clients'),
                        'text-red-500': tags.find((t) => t.label === 'Lost Prospects'),
                      })}
                    >
                      {name}
                    </div>
                    <div className="max-w-[210px] truncate text-gray-600">{email}</div>
                    <div className="max-w-[210px] truncate text-gray-600 sm:hidden">{phone}</div>
                  </div>

                  <div className="hidden cursor-pointer py-3 text-right sm:flex sm:items-center" onClick={() => rowClicked(id)}>
                    {phone}
                  </div>

                  <div className="flex shrink-0 items-center gap-1 py-3 pr-4">
                    <span className="text-gray-400">({notes || 0})</span>

                    <Tooltip label="Add a note">
                      <button
                        type="button"
                        className="inline-flex h-9 w-9 items-center justify-center rounded-full outline-none transition-all duration-100 hover:bg-gray-300 hover:bg-opacity-75 focus:ring-2 focus:ring-blue-500"
                        onClick={(e) => noteClicked(e, { id: id, name: name })}
                      >
                        <i className="far fa-note-medical text-xl leading-none text-blue-500"></i>
                      </button>
                    </Tooltip>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="flex max-h-[400px] min-h-[400px] items-center justify-center py-6">
              <NoResults label={tag ? tag : 'Clients'} />
            </div>
          )}
        </Portlet.Body>
      </Portlet>

      {activeContact && (
        <NoteDialog
          open={noteDialogOpen}
          id={activeContact.id}
          title={`Add a Note to ${activeContact.name}`}
          type="Contact"
          record={{ type: 'Note' }}
          onClosed={() => {
            setNoteDialogOpen(false)
            setTimeout(() => {
              setActiveContact(null)
            }, 500)
          }}
        />
      )}
    </Fragment>
  )
}
