import { useEffect, useState } from 'react'
import axios from '@/apis/axios'
import Button from '@/Shared/Button'
import NotificationGroup from './NotificationGroup'
import { usePage } from '@inertiajs/react'

const NotificationSettings = ({ standAlone }) => {
  const { user } = usePage().props
  const categories = user.settings.notification_categories || []
  const [settings, setSettings] = useState(user.settings.notifications)
  const [loading, setLoading] = useState(true)

  useEffect(() => setLoading(false), [])

  useEffect(() => {
    if (!loading) {
      updateSettings()
    }
  }, [settings])

  const getChannelSetting = (channel, category) => {
    let setting = settings.find((setting) => setting.type === category.type)
    return setting?.channels?.find((c) => c.name === channel) || { name: channel, active: true }
  }

  const createSetting = (type, channel, checked) => {
    return {
      type: type,
      channels: [
        {
          name: channel,
          active: checked,
        },
      ],
    }
  }

  const createChannel = (channel, checked) => {
    return {
      name: channel,
      active: checked,
    }
  }

  const updateChannelSetting = (channel, category, checked) => {
    let updated
    let settingExists = settings.find((setting) => setting.type === category.type)
    if (settingExists) {
      updated = settings.map((setting) => {
        if (setting.type === category.type) {
          let channelExists = setting.channels.find((c) => c.name === channel)
          let updatedChannels = channelExists
            ? setting.channels.map((c) => (c.name === channel ? { ...c, active: checked } : c))
            : setting.channels.concat(createChannel(c, checked))

          return { ...setting, channels: updatedChannels }
        }
        return setting
      })
    } else {
      updated = settings.concat(createSetting(category.type, channel, checked))
    }

    setSettings(updated)
  }

  const updateSettings = () => {
    axios.post(`/api/users/${user.id}/settings`, {
      _method: 'PATCH',
      notifications: settings,
    })
  }

  const notifications = () =>
    categories.map((category, index) => (
      <NotificationGroup
        category={category}
        setting={getChannelSetting('mail', category)}
        key={index}
        onChange={(value) => updateChannelSetting('mail', category, value)}
      />
    ))

  return standAlone ? (
    <div className="flex h-screen flex-col border border-gray-200 bg-gray-100 md:pt-16">
      <div className="mx-auto max-w-4xl rounded-lg bg-white p-6 sm:p-8">
        <form className="divide-y divide-gray-300">
          <div>
            <div className="mb-4 text-center text-xl font-bold text-gray-600">{user.email}</div>
            <div className="text-center text-xl font-bold">
              Your <span className="text-primary-500">{user.type === 'Associate' ? 'BetterLivingPro' : 'LSNPros.com'}</span>
            </div>
            <div className="mb-3 text-center text-3xl font-bold">Email Notification Preferences</div>
            <div className="mb-6 text-center font-bold">
              You can choose to opt out of any of the following types of email notifications we send.
            </div>
          </div>

          <div>{notifications()}</div>

          <div className="flex items-center justify-end space-x-6 py-4">
            {updated && <div className="font-bold text-green-500">Updated!</div>}

            <Button theme="solid" onClick={(event) => onSave(event)}>
              Save
            </Button>
          </div>
        </form>
      </div>
    </div>
  ) : (
    <form className="divide-y divide-gray-200 lg:col-span-9">
      <div className="p-4 sm:p-6 lg:pb-4">
        <div>
          <h2 className="text-xl font-medium leading-6 text-gray-900">Email Notifications</h2>
          <p className="mt-1 text-gray-500">
            Get emails to find out what’s going on when you’re not on LSNPros. You can turn them off anytime.
          </p>
        </div>

        <fieldset className="mt-6">
          <legend className="text-lg font-medium text-gray-900">From LSNPros</legend>
          <div className="mt-2 divide-y divide-gray-200 border-t border-gray-200">{notifications()}</div>
        </fieldset>
      </div>
    </form>
  )
}

export default NotificationSettings
