import classNames from 'classnames'
import MainMenuItem from './MainMenuItem'
import { usePage } from '@inertiajs/react'

export default ({ mobile }) => {
  const { user } = usePage().props.auth
  const isAdminHRAssociate = user.roles.some((r) => ['Admin', 'hr_controller', 'Associate'].indexOf(r) >= 0)
  const isAdminHR = user.roles.some((r) => ['Admin', 'hr_controller'].indexOf(r) >= 0)

  const navigation = [
    { name: 'Dashboard', href: 'dashboard', group: 'dashboard', restricted: !isAdminHRAssociate },
    { name: 'Contacts', href: 'contacts.index', group: 'contacts', restricted: !isAdminHRAssociate },
    { name: 'Transactions', href: 'transactions.index', group: 'transactions', restricted: !isAdminHRAssociate },
    { name: 'Open House', href: 'openhouses.index', group: 'openhouses', restricted: !isAdminHRAssociate },
    { name: 'My PRO-Website', href: 'my-website.index', group: 'my-website' },
    { name: 'Find a PRO', href: 'pros.index', group: 'pros' },
    {
      name: 'LSNs',
      href: 'lsns.index',
      group: 'lsns',
    },
    {
      name: 'Administration',
      current: false,
      group: 'admin',
      children: [
        { name: 'Associates', href: 'admin.associates.index', group: 'associates' },
        { name: 'PROs', href: 'admin.pros.index', group: 'pros' },
        {
          name: 'Services',
          href: null,
          group: 'services',
          children: [
            { name: 'Search', href: 'admin.services.index', group: 'services' },
            { name: 'Service Relation Requests', href: 'admin.service-relations.index', group: 'service-relations' },
          ],
        },
        {
          name: 'Transactions',
          href: null,
          group: 'transactions',
          children: [
            { name: 'Checklists', href: 'admin.checklists.index', group: 'checklists' },
            { name: 'Email Templates', href: 'admin.email-templates.index', group: 'email-templates' },
            { name: 'File Types', href: 'admin.file-types.index', group: 'file-types' },
          ],
        },
      ],
      restricted: !isAdminHR,
    },
  ]

  return (
    <nav aria-label="Navigation" role="menubar" className="flex">
      <div className={classNames('flex flex-1 flex-col xl:flex-row xl:items-center', { 'space-y-1': mobile })}>
        {navigation
          .filter((item) => item.name)
          .map((item) =>
            !item.restricted ? (
              <MainMenuItem item={item} desktop={!mobile} key={item.name}>
                {item.name}
              </MainMenuItem>
            ) : null,
          )}
      </div>
    </nav>
  )
}
