import { useContext, useEffect, useRef, useState } from 'react'
import axios from '@/apis/axios'
import { AccordionContext } from '@/Shared/Accordion'
import Button from '@/Shared/Button'
import Select from '@/Shared/Forms/Select'
import { router } from '@inertiajs/react'

export default ({ user }) => {
  const focusRef = useRef()
  const { onSaved } = useContext(AccordionContext)

  const [data, setData] = useState({
    setting: 'search-zipcode',
    search_state: user.search_state,
    search_zipcode_id: user.search_zipcode_id,
  })
  const [selectedTown, setSelectedTown] = useState(null)
  const [towns, setTowns] = useState([])
  const [errors, setErrors] = useState({})

  useEffect(() => {
    focusRef.current.focus()

    if (user.search_zipcode_id) {
      getTowns(data.search_state).then((_) => {
        setSelectedTown(user.search_zipcode_id)
      })
    }
  }, [])

  const states = [
    { value: 'MA', label: 'Massachusetts' },
    { value: 'NH', label: 'New Hampshire' },
    { value: 'RI', label: 'Rhode Island' },
  ]

  const getTowns = (state) => {
    setTowns([])

    return axios
      .get(route('api.cities.index'), {
        params: {
          state: state,
        },
      })
      .then((response) => {
        setTowns(response.data.areas)
      })
  }

  const stateChanged = (selected) => {
    getTowns(selected).then((_) => {
      setSelectedTown(null)
      setData({ ...data, search_state: selected, search_zipcode_id: null })
    })
  }

  const townChanged = (selected) => {
    setSelectedTown(selected)
    setData({ ...data, search_zipcode_id: selected })
  }

  const submit = (event) => {
    event.preventDefault()

    axios
      .post(route('api.users.update', user.id), { ...data, _method: 'PATCH' })
      .then((_) =>
        router.reload({
          onFinish: () => {
            onSaved()
          },
        }),
      )
      .catch((error) => {
        if (error.response != undefined) {
          const { status } = error.response
          const VALIDATION_ERROR = 422
          if (status == VALIDATION_ERROR) {
            setErrors(error.response.data.errors)
          }
        }
      })
  }

  return (
    <div>
      <p>
        Select the town that is your home Primary Market Location. This could be where you live, keep your home office, or optionally the
        town where you conduct your primary business. This ensures you are properly identified in geographic search results.
      </p>

      <form className="mx-auto mt-4 max-w-2xl space-y-1.5" onSubmit={submit}>
        <div className="space-y-3 sm:flex sm:items-start sm:justify-between sm:gap-4 sm:space-y-0">
          <div className="flex-1">
            <Select
              ref={focusRef}
              label="State"
              classes="mb-1"
              name="state"
              options={states}
              value={data.search_state}
              error={errors.search_state}
              isClearable={false}
              onChange={(selected) => stateChanged(selected && selected.value)}
              required
            />
          </div>
          <div className="flex-1">
            <Select
              label="Town"
              classes="mb-1"
              name="state"
              options={towns}
              value={selectedTown}
              error={errors.search_zipcode_id}
              isClearable={false}
              onChange={(selected) => townChanged(selected && selected.value)}
              disabled={towns.length == 0}
              required
            />
          </div>
        </div>

        <div className="flex justify-end">
          <Button type="submit" theme="solid">
            Save
          </Button>
        </div>
      </form>
    </div>
  )
}
