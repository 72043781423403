import { forwardRef, useEffect, useState } from 'react'
import classNames from 'classnames'
import Helpers from '@/utils/helpers'
import { useForm } from '@inertiajs/react'

export default forwardRef(
  (
    {
      autoComplete,
      autoCorrect,
      autoFocus,
      basis,
      classes,
      clearable,
      disabled,
      error,
      hideErrorMessage,
      inline,
      inputClasses,
      label,
      name,
      maxPercent,
      percent,
      placeholder,
      required,
      size,
      spellCheck,
      type,
      value,
      onBlur,
      onChange,
      ...props
    },
    ref,
  ) => {
    const { data, setData } = useForm({ percent: percent || '', amount: value || '' })
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
      if (basis) {
        recalculate()
      }
      setIsLoading(false)
    }, [])

    useEffect(() => {
      if (!isLoading) {
        recalculate()
      }
    }, [basis])

    useEffect(() => {
      if (value == null && Helpers.parseCurrency(data.amount) > 0) {
        setData({ percent: '', amount: '' })
      }
    }, [value])

    useEffect(() => {
      onChange(Helpers.parseCurrency(data.amount), data.percent)
    }, [data])

    const recalculate = () => {
      let updated = { ...data }

      let values = {
        basis: Helpers.parseCurrency(basis || 0),
        percent: data.percent || percent || 0,
        amount: Helpers.parseCurrency(data.value || value || 0),
      }

      if (values.basis > 0 && values.percent > 0) {
        updated = { percent: values.percent, amount: Helpers.round(values.basis * (values.percent / 100)) }
      } else if (values.basis > 0 && values.amount > 0) {
        updated = { percent: Helpers.round((values.amount / values.basis) * 100, 2), amount: values.amount }
      } else if (values.percent > 0) {
        updated = { percent: values.percent, amount: 0 }
      } else {
        updated = { percent: '', amount: '' }
      }

      if (updated.amount) {
        updated.amount = Helpers.formatDecimal(Helpers.round(updated.amount, 2), 2)
      }

      setData(updated)
    }

    const onPercentChanged = (pct) => {
      let val = pct ? parseFloat(pct) : ''

      if (val > (maxPercent || 100)) {
        val = maxPercent || 100
      } else if (val < 0) {
        val = 0
      }

      setData({ ...data, percent: val, amount: Helpers.formatDecimal(Helpers.round(Helpers.parseCurrency(basis) * (val / 100)), 2) })
    }

    const onAmountChanged = (amount) => {
      let result = (Helpers.parseCurrency(amount) / Helpers.parseCurrency(basis)) * 100
      setData({ ...data, percent: parseFloat(result.toFixed(2)), amount: amount })
    }

    return (
      <div
        className={classNames(props.className || classes, !props.className && !classes && !inline && 'mb-4', {
          'flex items-center': inline,
        })}
      >
        {label && (
          <label
            htmlFor={name}
            className={classNames(
              'block text-sm font-medium uppercase',
              error ? 'text-red-600' : 'text-gray-500',
              inline ? 'mr-3' : 'mb-0.5',
            )}
          >
            {label}
            {required && <span className="pl-1 text-red-600">*</span>}
          </label>
        )}

        <div className="flex items-center gap-4">
          <div className="relative flex w-40">
            <input
              autoComplete={autoComplete || 'off'}
              autoCorrect={autoCorrect || 'false'}
              autoFocus={autoFocus || false}
              id={name}
              className={classNames(
                'font-md transition-border relative block h-11 w-full rounded-l placeholder-gray-400 outline-none duration-150 ease-in-out',
                { 'px-4 py-2': !inputClasses },
                disabled ? 'cursor-not-allowed bg-gray-200' : '',
                error
                  ? 'border-transparent ring-2 ring-red-500 hover:ring-red-400 focus:ring-red-700'
                  : 'border border-gray-300 hover:border-gray-400 focus:border-transparent focus:ring-2 focus:ring-primary-500',
                inputClasses,
              )}
              placeholder={placeholder || null}
              ref={ref}
              spellCheck={spellCheck || 'false'}
              type={type || 'number'}
              value={data.percent}
              onChange={(e) => onPercentChanged(e.target.value)}
              onFocus={(e) => e.target.select()}
              disabled={disabled}
              {...props}
            />

            <span
              className={classNames(
                'flex w-20 items-center justify-center rounded-r border-b border-r border-t text-sm font-semibold transition-all duration-150 ease-in-out',
                error ? 'bg-red-100 text-red-500 ring-2 ring-red-500' : 'border-gray-300 bg-gray-200 text-gray-700',
              )}
            >
              <i className="fal fa-percent text-lg"></i>
            </span>
          </div>

          <div className="relative flex flex-1">
            <span
              className={classNames(
                'flex w-12 items-center justify-center rounded-l border-b border-l border-t text-sm font-semibold transition-all duration-150 ease-in-out',
                error ? 'bg-red-100 text-red-500 ring-2 ring-red-500' : 'border-gray-300 bg-gray-200 text-gray-700',
              )}
            >
              <i className="fas fa-dollar-sign"></i>
            </span>

            <input
              autoComplete={autoComplete || 'off'}
              autoCorrect={autoCorrect || 'false'}
              autoFocus={autoFocus || false}
              id={name}
              className={classNames(
                'font-md transition-border block h-11 w-full rounded-r placeholder-gray-400 outline-none duration-150 ease-in-out',
                { 'px-4 py-2': !inputClasses },
                disabled ? 'cursor-not-allowed bg-gray-200' : '',
                error
                  ? 'border-transparent ring-2 ring-red-500 hover:ring-red-400 focus:ring-red-700'
                  : 'border border-gray-300 hover:border-gray-400 focus:border-transparent focus:ring-2 focus:ring-primary-500',
                inputClasses,
              )}
              placeholder={placeholder || null}
              ref={ref}
              spellCheck={spellCheck || 'false'}
              type={type || 'text'}
              value={data.amount}
              onBlur={() => {
                setData({ ...data, amount: Helpers.formatDecimal(Helpers.parseCurrency(data.amount), 2) })
              }}
              onChange={(e) => onAmountChanged(Helpers.sanitizeCurrencyInput(e.target.value))}
              onFocus={(e) => e.target.select()}
              disabled={disabled}
              {...props}
            />

            {clearable && value && (
              <button
                type="button"
                className="absolute inset-y-0 right-1 m-1 rounded-lg bg-white px-2.5 text-gray-400 hover:text-gray-700"
                onClick={() => onChange('')}
              >
                <i className="far fa-times text-lg"></i>
              </button>
            )}
          </div>
        </div>

        {error && !hideErrorMessage && <div className="mt-1 text-red-600" dangerouslySetInnerHTML={{ __html: error }}></div>}
      </div>
    )
  },
)
