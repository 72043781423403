import { Fragment, useEffect, useState } from 'react'
import classNames from 'classnames'
import { router } from '@inertiajs/react'
import NoResults from '../NoResults'
import Portlet from '@/Shared/Portlet'
import Select from '@/Shared/Forms/Select'
import { usePage } from '@inertiajs/react'

export default () => {
  const { filters, transactions_reviewing: records } = usePage().props
  const [transactions, setTransactions] = useState([])
  const [option, setOption] = useState(filters.transactions_reviewing?.name || '')
  const options = [
    { value: '', label: 'Any' },
    { value: 'Compliance', label: 'Compliance - Awaiting Review' },
    { value: 'Under Review', label: 'Under Review' },
  ]

  useEffect(() => setTransactions(records), [records])

  const rowClicked = (id) => {
    router.visit(route('transactions.show', id))
  }

  const filter = (option) => {
    setOption(option)

    router.reload({
      data: { transactions_reviewing: { name: option } },
      only: ['transactions_reviewing'],
    })
  }

  return (
    <Fragment>
      <Portlet>
        <Portlet.Header>
          <div className="flex items-center justify-between gap-3">
            <div className="flex flex-1 flex-col">
              <span className="text-sm font-normal text-gray-500">Transactions</span>
              <span>Under Review</span>
            </div>

            <Select
              className="w-40"
              isClearable={false}
              name="option"
              options={options}
              value={option}
              onChange={(selected) => filter(selected.value)}
            />
          </div>
        </Portlet.Header>

        <Portlet.Body>
          {transactions.length > 0 ? (
            <div className="divide-y divide-gray-200 border-b border-t border-gray-200">
              {transactions.map((transaction) => (
                <div className="flex cursor-pointer gap-4 hover:bg-gray-100" key={transaction.id}>
                  <div className="flex shrink-0 items-center justify-center pl-4">
                    {transaction.associates?.map((associate, index) => (
                      <Fragment key={index}>
                        {associate.avatar && (
                          <div className={index > 0 ? '-ml-2' : ''} style={{ zIndex: transaction.associates.length - index }}>
                            <img
                              className={classNames(
                                'inline-block rounded-full ring-1',
                                index == 0 ? 'h-10 w-10 ring-white' : 'h-9 w-9 ring-white',
                              )}
                              src={associate.avatar}
                              alt=""
                              aria-hidden="true"
                            />
                          </div>
                        )}

                        {!associate.avatar && (
                          <div className="flex h-10 w-10 items-center justify-center rounded-full border border-gray-300 bg-gray-200 text-gray-700">
                            <div className="text-xl font-medium tracking-wide">{associate.initials}</div>
                          </div>
                        )}
                      </Fragment>
                    ))}
                  </div>

                  <div className="flex-1 py-3 pr-4" onClick={() => rowClicked(transaction.id)}>
                    <div className="font-bold">{transaction.name}</div>

                    {['Compliance', 'Under Review'].indexOf(transaction.status) >= 0 && (
                      <div className="inline-block whitespace-nowrap rounded bg-orange-200/60 px-2.5 py-1.5 text-sm font-medium leading-none text-orange-600">
                        <span>{transaction.status === 'Compliance' ? 'Compliance - Awaiting Review' : transaction.status}</span>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="flex max-h-[400px] min-h-[400px] items-center justify-center py-6">
              <NoResults
                label={option ? `transactions in "${options.find((o) => option === o.value).label}"` : 'transactions under review'}
              />
            </div>
          )}
        </Portlet.Body>
      </Portlet>
    </Fragment>
  )
}
