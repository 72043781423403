import React, { useState } from 'react'
import { router } from '@inertiajs/react'
import { usePage } from '@inertiajs/react'
import ActiveFilters from '@/Shared/SlideOvers/ActiveFilters'
import Avatar from '@/Shared/Avatar'
import Button from '@/Shared/Button'
import classnames from 'classnames'
import ContactPreview from '@/Pages/Contacts/Preview'
import DataTableHeader from '@/Pages/Contacts/DataTableHeader'
import MainLayout from '@/Shared/Layouts/Main'
import Pagination from '@/Shared/Pagination'

const ContactIndex = () => {
  const { contacts, filters } = usePage().props
  const [contactPreviewing, setContactPreviewing] = useState(null)
  const { data, paginator } = contacts
  const [selected, setSelected] = useState(data.reduce((options, contact) => ({ ...options, [contact.id]: false }), {}))

  const selectAll = (isSelected) => {
    let updated = { ...selected }
    Object.keys(selected).forEach((id) => (updated[id] = isSelected))
    setSelected(updated)
  }

  const selectedItemsCount = () => Object.values(selected).filter((s) => Boolean(s)).length

  const handleCheckboxChange = (id) => {
    setSelected({ ...selected, [id]: !selected[id] })
  }

  const changeContact = (id) => {
    setContactPreviewing(null)

    setTimeout(() => {
      getContactData(id)
    }, 300)
  }

  const getContactData = (id) => {
    axios.get(route('pros.show', id)).then((response) => {
      setContactPreviewing(response.data)
    })
  }

  const rowClicked = (contact) => {
    if (contact.editable) {
      router.visit(route('contacts.show', contact.id))
    } else {
      getContactData(contact.id)
    }
  }

  return (
    <div className="flex-1 space-y-2">
      {filters && <ActiveFilters />}

      <div className="bg-white shadow sm:rounded">
        <div className="flex flex-col">
          <div className="overflow-x-auto">
            <div className="inline-block min-w-full align-middle">
              <div className="overflow-hidden border-b border-gray-200 shadow">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead>
                    {selectedItemsCount() == 0 ? (
                      <DataTableHeader sortBy={filters?.sortBy || 'last_name'} direction={filters?.direction || 'asc'} />
                    ) : (
                      <tr>
                        <th scope="col" className="relative h-14 px-4 py-2 sm:px-6" colSpan="7">
                          <div className="flex items-center">
                            <button
                              className="h-10 w-10 outline-none"
                              onClick={() => selectAll(selectedItemsCount() < Object.keys(selected).length)}
                            >
                              {selectedItemsCount() < Object.keys(selected).length ? (
                                <i className="fas fa-minus-square fa-2x text-blue-500"></i>
                              ) : (
                                <i className="fas fa-check-square fa-2x text-blue-500"></i>
                              )}
                            </button>

                            <span className="pl-3 font-normal">{selectedItemsCount()} selected</span>
                          </div>
                        </th>
                      </tr>
                    )}
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {data.map((contact) => (
                      <tr
                        className={classnames({
                          'group hover:bg-gray-100': !selected[contact.id],
                          'bg-gray-100': selected[contact.id],
                        })}
                        key={contact.id}
                      >
                        <td className="lg:w-18 w-10 whitespace-nowrap py-3 pl-4 pr-4 sm:pl-6">
                          <div className="flex h-10 w-10 flex-shrink-0 items-center justify-center">
                            <div
                            // className={classnames({
                            //   'group-hover:hidden': !selected[id],
                            //   'hidden': selected[id],
                            // })}
                            >
                              <Avatar
                                contact={{ avatar: contact.avatar, name: contact.name }}
                                height="h-10"
                                width="w-10"
                                textSize="text-base"
                              />
                            </div>

                            {/* <button className={classnames("outline-none h-7 w-7", {
                              'hidden group-hover:block': !selected[id],
                            })}
                              onClick={() => handleCheckboxChange(id)}>
                              <i className={classnames("fa-2x", {
                                'fas fa-check-square text-blue-500': selected[id],
                                'far fa-square text-gray-600': !selected[id],
                              })}></i>
                            </button> */}
                          </div>
                        </td>
                        <td className="cursor-pointer py-3 pr-4 sm:pr-6" onClick={() => rowClicked(contact)}>
                          <div className="font-medium text-blue-500">{contact.name}</div>
                          {contact.company && <div className="leading-tight text-gray-700">{contact.company}</div>}

                          <div className="leading-normal">
                            {['created_at', 'updated_at'].indexOf(filters?.sortBy) >= 0 && contact[filters?.sortBy] && (
                              <div>
                                {(() => {
                                  switch (filters?.sortBy) {
                                    case 'created_at':
                                      return 'Date Created'
                                    default:
                                      return 'Last Activity'
                                  }
                                })()}
                                : {contact[filters?.sortBy]}
                              </div>
                            )}
                          </div>
                        </td>
                        <td
                          className="hidden cursor-pointer whitespace-nowrap px-4 py-3 text-gray-700 sm:px-6 xl:table-cell"
                          onClick={() => rowClicked(contact)}
                        >
                          {contact.phone}
                        </td>
                        <td
                          className="hidden cursor-pointer whitespace-nowrap px-4 py-3 text-gray-700 sm:px-6 xl:table-cell"
                          onClick={() => rowClicked(contact)}
                        >
                          {contact.email}
                        </td>
                        <td
                          className="hidden cursor-pointer whitespace-nowrap px-4 py-3 text-gray-700 sm:px-6 2xl:table-cell"
                          onClick={() => rowClicked(contact)}
                        >
                          <div>{contact.location}</div>
                          {contact.distance != null && <span className="text-blue-500">{contact.distance} miles away</span>}
                        </td>
                        <td
                          className="w-16 cursor-pointer whitespace-nowrap py-3 pr-6 text-right font-medium"
                          onClick={() => rowClicked(contact)}
                        >
                          <div className="flex items-center justify-end">
                            <i className="far fa-angle-right text-2xl leading-none text-gray-700"></i>
                          </div>
                        </td>
                      </tr>
                    ))}

                    {data.length == 0 && (
                      <tr>
                        <td colSpan="8" className="px-4 py-12 sm:px-6">
                          <div className="flex flex-col text-center">
                            <span className="text-2xl font-semibold text-red-600">No contacts match your current filters.</span>
                            <span className="text-lg font-medium text-gray-700">
                              Expecting to see results? Check your filters to make sure you're not excluding results.
                            </span>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          {data.length > 0 && <Pagination paginator={paginator} />}
        </div>
      </div>

      <ContactPreview
        contact={contactPreviewing}
        hideFooter={!contactPreviewing?.id}
        open={contactPreviewing != null}
        onContactChanged={changeContact}
        onClosed={() => setContactPreviewing(null)}
      >
        <ContactPreview.FooterActions>
          {({ contact }) => (
            <div className="flex justify-center">
              <Button
                type="submit"
                theme="solid"
                onClick={() =>
                  contact.status === 'internal'
                    ? router.visit(route('pros.show', contact.id))
                    : contact.url
                      ? window.open(contact.url, '_blank')
                      : null
                }
                disabled={!contact.status || !contact.url}
              >
                {contact.status === 'internal' ? 'View Internal' : 'View'} PRO-Website
              </Button>
            </div>
          )}
        </ContactPreview.FooterActions>
      </ContactPreview>
    </div>
  )
}

// Persistent layout
// Docs: https://inertiajs.com/pages#persistent-layouts
ContactIndex.layout = (page) => <MainLayout title="Contacts" children={page} />

export default ContactIndex
