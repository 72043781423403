import { Fragment, useEffect, useState } from 'react'
import classNames from 'classnames'

const Card = () => null
const LeftColumn = () => null
const MiddleColumn = () => null
const RightColumn = () => null

function debounce(fn, ms) {
  let timer
  return (_) => {
    clearTimeout(timer)
    timer = setTimeout((_) => {
      timer = null
      fn.apply(this, arguments)
    }, ms)
  }
}

const Details = ({ children, columns }) => {
  const card = children.find((el) => el.type === Card)
  const leftColumn = children.find((el) => el.type === LeftColumn)
  const middleColumn = children.find((el) => el.type === MiddleColumn)
  const rightColumn = children.find((el) => el.type === RightColumn)

  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  })

  const debouncedHandleResize = debounce(function handleResize() {
    setDimensions({
      height: window.innerHeight,
      width: window.innerWidth,
    })
  }, 200)

  useEffect(() => {
    window.addEventListener('resize', debouncedHandleResize)

    return (_) => {
      window.removeEventListener('resize', debouncedHandleResize)
    }
  })

  return (
    <div className={classNames('relative md:z-0 md:grid md:h-full md:grid-flow-row md:grid-cols-details-2')}>
      <div className="border-r border-gray-200 bg-white md:h-full lg:overflow-hidden">
        <div className="md:h-full lg:flex lg:flex-col">
          {card && card.props.children}

          <div className="md:h-full lg:overflow-y-auto xl:pb-6">
            {leftColumn && leftColumn.props.children}

            {rightColumn && dimensions.width < 1200 && (
              <Fragment>
                <div className="h-5 border-b border-gray-200 bg-gray-100"></div>
                {rightColumn.props.children}
              </Fragment>
            )}
          </div>
        </div>
      </div>

      <div
        className={classNames('flex lg:overflow-hidden', {
          'xl:relative xl:grid xl:h-full xl:grid-flow-row xl:grid-cols-details-2-reversed': columns == 3,
        })}
      >
        {middleColumn && (
          <div
            className={classNames(
              'flex w-full flex-col self-stretch',
              middleColumn.props.overflowHidden ? 'overflow-hidden' : 'lg:overflow-y-auto',
            )}
            onScroll={middleColumn.props.onScroll}
          >
            {middleColumn.props.children}
          </div>
        )}

        {rightColumn && dimensions.width >= 1200 && (
          <div
            className={classNames(
              'relative z-0 hidden border-l border-gray-200 bg-white xl:block',
              rightColumn.props.overflowHidden ? 'overflow-hidden' : 'overflow-y-auto',
            )}
          >
            {rightColumn.props.children}
          </div>
        )}
      </div>
    </div>
  )
}

Details.Card = Card
Details.LeftColumn = LeftColumn
Details.MiddleColumn = MiddleColumn
Details.RightColumn = RightColumn

export default Details
