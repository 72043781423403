import { useEffect, useState } from 'react'
import Button from '@/Shared/Button'
import Cropper from 'react-cropper'
import 'cropperjs/dist/cropper.css'
import Dialog from '@/Shared/Dialog/Index'

export default ({ aspectRatio, dialogSize, file, onApply, onClose }) => {
  const [cropper, setCropper] = useState(null)

  useEffect(() => {
    if (cropper) {
      window.addEventListener('keydown', handleKeydown)
    }

    return (_) => {
      window.removeEventListener('keydown', handleKeydown)
    }
  }, [cropper])

  const handleKeydown = ({ key }) => {
    switch (key) {
      case 'Enter':
        onCrop()
        break
      case 'ArrowLeft':
        cropper.move(-1, 0)
        break
      case 'ArrowRight':
        cropper.move(1, 0)
        break
      case 'i':
        cropper.zoom(0.1)
        break
      case 'o':
        cropper.zoom(-0.1)
        break
      case 'l':
        cropper.rotate(-90)
        break
      case 'r':
        cropper.rotate(90)
        break
    }
  }

  const onCrop = () => {
    if (cropper) {
      onApply(cropper.getCroppedCanvas().toDataURL())
    }
  }

  return (
    <Dialog
      footerActions={
        <Button type="submit" theme="solid" onClick={onCrop}>
          Apply
        </Button>
      }
      show={Boolean(file)}
      size={dialogSize || 'max-w-3xl'}
      title="Edit Media"
      onClosed={() => onClose()}
    >
      <Cropper
        style={{ maxHeight: '450px', idth: '100%' }}
        autoCropArea={1}
        checkOrientation={false}
        cropBoxMovable={false}
        cropBoxResizable={false}
        dragMode="move"
        initialAspectRatio={aspectRatio}
        viewMode={1}
        src={file?.url}
        onInitialized={(instance) => setCropper(instance)}
        guides={false}
        responsive
      />

      {cropper && (
        <div className="mt-2.5 -mb-4 flex items-center justify-center">
          <Button theme="icon" title="Zoom In (I)" onClick={(event) => cropper.zoom(0.1)}>
            <span className="fas fa-search-plus text-xl" />
          </Button>
          <Button theme="icon" title="Zoom Out (O)" onClick={(event) => cropper.zoom(-0.1)}>
            <span className="fas fa-search-minus text-xl" />
          </Button>
          <Button theme="icon" title="Rotate Left (L)" onClick={(event) => cropper.rotate(-90)}>
            <span className="fas fa-undo text-xl" />
          </Button>
          <Button theme="icon" title="Rotate Right (R)" onClick={(event) => cropper.rotate(90)}>
            <span className="fas fa-redo text-xl" />
          </Button>
        </div>
      )}
    </Dialog>
  )
}
