import { Fragment, useEffect, useRef, useState } from 'react'
import { router } from '@inertiajs/react'
import About from '../../Shared/Contacts/About'
import ContactEdit from '@/Pages/Contacts/Edit'
import SlideOver from '@/Shared/SlideOver'

const Actions = () => null
const FooterActions = () => null

const Preview = ({
  children,
  contact: record,
  editable,
  hideFooter,
  linkedId,
  linkedType,
  open,
  title,
  onContactChanged,
  onEdit,
  onClosed,
}) => {
  let focusRef = useRef()
  const actions = Array.isArray(children) ? children.find((el) => el?.type === Actions) : children?.type === Actions ? children : null
  const footerActions = Array.isArray(children)
    ? children.find((el) => el?.type === FooterActions)
    : children.type === FooterActions
    ? children
    : null
  const [contact, setContact] = useState({})
  const [editing, setEditing] = useState(null)

  useEffect(() => setContact(record || {}), [record])

  const onVisit = (id) => {
    router.visit(route('contacts.show', id))
  }

  return (
    <SlideOver
      focusRef={focusRef}
      footerActions={footerActions?.props.children({ contact: contact, onVisit })}
      show={open}
      size="max-w-md"
      title={contact.full_name}
      subTitle={contact.contact_type}
      onClosed={onClosed}
      hideCloseButton
      hideFooter={hideFooter}
      hidePadding
    >
      {contact && (
        <Fragment>
          <div className="relative z-10 flex justify-end p-4">{actions?.props.children({ contact: contact })}</div>

          {children}

          <About
            contact={contact}
            editable={editable}
            title={title}
            preview
            onContactChanged={onContactChanged}
            onEdit={() => (onEdit != undefined ? onEdit(contact) : setEditing(contact))}
          />

          {!onEdit && (
            <ContactEdit
              linkedId={linkedId}
              linkedType={linkedType}
              record={editing}
              open={editing != null}
              onClosed={() => setEditing(null)}
            />
          )}
        </Fragment>
      )}
    </SlideOver>
  )
}

Preview.Actions = Actions
Preview.FooterActions = FooterActions

export default Preview
