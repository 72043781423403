import { useEffect, useState } from 'react'
import Select from '@/Shared/Forms/Select'

const TimePicker = ({ id, label, error, value, disabled, interval, isClearable, required, defaultToCurrentTime, onChange }) => {
  const [time, setTime] = useState()
  useEffect(() => {
    if (!Boolean(value)) {
      if (defaultToCurrentTime) {
        setTime(getCurrentTime(new Date()))
      }
    } else {
      setTime(null)
      setTimeout(() => {
        setTime(getCurrentTime(value))
      }, 5)
    }
  }, [value])

  const getCurrentTime = (datetime) => {
    let hours = datetime.getHours()
    let minutes = datetime.getMinutes()
    let nextInterval = minutes + interval - (minutes % interval)

    return minutes % interval == 0
      ? addLeadingZero(hours) + ':' + addLeadingZero(minutes)
      : addLeadingZero(nextInterval < 60 ? hours : hours + 1) + ':' + (nextInterval < 60 ? addLeadingZero(nextInterval) : '00')
  }

  const addLeadingZero = (data) => {
    return data.toString().length == 1 ? '0' + data : data
  }

  const getAvailableTimes = () => {
    let times = []

    for (let hour = 9; hour < 24; hour++) {
      for (let minutes = 0; minutes < 60; minutes = minutes + interval) {
        let label = (hour > 12 ? hour - 12 : hour == 0 ? 12 : hour) + ':' + addLeadingZero(minutes) + ' ' + (hour >= 12 ? 'PM' : 'AM')

        times.push({
          value: addLeadingZero(hour) + ':' + addLeadingZero(minutes),
          label: label === '12:00 PM' ? 'Noon' : label,
        })
      }
    }

    for (let hour = 0; hour < 9; hour++) {
      for (let minutes = 0; minutes < 60; minutes = minutes + interval) {
        let label = (hour > 12 ? hour - 12 : hour == 0 ? 12 : hour) + ':' + addLeadingZero(minutes) + ' ' + (hour >= 12 ? 'PM' : 'AM')

        times.push({
          value: addLeadingZero(hour) + ':' + addLeadingZero(minutes),
          label: label === '12:00 AM' ? 'Midnight' : label,
        })
      }
    }

    return times
  }

  const handleChanged = (selected) => {
    let datetime = value || new Date()
    let values = (selected || getCurrentTime(new Date())).split(':')

    datetime.setHours(values[0])
    datetime.setMinutes(values[1])

    setTime(getCurrentTime(datetime))
    onChange(datetime)
  }

  return (
    <Select
      className=""
      label={label}
      name={id}
      error={error}
      options={getAvailableTimes()}
      placeholder="Select a time"
      value={time}
      isClearable={isClearable}
      onChange={(selected) => handleChanged(selected?.value)}
      required={required || false}
      disabled={disabled}
    />
  )
}

TimePicker.defaultProps = {
  interval: 15,
}

export default TimePicker
