import { Fragment, useEffect, useState } from 'react'
import Button from '@/Shared/Button'
import FormDialog from '@/Shared/Transaction/Forms/FormDialog'
import classNames from 'classnames'
import { usePage } from '@inertiajs/react'

export default function TransactionForms() {
  const { transaction } = usePage().props
  const { forms } = transaction

  const [form, setForm] = useState(null)
  const [editing, setEditing] = useState(false)

  useEffect(() => setEditing(Boolean(form)), [form])

  const openForm = (id) => {
    axios.get(route('transactions.forms.show', id)).then((response) => {
      setForm(response.data)
    })
  }

  return (
    <Fragment>
      <div className="mb-2 p-3">
        <span className="text-2xl font-medium tracking-wider text-gray-800">Forms</span>
      </div>

      <div className="mb-4 flex flex-grow flex-col divide-y divide-gray-200 rounded bg-white shadow">
        {forms.length > 0 ? (
          <div className="flex-1 divide-y divide-gray-200">
            {forms.map((form) => (
              <div
                className={classNames('flex w-full cursor-pointer items-center gap-4 px-5 py-4 text-gray-600 hover:bg-gray-100')}
                key={form.id}
                onClick={() => !transaction.restricted && openForm(form.id)}
              >
                <div className="flex flex-1 items-center">
                  <div className="flex h-10 w-10 items-center justify-center">
                    <i className={`far fa-ballot text-2xl text-gray-400`}></i>
                  </div>

                  <div className="ml-4 flex-1 text-left">
                    <p className="font-medium text-blue-500">{form.name}</p>
                  </div>
                </div>

                {!transaction.restricted &&
                  (transaction.completed_at ? (
                    <Button theme="outline-sm">
                      <div className="flex items-center gap-2">
                        <i className="far fa-magnifying-glass-plus text-xl leading-none"></i>
                        View
                      </div>
                    </Button>
                  ) : (
                    <div className="mr-2 flex-shrink-0">
                      <i className="far fa-pen text-lg font-medium leading-none hover:font-bold hover:text-blue-500"></i>
                    </div>
                  ))}
              </div>
            ))}
          </div>
        ) : (
          <div className="bg-gray-100 p-4 text-center font-medium text-gray-600 sm:px-6">No forms have been added to this transaction.</div>
        )}

        {form && (
          <FormDialog
            open={editing}
            record={form}
            disabled={!transaction.editable || transaction.restricted || transaction.completed_at}
            onClosed={() => {
              setEditing(false)
              setTimeout(() => {
                setForm(null)
              }, 100)
            }}
          />
        )}
      </div>
    </Fragment>
  )
}
