import { useState } from 'react'
import AccordionCard from '@/Shared/AccordionCard'
import Button from '@/Shared/Button'
import Edit from './Edit'
import Helpers from '@/utils/helpers'
import { usePage } from '@inertiajs/react'
import classNames from 'classnames'
import { Tooltip } from '@/Shared/Tooltip'

export default ({ type }) => {
  const { associate } = usePage().props.contact
  const { user } = usePage().props.auth
  const [entry, setEntry] = useState(null)
  const entries = associate[type] || []

  const viewTransaction = (e, entry) => {
    e.stopPropagation()

    window.open(route('transactions.show', entry.transaction_id), '_blank')
  }

  return (
    <AccordionCard
      title={`${type === 'team_cap_history' ? 'Team ' : ''}CAP History (${entries?.length || 0})`}
      actions={
        Helpers.isAdminOrHR(user) && (
          <Button
            theme="link"
            onClick={() =>
              setEntry({
                associate_id: associate.id,
                cap: associate.cap,
                cap_residual: associate.cap_residual,
              })
            }
          >
            <i className="far fa-plus pr-1 text-sm"></i>
            <span>Add</span>
          </Button>
        )
      }
      indented={false}
      className="mx-6 mb-4"
      collapsed
    >
      {entries?.length > 0 ? (
        <table className="w-full border border-gray-300">
          <thead>
            <tr className="bg-gray-200">
              <th width="145px" className="px-3 py-2 text-left font-medium">
                Date
              </th>

              <th className="px-3 py-2 text-center font-medium">CAP Change</th>

              {Helpers.isAdminOrHR(user) && <th width="30px"></th>}
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-300">
            {entries.map((entry, index) => (
              <tr
                className={classNames({ 'group hover:cursor-pointer hover:bg-gray-100': Helpers.isAdminOrHR(user) })}
                key={index}
                onClick={() => Helpers.isAdminOrHR(user) && setEntry(entry)}
              >
                <td className="py-2 pl-3 text-left">{entry.date}</td>

                <td className="py-2 pl-3 text-center">
                  <span
                    className={classNames('font-semibold leading-none', {
                      'text-red-600': entries[index + 1] && entry.cap - entries[index + 1].cap < 0,
                    })}
                  >
                    {Helpers.formatCurrency(
                      entries[index + 1] && entry.cap - entries[index + 1].cap < 0 ? entry.cap - entries[index + 1].cap : entry.cap,
                      2,
                    )}
                  </span>
                  {entries[index + 1] && entry.cap - entries[index + 1].cap < 0 && (
                    <div className="text-sm leading-tight text-gray-600">{Helpers.formatCurrency(entry.cap)}</div>
                  )}
                </td>

                {Helpers.isAdminOrHR(user) && (
                  <td className="pr-4 text-right">
                    <div className="flex items-center justify-end gap-3">
                      {entry.transaction_id && (
                        <Tooltip label="Visit Page" placement="bottom">
                          <Button theme="icon" onClick={(e) => viewTransaction(e, entry)}>
                            <span className="sr-only">View Transaction</span>
                            <i className="far fa-external-link text-xl text-gray-500 group-hover:font-bold group-hover:text-primary-500"></i>
                          </Button>
                        </Tooltip>
                      )}

                      <i className="far fa-angle-right text-2xl leading-none text-gray-800"></i>
                    </div>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div className="p-4 text-center text-gray-700">No entries have been added.</div>
      )}

      <Edit entry={entry} type={type} open={entry != undefined} onClosed={() => setEntry(null)} />
    </AccordionCard>
  )
}
