import { useContext, useEffect, useRef, useState } from 'react'
import { AccordionContext } from '@/Shared/Accordion'
import Button from '@/Shared/Button'
import MultipleInputBlock from '@/Shared/Forms/MultipleInputBlock'
import Select from '@/Shared/Forms/Select'
import TextInput from '@/Shared/Forms/TextInput'
import { router } from '@inertiajs/react'

export default ({ user }) => {
  const focusRef = useRef()
  const { onSaved } = useContext(AccordionContext)

  const [data, setData] = useState({
    setting: 'socialmedia',
    social_accounts: user.social_accounts?.length > 0 ? user.social_accounts : [{}],
  })
  const [errors, setErrors] = useState({})
  const [socialTypes] = useState([
    { label: 'Facebook', value: 'Facebook' },
    { label: 'Flickr', value: 'Flickr' },
    { label: 'Instagram', value: 'Instagram' },
    { label: 'LinkedIn', value: 'LinkedIn' },
    { label: 'Pinterest', value: 'Pinterest' },
    { label: 'Snapchat', value: 'Snapchat' },
    { label: 'TikTok', value: 'TikTok' },
    { label: 'Twitter', value: 'Twitter' },
    { label: 'YouTube', value: 'YouTube' },
  ])

  useEffect(() => {
    focusRef.current.focus()
  }, [])

  const submit = (event) => {
    event.preventDefault()

    axios
      .post(route('api.users.update', user.id), { ...data, _method: 'PATCH' })
      .then((_) =>
        router.reload({
          onFinish: () => {
            onSaved()
          },
        }),
      )
      .catch((error) => {
        if (error.response != undefined) {
          const { status } = error.response
          const VALIDATION_ERROR = 422
          if (status == VALIDATION_ERROR) {
            setErrors(error.response.data.errors)
          }
        }
      })
  }

  return (
    <div>
      <form className="mx-auto mt-4 max-w-lg space-y-1.5" onSubmit={submit}>
        <MultipleInputBlock
          data={data.social_accounts}
          onChange={(value) => setData({ ...data, social_accounts: value })}
          render={({ data, onChange }) => (
            <div className="flex-1 space-y-3 sm:flex sm:space-y-0 sm:space-x-3">
              <TextInput
                ref={focusRef}
                label="URL"
                classes="sm:flex-grow"
                name="url"
                value={data.url ?? ''}
                onChange={(value) => onChange(value, 'url')}
              />

              <Select
                classes="sm:flex-grow"
                label="Type"
                name="label"
                options={socialTypes}
                value={data.type}
                isClearable={false}
                onChange={(selected) => onChange(selected && selected.value, 'type')}
              />
            </div>
          )}
        />

        <div className="flex justify-end">
          <Button type="submit" theme="solid">
            Save
          </Button>
        </div>
      </form>
    </div>
  )
}
