import { Fragment, useContext, useEffect, useRef, useState } from 'react'
import axios from '@/apis/axios'
import { AccordionContext } from '@/Shared/Accordion'
import classNames from 'classnames'
import Button from '@/Shared/Button'
import MultipleInputBlock from '@/Shared/Forms/MultipleInputBlock'
import Select from '@/Shared/Forms/Select'
import { router } from '@inertiajs/react'

export default ({ user }) => {
  const focusRef = useRef()
  const { onSaved } = useContext(AccordionContext)

  const [data, setData] = useState({
    setting: 'services-provided',
    services_provided: [{ primary: true }],
  })
  const [services, setServices] = useState([])
  const [errors, setErrors] = useState({})

  useEffect(() => {
    focusRef.current.focus()

    getServices().then((services) => {
      setData({
        ...data,
        services_provided:
          user.services_provided.length > 0
            ? user.services_provided.map((serviceProvided) => {
                let service = services.find((service) => service.value === Number(serviceProvided.service_id))
                if (!service) return

                return {
                  id: serviceProvided.id,
                  value: service.value,
                  label: service.label,
                  approved: serviceProvided.approved,
                  primary: serviceProvided.primary,
                }
              })
            : data.services_provided,
      })
    })
  }, [])

  const filteredServices = (currentService) =>
    services.filter(
      (service) =>
        service.value === currentService ||
        data.services_provided?.find((serviceProvided) => service.value == serviceProvided.value) == undefined,
    )

  const getServices = () => {
    return axios.get(route('api.services.index')).then((response) => {
      setServices(response.data)
      return response.data
    })
  }

  const removeDeniedServices = (deniedServices) => {
    setData({
      ...data,
      services_provided: data.services_provided.filter(
        (serviceProvided) => deniedServices.find((service) => serviceProvided.value == service.id) == undefined,
      ),
    })
  }

  const serviceChanged = (servicesProvided) => {
    let currentPrimaryService = data.services_provided.find((service) => service.primary)
    let newPrimaryService = servicesProvided.find((service) => service.primary)

    if (newPrimaryService == undefined) {
      // Make sure primary can't be removed
      servicesProvided = servicesProvided.map((service) =>
        service.value == currentPrimaryService?.value ? { ...service, primary: true } : service,
      )
    } else if (currentPrimaryService?.value != newPrimaryService.value) {
      // If primary is changing, set other services to pending approval
      servicesProvided = servicesProvided.map((service) => ({ ...service, approved: service.value == newPrimaryService.value }))
    } else {
      // Make sure primary value is set
      servicesProvided = servicesProvided.map((service) => ({ ...service, primary: service.primary || false }))
    }

    setData({ ...data, services_provided: servicesProvided })
  }

  const submit = (event) => {
    event.preventDefault()

    let formData = { ...data, services_provided: data.services_provided.filter((serviceProvided) => Boolean(serviceProvided.value)) }

    axios
      .post(route('api.users.update', user.id), { ...formData, _method: 'PATCH' })
      .then((_) =>
        router.reload({
          onFinish: () => {
            onSaved()
          },
        }),
      )
      .catch((error) => {
        if (error.response != undefined) {
          const { status } = error.response
          const VALIDATION_ERROR = 422
          if (status == VALIDATION_ERROR) {
            let errors = error.response.data.errors
            setErrors(errors)
            if (errors.denied_services?.length > 0) {
              removeDeniedServices(errors.denied_services)
            }
          }
        }
      })
  }

  return (
    <div>
      <p>
        Below is the list of services you provide. For each service you add to the below list, you will appear in searches on LSNPros.com.
        Your PRO-Website is unique to you and therefore all services specified must relate to your primary service. Therefore, when
        additional services are added and you select the <strong>Save</strong> button, the system will check to see if the services relate.
        If the system has previously approved the relationship, the service will be automatically approved. If not, however, the service
        will be marked as <strong>Pending Approval</strong> until a member of our team can verify the relationship. You will be notified
        when the service has been approved or why it was not approved.
      </p>

      {Object.keys(errors).length > 0 && (
        <div className="mt-3 space-y-3 rounded-lg border border-red-500 bg-red-50 p-4 text-red-600">
          {errors.services_provided && <p className="text-center">{errors.services_provided[0]}</p>}

          {errors.denied_services?.length > 0 && (
            <Fragment>
              <p>
                We have determined that the following services aren't closely related to your primary service{' '}
                <strong>{errors.denied_services[0].primary_service}</strong> and therefore have been removed from your services provided:
              </p>
              <ul className="ml-5 list-disc font-bold">
                {errors.denied_services.map((serviceProvided) => (
                  <li key={serviceProvided.service}>{serviceProvided.service}</li>
                ))}
              </ul>
            </Fragment>
          )}
        </div>
      )}

      <form className="mx-auto max-w-2xl" onSubmit={submit}>
        <MultipleInputBlock
          data={data.services_provided}
          onChange={(value) => serviceChanged(value)}
          hasPrimary
          render={({ data, onChange }) => (
            <div className="flex flex-1 flex-col gap-1.5 sm:flex-row sm:items-center sm:gap-3">
              <div className="order-2 sm:order-1 sm:flex sm:w-24 sm:justify-center">
                {data.value && (
                  <div
                    className={classNames(
                      'inline-flex flex-wrap rounded px-2.5 py-1.5 text-center text-sm leading-none',
                      data.approved ? 'bg-green-100 text-green-800' : 'bg-yellow-200 text-yellow-700',
                    )}
                  >
                    <span className="font-medium uppercase">{data.approved ? 'Approved' : 'Pending Approval'}</span>
                  </div>
                )}
              </div>

              <div className="flex-1">
                <Select
                  ref={focusRef}
                  classes="mb-0"
                  name="service_id"
                  options={filteredServices(data.value)}
                  placeholder="Choose a service"
                  value={data.value}
                  isClearable={false}
                  disabled={Number.isInteger(data.id)}
                  onChange={(selected) => onChange(selected?.value, 'value')}
                />
              </div>
            </div>
          )}
        />

        <div className="flex justify-end">
          <Button type="submit" theme="solid">
            Save
          </Button>
        </div>
      </form>
    </div>
  )
}
