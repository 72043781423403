import React from 'react'
import NotificationItem from './Item'

interface Props {
  type?: string
  items: Object[]
}

const NotificationsGroup = ({ type, items }: Props) => {
  return (
    <React.Fragment>
      {items.length > 0 && (
        <div>
          {type && (
            <div className="px:4 border-b border-gray-300 bg-gray-200 py-1 sm:px-6">
              <span className="text-xs font-bold uppercase text-gray-600">{type}</span>
            </div>
          )}
          <div className="divide-y divide-gray-300 divide-opacity-75">
            {items.map((item: any, index: number) => (
              <NotificationItem item={item} key={index} />
            ))}
          </div>
        </div>
      )}
    </React.Fragment>
  )
}

export default NotificationsGroup
