import { Fragment, useRef, useState } from 'react'
import axios from 'axios'
import Button from '@/Shared/Button'
import Contact from './Item'
import Dialog from '@/Shared/Dialog/Index'
import TextInput from '@/Shared/Forms/TextInput'
import { router } from '@inertiajs/react'
import useDebounce from '@/hooks/useDebounce'

export default ({ contacts: current, open, type, onCreateContact, onClose }) => {
  let focusRef = useRef()

  const minCharactersToSearch = 3
  const [pros, setPROs] = useState([])
  const [contacts, setContacts] = useState([])
  const [search, setSearch] = useState('')
  const [activeContactId, setActiveContactId] = useState(null)
  const [errors, setErrors] = useState(null)

  useDebounce(() => search.length >= minCharactersToSearch && filterData(), 300, [search])

  const filterData = () => {
    axios
      .get('/api/contacts', {
        params: {
          limit: 25,
          search: search,
          groups: 'all',
          exclude_following: true,
        },
      })
      .then((response) => {
        setContacts(response.data.filter((contact) => contact.type === 'Contact'))
        setPROs(response.data.filter((contact) => ['Associate', 'PRO'].indexOf(contact.type) >= 0))
      })
  }

  const getStatus = (contact) => {
    let match = current.find((c) => c.related_id === contact.id || c.contactsFollowed?.indexOf(contact.id) >= 0)
    if (match) {
      return 'Added'
    }
  }

  const refreshResults = () => {
    router.visit(window.location.href, {
      preserveScroll: true,
      preserveState: true,
      replace: true,
      onFinish: (_visit) => {
        setErrors(null)
      },
    })
  }

  const onClosing = () => {
    onClose()

    setTimeout(() => {
      setSearch('')
    }, 300)
  }

  return (
    <Dialog
      focusRef={focusRef}
      background="bg-gray-100"
      cancelText="Close"
      position="top"
      show={open}
      size="sm:max-w-4xl"
      title="Search Contacts & PROs"
      onClosed={() => onClosing()}
    >
      <div>
        <div className="relative flex w-full flex-grow">
          <TextInput
            ref={focusRef}
            classes="flex-grow"
            inputClasses="px-4 pr-14 py-2 bg-gray-50"
            placeholder="Search by name, phone number or email address"
            value={search}
            onChange={(value) => setSearch(value)}
          />

          <span className="absolute inset-y-0 right-0 flex items-center justify-center">
            {search.length > 0 && search.length < 3 && (
              <span className="text-base text-gray-500">Type {3 - search.length} more characters</span>
            )}
            <span className="flex h-full items-center justify-center px-2">
              <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <rect id="bound" x="0" y="0" width="24" height="24"></rect>
                  <path
                    className="fill-current text-blue-500"
                    d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                    id="Path-2"
                    fillRule="nonzero"
                    opacity="0.3"
                  ></path>
                  <path
                    className="fill-current text-blue-500"
                    d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                    id="Path"
                    fillRule="nonzero"
                  ></path>
                </g>
              </svg>
            </span>
          </span>
        </div>

        {search.length < minCharactersToSearch ? (
          <div className="flex p-8 text-center text-gray-900">
            <p>To add a relationship to this contact, use the above field to search your existing contacts and our directory of PROs.</p>
          </div>
        ) : (
          <div>
            {errors?.contactExists && (
              <div className="mb-2 rounded-md border border-red-200 bg-red-100 p-4 text-center font-semibold text-red-600">
                <p className="mb-2">{errors.contactExists}</p>

                <Button theme="outline" onClick={() => refreshResults()}>
                  Refresh Search Results
                </Button>
              </div>
            )}

            {pros?.length > 0 && (
              <Fragment>
                <h3 className="mb-2 mt-4 block text-lg font-medium">
                  Maintained by the PRO <span className="text-red-500">- Recommended</span>
                </h3>

                <div className="flex flex-col">
                  {pros.map((contact, index) => (
                    <Contact
                      activeContactId={activeContactId}
                      contact={contact}
                      position={pros.length - index}
                      last={index == 0}
                      status={getStatus(contact)}
                      type={type}
                      key={contact.id}
                      onAdding={(id) => setActiveContactId(id)}
                      onClose={() => onClosing()}
                      onError={(errors) => setErrors(errors)}
                    />
                  ))}
                </div>
              </Fragment>
            )}

            {contacts?.length > 0 && (
              <Fragment>
                <h3 className="mb-2 mt-4 block text-lg font-medium">Others that we found in your Contacts</h3>

                <div className="flex flex-col">
                  {contacts.map((contact, index) => (
                    <Contact
                      activeContactId={activeContactId}
                      contact={contact}
                      position={contacts.length - index}
                      last={index == 0}
                      status={getStatus(contact)}
                      type={type}
                      key={contact.id}
                      onAdding={(id) => setActiveContactId(id)}
                      onClose={() => onClosing()}
                      onError={(errors) => setErrors(errors)}
                    />
                  ))}
                </div>
              </Fragment>
            )}

            {search.length >= minCharactersToSearch && (
              <div className="mt-4 rounded-lg border-2 border-gray-300 bg-white p-6 text-center font-semibold">
                {pros?.length == 0 && contacts?.length == 0
                  ? `No results found for "${search}"`
                  : "Can't find the person you're looking for?"}

                <div className="mt-2">
                  <Button theme="outline" onClick={() => onCreateContact(search)}>
                    Create a new contact
                  </Button>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </Dialog>
  )
}
