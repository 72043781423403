import { useEffect, useState } from 'react'
import classNames from 'classnames'
import Container from './Container'
import { router } from '@inertiajs/react'
import TextInput from '@/Shared/Forms/TextInput'
import { usePage } from '@inertiajs/react'

export default ({ contact, editing, tag, onChanged, onDeleted, onRestored, onUpdated }) => {
  const { filters } = usePage().props
  const [selected, setSelected] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  useEffect(() => {
    if (contact) {
      setSelected(contact?.tags.filter((t) => t.label === tag.label).length > 0)
    }
  }, [contact])

  useEffect(() => {
    if (!contact && filters) {
      setSelected(filters?.tags?.filter((t) => t === tag.label).length > 0)
    }
  }, [filters])

  const handleCreated = () => {
    setSubmitting(true)

    axios
      .post(`/api/tags`, {
        data: { value: tag.label },
      })
      .then((response) => onUpdated(response))
      .catch((_) => setSubmitting(false))
  }

  const updateContact = () => {
    let exists = contact.tags.find((t) => t.id == tag.id)
    let tags = exists ? contact.tags.filter((t) => t.id != tag.id) : contact.tags.concat(tag)

    axios
      .patch(route('contacts.tags.update', contact.id), {
        data: tags,
        _method: 'patch',
      })
      .then((_) => router.reload())
  }

  const updateSelected = () => {
    let currentTags = filters && filters.tags ? filters.tags : []

    if (selected) {
      currentTags = currentTags.filter((t) => t !== tag.label)
    } else {
      currentTags = currentTags.concat(tag.label)
    }

    const values = { ...filters, tags: currentTags }
    const query = encodeURIComponent(JSON.stringify(values))

    router.get(
      route(route().current(), { filters: query }),
      {},
      {
        preserveScroll: true,
        preserveState: true,
      },
    )
  }

  return (
    <Container
      classes={classNames(
        'flex items-center justify-between relative my-0.5 group outline-none rounded focus:ring-2 focus:ring-primary-500',
        {
          'text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:bg-white': !selected && !tag.creatable && !editing,
          'font-medium bg-green-50 text-green-900 hover:bg-green-100': selected && !editing,
        },
      )}
      interactive={!editing && !tag.creatable}
      onClick={() => (contact ? updateContact() : updateSelected())}
    >
      <div className={classNames('flex flex-1 items-center', { 'px-3 py-1.5': !editing })}>
        {!editing ? (
          <i
            className={classNames('fa-tag pr-4 text-xl', {
              'far text-gray-400 group-hover:text-gray-900': !selected && !tag.creatable,
              'far text-green-900': selected,
              'fas text-orange-500': tag.creatable,
            })}
          ></i>
        ) : (tag.deleting == undefined ? !tag.deleted : !tag.deleting) ? (
          <button
            className="mr-3.5 flex h-8 items-center justify-center rounded border-0 px-2.5 py-2 outline-none transition-all duration-150 hover:bg-red-100 focus:ring-2 focus:ring-red-600"
            onClick={() => onDeleted(tag)}
          >
            <span className="sr-only">Delete {tag.label}</span>
            <i className="fas fa-times text-xl text-red-600"></i>
          </button>
        ) : (
          <button
            className="mr-3.5 flex h-8 items-center justify-center rounded border-0 px-2.5 py-2 outline-none transition-all duration-150 hover:bg-primary-100 focus:ring-2 focus:ring-primary-500"
            onClick={() => onRestored(tag)}
          >
            <span className="sr-only">Restore {tag.label}</span>
            <i className="fas fa-plus text-xl text-primary-500"></i>
          </button>
        )}

        {!editing ? (
          <span className={classNames('text-lg', { 'font-medium text-orange-500': tag.creatable })}>{tag.label}</span>
        ) : (
          <div className="flex-grow border-b border-gray-400 hover:border-transparent">
            <TextInput
              classes="w-full"
              inputClasses="border-none py-1.5 px-3"
              placeholder="Enter the name of your tag"
              value={tag.label}
              onChange={(value) => onChanged({ ...tag, label: value })}
            />
          </div>
        )}

        {!editing && selected && (
          <div className="absolute inset-y-0 right-3.5 flex items-center">
            <i className="fas fa-check text-lg text-green-900"></i>
          </div>
        )}
      </div>

      {!editing && tag.creatable && (
        <button
          className={classNames(
            'relative mr-2 inline-flex h-8 items-center rounded px-4 font-medium leading-none outline-none transition-all duration-150 ease-in-out',
            {
              'bg-orange-500 text-white hover:bg-opacity-80 focus:border-transparent focus:ring-2 focus:ring-orange-500 focus:ring-offset-1':
                !submitting,
              'bg-gray-300 text-gray-500 shadow-inner': submitting,
            },
          )}
          disabled={submitting}
          onClick={() => handleCreated()}
        >
          <i className="fas fa-plus mr-2 text-sm"></i> Create
        </button>
      )}
    </Container>
  )
}
