import React, { Fragment, useEffect, useRef, useState } from 'react'
import Button from '@/Shared/Button'
import classNames from 'classnames'
import { usePage } from '@inertiajs/react'
import Dropdown from '@/Shared/Dropdown'
import DropdownItem from '@/Shared/DropdownItem'
import { router } from '@inertiajs/react'
import Radio from '@/Shared/Forms/Radio'
import Select from '@/Shared/Forms/Select'
import SlideOver from '@/Shared/SlideOver'
import TextArea from '@/Shared/Forms/TextArea'
import TextInput from '@/Shared/Forms/TextInput'
import { Tooltip } from '@/Shared/Tooltip'
import { useForm } from '@inertiajs/react'

export default ({ open, visit, onClosed, onUpdate }: any) => {
  let focusRef = useRef()
  const { auth, openhouse }: any = usePage().props
  const { user }: any = auth
  const { data, clearErrors, errors, setData, post, reset, transform } = useForm()
  const [cohosts, setCohosts] = useState([] as any)
  const [submitting, setSubmitting] = useState(false)

  useEffect(() => {
    if (visit) {
      setData({
        id: visit.id,
        type: visit.type,
        assigned_id: visit.assigned_id,
        first_name: visit.first_name,
        last_name: visit.last_name,
        company: visit.company || '',
        email: visit.email,
        phone: visit.phone,
        notified: visit.notified || false,
        notes: visit.notes,
        contracted: visit.contracted || false,
        broker_first_name: visit.broker && visit.broker.first_name,
        broker_last_name: visit.broker && visit.broker.last_name,
        broker_email: visit.broker && visit.broker.email,
        buyer_name: visit.buyer && visit.buyer.name,
        prequalified: visit.prequalified || false,
        lender_name: visit.lender && visit.lender.name,
        lender_company: visit.lender && visit.lender.company,
      })

      setCohosts(
        [
          { label: 'None', value: '' },
          { label: openhouse.host.full_name, value: openhouse.host.user_id },
        ].concat(openhouse.cohosts.map((cohost: any) => ({ label: `${cohost.name}`, value: cohost.user_id }))),
      )
    }
  }, [visit])

  const resendRegistrationEmail = () => {
    router.post(
      route('api.openhouses.visit.resendRegistrationEmail', visit.id),
      {},
      {
        onFinish: () => {
          onClosed()
        },
      },
    )
  }

  const onSubmit = (e: any) => {
    e.preventDefault()

    setSubmitting(true)

    if (data.id) {
      transform((data) => ({
        ...data,
        _method: 'put',
      }))

      post(route('api.openhouses.visits.update', { openhouse: openhouse.id, visit: data.id }), {
        onSuccess: (_) => {
          onClosed()
        },
        onFinish: () => setTimeout(() => setSubmitting(false), 150),
      })
    } else {
      post(route('api.openhouses.visits.store', { openhouse: openhouse.id }), {
        onSuccess: (_) => {
          reset()
          onClosed()
        },
        onFinish: () => setTimeout(() => setSubmitting(false), 150),
      })
    }

    clearErrors()
  }

  return (
    <SlideOver
      focusRef={focusRef}
      footerActions={
        visit &&
        !visit.imported_at && (
          <Button theme="solid" type="submit" form="newVisitor" disabled={submitting}>
            {data.id ? 'Save Changes' : 'Register'}
          </Button>
        )
      }
      show={open}
      size={data.id ? 'max-w-xl' : 'max-w-lg'}
      title={data.id ? `${data.first_name} ${data.last_name}` : 'New'}
      subTitle="Visitor"
      onClosed={onClosed}
      aboveMessages
    >
      <form id="newVisitor" onSubmit={onSubmit}>
        {visit && (
          <div className="pb-4">
            {visit.id ? (
              <Fragment>
                <div className="pb-2">
                  <div className="flex justify-between">
                    <div className="flex-1">
                      <label htmlFor="type" className="block font-medium uppercase text-gray-700">
                        Type
                      </label>

                      <span
                        className={`inline-flex items-center rounded px-2 py-1 font-medium uppercase leading-none ${
                          data.type === 'Broker' ? 'bg-gray-200 bg-opacity-75 text-gray-700' : 'bg-orange-100 text-orange-600'
                        }`}
                      >
                        {visit.type}
                      </span>
                    </div>

                    {openhouse.checkins_enabled && (
                      <div>
                        <Dropdown
                          id="actions-options"
                          label={<i className="fas fa-ellipsis-h text-xl"></i>}
                          tooltip="Actions"
                          orientation="right"
                          icon
                          size="w-72"
                        >
                          <Fragment>
                            <Fragment>
                              <DropdownItem
                                onClick={() => {
                                  onUpdate(
                                    visit.id,
                                    visit.checked_in_time && !visit.checked_out_time
                                      ? { checked_out_time: true }
                                      : { checked_in_time: true },
                                  )
                                }}
                              >
                                <div
                                  className={classNames('flex items-center', {
                                    'text-orange-500': !visit.checked_in_time || (visit.checked_in_time && visit.checked_out_time),
                                  })}
                                >
                                  <i
                                    className={classNames(
                                      'fas w-9 pr-3 text-center text-lg leading-none',
                                      visit.checked_in_time && !visit.checked_out_time ? 'fa-house-leave' : 'fa-house-return',
                                    )}
                                  ></i>
                                  Mark as {visit.checked_in_time && !visit.checked_out_time ? 'OUT' : 'IN'}
                                </div>
                              </DropdownItem>

                              {!visit.checked_in_time && !visit.checked_out_time && (
                                <DropdownItem
                                  onClick={() => {
                                    onUpdate(visit.id, { notified: !data.notified }, false)
                                  }}
                                >
                                  <div className="flex items-center">
                                    <i
                                      className={classNames('fas w-9 pr-3 text-center text-lg leading-none', {
                                        'fa-comment-alt-check text-green-700': !data.notified,
                                        'fa-comment-alt-times text-red-700': data.notified,
                                      })}
                                    ></i>

                                    <span>Mark as {data.notified && <span className="font-medium">NOT</span>} Notified</span>
                                  </div>
                                </DropdownItem>
                              )}

                              {(visit.checked_in_time || visit.checked_out_time) && (
                                <DropdownItem
                                  onClick={() => {
                                    onUpdate(visit.id, { waiting: true })
                                  }}
                                >
                                  <div className="flex items-center">
                                    <i className="fas fa-clock w-9 pr-3 text-center text-lg leading-none"></i>
                                    Mark as Waiting
                                  </div>
                                </DropdownItem>
                              )}
                            </Fragment>
                          </Fragment>
                        </Dropdown>
                      </div>
                    )}
                  </div>
                </div>

                <div className="pb-2">
                  <label htmlFor="type" className="block font-medium uppercase text-gray-700">
                    Status
                  </label>

                  <div className="flex items-center space-x-1.5">
                    {openhouse.checkins_enabled && (
                      <span
                        className={classNames('inline-flex items-center rounded px-2 py-1 font-medium uppercase leading-none', {
                          'bg-orange-500 text-white': visit.checked_in_time && !visit.checked_out_time,
                          'bg-gray-200': !visit.checked_in_time && !visit.checked_out_time,
                          'bg-gray-500 text-gray-100': visit.checked_out_time,
                        })}
                      >
                        {visit.checked_in_time && !visit.checked_out_time && 'In'}
                        {!visit.checked_in_time && !visit.checked_out_time && 'Waiting'}
                        {visit.checked_out_time && 'Out'}
                      </span>
                    )}

                    {openhouse.checkins_enabled && data.notified && !visit.checked_in_time && !visit.checked_out_time && (
                      <span
                        className={`inline-flex items-center rounded bg-yellow-100 px-2 py-1 font-medium uppercase leading-none text-yellow-600`}
                      >
                        Notified
                      </span>
                    )}

                    {(visit.checked_out_time || !openhouse.checkins_enabled) && (
                      <Fragment>
                        {visit.assigned ? (
                          <span className="inline-flex items-center rounded bg-blue-100 px-2 py-1 font-medium uppercase leading-none text-blue-500">
                            Assigned to {visit.assigned.name}
                          </span>
                        ) : (
                          <span
                            className={`inline-flex items-center rounded bg-yellow-100 px-2 py-1 font-medium uppercase leading-none text-yellow-600`}
                          >
                            Unassigned
                          </span>
                        )}

                        {visit.imported_at && (
                          <span
                            className={`inline-flex items-center rounded bg-green-50 px-2 py-1 font-medium uppercase leading-none text-green-700`}
                          >
                            Imported
                          </span>
                        )}
                      </Fragment>
                    )}
                  </div>
                </div>

                <div className="pb-2">
                  <label htmlFor="type" className="block font-medium uppercase text-gray-700">
                    Registered at
                  </label>

                  <span className="inline-flex items-center rounded py-1 font-medium leading-none">
                    <span className="uppercase">{visit.registered_at}</span>
                    {visit.creator && <span className="ml-1 text-gray-700">BY {visit.creator.name}</span>}
                  </span>
                </div>

                {visit.checked_out_time && (
                  <div className="pb-2">
                    <label htmlFor="type" className="block font-medium uppercase text-gray-700">
                      Visited
                    </label>

                    <span className="inline-flex items-center rounded py-1 font-medium uppercase leading-none">
                      {visit.checked_in_time || ''}
                      {visit.checked_in_time && visit.checked_out_time && ' - '}
                      {visit.checked_out_time || ''}
                    </span>
                  </div>
                )}
              </Fragment>
            ) : (
              <div className="space-y-1 pb-2">
                <label htmlFor="email" className={classNames('font-medium uppercase', errors.type ? 'text-red-600' : 'text-gray-700')}>
                  <span>Visitor Type</span>
                </label>

                <div className="flex justify-center space-x-4">
                  <Radio
                    ref={focusRef}
                    id="type_buyer"
                    name="type"
                    label="Buyer / Visitor"
                    value={data.type === 'Buyer'}
                    onChange={() => setData({ ...data, type: 'Buyer' })}
                  />
                  <Radio
                    id="type_broker"
                    name="type"
                    label="Agent / Broker"
                    value={data.type === 'Broker'}
                    onChange={() => setData({ ...data, type: 'Broker' })}
                  />
                </div>

                {errors && <div className="mt-1 text-red-600" dangerouslySetInnerHTML={{ __html: errors.type }}></div>}
              </div>
            )}

            <Select
              label="Assigned To"
              name="assigned_id"
              options={cohosts}
              placeholder="None"
              value={cohosts.find((host: any) => host.value === data.assigned_id)?.value}
              onChange={(selected: any) => setData({ ...data, assigned_id: selected?.value })}
              disabled={openhouse.user_id != user.id && visit.imported_at}
            />

            <TextInput
              name="first_name"
              label="First Name"
              value={data.first_name}
              error={errors.first_name}
              onChange={(value: any) => setData({ ...data, first_name: value })}
              disabled={visit.imported_at}
              required
            />

            <TextInput
              name="last_name"
              label="Last Name"
              value={data.last_name}
              error={errors.last_name}
              onChange={(value: any) => setData({ ...data, last_name: value })}
              disabled={visit.imported_at}
              required
            />

            {data.type === 'Broker' && (
              <React.Fragment>
                <TextInput
                  name="company"
                  label="Company"
                  value={data.company}
                  error={errors.company}
                  onChange={(value: any) => setData({ ...data, company: value })}
                  disabled={visit.imported_at}
                  required
                />

                <TextInput
                  name="buyer_name"
                  label="Buyer Name"
                  value={data.buyer_name}
                  error={errors.buyer_name}
                  onChange={(value: any) => setData({ ...data, buyer_name: value })}
                  disabled={visit.imported_at}
                />
              </React.Fragment>
            )}

            <div className="pb-4">
              <div className="flex items-end justify-between gap-4">
                <div className="w-2/3">
                  <TextInput
                    classes="mb-0 w-full"
                    name="email"
                    label="Email"
                    value={data.email}
                    error={errors.email}
                    onChange={(value: any) => setData({ ...data, email: value })}
                    disabled={visit.imported_at}
                    required
                  />
                </div>

                {visit.id && (
                  <div className="mb-1 ml-2 flex items-center justify-end gap-2">
                    <Tooltip label="Email" placement="left">
                      <div className="group">
                        <a
                          href={`mailto:${data.email}`}
                          className="inline-flex h-9 w-9 items-center justify-center rounded-full border border-blue-100 bg-blue-100 outline-none transition-all duration-300 group-hover:border-blue-500"
                          target="_blank"
                        >
                          <i className="fas fa-envelope text-xl leading-none text-blue-500"></i>
                        </a>
                      </div>
                    </Tooltip>

                    <Tooltip label="Resend Registration Email" placement="left">
                      <div className="group">
                        <button
                          type="button"
                          className="border-transaparent inline-flex items-center justify-center gap-3 rounded-full border bg-blue-500 px-4 py-1 font-medium text-white outline-none transition-all duration-300 hover:border-blue-500 hover:bg-opacity-90 focus:border-transparent focus:ring-2 focus:ring-blue-500 focus:ring-offset-1"
                          onClick={() => resendRegistrationEmail()}
                        >
                          Resend
                        </button>
                      </div>
                    </Tooltip>
                  </div>
                )}
              </div>
            </div>

            <div className="pb-4">
              <div className="flex items-end justify-between gap-4">
                <div className="w-2/3">
                  <TextInput
                    classes="mb-0 w-full"
                    name="phone"
                    label="Mobile Number"
                    value={data.phone}
                    error={errors.phone}
                    onChange={(value: any) => setData({ ...data, phone: value })}
                    disabled={visit.imported_at}
                    required={data.type === 'Broker'}
                  />
                </div>

                {visit.id && (
                  <div className="-mr-2 mb-1 ml-2 flex items-center justify-end">
                    <div className="group cursor-pointer pr-2 text-center">
                      <Tooltip label="Call" placement="left">
                        <a
                          href={`tel:${data.phone}`}
                          title="Call"
                          className="inline-flex h-9 w-9 items-center justify-center rounded-full border border-blue-100 bg-blue-100 outline-none transition-all duration-300 group-hover:border-blue-500"
                          target="_blank"
                        >
                          <i className="fas fa-mobile text-xl leading-none text-blue-500 group-hover:text-blue-700"></i>
                        </a>
                      </Tooltip>
                    </div>

                    <div className="group cursor-pointer pr-2 text-center">
                      <Tooltip label="Send a Text" placement="left">
                        <a
                          href={`sms:${data.phone}`}
                          title="SMS"
                          className="inline-flex h-9 w-9 items-center justify-center rounded-full border border-blue-100 bg-blue-100 outline-none transition-all duration-300 group-hover:border-blue-500"
                          target="_blank"
                        >
                          <i className="fas fa-comment-alt-dots text-xl leading-none text-blue-500 group-hover:text-blue-700"></i>
                        </a>
                      </Tooltip>
                    </div>
                  </div>
                )}
              </div>
            </div>

            {(!visit.imported_at || data.notes) && (
              <div className="pb-4">
                <TextArea
                  name="notes"
                  label="Notes"
                  className="block w-full rounded border border-gray-500 px-2 py-1 leading-normal outline-none focus:border-transparent focus:ring-2 focus:ring-blue-500"
                  rows={4}
                  error={errors.notes}
                  value={data.notes}
                  onChange={(value: any) => setData({ ...data, notes: value })}
                  disabled={visit.imported_at}
                />
              </div>
            )}

            {data.type === 'Buyer' && (
              <React.Fragment>
                <div className="space-y-1 pb-2">
                  <label htmlFor="email" className="font-medium uppercase text-gray-700">
                    <span>Contracted with a real estate agent</span>
                  </label>

                  <div className="flex flex-row space-x-4">
                    <Radio
                      name="contracted"
                      label="Yes"
                      value={data.contracted}
                      onChange={() => setData({ ...data, contracted: true })}
                      disabled={visit.imported_at}
                    />

                    <Radio
                      name="contracted"
                      label="No"
                      value={!data.contracted}
                      onChange={() => setData({ ...data, contracted: false })}
                      disabled={visit.imported_at}
                    />
                  </div>
                </div>

                {Boolean(data.contracted) && (
                  <div className="mb-2 mt-1 border border-gray-400 bg-gray-200 p-3">
                    <TextInput
                      name="broker_first_name"
                      label="Agent First Name"
                      value={data.broker_first_name}
                      error={errors.broker_first_name}
                      onChange={(value: any) => setData({ ...data, broker_first_name: value })}
                      disabled={visit.imported_at}
                    />

                    <TextInput
                      name="broker_last_name"
                      label="Agent Last Name"
                      value={data.broker_last_name}
                      error={errors.broker_last_name}
                      onChange={(value: any) => setData({ ...data, broker_last_name: value })}
                      disabled={visit.imported_at}
                    />

                    <TextInput
                      name="broker_email"
                      label="Agent Email"
                      value={data.broker_email}
                      error={errors.broker_email}
                      onChange={(value: any) => setData({ ...data, broker_email: value })}
                      disabled={visit.imported_at}
                    />
                  </div>
                )}

                <div className="space-y-1 pb-2">
                  <label htmlFor="email" className="font-medium uppercase text-gray-700">
                    <span>Prequalified</span>
                  </label>

                  <div className="flex flex-row space-x-4">
                    <Radio
                      name="prequalified"
                      label="Yes"
                      value={data.prequalified}
                      onChange={() => setData({ ...data, prequalified: true })}
                      disabled={visit.imported_at}
                    />

                    <Radio
                      name="prequalified"
                      label="No"
                      value={!data.prequalified}
                      onChange={() => setData({ ...data, prequalified: false })}
                      disabled={visit.imported_at}
                    />
                  </div>
                </div>
              </React.Fragment>
            )}
          </div>
        )}
      </form>
    </SlideOver>
  )
}
