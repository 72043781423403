import classNames from 'classnames'
import { Disclosure, Popover } from '@headlessui/react'
import { Link } from '@inertiajs/react'

const MainMenuItem = ({ desktop, item, sub }) => {
  const isCurrentRoute = !sub && route().current()?.startsWith(item.group)

  const desktopClasses = classNames(
    'flex items-center h-full font-semibold text-nowrap focus:outline-none',
    isCurrentRoute
      ? [
          sub
            ? 'border-l border-r border-transparent bg-primary-50 bg-opacity-80 border-primary-400 border-opacity-20 text-primary-900'
            : 'bg-primary-50 bg-opacity-80 border-b border-primary-400 border-opacity-20 text-primary-900',
        ]
      : ['xl:text-white', sub ? 'hover:bg-primary-500' : 'hover:bg-primary-800'],
    sub ? 'flex-1 px-4 py-3' : 'px-4',
  )

  const mobileClasses = classNames(
    'group w-full flex items-center px-3 py-2 font-semibold rounded-md focus:outline-none focus:ring-2 focus:ring-primary-500',
    isCurrentRoute ? 'bg-primary-100 text-primary-800' : 'bg-white text-gray-600 hover:bg-gray-50 hover:text-gray-900',
  )

  return (
    <div role="none" className="text-nowrap xl:flex xl:h-full xl:items-center">
      {item.children ? (
        desktop ? (
          sub ? (
            <Popover className="xl:h-full">
              {({ open }) => (
                <>
                  <Popover.Button
                    className={classNames(desktopClasses, { 'xl:bg-primary-900 xl:text-white': open })}
                    style={{ minWidth: '190px' }}
                  >
                    <span className="flex-1 text-left">{item.name}</span>
                    <i
                      className={classNames(
                        'fal fa-angle-right ml-2 h-5 w-5 flex-shrink-0 text-lg leading-none transition-all duration-150 ease-in-out',
                        isCurrentRoute && !open ? 'text-primary-800' : 'text-white',
                      )}
                      aria-hidden="true"
                    ></i>
                  </Popover.Button>

                  <Popover.Panel className="absolute left-full top-0 z-10 xl:h-full xl:bg-primary-700" style={{ minWidth: '170px' }}>
                    <div role="none" className="space-y-1 xl:py-4">
                      {item.children.map((subItem) =>
                        !subItem.restricted ? (
                          <MainMenuItem item={subItem} desktop={desktop} key={subItem.name} sub>
                            {subItem.name}
                          </MainMenuItem>
                        ) : null,
                      )}
                    </div>
                  </Popover.Panel>
                </>
              )}
            </Popover>
          ) : (
            <Popover className="xl:h-full">
              {({ open }) => (
                <>
                  <Popover.Button className={classNames(desktopClasses, { 'xl:bg-primary-900 xl:text-white': open })}>
                    <span className="flex-1 text-left">{item.name}</span>
                    <i
                      className={classNames(
                        'fal fa-angle-down ml-2 h-5 w-5 flex-shrink-0 text-lg leading-none transition-all duration-150 ease-in-out',
                        isCurrentRoute && !open ? 'text-primary-800' : 'text-white',
                      )}
                      aria-hidden="true"
                    ></i>
                  </Popover.Button>

                  <Popover.Panel className="absolute z-10" style={{ minWidth: '190px' }}>
                    <div role="none" className="space-y-1 xl:bg-primary-900 xl:py-4">
                      {item.children.map((subItem) =>
                        !subItem.restricted ? (
                          <MainMenuItem item={subItem} desktop={desktop} key={subItem.name} sub>
                            {subItem.name}
                          </MainMenuItem>
                        ) : null,
                      )}
                    </div>
                  </Popover.Panel>
                </>
              )}
            </Popover>
          )
        ) : (
          <Disclosure as="div" className="space-y-1 xl:h-full">
            {({ open }) => (
              <>
                <Disclosure.Button className={mobileClasses}>
                  <span className="flex-1 text-left">{item.name}</span>
                  <i
                    className={classNames(
                      open ? 'rotate-90' : '',
                      'far fa-angle-right ml-2 h-5 w-5 flex-shrink-0 text-2xl leading-none text-gray-700 transition-all duration-150 ease-in-out',
                    )}
                    aria-hidden="true"
                  ></i>
                </Disclosure.Button>
                <Disclosure.Panel className="space-y-1 pl-3">
                  {item.children.map((subItem) =>
                    !subItem.restricted ? (
                      <MainMenuItem item={subItem} desktop={desktop} key={subItem.name}>
                        {subItem.name}
                      </MainMenuItem>
                    ) : null,
                  )}
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        )
      ) : item.href ? (
        <Link href={item.href && route(item.href)} className={desktop ? desktopClasses : mobileClasses} role="menuitem">
          {item.name}
        </Link>
      ) : (
        <a className={desktop ? desktopClasses : mobileClasses} role="menuitem">
          {item.name}
        </a>
      )}
    </div>
  )
}

export default MainMenuItem
