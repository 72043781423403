import { useState } from 'react'
import AccordionCard from '@/Shared/AccordionCard'
import Button from '@/Shared/Button'
import Edit from './Edit'
import Label from '@/Shared/Label'
import PropertyListItem from '@/Shared/PropertyListItem'
import { usePage } from '@inertiajs/react'

export default () => {
  const { associate } = usePage().props.contact
  const { user } = usePage().props.auth
  const [open, setOpen] = useState(false)

  return (
    <AccordionCard
      title={`MLS Systems (${associate.mls_associations?.length || 0})`}
      actions={
        <Button theme="icon" onClick={() => setOpen(true)}>
          <span className="sr-only">Edit</span>
          <i className="far fa-pen text-gray-500 group-hover:font-bold group-hover:text-blue-500"></i>
        </Button>
      }
      indented={associate.mls_associations?.length == 0}
      collapsed
    >
      {associate.mls_associations?.length > 0 ? (
        associate.mls_associations.map((association, index) => (
          <PropertyListItem
            value={
              <div className="flex items-center justify-between gap-3">
                <div className="space-y-1">
                  <div>
                    {association.mls} - {association.label}
                  </div>

                  {!association.active && <div className="italic text-gray-500">Inactive reason: Unpaid</div>}
                </div>
                <div className="space-x-2">
                  <Label type={association.active ? 'success' : 'error'}>{association.active ? 'Active' : 'Inactive'}</Label>
                </div>
              </div>
            }
            key={index}
          />
        ))
      ) : (
        <div className="text-gray-700">No MLS systems have been added.</div>
      )}

      <Edit open={open} onClosed={() => setOpen(false)} />
    </AccordionCard>
  )
}
