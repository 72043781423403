import { Fragment, useState } from 'react'
import { usePage } from '@inertiajs/react'
import ActiveFilters from '@/Shared/SlideOvers/ActiveFilters'
import Button from '@/Shared/Button'
import DataTableHeader from './DataTableHeader'
import { router } from '@inertiajs/react'
import OpenHouseEdit from './Edit'
import MainLayout from '@/Shared/Layouts/Main'
import Pagination from '@/Shared/Pagination'
import SearchBar from '@/Shared/SearchBar'

const OpenHouseIndex = () => {
  const { filters } = usePage().props
  const { data, paginator } = usePage().props.openhouses
  const [openDrawer, setOpenDrawer] = useState(null)

  const rowClicked = (id) => {
    router.visit(route('openhouses.show', id))
  }

  return (
    <div>
      <div className="flex flex-wrap items-stretch justify-between bg-white px-6 py-4">
        <SearchBar
          actions={
            <Button theme="solid" onClick={() => setOpenDrawer('edit')}>
              <i className="fas fa-plus mr-2 text-lg"></i>
              <span className="pr-1">New</span>
            </Button>
          }
          label="Open Houses"
          placeholder="Search by MLS #, address or host"
          hideFilters
        />
      </div>

      <div className="space-y-1.5">
        <div className="mx-4 my-2 flex flex-1 md:mx-6">
          <ActiveFilters />
        </div>

        <div className="mx-4 mb-6 bg-white shadow sm:mx-6 sm:rounded">
          <div className="flex flex-col">
            <div className="overflow-x-auto">
              <div className="inline-block min-w-full align-middle">
                <div className="overflow-hidden border-b border-gray-200 shadow">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead>
                      <DataTableHeader sortBy={filters.sortBy} direction={filters.direction} />
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {data.map((openhouse) => (
                        <tr className="group hover:bg-gray-100" key={openhouse.id}>
                          <td
                            className="mx-auto hidden cursor-pointer px-4 py-3 text-gray-700 sm:table-cell sm:px-6"
                            onClick={() => rowClicked(openhouse.id)}
                          >
                            <img className="mx-auto max-h-[60px]" src={openhouse.image} />
                          </td>

                          <td className="cursor-pointer px-4 py-3 text-gray-700 sm:pl-0 sm:pr-6" onClick={() => rowClicked(openhouse.id)}>
                            <span className="font-bold text-blue-500">{openhouse.address}</span>

                            <div className="md:hidden">
                              {openhouse.when ? (
                                <div className="flex flex-col font-bold sm:flex-row sm:gap-1.5">
                                  <span>{openhouse.when}</span>
                                  <span className="hidden sm:inline">•</span>
                                  <span>
                                    {openhouse.start} ~ {openhouse.end}
                                  </span>
                                </div>
                              ) : (
                                <span className="text-gray-600">
                                  <i className="fas fa-ban"></i> Not yet scheduled
                                </span>
                              )}
                            </div>

                            <div className="flex flex-col xl:hidden">
                              {openhouse.host && <span className="text-gray-900">Host: {openhouse.host}</span>}
                              {openhouse.cohosts && <span className="text-gray-700">Co-Hosts: {openhouse.cohosts}</span>}
                            </div>

                            <div className="flex items-center space-x-1.5 lg:hidden">
                              {openhouse.when ? (
                                <Fragment>
                                  {openhouse.elapsed ? (
                                    <span className="inline-flex items-center whitespace-nowrap rounded bg-gray-200 px-2.5 py-1.5 text-sm leading-none text-gray-800">
                                      Past
                                    </span>
                                  ) : (
                                    <span className="inline-flex items-center whitespace-nowrap rounded bg-green-50 px-2.5 py-1.5 text-sm font-medium uppercase leading-none text-green-700">
                                      Scheduled
                                    </span>
                                  )}
                                </Fragment>
                              ) : (
                                <span className="inline-block rounded bg-red-100 px-2.5 py-1.5 text-sm font-medium uppercase leading-none text-red-600">
                                  Not Scheduled
                                </span>
                              )}

                              <span className="inline-flex items-center whitespace-nowrap rounded bg-gray-200 px-2.5 py-1.5 text-sm leading-none text-gray-800 sm:hidden">
                                {openhouse.visitors} Visitors
                              </span>
                            </div>
                          </td>

                          <td
                            className="hidden cursor-pointer px-4 py-3 text-gray-700 xl:table-cell xl:px-6"
                            onClick={() => rowClicked(openhouse.id)}
                          >
                            <div className="flex flex-col">
                              {openhouse.host && <span className="font-medium text-gray-900">{openhouse.host}</span>}
                              {openhouse.cohosts && <span className="text-gray-700">Co-Hosts: {openhouse.cohosts}</span>}
                            </div>
                          </td>

                          <td
                            className="hidden cursor-pointer whitespace-nowrap px-4 py-3 text-gray-700 sm:px-6 md:table-cell"
                            onClick={() => rowClicked(openhouse.id)}
                            width="150px"
                          >
                            {openhouse.when ? (
                              <div className="flex flex-col">
                                <span className="font-bold">{openhouse.when}</span>
                                <span>
                                  {openhouse.start} ~ {openhouse.end}
                                </span>
                              </div>
                            ) : (
                              <span className="text-gray-600">
                                <i className="fas fa-ban"></i> Not yet scheduled
                              </span>
                            )}
                          </td>

                          <td
                            className="hidden cursor-pointer whitespace-nowrap px-4 py-3 text-center text-gray-700 sm:table-cell sm:px-6"
                            onClick={() => rowClicked(openhouse.id)}
                          >
                            {openhouse.visitors > 0 ? (
                              <span className="font-bold text-blue-500">{openhouse.visitors}</span>
                            ) : (
                              <span className="text-gray-300">
                                <i className="fas fa-ban"></i>
                              </span>
                            )}
                          </td>

                          <td
                            className="hidden cursor-pointer whitespace-nowrap px-4 py-3 text-gray-700 sm:px-6 lg:table-cell"
                            onClick={() => rowClicked(openhouse.id)}
                            width="80px"
                          >
                            {openhouse.when ? (
                              <Fragment>
                                {openhouse.elapsed ? (
                                  <span className="inline-flex items-center whitespace-nowrap rounded bg-gray-200 px-2.5 py-1.5 text-sm leading-none text-gray-800">
                                    Past
                                  </span>
                                ) : (
                                  <span className="inline-flex items-center whitespace-nowrap rounded bg-green-50 px-2.5 py-1.5 text-sm font-medium uppercase leading-none text-green-700">
                                    Scheduled
                                  </span>
                                )}
                              </Fragment>
                            ) : (
                              <span className="inline-block rounded bg-red-100 px-2.5 py-1.5 text-sm font-medium uppercase leading-none text-red-600">
                                Not Scheduled
                              </span>
                            )}
                          </td>

                          <td
                            className="w-16 cursor-pointer whitespace-nowrap py-3 pr-6 text-right font-medium"
                            onClick={() => rowClicked(openhouse.id)}
                          >
                            <div className="flex items-center justify-end">
                              <i className="far fa-angle-right text-2xl leading-none text-gray-700"></i>
                            </div>
                          </td>
                        </tr>
                      ))}

                      {data.length == 0 && (
                        <tr>
                          <td colSpan="8" className="px-4 py-12 sm:px-6">
                            <div className="flex flex-col text-center">
                              <span className="text-2xl font-semibold text-red-600">No Open Houses match your current filters.</span>
                              <span className="text-lg font-medium text-gray-700">
                                Expecting to see results? Check your filters to make sure you're not excluding results.
                              </span>
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            {data.length > 0 && <Pagination paginator={paginator} />}
          </div>
        </div>
      </div>

      <OpenHouseEdit open={openDrawer === 'edit'} onClosed={() => setOpenDrawer(null)} />
    </div>
  )
}

// Persistent layout
// Docs: https://inertiajs.com/pages#persistent-layouts
OpenHouseIndex.layout = (page) => <MainLayout title="Open Houses" children={page} />

export default OpenHouseIndex
