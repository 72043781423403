import { useEffect, useState } from 'react'
import AccordionCard from '@/Shared/AccordionCard'
import AccordionItem from '@/Shared/AccordionItem'
import Button from '@/Shared/Button'
import ContactSearch from './Search/Index'
import ContactEdit from './Edit'
import PreviewExisting from './PreviewExisting'
import PreviewTemporary from './PreviewTemporary'
import { usePage } from '@inertiajs/react'

export default ({ type, contacts }) => {
  const { contact } = usePage().props
  const [creating, setCreating] = useState(null)
  const [previewing, setPreviewing] = useState(null)
  const [searching, setSearching] = useState(false)

  useEffect(() => {
    if (contacts?.length > 0 && previewing) {
      setPreviewing(contacts.find((contact) => contact.id == previewing.id))
    }
  }, [contacts])

  const createContact = (search) => {
    setSearching(false)

    setTimeout(() => {
      setCreating({
        contact_id: contact.id,
        name: search,
      })
    }, 300)
  }

  return (
    <AccordionCard
      title={`${type} Contacts (${contacts?.length || 0})`}
      actions={
        <Button theme="link" onClick={() => setSearching(true)}>
          <i className="far fa-plus pr-1 text-sm"></i>
          <span>Add</span>
        </Button>
      }
      collapsed
    >
      {contacts?.length > 0 ? (
        <div className="divide-y divide-dashed divide-gray-200 overflow-hidden rounded-md border border-gray-200">
          {contacts.map((contact) => (
            <AccordionItem key={contact.id} onClick={() => setPreviewing(contact)}>
              <div className="flex items-center justify-between space-x-2">
                <div className="flex flex-col">
                  <span className="font-semibold text-blue-500">{contact.name}</span>
                  <span>{contact.label}</span>
                </div>

                {contact.related_id && (
                  <div className="inline-flex flex-shrink-0 items-center whitespace-nowrap rounded-full bg-gray-200 bg-opacity-75 px-2.5 py-1.5 text-sm font-medium uppercase leading-none text-gray-800">
                    <span>Contact</span>
                  </div>
                )}
              </div>
            </AccordionItem>
          ))}
        </div>
      ) : (
        <div className="text-gray-700">No {type.toLowerCase()} contacts have been added.</div>
      )}

      <ContactSearch
        contacts={contacts}
        open={searching}
        type={type}
        onCreateContact={(search) => createContact(search)}
        onClose={() => setSearching(false)}
      />
      <ContactEdit record={creating} open={creating != null} type={type} onClosed={() => setCreating(null)} />

      {previewing?.related_id ? (
        <PreviewExisting relationship={previewing} onClosed={() => setPreviewing(null)} />
      ) : (
        <PreviewTemporary relationship={previewing} onClosed={() => setPreviewing(null)} />
      )}
    </AccordionCard>
  )
}
