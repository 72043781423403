import { Fragment, useState } from 'react'
import Button from '@/Shared/Button'
import DeleteConfirmationDialog from '@/Shared/Dialog/DeleteConfirmationDialog'
import { router } from '@inertiajs/react'
import classNames from 'classnames'

const UploadedFile = ({ file, step, transaction, onDelete }) => {
  const onFileChanged = (event, type_id) => {
    if (event.target.files.length > 0) {
      let file = transaction.allFiles.find((file) => file.file_type_id == type_id)

      if (file) {
        router.post(
          route('transactions.files.update', file.id),
          { newFile: Array.from(event.target.files)[0], _method: 'put' },
          {
            only: ['flash', 'history', 'transaction'],
          },
        )
      }
    }
  }

  return (
    <div
      className={classNames('space-y-4', {
        'overflow-hidden rounded-lg border-2 border-red-600 border-opacity-75 pb-4': file.declined || file.review_not_applicable,
      })}
    >
      {(file.declined || file.review_not_applicable) && (
        <div className="flex-1 space-y-4">
          <div className="flex items-center gap-2 bg-red-100 px-4 py-1.5 font-semibold">
            <i className="fa-solid fa-circle-exclamation text-2xl text-red-500"></i>
            <div>
              This file was {file.declined ? 'declined' : 'marked not applicable'} during the Compliance Review and must be{' '}
              {file.declined ? 'replaced' : 'deleted'}.
            </div>
          </div>

          {file.comments && (
            <div className="mx-4">
              <blockquote className="!bg-gray-200/60 !pb-2 !pt-4">
                <div>
                  <b>Reviewer's Comments:</b>
                </div>
                <div dangerouslySetInnerHTML={{ __html: file.comments }}></div>
              </blockquote>
            </div>
          )}
        </div>
      )}

      {file.not_applicable ? (
        <div className="flex justify-center">
          <span className="flex items-center gap-2 rounded-md border border-gray-400 bg-gray-100 px-4 py-1.5 font-medium text-gray-500">
            {file.filename} Not Applicable
            <Button theme="border" onClick={() => onDelete(file.id, true)}>
              <div className="flex items-center gap-2">
                <i className="fas fa-undo leading-none"></i> Undo
              </div>
            </Button>
          </span>
        </div>
      ) : (
        <div className="flex flex-1 flex-wrap items-center justify-center gap-2">
          <div className="flex flex-col items-center justify-center gap-4 space-y-3 rounded-md border border-gray-300 bg-gray-100 py-1 pl-3 pr-2 sm:flex-row sm:space-y-0">
            <div className="flex items-center gap-2 py-1">
              <i className="fal fa-file-pdf text-3xl text-gray-500"></i>
              <div className="flex items-center gap-2">
                <div className="space-x-1.5 text-lg font-semibold leading-tight text-gray-900">
                  <span>{file.filename}</span>
                </div>
              </div>
            </div>

            <div className="flex items-center gap-2">
              <Button theme="icon" onClick={() => window.open(route('transactions.files.show', file.id), '_blank')}>
                <i className="fas fa-external-link text-xl leading-none text-gray-700"></i>
              </Button>

              {file.replaceable && !file.review_not_applicable && (
                <label
                  htmlFor={`step-${step.id}-file-${step.actionable_id}-url`}
                  className={classNames(
                    'group relative inline-flex cursor-pointer items-center rounded-md px-4 py-2.5 text-base font-medium leading-none shadow-sm outline-none transition-all duration-150 ease-in-out',
                    file.declined
                      ? 'border-2 border-blue-500 bg-blue-100 text-blue-500 hover:bg-blue-600 hover:text-white focus:border-transparent focus:ring-2 focus:ring-blue-500'
                      : 'border border-gray-300 bg-white text-gray-700 hover:bg-gray-200 hover:text-gray-800 focus:border-transparent focus:ring-2 focus:ring-primary-500',
                  )}
                >
                  <input
                    type="file"
                    id={`step-${step.id}-file-${step.actionable_id}-url`}
                    className="sr-only"
                    accept="application/pdf"
                    onChange={(event) => onFileChanged(event, step.actionable_id)}
                  />

                  <div className="flex items-center gap-2">
                    <i className="far fa-file-arrow-up text-xl leading-none"></i> <span>{file.not_applicable ? 'Upload' : 'Replace'}</span>
                  </div>
                </label>
              )}

              {(step.actionable.optional || file.addendum) && (
                <>
                  {file.review_not_applicable ? (
                    <button
                      className="group relative inline-flex cursor-pointer items-center rounded-md border-2 border-red-500 bg-red-100 px-4 py-2.5 text-base font-medium leading-none text-red-500 shadow-sm outline-none transition-all duration-150 ease-in-out hover:bg-red-600 hover:text-white focus:border-transparent focus:ring-2 focus:ring-red-500"
                      type="button"
                      onClick={() => onDelete(file.id)}
                    >
                      <div className="flex items-center gap-2">
                        <i className="fas fa-trash text-lg leading-none"></i>
                      </div>
                    </button>
                  ) : (
                    <Button theme="icon-sm" onClick={() => onDelete(file.id)}>
                      <i className="fas fa-trash text-lg leading-none text-red-600"></i>
                    </Button>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

const UploadFile = ({ buttonText, disableNotApplicable, optional, showAcceptedFiles, onFileUploaded, onFileNotApplicable }) => {
  return (
    <div className="flex justify-center gap-6">
      <div className="flex flex-col items-center gap-2">
        <label className="group relative inline-flex min-h-[2.5rem] items-center gap-2.5 rounded-md bg-primary-500 px-6 py-2.5 text-center font-medium leading-snug text-white shadow-sm outline-none transition-all duration-150 ease-in-out focus-within:ring-2 focus-within:ring-primary-500 focus-within:ring-offset-1 hover:bg-primary-900">
          {buttonText}
          <input type="file" className="sr-only" accept="application/pdf" onChange={onFileUploaded}></input>
        </label>

        {showAcceptedFiles && <div className="italic text-red-600">Only PDF file types are accepted.</div>}
      </div>

      {optional && (disableNotApplicable == undefined || !disableNotApplicable) && (
        <Fragment>
          <div className="flex h-10 items-center">
            <span className="font-bold">or</span>
          </div>

          <Button theme="border" onClick={onFileNotApplicable}>
            Not Applicable
          </Button>
        </Fragment>
      )}
    </div>
  )
}

export default ({ associates, step, transaction }) => {
  let states = transaction.type === 'Buyer' ? transaction.states : [{ state: transaction.property_state }]
  let hasMultipleFiles = (step.actionable.required_per_associate && associates.length > 1) || step.actionable.required_per_state
  let fileUploaded = !hasMultipleFiles && transaction.allFiles.find((file) => file.type === step.actionable.name)
  const [deleting, setDeleting] = useState(null)

  const onFileUploaded = (event, type_id, options) => {
    router.post(
      route('transactions.files.store', transaction.id),
      {
        type_id: type_id,
        file: Array.from(event.target.files)[0],
        transaction_contact_id: options?.transaction_contact_id || associates.find((associate) => associate.is_transaction_owner)?.id,
        state: options?.state,
        addendum: options?.addendum,
      },
      {
        only: ['fileTypes', 'flash', 'history', 'transaction'],
      },
    )
  }

  const onFileNotApplicable = (event, type_id, options) => {
    router.post(
      route('transactions.files.store', transaction.id),
      {
        type_id: type_id,
        transaction_contact_id: options?.transaction_contact_id || associates.find((associate) => associate.is_transaction_owner)?.id,
        state: options?.state,
        addendum: options?.addendum,
        not_applicable: true,
      },
      {
        only: ['fileTypes', 'flash', 'history', 'transaction'],
      },
    )
  }

  const handleDeleting = (id, confirmDelete) => {
    if (confirmDelete == undefined || !Boolean(confirmDelete)) {
      setDeleting(id)
    } else {
      onFileDelete(id)
    }
  }

  const onFileDelete = (id) => {
    router.delete(route('transactions.files.destroy', id || deleting), {
      only: ['flash', 'history', 'transaction', 'fileTypes'],
      onFinish: () => {
        setDeleting(null)
      },
    })
  }

  return (
    <Fragment>
      {hasMultipleFiles ? (
        <div className="space-y-8">
          <div className="space-y-2.5">
            <div className="sm:flex sm:flex-wrap sm:justify-center sm:gap-6">
              {step.actionable.required_per_state
                ? step.actionable.required_per_associate
                  ? associates.map((associate) =>
                      states
                        .filter(
                          (state) =>
                            associate.contact.associate.licenses.find((license) => !license.expired && license.state == state.state) &&
                            !transaction.allFiles
                              .filter((file) => file.type === step.actionable.name)
                              .find((file) => file.transaction_contact_id == associate.id && file.state == state.state),
                        )
                        .map((state) => (
                          <UploadFile
                            buttonText={
                              <div>
                                <div>
                                  Upload{' '}
                                  <b>
                                    {step.actionable.name} ({state.state})
                                  </b>{' '}
                                  File
                                </div>
                                <div className="mt-1 text-center font-normal">for {associate.contact.full_name || associate.full_name}</div>
                              </div>
                            }
                            optional={step.actionable.optional}
                            onFileUploaded={(event) =>
                              onFileUploaded(event, step.actionable_id, { transaction_contact_id: associate.id, state: state.state })
                            }
                            onFileNotApplicable={(event) =>
                              onFileNotApplicable(event, step.actionable_id, { transaction_contact_id: associate.id, state: state.state })
                            }
                            key={`${state.state}-${associate.id}`}
                          />
                        )),
                    )
                  : states
                      .filter(
                        (state) => !transaction.allFiles?.find((file) => file.type === step.actionable.name && file.state == state.state),
                      )
                      .map((state) => (
                        <UploadFile
                          buttonText={
                            <div>
                              <div>
                                Upload{' '}
                                <b>
                                  {step.actionable.name} ({state.state})
                                </b>{' '}
                                File
                              </div>
                            </div>
                          }
                          optional={step.actionable.optional}
                          onFileUploaded={(event) => onFileUploaded(event, step.actionable_id, { state: state.state })}
                          onFileNotApplicable={(event) => onFileNotApplicable(event, step.actionable_id, { state: state.state })}
                          key={state.state}
                        />
                      ))
                : associates
                    .filter(
                      (associate) =>
                        !transaction.allFiles.find(
                          (file) => file.type === step.actionable.name && file.transaction_contact_id == associate.id,
                        ),
                    )
                    .map((associate) => (
                      <UploadFile
                        buttonText={
                          <div>
                            <div>
                              Upload <b>{step.actionable.name}</b> File
                            </div>
                            <div className="mt-1 text-center font-normal">for {associate.contact.full_name || associate.full_name}</div>
                          </div>
                        }
                        optional={step.actionable.optional}
                        onFileUploaded={(event) => onFileUploaded(event, step.actionable_id, { transaction_contact_id: associate.id })}
                        onFileNotApplicable={(event) =>
                          onFileNotApplicable(event, step.actionable_id, { transaction_contact_id: associate.id })
                        }
                        key={associate.id}
                      />
                    ))}
            </div>
            <div className="w-full text-center italic text-red-600">Only PDF file types are accepted.</div>
          </div>

          {transaction.allFiles.filter((file) => file.type === step.actionable.name).length > 0 && (
            <div className="flex flex-1 flex-wrap items-center justify-center gap-2">
              {step.actionable.required_per_state
                ? step.actionable.required_per_associate
                  ? associates.map((associate) =>
                      states
                        .filter((state) =>
                          associate.contact.associate.licenses.find((license) => !license.expired && license.state == state.state),
                        )
                        .map((state) =>
                          transaction.allFiles
                            .filter(
                              (file) =>
                                file.type === step.actionable.name &&
                                file.transaction_contact_id == associate.id &&
                                file.state == state.state,
                            )
                            .map((file) => (
                              <UploadedFile
                                file={file}
                                step={step}
                                transaction={transaction}
                                key={`${state.state}-${associate.id}`}
                                onDelete={handleDeleting}
                              />
                            )),
                        ),
                    )
                  : states.map((state) =>
                      transaction.allFiles
                        .filter((file) => file.type === step.actionable.name && file.state == state.state)
                        .map((file) => (
                          <UploadedFile file={file} step={step} transaction={transaction} key={state.state} onDelete={handleDeleting} />
                        )),
                    )
                : associates.map((associate) =>
                    transaction.allFiles
                      .filter((file) => file.type === step.actionable.name && file.transaction_contact_id == associate.id)
                      .map((file) => (
                        <UploadedFile file={file} step={step} transaction={transaction} key={associate.id} onDelete={handleDeleting} />
                      )),
                  )}
            </div>
          )}
        </div>
      ) : step.actionable.allow_addendums && (!fileUploaded || !fileUploaded.not_applicable) ? (
        <div className="space-y-8">
          <div className="space-y-2.5">
            <div className="sm:flex sm:flex-wrap sm:justify-center sm:gap-6">
              {!fileUploaded ? (
                <UploadFile
                  buttonText={<span>Upload {step.actionable.name} File</span>}
                  optional={step.actionable.optional}
                  onFileUploaded={(event) => onFileUploaded(event, step.actionable_id)}
                  onFileNotApplicable={(event) => onFileNotApplicable(event, step.actionable_id)}
                />
              ) : (
                <UploadFile
                  buttonText={
                    <div>
                      <div>Upload an Addendum to the {step.actionable.name}</div>
                    </div>
                  }
                  optional={step.actionable.optional}
                  onFileUploaded={(event) => onFileUploaded(event, step.actionable_id, { addendum: true })}
                  onFileNotApplicable={(event) => onFileNotApplicable(event, step.actionable_id, { addendum: true })}
                  disableNotApplicable
                />
              )}
            </div>
            <div className="w-full text-center italic text-red-600">Only PDF file types are accepted.</div>
          </div>

          {fileUploaded && (
            <div className="flex flex-1 flex-wrap items-center justify-center gap-2">
              <UploadedFile file={fileUploaded} step={step} transaction={transaction} onDelete={handleDeleting} />

              {transaction.allFiles
                .filter((file) => file.type === step.actionable.name && file.addendum)
                .map((file) => (
                  <UploadedFile file={file} step={step} transaction={transaction} key={file.id} onDelete={handleDeleting} />
                ))}
            </div>
          )}
        </div>
      ) : fileUploaded ? (
        <UploadedFile file={fileUploaded} step={step} transaction={transaction} onDelete={handleDeleting} />
      ) : (
        <UploadFile
          buttonText={<span>Upload {step.actionable.name} File</span>}
          optional={step.actionable.optional}
          onFileUploaded={(event) => onFileUploaded(event, step.actionable_id)}
          onFileNotApplicable={(event) => onFileNotApplicable(event, step.actionable_id)}
          showAcceptedFiles
        />
      )}

      <DeleteConfirmationDialog open={deleting != null} onCanceled={() => setDeleting(null)} onApproved={() => onFileDelete()}>
        <p>Are you sure you want to delete this file?</p>
      </DeleteConfirmationDialog>
    </Fragment>
  )
}
