import { Fragment, useEffect, useState } from 'react'
import Button from '@/Shared/Button'
import Dropdown from '@/Shared/Dropdown'
import DropdownItem from '@/Shared/DropdownItem'
import { router } from '@inertiajs/react'
import { Link } from '@inertiajs/react'
import NoteDialog from '@/Shared/NoteDialog'
import { Tooltip } from '@/Shared/Tooltip'
import { usePage } from '@inertiajs/react'

export default ({ onEdit }) => {
  const { is_app, openhouse } = usePage().props
  const [noteDialogOpen, setNoteDialogOpen] = useState(false)
  const [note, setNote] = useState(null)

  useEffect(() => {
    if (note != null) {
      setNoteDialogOpen(true)
    }
  }, [note])

  const onDestroy = () => {
    // prettier-ignore
    router.delete(route('openhouses.destroy', openhouse.id), { preserveState: false })
  }

  const onDuplicate = () => {
    // prettier-ignore
    router.post(route('openhouses.duplicate', openhouse.id), {}, { preserveState: false })
  }

  const onImportVisitors = () => {
    // prettier-ignore
    router.post(route('api.openhouses.import', openhouse.id), {}, { preserveState: false })
  }

  const onRestore = () => {
    // prettier-ignore
    router.post(route('openhouses.restore', openhouse.id), { _method: 'patch' }, { preserveState: false })
  }

  return (
    <div className="relative bg-white py-3 pb-6">
      <div className="mb-3 space-y-3 px-8">
        <div className="relative z-10 flex items-center justify-between">
          <div className="flex flex-1 items-center justify-between">
            <div>
              <Link
                href={route('openhouses.index')}
                className="-ml-5 inline-flex h-10 items-center rounded-md px-3 leading-none text-blue-500 outline-none transition-all duration-150 ease-in-out hover:bg-blue-100 hover:text-gray-800 focus:ring-2 focus:ring-blue-500"
              >
                <i className="far fa-angle-left pr-3 text-xl"></i>
                <span className="font-medium">Open Houses</span>
              </Link>
            </div>

            <div className="-mr-5 flex items-center justify-end gap-0.5">
              {openhouse.editable && (
                <Tooltip label="Edit" placement="bottom">
                  <Button theme="icon" onClick={onEdit} disabled={openhouse.deleted_at}>
                    <span className="sr-only">Edit</span>
                    <i className="far fa-pen text-gray-500 group-hover:font-bold group-hover:text-blue-500"></i>
                  </Button>
                </Tooltip>
              )}

              <Dropdown id="actions-options" label="Actions" orientation="right" size="w-80">
                {openhouse.launchable && (
                  <Fragment>
                    <DropdownItem
                      href={route('openhouses.kiosk', openhouse.kiosk_token)}
                      colorClasses="text-orange-500 font-semibold"
                      target="_blank"
                    >
                      <i className="far fa-house-laptop mr-4 text-xl"></i>
                      Launch Self-Registraton Mode
                    </DropdownItem>

                    <hr />
                  </Fragment>
                )}

                <div className="bg-gray-200 py-1 pl-4 text-sm font-semibold uppercase text-gray-700">Visitor Actions</div>

                <DropdownItem onClick={() => onImportVisitors()} disabled={!openhouse.assigned_count || openhouse.assigned_count == 0}>
                  <div className="flex text-left">
                    <i className="far fa-upload mr-4 mt-1.5 w-6"></i>
                    Import ({openhouse.assigned_count || 0}) Assigned Visitors into Contacts
                  </div>
                </DropdownItem>

                {!is_app && (
                  <DropdownItem href={`/account/openhouses/${openhouse.id}/export`} disabled={openhouse.visits.length == 0}>
                    <i className="far fa-file-csv mr-4"></i>
                    Export ({openhouse.visits.length}) Visitors to CSV
                  </DropdownItem>
                )}

                <div className="bg-gray-200 py-1 pl-4 text-sm font-semibold uppercase text-gray-700">General</div>

                <DropdownItem onClick={() => onDuplicate()}>
                  <i className="far fa-copy mr-4"></i>
                  Duplicate
                </DropdownItem>

                {openhouse.editable && (
                  <Fragment>
                    {Boolean(openhouse.deleted_at) ? (
                      <DropdownItem colorClasses="text-green-600 hover:text-green-700" onClick={() => onRestore()}>
                        <i className="fas fa-undo mr-4"></i>
                        Restore
                      </DropdownItem>
                    ) : (
                      <DropdownItem colorClasses="text-red-500 hover:text-red-600" onClick={() => onDestroy()}>
                        <i className="fas fa-trash mr-4"></i>
                        Delete
                      </DropdownItem>
                    )}
                  </Fragment>
                )}
              </Dropdown>
            </div>
          </div>
        </div>

        <div className="flex items-center justify-center">
          <img className="max-w-[225px] rounded-lg shadow-lg" src={openhouse.image} />
        </div>

        <div className="relative">
          <div className="mt-4 text-center text-xl font-medium">{openhouse.address}</div>

          <div className="mt-4 flex items-center justify-center">
            <i className="far fa-calendar-day mr-5 text-3xl text-gray-500"></i>
            <div>
              <div className="text-xl font-medium">{openhouse.when}</div>
              <div className="text-lg font-medium">{openhouse.time_range}</div>
            </div>
          </div>

          {Boolean(openhouse.deleted_at) && (
            <div className="mt-2 flex justify-center">
              <div className="inline-block whitespace-nowrap rounded bg-red-100 px-2.5 py-1.5 text-sm font-medium uppercase leading-none text-red-600">
                <span>Deleted</span>
              </div>
            </div>
          )}
        </div>
      </div>

      {note && (
        <NoteDialog
          open={noteDialogOpen}
          id={openhouse.id}
          type="OpenHouse"
          record={note}
          onClosed={() => {
            setNoteDialogOpen(false)
            setTimeout(() => {
              setNote(null)
            }, 500)
          }}
        />
      )}
    </div>
  )
}
