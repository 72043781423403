import { createContext, useState } from 'react'

export const SettingContext = createContext({
  setting: '',
  setSetting: (setting: string) => {},
})

export const SettingProvider = ({ children }: any) => {
  const [setting, setSetting] = useState('')

  const context = {
    setting,
    setSetting,
  }

  return <SettingContext.Provider value={context}>{children}</SettingContext.Provider>
}

export const SettingConsumer = SettingContext.Consumer
