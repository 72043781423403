import { useEffect, useState } from 'react'
import ActiveFilters from '@/Shared/SlideOvers/ActiveFilters'
import axios from '@/apis/axios'
import classNames from 'classnames'
import { router } from '@inertiajs/react'
import { useForm, usePage } from '@inertiajs/react'
import Button from '@/Shared/Button'
import DataTableHeader from './DataTableHeader'
import Edit from './Edit'
import Main from '@/Shared/Layouts/Main'
import Pagination from '@/Shared/Pagination'
import SearchBar from './SearchBar'
import Link from '../../../Shared/Link'

const AdminServicesIndex = () => {
  const { services } = usePage().props
  const { data: records, paginator } = services
  const [open, setOpen] = useState(false)
  const { data, setData, delete: destroy } = useForm({})

  useEffect(() => {
    setOpen(Boolean(data.id))
  }, [data])

  const getPROPageLink = (id) => {
    const filters = { services: [id] }
    const query = encodeURIComponent(JSON.stringify(filters))

    return route('pros.index', { filters: query })
  }

  const onClosed = () => {
    setOpen(false)

    setTimeout(() => {
      setData({})
    }, 400)
  }

  const onRowClicked = (service) => {
    axios.get(route('admin.services.show', service.id)).then((response) => {
      setData(response.data)
      setOpen(true)
    })
  }

  const onDelete = (id) => {
    destroy(route('admin.services.destroy', id), {
      onSuccess: () => {
        setData({})
      },
    })
  }

  const onRestore = (id) => {
    router.post(
      route('admin.services.update', id),
      {
        id: id,
        deleted_at: null,
        _method: 'put',
      },
      {
        onSuccess: () => {
          setData({})
        },
      },
    )
  }

  return (
    <div className="space-y-4">
      <div className="flex flex-wrap items-stretch justify-between bg-white px-6 py-4">
        <SearchBar onCreate={() => setOpen(true)} />
      </div>

      <div className="space-y-1.5 px-4 sm:px-6">
        <ActiveFilters />

        <div className="bg-white shadow sm:rounded">
          <div className="flex flex-col">
            <div className="overflow-x-auto">
              <div className="inline-block min-w-full align-middle">
                <div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead>
                      <DataTableHeader />
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {records.map((service) => (
                        <tr className="group hover:bg-gray-100" key={service.id}>
                          <td
                            className="hidden cursor-pointer px-6 py-3 text-center text-gray-700 md:table-cell"
                            onClick={() => onRowClicked(service)}
                          >
                            {service.pros_count > 0 ? (
                              <Link href={getPROPageLink(service.id)} onClick={(event) => event.stopPropagation()} target="_blank">
                                {service.pros_count}
                              </Link>
                            ) : (
                              <i className="far fa-ban text-gray-300"></i>
                            )}
                          </td>

                          <td className="cursor-pointer px-6 py-3 text-gray-700" onClick={() => onRowClicked(service)}>
                            {service.title}
                          </td>
                          <td
                            className="hidden cursor-pointer whitespace-nowrap px-6 py-3 text-gray-700 md:table-cell"
                            onClick={() => onRowClicked(service)}
                          >
                            <div
                              className={classNames(
                                {
                                  public: 'bg-green-50 text-green-700',
                                  private: 'bg-gray-200 text-gray-600',
                                  deleted: 'bg-red-100 text-red-700',
                                }[service.status],
                                'inline-flex items-center whitespace-nowrap rounded-full p-1 px-2.5 text-sm font-medium uppercase leading-none',
                              )}
                            >
                              {service.status}
                            </div>
                          </td>
                          <td className="cursor-pointer whitespace-nowrap py-3 pr-6 text-right font-medium">
                            <div className="flex items-center justify-end">
                              <Button theme="icon" onClick={() => onRowClicked(service)}>
                                <i className="far fa-pen text-lg text-gray-500 group-hover:font-bold group-hover:text-blue-500"></i>
                              </Button>

                              {service.status !== 'deleted' ? (
                                <button
                                  role="button"
                                  className={classNames(
                                    'relative ml-2 inline-flex h-10 w-10 items-center justify-center rounded leading-none',
                                    service.pros_count > 0
                                      ? 'cursor-not-allowed'
                                      : 'transition-all duration-150 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-red-600',
                                  )}
                                  onClick={() => onDelete(service.id)}
                                  disabled={service.pros_count > 0}
                                >
                                  <span className="sr-only">Delete Service</span>
                                  <i
                                    className={classNames(
                                      'far fa-trash text-lg',
                                      service.pros_count > 0 ? 'text-gray-200' : 'text-red-600',
                                    )}
                                  ></i>
                                </button>
                              ) : (
                                <Button theme="icon" onClick={() => onRestore(service.id)}>
                                  <i className="far fa-trash-restore text-lg text-gray-600 group-hover:text-gray-900"></i>
                                </Button>
                              )}
                            </div>
                          </td>
                        </tr>
                      ))}

                      {records.length == 0 && (
                        <tr>
                          <td colSpan="8" className="px-6 py-12">
                            <div className="flex flex-col text-center">
                              <span className="text-2xl font-semibold text-red-600">No services match your current filters.</span>
                              <span className="text-lg font-medium text-gray-700">
                                Expecting to see results? Check your filters to make sure you're not excluding results.
                              </span>
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            {records.length > 0 && <Pagination paginator={paginator} />}
          </div>
        </div>
      </div>

      <Edit open={open} record={data} onClosed={() => onClosed()} />
    </div>
  )
}

// Persistent layout
// Docs: https://inertiajs.com/pages#persistent-layouts
AdminServicesIndex.layout = (page) => <Main title="Services" children={page} />

export default AdminServicesIndex
