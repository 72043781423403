import React, { Fragment, useState } from 'react'
import { usePage } from '@inertiajs/react'
import Button from '@/Shared/Button'
import ConfirmationDialog from '@/Shared/Dialog/ConfirmationDialog'
import PublishToTransactionDialog from './PublishToTransactionDialog'
import StepDialog from './Steps/StepDialog'
import Section from './Section'
import { router } from '@inertiajs/react'

export default ({ onEditChecklist }) => {
  const scrollRef = React.createRef()
  const { checklist } = usePage().props
  const [step, setStep] = useState(null)
  const [beforePublish, setBeforePublish] = useState(false)
  const [publishing, setPublishing] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  const onPublish = () => {
    setSubmitting(true)

    router.post(route('admin.checklists.publish', checklist.id), null, {
      onFinish: () => {
        setBeforePublish(false)
        setSubmitting(false)
      },
    })
  }

  const scrollToElement = (el) => {
    let container = scrollRef.current

    container.scrollTo({
      top: el.offsetTop - 155,
    })
  }

  return (
    <div className="flex h-full flex-col gap-6">
      <div className="relative z-20 flex flex-col items-center gap-4 border-b border-gray-300 bg-white px-4 py-4 shadow-sm sm:flex-row sm:items-center sm:justify-end sm:px-6">
        <div className="text-center sm:text-left">
          <div>
            <b>Last published:</b> {checklist.published_at}
          </div>

          {checklist.pending_edits && (
            <div className="inline-flex items-center whitespace-nowrap rounded border border-yellow-200 bg-yellow-100 px-2.5 py-1.5 text-sm font-medium uppercase leading-none text-yellow-600">
              <span className="flex items-center gap-2">
                <i className="far fa-exclamation-triangle text-lg leading-none"></i>
                <span className="whitespace-pre-line">Pending Changes</span>
              </span>
            </div>
          )}
        </div>

        <div className="flex flex-1 items-center justify-end">
          <div className="btn-group pull-right">
            <Button theme="outline" onClick={() => setStep({})}>
              <i className="fal fa-plus pr-2 text-gray-500"></i>
              Add Step
            </Button>

            <Button
              theme="solid"
              colors={checklist.pending_edits ? 'bg-green-700' : ''}
              disabled={!checklist.pending_edits}
              onClick={() => setBeforePublish(true)}
              grouped
            >
              Publish Changes
              <Button.GroupOptions>
                <button
                  className="block w-full px-4 py-2 text-gray-700 hover:bg-gray-100"
                  role="menuitem"
                  onClick={() => setPublishing(true)}
                >
                  To a Specific Transaction
                </button>
              </Button.GroupOptions>
            </Button>
          </div>
        </div>
      </div>

      {checklist.sections.length > 0 ? (
        <div ref={scrollRef} className="relative h-full px-5 lg:overflow-y-auto">
          {checklist.sections
            .filter((section) => section.steps.length > 0)
            .map((section) => (
              <Section
                section={section}
                key={section.id}
                onCreate={(options) => setStep(options)}
                onEdit={(step) => setStep(step)}
                scrollToElement={(ref) => scrollToElement(ref)}
              />
            ))}

          {checklist.sections.filter((section) => section.steps.length > 0).length == 0 && (
            <div className="overflow-hidden rounded-md border border-gray-200 bg-white p-6 text-center">
              <p className="mb-2 border-b border-gray-200 pb-2 text-lg font-medium text-red-700">
                No sections containing 1 or more steps were found matching your search.
              </p>
              <p className="mb-1 font-medium text-gray-600">Want to add a new section?</p>
              <Button theme="solid" onClick={onEditChecklist}>
                Edit Checklist
              </Button>
            </div>
          )}
        </div>
      ) : (
        <div className="overflow-hidden rounded-md border border-gray-200 bg-white p-6 text-center">
          <p className="mb-2 border-b border-gray-200 pb-2 text-lg font-medium text-red-700">
            No sections have been added to this checklist.
          </p>
          <p className="mb-1 font-medium text-gray-600">Want to add a new section?</p>
          <Button theme="solid" onClick={onEditChecklist}>
            Edit Checklist
          </Button>
        </div>
      )}

      <StepDialog open={step != null} record={step} onClosed={() => setStep(null)} />

      <PublishToTransactionDialog checklistId={checklist.id} open={publishing} onClosed={() => setPublishing(false)} />

      <ConfirmationDialog
        open={beforePublish}
        title="Publish Changes Confirmation"
        onCanceled={() => setBeforePublish(false)}
        onApproved={() => onPublish()}
        disabled={submitting}
      >
        <Fragment>
          <p className="mb-3">
            You are about to publish all pending changes for this checklist to <b>all open transactions</b>. This process may take a few
            minutes to complete depending on the number of open transactions using this checklist.
          </p>
          <p className="mb-3">Would you like to continue?</p>
        </Fragment>
      </ConfirmationDialog>
    </div>
  )
}
