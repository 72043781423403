import React, { useEffect, useState } from 'react'
import { router } from '@inertiajs/react'
import { usePage } from '@inertiajs/react'
import Select from 'react-select'

const customStyles = {
  control: (provided) => ({
    ...provided,
    borderWidth: '0',
    backgroundColor: '#f3f4f8',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    backgroundColor: 'transparent',
  }),
}

export default ({ paginator = {} }) => {
  const { filters } = usePage().props
  const { from, per_page, to, total } = paginator
  const [limit, setLimit] = useState(per_page)
  const options = [
    { value: '10', label: '10' },
    { value: '25', label: '25' },
    { value: '50', label: '50' },
    { value: '100', label: '100' },
  ]

  useEffect(() => {
    if (limit != per_page) {
      limitChanged()
    }
  }, [limit])

  const limitChanged = () => {
    const values = filters ? { ...filters, limit: limit } : { limit: limit }
    const query = encodeURIComponent(JSON.stringify(values))

    router.get(route(route().current(), { filters: query }))
  }

  return (
    <div className="flex items-center justify-between space-x-4">
      <span>
        {total > to && (
          <Select
            className="w-20"
            menuPlacement="top"
            styles={customStyles}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: '#1c3f95',
                primary25: '#EDF0FC',
              },
            })}
            placeholder=""
            isClearable={false}
            isSearchable={false}
            options={options}
            value={options.find((option) => option.value == per_page)}
            onChange={(option) => setLimit(option.value)}
          />
        )}
      </span>

      <span>
        Showing {from} - {to} of {total}
      </span>
    </div>
  )
}
