import React from 'react'
import classNames from 'classnames'

export default React.forwardRef(
  (
    {
      autoComplete,
      autoCorrect,
      autoFocus,
      classes,
      clearable,
      disabled,
      disabledMessage,
      error,
      hideErrorMessage,
      icon,
      iconPosition,
      inline,
      inputClasses,
      inputGroup,
      label,
      name,
      placeholder,
      required,
      size,
      spellCheck,
      type,
      value,
      onBlur,
      onChange,
      ...props
    },
    ref,
  ) => {
    return (
      <div
        className={classNames(props.className || classes, !props.className && !classes && !inline && 'mb-4', {
          'flex items-center': inline,
        })}
      >
        {label && (
          <label
            htmlFor={name}
            className={classNames(
              'block text-sm font-medium uppercase',
              error ? 'text-red-600' : 'text-gray-500',
              inline ? 'mr-3' : 'mb-0.5',
            )}
          >
            {label}
            {required && <span className="pl-1 text-red-600">*</span>}
          </label>
        )}

        <div className={classNames('relative z-0 flex', { 'items-center': !icon && !inputGroup }, size)}>
          {icon && iconPosition == undefined && (
            <span
              className={classNames(
                'flex w-12 shrink-0 items-center justify-center rounded-l border-b border-l border-t text-sm font-semibold transition-all duration-150 ease-in-out',
                error ? 'bg-red-100 text-red-500 ring-2 ring-red-500' : 'border-gray-300 bg-gray-200 text-gray-700',
              )}
            >
              {icon}
            </span>
          )}

          {inputGroup && (
            <span
              className={classNames(
                'flex items-center rounded-l border-b border-l border-t px-3 text-sm font-semibold transition-all duration-150 ease-in-out',
                error ? 'bg-red-100 text-red-500 ring-2 ring-red-500' : 'border-gray-300 bg-gray-100 text-gray-500',
              )}
            >
              {inputGroup}
            </span>
          )}

          <input
            autoComplete={autoComplete || 'off'}
            autoCorrect={autoCorrect || 'false'}
            autoFocus={autoFocus || false}
            id={name}
            className={classNames(
              'font-md transition-border z-20 block h-11 w-full placeholder-gray-400 outline-none duration-150 ease-in-out',
              { 'px-4 py-2': !inputClasses },
              icon ? 'rounded-r' : 'rounded',
              disabled ? 'cursor-not-allowed bg-gray-200' : '',
              error
                ? 'border-transparent ring-2 ring-red-500 hover:ring-red-400 focus:ring-red-700'
                : 'border border-gray-300 hover:border-gray-400 focus:border-transparent focus:ring-2 focus:ring-primary-500',
              inputClasses,
            )}
            placeholder={placeholder || null}
            ref={ref}
            spellCheck={spellCheck || 'false'}
            type={type || 'text'}
            value={[undefined, null, ''].indexOf(value) >= 0 ? '' : value}
            onBlur={onBlur}
            onChange={(e) => onChange(e.target.value)}
            disabled={disabled}
            {...props}
          />

          {clearable && [undefined, null, ''].indexOf(value) < 0 && (
            <button
              type="button"
              className="absolute inset-y-0 right-1 m-1 rounded-lg bg-white px-2.5 text-gray-400 hover:text-gray-700"
              onClick={() => onChange('')}
            >
              <i className="far fa-times text-lg"></i>
            </button>
          )}

          {icon && iconPosition != undefined && iconPosition === 'right' && (
            <span
              className={classNames(
                'flex w-12 shrink-0 items-center justify-center rounded-r border-b border-r border-t text-sm font-semibold transition-all duration-150 ease-in-out',
                error ? 'bg-red-100 text-red-500 ring-2 ring-red-500' : 'border-gray-300 bg-gray-200 text-gray-700',
              )}
            >
              {icon}
            </span>
          )}
        </div>

        {disabled && disabledMessage && (
          <div className="mt-2 flex items-center justify-center gap-2 rounded bg-red-100/70 px-6 py-2 leading-none text-red-500 shadow">
            <i className="fas fa-question-circle text-lg"></i>
            <p>{disabledMessage}</p>
          </div>
        )}

        {error && !hideErrorMessage && <div className="mt-1 text-red-600" dangerouslySetInnerHTML={{ __html: error }}></div>}
      </div>
    )
  },
)
