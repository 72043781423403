import { createContext, useEffect, useState } from 'react'

type ViewState = 'date' | 'month' | 'year'

interface MonthYear {
  month: number
  year: number
}

interface DatepickerContextType {
  date: Date | null
  visible: MonthYear
  view: ViewState
  nextMonth: () => void
  prevMonth: () => void
  nextYear: () => void
  prevYear: () => void
  nextDecade: () => void
  prevDecade: () => void
  selectMonth: (m: number) => void
  selectYear: (y: number) => void
  selectDate: (d: number) => void
  viewMonths: () => void
  viewYears: () => void
  isYearVisible: boolean
  isVisible: boolean
  showCalendar: () => void
  toggleCalendar: () => void
  isCurrentDate: (d: number) => boolean
  isSelectedDate: (d: number) => boolean
  isSelectedMonth: (d: number) => boolean
  isSelectedYear: (d: number) => boolean
}

export const DatepickerCtx = createContext<DatepickerContextType>({
  date: new Date(),
  visible: {
    month: 0,
    year: 1970,
  },
  view: 'date',
  nextMonth: () => {},
  prevMonth: () => {},
  nextYear: () => {},
  prevYear: () => {},
  nextDecade: () => {},
  prevDecade: () => {},
  selectMonth: (m) => {},
  selectYear: (y) => {},
  selectDate: (d) => {},
  viewMonths: () => {},
  viewYears: () => {},
  isYearVisible: true,
  isVisible: false,
  showCalendar: () => {},
  toggleCalendar: () => {},
  isCurrentDate: (d) => false,
  isSelectedDate: (d) => false,
  isSelectedMonth: (d) => false,
  isSelectedYear: (d) => false,
})

export function useDatepickerCtx(
  date: Date,
  hideYear: boolean,
  onChange: (d: Date) => void,
  ref: React.MutableRefObject<HTMLElement | null>,
): DatepickerContextType {
  const [monthYear, setMonthYear] = useState<MonthYear>({
    month: date?.getMonth() ?? new Date().getMonth(),
    year: !hideYear ? date?.getFullYear() ?? new Date().getFullYear() : new Date().getFullYear(),
  })

  const [view, setView] = useState<ViewState>(hideYear ? 'month' : 'date')
  const [isYearVisible] = useState<boolean>(!hideYear)
  const [isVisible, setVisible] = useState<boolean>(false)

  const selectDate = (d: number) => {
    onChange(new Date(monthYear.year, monthYear.month, d))
    setVisible(false)
  }

  const isCurrentDate = (d: number): boolean => {
    return d === new Date().getDate()
  }

  const isSelectedDate = (d: number): boolean => {
    if (date && d === date.getDate() && monthYear.month === date.getMonth() && monthYear.year === date.getFullYear()) {
      return true
    }
    return false
  }

  const isSelectedMonth = (month: number): boolean => {
    if (date && month === date.getMonth()) {
      return true
    } else if (!date && month === new Date().getMonth()) {
      return true
    }
    return false
  }

  const isSelectedYear = (year: number): boolean => {
    if (date && year === date.getFullYear()) {
      return true
    } else if (!date && year === new Date().getFullYear()) {
      return true
    }
    return false
  }

  const selectMonth = (m: number) => {
    setMonthYear((state) => ({ ...state, month: m }))
    setView('date')
  }

  const selectYear = (y: number) => {
    setMonthYear((state) => ({ ...state, year: y }))
    setView('month')
  }

  useEffect(() => {
    function mouseDownListener(e: MouseEvent) {
      if (ref.current && !ref.current.contains(e.target as Node)) {
        setVisible(false)
      }
    }

    if (isVisible) {
      if (date) {
        setMonthYear({ month: date.getMonth(), year: date.getFullYear() })
      }
      document.addEventListener('mousedown', mouseDownListener)
    }

    return () => {
      document.removeEventListener('mousedown', mouseDownListener)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible])

  return {
    date,
    visible: monthYear,
    view,
    nextMonth: () =>
      setMonthYear((state) => (state.month >= 11 ? { month: 0, year: state.year + 1 } : { month: state.month + 1, year: state.year })),
    prevMonth: () =>
      setMonthYear((state) => (state.month <= 0 ? { month: 11, year: state.year - 1 } : { month: state.month - 1, year: state.year })),
    nextYear: () => setMonthYear((state) => ({ ...state, year: state.year + 1 })),
    prevYear: () => setMonthYear((state) => ({ ...state, year: state.year - 1 })),
    nextDecade: () => setMonthYear((state) => ({ ...state, year: state.year + 12 })),
    prevDecade: () => setMonthYear((state) => ({ ...state, year: state.year - 12 })),
    selectMonth,
    selectYear,
    selectDate,
    viewMonths: () => setView('month'),
    viewYears: () => setView('year'),
    isYearVisible,
    isVisible,
    showCalendar: () => setVisible(true),
    toggleCalendar: () => setVisible((state) => !state),
    isCurrentDate,
    isSelectedDate,
    isSelectedMonth,
    isSelectedYear,
  }
}
