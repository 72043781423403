import { usePage } from '@inertiajs/react'
import classNames from 'classnames'
import DataTableHeader from './DataTableHeader'
import { router } from '@inertiajs/react'

export default () => {
  const { filters, myLSNs } = usePage().props

  const rowClicked = (id) => {
    router.visit(route('lsns.show', id))
  }

  return (
    <div className="bg-white shadow sm:rounded">
      <div className="flex flex-col">
        <div className="overflow-x-auto">
          <div className="inline-block min-w-full align-middle">
            <div className="overflow-hidden border-b border-gray-200 shadow">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th
                      className="h-10 cursor-pointer rounded-t-md bg-primary-500 px-4 py-4 text-left font-medium uppercase text-white sm:px-6"
                      colSpan="5"
                    >
                      <div className="flex items-center leading-none">My LSNs</div>
                    </th>
                  </tr>
                  <DataTableHeader editable={true} sortBy={filters.sortBy} direction={filters.direction} />
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {myLSNs.map((lsn) => (
                    <tr className="group hover:bg-gray-100" key={lsn.id}>
                      <td className="mx-auto cursor-pointer px-4 py-3 text-gray-700 sm:px-6" onClick={() => rowClicked(lsn.id)}>
                        <span className="font-bold text-primary-500">{lsn.name}</span>

                        <div className="flex flex-col md:hidden">
                          <div>{lsn.location}</div>
                        </div>

                        <div className="flex items-center space-x-1.5 sm:hidden">
                          <span className="inline-flex items-center whitespace-nowrap rounded bg-gray-200 px-2.5 py-1.5 text-sm leading-none text-gray-800 sm:hidden">
                            {lsn.pros || 0} Members
                          </span>

                          {lsn.published ? (
                            <span className="inline-flex items-center whitespace-nowrap rounded bg-green-50 px-2.5 py-1.5 text-sm font-medium uppercase leading-none text-green-700">
                              Public
                            </span>
                          ) : (
                            <span className="inline-block rounded bg-red-100 px-2.5 py-1.5 text-sm font-medium uppercase leading-none text-red-600">
                              Private
                            </span>
                          )}
                        </div>
                      </td>

                      {route().current() === 'lsns.index' && (
                        <td
                          className="hidden cursor-pointer whitespace-nowrap px-4 py-3 text-center text-gray-700 sm:table-cell sm:px-6"
                          onClick={() => rowClicked(lsn.id)}
                          width="80px"
                        >
                          {lsn.published ? (
                            <span className="inline-flex items-center whitespace-nowrap rounded bg-green-50 px-2.5 py-1.5 text-sm font-medium uppercase leading-none text-green-700">
                              Public
                            </span>
                          ) : (
                            <span className="inline-block rounded bg-red-100 px-2.5 py-1.5 text-sm font-medium uppercase leading-none text-red-600">
                              Private
                            </span>
                          )}
                        </td>
                      )}

                      <td
                        className="hidden cursor-pointer px-4 py-3 text-gray-700 md:table-cell xl:px-6"
                        onClick={() => rowClicked(lsn.id)}
                      >
                        {lsn.location ? (
                          <div className="flex flex-col">{lsn.location}</div>
                        ) : (
                          <span className="text-gray-300">
                            <i className="fas fa-ban"></i>
                          </span>
                        )}
                      </td>

                      <td
                        className="hidden cursor-pointer whitespace-nowrap px-4 py-3 text-center text-gray-700 sm:table-cell sm:px-6"
                        onClick={() => rowClicked(lsn.id)}
                      >
                        {lsn.pros > 0 ? (
                          <span className="font-bold text-primary-500">{lsn.pros}</span>
                        ) : (
                          <span className="text-gray-300">
                            <i className="fas fa-ban"></i>
                          </span>
                        )}
                      </td>

                      <td
                        className="w-16 cursor-pointer whitespace-nowrap py-3 pr-6 text-right font-medium"
                        onClick={() => rowClicked(lsn.id)}
                      >
                        <div className="flex items-center justify-end">
                          <i
                            className={classNames('far', lsn.editable ? 'fa-pen' : 'fa-angle-right text-2xl', 'leading-none text-gray-700')}
                          ></i>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
