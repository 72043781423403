import { ToastContainer } from 'react-toastify'

import Portal from '@/Shared/Portal'
import 'react-toastify/dist/ReactToastify.css'

export default () => {
  return (
    <Portal>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        style={{
          width: '100%',
          maxWidth: '700px',
        }}
      />
    </Portal>
  )
}
