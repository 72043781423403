import { useCallback, useState, useEffect, useRef } from 'react'

/**
 * A custom hook that manages polling with pause/resume based on tab visibility.
 *
 * @param {Function} callback - The function to call at each poll interval.
 * @param {number} interval - The polling interval in milliseconds.
 */
const useVisibilityAwarePolling = (callback, interval = 60000, activityTimeout = 5 * 60 * 1000) => {
  const [isActive, setIsActive] = useState(true)
  const lastPollTime = useRef(Date.now())
  const activityTimer = useRef(null)
  const pollingTimer = useRef(null)

  const resetActivityTimer = useCallback(() => {
    clearTimeout(activityTimer.current)
    setIsActive(true) // User is active, ensure polling is active

    activityTimer.current = setTimeout(() => {
      setIsActive(false) // User is inactive, pause polling
    }, activityTimeout)
  }, [activityTimeout])

  // Setup event listeners for user activity
  useEffect(() => {
    const events = ['mousemove', 'keydown', 'scroll', 'touchstart']
    events.forEach((event) => window.addEventListener(event, resetActivityTimer))

    // Start the activity timer initially
    resetActivityTimer()

    return () => {
      clearTimeout(activityTimer.current)
      events.forEach((event) => window.removeEventListener(event, resetActivityTimer))
    }
  }, [resetActivityTimer])

  // Effect for handling the visibility change
  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsActive(document.visibilityState === 'visible')

      if (document.visibilityState === 'visible') {
        resetActivityTimer() // Reset activity timer when tab becomes visible
      }
    }

    document.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [])

  // Controlled polling logic
  useEffect(() => {
    if (!isActive) {
      // If not active, ensure no polling is scheduled
      clearTimeout(pollingTimer.current)
      return
    }

    const executePolling = () => {
      const now = Date.now()
      const timeSinceLastPoll = now - lastPollTime.current
      if (timeSinceLastPoll >= interval) {
        callback()
        lastPollTime.current = now
      }
      // Schedule the next poll
      pollingTimer.current = setTimeout(executePolling, interval - (Date.now() - lastPollTime.current))
    }

    // Initial setup or resume
    executePolling()

    return () => clearTimeout(pollingTimer.current) // Cleanup on unmount or state change
  }, [isActive, callback, interval])
}

export default useVisibilityAwarePolling
