import MainMenu from '@/Shared/Navigation/MainMenu'
import SlideOver from '@/Shared/SlideOver'

export default ({ open, onClosed }) => {
  return (
    <SlideOver show={open} size="max-w-sm" position="left" title="Menu" onClosed={onClosed} hideFooter aboveMessages>
      <MainMenu mobile />
    </SlideOver>
  )
}
