import { Fragment } from 'react'
import { NotificationConsumer } from '@/Shared/Notifications/NotificationContext'

export default () => {
  return (
    <NotificationConsumer>
      {({ count, setOpen }) => (
        <button
          type="button"
          aria-label={`${count > 0 ? `${count} pending` : 'view'} notification${count == 1 ? '' : 's'}`}
          className="relative inline-flex h-10 w-10 items-center justify-center rounded text-white outline-none"
          onClick={() => setOpen(true)}
        >
          {count > 0 ? (
            <Fragment>
              <i className="fas fa-bell text-xl leading-none"></i>
              <span className="absolute -top-0.5 flex flex-wrap items-center justify-center rounded bg-red-600 p-1 pb-0.5 text-xs font-medium leading-none text-white">
                {count}
              </span>
            </Fragment>
          ) : (
            <i className="far fa-bell text-xl leading-none opacity-75"></i>
          )}
        </button>
      )}
    </NotificationConsumer>
  )
}
