import { Fragment, useEffect, useRef } from 'react'
import Button from '@/Shared/Button'
import Checkbox from '@/Shared/Forms/Checkbox'
import Heading from '@/Shared/Forms/Heading'
import Helpers from '@/utils/helpers'
import MultipleInputBlock from '@/Shared/Forms/MultipleInputBlock'
import Select from '@/Shared/Forms/Select'
import SlideOver from '@/Shared/SlideOver'
import TextInput from '@/Shared/Forms/TextInput'
import { useForm, usePage } from '@inertiajs/react'
import { router } from '@inertiajs/react'
import Label from '../../../../Shared/Label'

export default ({ open, onClosed }) => {
  let focusRef = useRef()
  const { contact } = usePage().props
  const { user } = usePage().props.auth

  const form = useForm({
    nrds_id: '',
    realtor_associations: [],
  })
  const { clearErrors, data, errors, setData, setError, reset } = form
  const realtorAassociations = [
    { value: 'Cape Cod & Islands Assoc. of Realtors (CCIAOR)', label: 'Cape Cod & Islands Assoc. of Realtors (CCIAOR)' },
    { value: 'Greater Boston Real Estate Board', label: 'Greater Boston Real Estate Board' },
    { value: 'Greater Providence Board of Realtors', label: 'Greater Providence Board of Realtors' },
    { value: 'Massachusetts Association of Realtors', label: 'Massachusetts Association of Realtors' },
    { value: 'New Hampshire Association of Realtors', label: 'New Hampshire Association of Realtors' },
    { value: 'Northern RI Board of Realtors', label: 'Northern RI Board of Realtors' },
    { value: 'Realtor Association of Central Massachusetts', label: 'Realtor Association of Central Massachusetts' },
    { value: 'Realtor Association of Southeastern MA (RASEM)', label: 'Realtor Association of Southeastern MA (RASEM)' },
    { value: 'Rhode Island Association of Realtors', label: 'Rhode Island Association of Realtors' },
    { value: 'Seacoast Board of Realtors', label: 'Seacoast Board of Realtors' },
  ]
  const inactiveReasons = [
    { value: 'Orientation', label: 'Orientation' },
    { value: 'Unpaid Dues', label: 'Unpaid Dues' },
  ]

  useEffect(() => {
    if (open) {
      setData({
        nrds_id: contact.associate.nrds_id,
        realtor_associations:
          contact.associate.realtor_associations.length > 0
            ? contact.associate.realtor_associations.map((association) =>
                Helpers.isAdminOrHR(user) ? association : { ...association, disableDeleting: !association.active },
              )
            : [{ primary: true, active: true }],
      })
    }
  }, [open])

  const closeDialog = () => {
    clearErrors()
    reset()
    onClosed()
  }

  const submit = (event) => {
    event.preventDefault()

    let associations = data.realtor_associations
      .filter((association) => association.value)
      .map((association) => {
        delete association.disableDeleting
        return association
      })

    router.post(
      Helpers.isAdminOrHR(user) ? route('admin.associates.update', contact.associate.id) : route('my-card.update'),
      { ...data, realtor_associations: associations, _method: 'put' },
      {
        onSuccess: () => {
          closeDialog()
        },
        onError: (errors) => setError(errors),
      },
    )
  }

  return (
    <SlideOver
      focusRef={focusRef}
      footerActions={
        <Button type="submit" theme="solid" form="realtorAssociationsForm">
          Save
        </Button>
      }
      show={open}
      size="max-w-lg"
      title="Edit"
      subTitle="Realtor Associations"
      onClosed={closeDialog}
    >
      <form id="realtorAssociationsForm" className="mb-5 space-y-4" onSubmit={submit}>
        <div>
          <Heading>Realtor Associations</Heading>
          <MultipleInputBlock
            data={data.realtor_associations}
            onChange={(value) =>
              setData({
                ...data,
                realtor_associations: value.map((item) => ({
                  ...item,
                  primary: item.primary == undefined ? false : item.primary,
                  active: item.active == undefined ? true : item.active,
                })),
              })
            }
            hasPrimary
            render={({ data, index, onChange }) => (
              <div className="flex-1 space-y-4">
                <Select
                  className="mb-0"
                  name={`realtor_association_${index}_value`}
                  options={realtorAassociations}
                  placeholder="Select a Realtor Association"
                  value={data.value}
                  isClearable={false}
                  disabled={!Helpers.isAdminOrHR(user) && !data.active}
                  onChange={(selected) => onChange(selected && selected.value, 'value')}
                />

                {Helpers.isAdminOrHR(user) ? (
                  <div className="space-y-4">
                    <Checkbox
                      name={`realtor_association_${index}_active`}
                      label="Active"
                      value={data.active}
                      onChange={(selected) => onChange(selected, 'active')}
                    />

                    {!data.active && (
                      <Fragment>
                        <Select
                          className="mb-0"
                          label="Inactive Reason(s)"
                          name={`realtor_association_${index}_inactive_reason`}
                          options={inactiveReasons}
                          placeholder="Select One"
                          value={data.inactive_reason}
                          onChange={(selected) => onChange(selected, 'inactive_reason')}
                          multiple
                        />
                      </Fragment>
                    )}
                  </div>
                ) : (
                  data.value && (
                    <div className="space-x-2">
                      {data.active ? (
                        <Label type="success">Active</Label>
                      ) : (
                        <Label type="error">
                          Inactive (Reason{data.inactive_reason.length > 1 ? 's' : ''}:{' '}
                          {data.inactive_reason.reduce((output, reason) => output + (output ? ', ' : '') + reason.label, '')})
                        </Label>
                      )}
                    </div>
                  )
                )}

                {Boolean(errors[`realtor_associations.${index}.inactive_reason`]) && (
                  <div className="mx-1 mt-2 text-red-600">{errors[`realtor_associations.${index}.inactive_reason`]}</div>
                )}
              </div>
            )}
          />
        </div>

        <TextInput
          ref={focusRef}
          label="NRDS ID"
          name="nrds_id"
          value={data.nrds_id}
          onChange={(value) => setData({ ...data, nrds_id: value })}
        />
      </form>
    </SlideOver>
  )
}
