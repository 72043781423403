import AccordionCard from '@/Shared/AccordionCard'
import AccordionItem from '@/Shared/AccordionItem'
import classNames from 'classnames'
import { usePage } from '@inertiajs/react'

const ContactItem = ({ contact, label, onClick }) => {
  return (
    <AccordionItem onClick={() => (onClick ? onClick(contact.id) : null)}>
      <div className="flex items-center justify-between space-x-3">
        <div>
          {contact.avatar ? (
            <img className="h-10 w-10 rounded-full" src={contact.avatar} alt="" />
          ) : (
            <div className="flex h-10 w-10 items-center justify-center rounded-full bg-gray-200 text-gray-700">
              <div className="text-base font-medium tracking-wide">{contact.initials}</div>
            </div>
          )}
        </div>
        <div className="flex flex-1 flex-col">
          <span className="font-semibold text-blue-500">{contact.name}</span>
          <span>{label}</span>
        </div>
      </div>
    </AccordionItem>
  )
}

export default ({ onClick }) => {
  const { contact } = usePage().props

  const associates = contact.associate.sponsors?.filter((contact) => !contact.pal)
  const pals = contact.associate.sponsors?.filter((contact) => contact.pal)

  return associates?.length > 0 || pals?.length > 0 ? (
    <AccordionCard title={`${route().current() === 'my-card' ? 'My ' : ''}Sponsored Associates`} collapsed>
      {associates?.length > 0 && (
        <div className="space-y-4">
          <div className="divide-y divide-dashed divide-gray-200 overflow-hidden rounded-md border border-gray-200">
            {associates.map((contact) => (
              <div key={contact.id}>
                <ContactItem label="Primary" contact={contact} onClick={onClick} />

                {contact.sponsors.length > 0 &&
                  contact.sponsors.map((tier2Contact) => (
                    <div className="flex items-center pl-8" key={tier2Contact.id}>
                      <div>
                        <i className="far fa-arrow-turn-down-right mr-1.5 text-lg text-gray-400"></i>
                      </div>
                      <ContactItem label="Secondary" contact={tier2Contact} onClick={onClick} key={tier2Contact.id} />
                    </div>
                  ))}
              </div>
            ))}
          </div>
        </div>
      )}

      {pals?.length > 0 && (
        <div className={classNames('space-y-4', { 'mt-4': associates?.length > 0 })}>
          <div className="rounded-lg border border-blue-200 border-opacity-50 bg-blue-50 p-2 text-center font-medium uppercase">PALs</div>

          <div className="divide-y divide-dashed divide-gray-200 overflow-hidden rounded-md border border-gray-200">
            {pals.map((contact) => (
              <ContactItem label="Primary" contact={contact} onClick={onClick} key={contact.id} />
            ))}
          </div>
        </div>
      )}
    </AccordionCard>
  ) : null
}
