import { Fragment } from 'react'
import AccordionCard from '@/Shared/AccordionCard'
import AccordionItem from '@/Shared/AccordionItem'

export default ({ associate, collapsed, showResources, onClick }) => {
  return (
    <AccordionCard title={`${route().current() === 'my-card' ? 'My ' : ''}Support Network`} collapsed={collapsed}>
      {showResources && (
        <div className="mb-4 space-y-6 text-lg md:space-y-4">
          <div className="rounded-lg border border-blue-200 border-opacity-50 bg-blue-50 p-2 text-center font-medium uppercase">
            Search these solutions for support:
          </div>

          <a
            href="https://sites.google.com/a/betterlivingre.com/intranet/home"
            className="flex cursor-pointer items-center justify-between border-b border-transparent font-medium text-blue-500 hover:border-blue-500"
            target="_blank"
          >
            TIER ONE - Our Team Intranet <i className="far fa-external-link text-xl md:text-base"></i>
          </a>

          <a
            href="https://sites.google.com/a/betterlivingre.com/intranet/support-resources"
            className="flex cursor-pointer items-center justify-between border-b border-transparent font-medium text-blue-500 hover:border-blue-500"
            target="_blank"
          >
            TIER TWO - Our Help & SUPPORT Forum <i className="far fa-external-link text-xl md:text-base"></i>
          </a>

          {(associate.svpContacts || associate.sponsor1stTier) && <div className="font-medium">TIER THREE - Human Support</div>}
        </div>
      )}

      {(associate.svpContacts || associate.sponsor1stTier) && (
        <div className="divide-y divide-dashed divide-gray-200 overflow-hidden rounded-md border border-gray-200">
          {associate.svpContacts?.map((svp) => (
            <AccordionItem onClick={() => (onClick ? onClick(svp.id) : null)} key={svp.id}>
              <div className="flex items-center justify-between space-x-3">
                <div>
                  {svp.avatar && <img className="h-10 w-10 rounded-full" src={svp.avatar} alt="" />}
                  {!svp.avatar && (
                    <div className="flex h-10 w-10 items-center justify-center rounded-full bg-gray-200 text-gray-700">
                      <div className="text-base font-medium tracking-wide">{svp.initials}</div>
                    </div>
                  )}
                </div>
                <div className="flex flex-1 flex-col">
                  <span className="font-semibold text-blue-500">{svp.name}</span>
                  <span>Support Vice President ({svp.states_supervising})</span>
                </div>
              </div>
            </AccordionItem>
          ))}

          {associate.sponsor1stTier && (
            <Fragment>
              {!associate.sponsor1stTier.terminated && (
                <AccordionItem onClick={() => (onClick ? onClick(associate.sponsor1stTier.id) : null)}>
                  <div className="flex items-center justify-between space-x-3">
                    <div>
                      {associate.sponsor1stTier.avatar && (
                        <img className="h-10 w-10 rounded-full" src={associate.sponsor1stTier.avatar} alt="" />
                      )}
                      {!associate.sponsor1stTier.avatar && (
                        <div className="flex h-10 w-10 items-center justify-center rounded-full bg-gray-200 text-gray-700">
                          <div className="text-base font-medium tracking-wide">{associate.sponsor1stTier.initials}</div>
                        </div>
                      )}
                    </div>
                    <div className="flex flex-1 flex-col">
                      <span className="font-semibold text-blue-500">{associate.sponsor1stTier.name}</span>
                      <span>Primary Sponsor</span>
                    </div>
                  </div>
                </AccordionItem>
              )}

              {associate.sponsor1stTier.sponsor1stTier && !associate.sponsor1stTier.sponsor1stTier.terminated && (
                <AccordionItem onClick={() => (onClick ? onClick(associate.sponsor1stTier.sponsor1stTier.id) : null)}>
                  <div className="flex items-center justify-between space-x-3">
                    <div>
                      {associate.sponsor1stTier.sponsor1stTier.avatar && (
                        <img className="h-10 w-10 rounded-full" src={associate.sponsor1stTier.sponsor1stTier.avatar} alt="" />
                      )}
                      {!associate.sponsor1stTier.sponsor1stTier.avatar && (
                        <div className="flex h-10 w-10 items-center justify-center rounded-full bg-gray-200 text-gray-700">
                          <div className="text-base font-medium tracking-wide">{associate.sponsor1stTier.sponsor1stTier.initials}</div>
                        </div>
                      )}
                    </div>
                    <div className="flex flex-1 flex-col">
                      <span className="font-semibold text-blue-500">{associate.sponsor1stTier.sponsor1stTier.name}</span>
                      <span>Secondary Sponsor</span>
                    </div>
                  </div>
                </AccordionItem>
              )}
            </Fragment>
          )}
        </div>
      )}

      {showResources && (
        <div className="mt-6 text-lg md:mt-4">
          <a
            href="https://www.facebook.com/groups/BetterLivingRE/"
            className="flex cursor-pointer items-center justify-between border-b border-transparent font-medium text-blue-500 hover:border-blue-500"
            target="_blank"
          >
            TIER FOUR - Team Facebook Group <i className="far fa-external-link text-xl md:text-base"></i>
          </a>
        </div>
      )}
    </AccordionCard>
  )
}
