import React, { useEffect, useRef, useState } from 'react'
import { router } from '@inertiajs/react'
import { useForm } from '@inertiajs/react'
import Button from '@/Shared/Button'
import Checkbox from '@/Shared/Forms/Checkbox'
import classNames from 'classnames'
import Helpers from '@/utils/helpers'
import Info from '@/Shared/Info'
import SlideOver from '@/Shared/SlideOver'
import TextInput from '@/Shared/Forms/TextInput'
import { Tooltip } from '@/Shared/Tooltip'

export default ({ open, onClosed, record }) => {
  const bannerRef = useRef()
  const focusRef = useRef()
  const { clearErrors, data, setData, errors, setError, post } = useForm()
  const [file, setFile] = useState(null)
  const [submitting, setSubmitting] = useState(false)

  useEffect(() => {
    if (record) {
      setData({
        id: record.id || null,
        title: record.title || '',
        banner: record.banner || null,
        slug: record.slug || '',
        published: record.published != undefined ? record.published : true,
      })
    }
  }, [record])

  const onClosing = () => {
    clearErrors()
    onClosed()

    setTimeout(() => {
      setFile(null)
    }, 400)
  }

  const readFile = (files) => {
    setData({ ...data, banner: files[0] })

    return new Promise((resolve, reject) => {
      if (!files || files.length === 0) {
        resolve()
        return
      }

      const file = files[0]

      if (/^image\/\w+$/.test(file.type)) {
        if (URL) {
          resolve({
            loaded: true,
            name: file.name,
            type: file.type,
            url: URL.createObjectURL(file),
          })
        } else {
          reject(new Error('Your browser is not supported.'))
        }
      } else {
        reject(new Error('Please choose an image file.'))
      }
    }).then((data) => {
      setFile(data)
    })
  }

  const submit = (event) => {
    event.preventDefault()

    setSubmitting(true)

    if (data.id) {
      router.post(
        route('admin.services.update', data.id),
        {
          ...data,
          _method: 'put',
        },
        {
          onSuccess: () => onClosing(),
          onError: (errors) => setError(errors),
          onFinish: () => {
            setSubmitting(false)
          },
        },
      )
    } else {
      post(route('admin.services.store'), {
        onSuccess: () => onClosing(),
        onFinish: () => {
          setSubmitting(false)
        },
      })
    }
  }

  return (
    <SlideOver
      focusRef={focusRef}
      footerActions={
        <Button type="submit" theme="solid" form="service-form" disabled={submitting}>
          {data.id ? 'Save Changes' : 'Create'}
        </Button>
      }
      show={open}
      size="max-w-lg"
      title={data.id ? data.name : 'New'}
      subTitle="Service"
      onClosed={onClosing}
    >
      <form id="service-form" className="mb-5" onSubmit={submit}>
        <div className="space-y-4">
          {data.id && <Info text={`There are ${record.pros_count} active PROs listed for this service.`} />}

          <TextInput
            label="Name"
            name="title"
            value={data.title}
            error={errors.title}
            onChange={(value) => setData({ ...data, title: value })}
            required
          />

          {data.id && (
            <TextInput
              label={
                <div>
                  <span>Permalink</span>
                  <div className="mb-1.5">
                    <a
                      href={`/search-results/${data.slug}`}
                      className="flex items-center justify-between gap-4 rounded-md px-0.5 font-normal lowercase text-blue-500 hover:underline focus:ring-1 focus:ring-blue-500 focus:ring-offset-1"
                      target="_blank"
                    >
                      <div>
                        {`${window.location.protocol}/${window.location.host}/search-results/`}
                        <span className="font-bold">{data.slug}</span>
                      </div>
                      <i className="fal fa-external-link text-sm"></i>
                    </a>
                  </div>
                </div>
              }
              name="slug"
              value={data.slug}
              error={errors.slug}
              onChange={(value) => setData({ ...data, slug: Helpers.slugify(value) })}
            />
          )}

          <div className="mb-4">
            <div className="mb-1 flex items-center gap-2 text-sm font-medium uppercase text-gray-500">
              Banner image
              <Tooltip label="Recommended size is 1200 x 300." placement="bottom">
                <i className="far fa-question-circle cursor-help text-xl text-gray-400"></i>
              </Tooltip>
            </div>

            <div
              className={classNames('relative mb-3 overflow-hidden shadow-lg ring-2 ring-white', {
                'bg-gray-400': !data.banner,
              })}
              style={{ paddingBottom: '25%' }}
            >
              <div className="absolute inset-0 inline-flex w-full items-center justify-center outline-none">
                <span className="inline-flex w-full items-center justify-center overflow-hidden shadow-md outline-none">
                  <img
                    className="max-h-full"
                    src={file ? file.url : typeof data.banner === 'string' ? data.banner : '/images/layout/banner_ph.jpg'}
                  />
                </span>

                <div className="absolute right-2 top-2 flex items-center justify-center gap-6">
                  {!file && data.banner && (
                    <a
                      href={data.banner}
                      className="rounded-full bg-black bg-opacity-60 transition-colors duration-150 hover:bg-gray-500 hover:bg-opacity-50 focus:bg-gray-500 focus:bg-opacity-75"
                      target="_blank"
                    >
                      <div className="flex h-8 w-8 items-center justify-center">
                        <i className="fal fa-external-link text-lg leading-none text-white opacity-90"></i>
                      </div>
                    </a>
                  )}
                </div>
              </div>
            </div>

            <input
              ref={bannerRef}
              name="banner"
              accept="image/jpeg,image/png"
              tabIndex="-1"
              type="file"
              className="
                block w-full cursor-pointer text-sm text-slate-500 outline-blue-500 file:mr-4
                file:rounded-full file:border-0 file:bg-blue-100
                file:px-4 file:py-2
                file:text-sm file:font-semibold
                file:text-blue-500 hover:file:bg-blue-200
                hover:file:bg-opacity-40
              "
              onChange={(event) => readFile(event.target.files)}
            />
          </div>
        </div>

        <div className="mt-8 space-y-4">
          <Checkbox
            label="Public"
            description="This service is viewable to the general public and search engines such as Google, Bing, etc."
            name="published"
            value={data.published}
            onChange={(checked) => setData({ ...data, published: checked })}
          />
        </div>

        {record.id && (
          <div className="mt-6 space-y-3 border-t border-dashed border-gray-300 pt-6">
            <div className="flex items-center justify-center gap-4">
              <label className="block w-40 text-right text-sm font-semibold uppercase text-gray-700">Created</label>
              <div className="flex-1">{record.created_at}</div>
            </div>
            <div className="flex items-center justify-center gap-4">
              <label className="block w-40 text-right text-sm font-semibold uppercase text-gray-700">Last Updated</label>
              <div className="flex-1">{record.updated_at}</div>
            </div>
          </div>
        )}
      </form>
    </SlideOver>
  )
}
