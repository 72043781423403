import Button from '@/Shared/Button'
import classNames from 'classnames'
import Steps from '@/Shared/Checklists/Steps'

export default ({ section, transaction, showCompleted, onBack, onClose, onShowCompleted }) => {
  const availableSteps = section?.steps.filter((step) => showCompleted || !step.completed_at) || []

  return (
    <div
      className={classNames(
        'fixed inset-y-0 right-0 z-20 h-full w-full overflow-y-auto bg-gray-100 transition duration-300 ease-in-out',
        section ? 'translate-x-0' : 'translate-x-full',
      )}
    >
      <div className="sticky top-0 z-20 shadow-md">
        <div className="bg-blue-700 py-2.5 pl-4 pr-3 text-white sm:pl-6">
          <div className="flex items-center justify-between">
            <button className="inline-flex h-10 items-center leading-none text-white outline-none" type="button" onClick={onBack}>
              <span className="sr-only">Back</span>
              <i className="far fa-chevron-left mr-3 text-xl leading-none"></i>
              <span className="font-medium">Checklist</span>
            </button>

            <div className="ml-3 flex h-10 items-center space-x-2">
              <Button theme="clean" onClick={() => onShowCompleted(!showCompleted)}>
                <div className="flex items-center gap-2.5 text-white">
                  <i className={classNames('far', showCompleted ? 'fa-eye' : 'fa-eye-slash')}></i>
                  <span>{showCompleted ? 'Hide' : 'Show'} Completed</span>
                </div>
              </Button>

              <Button theme="toolbar-icon" onClick={onClose}>
                <span className="sr-only">Close</span>
                <i className="fal fa-times text-2xl leading-none text-opacity-80"></i>
              </Button>
            </div>
          </div>
        </div>

        <div className="bg-white px-3 py-3 sm:px-6">
          <div className="text-center text-xl">
            <span>{transaction.name}</span>
          </div>

          <div className="text-center text-2xl font-semibold">
            <span className="text-gray-900">{section?.name}</span>
          </div>
        </div>
      </div>

      {availableSteps.length > 0 ? (
        <div className="border-b border-gray-200 bg-white p-4">
          <Steps section={section} showCompleted={showCompleted} transaction={transaction} />
        </div>
      ) : (
        <div className="flex-1 p-4 md:-mt-6 md:flex md:items-center md:justify-center md:bg-gray-300 md:px-0 md:py-0">
          <div className="mx-auto w-full max-w-2xl space-y-6 rounded-md bg-white p-8 shadow-md">
            <div className="border-b border-gray-200 pb-2 text-xl font-medium text-blue-500">
              All steps for this section have been completed
            </div>

            <div className="space-y-1">
              <p className="text-lg font-semibold text-black">Want to review completed steps?</p>
              <p>
                Tap the <span className="font-bold">Show Completed</span> action at the top of this screen to view all completed steps.
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
