import { forwardRef, useState } from 'react'
import classNames from 'classnames'
import { usePage } from '@inertiajs/react'
import Button from '@/Shared/Button'
import MoveDialog from './MoveDialog'
import { Tooltip } from '@/Shared/Tooltip'

const Tag = ({ tag }) => {
  return (
    <div className="inline-block whitespace-nowrap rounded bg-gray-200 px-2.5 py-1.5 text-sm leading-none text-gray-800">
      <span>{tag.name}</span>
    </div>
  )
}

export default forwardRef(({ active, index, step, onDuplicating, onEditing }, ref) => {
  const { states } = usePage().props
  const [moving, setMoving] = useState(false)
  const requiredFiles = getRequiredFiles() || []

  function getRequiredFiles() {
    if (step.actionable_type === 'Email' && step.actionable.files.length > 0) {
      return step.actionable.files
    }
  }

  return (
    <li ref={ref} className="mb-4">
      <div
        className={classNames('overflow-hidden rounded-md bg-white', {
          'border border-gray-200': !active,
          'border-2 border-blue-500': active,
        })}
      >
        <div className="flex items-center justify-between pr-4">
          <div className="flex w-0 flex-1 gap-4">
            <span className="flex items-center bg-gray-200 px-4 text-xl font-semibold text-gray-800 sm:px-6">{step.index}.</span>

            <div className="flex-1 py-3 lg:flex lg:items-center lg:justify-between lg:py-0">
              <div className="flex-1 space-y-1.5 sm:space-y-0 lg:py-3">
                <div className="mb-0.5 text-lg leading-snug">
                  <span dangerouslySetInnerHTML={{ __html: step.name }}></span>
                </div>

                <div className="flex flex-wrap items-center gap-1.5">
                  {!step.active && (
                    <div className="inline-flex items-center whitespace-nowrap rounded bg-yellow-100 px-2.5 py-1.5 text-sm font-medium uppercase leading-none text-yellow-600">
                      Inactive
                    </div>
                  )}

                  {step.property_types?.map((tag) => (
                    <Tag tag={tag} key={tag.id} />
                  ))}

                  {step.states?.map((tag) => (
                    <Tag tag={{ ...tag, type: 'state', color: states.find((s) => s.value == tag.name)?.color }} key={tag.name} />
                  ))}

                  {step.required_steps?.length > 0 && (
                    <div className="inline-block rounded bg-red-100 px-2.5 py-1.5 text-sm font-medium uppercase leading-none text-red-600">
                      {step.required_steps
                        .sort((a, b) => (a.index > b.index ? 1 : -1))
                        .map(
                          (s, index) =>
                            (index > 0 ? (step.required_steps.length > 2 && index + 1 < step.required_steps.length ? ', ' : ' & ') : '') +
                            s.nameWithoutTags,
                        )}{' '}
                      Step{step.required_steps?.length > 1 && 's'} required
                    </div>
                  )}

                  {step.settings?.required_roles?.length > 0 && (
                    <div className="inline-block rounded bg-red-100 px-2.5 py-1.5 text-sm font-medium uppercase leading-none text-red-600">
                      {step.settings.required_roles.map(
                        (role, index) =>
                          (index > 0
                            ? step.settings.required_roles.length > 2 && index + 1 < step.settings.required_roles.length
                              ? ', '
                              : ' & '
                            : '') +
                          (role.party_representing ? `${role.party_representing} ` : '') +
                          role.role,
                      )}{' '}
                      required
                    </div>
                  )}

                  {requiredFiles.length > 0 && (
                    <div className="inline-block rounded bg-red-100 px-2.5 py-1.5 text-sm font-medium uppercase leading-none text-red-600">
                      {requiredFiles.map(
                        (file, index) =>
                          (index > 0 ? (requiredFiles.length > 2 && index + 1 < requiredFiles.length ? ', ' : ' & ') : '') + file.name,
                      )}{' '}
                      file{requiredFiles.length > 1 ? 's' : ''} required
                    </div>
                  )}

                  {step.rules?.length > 0 && (
                    <div className="inline-block whitespace-nowrap rounded bg-red-100 px-2.5 py-1.5 text-sm font-medium uppercase leading-none text-red-600">
                      {step.rules.length} rule{step.rules.length > 1 ? 's' : ''} applied
                    </div>
                  )}
                </div>
              </div>

              <div className="flex flex-shrink-0 items-center justify-end">
                <Tooltip label="Reorder" placement="bottom">
                  <Button theme="icon" colors="text-gray-500 hover:bg-blue-100 hover:text-gray-800" onClick={() => setMoving(true)}>
                    <i className="far fa-exchange-alt rotate-90 transform"></i>
                  </Button>
                </Tooltip>

                <Tooltip label="Duplicate" placement="bottom">
                  <Button theme="icon" onClick={() => onDuplicating(step)}>
                    <i className="far fa-copy text-gray-500 group-hover:font-bold group-hover:text-blue-500"></i>
                  </Button>
                </Tooltip>

                <Tooltip label="Edit" placement="bottom">
                  <Button theme="icon" onClick={() => onEditing(step)}>
                    <i className="far fa-pen text-gray-500 group-hover:font-bold group-hover:text-blue-500"></i>
                  </Button>
                </Tooltip>
              </div>
            </div>
          </div>
        </div>
      </div>

      <MoveDialog open={moving} record={step} onClosed={() => setMoving(false)} />
    </li>
  )
})
