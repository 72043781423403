import { usePage } from '@inertiajs/react'

import Button from '@/Shared/Button'
import React from 'react'
import Step from './Steps/Index'
import { nanoid } from 'nanoid'

export default React.forwardRef(({ section, onCreate, onEdit, scrollToElement }, ref) => {
  const { active_step_id } = usePage().props
  const steps = section.steps

  const refs = steps.reduce((carry, step) => {
    carry[step.id] = React.createRef()
    return carry
  }, {})

  React.useEffect(() => {
    if (refs[active_step_id]?.current) {
      scrollToElement(refs[active_step_id].current)
    }
  }, [active_step_id])

  const handleDuplicating = (step) => {
    let parent_id = nanoid()

    onCreate({
      ...step,
      id: null,
      temp_id: parent_id,
      sort_order: parseInt(step.sort_order) + 1,
      subtasks: step.subtasks.map((subtask) => ({ ...subtask, id: nanoid(), parent_id: parent_id })),
    })
  }

  return (
    <div ref={ref} className="mb-6 shadow">
      <div className="flex items-center justify-between rounded-t-lg bg-blue-500 px-4 py-5 text-white sm:px-6 md:sticky md:top-0 md:z-10">
        <div className="relative flex-1">
          <div className="flex items-center justify-between">
            <h3 className="text-xl font-medium">{section.name}</h3>
          </div>
        </div>
      </div>

      <div className="rounded-b-lg border-t border-gray-200 bg-gray-50 p-4 sm:p-6 sm:pb-2">
        {steps.length > 0 ? (
          <ul role="list" className="-mb-2">
            {steps.map((step, index) => (
              <Step
                ref={refs[step.id]}
                index={index + 1}
                active={step.id == active_step_id}
                step={step}
                stepCount={steps.length}
                key={step.id}
                onDuplicating={(step) => handleDuplicating(step)}
                onEditing={onEdit}
              />
            ))}
          </ul>
        ) : (
          <div className="mb-1 bg-gray-50 text-center italic">No steps have been added to this section.</div>
        )}

        <div className="flex justify-center">
          <Button
            theme="icon"
            onClick={() =>
              onCreate({
                section_id: section.id,
                sort_order: steps.length + 1,
              })
            }
          >
            <div className="flex items-center justify-center">
              <i className="fal fa-plus text-gray-500"></i>
            </div>
          </Button>
        </div>
      </div>
    </div>
  )
})
