import { Fragment, useState } from 'react'
import Button from '@/Shared/Button'
import Invite from './Invite'
import Filters from './Filters'
import SearchBar from '@/Shared/SearchBar'
import { usePage } from '@inertiajs/react'

export default () => {
  const { user } = usePage().props.auth
  const [openDrawer, setOpenDrawer] = useState(null)

  return (
    <Fragment>
      <SearchBar
        actions={
          !route().current().startsWith('admin') &&
          user.roles.find((role) => role === 'Associate') && (
            <Button theme="solid" onClick={() => setOpenDrawer('invite')}>
              <i className="fas fa-user-plus mr-2 text-lg"></i>
              <span className="pr-1">Invite a PRO</span>
            </Button>
          )
        }
        label={route().current().startsWith('admin') ? 'PROs' : 'Find a PRO'}
        onShowFilters={() => setOpenDrawer('filters')}
      />

      <Filters open={openDrawer === 'filters'} onClosed={() => setOpenDrawer(null)} />
      <Invite open={openDrawer === 'invite'} onClosed={() => setOpenDrawer(null)} />
    </Fragment>
  )
}
