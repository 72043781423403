import classNames from 'classnames'

const Wrapper = ({ children, ...props }) =>
  props.onClick ? (
    <button type="button" {...props}>
      {children}
    </button>
  ) : (
    <div {...props}>{children}</div>
  )

export default ({ children, className, onClick, ...props }) => {
  return (
    <Wrapper
      className={classNames(
        'flex w-full items-center justify-between p-4 text-gray-600 outline-none',
        {
          'hover:bg-gray-100 focus:ring-2 focus:ring-inset focus:ring-primary-500': !props.disabled && onClick,
        },
        className,
      )}
      onClick={!props.disabled ? onClick : undefined}
      style={{
        minHeight: '60px',
      }}
      {...props}
    >
      <div className="flex-grow text-left">{children}</div>
      {onClick && !props.disabled && (
        <div className="pl-4 text-right leading-none">
          <i className="fal fa-angle-right text-3xl"></i>
        </div>
      )}
    </Wrapper>
  )
}
