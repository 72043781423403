import React from 'react'
import classNames from 'classnames'

export default function TopBarDropdownItem({ children, colorClasses, href, onClick }) {
  return (
    <a
      href={href}
      className={classNames('group flex cursor-pointer items-center px-6 py-3 leading-none hover:bg-gray-200', colorClasses)}
      role="menuitem"
      onClick={onClick}
    >
      {children}
    </a>
  )
}

TopBarDropdownItem.defaultProps = {
  colorClasses: 'text-gray-700 hover:text-gray-900',
}
